import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import useLanguage from 'hooks/useLanguage'
import { fetchNationalities } from 'store/slices/nationalitiesSlice'
import { getLocalizedName } from 'utils/getLocalizedName'
import { Nationality, NationalityOption } from './types'


const useNationalities = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { nationalities, isLoading, error } = useSelector((state: RootState) => state.nationalities)

  useEffect(() => {
    if (nationalities.length === 0 && !isLoading && !error) {
      dispatch(fetchNationalities()).then((action: any) => {
        if (action.payload.length === 0 && !error) {
          return
        }
      })
    }
  }, [dispatch, nationalities, isLoading, error])

  return { nationalities, isLoading, error }
}

export const useNationalityOptions = (): {
  options: NationalityOption[]
  isLoading: boolean
  error: string | null
} => {
  const language = useLanguage() as 'ar' | 'en'
  const { nationalities, isLoading, error } = useNationalities()

  const options = useMemo(() => {
    if (nationalities.length === 0) return []

    return nationalities.map((nationality: Nationality) => {
      const localizedName = getLocalizedName(nationality.name, language)
      const fallbackName = language === 'ar' ? nationality.name.en : nationality.name.ar

      return {
        key: String(nationality.id),
        label: localizedName || fallbackName || 'Unknown Nationality'
      }
    })
  }, [nationalities, language])

  return { options, isLoading, error }
}
