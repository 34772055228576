import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { z } from 'zod'

export const useUpdateEmailSchema = () => {
  const { _ } = useLingui()

  return z
    .object({
      email: z
        .string()
        .email({ message: _(msg`الرجاء إدخال عنوان بريد إلكتروني صحيح. مثال: mailto@email.com`) })
        .optional(), // existing email, just for display, optional
      new_email: z
        .string({
          required_error: _(msg`هذا الحقل مطلوب`)
        })
        .email({
          message: _(msg`الرجاء إدخال عنوان بريد إلكتروني صحيح. مثال: mailto@email.com`)
        }),
      confirm_email: z
        .string({
          required_error: _(msg`هذا الحقل مطلوب`)
        })
        .email({
          message: _(msg`الرجاء إدخال عنوان بريد إلكتروني صحيح. مثال: mailto@email.com`)
        })
    })
    .refine((data) => data.new_email === data.confirm_email, {
      message: _(msg`البريد الإلكتروني الجديد لا يتطابق مع تأكيد البريد الإلكتروني`),
      path: ['confirm_email']
    })
    .refine((data) => data.new_email !== data.email, {
      message: _(
        msg`يتطابق عنوان البريد الإلكتروني الجديد الذي أدخلته مع معلوماتك الحالية. يُرجى إدخال عنوان بريد إلكتروني مختلفة لتحديث حسابك`
      ),
      path: ['new_email']
    })
}
