import React from 'react'

interface Props {
  fill?: string
}

const FileUserIcon: React.FC<Props> = ({ fill = 'white' }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8223_133777)">
        <path
          d="M13 3.33335H4.66667V16.6667H16.3333V6.66669H13V3.33335ZM3 2.49335C3 2.03669 3.3725 1.66669 3.8325 1.66669H13.8333L18 5.83335V17.4942C18.0008 17.6036 17.98 17.7121 17.9388 17.8135C17.8976 17.9149 17.8369 18.0072 17.7601 18.0852C17.6832 18.1631 17.5918 18.2251 17.491 18.2677C17.3902 18.3103 17.2819 18.3326 17.1725 18.3334H3.8275C3.60865 18.3318 3.39918 18.2443 3.24435 18.0896C3.08951 17.9349 3.00175 17.7255 3 17.5067V2.49335ZM10.5 9.58335C9.94747 9.58335 9.41756 9.36386 9.02686 8.97316C8.63616 8.58246 8.41667 8.05256 8.41667 7.50002C8.41667 6.94749 8.63616 6.41758 9.02686 6.02688C9.41756 5.63618 9.94747 5.41669 10.5 5.41669C11.0525 5.41669 11.5824 5.63618 11.9731 6.02688C12.3638 6.41758 12.5833 6.94749 12.5833 7.50002C12.5833 8.05256 12.3638 8.58246 11.9731 8.97316C11.5824 9.36386 11.0525 9.58335 10.5 9.58335ZM6.7725 14.1667C6.87352 13.2487 7.30974 12.4002 7.99757 11.784C8.6854 11.1677 9.57646 10.8269 10.5 10.8269C11.4235 10.8269 12.3146 11.1677 13.0024 11.784C13.6903 12.4002 14.1265 13.2487 14.2275 14.1667H6.7725Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_8223_133777">
          <rect width="20" height="20" fill={fill} transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FileUserIcon
