import React from 'react'
import awardsList from './awards-list'

interface BackgroundImageProps {
  activeCard: number
  prevActiveCard: number | null
}

const BackgroundImage: React.FC<BackgroundImageProps> = ({ activeCard, prevActiveCard }) => {
  return (
    <div className="absolute w-full h-full left-0 top-0 -z-10">
      {prevActiveCard && prevActiveCard !== activeCard && (
        <img
          key={prevActiveCard}
          className="object-cover w-full h-full transition-opacity duration-1000 ease-in-out opacity-0"
          src={awardsList[prevActiveCard - 1].bgImage}
          alt={awardsList[prevActiveCard - 1].title.id}
          style={{ position: 'absolute', top: 0, left: 0 }}
        />
      )}
      <img
        key={activeCard}
        className="object-cover w-full h-full transition-opacity duration-1000 ease-in-out opacity-100"
        src={awardsList[activeCard - 1].bgImage}
        alt={awardsList[activeCard - 1].title.id}
      />

      <div className="absolute inset-0 bg-gradient-to-tr from-black from-3% animate-fade-in z-20 pointer-events-none" />
      <div
        className="absolute inset-0 animate-fade-in z-30 pointer-events-none"
        style={{
          backgroundImage: `linear-gradient(rgba(42, 172, 173, 0), rgba(43, 173, 173, 1))`
        }}
      />
    </div>
  )
}

export default BackgroundImage
