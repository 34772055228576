// hooks/useRegions.ts

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchEducationLevels } from 'store/slices/educationLevelsSlice'

interface UseRegionsProps {
  countryId?: string
  countryCode?: string
}

export const useEducationLevels = ({ countryId, countryCode }: UseRegionsProps = {}) => {
  const dispatch = useDispatch<AppDispatch>()
  const { educationLevels, isLoading } = useSelector((state: RootState) => state.educationLevels)

  useEffect(() => {
    if (countryId) {
      dispatch(fetchEducationLevels({ countriesIds: [countryId] }))
    } else if (countryCode) {
      dispatch(fetchEducationLevels({ countryCode: countryCode }))
    } else {
      dispatch(fetchEducationLevels({}))
    }
  }, [dispatch, countryId, countryCode])

  return { educationLevels, isLoading }
}
