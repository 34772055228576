import classNames from 'classnames'
import ButtonVariant from 'components/button-variant/button'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface SelectButtonGroupProps {
  name: string
  label: string
  options: { key: string; label: string }[]
  containerClassName?: string
  className?: string
}

const ButtonGroupInput: FC<SelectButtonGroupProps> = ({
  name,
  label,
  options,
  containerClassName,
  className
}) => {
  const { control, setValue, watch } = useFormContext()

  const selectedValue = watch(name)
  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState: { error } }) => {
        return (
          <div>
            {label && (
              <p className="font-sub-heading-h6-ar mb-2 text-natural_icon_normal capitalize">
                {label}
              </p>
            )}
            <div className={`flex gap-2 ${containerClassName}`}>
              {options.map((item, index) => {
                const isActive = selectedValue === item.key
                return (
                  <ButtonVariant
                    variant="primary"
                    key={index.toString()}
                    className={classNames(className, {
                      '!border-secondary_fill_normal_pressed border !text-natural_icon_normal':
                        true,
                      '!bg-[#D8F0C2] border text-natural_lv1_text_normal': isActive,
                      'bg-white': !isActive
                    })}
                    onClick={() => setValue(name, item.key)}
                  >
                    {item.label}
                  </ButtonVariant>
                )
              })}
            </div>

            {!!error?.message && (
              <p className="text-danger_default_lv1_text_normal_active_hover_pressed font-label-sm-ar">
                {error?.message}
              </p>
            )}
          </div>
        )
      }}
    />
  )
}

export default ButtonGroupInput
