import React from 'react'
import ModalHeader from 'components/modal/modal-header'
import ModalLayout from 'components/modal/modal-layout'
import { Trans } from '@lingui/macro'
import { msg } from '@lingui/macro'
import ButtonVariant from 'components/button-variant/button'
import TextareaField from 'components/textarea-field/textarea-field'
import { useLingui } from '@lingui/react'

interface SendSubmitReminderAppModalProps {
  onConfirm: (emailContent: string) => void
  onCancel: () => void,
}

const SendSubmitReminderAppModal: React.FC<SendSubmitReminderAppModalProps> = ({ onConfirm, onCancel }) => {
  const [emailContent, setEmailContent] = React.useState<string>('')
  const { _ } = useLingui()

  return (
    <ModalLayout
      onBack={onCancel}
      appStyle
      header={
        <ModalHeader
          headerText={msg`إرسال تذكير`}
          subheaderText={
            <p>
              <Trans>يرجى كتابة نص الرسالة لإرساله الى الطالب</Trans>
            </p>
          }
        />
      }
      body={
        <TextareaField
          label={_(msg`نص الرسالة`)}
          maxLength={2000}
          onChange={(value: any) => setEmailContent(value.target.value)}
        />
      }
      footer={
        <>
          <div className="flex items-center gap-2">
            <ButtonVariant onClick={() => onConfirm(emailContent)} variant="primary" disabled={!emailContent}>
              <Trans>إرسال تذكير</Trans>
            </ButtonVariant>
          </div>
        </>
      }
    />
  )
}

export default SendSubmitReminderAppModal
