import { Trans } from '@lingui/macro'

const IntroSection = () => {
  return (
    <div className="h-full bg-[#F9FAFB] flex flex-col justify-center items-center text-center md:text-start md:items-start md:ps-20 md:pe-8">
      <p className="text-[#52525B] font-ithra text-25 md:text-34 font-400">
        <Trans>مسابقة أقرأ</Trans>
      </p>
      <p className="text-[#18181B] font-ithra text-20 md:text-40 font-400">
        <Trans>لأن رحلتك بين الكتب تستحق أن تُروى، شارك الآن..</Trans>
      </p>
    </div>
  )
}

export default IntroSection
