import useLanguage from 'hooks/useLanguage'
import { useUniversities } from './get-universities'
import { getLocalizedName } from '../utils'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

interface UseUniversityOptionsProps {
  countryId?: string
  regionId?: string // Single region ID
  countryCode?: string
}

export const useUniversityOptions = ({
  countryId,
  regionId,
  countryCode
}: UseUniversityOptionsProps = {}) => {
  const language = useLanguage() as 'ar' | 'en'
  const { i18n } = useLingui()
  const { universities, isLoading } = useUniversities({ countryId, regionId, countryCode })

  const options = universities.map((university: any) => ({
    key: String(university.id),
    label: getLocalizedName(university.name, language)
  }))

  const otherOption = {
    key: 'other',
    label: i18n._(msg`الجامعة غير مدرجة في القائمة`)
  }

  return {
    options: [...options],
    isLoading
  }
}
