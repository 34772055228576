import { FormProvider, useForm } from 'react-hook-form'
import { useUpdateEmailSchema } from './schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import TextInput from 'components/hook-form/text-input'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import ButtonVariant from 'components/button-variant/button'
import { updateEmail } from 'store/slices/updateEmailSlice'
import { fetchMe } from 'store/slices/meSlice'
import { setNewEmail } from 'store/slices/newEmailSlice'
import {
  closeUpdateEmailOTPModal,
  openUpdateEmailOTPModal
} from 'store/slices/updateEmailOTPModalSlice'
import Alert from 'components/alert/alert'
import OtpModalRestEmail from './otp-modal/otp-modal-rest-email'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

function RestEmail() {
  const { _, i18n } = useLingui()
  const { me } = useSelector((state: RootState) => state.me)
  const { isUpdateEmailOTPModalOpen } = useSelector((state: RootState) => state.updateEmailOTPModal)
  const [showAlert, setShowAlert] = useState<{ type: 'success' | 'error'; message: string } | null>(
    null
  )
  const dispatch = useDispatch<AppDispatch>()
  const restEmailSchema = useUpdateEmailSchema()

  // Infer the type from the schema
  type RestEmailType = z.infer<typeof restEmailSchema>

  // Initialize the form
  const methods = useForm<RestEmailType>({
    resolver: zodResolver(restEmailSchema),
    defaultValues: {
      email: undefined,
      new_email: undefined,
      confirm_email: undefined
    }
  })

  const [showEmailFields, setShowEmailFields] = useState(false) // Toggle state for email fields

  // Extract methods for form handling
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting }
  } = methods

  const onSubmit = async (data: RestEmailType) => {
    try {
      const result = await dispatch(
        updateEmail({
          email: data.new_email
        })
      ).unwrap()

      if (result.status === 'success') {
        dispatch(setNewEmail(data.new_email))
        dispatch(openUpdateEmailOTPModal())

        // Get the translated message
        let translatedMessage = _(msg`نود إعلامك بأنه تم تحديث {0} بنجاح إلى {1}`)

        translatedMessage = translatedMessage
          .replace('{0}', watch('email') ?? '')
          .replace('{1}', data.new_email)

        setShowAlert({
          type: 'success',
          message: translatedMessage
        })
      }
    } catch (error: any) {
      console.log(error.message)
      const errorMessage = i18n._(error.message) || i18n._(msg`هنالك خطأ`)
      setShowAlert({
        type: 'error',
        message: errorMessage
      })
    }
  }

  useEffect(() => {
    if (me?.data) setValue('email', me.data.email)
  }, [me, setValue])

  const onSuccessCallback = async () => {
    try {
      await dispatch(fetchMe()).unwrap()

      // Close the modal and reset the email fields after successful fetch
      dispatch(closeUpdateEmailOTPModal())
      setValue('new_email', '')
      setValue('confirm_email', '')
      setShowEmailFields(false)
    } catch (error) {
      setShowAlert({ type: 'error', message: _(msg`فشل في جلب بيانات المستخدم`) })
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        {showAlert && (
          <Alert
            closable
            mainText={showAlert.message}
            sizeMainText="md"
            weightMainText="normal"
            onClose={() => setShowAlert(null)}
            type={showAlert.type} // success or error
          />
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center gap-4 w-full my-5">
            <div className="flex-1">
              <TextInput
                label={_(msg`البريد الإلكتروني الحالي `)}
                name="email"
                type="email"
                isDisabled
                classNames={{
                  inputWrapper:
                    'bg-natural_fill_disabled border border-natural_stroke_normal_disabled w-full'
                }}
              />
            </div>

            {!showEmailFields && (
              <div className="flex-shrink-0 self-center mt-7">
                <ButtonVariant
                  type="button"
                  variant="bordered"
                  onPress={() => setShowEmailFields(true)}
                  className=""
                >
                  {_(msg`تحديث`)}
                </ButtonVariant>
              </div>
            )}
          </div>

          {showEmailFields && (
            <div className="flex flex-col">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-12 w-full my-5">
                <TextInput label={_(msg`البريد الإلكتروني الجديد`)} name="new_email" type="email" />
                <TextInput
                  label={_(msg`تأكيد البريد الإلكتروني الجديد`)}
                  name="confirm_email"
                  type="email"
                />
              </div>

              <div className="flex w-full justify-end">
                <ButtonVariant
                  type="submit"
                  variant="secondary"
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                >
                  {_(msg`تحديث`)}
                </ButtonVariant>
              </div>
            </div>
          )}
        </form>
      </FormProvider>
      {isUpdateEmailOTPModalOpen && <OtpModalRestEmail onSuccessCallback={onSuccessCallback} />}
    </>
  )
}

export default RestEmail
