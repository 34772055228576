import React from 'react'
import SelectInput from 'components/hook-form/select-input'
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'
import ButtonVariant from 'components/button-variant/button'
import TrashIcon from 'assets/icons/TrashIcon'
import TextInput from 'components/hook-form/text-input'

interface FilterCriteriaProps {
  index: number
  remove: (index: number) => void
  dataFields: { label: string; key: string }[]
  valueFields: { label: string; key: string }[]
  operatorOptions: { label: string; key: string }[]
  linkOperatorOptions: { label: string; key: string }[]
  hasAge?: boolean
  isMulti?: boolean
}

export default function FilterCriteria({
  index,
  remove,
  dataFields,
  valueFields,
  operatorOptions,
  linkOperatorOptions,
  hasAge,
  isMulti
}: FilterCriteriaProps) {
  const { _ } = useLingui()

  return (
    <div className="flex flex-row  flex-wrap gap-4 ">
      <div className="flex-1 w-full  md:w-1/2">
        {
          <SelectInput
            isDisabled={index == 0}
            name={`criteria[${index}].linkOperator`}
            label={_(msg`عامل الربط`)}
            placeholder={_(msg`اختر عامل الربط`)}
            options={linkOperatorOptions}
          />
        }
      </div>
      <div className="flex-1 w-full  md:w-1/2">
        <SelectInput
          name={`criteria[${index}].data`}
          label={_(msg`البيانات`)}
          placeholder={_(msg`اختر البيانات`)}
          options={dataFields}
        />
      </div>
      <div className="flex-1 w-full  md:w-1/2">
        <SelectInput
          name={`criteria[${index}].operator`}
          label={_(msg`العامل`)}
          placeholder={_(msg`اختر العامل`)}
          options={operatorOptions}
        />
      </div>
      <div className="flex-1 w-full  md:w-1/2">
        {!hasAge ? (
          <SelectInput
            isChips={isMulti}
            selectionMode={isMulti ? 'multiple' : 'single'}
            name={`criteria[${index}].value`}
            label={_(msg`قيمة العامل`)}
            placeholder={_(msg`اختر قيمة العامل`)}
            options={valueFields}
          />
        ) : (
          <TextInput
            max={100}
            min={1}
            type="number"
            name={`criteria[${index}].value`}
            label={_(msg`قيمة العامل`)}
            placeholder={_(msg`اختر قيمة العامل`)}
          />
        )}
      </div>

      <div className=" w-6 justify-end flex bottom-0 flex-col items-center">
        {index > 0 && (
          <ButtonVariant
            className="text-black"
            variant="clear"
            isIconOnly
            onClick={() => remove(index)}
          >
            <TrashIcon />
          </ButtonVariant>
        )}
      </div>
    </div>
  )
}
