import { msg, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import AppActions from 'components/apps/Actions'
import AppStatus from 'components/apps/Status'
import DataTable from 'components/data-table/data-table'
import Filter from 'components/Filter'
import { getRequestStatusLabel } from 'constants/requestStatus'
import useLanguage from 'hooks/useLanguage'
import { useCountryOptions } from 'pages/SubmitApplication/hooks/useCountryOptions'
import { useEducationAdministrationOptions } from 'pages/SubmitApplication/hooks/useEducationAdministrationOptions'
import { useEducationLevelsOptions } from 'pages/SubmitApplication/hooks/useEducationLevelsOptions'
import { useRegionOptions } from 'pages/SubmitApplication/hooks/useRegionOptions'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import { Application, ApplicationStatus } from 'store/slices/applicationsSlice'
import { fetchWorkflowApps, ISearchWorkflowApp } from 'store/slices/workflowAppsSlice'
import { yyyy_mm_dd } from 'utils/time'
import MSelectPicker from './components/MSelectPicker'
import RangeDatePicker from './components/RangeDatePicker'

const statusOptions = [
  { key: 'admin', label: t`يدوي` },
  { key: 'system', label: t`اوتوماتيك` }
]

type Location = { country: string | string[]; region: string | string[] }

export const WorkflowApps = () => {
  const { year, id } = useParams<{ year: string; id: string }>()
  const dispatch = useDispatch<AppDispatch>()
  const { _ } = useLingui()
  const language = useLanguage()
  const queryRef = useRef<Partial<ISearchWorkflowApp>>({})

  const [education, setEducation] = useState<string[]>([])
  const [location, setLocation] = useState<Location>({ country: '', region: '' })
  const { options: countries } = useCountryOptions()
  const { options: regions } = useRegionOptions(location.country)
  const { options: educationLevels } = useEducationLevelsOptions({ countryId: location.country })
  const { options: educationAdmins } = useEducationAdministrationOptions({
    countryId: location.country,
    regionId: location.region
  })

  const hasCountry = Array.isArray(location.country)
    ? !!location.country.length
    : !!location.country
  const hasRegion = Array.isArray(location.region) ? !!location.region.length : !!location.region

  const { workflowApps, isLoading, page, total_pages } = useSelector(
    (state: RootState) => state.workflowApps
  )

  const appStatusOptions = [
    { key: '2', label: getRequestStatusLabel('pending', language) },
    { key: '3', label: getRequestStatusLabel('approved', language) },
    { key: '4', label: getRequestStatusLabel('rejected', language) }
  ]

  const columns = [
    { key: 'name', label: _(msg`البيانات الشخصية للطالب`) },
    { key: 'assigned', label: _(msg`تاريخ الإضافة`) },
    { key: 'type', label: _(msg`نوع الإضافة`) },
    { key: 'from', label: _(msg`من قبل`) },
    { key: 'status', label: _(msg`الحالة`) },
    ...(hasCountry ? [{ key: 'country', label: _(msg`الدولة`) }] : []),
    ...(hasRegion ? [{ key: 'region', label: _(msg`المنطقة`) }] : []),
    ...(!!education.length ? [{ key: 'education', label: _(msg`مستوى التعليم`) }] : []),
    { key: 'submit', label: _(msg`تاريخ التقديم`) },
    { key: 'settings', label: _(msg`الإعدادات`) }
  ]

  function renderCell(app: Application, columnKey: React.Key) {
    return (
      <div className="min-w-28">
        {columnKey === 'name' && (
          <div className=" flex flex-col gap-0.5">
            <span>{app.student.name}</span>
            <span className="text-sm">{app.student.email}</span>
            <span className="text-sm">{app.student.phone}</span>
          </div>
        )}
        {columnKey === 'assigned' && (
          <span className={language === 'ar' ? 'text-right' : 'text-left'}>
            {app.submission_at ? yyyy_mm_dd(app.workflow_assigned_at) : '/'}
          </span>
        )}
        {columnKey === 'type' && (
          <p>{app.workflow_assign_type === 'admin' ? _(msg`يدويا`) : _(msg`تلقائيا`)}</p>
        )}
        {columnKey === 'from' && <p>{app.workflow_assigned_by?.name}</p>}
        {columnKey === 'status' && (
          <AppStatus status={app.status.name.en.toLocaleLowerCase() as ApplicationStatus} />
        )}
        {columnKey === 'country' && (
          <span>{countries.find((e) => e.key === `${app.location.country_id}`)?.label}</span>
        )}
        {columnKey === 'region' && (
          <span>{regions.find((e) => e.key === `${app.location.region_id}`)?.label}</span>
        )}
        {columnKey === 'education' && (
          <span>
            {educationLevels.find((e) => e.key === `${app.education.education_level_id}`)?.label}
          </span>
        )}
        {columnKey === 'submit' && (
          <span className={language === 'ar' ? 'text-right' : 'text-left'}>
            {app.submission_at ? yyyy_mm_dd(app.submission_at) : '/'}
          </span>
        )}
        {columnKey === 'settings' && (
          <AppActions to={`/app/workflows/application-details/${app.id}`} />
        )}
      </div>
    )
  }

  const fields = [
    {
      name: 'search',
      hidden: true,
      component: <></>,
      action: (value: string) => onSearch({ q: value })
    },
    {
      name: 'assign_date',
      component: (
        <RangeDatePicker
          queryRef={queryRef}
          name={{ from: 'workflow_assigned_at_from', to: 'workflow_assigned_at_to' }}
          label={{
            from: _(msg`تاريخ الاضافة لمجموعة المشاركة (من)`),
            to: _(msg`تاريخ الاضافة لمجموعة المشاركة (الى)`)
          }}
        />
      )
    },
    {
      name: 'type',
      component: (
        <MSelectPicker
          multiple={false}
          queryRef={queryRef}
          options={{ from: statusOptions, to: appStatusOptions }}
          name={{ from: 'workflow_assign_type', to: 'statuses[]' }}
          label={{ from: _(msg`نوع الاضافة`), to: _(msg`حالة المشاركة`) }}
        />
      )
    },
    {
      name: 'location',
      component: (
        <MSelectPicker
          multiple
          queryRef={queryRef}
          getValue={(e) => setLocation({ country: e.from, region: e.to })}
          options={{ from: countries, to: regions }}
          name={{ from: 'countries[]', to: 'regions[]' }}
          label={{ from: _(msg`الدولة`), to: _(msg`المنطقة`) }}
        />
      )
    },
    {
      name: 'education',
      component: (
        <MSelectPicker
          multiple
          queryRef={queryRef}
          getValue={(e) => setEducation((e.from || []) as string[])}
          options={{ from: educationLevels, to: educationAdmins }}
          name={{ from: 'education_levels[]', to: 'education_administrations[]' }}
          label={{
            from: _(msg`المرحلة التعليمية`),
            to: _(msg`الادارة التعليمية`)
          }}
        />
      )
    },
    {
      name: 'submission_date',
      component: (
        <RangeDatePicker
          queryRef={queryRef}
          name={{ from: 'submission_at_from', to: 'submission_at_to' }}
          label={{
            from: _(msg`تاريخ تقديم الطلب (من)`),
            to: _(msg`تاريخ تقديم الطلب (الى)`)
          }}
        />
      )
    }
  ]

  const onSearch = (query?: Partial<ISearchWorkflowApp>) => {
    queryRef.current = { ...queryRef.current, ...(query || {}) }

    dispatch(
      fetchWorkflowApps({
        competition_year_id: Number(year),
        workflow_id: Number(id),
        page,
        ...queryRef.current
      })
    )
  }

  const filterReset = async () => {
    queryRef.current = {}
    setLocation({ country: '', region: '' })
    setEducation([])
    dispatch(fetchWorkflowApps({ competition_year_id: Number(year), workflow_id: Number(id) }))
  }

  useEffect(() => {
    if (year && id) {
      dispatch(
        fetchWorkflowApps({
          competition_year_id: Number(year),
          workflow_id: Number(id),
          ...queryRef.current
        })
      )
    }
  }, [dispatch, year, id])

  return (
    <div className="flex flex-col w-full">
      <div className="sm:max-w-sm">
        <Filter
          className="justify-start"
          filterFields={fields}
          onFilterApply={onSearch}
          onFilterReset={filterReset}
        />
      </div>

      <div className="w-full md:flex flex-col gap-y-3 py-9">
        <DataTable
          withColumnsFilter
          page={page}
          columns={columns}
          data={workflowApps}
          isLoading={isLoading}
          totalPages={total_pages}
          renderCell={renderCell}
          onPageChange={(page) => onSearch({ page })}
        />
      </div>
    </div>
  )
}
