import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Radio, RadioGroup, RadioGroupProps } from '@nextui-org/react'
import clsx from 'clsx'
import Typography from 'components/typography/typography'
import { Trans } from '@lingui/react'

interface RadioOption {
  value: string
  label: string
}

interface RadioInputProps extends Omit<RadioGroupProps, 'onChange'> {
  name: string
  options: RadioOption[]
  label?: string
  isDisabled?: boolean
  helperText?: string
  app_style?: boolean
  onChange?: (value: string) => void
}

const RadioInput: FC<RadioInputProps> = ({
  name,
  options,
  label,
  helperText,
  app_style = false,
  isDisabled = false,
  onChange,
  value,
  ...other
}) => {
  const { control } = useFormContext()

  return (
    <div className="flex flex-col gap-2">
      {label && (
        <p className="font-sub-heading-h6-ar text-natural_icon_normal capitalize">
          <Trans id={label} />
        </p>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <RadioGroup
              orientation="horizontal"
              isDisabled={isDisabled}
              value={field.value}
              onChange={(e) => {
                field.onChange(e)
                if (onChange) {
                  onChange(e.target.value)
                }
              }}
              {...other}
              className="flex flex-row gap-2"
            >
              <div className="flex flex-row gap-4">
                {options.map((option) =>
                  app_style ? (
                    <label
                      key={option.value} // key added here
                      className={clsx(
                        'px-4 py-2 border rounded-full text-center cursor-pointer',
                        value === option.value
                          ? 'bg-primary text-white border-primary'
                          : 'bg-radio text-black border-customBg',
                        'hover:bg-highlight transition',
                        'select-none'
                      )}
                    >
                      <input
                        type="radio"
                        value={option.value}
                        checked={field.value === option.value}
                        onChange={(e) => {
                          field.onChange(e)
                          if (onChange) {
                            onChange(e.target.value)
                          }
                        }}
                        className="hidden"
                      />
                      {option.label}
                    </label>
                  ) : (
                    <Radio  key={option.value} value={option.value}>
                      {option.label}
                    </Radio>
                  )
                )}
              </div>
            </RadioGroup>
            {error && (
              <p className="mx-2 text-danger_default_lv1_text_normal_active_hover_pressed font-label-sm-ar">
                <Trans id={error.message || ''} />
              </p>
            )}
            {helperText && !error && (
              <Typography className="text-gray-500 text-sm">{helperText}</Typography>
            )}
          </>
        )}
      />
    </div>
  )
}

export default RadioInput
