import { z } from 'zod'
import { msg } from '@lingui/macro'

const validationSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: msg`يرجى ملء جميع الحقول المطلوبة (*) للمتابعة`.id })
    .min(3, { message: msg`ادخل 3 أحرف على الأقل`.id })
    .max(30, { message: msg`حد أقصى 30 حرفًا`.id })
    .regex(/^[\u0600-\u06FF\s]+$/, {
      message: msg`الرجاء إدخال اسم صحيح باللغة العربية. يُسمح باستخدام الأحرف والمسافات فقط`.id
    }),

  email: z
    .string()
    .trim()
    .min(1, { message: msg`يرجى ملء جميع الحقول المطلوبة (*) للمتابعة`.id })
    .email({
      message: msg`"الرجاء إدخال عنوان بريد إلكتروني صحيح. مثال: mailto:example@email.com`.id
    }),

  password: z
    .string()
    .trim()
    .min(1, { message: msg`يرجى ملء جميع الحقول المطلوبة (*) للمتابعة`.id })
    .min(6, { message: msg`يجب أن تتكون كلمة المرور من 6 أحرف على الأقل`.id })
    .refine((string) => !/^[A-Za-z0-9]+$|^[a-z0-9]+$|^[A-Z0-9]+$|^[\W_]+$/.test(string), {
      message:
        msg`كلمه السر ليست قوية بما فيه الكفاية. يجب أن تحتوي كلمة المرور على حروف كبيرة وصغيرة وأرقام ورموز`
          .id
    }),

  phone: z
    .string()
    .trim()
    .min(1, { message: msg`يرجى ملء جميع الحقول المطلوبة (*) للمتابعة`.id })
    .min(1, { message: msg`رقم الهاتف مطلوب`.id })
    .min(13, { message: msg`الرجاء إدخال رقم جوال صحيح`.id }),

  gender: z
    .string()
    .nullable()
    .refine((value) => !!value, { message: msg`يرجى ملء جميع الحقول المطلوبة (*) للمتابعة`.id }),

  country: z
    .string()
    .nullable()
    .refine((value) => !!value, { message: msg`يرجى ملء جميع الحقول المطلوبة (*) للمتابعة`.id }),

  terms_agreed: z.boolean().refine((value) => value === true, {
    message: msg`يجب أن توافق على الشروط والأحكام`.id
  })
})

export default validationSchema
