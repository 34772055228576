import { Trans } from '@lingui/macro'
import React from 'react'

const AwardsHeader: React.FC = () => {
  return (
    <div className="flex flex-col">
      <h2 className="text-customWhite text-3xl md:text-4xl font-bold">
        <Trans>جوائز المسابقة</Trans>
      </h2>
      <p className="text-customWhite text-xl md:text-2xl font-bold">
        <Trans>الإحتفاء بالقراء والقراءة</Trans>
      </p>
    </div>
  )
}

export default AwardsHeader
