import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import {
  Pagination,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow
} from '@nextui-org/react'
import useLanguage from 'hooks/useLanguage'
import { FC } from 'react'

import { useState, useEffect } from 'react'
import TableSettingPopover from './table-setting'
import Loader from 'components/loader/Loader'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Workflow } from 'api/types'
interface Column {
  key: string
  label: string
}

interface DataTableProps<T> {
  columns: Column[]
  data: T[]
  isLoading: boolean
  page: number
  totalPages: number
  onPageChange: (newPage: number) => void
  renderCell: (item: T, columnKey: string | number) => React.ReactNode
  showPagination?: boolean
  withColumnsFilter?: boolean
  onRowClick?: (w: Workflow) => void
}

export const DataTable: FC<DataTableProps<any>> = ({
  columns,
  data,
  isLoading,
  page,
  totalPages,
  onPageChange,
  renderCell,
  showPagination = true,
  withColumnsFilter,
  onRowClick
}) => {
  let i = 0
  const { _ } = useLingui()
  const language = useLanguage()
  const isAr = language === 'ar'

  const [visibleColumns, setVisibleColumns] = useState<Column[]>(columns)
  const [filteredColumns, setfilteredColumns] = useState<Column[]>(columns)

  const navigateToDetails = (w: Workflow) => {
    if (onRowClick) return () => onRowClick(w)
    return undefined
  }

  const handleFilterColumns = (column: Column) =>
    setfilteredColumns((prev) =>
      prev.includes(column) && prev.length > 1
        ? prev.filter((col) => col !== column)
        : prev.includes(column)
          ? prev
          : [...prev, column]
    )

  const handleApply = () => {
    setVisibleColumns(filteredColumns)
  }

  const handleReset = () => {
    setVisibleColumns(columns)
    setfilteredColumns(columns)
  }

  useEffect(() => {
    if (columns && withColumnsFilter) {
      setVisibleColumns(columns)
      setfilteredColumns(columns)
    }
  }, [columns])

  return (
    <div className="block-inline h-full">
      <div className="flex flex-row justify-end pb-4">
        {withColumnsFilter && (
          <TableSettingPopover
            handleApply={handleApply}
            handleReset={handleReset}
            handleFilterColumns={handleFilterColumns}
            visibleColumns={filteredColumns}
            columns={columns}
          ></TableSettingPopover>
        )}
      </div>
      <Table
        aria-label="Dynamic data table"
        classNames={{
          th: 'm-0 border-b',
          base: 'h-full',
          table: 'h-full'
        }}
        className="p-0"
      >
        <TableHeader columns={visibleColumns}>
          {(column) => (
            <TableColumn
              className={
                'text-start bg-[#fbf9fb] last:rounded-none first:rounded-none text-sm text-[#3F3F46] font-normal h-14'
              }
              key={column.key}
            >
              {column.label}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          items={data}
          isLoading={isLoading}
          loadingContent={<Loader />}
          emptyContent={_(msg`لايوجد نتائج`)}
        >
          {(item) => (
            <TableRow
              onClick={navigateToDetails(item)}
              key={item.id ?? i++}
              className={classNames({
                'border-b border-gray-100': item.id < data.length - 1,
                'cursor-pointer': !!onRowClick
              })}
            >
              {(columnKey) => (
                <TableCell key={columnKey} className="py-4">
                  {renderCell(item, columnKey)}
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>

      {showPagination && (
        <div className="mt-8 flex justify-end w-full">
          <Pagination
            className={`gap-5 p-6 text-start`}
            classNames={{
              wrapper: 'gap-8',
              prev: isAr ? 'rotate-180' : undefined,
              next: isAr ? 'rotate-180' : undefined
            }}
            dir={isAr ? 'rtl' : 'ltr'}
            disableAnimation
            initialPage={page}
            onChange={onPageChange}
            radius="full"
            showControls
            total={totalPages}
            variant="light"
          />
        </div>
      )}
    </div>
  )
}

export default DataTable
