import { msg } from '@lingui/macro'
import SearchInput from 'components/search-field/search-field'
import { ChangeEvent } from 'react'

interface PodcastNavbarProps {
  searchValue: string
  setSearchValue: (value: string) => void
}

const PodcastNavbar: React.FC<PodcastNavbarProps> = ({ searchValue, setSearchValue }) => {
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  return (
    <div
      id="file-navbar"
      className="py-12 flex flex-col space-y-3 w-full md:w-10/12 mx-auto px-3.5 md:px-0"
    >
      <SearchInput
        placeholder={msg`ابحث من هنا`.id}
        value={searchValue}
        onChange={handleSearchChange}
      />
    </div>
  )
}

export default PodcastNavbar
