import { msg } from '@lingui/macro'
import AccountProfile from 'pages/AccountProfile'
import ActivateRequests from 'pages/ActivateRequests'
import ReviewRequest from 'pages/ActivateRequests/ReviewRequest'
import { ApplicationDetails, IdelApplicationDetails } from 'pages/ApplicationDetails'
import CompetitionDetails from 'pages/CompetitionDetails'
import { CompetitionYear } from 'pages/CompetitionYear/CompetitionYear'
import Home from 'pages/auth/Home'
import Points from 'pages/auth/Points'
import ReportTableList from 'pages/Reports/ReportTable/ReportsListTable'
import Applications_ from 'pages/auth/Applications'
import SubmissionStatusCheck from 'pages/SubmitApplication/SubmissionStatusCheck'
import ReportsProvider from 'pages/Reports'
import CompetitionWorkFlows from 'pages/CompetitionWorkFlows'
import WorkFlowsDetails from 'pages/WorkFlowsDetails'
import IdleApplicationsList from 'pages/IdleApplications'
import AdminUsersAccounts from 'pages/AdminUsersAccounts'

export interface RouteConfig {
  path: string
  component?: React.FC
  roles?: string[]
  showInSidebar?: boolean
  showInBreadcrumb?: boolean
  name: string
  icon?: string
  title?: string
  subtitles?: string
  label: string
  children?: RouteConfig[]
}

const routesConfig: RouteConfig[] = [
  {
    path: 'home',
    component: Home,
    roles: ['student', 'teacher', 'coordinator', 'ithraa'],
    showInSidebar: true,
    name: 'ROUTES.HOME',
    icon: 'home-icon',
    label: msg`الصفحة الرئيسية`.id
  },
  {
    path: 'account-profile',
    component: AccountProfile,
    roles: ['student', 'teacher', 'coordinator', 'ithraa', 'admin', 'judge'],
    showInSidebar: true,
    name: 'ROUTES.ACCOUNT_PROFILE',
    icon: 'person-icon',
    label: msg`الملف الشخصي`.id
  },
  {
    path: 'competition',
    component: CompetitionYear,
    roles: ['admin', 'ithraa'],
    showInSidebar: true,
    name: 'ROUTES.COMPETITION',
    icon: 'competition-icon',
    label: msg`سنوات المسابقة`.id
  },
  {
    path: 'competition/competition-details/:id',
    component: CompetitionDetails,
    roles: ['admin', 'ithraa'],
    showInSidebar: false,
    name: 'ROUTES.COMPETITION.COMPETITION_DETAILS',
    label: msg`تفاصيل المسابقة`.id
  },
  {
    path: 'workflows',
    roles: ['admin', 'ithraa'],
    component: CompetitionWorkFlows,
    showInSidebar: true,
    icon: 'workflows-icon',
    name: 'ROUTES.COMPETITION_WORKFLOWS',
    label: msg`سير المشاركات`.id
  },
  {
    path: 'workflows/:year/:id',
    roles: ['admin', 'ithraa'],
    component: WorkFlowsDetails,
    showInSidebar: false,
    name: 'ROUTES.WORKFLOWS_DETAILS',
    label: msg`تفاصيل سير المشاركة`.id
  },
  {
    path: 'workflows/application-details/:id',
    component: ApplicationDetails,
    roles: ['ithraa', 'admin'],
    showInSidebar: false,
    name: 'ROUTES.WORKFLOWS.WORKFLOWS_DETAILS',
    label: msg`تفاصيل النموذج`.id
  },
  {
    path: 'activate-requests',
    component: ActivateRequests,
    roles: ['ithraa'],
    showInSidebar: true,
    icon: 'activate-requests-icon',
    name: 'ROUTES.ACTIVATE_REQUESTS',
    label: msg`طلبات التفعيل`.id
  },
  {
    path: 'activate-requests/review-request/:id',
    component: ReviewRequest,
    roles: ['ithraa'],
    showInSidebar: false,
    name: 'ROUTES.ACTIVATE_REQUESTS.REVIEW_REQUEST',
    label: msg`سير المشاركات`.id
  },
  {
    path: 'points',
    component: Points,
    roles: ['teacher', 'coordinator'],
    showInSidebar: true,
    name: 'ROUTES.POINTS',
    icon: 'medal-icon',
    label: msg`النقاط و الجوائز`.id
  },
  {
    path: 'report',
    component: ReportsProvider,
    roles: ['coordinator', 'ithraa'],
    showInSidebar: true,
    name: 'ROUTES.REPORT',
    icon: 'report-icon',
    label: msg`التقارير`.id
  },
  {
    path: 'report/more/:key',
    component: ReportTableList,
    roles: ['coordinator', 'ithraa'],
    showInSidebar: false,
    name: 'ROUTES.REPORT.VIEW_MORE_REPORTS',
    label: msg`تفاصيل التقارير`.id
  },
  {
    path: 'management-applications',
    roles: ['ithraa'],
    showInSidebar: true,
    name: 'ROUTES.MANGMENT_APPLICATIONS',
    icon: 'apps-icon',
    label: msg`المشاركات`.id,
    children: [
      {
        path: 'idle-applications',
        roles: ['ithraa'],
        component: IdleApplicationsList,
        showInSidebar: true,
        name: 'ROUTES.MANGMENT_APPLICATIONS.IDLE_APPLICATIONS',
        icon: 'apps-icon',
        label: msg`المشاركات المتوقفة`.id
      },
      {
        path: 'idle-applications/application-details/:id',
        component: IdelApplicationDetails,
        roles: ['ithraa'],
        showInSidebar: false,
        name: 'ROUTES.MANGMENT_APPLICATIONS.IDLE_APPLICATIONS.IDLE_APPLICATIONS_DETAILS',
        icon: 'list-icon',
        label: msg`تفاصيل النموذج`.id
      }
    ]
  },
  {
    path: 'applications',
    component: Applications_,
    roles: ['student', 'teacher', 'coordinator'],
    showInSidebar: true,
    name: 'ROUTES.APPLICATIONS',
    icon: 'apps-icon',
    label: msg`المشاركات`.id
  },
  {
    path: 'applications/submit-application',
    component: SubmissionStatusCheck,
    roles: ['student'],
    showInSidebar: false,
    name: 'ROUTES.APPLICATIONS.SUBMIT_APPLICATION',
    icon: 'list-icon',
    label: msg`تقديم مشاركة`.id
  },
  {
    path: 'applications/application-details/:id',
    component: ApplicationDetails,
    roles: ['student', 'teacher', 'coordinator'],
    showInSidebar: false,
    name: 'ROUTES.APPLICATIONS.APPLICATION_DETAILS',
    icon: 'list-icon',
    label: msg`تفاصيل النموذج`.id
  },
  {
    path: 'users-accounts',
    component: AdminUsersAccounts,
    roles: ['ithraa'],
    showInSidebar: true,
    name: 'ROUTES.USERS_ACCOUNTS',
    icon: 'file-user',
    label: msg`إداراة الحسابات`.id
  }
]

export default routesConfig
