import { zodResolver } from '@hookform/resolvers/zod'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import Typography from 'components/typography/typography'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

import { useRegionOptions } from 'pages/AccountProfile/personal-info/hook/regions-options'
import {
  useEducationStageOptions,
  useGenderOfEducationOptions
} from 'pages/AccountProfile/personal-info/utils'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchMe } from 'store/slices/meSlice'
import { useEducationAdministrationOptions } from 'pages/AccountProfile/personal-info/hook/eduction-adminstrations-options'
import CheckboxInput from 'components/hook-form/checkbox-input'
import { useUniversityOptions } from 'pages/AccountProfile/personal-info/hook/university-options'
import TextInput from 'components/hook-form/text-input'
import UploadInput from 'components/hook-form/upload-input'
import TextareaInput from 'components/hook-form/textarea-input'
import ButtonVariant from 'components/button-variant/button'
import { ArrowLeft } from 'assets/icons/ArrowLeft'
import useLanguage from 'hooks/useLanguage'
import Alert from 'components/alert/alert'
import SelectInput from 'components/hook-form/select-field'
import { AccessRequestSchemaType, ReviewRequestSchemaType } from './ReviewRequestSchemaType2'
import { getRegionLabelFromCountry } from './utils'
import { useReviewRequestSchema } from './schema'
import RadioInput from 'components/hook-form/radio-input'
import { useParams } from 'react-router-dom'
import ConfirmModule from './confirm-module'
import { activateRequest } from 'store/slices/activateRequestSlice'

interface ReviewRequestFormProps {
  reviewRequestValues?: ReviewRequestSchemaType
  reviewAccessValues?: AccessRequestSchemaType
}

const ReviewRequestForm: React.FC<ReviewRequestFormProps> = ({
  reviewRequestValues,
  reviewAccessValues
}) => {
  const { id } = useParams()
  const { _, i18n } = useLingui()
  const language = useLanguage()
  const { me } = useSelector((state: RootState) => state.me)
  const dispatch = useDispatch<AppDispatch>()
  const [countryCode, setCountryCode] = useState<string | null>(null)
  const [countryId, setCountryId] = useState<string | null>(null)
  const [applicationStatus, setApplicationStatus] = useState<
    'draft' | 'pending' | 'approved' | 'rejected' | undefined
  >(undefined)
  const [regionLabelCountry, setRegionLabelCountry] = useState<string | null>(null)
  const [enableEdit, setEnableEdit] = useState<boolean>(true)
  const [openModalConfirm, setOpenModal] = useState<boolean>(false)
  const [statusAlert, setStatusAlert] = useState<{
    show: boolean
    type: 'success' | 'error' | 'info'
    message: string
  } | null>(null)
  const ReviewRequestSchema = useReviewRequestSchema(countryCode === 'SA')
  const educationStageOptions = useEducationStageOptions()
  const {
    options: educationAdministrationOptions,
    isLoading: EducationAdministrationOptionLoading
  } = useEducationAdministrationOptions({
    countryId: countryId!
  })
  const { options: regionOptions, isLoading: RegionOptionsLoading } = useRegionOptions(countryId!)
  const { options: universityOptions, isLoading: UniversityOptionsLoading } = useUniversityOptions({
    countryId: countryId!
  })
  const genderOfEducationOptions = useGenderOfEducationOptions()

  // Infer the type from the schema
  type ReviewRequestType = z.infer<typeof ReviewRequestSchema>

  // Initialize the form
  const methods = useForm<ReviewRequestType>({
    resolver: zodResolver(ReviewRequestSchema),
    defaultValues: {
      regions: [],
      education_stage: undefined,
      education_administrations: [],
      all_regions: false,
      all_education_stages: false,
      all_education_administrations: false,
      university: undefined,
      gender_of_education: undefined,
      number_of_students: undefined,
      proof_document: undefined,
      comment: undefined,
      school_university_name: undefined
    }
  })

  const {
    handleSubmit,
    clearErrors,
    setValue,
    reset,
    watch,
    formState: { isSubmitting }
  } = methods
  const education_stage = watch('education_stage')
  const all_regions = watch('all_regions')
  const all_education_stage = watch('all_education_stages')
  const add_to_data_base = watch('add_to_data_base')
  const all_education_administrations = watch('all_education_administrations')
  const university = watch('university')
  const result = watch('result')

  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleConfirm = async () => {
    handleSubmit(onSubmit)()
    setOpenModal(false)
  }

  const onSubmit = async (data: ReviewRequestType) => {
    let access: Record<string, any> = {
      regions: data.regions && data.regions.length > 0 ? data.regions : null,
      education_administrations:
        data.education_stage === 'general' &&
        data.education_administrations &&
        data.education_administrations.length > 0
          ? data.education_administrations
          : null,
      universities:
        data.education_stage === 'university' && data.university !== 'other'
          ? [data.university]
          : null,
      education_stage: data.education_stage ? [data.education_stage] : null
    }
    let payload: Record<string, any> = {
      id: id,
      access: access,
      _method: 'put',
      result: data.result,
      gender_of_education: data.gender_of_education || null,
      comment: data.comment || null,
      rejection_reason: data.rejection_reason || null,

      access_all_regions: data.all_regions ? 1 : 0,
      access_all_education_administrations: data.all_education_administrations ? 1 : 0,
      access_all_education_stages: data.all_education_stages ? 1 : 0,
      all_education_stages: data.all_education_stages ? 1 : 0,

      number_of_students: data.number_of_students || null,
      school_university_name: data.university === 'other' ? data.school_university_name : null,
      university:
        data.education_stage === 'university' && data?.add_to_data_base
          ? {
              id: '',
              name: { ar: data.school_university_name, en: data?.school_university_name_en }
            }
          : null
    }
    delete payload.proof_document
    dispatch(activateRequest({ id: payload.id, formData: payload }))
      .unwrap()
      .then((result: any) => {
        if (result.status === 'success') {
          setStatusAlert({
            show: true,
            message: _(msg`تم مراجعة الطلب بنجاح`),
            type: 'success'
          })
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
          console.log(result)
          setOpenModal(false)
          setEnableEdit(false)
          if (reviewRequestValues)
            reviewRequestValues.status = data.result == 'approve' ? 'approved' : 'rejected'
        }
      })
      .catch((error: any) => {
        if (error.status === 'error') {
          console.log(error)
          const errorMessage = i18n._(error.message) || i18n._(msg`هنالك خطأ`)
          setStatusAlert({
            show: true,
            message: errorMessage,
            type: 'error'
          })
        }
      })
  }

  useEffect(() => {
    dispatch(fetchMe())
  }, [dispatch, reset])

  useEffect(() => {
    setApplicationStatus(reviewRequestValues?.status)
  }, [])

  useEffect(() => {
    if (me?.data) {
      setCountryCode(reviewRequestValues?.country_code || null)
      setCountryId(String(reviewRequestValues?.country_id))
      if (reviewRequestValues?.region_label)
        setRegionLabelCountry(getRegionLabelFromCountry(reviewRequestValues.region_label, language))
    }
  }, [language, me])

  useEffect(() => {
    const updateFormData = async () => {
      let reset_data =
        reviewRequestValues?.status && ['approved'].includes(reviewRequestValues.status)
          ? reviewAccessValues
          : reviewRequestValues

      console.log(reset_data)
      reset({
        regions: reset_data?.regions || [],
        all_regions: reset_data?.all_regions || false,
        education_stage: reset_data?.education_stage || undefined,
        all_education_stages: reset_data?.all_education_stages || false,
        education_administrations: reset_data?.education_administrations || [],
        all_education_administrations: reset_data?.all_education_administrations || false,
        gender_of_education: reset_data?.gender_of_education,
        university: reset_data?.university || undefined,
        number_of_students: reset_data?.number_of_students,
        comment: reviewRequestValues?.comment || undefined,
        school_university_name: reviewRequestValues?.school_university_name || undefined,
        proof_document: reviewRequestValues?.proof_document || undefined,
        result: reviewRequestValues?.result || undefined,
        rejection_reason: reviewRequestValues?.rejection_reason || undefined
      })
    }
    updateFormData()
  }, [reviewRequestValues, reviewAccessValues, me, reset])

  useEffect(() => {
    if (all_regions) setValue('regions', [])
    if (all_education_stage) setValue('education_stage', undefined)
    if (all_education_administrations) setValue('education_administrations', [])
  }, [all_education_administrations, all_education_stage, all_regions, setValue])

  useEffect(() => {
    if (applicationStatus && ['approved', 'rejected'].includes(applicationStatus)) {
      setEnableEdit(false)
      setStatusAlert({
        show: true,
        message:
          applicationStatus == 'approved'
            ? _(msg`تم مراجعة الطلب مسبقا واعتماده`)
            : _(msg`تم مراجعة الطلب مسبقا ورفضه`),
        type: 'info'
      })
    }
  }, [applicationStatus])

  return (
    <div className="flex flex-col gap-5 w-full">
      <div className="flex flex-col gap-2 items-start">
        <Typography size="xl" weight="bold">
          {_(msg`بيانات المنسق لتنشيط الحساب`)}
        </Typography>
        <Typography>{_(msg`الإستمارة التالية تحوي على بيانات طلب تنشيط حساب المنسق`)}</Typography>
      </div>
      {statusAlert && statusAlert.show && (
        <Alert
          mainText={statusAlert.message}
          sizeMainText="md"
          weightMainText="normal"
          type={statusAlert.type}
          onClose={() => setStatusAlert(null)}
        />
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4 w-full">
          <div className="flex flex-col md:flex-row gap-4 w-full items-center">
            <div className="w-full md:w-1/4">
              <SelectInput
                name="regions"
                size="md"
                selectionMode="multiple"
                mode="search"
                isDisabled={all_regions || !enableEdit}
                isLoading={RegionOptionsLoading}
                options={regionOptions}
                label={regionLabelCountry ? regionLabelCountry : _(msg`المنطقة`)}
                placeholder={_(msg`اختر المنطقة`)}
              />
            </div>
            <div className="w-full md:w-auto mt-4 md:mt-0">
              <div className="flex items-center gap-x-2">
                <CheckboxInput
                  rounded
                  className="mt-8"
                  removeBorder
                  reverse
                  name="all_regions"
                  isDisabled={!enableEdit}
                  label={_(msg`جميع المناطق`)}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-4 w-full">
            <div className="w-full md:w-1/4">
              <SelectInput
                name="education_stage"
                size="md"
                isDisabled={all_education_stage || !enableEdit}
                options={educationStageOptions}
                label={_(msg`مرحلة التعليم`)}
                placeholder={_(msg`اختر المرحلة التعليمية`)}
              />
            </div>
            <div className="w-full md:w-auto mt-4 md:mt-0">
              <div className="flex items-center gap-x-2">
                <CheckboxInput
                  rounded
                  removeBorder
                  reverse
                  className="mt-8"
                  name="all_education_stages"
                  isDisabled={!enableEdit}
                  label={_(msg`جميع المراحل التعليمية `)}
                />
              </div>
            </div>
          </div>
          {countryCode === 'SA' && education_stage === 'general' && (
            <div className="flex flex-col md:flex-row items-center gap-4 w-full">
              <div className="w-full md:w-1/4">
                <SelectInput
                  name="education_administrations"
                  size="md"
                  mode="search"
                  selectionMode="multiple"
                  isDisabled={all_education_administrations || !enableEdit}
                  isLoading={EducationAdministrationOptionLoading}
                  options={educationAdministrationOptions}
                  label={_(msg`الإدارة التعليمية`)}
                  placeholder={_(msg`اختر الادارات التعليمية`)}
                />
              </div>
              <div className="w-full md:w-auto mt-4 md:mt-0">
                <div className="flex items-center gap-x-2">
                  <CheckboxInput
                    rounded
                    removeBorder
                    className="mt-8"
                    isDisabled={!enableEdit}
                    reverse
                    name="all_education_administrations"
                    label={_(msg`جميع الإدارات التعليمية `)}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col md:flex-row items-center gap-4 w-full">
            <div className="w-full md:w-1/4">
              <SelectInput
                name="gender_of_education"
                multiple
                size="md"
                options={genderOfEducationOptions}
                isDisabled={!enableEdit}
                label={_(msg`جنس التعليم`)}
                placeholder={_(msg`اختر جنس التعليم`)}
              />
            </div>
            <div className="w-full md:w-1/4">
              <TextInput
                name="number_of_students"
                type="number"
                size="md"
                isDisabled={!enableEdit}
                label={_(msg`عدد الطلاب`)}
                placeholder={_(msg`عدد الطلاب`)}
              />
            </div>
          </div>
          {education_stage === 'university' && (
            <>
              <div className="flex flex-col md:flex-row items-center gap-4 w-full">
                <div className="w-full md:w-1/4">
                  <SelectInput
                    name="university"
                    multiple
                    size="md"
                    mode="search"
                    isLoading={UniversityOptionsLoading}
                    options={universityOptions}
                    showDefaultOptionWhenNoResults={true}
                    isDisabled={!enableEdit}
                    label={_(msg`الجامعة`)}
                    placeholder={_(msg`اختر الجامعة`)}
                  />
                </div>
                {university === 'other' && (
                  <>
                    <div className="w-full md:w-1/4">
                      <TextInput
                        name="school_university_name"
                        size="md"
                        isDisabled={!enableEdit}
                        label={_(msg`اسم الجامعة المدخل من قبل المنسق`)}
                        placeholder={_(msg`يرجى كتابة اسم الجامعة`)}
                      />
                    </div>
                    <div className="w-full md:w-auto mt-4 md:mt-0"></div>
                    {add_to_data_base && (
                      <div className="w-full md:w-1/4">
                        <TextInput
                          name="school_university_name_en"
                          size="md"
                          isDisabled={!enableEdit}
                          label={_(msg`اسم الجامعة بالإنجليزية`)}
                          placeholder={_(msg`اكتب اسم الجامعة بالإنجليزية`)}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="flex flex-col md:flex-row items-center gap-4 w-full">
                <div className="w-full md:w-1/4"></div>
                {university === 'other' && (
                  <>
                    <div className="w-full md:w-auto  md:mt-0">
                      <div className="flex items-center gap-x-2">
                        <CheckboxInput
                          className="font-paragraph-overline-sm-ar"
                          reverse
                          size="sm"
                          isDisabled={!enableEdit}
                          name="add_to_data_base"
                          label={_(msg`إضافة اسم الجامعة إلى قائمة قواعد البيانات`)}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          <UploadInput
            name="proof_document"
            allowRemove={false}
            label={_(msg`مستند داعم`)}
            placeholder={_(msg`حجم الملف الاقصى 3 ميجا`)}
            noFileLabel={true}
            onRemove={() => setValue('proof_document', null)}
          />
          <TextareaInput
            name="comment"
            isDisabled={true}
            maxLength={200}
            minRows={5}
            label={_(msg`ملاحظات المنسق`)}
            placeholder={_(msg`تسجل ملاحظات المنسق هنا`)}
          />

          <div className="flex flex-col justify-start items-start w-full gap-[4px]">
            <RadioInput
              name="result"
              isDisabled={!enableEdit}
              label={_(msg`نتيجة المراجعة`)}
              options={[
                { value: 'approve', label: _(msg`قبول`) },
                { value: 'reject', label: _(msg`رفض`) }
              ]}
            />
          </div>
          {result == 'reject' && (
            <TextareaInput
              name="rejection_reason"
              isDisabled={!enableEdit}
              maxLength={200}
              minRows={5}
              label={_(msg`سبب الرفض`)}
              placeholder={_(msg`بإمكانك إضافة سبب الرفض هنا`)}
            />
          )}

          {enableEdit && (
            <div className="flex justify-end gap-4 flex-row items-center w-full pt-4">
              <ButtonVariant
                type="button"
                variant="secondary"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                endContent={<ArrowLeft />}
                onClick={handleSubmit(handleOpenModal)}
              >
                {_(msg`تأكيد نتيجة المراجعة`)}
              </ButtonVariant>
            </div>
          )}
        </form>
      </FormProvider>
      {openModalConfirm && (
        <ConfirmModule
          onClose={() => setOpenModal(false)}
          isSubmitting={isSubmitting}
          onConfirm={handleConfirm}
        />
      )}
    </div>
  )
}

export default ReviewRequestForm
