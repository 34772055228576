import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import Applications from 'pages/apps'
import StudentApplicationsList from 'pages/StudentApplications'

const Applications_: React.FC = () => {
  const { userType } = useSelector((state: RootState) => state.auth)

  const renderContent = () => {
    switch (userType) {
      case 'student':
        return <StudentApplicationsList />
      case 'teacher':
        return <Applications />
      case 'coordinator':
        return <Applications />
      //   case 'ithraa':
      //     return
      default:
        return <p>This is the default applications page</p>
    }
  }

  return <div className="h-full w-full">{renderContent()}</div>
}

export default Applications_
