import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { renderTableHeaderTh } from './tableHeaderGenerator'
import DataTable from 'components/data-table/data-table'
import { AppDispatch, RootState } from 'store'
import { useDispatch } from 'react-redux'
import LocalizedNameText from 'components/LocalizedNameText'
import { useLingui } from '@lingui/react'
import {
  fetchMoreReports,
  setExportSuccessMore
} from 'store/slices/reportsHeadCoordinatorMoreSlice'
import { detailsReport } from 'constants/reports'
import ModalLayout from 'components/modal/modal-layout'
import ReportHeader from '../ReportHeader/ReportHeader'
import { useSelector } from 'react-redux'
import Export from '../ModalExport/Export'
import ExportConfirmation from '../ModalExport/ExportConfirmation'
import { msg } from '@lingui/macro'

interface Params extends Record<string, string | undefined> {
  key: string
}
type ReportKeys = keyof typeof detailsReport

export default function ReportTableList() {
  const userType = useSelector((state: RootState) =>
    typeof state.auth.userType === 'string' ? state.auth.userType : null
  )
  const isIthraa = userType == 'ithraa'
  const [reportModal, setreportModal] = useState(false)
  const { competitionYear } = useSelector((state: RootState) => state.competitionYearNavbar)
  const [page, setpage] = useState(1)
  const { key } = useParams<Params>()
  const dispatch = useDispatch<AppDispatch>()
  const { _ } = useLingui()
  const [searchText, setSearchText] = useState('')

  const { exportSuccess, isLoading, pagination, dataMore } = useSelector(
    (state: RootState) => state.reportsHeadCoordinatorMoreSlice
  )
  const durationType = useSelector(
    (state: RootState) => state.reportsHeadCoordinatorFilter.durationType
  )
  const beginDate = useSelector((state: RootState) => state.reportsHeadCoordinatorFilter.beginDate)
  const endDate = useSelector((state: RootState) => state.reportsHeadCoordinatorFilter.endDate)
  const { i18n } = useLingui()
  const fetchReports = () => {
    dispatch(
      fetchMoreReports({
        competition_year_id: competitionYear?.id,
        keys: key ? [key] : [],
        page: page
      })
    )
  }
  useEffect(() => {
    fetchReports()
  }, [page])
  let data: any[] = []
  if (key && key in detailsReport) {
    const typedKey = key as ReportKeys
    data = detailsReport[typedKey]
  }

  const columns = renderTableHeaderTh(key as string, i18n.locale as any)
  const renderCell = (item: any, columnKey: string | number) => {
    if (
      columnKey === 'region_name' ||
      columnKey === 'education_administration_name' ||
      columnKey === 'university_name' ||
      columnKey === 'country_name'
    ) {
      return (
        <LocalizedNameText
          localizedName={item[columnKey] ?? ''}
          language={i18n.locale as any}
        ></LocalizedNameText>
      )
    } else if (columnKey === 'percentage') return <span>{item[columnKey] + '%'}</span>
    else return <span>{item[columnKey]}</span>
  }
  const handleExportModal = () => {
    setreportModal(!reportModal)
  }
  const onApplyFilter = () => {
    let prams =
      durationType + '' == 'custom'
        ? {
            competition_year_id: competitionYear?.id,
            submission_at_from: new Date(beginDate as string),
            submission_at_to: new Date(endDate as string),
            keys: key ? [key] : [],
            page: 1
          }
        : {
            competition_year_id: competitionYear?.id,
            keys: key ? [key] : [],
            page: 1
          }
    dispatch(fetchMoreReports(prams))
  }
  const onResetFilter = () => {
    fetchReports()
  }
  const onChagePage = (page: number) => {
    setpage(page)
  }
  const onBack = () => {
    dispatch(setExportSuccessMore(false))
  }
  useEffect(() => {
    if (exportSuccess) {
      setreportModal(false)
    }
  }, [exportSuccess])
  const mapMoreData = (dataMore: any) => {
    let data = dataMore

    if (
      (key === 'countryStagesApplications' || key == 'othersCountriesStagesApplications') &&
      Array.isArray(data)
    ) {
      data = data.map((item: any) => ({
        ...item,
        total_university: item.stages?.find((s: any) => s?.education_stage === 'university')
          ?.total_applications,
        total_general: item.stages?.find((s: any) => s?.education_stage === 'general')
          ?.total_applications
      }))
    }

    return data
  }
  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    let text = e.target.value
    setSearchText(text)
  }
  const tableData = Array.isArray(dataMore) ? mapMoreData(dataMore) : []
  const filteredReportsData = searchText
    ? tableData.filter((report: any) =>
        Object.values(report).some((value) =>
          value?.toString()?.toLowerCase().includes(searchText.toLowerCase())
        )
      )
    : tableData
  return (
    <div>
      <ReportHeader
        searchValue={searchText}
        onSearch={onSearch}
        hideFilter={isIthraa}
        onResetFilter={onResetFilter}
        onApplyFilter={onApplyFilter}
        searchable
        hideLastUpdate
        handleExportModal={handleExportModal}
      ></ReportHeader>
      <DataTable
        columns={columns}
        data={filteredReportsData ?? []}
        isLoading={isLoading}
        page={page as number}
        totalPages={pagination?.total_pages as number}
        onPageChange={onChagePage}
        renderCell={renderCell}
      ></DataTable>
      {reportModal && (
        <ModalLayout forOthersPage backdrop="opaque" onBack={handleExportModal}>
          <Export fixedReportType={key}></Export>
        </ModalLayout>
      )}
      {exportSuccess && (
        <ExportConfirmation
          onBack={onBack}
          title={_(msg` تصدير تقارير `)}
          subtitle={_(msg`تم إرسال التقارير إلى بريدك الإلكتروني`)}
        ></ExportConfirmation>
      )}{' '}
    </div>
  )
}
