import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { PendingApplicationIcon } from 'assets/icons/PendingApplicationIcon'
import ModalHeader from 'components/modal/modal-header'
import ModalLayout from 'components/modal/modal-layout'
import Typography from 'components/typography/typography'

function PendingApplicationModule() {
  const { _ } = useLingui()
  return (
    <ModalLayout
      showBackButton={false}
      coordinitorPageStyle={true}
      appStyle
      backdrop="opaque"
      header={
        <ModalHeader
          subheaderText={
            <div className="flex flex-col gap-2">
              <div className="mb-4 flex w-full justify-center">
                <PendingApplicationIcon />
              </div>
              <Typography as="div" weight="bold" size="xl" align="center">
                {_(msg`لازال طلبك تحت المراجعة`)}
              </Typography>
              <Typography as="div" align="center">
                {_(msg`سيراجع فريق المسابقة طلبك، وسوف تصلك رسالة البريد الإلكتروني بعد الموافقة.`)}
              </Typography>
            </div>
          }
        />
      }
    />
  )
}

export default PendingApplicationModule
