import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getRequestStatusFilterOptions,
  getRequestStatusFilterLabel
} from 'constants/requestStatusFilterOptions'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { setStatusFilter } from 'store/slices/activateRequestsSlice'
import useLanguage from 'hooks/useLanguage'
import { RootState } from 'store'
import { Select, SelectItem } from '@nextui-org/react'
const StatusFilter: React.FC = () => {
  const { _ } = useLingui()
  const dispatch = useDispatch()
  const language = useLanguage()
  const { status } = useSelector((state: RootState) => state.activateRequests)

  const handleSetStatus = (status: any) => {
    dispatch(setStatusFilter(status))
  }
  const options = getRequestStatusFilterOptions(language)
  const handelStatus = (keys: Selection) => {
    let selectedKeys: string[] = []
    if (keys instanceof Set) {
      selectedKeys = Array.from(keys) as string[]
    } else if (typeof keys === 'string') {
      selectedKeys = [keys]
    }
    handleSetStatus(selectedKeys[0])
  }
  return (
    <div className="flex flex-col gap-0.5">
      <p className="text-primary pb-2">{_(msg`"حالة الطلب"`)}</p>
      <Select
        selectedKeys={[status]}
        variant="bordered"
        color="primary"
        name=""
        onSelectionChange={handelStatus as any}
        placeholder={_(msg`"حالة الطلب"`)}
      >
        {options.map((option) => (
          <SelectItem key={option.value}>
            {getRequestStatusFilterLabel(option.value, language)}
          </SelectItem>
        ))}
      </Select>
    </div>
  )
}

export default StatusFilter
