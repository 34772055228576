import { Trans } from '@lingui/macro'
import ToolTip from 'assets/icons/ToolTip'
import React from 'react'
import img from 'assets/icons/trophy-dynamic-premium.svg'

const PromptMsg = () => {
  return (
    <div className="flex flex-col items-center gap-1 bg-natural_lv1_text_normal rounded-xl w-[160px] md:w-[235px] p-3">
      <div className="w-10 h-10">
        <img src={img} alt="trophy" />
      </div>
      <p className="font-paragraph-overline-h6-ar text-white text-center">
        <Trans>قم بمشاركة الرمز الخاص بك مع طلابك</Trans>
      </p>
      <div className="absolute -bottom-[10px] inset-x-auto text-natural_lv1_text_normal">
        <ToolTip fill="currentColor" />
      </div>
    </div>
  )
}

export default PromptMsg
