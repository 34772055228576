import React, { useEffect, useState, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Page404 from 'pages/Page404'
import { Loader } from 'rsuite'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

type Props = {
  children: ReactNode
  urse_roles: string[] | undefined
}

const UserGuard: React.FC<Props> = ({ children, urse_roles }) => {
  const { _ } = useLingui()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [checked, setChecked] = useState(false)
  const [authorized, setAuthorized] = useState(true)

  useEffect(() => {
    const userType = localStorage.getItem('userType')

    if (!userType) {
      navigate('/login')
    } else {
      if (urse_roles && urse_roles.includes(userType)) {
        setChecked(true)
        setAuthorized(true)
      } else {
        setAuthorized(false)
      }
    }
  }, [navigate, dispatch, urse_roles])

  if (!checked && authorized) {
    return <Loader />
  }

  if (!authorized) {
    return <Page404 errorMsg={_(msg`ليس لديك إذن الدخول إلى هذه الصفحة`)} />
  }

  return <>{children}</>
}

export default UserGuard
