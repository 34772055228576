import React from 'react'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import TabsNavigator from 'components/tabs-navigator/tabs-navigator'
import Points from 'pages/CoordinatorPages/CoordinatorPoints/tabs/PointsTab'
import CalculationMethod from 'pages/CoordinatorPages/CoordinatorPoints/tabs/CalculationMethodTab'

const CoordinatorPoints = () => {
  const { _ } = useLingui()
  const tabItems = [
    {
      key: 'points',
      title: _(msg`الجوائز`),
      content: <Points />
    },
    {
      key: 'calculation-methods',
      title: _(msg`طريقة حساب عدد الجوائز و المشاركات`),
      content: <CalculationMethod />
    }
  ]

  return (
    <div className="flex flex-col w-full">
      <TabsNavigator variant="underlined" color="success" items={tabItems} />
    </div>
  )
}

export default CoordinatorPoints
