import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import TabsNavigator from 'components/tabs-navigator/tabs-navigator'
import HistoryChanges from 'pages/CompetitionDetails/HistoryChanges'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import { fetchCompetitionYearDetails } from 'store/slices/competitionYearDetails'
import { fetchWorkflowDetail } from 'store/slices/workflowDetailsSlice'
import UpdateWorkflow from './UpdateWorkflow'
import WorkflowApps from './WorkflowApps'
import WorkFlowFiltration from './WorkFlowsFilteration/WorkFlowFiltration'

export const WorkFlowsDetails: FC = () => {
  const { year, id } = useParams<{ year: string; id: string }>()
  const dispatch = useDispatch<AppDispatch>()
  const workflowDetail = useSelector((state: RootState) => state.workflowDetails.workflowDetail)
  const competition = useSelector(
    (state: RootState) => state.competitionYearDetails.competitionYearDetails
  )
  const isWorkflowLoading = useSelector((state: RootState) => state.workflowDetails.isLoading)
  const isCompetitionYearLoading = useSelector(
    (state: RootState) => state.competitionYearDetails.isLoading
  )
  const isLoading = isWorkflowLoading || isCompetitionYearLoading
  const { _ } = useLingui()

  const tabs = [
    {
      key: 'info',
      title: _(msg`معلومات سير المشاركات`),
      isLoading,
      content: <UpdateWorkflow competition={competition} workflow={workflowDetail} />
    },
    {
      key: 'filter',
      title: _(msg`معاير التصفية`),
      isLoading,
      content: (
        <WorkFlowFiltration
          competition={competition}
          workflow={workflowDetail}
        ></WorkFlowFiltration>
      )
    },
    {
      key: 'workflows',
      title: _(msg`المشاركات`),
      isLoading,
      content: <WorkflowApps />
    },
    {
      key: 'names',
      title: _(msg`أسماء المراحل العامة`),
      isLoading,
      content: <div className="mt-5">{_(msg`معاير التصفية`)}</div>
    },
    {
      key: 'steps',
      title: _(msg`المراحل`),
      isLoading,
      content: <div className="mt-5">{_(msg`المراحل`)}</div>
    },
    {
      key: 'histories',
      title: _(msg`سجل التغيبرات`),
      isLoading,
      content: <HistoryChanges activities={workflowDetail?.activities!} />
    }
  ]

  useEffect(() => {
    if (year && id) {
      dispatch(fetchWorkflowDetail({ competitionId: Number(year), workflowId: Number(id) }))
      dispatch(fetchCompetitionYearDetails({ id: year }))
    }
  }, [dispatch, year, id])

  return (
    <div className="flex flex-col w-full gap-4">
      <TabsNavigator variant="underlined" color="primary" items={tabs} />
    </div>
  )
}

export default WorkFlowsDetails
