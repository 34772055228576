import { FaUserPlus, FaCheckCircle, FaComments, FaUsers, FaTrophy } from 'react-icons/fa'
import { msg } from '@lingui/macro'

export interface Step {
  title: string
  icon: JSX.Element
  component?: JSX.Element
  fields?: string[]
}

export const steps: Step[] = [
  {
    title: msg`التسجيل`.id,
    icon: <FaUserPlus /> // Registration
  },
  {
    title: msg`التصفيات الأولية`.id,
    icon: <FaCheckCircle /> // Initial Screening
  },
  {
    title: msg`المقابلات الشخصية`.id,
    icon: <FaComments /> // Personal Interviews
  },
  {
    title: msg`الملتقى`.id,
    icon: <FaUsers /> // Forum
  },
  {
    title: msg`الحفل`.id,
    icon: <FaTrophy /> // Ceremony
  }
]
