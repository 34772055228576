import React from 'react';

const Dash: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path d="M3.83398 7.3335H13.1673V8.66683H3.83398V7.3335Z" fill="#F9FAFB" />
    </svg>
  );
};

export default Dash;
