import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useLanguage from 'hooks/useLanguage'
import { AppDispatch, RootState } from 'store'
import { fetchWorkflows } from 'store/slices/workFlowSlice'
import { Workflow } from 'api/types'
const useWorkflows = (competitionId: number) => {
  const dispatch = useDispatch<AppDispatch>()
  const { workflows, isLoading, error } = useSelector((state: RootState) => state.workflows)
  const memoizedCompetitionId = useMemo(() => competitionId, [competitionId])
  useEffect(() => {
    if (memoizedCompetitionId) {
      dispatch(
        fetchWorkflows({
          competitionId: memoizedCompetitionId
        })
      )
    }
  }, [dispatch, memoizedCompetitionId])

  return { workflows, isLoading, error }
}

export const useWorkflowOptions = (competitionId: number) => {
  const language = useLanguage() as 'ar' | 'en'
  const { workflows, isLoading, error } = useWorkflows(competitionId)

  const options = useMemo(() => {
    if (workflows?.length === 0) {
      return [
        {
          key: '',
          label: language === 'ar' ? 'لا يوجد بيانات للاختيار' : 'No options available'
        }
      ]
    } else {
      return workflows?.map((workflow: Workflow) => ({
        key: String(workflow.name),
        label: workflow.name
      }))
    }
  }, [workflows, language])

  return { options, isLoading, error }
}
