import React from 'react'

interface StepperProps {
  steps:
    | {
        borderColor: string
        bgColor: string
        icon: React.ReactNode
        status: 'coordinator.submit' | 'coordinator.approve' | 'coordinator.reject'
      }[]
    | undefined
}

const VerticalStepper: React.FC<StepperProps> = ({ steps }) => {
  return (
    <div className="flex flex-col items-center">
      {steps?.map((step, index) => (
        <div key={index} className="flex flex-col items-center">
          <div
            className={`flex items-center justify-center w-10 h-10 rounded-full border-2 ${step.bgColor} ${step.borderColor}`}
          >
            {step.icon}
          </div>

          {index < steps.length - 1 && (
            <div className={`w-[2px]  bg-gray-300 h-[100px] mt-2 mb-2`} />
          )}
        </div>
      ))}
    </div>
  )
}

export default VerticalStepper
