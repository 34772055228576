import React from 'react'

interface InformationIconProps {
  fill?: string
  size?: string
}

const InformationIcon: React.FC<InformationIconProps> = ({ size = '18px', fill = '#3F3F46' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M8.0026 14.6663C4.3206 14.6663 1.33594 11.6817 1.33594 7.99967C1.33594 4.31767 4.3206 1.33301 8.0026 1.33301C11.6846 1.33301 14.6693 4.31767 14.6693 7.99967C14.6693 11.6817 11.6846 14.6663 8.0026 14.6663ZM7.33594 7.33301V11.333H8.66927V7.33301H7.33594ZM7.33594 4.66634V5.99967H8.66927V4.66634H7.33594Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default InformationIcon