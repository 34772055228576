import { msg } from '@lingui/macro'

export const awardsList = [
  {
    id: 1,
    title: msg`مشاركة نسبة قدرها 2٪ من إجمالي عدد الطلاب.`.id,
    awards: [msg`شهادة تقديرية`.id, msg`خصم 20٪ على جميع دورات اكاديمية إثراء`.id]
  },
  {
    id: 2,
    title: msg`مشاركة نسبة قدرها 5٪ من إجمالي عدد الطلاب.`.id,
    awards: [
      msg`شهادة تقديرية`.id,
      msg`خصم 20٪ على جميع دورات اكاديمية إثراء`.id,
      msg`5 آلاف ريال`.id
    ]
  },
  {
    id: 3,
    title: msg`مشاركة نسبة قدرها 10٪ من إجمالي عدد الطلاب.`.id,
    awards: [
      msg`خصم 20٪ على جميع دورات اكاديمية إثراء`.id,
      msg`5 آلاف ريال`.id,
      msg`تغطية مدفوعة التكاليف لحضور حفل إثراء`.id
    ]
  }
]
