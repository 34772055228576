import React from 'react'

interface props {
  fill?: string
  size?: string
}

const InformationIconOutline: React.FC<props> = ({ size = '18px', fill = 'white' }) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5026 14.6668C4.8206 14.6668 1.83594 11.6822 1.83594 8.00016C1.83594 4.31816 4.8206 1.3335 8.5026 1.3335C12.1846 1.3335 15.1693 4.31816 15.1693 8.00016C15.1693 11.6822 12.1846 14.6668 8.5026 14.6668ZM8.5026 13.3335C9.91709 13.3335 11.2736 12.7716 12.2738 11.7714C13.274 10.7712 13.8359 9.41465 13.8359 8.00016C13.8359 6.58567 13.274 5.22912 12.2738 4.22893C11.2736 3.22873 9.91709 2.66683 8.5026 2.66683C7.08812 2.66683 5.73156 3.22873 4.73137 4.22893C3.73117 5.22912 3.16927 6.58567 3.16927 8.00016C3.16927 9.41465 3.73117 10.7712 4.73137 11.7714C5.73156 12.7716 7.08812 13.3335 8.5026 13.3335ZM7.83594 10.0002H9.16927V11.3335H7.83594V10.0002ZM7.83594 4.66683H9.16927V8.66683H7.83594V4.66683Z"
        fill={fill}
      />
    </svg>
  )
}

export default InformationIconOutline
