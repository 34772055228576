import React, { forwardRef } from 'react'
import Typography from 'components/typography/typography'

interface OtpInputFieldProps {
  name: string
  maxLength: number
  type: string
  value: string
  error?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void
}

const OtpInputField = forwardRef<HTMLInputElement, OtpInputFieldProps>(
  ({ name, maxLength, type, value, error, onChange, onKeyDown, onFocus }, ref) => {
    return (
      <div className="flex flex-col items-center mt-2" dir="ltr">
        <input
          ref={ref}
          name={name}
          maxLength={maxLength}
          type={type}
          inputMode="numeric"
          pattern="[0-9]*"
          dir="ltr"
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          className={`w-16 h-16 text-2xl font-bold text-center leading-none rounded-lg shadow-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary ${error ? 'border-red-500' : ''}`}
        />
        {error && <Typography className="text-red-500 text-sm mt-1">{error}</Typography>}
      </div>
    )
  }
)

export default OtpInputField
