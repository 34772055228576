import React from 'react'
import ModalHeader from 'components/modal/modal-header'
import ModalLayout from 'components/modal/modal-layout'
import { Trans } from '@lingui/macro'
import { msg } from '@lingui/macro'
import ButtonVariant from 'components/button-variant/button'

interface SubmitConfirmModalProps {
  onConfirm: () => void
  onCancel: () => void
}

const SubmitConfirmModal: React.FC<SubmitConfirmModalProps> = ({
  onConfirm,
  onCancel
}) => {
  return (
    <ModalLayout
      onBack={onCancel}
      accountPageStyle
      header={
        <ModalHeader
          headerText={msg`تقديم نموذج المشاركة`}
          subheaderText={
            <p>
              <Trans>
                في حالة تقديمك للنموذج سيتم مراجعته من قبل المسئولين وابلاغك بالنتيجة الخاصة
                بالنموذج الخاص بك.
              </Trans>
            </p>
          }
        />
      }
      footer={
        <>
          <div className="flex items-center gap-2">
            <ButtonVariant onClick={onCancel} variant="bordered">
              <Trans>إلغاء</Trans>
            </ButtonVariant>
            <ButtonVariant onClick={onConfirm} variant="secondary">
              <Trans>تقديم النموذج</Trans>
            </ButtonVariant>
          </div>
        </>
      }
    />
  )
}

export default SubmitConfirmModal
