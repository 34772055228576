import React from 'react'

interface PhoneIconProps {
  fill?: string
}

const PhoneIcon: React.FC<PhoneIconProps> = ({ fill = '#A1A1AA' }) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.744 7.12133C7.36956 8.22032 8.27968 9.13044 9.37867 9.756L9.968 8.93067C10.0628 8.79796 10.2029 8.70459 10.3619 8.66822C10.5208 8.63186 10.6876 8.65502 10.8307 8.73333C11.7735 9.24862 12.8148 9.55852 13.886 9.64267C14.0532 9.65592 14.2092 9.73169 14.323 9.85488C14.4368 9.97807 14.5 10.1396 14.5 10.3073V13.282C14.5 13.4471 14.4388 13.6063 14.3282 13.7288C14.2177 13.8513 14.0655 13.9285 13.9013 13.9453C13.548 13.982 13.192 14 12.8333 14C7.12667 14 2.5 9.37333 2.5 3.66667C2.5 3.308 2.518 2.952 2.55467 2.59867C2.5715 2.43446 2.64866 2.28235 2.77119 2.17176C2.89373 2.06116 3.05294 1.99996 3.218 2H6.19267C6.36037 1.99998 6.52193 2.06317 6.64512 2.17697C6.76831 2.29077 6.84408 2.44682 6.85733 2.614C6.94148 3.68519 7.25138 4.72645 7.76667 5.66933C7.84498 5.81238 7.86814 5.97916 7.83178 6.13813C7.79541 6.2971 7.70204 6.43723 7.56933 6.532L6.744 7.12133ZM5.06267 6.68333L6.32933 5.77867C5.96986 5.00273 5.72357 4.17923 5.598 3.33333H3.84C3.836 3.444 3.834 3.55533 3.834 3.66667C3.83333 8.63733 7.86267 12.6667 12.8333 12.6667C12.9447 12.6667 13.056 12.6647 13.1667 12.66V10.902C12.3208 10.7764 11.4973 10.5301 10.7213 10.1707L9.81667 11.4373C9.45244 11.2958 9.09867 11.1287 8.758 10.9373L8.71933 10.9153C7.41172 10.1711 6.32885 9.08828 5.58467 7.78067L5.56267 7.742C5.37127 7.40133 5.20419 7.04756 5.06267 6.68333Z"
        fill={fill}
      />
    </svg>
  )
}

export default PhoneIcon