import TabsNavigator from 'components/tabs-navigator/tabs-navigator'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { FormDetails } from './tabs/FormDetails'

const IdelApplicationDetails = () => {
  const { _ } = useLingui()
  const tabItems = [
    {
      key: 'application-details',
      title: _(msg`نموذج  المشاركة`),
      content: <FormDetails idle />
    }
  ]
  return (
    <>
      <div className="flex flex-col size-full">
        <TabsNavigator variant="underlined" color="success" items={tabItems} />
      </div>
    </>
  )
}

export default IdelApplicationDetails
