import React from 'react'
import { Trans, useLingui } from '@lingui/react'
import { MessageDescriptor } from '@lingui/core'

interface LinkProps {
  placeholder: MessageDescriptor | string // Allow both MessageDescriptor and string
  href?: string
  className?: string
  fontWeight?: 'normal' | 'medium' | 'bold' // Dynamic font weight prop
  spacing?: 'space-y-1' | 'space-y-2' | 'space-y-3' | 'space-y-4' // Dynamic spacing prop
  paddingX?: 'px-0' | 'px-1' | 'px-1.5' | 'px-2' | 'px-2.5' | 'px-3' // Dynamic padding prop
  onClick?: (event: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>) => void
}

const Link: React.FC<LinkProps> = ({
  placeholder,
  href,
  className,
  fontWeight = 'medium',
  spacing = 'space-y-2',
  paddingX = 'px-1.5', // Default padding
  onClick
}) => {
  const WrapperTag = href ? 'a' : 'div'
  const { _, i18n } = useLingui()

  const fontClass =
    fontWeight === 'bold' ? 'font-bold' : fontWeight === 'medium' ? 'font-medium' : 'font-normal'

  return (
    <WrapperTag
      href={href || undefined}
      onClick={onClick || undefined}
      className={`text-lg flex flex-col group ${spacing} cursor-pointer ${className}`}
    >
      <h5 className={`font-ithra ${fontClass} ${paddingX}`}>
        {typeof placeholder === 'string' ? placeholder : <Trans id={placeholder.id} />}
      </h5>
      <div
        className={` ${i18n.locale === 'ar' ? 'right-0' : 'left-0'} bottom-[-7px] w-0 h-1 bg-customTealLv2 transition-all duration-500 ease-in-out group-hover:w-full rounded-full`}
      ></div>
    </WrapperTag>
  )
}

export default Link
