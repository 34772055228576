import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { UniversitiesResponse, University } from 'api/types'

interface RegisterState {
  universities: University[]
  isLoading: boolean
  error: string | null
  regionsIds: string[]
}

const initialState: RegisterState = {
  universities: [],
  isLoading: false,
  error: null,
  regionsIds: []
}

export const fetchUniversities = createAsyncThunk(
  'universities',
  async (params: { regionsIds?: string[]; countryId?: string; countryCode?: string }) => {
    const { regionsIds = [], countryId, countryCode } = params

    const paramsObj = {
      regions: regionsIds,
      country_id: countryId,
      country_code: countryCode
    }

    const response = await axiosInstance.get<UniversitiesResponse>('/universities', {
      params: {
        ...paramsObj
      }
    })
    return response.data
  }
)

const universitiesSlice = createSlice({
  name: 'universities',
  initialState,
  reducers: {
    setRegionsIds(state, action: PayloadAction<string[]>) {
      state.regionsIds = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUniversities.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchUniversities.fulfilled, (state, action) => {
        state.isLoading = false
        const universities = action.payload.data ?? []
        state.universities = universities
      })
      .addCase(fetchUniversities.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export const { setRegionsIds } = universitiesSlice.actions

export default universitiesSlice
