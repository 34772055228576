import HowToCalc from 'pages/CoordinatorPages/CoordinatorPoints/components/HowToCalc'
import React from 'react'
import MetricCard from 'components/card/MetricCardLayout'
import NumericCard from 'components/card/NumericCard'
import { msg } from '@lingui/macro'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

const CalculationMethod = () => {
  const { totalStudents } = useSelector((state: RootState) => state.coordinatorStats)
  return (
    <div className="w-full flex flex-col gap-6">
      
      <MetricCard color="bg-natural_lv1_text_normal" className="w-full max-w-[337px]">
        <NumericCard
          title={msg`عدد الطلاب الكلي في تعليم العام الذي يخضع لإدارتك التعليمية:`.id}
          numericValue={totalStudents}
          color="text-natural_lv1_text_normal"
          unit={msg`طالب`.id}
          emptyMsg={msg`حتى الآن، لم يتم تسجيل اي طالب بعد.`.id}
        />
      </MetricCard>

      <HowToCalc totalStudents={totalStudents} />
    </div>
  )
}

export default CalculationMethod
