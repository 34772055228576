import { WinnersList } from 'pages/landing/models'
// import img1 from 'assets/images/winner-1.png'
// import img2 from 'assets/images/winner-2.png'
// import img3 from 'assets/images/winner-avtar-female.png'
// import img4 from 'assets/images/winner-avtar-male.png'

//2013
import fahad2013 from 'assets/images/winners/fahad2013.png'
import moh2013 from 'assets/images/winners/moh2013.png'
import nourah_2013 from 'assets/images/winners/nourah2013.png'

//2014
import mor2014 from 'assets/images/winners/mor2014.png'
import areej2014 from 'assets/images/winners/areej2014.jpg'
import amjad2014 from 'assets/images/winners/amjad2014.jpg'

//2015
import shro2015 from 'assets/images/winners/shro2015.png'
import abd2015 from 'assets/images/winners/abd2015.png'
import ali2015 from 'assets/images/winners/ali2015.png'

//2016
import shro2016 from 'assets/images/winners/shro2016.jpg'
import tala2016 from 'assets/images/winners/tala2016.jpg'
import aseed2016 from 'assets/images/winners/aseed2016.jpg'
import amjad2016 from 'assets/images/winners/amjad2016.jpg'

//2017
import tasneem2017 from 'assets/images/winners/tasneem2017.jpg'
import sami2017 from 'assets/images/winners/sami2017.jpg'
import kholud2017 from 'assets/images/winners/kholud2017.jpg'
import abd2017 from 'assets/images/winners/abd2017.jpg'

//2020
import jenan2020 from 'assets/images/winners/jenan2020.png'
import kha2020 from 'assets/images/winners/kha2020.png'
import khadeja2020 from 'assets/images/winners/khadeja2020.png'
import layan2020 from 'assets/images/winners/layan2020.png'

//2021
import zainab2021 from 'assets/images/winners/zainab2021.png'
import moh2021 from 'assets/images/winners/moh2021.png'
import shah2021 from 'assets/images/winners/shah2021.png'

//2023
import balqees2023 from 'assets/images/winners/balqees2023.png'
import zain2023 from 'assets/images/winners/zain2023.png'
import sofyan2023 from 'assets/images/winners/sofyan2023.png'

//other
import femaleWinner from 'assets/images/winners/winner-avtar-female.png'

import { msg } from '@lingui/macro'

const winnersList: WinnersList = {
  2023: [
    {
      name: msg`زين العابدين`,
      quote: msg`قارئ العام باختيار لجنة التحكيم`,
      avatar: zain2023
    },
    {
      name: msg`سفيان البراق`,
      quote: msg`قارئ عام الملتقى`,
      avatar: sofyan2023
    },
    {
      name: msg`بلقيس الصولان`,
      quote: msg`تصويت الجمهور`,
      avatar: balqees2023
    }
  ],
  2022: [
    {
      name: msg`محمد الخليفة`,
      quote: msg`اختيار لجنة التحكيم`,
      avatar: moh2021
    },
    {
      name: msg`زينب الناصر`,
      quote: msg`التعليم العام`,
      avatar: zainab2021
    },
    {
      name: msg`شهد القيصوم`,
      quote: msg`تصويت الجمهور`,
      avatar: shah2021
    }
  ],
  2020: [
    {
      name: msg`خالد الفيفي`,
      quote: msg`المرحلة الجامعية`,
      avatar: kha2020
    },
    {
      name: msg`جنان نعمة`,
      quote: msg`المرحلة الثانوية`,
      avatar: jenan2020
    },
    {
      name: msg`خديجة آل سالم`,
      quote: msg`المرحلة الابتدائية والمتوسطة`,
      avatar: khadeja2020
    },
    {
      name: msg`ليان الغامدي`,
      quote: msg`تصويت الجمهور`,
      avatar: layan2020
    }
  ],
  2018: [
    {
      name: msg`خلود الدباسي`,
      quote: msg`المرحلة الجامعية`,
      avatar: kholud2017
    },
    {
      name: msg`تسنيم عبدالمنعم`,
      quote: msg`المرحلة الثانوية`,
      avatar: tasneem2017
    },
    {
      name: msg`عبدالله الممتن`,
      quote: msg`المرحلة الابتدائية والمتوسطة`,
      avatar: abd2017
    },
    {
      name: msg`سامي البطاطي`,
      quote: msg`تصويت الجمهور`,
      avatar: sami2017
    }
  ],
  2016: [
    {
      name: msg`شروق الزهراني`,
      quote: msg`المرحلة الجامعية`,
      avatar: shro2016
    },
    {
      name: msg`أسيد عاجز`,
      quote: msg`المرحلة الثانوية`,
      avatar: aseed2016
    },
    {
      name: msg`أمجاد الغامدي`,
      quote: msg`المرحلة الثانوية`,
      avatar: amjad2016
    },
    {
      name: msg`سارة الرحيلي`,
      quote: msg`تصويت الجمهور`,
      avatar: femaleWinner
    },
    {
      name: msg`تالا الملا`,
      quote: msg`المرحلة الابتدائية والمتوسطة`,
      avatar: tala2016
    }
  ],
  2015: [
    {
      name: msg`عبدالله المشوح`,
      quote: msg`المرحلة الجامعية`,
      avatar: abd2015
    },
    {
      name: msg`شروق شخشير`,
      quote: msg`التعليم العام`,
      avatar: shro2015
    },
    {
      name: msg`علي السليس`,
      quote: msg`تصويت الجمهور`,
      avatar: ali2015
    }
  ],
  2014: [
    {
      name: msg`مرتضى البحراني`,
      quote: msg`المرحلة الجامعية`,
      avatar: mor2014
    },
    {
      name: msg`أمجاد المكي`,
      quote: msg`المرحلة الثانوية`,
      avatar: amjad2014
    },
    {
      name: msg`أريج القرني`,
      quote: msg`المرحلة الابتدائية والمتوسطة`,
      avatar: areej2014
    }
  ],
  2013: [
    {
      name: msg`فهد المشرف`,
      quote: msg`المرحلة الجامعية`,
      avatar: fahad2013
    },
    {
      name: msg`محمد الجغيمان`,
      quote: msg`المرحلة الثانوية`,
      avatar: moh2013
    },
    {
      name: msg`نورة الجعفر`,
      quote: msg`المرحلة الابتدائية والمتوسطة`,
      avatar: nourah_2013
    }
  ]
}
export default winnersList
