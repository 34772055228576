import { FaqCategory } from 'pages/landing/models'
import { msg } from '@lingui/macro'

const faqData: FaqCategory[] = [
  {
    category: msg`شروط المسابقة`,
    questions: [
      {
        question: msg`الشروط والأحكام العامة`,
        answer: [
          msg`الشروط الواردة أدناه تتضمن شروط وأحكام مسارات مسابقة أقرأ، والتي سيطلق عليها لفظ "المسابقة"، حيث أن الاشتراك في المسابقة يعني الالتزام التام بهذه الشروط والأحكام وما يترتّب عليها.`,
          msg`هذه المسابقة من تنظيم مركز الملك عبدالعزيز الثقافي العالمي (إثراء).`,
          msg`يحتفظ المنظمون بحق إلغاء أو تمديد أو تعديل مجريات المسابقة أو شروطها أو أحكامها من دون إشعار مسبق.`,
          msg`تخضع كل المشاركات المقدمة في المسابقة للمراجعة وذلك للتأكد من مدى توافقها مع الشروط والأحكام.`,
          msg`على جميع المشاركين الالتزام بمواعيد بدء وانتهاء المدة المحددة لتقديم المشاركات في جميع المراحل.`,
          msg`يجب على المشترك الإقرار بأنه قد اطلع ووافق على شروط وأحكام المسابقة قبل الاشتراك فيها.`,
          msg`سيتم إلغاء أي مشاركة إذا ثبت عدم صحة المعلومات الواردة فيها، وإن ثبت ذلك بعد الترشيح لسبب من الأسباب، فسيُتخذ إجراء استبعاد المشارك المعني وترشيح مشارك بديل.`,
          msg`يحق للمنظمين عدم منح الجائزة لمتسابق فائز واختيار فائز آخر في حال أخلّ بأي من شروط وأحكام المسابقة.`,
          msg`يجب على المشترك التحلّي بالمصداقية التامة في جميع التفاصيل الخاصة بالمشاركة التي يضعها في نموذج المشاركة.`,
          msg`يجب على المشارك ضمان أن تكون جميع المعلومات المقدمة من قبله في هذه المسابقة صحيحة وحديثة وكاملة حتى يتجنب استبعاد اسمه.`,
          msg`للمنظمين الحق في نشر ما يرونه مناسبًا من المعلومات المدخلة في نموذج التسليم عن المشاركين في حال الترشح أو الفوز بإحدى الجوائز.`
        ]
      },
      {
        question: msg`الملكية الفكرية`,
        answer: [
          msg`يوافق أصحاب المشاركات أن يقوم فريق العمل أو أي طرف ثالث يتم تفويضه بنشر هذه المشاركات على الموقع أو في أي وسيلة إعلامية أخرى سواءً المعروفة حاليًا أم ناشئة مستقبلًا؛ كالمواقع الإلكترونية، أو القنوات الفضائية، أو عن طريق الهاتف الجوال، كما يمكن استخدامها في إطار التصويت/ الأنشطة الترويجية/ الإعلانات/ الدعاية الإعلانية.`,
          msg`يوافق المشارك على منح فريق العمل أو أي طرف ثالث يتم تفويضه، ترخيصًا غير حصري ولا رجوع فيه بعرض أو نشر أو نقل المشاركة المقدمة إلكترونيًا دون تغيير أو تعديل المشاركة أو تخزينها أو إعادة إخراجها بشكل أو بآخر أو ترخيصها للغير في سبيل نشره إعلاميًا بناءً على الترخيص الأول لهذه الغايات.`,
          msg`يجب أن تكون المشاركة بجميع تفاصيلها من عمل المتقدم شخصيًا، بحيث يقرّ بأن كل المعلومات التي تقدم بها للمسابقة لا تنتهك حقوق الملكية الفكرية أو الحقوق الخاصة والخصوصية أو غيرها من الحقوق لأي طرف ثالث، وأنها لا تتضمن إساءة أو تعدًّ أو قدح أو ذم أو تهديد أو ازدراء أو غير ذلك من المحظورات، ويحتفظ فريق العمل بحق رفض نشر أي مشاركة أو إقصائها دون الرجوع للمشترك في حال تعارضها مع أحد شروط المسابقة.`,
          msg`يقرّ المتسابق بموافقته على قيام فريق العمل بتصوير حملات دعائية، واستخدام المشاركات الفائزة وأسماء الفائزين في جميع الوسائل الإعلانية المتاحة.`,
          msg`الجهة المنظمة غير مسؤولة عن كافة الإجراءات القانونية اتجاه حقوق الملكية الفكرية على كافة الأصعدة أمام الجهات الرسمية.`
        ]
      },
      {
        question: msg`المسؤولية القانونية`,
        answer: [
          msg`لا يتحمل المنظمون مسؤولية أي مشاكل أو أعطال تقنية أو أي أضرار معنوية أو حسية أو مادية للمشترك تأتي نتيجة لتنفيذ عمل بهدف الاشتراك في المسابقة.`,
          msg`يحق للمسابقة استخدام اسم المشارك وصوره الضوئية وأي تصريحات شفوية أو مقاطع فيديو تخص المشارك للإعلان في الوسائط الإعلامية المختلفة، وفي وسائل التواصل الاجتماعي بأنواعها دون أي إخطار مسبق.`
        ]
      }
      // {
      //   question: msg`شروط مسار قارئ العام`,
      //   answer: []
      // },
      // {
      //   question: msg`شروط مسار سفراء القراءة`,
      //   answer: []
      // },
      // {
      //   question: msg`شروط المدرسة / الجامعة القارئة`,
      //   answer: []
      // }
    ]
  },
  {
    category: msg`مسار قارئ العام`,
    questions: [
      {
        question: msg`ما هو آخر موعد للمشاركة في المسابقة؟`,
        answer: [
          msg`التسجيل في النسخة التاسعة من المسابقة متاح حتى نهاية يوم السبت (15 فبراير 2025)، علمًا بأن الموعد النهائي للتسجيل في المسابقة في تمام الساعة 11:59 مساءً بتوقيت السعودية.`
        ]
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: [
          msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة (iReadAward.com) بعد إنشاء حساب في الموقع. ستجد على صفحتك في موقع المسابقة رمزًا خاصًا بك لمشاركته مع طلابك.`
        ]
      },
      {
        question: msg`أين ستقام المسابقة؟`,
        answer: [
          msg`يتم استقبال المشاركات عن طريق الموقع الإلكتروني، وبعد التصفيات الأولية، ستُعقد المقابلات الشخصية افتراضيًا على منصة "زوم" للمرحلة الابتدائية والمتوسطة، وستُعقد مقابلات المرحلة الثانوية والجامعية افتراضيًا على منصة "زوم" ثم حضوريًا في مدن مختلفة في المملكة العربية السعودية، وسينعقد كلٌ من الملتقى الإثرائي والحفل الختامي في مركز الملك عبدالعزيز الثقافي العالمي (إثراء) بمدينة الظهران في السعودية.`
        ]
      },
      {
        question: msg`كيف يمكنني التأكّد من وصول المشاركة؟`,
        answer: [
          msg`ستصلك رسالة على بريدك الإلكتروني مباشرة بعد تسليم المشاركة، وستجد مشاركتك على صفحتك في موقع المسابقة مع حالة المشاركة.`
        ]
      },
      {
        question: msg`هل يمكنني المشاركة في ملتقى أقرأ الإثرائي كطالب؟`,
        answer: [
          msg`المسابقة مقدمة للطلّاب والطالبات، وقد راعينا اختيار توقيت الملتقى الإثرائي بالتزامن مع الإجازة الصيفية للطلاب في كافة الدول العربية، علمًا بأنه في حال تعارض وقت الملتقى مع الأيام الدراسية سيقوم البرنامج بإرسال خطاب تفرّغ للجامعة أو المدرسة بعد تأهل المشارك.`
        ]
      },
      {
        question: msg`هل أستطيع التقدم للمسابقة وقد سبق لي الترشيح في السنة الماضية؟`,
        answer: [msg`نعم، ويستثنى من ذلك من تأهل سابقًا لملتقى أقرأ الإثرائي.`]
      },
      {
        question: msg`ما هي الفئة العمرية المستهدفة للمسابقة؟`,
        answer: [
          msg`المسابقة متاحة لجميع طلّاب وطالبات المرحلة الابتدائية العليا والمتوسطة والثانوية والجامعية حتى سن الخامسة والعشرين`
        ]
      },
      {
        question: msg`هل أستطيع المشاركة بكتاب تم نشره باللغة الإنجليزية؟`,
        answer: [msg`نعم، ولكن يجب أن تُكتب المراجعة باللغة العربية.`]
      },
      {
        question: msg`هل المسابقة للسعوديين فقط؟`,
        answer: [
          msg`المسابقة متاحة لجميع الطلاب والطالبات من المرحلة الثانوية والجامعية في العالم العربي، أما المرحلة الابتدائية والمتوسطة فالمشاركة متاحة للسعوديين والمقيميين في المملكة فقط حاليًا.`
        ]
      },
      {
        question: msg`هل يمكنني المشاركة بمراجعة سبق وأن نشرتها؟`,
        answer: [msg`نعم، يشترط أن تكون المراجعة غير منشورة مُسبقًا في أي مكان آخر.`]
      },
      {
        question: msg`هل أستطيع المشاركة بأكثر من مشاركة واحدة؟`,
        answer: [
          msg`لا يُسمح لك بأكثر من مشاركة واحدة فقط في كل سنة، ويمكنك تعديل المشاركة حتى نهاية موعد التسجيل.`
        ]
      },
      {
        question: msg`كيف يتم إبلاغي في حال ترشحي؟`,
        answer: [
          msg`سيتم التواصل معك عبر البريد الإلكتروني، وسيتم تحديث حالة مشاركتك على صفحتك في موقع المسابقة.`
        ]
      },
      {
        question: msg`متى سيتم الإعلان عن المرشحين؟`,
        answer: [msg`يتم إبلاغ جميع المرشحين فور الانتهاء من كل مرحلة.`]
      },
      {
        question: msg`هل توجد جوائز للفائزين في المسابقة؟`,
        answer: [
          msg`نعم، تُمنح جوائز عينية ونقدية للمتأهلين إلى الحفل الختامي والفائزين في المسابقة.`
        ]
      },
      {
        question: msg`متى سيكون موعد الحفل الختامي للمسابقة؟`,
        answer: [msg`سيكون الحفل الختامي في شهر أكتوبر 2025.`]
      },
      {
        question: msg`هل تتحمل المسابقة تكاليف السفر والضيافة؟`,
        answer: [
          msg`نعم، تتحمل المسابقة جميع التكاليف المتعلقة بحضور المشارك لأي مرحلة في المسابقة، وتشمل تذاكر السفر والمواصلات والإقامة الفندقية وجميع الوجبات.`
        ]
      },
      {
        question: msg`هل تقوم المسابقة بتحمل تكاليف مرافق للمشاركين؟`,
        answer: [
          msg`نعم، تتحمل المسابقة جميع التكاليف المتعلقة بحضور المشارك لأي مرحلة في المسابقة، وتشمل تذاكر السفر والمواصلات والإقامة الفندقية وجميع الوجبات.`
        ]
      },
      {
        question: msg`هل ستقوم المسابقة باستخراج تأشيرات الدخول للمشاركين من خارج السعودية؟`,
        answer: [
          msg`نعم، تتكفّل المسابقة بجميع إجراءات وتكاليف إصدار تأشيرة الدخول للمتأهلين من خارج السعودية.`
        ]
      }
    ]
  },
  {
    category: msg`مسار سفراء القراءة`,
    questions: [
      {
        question: msg`ما هو آخر موعد للمشاركة في المسابقة؟`,
        answer: [
          msg`التسجيل في النسخة التاسعة من المسابقة متاح حتى نهاية يوم السبت (15 فبراير 2025)، علمًا بأن الموعد النهائي للتسجيل في المسابقة في تمام الساعة 11:59 مساءً بتوقيت السعودية.`
        ]
      },
      {
        question: msg`من يحق له المشاركة في هذا المسار؟`,
        answer: [
          msg`حق المشاركة لجميع المعلمين والمعلمات وأعضاء هيئة التدريس في المدارس والجامعات في الدول العربية.`
        ]
      },
      {
        question: msg`هل المسار خاص بالمدارس والجامعات السعودية؟`,
        answer: [
          msg`لا. المسار متاح لجميع المعلمين والمعلمات وأعضاء هيئة التدريس في المدارس والجامعات في الدول العربية.`
        ]
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: [
          msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار سفراء القراءة في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع. ستجد على صفحتك في موقع المسابقة رمزًا خاصًا بك لمشاركته مع طلابك.`
        ]
      },
      {
        question: msg`ماذا يفعل الطلاب بالرمز الخاص بي؟`,
        answer: [msg`عليهم كتابة رمز المعلم في نموذج المشاركة ضمن مسار قارئ العام.`]
      },
      {
        question: msg`هل سيظهر لي عدد الطلاب الذين استخدموا الرمز الخاص بي؟`,
        answer: [msg`نعم، ستظهر لك أعداد ومشاركات الطلاب الذين استخدموا الرمز الخاص بك.`]
      },
      {
        question: msg`هل ستظهر لي مشاركات الطلاب المتأهلين الذين استخدموا الرمز الخاص بي؟`,
        answer: [msg`نعم.`]
      },
      {
        question: msg`كيف سيتم تحديد الفائزين في هذا المسار؟`,
        answer: [
          msg`ستُحسب النقاط بحسب عدد المشاركين في المسابقة عن طريق المعلم، وتتضاعف النقاط عند وصول المشارك لمراحل متقدمة في المسابقة.`
        ]
      },
      {
        question: msg`كيف يتم إبلاغي في حال ترشحي؟`,
        answer: [msg`سيتم التواصل معك هاتفيًا وعبر البريد الإلكتروني.`]
      },
      {
        question: msg`متى سيتم الإعلان عن المرشحين؟`,
        answer: [msg`يتم إبلاغ جميع المرشحين فور الانتهاء من مرحلة الملتقى الإثرائي.`]
      },
      {
        question: msg`هل توجد جوائز للفائزين في المسابقة؟`,
        answer: [msg`نعم، تُمنح جوائز عينية ونقدية للفائزين.`]
      },
      {
        question: msg`متى سيكون موعد الحفل الختامي للمسابقة؟`,
        answer: [msg`سيكون الحفل الختامي في شهر أكتوبر 2025.`]
      },
      {
        question: msg`هل تتحمل المسابقة تكاليف السفر والضيافة لحضور الحفل الختامي للمرشحين؟`,
        answer: [
          msg`نعم، تتحمل المسابقة تكاليف حضور المرشحين، وتشمل تذاكر السفر والمواصلات والإقامة وجميع الوجبات.`
        ]
      },
      {
        question: msg`هل ستقوم المسابقة باستخراج تأشيرات الدخول للمرشحين من خارج السعودية؟`,
        answer: [
          msg`نعم، تتكفّل المسابقة بجميع إجراءات وتكاليف إصدار تأشيرة الدخول للمرشحين من خارج السعودية.`
        ]
      }
    ]
  },
  {
    category: msg`مسار المدرسة / الجامعة القارئة`,
    questions: [
      {
        question: msg`من يحق له المشاركة في هذا المسار؟`,
        answer: [
          msg`مسار المدرسة / الجامعة القارئة هو لتكريم أعلى المدارس أو الجامعات في عدد المشاركين في المسابقة من طلابها وتقدمهم في مراحل المسابقة، ولا يتطلب الدخول في المنافسة تسجيل المدرسة أو الجامعة، حيث يتم إحصاء نقاط المدارس والجامعات تلقائيًا وفق بيانات الطلاب المشاركين في المسابقة ضمن مسار قارئ العام.`
        ]
      },
      {
        question: msg`هل المسار خاص بالمدارس والجامعات السعودية؟`,
        answer: [msg`لا. المسار متاح يشمل المدارس والجامعات في الدول العربية.`]
      },
      {
        question: msg`كيف يتم إبلاغ المدرسة / الجامعة في حال ترشحها؟`,
        answer: [msg`سيتم التواصل مع إدارة المدرسة هاتفيًا وعبر البريد الإلكتروني.`]
      },
      {
        question: msg`متى سيتم الإعلان عن المرشحين؟`,
        answer: [msg`يتم إبلاغ جميع المرشحين فور الانتهاء من مرحلة الملتقى الإثرائي.`]
      },
      {
        question: msg`متى سيكون موعد الحفل الختامي للمسابقة؟`,
        answer: [msg`سيكون الحفل الختامي في شهر أكتوبر 2025.`]
      },
      {
        question: msg`هل تتحمل المسابقة تكاليف السفر والضيافة لحضور الحفل الختامي للمرشحين؟`,
        answer: [
          msg`نعم، تتحمل المسابقة تكاليف حضور المرشحين، وتشمل تذاكر السفر والمواصلات والإقامة وجميع الوجبات.`
        ]
      },
      {
        question: msg`هل ستقوم المسابقة باستخراج تأشيرات الدخول للمرشحين من خارج السعودية؟`,
        answer: [
          msg`نعم، تتكفّل المسابقة بجميع إجراءات وتكاليف إصدار تأشيرة الدخول للمرشحين من خارج السعودية.`
        ]
      }
    ]
  }
]

export default faqData
