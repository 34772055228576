import { useState } from 'react'
import RequestsTable from './RequestsTable'
import StatusFilter from './StatusFilter'
import SubmissionDateFilter from './SubmissionDateFilter'
import ResponseDateFilter from './ResponseDateFilter'
import IthraUsersFilter from './IthraUsersFilter'
import NumberOfStudentsFromFilter from './NumberOfStudentsFromFilter'
import NumberOfStudentsToFilter from './NumberOfStudentsToFilter'
import FilterIcon from 'assets/icons/FilterIcon'
import { Button, Input, Popover, PopoverContent, PopoverTrigger } from '@nextui-org/react'
import { msg } from '@lingui/macro'
import ButtonVariant from 'components/button-variant/button'
import SearchInput from 'components/search-field/search-field'
import { useLingui } from '@lingui/react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchActivateRequests } from 'store/slices/activateRequestsSlice'

export default function ActivateRequests() {
  const dispatch: AppDispatch = useDispatch()
  const { _ } = useLingui()
const [SearchText, setSearchText] = useState('')
  const [popoverVisible, setPopoverVisible] = useState(false)

  const {
    requests,
    isLoading,
    status,
    page,
    rowsPerPage,
    fromSubmissionDate,
    toSubmissionDate,
    fromResponseDate,
    toResponseDate,
    ithraUsersIds,
    studentsNumberFrom,
    studentsNumberTo,
    pagination
  } = useSelector((state: RootState) => state.activateRequests)

  const onFilter = () => {
    dispatch(
      fetchActivateRequests({
        status,
        page,
        rowsPerPage,
        fromSubmissionDate,
        toSubmissionDate,
        fromResponseDate,
        toResponseDate,
        ithraUsersIds,
        studentsNumberFrom,
        studentsNumberTo
      })
    )
    setPopoverVisible(false) 
  }
  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    let text = e.target.value
    setSearchText(text)
  }
  return (
    <div className="flex flex-col justify-start items-start h-full w-full">
      <div className="flex flex-row justify-start items-start">
        <p>{_(msg`تفعيل الطلبات`)}</p>
      </div>
      <div className="mt-3" />
      <div className="flex flex-row gap-4 justify-center align-middle">
        <SearchInput placeholder={_(msg`بحث`)} value={SearchText} onChange={onSearch} />
        <Popover
          placement="bottom-end"
          showArrow={false}
          isOpen={popoverVisible}
        //  onOpenChange={setPopoverVisible}
        >
          <PopoverTrigger>
            <Button
              className="bg-white hover:border-[#2AACAD] border-2"
              size="md"
              isIconOnly={true}
              onClick={() => setPopoverVisible(true)} // Show popover
            >
              <FilterIcon />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="p-0 overflow-y-auto	">
            <div className="min-w-[300px]">
              <div className="px-2 py-6 flex flex-col gap-4 h-96 overflow-y-auto p-[20px]	">
                <div className="flex flex-col gap-4 items-start ">
                  <div className="flex items-center gap-3 w-full ">
                    <div className="w-full">
                      <SubmissionDateFilter />
                    </div>
                  </div>
                  <div className="flex items-center gap-3 w-full">
                    <div className="w-full">
                      <ResponseDateFilter />
                    </div>
                  </div>
                  <div className="flex items-center gap-3 w-full">
                    <div className="w-full">
                      <StatusFilter />
                    </div>
                  </div>
                  <div className="flex items-center gap-3 w-full">
                    <div className="w-full">
                      <IthraUsersFilter />
                    </div>
                  </div>
                  <div className="flex items-center gap-3 w-full">
                    <div className="w-full">
                      <NumberOfStudentsFromFilter />
                    </div>
                  </div>
                  <div className="flex items-center gap-3 w-full">
                    <div className="w-full">
                      <NumberOfStudentsToFilter />
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-4 px-2 w-full flex gap-2 justify-end bg-[#F9FAFB] border-t border-[#E0E0E0]">
                <ButtonVariant
                  className="bg-primary rounded-full"
                  size="sm"
                  color="primary"
                  onClick={onFilter}
                >
                  {_(msg`تأكيد`)}
                </ButtonVariant>
                <ButtonVariant
                  size="sm"
                  className="border border-gray text-black bg-transparent rounded-full font-bold"
                  onClick={() => setPopoverVisible(false)} // Close popover on cancel
                >
                  {_(msg`الغاء`)}
                </ButtonVariant>
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </div>

      <div className="mt-3" />
      <RequestsTable SearchText={SearchText} />
    </div>
  )
}
