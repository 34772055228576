import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { CompetitionStatus, CompetitionYearDetails } from 'api/types'
import Badge from 'components/badge/Badge'
import ButtonVariant from 'components/button-variant/button'
import Form from 'components/FormStructure/Form'
import DateInput from 'components/hook-form/date-picker-input'
import ButtonGroupInput from 'components/hook-form/group-button-input'
import TextInput from 'components/hook-form/text-input'
import { getStatus, getStatusColor } from 'pages/CompetitionYear/Competitions/Competitions'
import {
  CompetitionErrors,
  CompetitionSchema,
  useStatusOptions
} from 'pages/CompetitionYear/CreateCompetition/CreateCompetition'
import { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { AppDispatch, RootState } from 'store'
import { fetchCompetitionYearDetails } from 'store/slices/competitionYearDetails'
import { updateCompetition } from 'store/slices/updateCompetitionYear'
import { z } from 'zod'

export const useYearOptions = () => {
  const { _ } = useLingui()
  const currentYear = new Date().getFullYear()
  const years = Array.from({ length: 10 }, (_, i) => currentYear + i) // Generate the next 10 years
  return years.map((year) => ({ key: year.toString(), label: _(msg`${year.toString()}`) }))
}

export const getYearFromDate = (dateString: string): string => {
  const date = new Date(dateString)
  return date.getFullYear().toString()
}

type YearInformationProps = {
  competitionYear: CompetitionYearDetails
  id: string | undefined
  status: MutableRefObject<CompetitionStatus>
}

type YearInformationSchemaType = z.infer<typeof CompetitionSchema>

export const YearInformation: FC<YearInformationProps> = (props) => {
  const isLoading = useSelector((state: RootState) => state.updateCompetitionYear.isLoading)
  const dispatch = useDispatch<AppDispatch>()
  const { competitionYear, id: idCompetition } = props
  const statusOptions = useStatusOptions()
  const { _ } = useLingui()
  const formRef = useRef<UseFormReturn<any, any, undefined>>(null)
  const [status, setStatus] = useState<CompetitionStatus>(props.status?.current)
  const isDisableStatus =
    props.status?.current === 'ended' ||
    (props.status?.current === 'started' && status === 'started')

  useEffect(() => {
    if (formRef.current) {
      const subscription = formRef.current.watch((value) => {
        if (value.status !== undefined) {
          setStatus(value.status)
        }
      })

      return () => subscription.unsubscribe()
    }
  }, [props])

  const onSubmit = async (data: YearInformationSchemaType) => {
    const currentYear = Object.fromEntries(
      Object.entries(competitionYear).filter(([_, value]) => value !== null)
    )

    try {
      await dispatch(
        updateCompetition({
          ...currentYear,
          name: data.name,
          start_date: data.start_date,
          end_date: data.end_date,
          status: data.status as CompetitionStatus,
          close_at: data.closed_at
        })
      ).unwrap()
      props.status!.current = data.status as any
      dispatch(fetchCompetitionYearDetails({ id: idCompetition as string }))
      toast.success(_(msg`تم تحديث سنة المسابقة بنجاح`))
    } catch (error: any) {
      if (error.status === 'error') {
        const message = error.message ?? ''
        toast.dismiss()
        if (message === 'CONFLICTING_COMPETITION_YEAR_PERIOD')
          toast.error(
            `${_(CompetitionErrors[message])} ${_(msg`تداخل مع`)} ${error?.error?.conflict_year?.name}.`
          )
        else toast.error(_(CompetitionErrors[message]))
      } else toast.error(_(msg`حدث خطا اثناء تحديث سنة المسابقة`))
    }
  }

  return (
    <Form
      ref={formRef}
      className="!gap-3"
      schema={CompetitionSchema}
      onSubmit={onSubmit}
      defaultValues={{
        status,
        name: competitionYear.name,
        start_date: competitionYear.start_date,
        end_date: competitionYear.end_date,
        closed_at: competitionYear.close_at
      }}
    >
      <div className="flex gap-x-8 justify-between items-start max-w-4xl">
        <div>
          <p className="font-sub-heading-h6-ar mb-2 text-natural_icon_normal capitalize">
            {_(msg`الحالة الحالية لسنة المسابقة`)}
          </p>

          <Badge
            size="lg"
            shape="curved"
            className="!min-w-20"
            placeholderClassName="font-bold text-center text-md"
            variant={getStatusColor(competitionYear.status) as any}
            placeholder={_(getStatus(competitionYear.status)?.id!)}
          />
        </div>

        <ButtonGroupInput
          containerClassName="flex-wrap"
          name="status"
          label={_(msg`تغير حالة للسنة إلى:`)}
          options={statusOptions.filter((e) => e.key !== competitionYear.status)}
        />
      </div>

      <div className="flex flex-wrap gap-x-8 gap-y-4 mt-5 items-start w-full">
        <div className="min-w-full lg:min-w-[25%]">
          <TextInput
            isDisabled={isDisableStatus}
            size="sm"
            name="name"
            labelClassName="mb-1"
            classNames={{ inputWrapper: '!h-[44px]' }}
            label={_(msg`اسم نسخة المسابقة`)}
            placeholder={_(msg`ادخل اسم لنسخة المسابقة`)}
          />
        </div>
        <div className="min-w-full  lg:min-w-[25%]">
          <DateInput
            size="lg"
            name="start_date"
            disabled={isDisableStatus}
            className="!h-[45px]"
            label={_(msg`تاريخ البدء`)}
            labelClassName="mb-1 text-natural_icon_normal font-sub-heading-h6-ar"
          />
        </div>

        <div className="min-w-full lg:min-w-[25%]">
          <DateInput
            size="lg"
            name="end_date"
            disabled={isDisableStatus}
            className="!h-[45px]"
            label={_(msg`تاريخ الانتهاء`)}
            labelClassName="mb-1 text-natural_icon_normal font-sub-heading-h6-ar"
          />
        </div>
        <div className="min-w-full lg:min-w-[25%]">
          <DateInput
            size="lg"
            name="closed_at"
            disabled={isDisableStatus}
            className="!h-[45px]"
            label={_(msg`تاريخ الإغلاق`)}
            labelClassName="mb-1 text-natural_icon_normal font-sub-heading-h6-ar"
          />
        </div>
      </div>

      <div className="flex w-full mt-10 justify-end">
        <ButtonVariant
          type="submit"
          size="lg"
          disabled={isDisableStatus}
          variant="secondary"
          isDisabled={isLoading}
          isLoading={isLoading}
        >
          {_(msg`حفظ التغييرات`)}
        </ButtonVariant>
      </div>
    </Form>
  )
}

export default YearInformation
