import React from 'react'

interface ListIconProps {
  fill?: string
}

const ListIcon: React.FC<ListIconProps> = ({ fill = '#A1A1AA' }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5851_155429)">
        <path
          d="M17.1667 18.3337H3.83333C3.61232 18.3337 3.40036 18.2459 3.24408 18.0896C3.0878 17.9333 3 17.7213 3 17.5003V2.50033C3 2.27931 3.0878 2.06735 3.24408 1.91107C3.40036 1.75479 3.61232 1.66699 3.83333 1.66699H17.1667C17.3877 1.66699 17.5996 1.75479 17.7559 1.91107C17.9122 2.06735 18 2.27931 18 2.50033V17.5003C18 17.7213 17.9122 17.9333 17.7559 18.0896C17.5996 18.2459 17.3877 18.3337 17.1667 18.3337ZM16.3333 16.667V3.33366H4.66667V16.667H16.3333ZM7.16667 7.50033H13.8333V9.16699H7.16667V7.50033ZM7.16667 10.8337H13.8333V12.5003H7.16667V10.8337Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_5851_155429">
          <rect width="20" height="20" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ListIcon