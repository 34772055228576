import React from 'react'
import ButtonVariant from 'components/button-variant/button'
import ArrowIcon from 'assets/icons/ArrowIcon'
import { Trans } from '@lingui/macro'

interface FormNavigationBtnsProps {
  isFirstStep: boolean
  isLastStep: boolean
  onPrev: () => void
  onNext: () => void
  onSaveDraft: () => void
}

const FormNavigationBtns: React.FC<FormNavigationBtnsProps> = ({
  isFirstStep,
  isLastStep,
  onPrev,
  onNext,
  onSaveDraft
}) => {
  return (
    <div className="flex justify-between items-center">
      <ButtonVariant
        variant="bordered"
        onClick={onPrev}
        disabled={isFirstStep}
        iconStart={<ArrowIcon fill="currentColor" width="24" height="24" />}
        iconRotate
      >
        <p>
          <Trans>الخلف</Trans>
        </p>
      </ButtonVariant>

      <div className="flex items-center gap-2">
        {isLastStep && (
          <ButtonVariant variant="bordered" onClick={onSaveDraft}>
            <p>
              <Trans>حفظ كمسودة</Trans>
            </p>
          </ButtonVariant>
        )}

        <ButtonVariant
          variant="secondary"
          onClick={onNext}
          iconEnd={<ArrowIcon fill="currentColor" width="24" height="24" />}
          iconRotate
        >
          {isLastStep ? (
            <p>
              <Trans>تقديم المشاركة</Trans>
            </p>
          ) : (
            <p>
              <Trans>التالي</Trans>
            </p>
          )}
        </ButtonVariant>
      </div>
    </div>
  )
}

export default FormNavigationBtns
