import React, { useState } from 'react'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import img from 'assets/images/login-bg.png'
import Layout from 'pages/AccountPages/Layout'
import StaticBackground from 'pages/AccountPages/components/StaticBackground'
import LoginModal from 'pages/AccountPages/components/modals/LoginModal'
import OTPModal from 'pages/AccountPages/components/modals/OTPModal'
import useDocumentTitle from 'hooks/useDocumentTitle'
import { msg } from '@lingui/macro'
import UserBlocked from '../components/forms/user-login/user-blocked'
import ResetProcess from '../components/forms/user-login/reset-process-trigged'

const Login: React.FC = () => {
  useDocumentTitle(msg`تسجيل الدخول`)
  const isVerified = useSelector((state: RootState) =>
    typeof state.auth.verified === 'string' ? state.auth.verified : null
  )
  const [showOTPModal, setShowOTPModal] = useState(!!isVerified)
  const [showIncativeModal, setShowIncativeModal] = useState(false)
  const [showResetProcessModal, setShowResetProcessModal] = useState(false)

  const handleOTPModalDisplay = (shouldShow: boolean) => {
    setShowOTPModal(shouldShow)
  }
  const handleInactiveModalDisplay = (shouldShow: boolean) => {
    setShowIncativeModal(shouldShow)
  }
  const handleResetProcessModalDisplay = (shouldShow: boolean) => {
    setShowResetProcessModal(shouldShow)
  }
  return (
    <Layout>
      <StaticBackground img={img} />
      {showOTPModal ? (
        <OTPModal handleOTPModalDisplay={handleOTPModalDisplay} isLoginFlow={true} />
      ) : showIncativeModal ? (
        <UserBlocked></UserBlocked>
      ) : showResetProcessModal ? (
        <ResetProcess></ResetProcess>
      ) : (
        <LoginModal
          handleResetProcessModalDisplay={handleResetProcessModalDisplay}
          handleInactiveModalDisplay={handleInactiveModalDisplay}
          handleOTPModalDisplay={handleOTPModalDisplay}
        />
      )}
    </Layout>
  )
}

export default Login
