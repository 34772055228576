import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import Container from 'pages/auth/container'
import CoordinatorActiveAccount from 'pages/coordinator-active-account/coordinator-active-account'
import TeacherAccount from 'pages/TeacherPages/TeacherAccount'
import StudentAccount from 'pages/StudentAccount'
import CoordinatorAccount from 'pages/CoordinatorPages/CoordinatorAccount'

const Home: React.FC = () => {
  const { userType } = useSelector((state: RootState) => state.auth)
  const { me } = useSelector((state: RootState) => state.me)

  const renderContent = () => {
    if (userType === 'coordinator' && me?.data?.coordinator?.status !== 'approved') {
      return <CoordinatorActiveAccount />
    }

    switch (userType) {
      case 'ithraa':
        return (
          <Container title="Welcome Ithra User" subtitles="This is your home page.">
            <></>
          </Container>
        )
      case 'teacher':
        return <TeacherAccount />
      case 'coordinator':
        return <CoordinatorAccount />
      case 'student':
        return <StudentAccount />
      default:
        return (
          <Container title="Welcome" subtitles="This is the general home page.">
            <p>Welcome to the Home Page!</p>
          </Container>
        )
    }
  }

  return <div className="h-full w-full">{renderContent()}</div>
}

export default Home
