import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from 'api'

interface TeacherStatsResponse {
  status: string
  code: number
  message: string
  data: {
    points_count: number
    studenets_count: number
    code: string
  }
  error: object | null
}

interface TeacherStatsState {
  pointsCount: number
  studentsCount: number
  code: string | null
  isLoading: boolean
  error: string | null
}

const initialState: TeacherStatsState = {
  pointsCount: 0,
  studentsCount: 0,
  code: null,
  isLoading: false,
  error: null
}

export const fetchTeacherStats = createAsyncThunk('teacherStats/fetch', async () => {
  const response = await axiosInstance.get<TeacherStatsResponse>('/teachers/points/code-and-stats')
  return response.data
})

const teacherStatsSlice = createSlice({
  name: 'teacherStats',
  initialState,
  reducers: {
    setTeacherStats: (state, action: PayloadAction<TeacherStatsResponse['data']>) => {
      const { points_count, studenets_count, code } = action.payload
      state.pointsCount = points_count
      state.studentsCount = studenets_count
      state.code = code
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeacherStats.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchTeacherStats.fulfilled, (state, action) => {
        state.isLoading = false

        // Extracting nested data
        const { points_count, studenets_count, code } = action.payload.data
        state.pointsCount = points_count
        state.studentsCount = studenets_count
        state.code = code
      })
      .addCase(fetchTeacherStats.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? 'An error occurred'
      })
  }
})

export const { setTeacherStats } = teacherStatsSlice.actions

export default teacherStatsSlice
