interface RequestActivityStatus {
  value: string
  labelEn: string
  labelAr: string
}

export const requestActivityStatus: RequestActivityStatus[] = [
  { value: 'coordinator.approve', labelEn: 'Approved', labelAr: 'موافق عليه' },
  { value: 'coordinator.reject', labelEn: 'Rejected', labelAr: 'مرفوض' }
]

type Language = 'en' | 'ar'

export const getActivityRequestStatus = (lang: Language) => {
  return requestActivityStatus.map((option) => ({
    value: option.value,
    label: lang === 'en' ? option.labelEn : option.labelAr
  }))
}

export const getRequestActivityStatusLabel = (value: string, lang: Language) => {
  const option = requestActivityStatus.find((option) => option.value === value)
  return option ? (lang === 'en' ? option.labelEn : option.labelAr) : ''
}

export const getRequestActivityStatusValue = (value: string) => {
  const option = requestActivityStatus.find((option) => option.value === value)
  return option?.value ?? ''
}
export const getRequestActivityStatus = (value: string) => {
  if (value == "coordinator.approve") {
    return 'approved'
  } else if (value == 'coordinator.reject') {
    return 'rejected'
  }
}
