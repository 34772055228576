import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useLanguage from 'hooks/useLanguage'
import { AppDispatch, RootState } from 'store'
import { fetchTeacherUsers } from 'store/slices/teacherUsersSlice'
import { IthraUser } from 'api/types'

const useTeacherOptions = () => {
  const dispatch = useDispatch<AppDispatch>()
  const language = useLanguage() as 'ar' | 'en' 

  const { teacherUsers, isLoading, error } = useSelector((state: RootState) => state.teacherUsers)

  useEffect(() => {
    if (teacherUsers.length === 0) {
      dispatch(fetchTeacherUsers())
    }
  }, [dispatch, teacherUsers.length])

  const options = useMemo(() => {
    if (teacherUsers.length === 0) {
      return [
        {
          key: '',
          label: language === 'ar' ? 'لا يوجد بيانات للاختيار' : 'No options available'
        }
      ]
    } else {
      // Map through teacher users to create options with localized names
      return teacherUsers.map((user: IthraUser) => ({
        key: String(user.id),
        label: user.name
      }))
    }
  }, [teacherUsers, language])

  return { options, isLoading, error }
}

export default useTeacherOptions
