import React from 'react'
import { Progress } from '@nextui-org/react'
import DoughnutChart from './Daunght'
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'
export default function DoughnutWithProgress({
  chartProgress,
  data,
  totalApplications,
  translationKey,
  prefixBg,
  
}: {
  chartProgress: {
    name: string
    percentage: number
    total_applications: string
  }[]
  data: {
    name: string
    progressItems: {
      percentage: number
      name: string
      bgColor: string
      textColor: string
    }[]
  }[]
  totalApplications: number
  translationKey?: string
  prefixBg?: string
}) {
  const { _, i18n } = useLingui()

  const prefix = translationKey ?? _(msg`مشاركة`)
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-1 flex-col">
        <DoughnutChart
        
          prefixBg={prefixBg}
          prefix={prefix}
          totalApplications={totalApplications}
          chartProgress={chartProgress}
          data={data}
          layout="horizontal"
        />
      </div>
      <div className="mt-4 space-y-4">
        {data.map((indicator, index) => (
          <div key={index} className="flex justify-between items-center">
            <span className='w-20' >{indicator.name}</span>
            <div className="flex flex-1 flex-col w-1/2">
              {indicator.progressItems?.map((_) => (
                <div className="flex flex-row items-center">
                  <Progress
                    classNames={{
                      track: 'bg-transparent',
                      indicator: _.bgColor
                    }}
                    className="transform scale-x-[-1] mt-1 bg-none flex-1 mx-2"
                    value={_.percentage}
                    radius="none"
                    size="md"
                    color="primary"
                  />
                  <span className={_.textColor}>{_.percentage + '%'}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
