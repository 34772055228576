import React, { useEffect, useState, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setToken, setUserType, setVerified } from 'store/slices/authSlice'

type Props = {
  children: ReactNode
}

const AuthGuard: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem('token')
    const userType = localStorage.getItem('userType')
    const verified = localStorage.getItem('verified')=='true'

    // Check if token and verifiedAt both exist
    if (!token || !verified  ) {
      navigate('/login') // Redirect to login if either is missing
    } else {
      // Dispatch token and other user details to Redux store
      dispatch(setToken(token))
      if (userType) {
        dispatch(setUserType(userType))
      }
      if (verified) {
        dispatch(setVerified(verified))
      }
      setChecked(true) // Mark as checked only after successful validation
    }
  }, [navigate, dispatch])

  if (!checked) {
    return null // Render nothing while checking
  }

  return <>{children}</> // Render children if checks pass
}

export default AuthGuard
