import React from 'react'
import { Breadcrumb, BreadcrumbsProps } from 'pages/landing/models'
import ChevronIcon from 'assets/icons/ChevronIcon'
import { Trans } from '@lingui/react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  const direction = useSelector((state: RootState) => state.layout.direction)

  return (
    <nav aria-label="breadcrumb" className="w-full">
      <ol className="flex flex-wrap items-center">
        {breadcrumbs.map((breadcrumb: Breadcrumb, index: number) => (
          <li key={index} className="flex items-center">
            <a
              href={breadcrumb.href}
              className="text-customWhite hover:text-customGreen font-medium whitespace-nowrap"
            >
              <Trans id={breadcrumb.name.id} />
            </a>
            {index < breadcrumbs.length - 1 && (
              <div className={`${direction === 'rtl' ? '-rotate-90' : 'rotate-90'} mx-2`}>
                <ChevronIcon fill="rgba(249, 250, 251, 1)" width="24" height="24" />
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default Breadcrumbs
