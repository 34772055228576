import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button, Switch } from '@nextui-org/react'
import { CompetitionStatus, CompetitionYear, CompetitionYearDetails } from 'api/types'
import Form from 'components/FormStructure/Form'
import SelectInput from 'components/hook-form/select-input'
import TextareaInput from 'components/hook-form/textarea-input'
import useLanguage from 'hooks/useLanguage'
import { CompetitionErrors } from 'pages/CompetitionYear/CreateCompetition/CreateCompetition'
import { FC, MutableRefObject, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { AppDispatch, RootState } from 'store'
import { fetchCompetitionYearDetails } from 'store/slices/competitionYearDetails'
import { updateCompetition } from 'store/slices/updateCompetitionYear'
import { z } from 'zod'

const useDayOptions = () => {
  const { _ } = useLingui()
  const lang = useLanguage()

  const days = Array.from({ length: 30 }, (_, i) => i + 1) // Generate days from 1 to 30
  return days.map((day) => ({
    key: day.toString(),
    label:
      day === 1
        ? `${day} ${_(msg`يوم`)}`
        : day === 2
          ? `${day} ${_(msg`يومين`)}`
          : day > 10 && lang === 'ar'
            ? `${day} ${_(msg`يوم`)}`
            : `${day} ${_(msg`أيام`)}`
  }))
}

type CompetitionSettingsProps = {
  competitionYear: CompetitionYearDetails
  status: MutableRefObject<CompetitionStatus>
}

export const CompetitionSettings: FC<CompetitionSettingsProps> = (props) => {
  const { competitionYear, status } = props
  const dayOptions = useDayOptions()
  const dispatch = useDispatch<AppDispatch>()
  const isLoading = useSelector((state: RootState) => state.updateCompetitionYear.isLoading)
  const { _ } = useLingui()

  const disableStatus = ['ended', 'started'].includes(status.current)
  const schema = z.object({
    notification_days: z.string().nonempty({ message: _(msg`تحديد الفترة مطلوب`) }),
    notification_message: z.string().nonempty({ message: _(msg`رسالة الاشعار مطلوبة`) })
  })
  type CompetitionSettingsSchemaType = z.infer<typeof schema>

  const [auto, setAuto] = useState(!!competitionYear.auto_start)
  const [notify, setNotify] = useState(!!competitionYear.notification_enable)

  const setCompetition = async (data: Partial<CompetitionYear>) => {
    const currentYear = Object.fromEntries(
      Object.entries(competitionYear).filter(([_, value]) => value !== null)
    )

    try {
      await dispatch(updateCompetition({ ...currentYear, ...data })).unwrap()
      dispatch(fetchCompetitionYearDetails({ id: competitionYear.id.toString() }))
      toast.success(_(msg`تم تحديث اعدادات المسابقة بنجاح`))
    } catch (error: any) {
      setAuto(!!competitionYear.auto_start)
      setNotify(!!competitionYear.notification_enable)
      if (error.status === 'error') {
        const message = error.message ?? ''
        toast.dismiss()
        if (message === 'CONFLICTING_COMPETITION_YEAR_PERIOD')
          toast.error(
            `${_(CompetitionErrors[message])} ${_(msg`تداخل مع`)} ${error?.error?.conflict_year?.name}.`
          )
        else toast.error(_(CompetitionErrors[message]))
      } else toast.error(_(msg`حدث خطا اثناء تحديث الاعدادات`))
    }
  }

  const onSubmit = async (data: CompetitionSettingsSchemaType) => {
    setCompetition({
      notification_enable: notify,
      notification_days: parseInt(data.notification_days),
      notification_message: data.notification_message
    })
  }

  return (
    <Form
      schema={schema}
      onSubmit={onSubmit}
      defaultValues={{
        notification_days: (competitionYear.notification_days ?? '').toString(),
        notification_message: competitionYear.notification_message ?? ''
      }}
    >
      <div className="flex flex-col gap-y-4">
        <div className="w-full p-3 bg-[#F9FAFB] !rounded-xl">
          <div className="flex flex-row gap-2 mb-4">
            <Switch
              size="lg"
              isDisabled={disableStatus}
              aria-label="Automatic updates"
              isSelected={auto}
              onChange={(e) => {
                setAuto(e.target.checked)
                setCompetition({ auto_start: e.target.checked })
              }}
            />
            <div className="flex flex-col gap-2">
              <p className="font-medium text-md">{_(msg`بداية السنة`)}</p>
              <p className="text-sm">
                {_(
                  msg`بتفعيل خيار البدء التلقائي، سيتم بدء المسابقة تلقائياً عندما يحين موعد بدايتها`
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-[#F9FAFB] !rounded-xl">
          <div className="flex flex-row gap-2 p-3">
            <Switch
              size="lg"
              isDisabled={disableStatus}
              aria-label="Notification enable"
              isSelected={notify}
              onChange={(e) => {
                setNotify(e.target.checked)
                if (competitionYear.notification_enable)
                  setCompetition({ notification_enable: e.target.checked })
              }}
            />

            <div className="flex flex-col gap-2">
              <p className="font-medium text-md">
                {_(msg`تهيئة الاعدادات لإرسال اشعارات بداية العام:`)}
              </p>
              <p className="text-sm">
                {_(
                  msg`تفعيل ارسال الاشعارات للجمهور المستهدف عن طريق الايميل والموقع في خلال الايام ما قبل تاريخ بدء المسابقة`
                )}
              </p>
            </div>
          </div>

          {notify && (
            <div>
              <div className="flex gap-8 p-3 flex-col lg:flex-row items-start justify-between max-w-3xl">
                <SelectInput
                  className="min-w-48"
                  name="notification_days"
                  options={dayOptions}
                  label={_(msg`حدد فترة الزمن قبل تاريخ البدء لتشغيل الإشعارات`)}
                  placeholder={_(msg`الأيام`)}
                />

                <TextareaInput
                  minRows={10}
                  containerClassName="w-full lg:w-96"
                  label={_(msg`نص رسالة الأشعار`)}
                  name="notification_message"
                />
              </div>

              <div className="flex flex-row my-3 px-3 justify-end w-full">
                <Button
                  type="submit"
                  size="lg"
                  className="bg-[#2AACAD] text-white hover:bg-[#279a99] border rounded-full border-transparent"
                  isLoading={isLoading}
                  isDisabled={isLoading}
                >
                  {_(msg`حفظ التغيرات`)}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Form>
  )
}

export default CompetitionSettings
