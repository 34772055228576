import React from 'react'
import { Avatar, Progress } from '@nextui-org/react'
import Typography from 'components/typography/typography'
import { useLingui } from '@lingui/react'
import { Trans } from '@lingui/macro'
import { getColorByPercentage } from 'utils/charts'

interface UserProgress {
  userName: string
  region?: string
  percentage: number
  avatar?: string
  total_applications: number
}

interface ChartProgressBar {
  user: UserProgress
}

const ChartProgressBar: React.FC<ChartProgressBar> = ({ user }) => {
  const { _ } = useLingui()
  const isRtl = document.dir === 'rtl'
  const colorClass = getColorByPercentage(user.percentage)
  const percentageIntegerPart = user.percentage.toString().split('.')[0]
  const getLabelOffset = (progressValue: number) => {
    if (window.innerWidth >= 1024) {
      return isRtl ? 150 : 150
    } else if (window.innerWidth >= 768) {
      return 80
    } else {
      return 60
    }
  }
  let color = `${colorClass.toString()}`
  return (
    <div
      key={user.percentage}
      className={`flex items-center w-full space-x-4 ${isRtl ? 'flex-row-reverse' : ''}`}
    >
      <Progress
        radius="none"
        size="lg"
        value={user?.percentage}
        label={false}
        className={`transform ${isRtl ? 'scale-x-[-1]' : ''} mt-1 bg-none`}
        classNames={{
          track: 'bg-transparent',
          indicator: color
        }}
      />
      <div
        className="absolute flex flex-row items-center px-4"
        style={{ [isRtl ? 'right' : 'left']: `calc(${user.percentage}%)` }}
      >
        <span className="truncate text-natural_lv1_text_normal px-2  text-nowrap">
          {user.userName}
        </span>
      </div>
      <div
        className="absolute"
        style={{
          [isRtl ? 'right' : 'left']:
            `calc(${user.percentage}% - ${getLabelOffset(user.percentage)}px)`
        }}
      >
        {user.percentage > 0 && (
          <span className="px-4 text-white text-center text-nowrap font-normal text-sm">
            {user.total_applications + ' '}
            <Trans>مشاركة</Trans>
            {` (${percentageIntegerPart}%)`}
          </span>
        )}
      </div>
    </div>
  )
}

export default ChartProgressBar
