import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import ModalLayout from 'components/modal/modal-layout'
import React from 'react'

export default function ResetProcess() {
  const { _ } = useLingui()

  const handleEmailClick = () => {
    window.location.href = 'mailto:info@ireadaward.com'
  }

  return (
    <ModalLayout
      showBackButton={false}
      body={
        <div className="flex flex-col gap-4 items-center w-full">
          <h4 className="font-heading-h4">{_(msg`إعادة تعيين كلمة المرور`)}</h4>
          <span className="text-center">
            {_(msg`
يرجى مراجعة بريدك الإلكتروني لإكمال إجراءات تسجيل دخولك وإعادة تعيين كلمة المرور.`)}
          </span>
        </div>
      }
    />
  )
}
