import React from 'react'
import ireadLoader from 'assets/icons/ireadLoader.gif'

const Loader: React.FC<{ isVisible: boolean }> = ({ isVisible }) => {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-gradient-to-t from-primary_fill_hover to-primary_fill_hover-900/10 backdrop-opacity-20 transition-opacity duration-300 ease-in-out ${isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
    >
      <img src={ireadLoader} alt="Loading..." className="max-w-full h-auto md:h-3/6" />
    </div>
  )
}

export default Loader
