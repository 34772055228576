import React from 'react'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import InputField from 'components/application-viewer/components/DisabledInput'
import { Application } from 'store/slices/applicationsSlice'

const ApplicationBodyForm = ({ applicationData }: { applicationData: Application }) => {
  const { _ } = useLingui()

  return (
    <>
      <div className="col-span-3">
        <InputField
          name="application_body.reading_story"
          label={_(msg`قصتك مع القراءة`)}
          value={applicationData.application_body.story || ''}
          isDisabled
          textArea
        />
      </div>
      <InputField
        name="application_body.book_title"
        width="100%"
        label={_(msg`عنوان الكتاب`)}
        value={applicationData.application_body.book_title || ''}
      />
      <InputField
        name="application_body.author_name"
        label={_(msg`اسم المؤلف`)}
        value={applicationData.application_body.book_author || ''}
      />
      <div className="col-span-3">
        <InputField
          name="application_body.reason_for_choice"
          label={_(msg`سبب اختيارك للكتاب ؟`)}
          value={applicationData.application_body.book_reason || ''}
          textArea
        />
      </div>
      <div className="col-span-3">
        <InputField
          name="application_body.book_review"
          label={_(msg`مراجعة الكتاب`)}
          value={applicationData.application_body.book_review || ''}
          textArea
        />
      </div>
      <InputField
        name="application_body.teacher_code"
        label={_(msg`رمز المعلم`)}
        value={applicationData.application_body.teacher_code || ''}
      />
    </>
  )
}

export default ApplicationBodyForm
