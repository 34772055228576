import { Chip, Select, SelectItem } from '@nextui-org/react'
import React, { useEffect } from 'react'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { setIthraUsersIds } from 'store/slices/activateRequestsSlice'
import { fetchIthraUsers } from 'store/slices/ithraUsersSlice'
const IthraUsersFilter: React.FC = () => {
  const { _ } = useLingui()
  const dispatch: AppDispatch = useDispatch()
  const { ithraUsers, isLoading } = useSelector((state: RootState) => state.ithraUsers)
  const { ithraUsersIds } = useSelector((state: RootState) => state.activateRequests)

  useEffect(() => {
    dispatch(fetchIthraUsers())
  }, [])

  const handelIthraUsers = (keys: Selection) => {
    let selectedKeys: string[] = []
    if (keys instanceof Set) {
      selectedKeys = Array.from(keys) as string[]
    } else if (typeof keys === 'string') {
      selectedKeys = [keys]
    }
    dispatch(setIthraUsersIds(selectedKeys))
  }
  const ithraUsersOptions = ithraUsers.map((ithraUser: any) => {
    return { value: ithraUser.id, label: ithraUser.name }
  })

  // const ithraUserValue = ithraUsersOptions.find((option) => option.value === ithraUser)
  const ithraUserValues = ithraUsersIds
    .map((id) => ithraUsersOptions.find((option) => option.value === id))
    .filter(Boolean)

  return (
    <div className="flex flex-col gap-0.5">
      <p className="text-primary pb-2">{_(msg`معالجة الطلب من قبل`)}</p>
      <Select
        selectionMode="multiple"
        variant="bordered"
        color="primary"
        name=""
        selectedKeys={ithraUsersIds}
        onSelectionChange={handelIthraUsers as any}
        // onChange={handelIthraUsers}
        placeholder={_(msg`معالجة الطلب من قبل`)}
        renderValue={(items) => {
          return (
            <div className="flex flex-wrap gap-2">
              {items.map((item) => (
                <Chip className="text-white" key={item.key}>
                  {item.textValue}
                </Chip>
              ))}
            </div>
          )
        }}
      >
        {ithraUsersOptions.map((option) => (
          <SelectItem key={option?.value}>{option?.label}</SelectItem>
        ))}
      </Select>
    </div>
  )
}

export default IthraUsersFilter
