import { useSelector } from 'react-redux'
import { RootState } from 'store'

const useDefaultValues = () => {
  const applicationData = useSelector((state: RootState) => state.appsStates?.apps[0])
  const userData = useSelector((state: RootState) => state.me?.me?.data)

  // console.log(applicationData)
  // console.log(userData)


  const defaultValues = {
    appId: applicationData?.id || null,
    personal_info: {
      first_name: applicationData?.personal_info.first_name || userData?.first_name || '',
      second_name: applicationData?.personal_info.second_name || userData?.middle_name || '',
      last_name: applicationData?.personal_info.last_name || userData?.last_name || '',
      date_of_birth: applicationData?.personal_info.birth_date || userData?.date_of_birth || null, // type dont match
      age: applicationData?.personal_info.age || null,
      gender: applicationData?.personal_info.gender || userData?.gender || '',
      nationality: applicationData?.personal_info.nationality_id ? String(applicationData.personal_info.nationality_id) : (userData?.nationality_id ? String(userData.nationality_id) : null),
    },
    contact_info: {
      phone: applicationData?.contact_info.phone || userData?.phone || '',
      email: applicationData?.contact_info.email || userData?.email || '',
    },
    location_info: {
      country: applicationData?.location.country_id ? String(applicationData.location.country_id) : (userData?.country_id ? String(userData.country_id) : null),
      countryCode: applicationData?.location.country_code ? String(applicationData.location.country_code) : '',
      region: applicationData?.location.region_id ? String(applicationData.location.region_id) : (userData?.region?.id ? String(userData.region?.id) : null),
    },
    education_info: {
      education_stage: applicationData?.education.education_stage || userData?.education_stage || '',
      education_level: applicationData?.education.education_level_id ? String(applicationData.education.education_level_id) : (userData?.education_level_id ? String(userData.education_level_id) : null),
      education_administration: applicationData?.education.education_administration_id ? String(applicationData.education.education_administration_id) : (userData?.education_administration?.id ? String(userData?.education_administration?.id) : null),
      school: (applicationData?.education.school_university_name || userData?.school_university_name) ? 'other' : applicationData?.education.school_id ? String(applicationData.education.school_id) : (userData?.school?.id ? String(userData.school?.id) : null),
      university: (applicationData?.education.school_university_name || userData?.school_university_name) ? 'other' : applicationData?.education.university_id ? String(applicationData.education.university_id) : (userData?.university?.id ? String(userData.university?.id) : null),
      school_university_name: applicationData?.education.school_university_name || userData?.school_university_name || '',
    },
    application_body: {
      reading_story: applicationData?.application_body.story || '',
      book_title: applicationData?.application_body.book_title || '',
      author_name: applicationData?.application_body.book_author || '',
      reason_for_choice: applicationData?.application_body.book_reason || '',
      book_review: applicationData?.application_body.book_review || '',
      teacher_code: applicationData?.application_body.teacher_code || '',
      terms_agreed: applicationData?.application_body.terms_agreed || false
    }
  }

  return defaultValues
}

export default useDefaultValues
