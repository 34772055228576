import React, { FC, useState } from 'react'
import CardLayout from 'components/card/card-layout'
import { Tab } from '@nextui-org/react'
import Typography from 'components/typography/typography'
import TabSwitcher from 'components/tabSwitcher/TabSwitcher'
import { ArrowLeft } from 'assets/icons/ArrowLeft'
import ButtonVariant from 'components/button-variant/button'
import ListView from '../../../components/charts/ListView'
import SelectField from 'components/select-field/select-field'
import ChartComp from '../../../components/charts/ChartUi'
import DoughnutWithProgress from '../../../components/charts/DoughnutWithProgress'
import { Trans } from '@lingui/macro'
import Label from 'components/label/Label'
import { getTooltipContentBySection } from 'utils/chartsTooltip'
import { ContentType } from '../type'
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'

import { useNavigate } from 'react-router-dom'
import VerticalProgress from 'components/charts/VerticalProgress'
import { getLocalizedName } from 'utils/getLocalizedName'
import DoughnutChart from 'components/charts/Daunght'
import ProgressList from 'components/charts/ProgressList'
import EmptyChart from './EmptyChart'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import { getNameByLanguage } from 'utils/getNameByLanguage'
interface Tab {
  id: string
  label: string
}
interface UserProgress {
  userName: string
  percentage: number
  total_applications: number
}
interface DataItem {
  itemName: string
  numberOfApplications: number
}
interface DataItemProgress {
  name: string
  progressValue: number
}

interface ReportWrapperProps {
  cardTitle: string
  includeTabs?: boolean
  includeSelect?: boolean
  tabs?: Tab[]
  contentType: ContentType
  data: DataItem[] | DataItemProgress[] | any
  chartData?: {
    byRegion?: UserProgress[]
    byAdministration?: UserProgress[]
    byTopAdministrations?: UserProgress[]
    byAllAdministrations?: UserProgress[]
  }
  chartProgress?: {
    name: string
    percentage: number
    total_applications: string
  }[]
  totalApplications?: number
  selectOptions?: {
    key: string
    label: string
  }[]
  translationKey?: string
  indicotors?: any[]
  isIthraa?: boolean
}

const ReportWrapper: FC<ReportWrapperProps> = ({
  cardTitle,
  includeTabs = false,
  tabs = [],
  contentType = 'regionalStagesApplications',
  data,
  chartData,
  chartProgress,
  totalApplications,
  includeSelect,
  selectOptions,
  translationKey,
  indicotors,
  isIthraa
}) => {
  const { me } = useSelector((state: RootState) => state.me)

  const [reportHeaderTabIndex, setreportHeaderTabIndex] = useState(tabs[0]?.id)
  const [reportHeaderSelectIndex, setreportHeaderSelectIndex] = useState('1')
  const navigate = useNavigate()
  const { i18n } = useLingui()
  const { _ } = useLingui()

  const onChangeTab = (tab: string) => {
    setreportHeaderTabIndex(tab)
  }
  const onChangeSelect = (key: string) => {
    setreportHeaderSelectIndex(key)
  }
  const onShowMore = () => {
    if (contentType == 'countryStagesApplications' && reportHeaderTabIndex == tabs[1].id) {
      return navigate(`more/${'otherCountriesStagesApplications'}`)
    }
    if (contentType == 'countryTopCoordinators' && reportHeaderTabIndex == tabs[1].id) {
      return navigate(`more/${'otherCountriesTopCoordinators'}`)
    }
    if (contentType == 'countryTopTeachers' && reportHeaderTabIndex == tabs[1].id) {
      return navigate(`more/${'otherCountriesTopTeachers'}`)
    }
    navigate(`more/${contentType}`)
  }
  const header = (
    <div className="flex flex-row justify-between items-center w-full">
      <div className="flex flex-row gap-2 items-center">
        <Label
          className="max-w-72"
          label={cardTitle}
          tooltipInfoText={getLocalizedName(
            getTooltipContentBySection(contentType),
            i18n.locale as any
          )}
        ></Label>
      </div>

      <div className="flex-1 flex justify-end">
        {includeTabs && (
          <TabSwitcher activeTab={reportHeaderTabIndex} onClick={onChangeTab} tabs={tabs} />
        )}
        {contentType === 'administrativesApplications' && includeSelect && (
          <div className="w-1/2">
            <SelectField
              onChange={onChangeSelect as any}
              className="w-full"
              value={reportHeaderSelectIndex}
              name="repportHeader"
              options={selectOptions ?? []}
            />
          </div>
        )}
      </div>
    </div>
  )
  const getIsDisabled = () => {
    switch (contentType) {
      case 'regionalStagesApplications':
        return !data.byRegion.length || !data?.byEducationStage

      case 'administrativesApplications':
        if (!isIthraa) return !(data.top?.length > 0 || data.all?.length > 0)
        return false

      case 'reachPercentage':
        return !(data?.length > 0)

      case 'topCoordinators':
      case 'topTeachers':
        return !chartData?.[reportHeaderTabIndex as keyof typeof chartData]?.length

      case 'universitiesApplications':
        return !data.length
      case 'countryApplicationsCount':
        return true

      case 'genderCountriesApplications':
        return !data?.length
      case 'countriesApplications':
        return !data?.[reportHeaderTabIndex as keyof typeof data]?.length
      case 'countriesApplicationsStatus':
        return !data?.[reportHeaderTabIndex as keyof typeof data]?.length
      case 'countryRegionalApplications':
        return !data?.drafts?.length
      case 'countryStagesApplications':
        return !data
      case 'countryTopTeachers':
        return !chartData?.[reportHeaderTabIndex as keyof typeof chartData]?.length

      case 'countryTopCoordinators':
        return !chartData?.[reportHeaderTabIndex as keyof typeof chartData]?.length

      default:
        return true
    }
  }

  const renderContent = () => {
    const renderListView = (props: any) => <ListView {...props} />

    switch (contentType) {
      case 'regionalStagesApplications':
        if (!data.byRegion.length) {
          return <EmptyChart />
        }
        return reportHeaderTabIndex === 'byRegion' ? (
          renderListView({ data: data.byRegion })
        ) : !data?.byEducationStage ? (
          <EmptyChart />
        ) : (
          <DoughnutWithProgress
            chartProgress={chartProgress as any}
            totalApplications={totalApplications as any}
            data={data.byEducationStage}
          />
        )
      case 'countriesApplicationsStatus':
        if (!data?.drafts && !data?.drafts?.length && !data?.inProgress?.length) {
          return <EmptyChart />
        }
        return reportHeaderTabIndex === 'drafts' ? (
          renderListView({ data: data?.drafts, hideProgress: true })
        ) : !data?.inProgress ? (
          <EmptyChart />
        ) : (
          renderListView({ data: data.inProgress, hideProgress: true })
        )
      case 'countryRegionalApplications':
        if (!data?.drafts && !data?.drafts?.length) {
          return <EmptyChart />
        }
        return renderListView({ data: data?.drafts, hideProgress: true })
      case 'administrativesApplications':
        if (!isIthraa) {
          if (!(data.top?.length > 0 || data.all?.length > 0)) {
            return <EmptyChart />
          }
          return renderListView({
            data:
              reportHeaderSelectIndex === '1'
                ? data?.top ?? []
                : reportHeaderSelectIndex === '2'
                  ? data?.all
                  : data?.byGender ?? []
          })
        } else {
          if (!chartData?.[reportHeaderTabIndex as keyof typeof chartData]?.length) {
            return <EmptyChart />
          }
          return (
            <ChartComp data={chartData?.[reportHeaderTabIndex as keyof typeof chartData] ?? []} />
          )
        }

      case 'reachPercentage':
        if (!(data?.length > 0)) {
          return <EmptyChart />
        }
        return renderListView({ showPercentageValue: true, translationKey, data })
      case 'countryApplicationsCount':
        if (!(data?.length > 0)) {
          return <EmptyChart />
        }
        return (
          <DoughnutChart
            data={[]}
            prefix={_(msg`مشاركة`)}
            totalApplications={totalApplications as number}
            prefixBg={_(
              msg`مشاركات ${getNameByLanguage(
                {
                  ar: 'السعودية',
                  en: 'Saudi Arabia'
                } as any,
                i18n.locale as any
              )} وباقي الدول`
            )}
            chartProgress={data as any}
          ></DoughnutChart>
        )
      case 'topCoordinators':
      case 'topTeachers':
      case 'countryTopCoordinators':
      case 'countryTopTeachers':
        if (!chartData?.[reportHeaderTabIndex as keyof typeof chartData]?.length) {
          return <EmptyChart />
        }
        return (
          <ChartComp data={chartData?.[reportHeaderTabIndex as keyof typeof chartData] ?? []} />
        )

      case 'universitiesApplications':
        if (!data.length) {
          return <EmptyChart />
        }
        return <VerticalProgress data={data} />
      case 'genderCountriesApplications':
        if (!data.length) {
          return <EmptyChart />
        }
        return <ProgressList indicatorsColor={indicotors} indicators={data} />
      case 'countriesApplications':
        if (!data) {
          return <EmptyChart />
        }
        return reportHeaderTabIndex === 'top' ? (
          renderListView({ data: data?.top })
        ) : !data?.all ? (
          <EmptyChart />
        ) : (
          renderListView({ data: data.all })
        )
      case 'countryStagesApplications':
        if (!data) {
          return <EmptyChart />
        }
        return (
          <DoughnutWithProgress
            data={data[reportHeaderTabIndex] ?? []}
            translationKey={_(msg`مشاركة`)}
            totalApplications={
              ((totalApplications
                ? ((totalApplications as any)[reportHeaderTabIndex] as any)
                : 0) ?? (0 as any)) as any
            }
            prefixBg={_(msg`مجموع المشاركات`)}
            chartProgress={
              ((chartData ? ((chartData as any)[reportHeaderTabIndex] as any) : []) ??
                ([] as any)) as any
            }
          ></DoughnutWithProgress>
        )

      default:
        return <p>Other content types to be implemented</p>
    }
  }

  const footer = (
    <div className="flex flex-row justify-end w-full">
      <ButtonVariant
        variant="light"
        className="flex flex-row items-center"
        onClick={onShowMore}
        isDisabled={getIsDisabled()}
      >
        <div className="text-secondary_lv1_text_normal_2 px-2">
          <ArrowLeft fill="currentColor" />
        </div>
        <Typography className="text-secondary_lv1_text_normal_2">
          <Trans>المزيد</Trans>
        </Typography>
      </ButtonVariant>
    </div>
  )

  return (
    <div className="py-4 ">
      <CardLayout
        type="report"
        header={header}
        content={
          <div className="h-72 overflow-y-auto scrollbar-thin scrollbar-thumb-primary scrollbar-track-transparent">
            {renderContent()}{' '}
          </div>
        }
        footer={footer}
      />
    </div>
  )
}

export default ReportWrapper
