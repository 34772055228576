import { msg, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { CompetitionYearDetails, WorkflowDetail } from 'api/types'
import ButtonVariant from 'components/button-variant/button'
import Form from 'components/FormStructure/Form'
import DateInput from 'components/hook-form/date-picker-input'
import SelectInput from 'components/hook-form/select-input'
import TextInput from 'components/hook-form/text-input'
import TextareaInput from 'components/hook-form/textarea-input'
import dayjs from 'dayjs'
import { FC, useEffect, useRef, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { AppDispatch, RootState } from 'store'
import { updateWorkflow } from 'store/slices/updateWorkflowSlice'
import { fetchWorkflowDetail } from 'store/slices/workflowDetailsSlice'
import { workflowErrors } from 'store/slices/workFlowSlice'
import { z } from 'zod'

type Props = { competition: CompetitionYearDetails | null; workflow: WorkflowDetail | null }

export const schema = z.object({
  name: z.string().nonempty({ message: t`الاسم مطلوب` }),
  description: z.string().nonempty({ message: t`وصف سير المشاركة مطلوب` }),
  status: z.string().nonempty({ message: t`حالة سير المشاركة مطلوبة` }),
  end_date: z
    .string()
    .nonempty({ message: t`تاريخ الانتهاء مطلوب` })
    .refine((date) => dayjs(date).isValid(), { message: t`تاريخ الانتهاء غير صالح` })
})

type UpdateWorkflowSchemaType = z.infer<typeof schema>

export const UpdateWorkflow: FC<Props> = ({ competition, workflow }) => {
  const isLoading = useSelector((state: RootState) => state.createWorkflow.isLoading)
  const dispatch = useDispatch<AppDispatch>()
  const { _ } = useLingui()
  const formRef = useRef<UseFormReturn<any, any, undefined>>(null)
  const [isArchived, setIsArchived] = useState(workflow?.status === 'archived')

  const statusOptions = [
    { key: 'active', label: _(msg`مفعلة`) },
    { key: 'archived', label: _(msg`مؤرشفة`) }
  ]

  const onSubmit = async (data: UpdateWorkflowSchemaType) => {
    try {
      await dispatch(
        updateWorkflow({
          competitionId: Number(competition?.id),
          workflowId: Number(workflow?.id),
          name: data.name,
          description: data.description,
          end_date: data.end_date,
          status: data.status as 'active' | 'archived'
        })
      ).unwrap()
      toast.success(_(msg`تم تحديث سير المشاركة بنجاح`))
      await dispatch(
        fetchWorkflowDetail({
          competitionId: Number(competition?.id),
          workflowId: Number(workflow?.id)
        })
      ).unwrap()
    } catch (error: any) {
      if (error.status === 'error') {
        const message = error.message ?? ''
        toast.dismiss()
        toast.error(_(workflowErrors[message]))
      }
    }
  }

  useEffect(() => {
    if (formRef.current) {
      const subscription = formRef.current.watch((value) => {
        setIsArchived(value.status === 'archived' && workflow?.status === 'archived')
      })

      return () => subscription.unsubscribe()
    }
  }, [formRef, workflow])

  return (
    <Form
      ref={formRef}
      className="!gap-3"
      schema={schema}
      onSubmit={onSubmit}
      defaultValues={{ year: competition?.name, ...workflow }}
    >
      <div className="flex flex-wrap gap-x-8 max-w-5xl gap-y-4 mt-5 w-full items-center">
        <div className="min-w-full md:min-w-[45%] lg:min-w-[27%]">
          <TextInput
            isDisabled
            className="w-full rounded-lg"
            name="year"
            size="lg"
            label={_(msg`سنة المسابقة`)}
            placeholder={_(msg`سنة المسابقة`)}
          />
        </div>

        <div className="min-w-full md:min-w-[45%] lg:min-w-[27%]">
          <TextInput
            className="w-full rounded-lg"
            name="name"
            size="lg"
            label={_(msg`الاسم`)}
            placeholder={_(msg`الاسم`)}
          />
        </div>

        <div className="min-w-full md:min-w-[45%] lg:min-w-[27%]">
          <SelectInput
            containerClassName="gap-3"
            size="lg"
            name="status"
            options={statusOptions}
            label={_(msg`الحالة`)}
          />
        </div>

        <div className="min-w-full md:min-w-[45%] lg:min-w-[27%]">
          <DateInput
            labelClassName=" text-natural_icon_normal font-sub-heading-h6-ar"
            className="!h-auto rounded-lg bg-primary_fill_soft_overlay"
            size="lg"
            name="end_date"
            label={_(msg`تاريخ الانتهاء`)}
          />
        </div>
      </div>
      <TextareaInput
        size="lg"
        minRows={7}
        name="description"
        label={_(msg`الوصف`)}
        containerClassName="max-w-4xl"
        className="w-full rounded-lg"
        placeholder={_(msg`الوصف`)}
      />

      <div className="flex w-full mt-4 justify-end">
        <ButtonVariant
          type="submit"
          size="lg"
          variant="secondary"
          disabled={isLoading || isArchived}
          isLoading={isLoading}
        >
          {_(msg`حفظ التغيرات`)}
        </ButtonVariant>
      </div>
    </Form>
  )
}
