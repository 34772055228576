import { useLingui } from '@lingui/react'
import { Progress } from '@nextui-org/react'
import React from 'react'

type Indicator = {
  name: string
  progressValue: number
  valueText?: string
  progressValue2: number
  valueText2?: string
}

type IndicatorColor = {
  name: string
  color: string
  value: React.ReactNode
}

interface ProgressListProps {
  indicators: Indicator[]
  indicatorsColor?: IndicatorColor[]
  isRtl?: boolean
}

export default function ProgressList({
  indicators,
  indicatorsColor = [],
  isRtl = document.dir === 'rtl'
}: ProgressListProps) {
  const { _ } = useLingui()

  return (
    <div className="flex flex-col gap-4 justify-between h-full">
      <div className="mt-4 space-y-4">
        {indicators.map((indicator, index) => (
          <div key={index} className="flex justify-between items-center">
            <span className="w-18">{indicator.name}</span>
            <div className="flex flex-1 flex-col ">
              <div className="flex flex-row items-center">
                <Progress
                  classNames={{
                    track: 'bg-transparent',
                    indicator: indicatorsColor[0].color
                  }}
                  className={`transform ${isRtl ? 'scale-x-[-1]' : ''} mt-1 bg-none flex-1 mx-2`}
                  value={indicator.progressValue}
                  radius="none"
                  size="md"
                />
                <span>{indicator.progressValue + '%'}</span>
              </div>
              <div className="flex flex-row items-center">
                <Progress
                  classNames={{
                    track: 'bg-transparent',
                    indicator: indicatorsColor[1].color
                  }}
                  className={`transform ${isRtl ? 'scale-x-[-1]' : ''} mt-1 bg-none flex-1 mx-2 `}
                  value={indicator.progressValue2}
                  radius="none"
                  size="md"
                />
                <span>{indicator.progressValue2.toFixed(0) + '%'}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      {indicatorsColor.length > 0 && (
        <div className="flex flex-row justify-center items-center gap-4 mt-4 bottom-0">
          {indicatorsColor.map((indicator, index) => (
            <div key={index} className="flex items-center gap-2">
              <div className={`w-2 h-2 rounded-full mr-2 ${indicator.color}`} />
              <span>{indicator.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
