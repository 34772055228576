import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Pagination, Spinner } from '@nextui-org/react'
import { useLanguage } from 'hooks/LanguageProvider'
import { FC, ReactNode } from 'react'

interface DataCardsProps {
  data: any[]
  isLoading: boolean
  page: number
  per_page: number
  total_pages: number
  onPageChange: (page: number) => void
  renderCard: (item: any) => ReactNode
  showPagination?: boolean
}

const DataCards: FC<DataCardsProps> = ({
  data,
  isLoading,
  page,
  total_pages,
  onPageChange,
  renderCard,
  showPagination = true
}) => {
  const { _ } = useLingui()
  const { language } = useLanguage()
  const isAr = language === 'ar'

  return (
    <div className="flex flex-col min-h-[60vh] w-full">
      <div className="grid grid-col-1 sm:grid-cols-2 xl:grid-cols-3 gap-6 mb-4">
        {isLoading ? (
          <div className="flex justify-center items-center w-full h-full">
            <Spinner size="lg" />
          </div>
        ) : (
          data &&
          !!data.length &&
          data.map((item, index) => <div key={index.toString()}>{renderCard(item)}</div>)
        )}
      </div>

      {!data?.length && (
        <div className="flex justify-center items-center w-full h-full">
          <p>{_(msg`لايوجد نتائج`)}</p>
        </div>
      )}
      {showPagination && (
        <div className="mt-8 flex justify-end w-full">
          <Pagination
            className={`gap-5 p-6 text-start`}
            classNames={{
              wrapper: 'gap-8',
              prev: isAr ? 'rotate-180' : undefined,
              next: isAr ? 'rotate-180' : undefined
            }}
            dir={isAr ? 'rtl' : 'ltr'}
            disableAnimation
            initialPage={page}
            onChange={onPageChange}
            radius="full"
            showControls
            total={total_pages}
            variant="light"
          />
        </div>
      )}
    </div>
  )
}

export default DataCards
