import Label from 'components/label/Label'

interface InputFieldProps {
  name?: string
  label: string
  isDisabled?: boolean
  value?: string
  width?: string
  textArea?: boolean
}

const InputField: React.FC<InputFieldProps> = ({ label, isDisabled, value, textArea, ...other }) => {
  return (
    <div className="flex flex-col justify-start gap-2">
      <Label label={label} />
      <div className={`flex py-1.5 px-3 bg-natural_fill_disabled bg-opacity-65 border-natural_stroke_normal_disabled border-2 w-full max-h-40 overflow-y-auto rounded-lg font-sub-heading-h6-ar text-natural_icon_normal placeholder:text-primary_lv2_text_normal ${textArea? 'items-start min-h-20':'items-center min-h-10'}`}>
        <p>{value}</p>
      </div>
    </div>
  )
}

export default InputField