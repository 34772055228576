import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import ButtonVariant from 'components/button-variant/button'
import ModalHeader from 'components/modal/modal-header'
import ModalLayout from 'components/modal/modal-layout'
import SelectField from 'components/select-field/select-field'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchWorkflows } from 'store/slices/workFlowSlice'

interface Props {
  year: number
  onMove: (id: string) => void
  onCancel: () => void
}

const MoveAppModal: React.FC<Props> = ({ year, onMove, onCancel }) => {
  const dispatch = useDispatch<AppDispatch>()
  const { workflows } = useSelector((state: RootState) => state.workflows)
  const [workflow, setWorkflow] = React.useState('')
  const { _ } = useLingui()

  useEffect(() => {
    if (year) dispatch(fetchWorkflows({ competitionId: Number(year) }))
  }, [dispatch, year])

  return (
    <ModalLayout
      appStyle
      onBack={onCancel}
      size="lg"
      header={
        <ModalHeader
          headerText={msg`نقل لسير المشاركة`}
          subheaderText={<p>{_(msg`يرجى تحديد سير المشاركة التي تود نقل نموذج المشاركة لها`)}</p>}
        />
      }
      body={
        <div className="flex h-28">
          <SelectField
            containerClassName="!w-full"
            label={_(msg`سير المشاركة`)}
            placeholder={_(msg`سير المشاركة`)}
            options={
              workflows
                ?.filter((e) => e.status === 'active')
                .map((e) => ({ key: `${e.id}`, label: e.name })) || []
            }
            value={workflow}
            onChange={(e) => setWorkflow(e as string)}
          />
        </div>
      }
      footer={
        <>
          <div className="flex items-center gap-2">
            <ButtonVariant
              isDisabled={!workflow}
              onClick={() => onMove(workflow)}
              variant="secondary"
            >
              {_(msg`تأكيد النقل`)}
            </ButtonVariant>
          </div>
        </>
      }
    />
  )
}

export default MoveAppModal
