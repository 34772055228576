import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import ButtonVariant from 'components/button-variant/button'
import DataTable from 'components/data-table/data-table'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import AccountsFilters from './components/AccountsFilters'
import Alert from 'components/alert/alert'
import {
  fetchUsersAccounts,
  setPage,
  updateUserAccountStatus,
  sendVerificationEmail
} from 'store/slices/usersAccountsSlice'
import SendVerificationEmailModal from './modals/SendVerificationEmailModal'
import AccActivationModal from './modals/AccActivationModal'
import AddUserModal from './modals/AddUserModal'
import RenderCell from './components/renderCell'
import { yyyy_mm_dd } from 'utils/time'

export type Filters = {
  'countries[]': string[]
  'types[]': string[]
  completed_after_migration: string
  migrated_from_old_system: string
  active: string
  created_at_from: string
  created_at_to: string
}

const DEFAULT_PAGE = 1
const PER_PAGE = 10

const AdminUsersAccounts: FC = () => {
  const { _ } = useLingui()
  const direction = useSelector((state: RootState) => state.layout.direction)
  const dispatch = useDispatch<AppDispatch>()

  const { accounts, isLoading, page, total_pages } = useSelector(
    (state: RootState) => state.usersAccounts
  )

  const [isAddedByAdmin, setIsAddedByAdmin] = useState<number>(0)
  const [activeTab, setActiveTab] = useState<string>('0')
  const [popoverVisible, setPopoverVisible] = useState<null | number>(null)

  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false)
  const [showVerificationConfirm, setShowVerificationConfirm] = useState<boolean>(false)
  const [showActivationConfirm, setShowActivationConfirm] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<any>()

  const [showAlert, setShowAlert] = useState<{ type: 'success' | 'error'; message: string } | null>(
    null
  )

  const [key, setKey] = useState<number>(0)

  const [filters, setFilters] = useState<Filters>({
    'countries[]': [],
    'types[]': [],
    completed_after_migration: '',
    migrated_from_old_system: '',
    active: '',
    created_at_from: '',
    created_at_to: ''
  })

  const [searchValue, setSearchValue] = useState<string>('')

  const buildFilterQuery = (
    filters: Filters,
    activeTab: string,
    searchValue: string,
    page: number,
    per_page: number
  ) => {
    const filterQuery = {
      'countries[]': filters['countries[]'] || undefined,
      'types[]': filters['types[]'] || undefined,
      completed_after_migration: filters.completed_after_migration || undefined,
      migrated_from_old_system: filters.migrated_from_old_system === '1' || undefined,
      active: filters.active || undefined,
      created_at_from: filters.created_at_from ? yyyy_mm_dd(filters.created_at_from) : undefined,
      created_at_to: filters.created_at_to ? yyyy_mm_dd(filters.created_at_to) : undefined,
      added_by_admin: activeTab === '1' ? 1 : 0,
      search: searchValue || undefined,
      page,
      per_page
    }

    return Object.fromEntries(
      Object.entries(filterQuery).filter(([_, value]) => value !== undefined && value !== '')
    )
  }

  useEffect(() => {
    setKey((prevKey) => prevKey + 1)
  }, [direction, activeTab, popoverVisible, accounts])

  useEffect(() => {
    onCancel()
  }, [activeTab])

  const handlePageChange = (newPage: number) => {
    dispatch(setPage(newPage))
    const cleanFilterQuery = buildFilterQuery(filters, activeTab, searchValue, newPage, PER_PAGE)
    dispatch(fetchUsersAccounts(cleanFilterQuery))
  }

  const onFilter = () => {
    dispatch(setPage(1))
    const cleanFilterQuery = buildFilterQuery(filters, activeTab, searchValue, 1, PER_PAGE)
    dispatch(fetchUsersAccounts(cleanFilterQuery))
  }

  const onCancel = () => {
    setSearchValue('')
    setFilters({
      'countries[]': [],
      'types[]': [],
      completed_after_migration: '',
      migrated_from_old_system: '',
      active: '',
      created_at_from: '',
      created_at_to: ''
    })

    dispatch(
      fetchUsersAccounts({
        page: 1,
        per_page: 10,
        added_by_admin: activeTab === '1' ? 1 : 0
      })
    )
  }

  const registeredColumns = [
    { key: 'student_personal_data', label: _(msg`البيانات الشخصية للمستخدم`) },
    { key: 'registration_date', label: _(msg`تاريخ التسجيل`) },
    { key: 'account_status', label: _(msg`حالة الحساب`) },
    { key: 'email_verified', label: _(msg`التحقق من البريد الإلكتروني`) },
    { key: 'country', label: _(msg`الدولة`) },
    { key: 'options', label: _(msg`الإعدادات`) }
  ]

  const adminColumns = [
    { key: 'student_personal_data', label: _(msg`البيانات الشخصية للمستخدم`) },
    { key: 'registration_date', label: _(msg`تاريخ الإضافة`) },
    { key: 'account_status', label: _(msg`حالة الحساب`) },
    { key: 'email_verified', label: _(msg`التحقق من البريد الإلكتروني`) },
    { key: 'added_by', label: _(msg`إضافته من قبل`) },
    { key: 'options', label: _(msg`الإعدادات`) }
  ]

  const handleOptionClick = (option: string, item: any) => {
    switch (option) {
      case 'updateAccountStatus':
        setShowActivationConfirm(true)
        setSelectedItem(item)
        break
      case 'sendActivationEmail':
        setShowVerificationConfirm(true)
        setSelectedItem(item)
        break
      default:
        break
    }
    setPopoverVisible(null)
  }

  const handleModalConfirm = async (option: string) => {
    switch (option) {
      case 'updateAccountStatus':
        try {
          const result = await dispatch(
            updateUserAccountStatus({ userId: selectedItem.id, active: !selectedItem.active })
          ).unwrap()
          if (result.status === 200) {
            const successMessage = _(msg`تم تحديث الحالة بنجاح`)
            setShowAlert?.({ type: 'success', message: successMessage })
          }
        } catch (error: any) {
          const errorMessage = error.message || _(msg`حدث خطأ ما`)
          setShowAlert?.({ type: 'error', message: errorMessage })
        }
        setShowActivationConfirm(false)
        break

      case 'sendActivationEmail':
        try {
          const result = await dispatch(sendVerificationEmail(selectedItem.id)).unwrap()
          if (result.status === 200) {
            const successMessage = _(msg`لقد تم إرسال رابط التفعيل بنجاح`)
            setShowAlert?.({ type: 'success', message: successMessage })
          }
        } catch (error: any) {
          const errorMessage = error.message || _(msg`حدث خطأ ما`)
          setShowAlert?.({ type: 'error', message: errorMessage })
        }
        setShowVerificationConfirm(false)
        break

      default:
        break
    }
    setPopoverVisible(null)
  }

  return (
    <div className="flex flex-col w-full gap-4">
      {showAlert && (
        <div className="pt-4">
          <Alert
            mainText={showAlert?.message}
            sizeMainText="md"
            weightMainText="normal"
            onClose={() => setShowAlert(null)}
            type={showAlert?.type || 'success'}
          />
        </div>
      )}

      <AccountsFilters
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setIsAddedByAdmin={setIsAddedByAdmin}
        filters={filters}
        setFilters={setFilters}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        onFilter={onFilter}
        onCancel={onCancel}
        setShowAlert={setShowAlert}
      />

      {activeTab === '1' && (
        <div className="w-full flex justify-end">
          <ButtonVariant variant="secondary" onClick={() => setShowAddUserModal(true)}>
            <p>{_(msg`إضافة مستخدم جديد`)}</p>
          </ButtonVariant>
        </div>
      )}

      <DataTable
        key={key}
        columns={isAddedByAdmin ? adminColumns : registeredColumns}
        data={accounts || []}
        isLoading={isLoading}
        page={page}
        totalPages={total_pages}
        onPageChange={handlePageChange}
        renderCell={(item, columnKey) => (
          <RenderCell
            item={item}
            columnKey={columnKey}
            popoverVisible={popoverVisible}
            setPopoverVisible={setPopoverVisible}
            handleOptionClick={handleOptionClick}
          />
        )}
      />

      {showVerificationConfirm && (
        <SendVerificationEmailModal
          onCancel={() => setShowVerificationConfirm(false)}
          onConfirm={() => handleModalConfirm('sendActivationEmail')}
        />
      )}
      {showActivationConfirm && (
        <AccActivationModal
          isActive={selectedItem.active}
          onCancel={() => setShowActivationConfirm(false)}
          onConfirm={() => handleModalConfirm('updateAccountStatus')}
        />
      )}
      {showAddUserModal && (
        <AddUserModal
          onCancel={() => setShowAddUserModal(false)}
          setShowAlert={setShowAlert}
          setShowAddUserModal={setShowAddUserModal}
        />
      )}
    </div>
  )
}

export default AdminUsersAccounts
