import React from 'react'

interface FileListIconProps {
  fill?: string
}

const FileListIcon: React.FC<FileListIconProps> = ({ fill = '#A1A1AA' }) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4776_176521)">
        <path
          d="M13.1693 14.6663H3.83594C3.3055 14.6663 2.7968 14.4556 2.42172 14.0806C2.04665 13.7055 1.83594 13.1968 1.83594 12.6663V1.99967C1.83594 1.82286 1.90618 1.65329 2.0312 1.52827C2.15622 1.40325 2.32579 1.33301 2.5026 1.33301H11.8359C12.0127 1.33301 12.1823 1.40325 12.3073 1.52827C12.4324 1.65329 12.5026 1.82286 12.5026 1.99967V9.99967H15.1693V12.6663C15.1693 13.1968 14.9586 13.7055 14.5835 14.0806C14.2084 14.4556 13.6997 14.6663 13.1693 14.6663ZM12.5026 11.333V12.6663C12.5026 12.8432 12.5728 13.0127 12.6979 13.1377C12.8229 13.2628 12.9925 13.333 13.1693 13.333C13.3461 13.333 13.5157 13.2628 13.6407 13.1377C13.7657 13.0127 13.8359 12.8432 13.8359 12.6663V11.333H12.5026ZM11.1693 13.333V2.66634H3.16927V12.6663C3.16927 12.8432 3.23951 13.0127 3.36453 13.1377C3.48956 13.2628 3.65913 13.333 3.83594 13.333H11.1693ZM4.5026 4.66634H9.83594V5.99967H4.5026V4.66634ZM4.5026 7.33301H9.83594V8.66634H4.5026V7.33301ZM4.5026 9.99967H7.83594V11.333H4.5026V9.99967Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4776_176521">
          <rect width="16" height="16" fill={fill} transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FileListIcon