import React, { useState } from 'react'
import Button from 'components/base/button'
import ArrowIcon from 'assets/icons/ArrowIcon'
import { Path } from 'pages/landing/models'
import { useSelector } from 'react-redux'
import { Trans } from '@lingui/react'
import { msg } from '@lingui/macro'
import { RootState } from 'store'
import { useNavigate } from 'react-router-dom'

interface PathCardProps {
  path: Path
  isActive: boolean
  handleSetActive: (id: number) => void
}

const PathCard: React.FC<PathCardProps> = ({ path, isActive, handleSetActive }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const navigate = useNavigate()
  const direction = useSelector((state: RootState) => state.layout.direction)

  const activeStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${path.bgImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    width: '100%',
    transition: 'transform 2s ease-in-out',
    transform: isHovered ? 'scale(1.2)' : 'scale(1)'
  }

  const inactiveStyle = {
    backgroundImage: `linear-gradient(rgba(42, 172, 173, 0), rgba(43, 173, 173, 1)), url(${path.bgImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%'
  }

  const handleSignUpClick = () => {
    navigate(`/register`)
  }

  return (
    <div
      className={` md:h-[720px] transition-all ease-in-out overflow-hidden duration-[2000ms] rounded-[25px] group ${
        isActive ? 'w-full' : 'md:w-[130px]'
      }`}
    >
      <div
        style={isActive ? {} : inactiveStyle}
        className={`relative flex flex-col items-center justify-end h-full w-full transition-all ease-in-out duration-500`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="absolute top-0 left-0 -z-10" style={isActive ? activeStyle : {}}></div>
        <div
          className={`md:h-2/3 flex w-full transition-all md:transition-none ease-in-out duration-[2000ms] z-10 ${
            isActive
              ? `md:pl-10 md:pr-20 pb-16 pt-16 md:pt-0 px-6 justify-between space-y-56 md:space-y-0 flex-col`
              : `p-6 md:pb-16 justify-between md:justify-end items-center flex-row-reverse md:flex-col`
          }`}
        >
          <div className="flex flex-col space-y-8 w-full md:w-auto *:mx-2 md:*:,x-0">
            <h3
              className={`text-customWhite text-4xl md:text-5xl font-bold md:whitespace-nowrap text-start ${
                isActive ? '' : 'md:-mt-[60%] md:-rotate-90 text-center'
              }`}
            >
              <Trans id={path.title.id} />
            </h3>

            <div className={`space-y-8 ${isActive ? '' : 'hidden'}`}>
              <p
                className={`text-customWhite font-helveticaNeueArabic font-bold text-justify text-xl ${
                  direction === 'rtl' ? 'md:pl-16' : 'md:pr-16'
                }`}
              >
                <Trans id={path.description.id} />
              </p>
              <div className="w-fit">
                {path.showBtn && (
                  <Button
                    placeholder={msg`سجل الآن`}
                    btnStyle="primary"
                    onClick={() => handleSignUpClick()}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="flex md:justify-end md:content-end">
            <div
              className={`p-2 bg-customWhite70 w-fit rounded-full cursor-pointer 
              ${isActive ? '-rotate-90 md:rotate-0' : 'rotate-90 md:rotate-180 md:mt-6'}
                `}
              onClick={() => handleSetActive(path.id)}
            >
              <ArrowIcon fill="rgba(63, 63, 70, 1)" width="50" height="50" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PathCard
