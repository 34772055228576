import { msg } from '@lingui/macro'
import MetricCard from 'components/card/MetricCardLayout'
import React from 'react'
import NumericCard from 'components/card/NumericCard'
import AwardsSection from 'components/card/AwardsSection'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Award } from 'api/types'

const Points = () => {
  const { pointsCount, studentsCount } = useSelector((state: RootState) => state.teacherStats)

  console.log(studentsCount)
  const awardsList: Award[] = []

  return (
    <div className="w-full flex flex-col gap-6">
      <div className="w-full flex flex-col md:flex-row items-center justify-center gap-6 pt-9 md:py-9">
        <MetricCard color="bg-natural_lv1_text_normal">
          <NumericCard
            title={msg`عدد النقاط`.id}
            numericValue={pointsCount}
            color="text-natural_lv1_text_normal"
            unit={msg`نقطة`.id}
            emptyMsg={msg`حتى الآن، لم تحصل على أي نقاط بعد.`.id}
          />
        </MetricCard>

        <MetricCard color="bg-ithra_purple">
          <NumericCard
            title={msg`عدد الطلاب`.id}
            numericValue={studentsCount}
            color="text-ithra_purple"
            unit={msg`طالب`.id}
            emptyMsg={msg`حتى الآن، لم يتم تسجيل أي طالب من خلال رمزك.`.id}
          />
        </MetricCard>
      </div>

      <AwardsSection awardsList={awardsList} />
    </div>
  )
}

export default Points
