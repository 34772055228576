import { Trans } from '@lingui/macro'
import { FaClipboard } from 'react-icons/fa' // Clipboard icon
import { FaShareAlt } from 'react-icons/fa' // Share icon
import { FaUserFriends } from 'react-icons/fa' // Users icon for encouragement
import { FaCalculator } from 'react-icons/fa' // Input icon
import { FaSync } from 'react-icons/fa' // Calculator icon for points
import { FaAward } from 'react-icons/fa'

const HowToCalc = () => {
  const listClass = `flex items-center py-2 md:py-0`

  return (
    <div className="flex flex-col gap-10">
      <div>
        <h2 className="font-heading-h4-ar text-natural_lv1_text_normal">
          <Trans>كيفية حساب النقاط:</Trans>
        </h2>
        <p className="font-heading-h6-ar text-natural_icon_normal">
          <Trans>
            يتم احتساب النقاط من خلال مشاركة الرمز الخاص بك مع الطلاب . وبعدما يقوم الطالب بالتسجيل
            في المنصة والقيام بتعبئة نموذج المشاركة الخاص بسنة المسابقة الحالية ، يتيح النظام للطالب
            من إدخال رمز المعلم. وعندما يقوم بتقديم المشاركة التي تحوي على رمزك تُحسب النقاط لملفك
            الشخصي وتزداد مع كل مرحلة تترشح فيها المشاركة.
          </Trans>
        </p>
      </div>
      <div>
        <h2 className="font-heading-h4-ar text-natural_icon_normal">
          <Trans>طرق جمع النقاط واستبدالها بالجوائز:</Trans>
        </h2>
        <ul className="font-sub-heading-h6 text-primary_lv2_text_normal">
          <li className={listClass}>
            <span className="p-3.5">
              <FaClipboard className="text-ithra_purple" />
            </span>
            <p>
              <Trans>انسخ الرمز الخاص بك من خيار "الرمز الخاص بك"</Trans>
            </p>
          </li>
          <li className={listClass}>
            <span className="p-3.5">
              <FaShareAlt className="text-ithra_purple" />
            </span>
            <p>
              <Trans>
                شارك الرمز مع طلابك عبر وسائل التواصل الاجتماعي أو البوابات الإلكترونية الأخرى.
              </Trans>
            </p>
          </li>
          <li className={listClass}>
            <span className="p-3.5">
              <FaUserFriends className="text-ithra_purple" />
            </span>
            <p>
              <Trans>شجع الطلاب على التسجيل في المنصة باستخدام رمزك الخاص.</Trans>
            </p>
          </li>
          <li className={listClass}>
            <span className="p-3.5">
              <FaSync className="text-ithra_purple" />
            </span>
            <p>
              <Trans>يقوم الطلاب بإدخال الرمز الخاص بك من خلال استمارة المشاركة</Trans>
            </p>
          </li>
          <li className={listClass}>
            <span className="p-3.5">
              <FaCalculator className="text-ithra_purple" />
            </span>
            <p>
              <Trans>بعد تقديم المشاركة من قبل الطالب يتم احتساب النقاط.</Trans>
            </p>
          </li>
          <li className={listClass}>
            <span className="p-3.5">
              <FaAward className="text-ithra_purple" />
            </span>
            <p>
              <Trans>استمر بمشاركة رمزك لمضاعفة فرصة فوزك بجائزة سفراء القراءة لهذا العام.</Trans>
            </p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default HowToCalc
