import React, { useEffect, useState } from 'react'
import { msg } from '@lingui/macro'
import ReportHeader from './ReportHeader/ReportHeader'
import { useLingui } from '@lingui/react'
import { AppDispatch, RootState } from 'store'
import { useDispatch } from 'react-redux'
import ModalLayout from 'components/modal/modal-layout'
import { useSelector } from 'react-redux'
import ReportWrapper from './ReportContent'
import ExportConfirmation from './ModalExport/ExportConfirmation'
import Export from './ModalExport/Export'
import { getLocalizedName } from 'utils/getLocalizedName'
import { useReportDataIthraa } from './useReportsIthraa'
import IthraaReportsListTable from './ReportTable/IthraaReportsListTable'
import {
  fetchIthraaApplicationStatistics,
  setExportIthraaSuccess
} from 'store/slices/ithraaReports'
import FilterFormIthraa from './ReportHeader/FilterForm'
import {
  fetchIthraaApplicationStatisticsCharts,
  setExportSuccess
} from 'store/slices/ithraaChartsReportSlice'
import { getNameByLanguage } from 'utils/getNameByLanguage'
import { Spinner } from '@nextui-org/react'
export default function IthraaReports() {
  const dispatch = useDispatch<AppDispatch>()
  const [reportModal, setreportModal] = useState(false)
  const [currentTab, setcurrentTab] = useState('table')
  const [showSideBarFilter, setshowSideBarFilter] = useState(false)
  const { _ } = useLingui()
  const { i18n } = useLingui()
  const {
    data: reports,
    isLoading,
    exportSuccess
  } = useSelector((state: RootState) => state.chartsIthraaReports)
  const { exportSuccess: exportSuccessTable } = useSelector(
    (state: RootState) => state.ithraaReports
  )
  const { competitionYear } = useSelector((state: RootState) => state.competitionYearNavbar)
  const durationType = useSelector(
    (state: RootState) => state.reportsHeadCoordinatorFilter.durationType
  )
  const { me } = useSelector((state: RootState) => state.me)

  const beginDate = useSelector((state: RootState) => state.reportsHeadCoordinatorFilter.beginDate)
  const endDate = useSelector((state: RootState) => state.reportsHeadCoordinatorFilter.endDate)
  const tabsTypeA = [
    { id: 'drafts', label: _(msg` محفوظة كمسودة`.id) },
    { id: 'inProgress', label: _(msg`تم تسليم المشاركة`.id) }
  ]
  const tabsTypeB = [
    { id: 'byAllAdministrations', label: _(msg`كل الإدارات `) },
    { id: 'byTopAdministrations', label: _(msg`أعلى 5 إدارات `) }
  ]
  const tabsTypeC = [
    { id: 'top', label: _(msg`كل الدول`) },
    { id: 'all', label: _(msg`أعلى 5 دول`) }
  ]
  const tabsTypeD = [
    {
      id: 'country',
      label: getNameByLanguage(
        {
          ar: 'السعودية',
          en: 'Saudi Arabia'
        } as any,
        i18n.locale as any
      )
    },
    { id: 'othersContries', label: _(msg`كل الدول`) }
  ]
  const indics = [
    {
      name: getLocalizedName(
        {
          ar: 'ذكر',
          en: 'Male'
        },
        i18n.locale as any
      ),
      color: 'bg-information_default_fill_normal',
      value: 20
    },
    {
      name: getLocalizedName(
        {
          ar: 'أنثى',
          en: 'Female'
        },
        i18n.locale as any
      ),
      color: 'bg-secondary_fill_normal_pressed',
      value: 44
    }
  ]
  const handleExportModal = () => {
    setreportModal(!reportModal)
  }

  const fetchReports = () => {
    /* dispatch(
      fetchIthraaApplicationStatistics({
        competition_year_id: competitionYear?.id
      })
    ) */
    dispatch(
      fetchIthraaApplicationStatisticsCharts({
        competition_year_id: competitionYear?.id
      })
    )
  }
  useEffect(() => {
    fetchReports()
  }, [competitionYear])

  useEffect(() => {
    if (isLoading) {
      setshowSideBarFilter(false)
    }
  }, [isLoading])
  const {
    countryApplicationsCount,
    dataEducationStagesProgress,
    dataCountriesDrafts,
    dataCountriesDInprogress,
    countryTopTeachers,
    otherCountriesTopTeachers,
    genderCountriesApplications,
    educationStageByContry,
    educationStageByContryChart,
    dataRegions,
    countriesApplications,
    topCountries,
    educationStageByOthersContries,
    countryTotalSagesApplication,
    educationStageByOthersCountriesChart,
    topEducationAdministrations,
    administrativesApplications,

    otherCountriesTopCoordinators
  } = useReportDataIthraa(reports as any, i18n.locale as 'ar' | 'en', {
    ar: 'السعودية',
    en: 'Saudi Arabia'
  })
  const onApplyFilter = () => {
    let prams =
      durationType + '' == 'custom'
        ? {
            competition_year_id: competitionYear?.id,
            submission_at_from: new Date(beginDate as string),
            submission_at_to: new Date(endDate as string)
          }
        : {
            competition_year_id: competitionYear?.id
          }
    dispatch(fetchIthraaApplicationStatistics(prams))
  }
  const onResetFilter = () => {
    dispatch(
      fetchIthraaApplicationStatistics({
        competition_year_id: competitionYear?.id
      })
    )
  }
  const onBack = () => {
    dispatch(setExportSuccess(false))
    dispatch(setExportIthraaSuccess(false))
  }
  useEffect(() => {
    if (exportSuccess || exportSuccessTable) {
      setreportModal(false)
    }
  }, [exportSuccess, exportSuccessTable])
  const tabs = [
    {
      id: 'table',
      label: _(msg`تقرير جدولي`)
    },
    {
      id: 'charts',
      label: _(msg`تقرير رسومي`)
    }
  ]
  const onChangeTab = (tab: string) => {
    setcurrentTab(tab)
  }
  const onOpenFilter = () => {
    setshowSideBarFilter(true)
  }
  const onCloseSidebar = () => {
    setshowSideBarFilter(false)
  }
  return (
    <div>
      <div>
        <ReportHeader
          hideFilter={currentTab == 'charts'}
          onClickFilter={onOpenFilter}
          onChangeTab={onChangeTab}
          tabs={tabs}
          includeTabs
          onResetFilter={onResetFilter}
          onApplyFilter={onApplyFilter}
          onrefresh={fetchReports}
          handleExportModal={handleExportModal}
        />
        {currentTab == 'charts' ? (
          isLoading ? (
            <div className="flex flex-1 w-full justify-center">
              <Spinner size="lg" color="primary"></Spinner>
            </div>
          ) : (
            <div>
              <div className="flex flex-col lg:flex-row gap-4 justify-between">
                <div className="flex-1">
                  <ReportWrapper
                    cardTitle={_(msg`نماذج المشاركة بالدول`)}
                    includeTabs={true}
                    tabs={tabsTypeA}
                    contentType="countriesApplicationsStatus"
                    data={{ drafts: dataCountriesDrafts, inProgress: dataCountriesDInprogress }}
                  />
                </div>

                <div className="flex-1">
                  <ReportWrapper
                    totalApplications={countryTotalSagesApplication.all}
                    cardTitle={_(
                      msg`مشاركات ${getNameByLanguage(
                        {
                          ar: 'السعودية',
                          en: 'Saudi Arabia'
                        } as any,
                        i18n.locale as any
                      )} وباقي الدول`
                    )}
                    tabs={tabsTypeB}
                    contentType="countryApplicationsCount"
                    data={countryApplicationsCount ?? ([] as any)}
                  />
                </div>
              </div>

              <div className="flex flex-col lg:flex-row gap-4 justify-between">
                <div className="flex-1">
                  <ReportWrapper
                    cardTitle={_(
                      msg`المشاركات بالمناطق داخل ${getNameByLanguage(
                        {
                          ar: 'السعودية',
                          en: 'Saudi Arabia'
                        } as any,
                        i18n.locale as any
                      )}`
                    )}
                    //  includeTabs={true}
                    tabs={tabsTypeA}
                    contentType="countryRegionalApplications"
                    data={{ drafts: dataRegions, inProgress: dataRegions }}
                  />
                </div>

                <div className="flex-1">
                  <ReportWrapper
                    indicotors={indics}
                    cardTitle={_(msg`مشاركات الذكور والإناث`)}
                    tabs={tabsTypeB}
                    contentType="genderCountriesApplications"
                    data={genderCountriesApplications}
                  />
                </div>
              </div>
              <ReportWrapper
                isIthraa
                cardTitle={_(msg`الإدارات التعليمية`)}
                includeTabs={true}
                tabs={tabsTypeB}
                chartData={{
                  byAllAdministrations: administrativesApplications,
                  byTopAdministrations: topEducationAdministrations
                }}
                contentType="administrativesApplications"
                data={[]}
              />
              <div className="flex flex-col lg:flex-row gap-4 justify-between">
                <div className="flex-1">
                  <ReportWrapper
                    chartProgress={dataEducationStagesProgress}
                    cardTitle={_(msg`المشاركات بالدول`)}
                    includeTabs={true}
                    tabs={tabsTypeC}
                    contentType="countriesApplications"
                    data={{ top: topCountries, all: countriesApplications }}
                  />
                </div>

                <div className="flex-1">
                  <ReportWrapper
                    indicotors={indics}
                    includeTabs
                    totalApplications={countryTotalSagesApplication as any}
                    cardTitle={_(msg`المراحل التعليمية`)}
                    tabs={tabsTypeD}
                    contentType="countryStagesApplications"
                    data={{
                      country: educationStageByContry,
                      othersContries: educationStageByOthersContries
                    }}
                    chartData={
                      {
                        country: educationStageByContryChart,
                        othersContries: educationStageByOthersCountriesChart
                      } as any
                    }
                  />
                </div>
              </div>

              <ReportWrapper
                cardTitle={_(msg`أعلى المعلمين `)}
                includeTabs={true}
                tabs={tabsTypeD}
                chartData={
                  { country: countryTopTeachers, othersContries: otherCountriesTopTeachers } as any
                }
                contentType="countryTopTeachers"
                data={[]}
              />
              <ReportWrapper
                cardTitle={_(msg`أعلى المنسقين`)}
                includeTabs={true}
                tabs={tabsTypeD}
                chartData={
                  {
                    country: countryTopTeachers,
                    othersContries: otherCountriesTopCoordinators
                  } as any
                }
                contentType="countryTopCoordinators"
                data={[]}
              />
            </div>
          )
        ) : (
          <>
            <IthraaReportsListTable></IthraaReportsListTable>
          </>
        )}
      </div>
      {reportModal && (
        <ModalLayout forOthersPage backdrop="opaque" onBack={handleExportModal}>
          <Export ithraaTable={currentTab == 'table'}></Export>
        </ModalLayout>
      )}
      {showSideBarFilter && (
        <ModalLayout
          forOthersPage
          onClose={onCloseSidebar}
          isDrawer
          backdrop="opaque"
          showCloseButton
          showBackButton={false}
        >
          <FilterFormIthraa></FilterFormIthraa>
        </ModalLayout>
      )}
      {(exportSuccess || exportSuccessTable) && (
        <ExportConfirmation
          onBack={onBack}
          title={_(msg`تم إصدار تقريرك بنجاح`)}
          subtitle={_(msg`تم إرسال التقرير إلى بريدك الإلكتروني`)}
        ></ExportConfirmation>
      )}{' '}
    </div>
  )
}
