import React from 'react'
import InformationIcon from 'assets/icons/InformationIcon'
import { Tooltip } from 'components/toolTip/Tooltip'
import { Trans } from '@lingui/react'

interface LabelProps {
  label: string
  tooltipInfoText?: string,
  className?:string,
  labelClassName?:string
}

const Label: React.FC<LabelProps> = ({ tooltipInfoText, label,className,labelClassName }) => {
  return (
    <div className="flex gap-1 items-center">
      {tooltipInfoText && (
        <Tooltip className={className} showArrow={true} closeDelay={1000} content={tooltipInfoText}>
          <div>
            <InformationIcon />
          </div>
        </Tooltip>
      )}
      <p className={`font-sub-heading-h6-ar text-natural_icon_normal capitalize ${labelClassName}`}>
        <Trans id={label} />
      </p>
    </div>
  )
}

export default Label