import { msg } from '@lingui/macro'
import { formatTime } from 'utils/time'
import { useLingui } from '@lingui/react'
import { ActivateRequest } from 'api/types'
import AppActions from 'components/apps/Actions'
import AppStatus from 'components/apps/Status'
import dayjs from 'dayjs'
import { ApplicationStatus } from 'store/slices/applicationsSlice'
import LocalizedNameTableCell from './LocalizedNameTableCell'
import { amPm } from 'utils/time'

interface Props {
  requestItem: ActivateRequest
  columnKey: any
  language?: 'en' | 'ar'
}

export const RenderCell = ({ requestItem, columnKey, language }: Props) => {
  const { _ } = useLingui()
  // @ts-ignore
  const cellValue = requestItem[columnKey]
  switch (columnKey) {
    case 'coordinator_information':
      return (
        <div className=" flex flex-col gap-0.5">
          <span>{requestItem.name}</span>
          <span className="text-sm">{requestItem.email}</span>
          <span
            className={`text-sm [unicode-bidi:bidi-override] [direction:ltr] ${language == 'en' ? 'text-left' : 'text-right'}`}
          >
            {requestItem.phone}
          </span>
        </div>
      )
    case 'coordinator_country':
      return (
        <LocalizedNameTableCell localizedName={requestItem?.coordinator?.country?.name ?? null} />
      )
    case 'education_administration':
      return (
        <LocalizedNameTableCell
          localizedName={
            requestItem.coordinator?.all_education_administrations
              ? {
                  ar: _(msg`جميع المراحل التعلمية `),
                  en: _(msg`جميع المراحل التعلمية `)
                }
              : requestItem?.coordinator?.education_administrations?.[0]?.name ?? null
          }
        />
      )
    case 'region':
      return (
        <LocalizedNameTableCell
          localizedName={
            requestItem.coordinator?.all_regions
              ? {
                  ar: _(msg`جميع المناطق`),
                  en: _(msg`جميع المناطق`)
                }
              : requestItem?.coordinator?.region?.name ?? null
          }
        />
      )
    case 'submission_date':
      return (
        <div className="flex flex-col">
          <span className={language == 'ar' ? 'text-right' : 'text-left'}>
            {requestItem.coordinator?.submission_at
              ? dayjs(new Date(requestItem.coordinator?.submission_at)).format('DD/MM/YYYY')
              : '-'}
          </span>
          <span className={language == 'ar' ? 'text-right' : 'text-left'}>
            {requestItem.coordinator?.submission_at
              ? amPm(new Date(requestItem.coordinator?.submission_at).toString())
              : '-'}
          </span>
        </div>
      )
    case 'request_status':
      return <AppStatus status={requestItem.coordinator?.status as ApplicationStatus} />
    case 'actions':
      return <AppActions to={`/app/activate-requests/review-request/${requestItem.id}`} />
    default:
      return cellValue
  }
}
