import React from 'react'
import { MessageDescriptor } from '@lingui/core'
import { useSelector } from 'react-redux'
import Link from 'components/base/link'
import ChevronIcon from 'assets/icons/ChevronIcon'
import { RootState } from 'store'

interface MenuItemProps {
  label: MessageDescriptor
  onClick: () => void
  textClass: string
}

const MenuItem: React.FC<MenuItemProps> = ({ label, onClick, textClass }) => {
  const direction = useSelector((state: RootState) => state.layout.direction)

  return (
    <li className="flex items-center justify-between cursor-pointer" onClick={onClick}>
      <Link placeholder={label} className={textClass} fontWeight="medium" />
      <div className={`lg:hidden mb-4 ${direction === 'rtl' ? '-rotate-90' : 'rotate-90'}`}>
        <ChevronIcon fill="rgba(63, 63, 70, 1)" width="24" height="24" />
      </div>
    </li>
  )
}

export default MenuItem
