import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import TextareaField from 'components/textarea-field/textarea-field'

type TextareaInputProps = {
  name: string
  label?: string
  helperText?: React.ReactNode
  isDisabled?: boolean
  maxLength?: number // You can pass maxLength as a prop to enable character counting
} & Omit<React.ComponentProps<typeof TextareaField>, 'name' | 'label' | 'helperText'>

const TextareaInput: FC<TextareaInputProps> = ({
  name,
  label,
  helperText,
  maxLength,
  isDisabled = false,
  value,
  ...other
}) => {
  const { control, clearErrors } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextareaField
          {...field}
          label={label || name}
          isDisabled={isDisabled}
          helperText={helperText}
          error={error ? error.message : null}
          maxLength={maxLength} // Pass maxLength to enable character counting
          {...other}
          onChange={(e) => {
            field.onChange(e)
            clearErrors(name)
          }}
        />
      )}
    />
  )
}

export default TextareaInput
