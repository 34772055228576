import {
  extendVariants,
  Accordion as nextUIAccordion,
  AccordionItem as nextUIAccordionItem
} from '@nextui-org/react'

export const Accordion = extendVariants(nextUIAccordion, {
  variants: {
    variant: {
      new: 'flex flex-col gap-3 border-0'
    }
  },
  defaultVariants: {
    variant: 'new'
  }
})

export const AccordionItem = extendVariants(nextUIAccordionItem, {
  variants: {
    variant: {
      new: {
        base: 'px-6 bg-natural_fill_normal_hover_pressed_active rounded-xl border-0',
        startContent: 'flex-shrink-0',
        indicator:
          'transition-transform data-[open=true]:-rotate-90 -rotate-180 rtl:-rotate-0 rtl:data-[open=true]:-rotate-90 text-natural_icon_normal data-[open=true]:text-primary_fill_normal_pressed',
        titleWrapper: 'flex-1 flex flex-col text-start',
        title: 'font-sub-heading-h5-ar text-natural_icon_normal',
        content: 'py-3',
        trigger: ['flex py-3 w-full h-full gap-3 outline-none items-center']
      }
    }
  },
  defaultVariants: {
    variant: 'new'
  }
})
