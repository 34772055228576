import React from 'react'

const TableSetting: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M1.33203 11.9998H5.9987V13.3332H1.33203V11.9998ZM1.33203 7.33317H7.33203V8.6665H1.33203V7.33317ZM1.33203 2.6665H14.6654V3.99984H1.33203V2.6665ZM13.7814 8.68317L14.552 8.4225L15.2187 9.57717L14.608 10.1138C14.6849 10.4783 14.6849 10.8547 14.608 11.2192L15.2187 11.7558L14.552 12.9105L13.7814 12.6498C13.508 12.8965 13.1827 13.0865 12.8247 13.2032L12.6654 13.9998H11.332L11.172 13.2025C10.8182 13.0869 10.4925 12.8984 10.216 12.6492L9.44536 12.9105L8.7787 11.7558L9.38937 11.2192C9.3125 10.8547 9.3125 10.4783 9.38937 10.1138L8.7787 9.57717L9.44536 8.4225L10.216 8.68317C10.4894 8.4365 10.8147 8.2465 11.1727 8.12984L11.332 7.33317H12.6654L12.8254 8.1305C13.1827 8.2465 13.508 8.43717 13.7814 8.68384V8.68317ZM11.9987 11.3332C12.1755 11.3332 12.3451 11.2629 12.4701 11.1379C12.5951 11.0129 12.6654 10.8433 12.6654 10.6665C12.6654 10.4897 12.5951 10.3201 12.4701 10.1951C12.3451 10.0701 12.1755 9.99984 11.9987 9.99984C11.8219 9.99984 11.6523 10.0701 11.5273 10.1951C11.4023 10.3201 11.332 10.4897 11.332 10.6665C11.332 10.8433 11.4023 11.0129 11.5273 11.1379C11.6523 11.2629 11.8219 11.3332 11.9987 11.3332Z"
        fill="#F9FAFB"
      />
    </svg>
  )
}

export default TableSetting
