import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchRegions } from 'store/slices/regionsSlice'

interface UseRegionsProps {
  countryId?: string
  countryCode?: string
}

export const useRegions = ({ countryId, countryCode }: UseRegionsProps = {}) => {
  const dispatch = useDispatch<AppDispatch>()
  const { regions, isLoading } = useSelector((state: RootState) => state.regions)

  useEffect(() => {
    // Fetch regions only if countryId is available
    if (countryId || countryCode) {
      dispatch(fetchRegions({ countryId, countryCode }))
    }
  }, [dispatch, countryId, countryCode])

  return { regions, isLoading }
}
