import { msg, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import DownloadIcon from 'assets/icons/DownloadIcon'
import FilterIcon from 'assets/icons/FilterIcon'
import Refresh from 'assets/icons/Refresh'
import ButtonVariant from 'components/button-variant/button'
import Filter from 'components/Filter'
import SearchInput from 'components/search-field/search-field'
import TabSwitcher from 'components/tabSwitcher/TabSwitcher'
import Typography from 'components/typography/typography'
import { formatDateNow } from 'utils/time'
import FilterLayout from './FilterLayout'
import { ChangeEvent } from 'react'
export default function ReportHeader({
  handleExportModal,
  onrefresh,
  hideLastUpdate,
  searchable,
  onResetFilter,
  onApplyFilter,
  onChangeTab,
  tabs,
  includeTabs,
  onClickFilter,
  hideFilter,
  onSearch,
  searchValue
}: {
  handleExportModal: () => void
  onrefresh?: () => void
  hideLastUpdate?: boolean
  searchable?: boolean
  onResetFilter?: () => void
  onApplyFilter?: () => void
  onChangeTab?: (tab: string) => void
  tabs?: {
    id: string
    label: string
  }[]
  includeTabs?: boolean
  onClickFilter?: () => void
  hideFilter?: boolean
  onSearch?: (e: ChangeEvent<HTMLInputElement>) => void,
  searchValue?:string
}) {
  const { _ } = useLingui()

  return (
    <div className="flex flex-col sm:flex-row justify-between pb-4 gap-4">
      <div className="flex flex-row justify-between items-center gap-2">
        {!hideLastUpdate && (
          <span>
            <Trans> أخر تحديث</Trans> {' ' + formatDateNow()}
          </span>
        )}
        <div className="flex flex-col sm:flex-row gap-4 ">
          {searchable && (
            <SearchInput value={searchValue??''}  placeholder={_(msg`البحث `)}  onChange={onSearch?onSearch:()=>{}} />
          )}
          {!onClickFilter && !hideFilter && (
            <Filter
              customFilterContent={<FilterLayout></FilterLayout>}
              filterFields={[]}
              onFilterApply={onApplyFilter as any}
              onFilterReset={onResetFilter as any}
            />
          )}

          <div className="flex flex-row gap-2 ">
            {onClickFilter && !hideFilter && (
              <ButtonVariant
                className="border rounded-xl"
                variant="clear"
                onClick={onClickFilter}
                size="md"
                radius="md"
                isIconOnly={true}
              >
                {<FilterIcon />}
              </ButtonVariant>
            )}
            {includeTabs && (
              <ButtonVariant
                variant="clear"
                radius="sm"
                className="bg-natural_fill_normal_hover_pressed_active border border-natural_stroke_normal_disabled rounded-md"
                onClick={handleExportModal}
              >
                <span className="text-black text-sm">
                  <Trans>تصدير</Trans>
                </span>
                <DownloadIcon></DownloadIcon>
              </ButtonVariant>
            )}
          </div>
        </div>
      </div>

      <div className="flex  flex-row gap-4 justify-between items-center">
        {!hideLastUpdate && (
          <ButtonVariant
            onClick={onrefresh}
            variant="light"
            className="text-secondary_lv1_text_normal_2"
          >
            <Typography className="text-secondary_lv1_text_normal_2">
              <Trans>تحديث التقارير</Trans>
            </Typography>
            <Refresh fill="currentColor" />
          </ButtonVariant>
        )}
        {!includeTabs && (
          <ButtonVariant variant="secondary" onClick={handleExportModal}>
            <Typography className="text-white">
              <Trans>تصدير التقارير</Trans>
            </Typography>
          </ButtonVariant>
        )}
        {includeTabs && (
          <TabSwitcher activeTab={'1'} onClick={onChangeTab as any} tabs={tabs ?? []} />
        )}
      </div>
    </div>
  )
}
