// countryList.ts

export const allCountries = [
  { iso2: 'af', name_en: 'Afghanistan', name_ar: 'أفغانستان' },
  { iso2: 'al', name_en: 'Albania', name_ar: 'ألبانيا' },
  { iso2: 'dz', name_en: 'Algeria', name_ar: 'الجزائر' },
  { iso2: 'as', name_en: 'American Samoa', name_ar: 'ساموا الأمريكية' },
  { iso2: 'ad', name_en: 'Andorra', name_ar: 'أندورا' },
  { iso2: 'ao', name_en: 'Angola', name_ar: 'أنغولا' },
  { iso2: 'ai', name_en: 'Anguilla', name_ar: 'أنغويلا' },
  { iso2: 'aq', name_en: 'Antarctica', name_ar: 'أنتاركتيكا' },
  { iso2: 'ag', name_en: 'Antigua and Barbuda', name_ar: 'أنتيغوا وباربودا' },
  { iso2: 'ar', name_en: 'Argentina', name_ar: 'الأرجنتين' },
  { iso2: 'am', name_en: 'Armenia', name_ar: 'أرمينيا' },
  { iso2: 'aw', name_en: 'Aruba', name_ar: 'أروبا' },
  { iso2: 'au', name_en: 'Australia', name_ar: 'أستراليا' },
  { iso2: 'at', name_en: 'Austria', name_ar: 'النمسا' },
  { iso2: 'az', name_en: 'Azerbaijan', name_ar: 'أذربيجان' },
  { iso2: 'bs', name_en: 'Bahamas', name_ar: 'البهاما' },
  { iso2: 'bh', name_en: 'Bahrain', name_ar: 'البحرين' },
  { iso2: 'bd', name_en: 'Bangladesh', name_ar: 'بنغلاديش' },
  { iso2: 'bb', name_en: 'Barbados', name_ar: 'بربادوس' },
  { iso2: 'by', name_en: 'Belarus', name_ar: 'بيلاروسيا' },
  { iso2: 'be', name_en: 'Belgium', name_ar: 'بلجيكا' },
  { iso2: 'bz', name_en: 'Belize', name_ar: 'بليز' },
  { iso2: 'bj', name_en: 'Benin', name_ar: 'بنين' },
  { iso2: 'bm', name_en: 'Bermuda', name_ar: 'برمودا' },
  { iso2: 'bt', name_en: 'Bhutan', name_ar: 'بوتان' },
  { iso2: 'bo', name_en: 'Bolivia', name_ar: 'بوليفيا' },
  { iso2: 'ba', name_en: 'Bosnia and Herzegovina', name_ar: 'البوسنة والهرسك' },
  { iso2: 'bw', name_en: 'Botswana', name_ar: 'بوتسوانا' },
  { iso2: 'br', name_en: 'Brazil', name_ar: 'البرازيل' },
  { iso2: 'bn', name_en: 'Brunei Darussalam', name_ar: 'بروناي' },
  { iso2: 'bg', name_en: 'Bulgaria', name_ar: 'بلغاريا' },
  { iso2: 'bf', name_en: 'Burkina Faso', name_ar: 'بوركينا فاسو' },
  { iso2: 'bi', name_en: 'Burundi', name_ar: 'بوروندي' },
  { iso2: 'kh', name_en: 'Cambodia', name_ar: 'كمبوديا' },
  { iso2: 'cm', name_en: 'Cameroon', name_ar: 'الكاميرون' },
  { iso2: 'ca', name_en: 'Canada', name_ar: 'كندا' },
  { iso2: 'cv', name_en: 'Cape Verde', name_ar: 'الرأس الأخضر' },
  { iso2: 'cf', name_en: 'Central African Republic', name_ar: 'جمهورية أفريقيا الوسطى' },
  { iso2: 'td', name_en: 'Chad', name_ar: 'تشاد' },
  { iso2: 'cl', name_en: 'Chile', name_ar: 'تشيلي' },
  { iso2: 'cn', name_en: 'China', name_ar: 'الصين' },
  { iso2: 'co', name_en: 'Colombia', name_ar: 'كولومبيا' },
  { iso2: 'km', name_en: 'Comoros', name_ar: 'جزر القمر' },
  { iso2: 'cg', name_en: 'Congo', name_ar: 'الكونغو' },
  { iso2: 'cd', name_en: 'Congo (Democratic Republic)', name_ar: 'الكونغو الديمقراطية' },
  { iso2: 'cr', name_en: 'Costa Rica', name_ar: 'كوستاريكا' },
  { iso2: 'ci', name_en: "Côte d'Ivoire", name_ar: 'ساحل العاج' },
  { iso2: 'hr', name_en: 'Croatia', name_ar: 'كرواتيا' },
  { iso2: 'cu', name_en: 'Cuba', name_ar: 'كوبا' },
  { iso2: 'cy', name_en: 'Cyprus', name_ar: 'قبرص' },
  { iso2: 'cz', name_en: 'Czech Republic', name_ar: 'جمهورية التشيك' },
  { iso2: 'dk', name_en: 'Denmark', name_ar: 'الدنمارك' },
  { iso2: 'dj', name_en: 'Djibouti', name_ar: 'جيبوتي' },
  { iso2: 'dm', name_en: 'Dominica', name_ar: 'دومينيكا' },
  { iso2: 'do', name_en: 'Dominican Republic', name_ar: 'جمهورية الدومينيكان' },
  { iso2: 'ec', name_en: 'Ecuador', name_ar: 'الإكوادور' },
  { iso2: 'eg', name_en: 'Egypt', name_ar: 'مصر' },
  { iso2: 'sv', name_en: 'El Salvador', name_ar: 'السلفادور' },
  { iso2: 'gq', name_en: 'Equatorial Guinea', name_ar: 'غينيا الاستوائية' },
  { iso2: 'er', name_en: 'Eritrea', name_ar: 'إريتريا' },
  { iso2: 'ee', name_en: 'Estonia', name_ar: 'إستونيا' },
  { iso2: 'et', name_en: 'Ethiopia', name_ar: 'إثيوبيا' },
  { iso2: 'fj', name_en: 'Fiji', name_ar: 'فيجي' },
  { iso2: 'fi', name_en: 'Finland', name_ar: 'فنلندا' },
  { iso2: 'fr', name_en: 'France', name_ar: 'فرنسا' },
  { iso2: 'ga', name_en: 'Gabon', name_ar: 'الغابون' },
  { iso2: 'gm', name_en: 'Gambia', name_ar: 'غامبيا' },
  { iso2: 'ge', name_en: 'Georgia', name_ar: 'جورجيا' },
  { iso2: 'de', name_en: 'Germany', name_ar: 'ألمانيا' },
  { iso2: 'gh', name_en: 'Ghana', name_ar: 'غانا' },
  { iso2: 'gi', name_en: 'Gibraltar', name_ar: 'جبل طارق' },
  { iso2: 'gr', name_en: 'Greece', name_ar: 'اليونان' },
  { iso2: 'gl', name_en: 'Greenland', name_ar: 'غرينلاند' },
  { iso2: 'gd', name_en: 'Grenada', name_ar: 'غرينادا' },
  { iso2: 'gu', name_en: 'Guam', name_ar: 'غوام' },
  { iso2: 'gt', name_en: 'Guatemala', name_ar: 'غواتيمالا' },
  { iso2: 'gn', name_en: 'Guinea', name_ar: 'غينيا' },
  { iso2: 'gw', name_en: 'Guinea-Bissau', name_ar: 'غينيا بيساو' },
  { iso2: 'gy', name_en: 'Guyana', name_ar: 'غيانا' },
  { iso2: 'ht', name_en: 'Haiti', name_ar: 'هايتي' },
  { iso2: 'hn', name_en: 'Honduras', name_ar: 'هندوراس' },
  { iso2: 'hk', name_en: 'Hong Kong', name_ar: 'هونغ كونغ' },
  { iso2: 'hu', name_en: 'Hungary', name_ar: 'المجر' },
  { iso2: 'is', name_en: 'Iceland', name_ar: 'آيسلندا' },
  { iso2: 'in', name_en: 'India', name_ar: 'الهند' },
  { iso2: 'id', name_en: 'Indonesia', name_ar: 'إندونيسيا' },
  { iso2: 'ir', name_en: 'Iran', name_ar: 'إيران' },
  { iso2: 'iq', name_en: 'Iraq', name_ar: 'العراق' },
  { iso2: 'ie', name_en: 'Ireland', name_ar: 'أيرلندا' },
  { iso2: 'it', name_en: 'Italy', name_ar: 'إيطاليا' },
  { iso2: 'jm', name_en: 'Jamaica', name_ar: 'جامايكا' },
  { iso2: 'jp', name_en: 'Japan', name_ar: 'اليابان' },
  { iso2: 'jo', name_en: 'Jordan', name_ar: 'الأردن' },
  { iso2: 'kz', name_en: 'Kazakhstan', name_ar: 'كازاخستان' },
  { iso2: 'ke', name_en: 'Kenya', name_ar: 'كينيا' },
  { iso2: 'ki', name_en: 'Kiribati', name_ar: 'كيريباس' },
  { iso2: 'kw', name_en: 'Kuwait', name_ar: 'الكويت' },
  { iso2: 'kg', name_en: 'Kyrgyzstan', name_ar: 'قيرغيزستان' },
  { iso2: 'la', name_en: 'Laos', name_ar: 'لاوس' },
  { iso2: 'lv', name_en: 'Latvia', name_ar: 'لاتفيا' },
  { iso2: 'lb', name_en: 'Lebanon', name_ar: 'لبنان' },
  { iso2: 'ls', name_en: 'Lesotho', name_ar: 'ليسوتو' },
  { iso2: 'lr', name_en: 'Liberia', name_ar: 'ليبيريا' },
  { iso2: 'ly', name_en: 'Libya', name_ar: 'ليبيا' },
  { iso2: 'li', name_en: 'Liechtenstein', name_ar: 'ليختنشتاين' },
  { iso2: 'lt', name_en: 'Lithuania', name_ar: 'ليتوانيا' },
  { iso2: 'lu', name_en: 'Luxembourg', name_ar: 'لوكسمبورغ' },
  { iso2: 'mo', name_en: 'Macau', name_ar: 'ماكاو' },
  { iso2: 'mk', name_en: 'Macedonia', name_ar: 'مقدونيا' },
  { iso2: 'mg', name_en: 'Madagascar', name_ar: 'مدغشقر' },
  { iso2: 'mw', name_en: 'Malawi', name_ar: 'مالاوي' },
  { iso2: 'my', name_en: 'Malaysia', name_ar: 'ماليزيا' },
  { iso2: 'mv', name_en: 'Maldives', name_ar: 'المالديف' },
  { iso2: 'ml', name_en: 'Mali', name_ar: 'مالي' },
  { iso2: 'mt', name_en: 'Malta', name_ar: 'مالطا' },
  { iso2: 'mh', name_en: 'Marshall Islands', name_ar: 'جزر مارشال' },
  { iso2: 'mr', name_en: 'Mauritania', name_ar: 'موريتانيا' },
  { iso2: 'mu', name_en: 'Mauritius', name_ar: 'موريشيوس' },
  { iso2: 'mx', name_en: 'Mexico', name_ar: 'المكسيك' },
  { iso2: 'fm', name_en: 'Micronesia', name_ar: 'ميكرونيسيا' },
  { iso2: 'md', name_en: 'Moldova', name_ar: 'مولدوفا' },
  { iso2: 'mc', name_en: 'Monaco', name_ar: 'موناكو' },
  { iso2: 'mn', name_en: 'Mongolia', name_ar: 'منغوليا' },
  { iso2: 'me', name_en: 'Montenegro', name_ar: 'الجبل الأسود' },
  { iso2: 'ma', name_en: 'Morocco', name_ar: 'المغرب' },
  { iso2: 'mz', name_en: 'Mozambique', name_ar: 'موزمبيق' },
  { iso2: 'mm', name_en: 'Myanmar', name_ar: 'ميانمار' },
  { iso2: 'na', name_en: 'Namibia', name_ar: 'ناميبيا' },
  { iso2: 'nr', name_en: 'Nauru', name_ar: 'ناورو' },
  { iso2: 'np', name_en: 'Nepal', name_ar: 'نيبال' },
  { iso2: 'nl', name_en: 'Netherlands', name_ar: 'هولندا' },
  { iso2: 'nz', name_en: 'New Zealand', name_ar: 'نيوزيلندا' },
  { iso2: 'ni', name_en: 'Nicaragua', name_ar: 'نيكاراغوا' },
  { iso2: 'ne', name_en: 'Niger', name_ar: 'النيجر' },
  { iso2: 'ng', name_en: 'Nigeria', name_ar: 'نيجيريا' },
  { iso2: 'kp', name_en: 'North Korea', name_ar: 'كوريا الشمالية' },
  { iso2: 'no', name_en: 'Norway', name_ar: 'النرويج' },
  { iso2: 'om', name_en: 'Oman', name_ar: 'عمان' },
  { iso2: 'pk', name_en: 'Pakistan', name_ar: 'باكستان' },
  { iso2: 'pw', name_en: 'Palau', name_ar: 'بالاو' },
  { iso2: 'ps', name_en: 'Palestine', name_ar: 'فلسطين' },
  { iso2: 'pa', name_en: 'Panama', name_ar: 'بنما' },
  { iso2: 'pg', name_en: 'Papua New Guinea', name_ar: 'بابوا غينيا الجديدة' },
  { iso2: 'py', name_en: 'Paraguay', name_ar: 'باراغواي' },
  { iso2: 'pe', name_en: 'Peru', name_ar: 'بيرو' },
  { iso2: 'ph', name_en: 'Philippines', name_ar: 'الفلبين' },
  { iso2: 'pl', name_en: 'Poland', name_ar: 'بولندا' },
  { iso2: 'pt', name_en: 'Portugal', name_ar: 'البرتغال' },
  { iso2: 'qa', name_en: 'Qatar', name_ar: 'قطر' },
  { iso2: 'ro', name_en: 'Romania', name_ar: 'رومانيا' },
  { iso2: 'ru', name_en: 'Russia', name_ar: 'روسيا' },
  { iso2: 'rw', name_en: 'Rwanda', name_ar: 'رواندا' },
  { iso2: 'sa', name_en: 'Saudi Arabia', name_ar: 'المملكة العربية السعودية' },
  { iso2: 'sn', name_en: 'Senegal', name_ar: 'السنغال' },
  { iso2: 'rs', name_en: 'Serbia', name_ar: 'صربيا' },
  { iso2: 'sc', name_en: 'Seychelles', name_ar: 'سيشل' },
  { iso2: 'sl', name_en: 'Sierra Leone', name_ar: 'سيراليون' },
  { iso2: 'sg', name_en: 'Singapore', name_ar: 'سنغافورة' },
  { iso2: 'sk', name_en: 'Slovakia', name_ar: 'سلوفاكيا' },
  { iso2: 'si', name_en: 'Slovenia', name_ar: 'سلوفينيا' },
  { iso2: 'so', name_en: 'Somalia', name_ar: 'الصومال' },
  { iso2: 'za', name_en: 'South Africa', name_ar: 'جنوب أفريقيا' },
  { iso2: 'kr', name_en: 'South Korea', name_ar: 'كوريا الجنوبية' },
  { iso2: 'ss', name_en: 'South Sudan', name_ar: 'جنوب السودان' },
  { iso2: 'es', name_en: 'Spain', name_ar: 'إسبانيا' },
  { iso2: 'lk', name_en: 'Sri Lanka', name_ar: 'سريلانكا' },
  { iso2: 'sd', name_en: 'Sudan', name_ar: 'السودان' },
  { iso2: 'sr', name_en: 'Suriname', name_ar: 'سورينام' },
  { iso2: 'se', name_en: 'Sweden', name_ar: 'السويد' },
  { iso2: 'ch', name_en: 'Switzerland', name_ar: 'سويسرا' },
  { iso2: 'sy', name_en: 'Syria', name_ar: 'سوريا' },
  { iso2: 'tw', name_en: 'Taiwan', name_ar: 'تايوان' },
  { iso2: 'tj', name_en: 'Tajikistan', name_ar: 'طاجيكستان' },
  { iso2: 'tz', name_en: 'Tanzania', name_ar: 'تنزانيا' },
  { iso2: 'th', name_en: 'Thailand', name_ar: 'تايلاند' },
  { iso2: 'tl', name_en: 'Timor-Leste', name_ar: 'تيمور الشرقية' },
  { iso2: 'tg', name_en: 'Togo', name_ar: 'توغو' },
  { iso2: 'to', name_en: 'Tonga', name_ar: 'تونغا' },
  { iso2: 'tt', name_en: 'Trinidad and Tobago', name_ar: 'ترينيداد وتوباغو' },
  { iso2: 'tn', name_en: 'Tunisia', name_ar: 'تونس' },
  { iso2: 'tr', name_en: 'Turkey', name_ar: 'تركيا' },
  { iso2: 'tm', name_en: 'Turkmenistan', name_ar: 'تركمانستان' },
  { iso2: 'ug', name_en: 'Uganda', name_ar: 'أوغندا' },
  { iso2: 'ua', name_en: 'Ukraine', name_ar: 'أوكرانيا' },
  { iso2: 'ae', name_en: 'United Arab Emirates', name_ar: 'الإمارات العربية المتحدة' },
  { iso2: 'gb', name_en: 'United Kingdom', name_ar: 'المملكة المتحدة' },
  { iso2: 'us', name_en: 'United States', name_ar: 'الولايات المتحدة' },
  { iso2: 'uy', name_en: 'Uruguay', name_ar: 'أوروغواي' },
  { iso2: 'uz', name_en: 'Uzbekistan', name_ar: 'أوزبكستان' },
  { iso2: 'vu', name_en: 'Vanuatu', name_ar: 'فانواتو' },
  { iso2: 've', name_en: 'Venezuela', name_ar: 'فنزويلا' },
  { iso2: 'vn', name_en: 'Vietnam', name_ar: 'فيتنام' },
  { iso2: 'ye', name_en: 'Yemen', name_ar: 'اليمن' },
  { iso2: 'zm', name_en: 'Zambia', name_ar: 'زامبيا' },
  { iso2: 'zw', name_en: 'Zimbabwe', name_ar: 'زيمبابوي' },
  { iso2: 'bq', name_en: 'Bonaire', name_ar: 'بونير' },
  { iso2: 'ky', name_en: 'Cayman Islands', name_ar: 'جزر كايمان' },
  { iso2: 'cw', name_en: 'Curaçao', name_ar: 'كوراساو' },
  { iso2: 'qv', name_en: 'French Southern Territories', name_ar: 'الأقاليم الجنوبية الفرنسية' },
  { iso2: 'pf', name_en: 'French Polynesia', name_ar: 'بولينيزيا الفرنسية' },
  { iso2: 'gp', name_en: 'Guadeloupe', name_ar: 'غوادلوب' },
  { iso2: 'xk', name_en: 'Kosovo', name_ar: 'كوسوفو' },
  { iso2: 'mq', name_en: 'Martinique', name_ar: 'مارتينيك' },
  { iso2: 'nc', name_en: 'New Caledonia', name_ar: 'كاليدونيا الجديدة' },
  { iso2: 'pr', name_en: 'Puerto Rico', name_ar: 'بورتو ريكو' },
  { iso2: 're', name_en: 'Réunion', name_ar: 'ريونيون' },
  { iso2: 'kn', name_en: 'Saint Kitts and Nevis', name_ar: 'سانت كيتس ونيفيس' },
  { iso2: 'lc', name_en: 'Saint Lucia', name_ar: 'سانت لوسيا' },
  { iso2: 'vc', name_en: 'Saint Vincent and the Grenadines', name_ar: 'سانت فينسنت والغرينادين' },
  { iso2: 'ws', name_en: 'Samoa', name_ar: 'ساموا' },
  { iso2: 'sm', name_en: 'San Marino', name_ar: 'سان مارينو' },
  { iso2: 'st', name_en: 'Sao Tome and Principe', name_ar: 'ساو تومي وبرينسيبي' },
  { iso2: 'sb', name_en: 'Solomon Islands', name_ar: 'جزر سليمان' },
  { iso2: 'sz', name_en: 'Eswatini', name_ar: 'إسواتيني' },
  { iso2: 'tv', name_en: 'Tuvalu', name_ar: 'توفالو' },
  { iso2: 'va', name_en: 'Vatican City', name_ar: 'الفاتيكان' },
  {
    iso2: 'io',
    name_en: 'British Indian Ocean Territory',
    name_ar: 'إقليم المحيط الهندي البريطاني'
  },
  { iso2: 'gf', name_en: 'French Guiana', name_ar: 'غويانا الفرنسية' }
]
