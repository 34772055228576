import { Podcast } from 'pages/landing/models'
import { msg } from '@lingui/macro'

import bafaqeeh from 'assets/images/podcasts/bafaqeeh.png'
import meral from 'assets/images/podcasts/meral.png'
import shaqey from 'assets/images/podcasts/shaqey.png'
import bassam from 'assets/images/podcasts/bassam.png'
import yaghy from 'assets/images/podcasts/yaghy.png'
import ameer from 'assets/images/podcasts/ameer.png'
import zhoor from 'assets/images/podcasts/zhoor.png'
import almohsemy from 'assets/images/podcasts/almohsemy.png'

const podcastList: Podcast[] = [
  {
    id: 1,
    name: msg`حسين بافقيه والمكتبة الأولى`,
    description: msg`يحاور د. أشرف فقيه الناقد والمؤرخ الأستاذ حسين بافقيه عن حنينه لزمن مضى، وذكرياته مع مكتبته الأولى التي كبرت معه، وساهمت في تشكيل ملامح شخصيته وفكره في جُدّة القديمة المتجددة.`,
    link: 'm9fVr7PAFHw?si=mOOR18vF4xRiI5g5',
    bgImage: bafaqeeh
  },
  {
    id: 2,
    name: msg`الأيام المشرقة لميرال الطحاوي`,
    description: msg`حوارٌ مع الروائية المصرية ميرال الطحاوي حول روايتها "أيام الشمس المشرقة" وعن تأثير  الجوائز الأدبية في الحركة الثقافية، ورأيها في تجربة الكتابة النسائية، وأدب الهامش.`,
    link: 'E7p6gnd31yE?si=4bGqHfpcN5ZetSsj',
    bgImage: meral
  },
  {
    id: 3,
    name: msg`شوقي العنيزي والعلاقة بين القارئ والناقد`,
    description: msg`لماذا يُعَد النقد حاجةً ملحَّة بالأدب؟ يناقشنا الشاعر والناشر التونسي شوقي العنيزي في قيمة الأدب الجوهرية، وعلاقة القارئ بالناقد.`,
    link: 'wF2_QULbVYs?si=Bw1SkvS6lqxiT3AK',
    bgImage: shaqey
  },
  {
    id: 4,
    name: msg`بسام البزاز والمترجم قارئًا`,
    description: msg`حوارٌ مع المترجم العراقي بسام البزاز عن بداياته في القراءة، وتأثير مكتبته المنزلية في تشكيل قراءاته الأولى، ودورها في عمل المترجم، وتجربته في ترجمة الأدب من اللغة الإسبانية.`,
    link: 'TDvqn2gkc7g?si=X2sRj3_AiX56k3Fr',
    bgImage: bassam
  },
  {
    id: 5,
    name: msg`حسن ياغي وقراءة الناشر`,
    description: msg`كيف تختار دور النشر كتبها، وما التحديات المعاصرة؟  حوارٌ ثري عن القراءة والكتب وأسلوب نشرها مع الناشر حسن ياغي.`,
    link: 'Q1deY9G0Jd4?si=MYBDkbTvP9jmnwio',
    bgImage: yaghy
  },
  {
    id: 6,
    name: msg`أمير تاج السر وطقوس القراءة`,
    description: msg`حوارٌ مع الطبيب والروائي السوداني أمير تاج السر عن طقوس القراءة في عمره المبكر، ودورها في تشكيل تجربته الروائية، وحديثٍ عن أهم أعماله. `,
    link: '4rEScjhi3A8?si=aHalCuf7RlWHELbb',
    bgImage: ameer
  },
  {
    id: 7,
    name: msg`زهور كرام ورقمنة الإنسان`,
    description: msg`ما هي الإنسانيات الرقمية، وما علاقتها بالثقافة؟  حوارٌ مستفيض مع الدكتورة زهور كرام عن الثقافة الرقمية وانعكاسها على القارئ.`,
    link: '5i6NqEnFfEo?si=eMfPPNXyx5_J3KRP',
    bgImage: zhoor
  },
  {
    id: 8,
    name: msg`محسن الموسوي وتسليع ألف ليلة وليلة`,
    description: msg`حوارٌ مع د. محسن الموسوي عن التسليع العولمي والتصنيع الثقافي لقصص "ألف ليلة وليلة"، وتعامل المستشرقين معها.
كيف أثَّرت قصص "ألف ليلة وليلة" على الأدب في العالم؟`,
    link: '-_vxFugyp_Y?si=QESYiHcLdF6RZM-4',
    bgImage: almohsemy
  }
]

export default podcastList
