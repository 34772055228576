import CheckMarkIcon from 'assets/icons/CheckMarkIcon'
import { FC } from 'react'

interface CheckboxVariantProps {
  label: string
  checked: boolean
  onChange: () => void
  className?: string
  removeBorder?: boolean
  reverse?: boolean
  rounded?: boolean
  isDisabled?: boolean
}

const CheckboxVariant: FC<CheckboxVariantProps> = ({
  label,
  checked,
  onChange,
  className,
  removeBorder,
  isDisabled = false,
  reverse,
  rounded = false // Default to false (square checkbox)
}) => {
  return (
    <div
      className={`flex ${reverse && 'flex-row-reverse gap-4'} items-center justify-between ${!removeBorder && 'border-b border-gray-300'} py-2 ${className}`}
    >
      <span className="ml-2 text-gray-700 font-bold">{label}</span>
      {rounded ? (
        <input
          type="checkbox"
          onChange={onChange}
          checked={checked}
          disabled={isDisabled}
          className="appearance-none w-6 h-6 bg-primary_lv1_text_normal rounded-full border-2 border-natural_stroke_normal_disabled checked:bg-primary focus:outline-none focus:ring-2 focus:ring-primary"
        />
      ) : (
        <label className="inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            className="hidden"
            onChange={onChange}
            checked={checked}
            disabled={isDisabled}
          />
          <span
            className={`h-5 w-5 border border-gray-300 rounded-md flex items-center justify-center ${checked ? 'bg-primary' : 'bg-white'}`}
          >
            {checked && <CheckMarkIcon size="18" fill="white" />}
          </span>
        </label>
      )}
    </div>
  )
}

export default CheckboxVariant
