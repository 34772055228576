import React, { FC, useState, ChangeEvent } from 'react'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import SearchInput from 'components/search-field/search-field'
import { Popover, PopoverContent, PopoverTrigger } from 'components/popover/popover'
import ButtonVariant from 'components/button-variant/button'
import FilterIcon from 'assets/icons/FilterIcon'
import ButtonGroup from 'components/search-and-filter/filters/ButtonGroup'
import DateRangeFilter from 'components/search-and-filter/filters/DateRangeFilter'
import DropdownFilter from 'components/search-and-filter/filters/DropdownFilter'
import DateFilter from './filters/DateFilter'
import ModalLayout from 'components/modal/modal-layout'

export interface FilterOption {
  type: string
  selectionMode?: 'single' | 'multiple'
  label: string
  value: any
  options?: { label: string; key: string }[] | string[]
  onChange: (value: any) => void
}

interface props {
  searchValue: string
  onSearchChange?: (value: string) => void
  filters: FilterOption[]
  isSlider?: boolean
  onFilter: () => void
  onCancel: () => void
}

const SearchAndFilter: FC<props> = ({
  searchValue,
  onSearchChange = () => {},
  filters,
  isSlider,
  onFilter,
  onCancel
}) => {
  const { _ } = useLingui()
  const direction = useSelector((state: RootState) => state.layout.direction)
  const [popoverVisible, setPopoverVisible] = useState(false)

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    onSearchChange(e.target.value)
  }

  const handleFilter = () => {
    onFilter()
    setPopoverVisible(false)
  }

  const handleCancel = () => {
    onSearchChange('')
    onCancel()
    setPopoverVisible(false)
  }

  const renderFilter = (filter: FilterOption) => {
    switch (filter.type) {
      case 'dateRange':
        return (
          <DateRangeFilter
            label={filter.label}
            fromDate={(filter.value as { from: string; to: string }).from}
            toDate={(filter.value as { from: string; to: string }).to}
            onDateChange={filter.onChange}
          />
        )
      case 'date':
        return (
          <DateFilter
            label={filter.label}
            selectedDate={filter.value}
            onDateChange={filter.onChange}
          />
        )
      case 'dropdown':
        return (
          <DropdownFilter
            label={filter.label}
            selectionMode={filter.selectionMode}
            selectedValue={filter.value as string}
            onValueChange={filter.onChange}
            options={filter.options || []}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className="flex flex-row gap-2 justify-start align-middle w-full md:w-fit">
      <div className="w-full md:w-fit">
        <SearchInput placeholder={_(msg`بحث`)} value={searchValue} onChange={handleSearchChange} />
      </div>
      <div>
        <Popover
          className="top-0"
          isOpen={popoverVisible}
          onOpenChange={setPopoverVisible}
          placement={direction === 'rtl' ? 'bottom-end' : 'bottom-start'}
          size="custom"
        >
          <PopoverTrigger>
            <div>
              <ButtonVariant variant="outline" isIconOnly onClick={() => setPopoverVisible(true)}>
                <FilterIcon />
              </ButtonVariant>
            </div>
          </PopoverTrigger>

          {!isSlider && (
            <PopoverContent>
              {filters.map((filter, index) => (
                <div key={index}>{renderFilter(filter)}</div>
              ))}

              <ButtonGroup onConfirm={handleFilter} onCancel={handleCancel} />
            </PopoverContent>
          )}
        </Popover>
        {isSlider && (
          <ModalLayout
            forOthersPage
            // onClose={onCloseSidebar}
            isDrawer
            // backdrop="opaque"
            showCloseButton
            showBackButton={false}
          >
            {filters.map((filter, index) => (
              <div key={index}>{renderFilter(filter)}</div>
            ))}

            <ButtonGroup onConfirm={handleFilter} onCancel={handleCancel} />
          </ModalLayout>
        )}
      </div>
    </div>
  )
}

export default SearchAndFilter
