export const reportsData = {
  totalApplications: 333,
  genderAdministrativesApplications: [
    {
      education_administration_id: 1,
      education_administration_name: {
        ar: 'إدارة التعليم بالرياض',
        en: 'Education Administration in Riyadh'
      },
      male: 120,
      male_percentage: 60.0,
      female: 80,
      female_percentage: 40.0
    },
    {
      education_administration_id: 2,
      education_administration_name: {
        ar: 'إدارة التعليم بجدة',
        en: 'Education Administration in Jeddah'
      },
      male: 100,
      male_percentage: 55.0,
      female: 80,
      female_percentage: 45.0
    },
    {
      education_administration_id: 3,
      education_administration_name: {
        ar: 'إدارة التعليم بالدمام',
        en: 'Education Administration in Dammam'
      },
      male: 90,
      male_percentage: 65.0,
      female: 50,
      female_percentage: 35.0
    },
    {
      education_administration_id: 4,
      education_administration_name: {
        ar: 'إدارة التعليم بمكة',
        en: 'Education Administration in Makkah'
      },
      male: 110,
      male_percentage: 50.0,
      female: 110,
      female_percentage: 50.0
    },
    {
      education_administration_id: 5,
      education_administration_name: {
        ar: 'إدارة التعليم بتبوك',
        en: 'Education Administration in Tabuk'
      },
      male: 70,
      male_percentage: 45.0,
      female: 85,
      female_percentage: 55.0
    }
  ],
  topCoordinators: {
    byRegion: [
      {
        region_id: 1,
        region_name: {
          ar: 'الرياض',
          en: 'Riyadh'
        },
        coordinator_id: 101,
        coordinator_name: 'محمد العتيبي',
        total_applications: 150,
        percentage: 80.0
      },
      {
        region_id: 2,
        region_name: {
          ar: 'جدة',
          en: 'Jeddah'
        },
        coordinator_id: 102,
        coordinator_name: 'خالد الغامدي',
        total_applications: 120,
        percentage: 74.0
      },
      {
        region_id: 3,
        region_name: {
          ar: 'الدمام',
          en: 'Dammam'
        },
        coordinator_id: 103,
        coordinator_name: 'عبد الله القحطاني',
        total_applications: 100,
        percentage: 20.0
      },
      {
        region_id: 4,
        region_name: {
          ar: 'مكة',
          en: 'Mecca'
        },
        coordinator_id: 104,
        coordinator_name: 'سعيد الزهراني',
        total_applications: 90,
        percentage: 68.0
      },
      {
        region_id: 5,
        region_name: {
          ar: 'تبوك',
          en: 'Tabuk'
        },
        coordinator_id: 105,
        coordinator_name: 'فيصل الحربي',
        total_applications: 40,
        percentage: 48.0
      }
    ],
    byAdministration: [
      {
        education_administration_id: 1,
        education_administration_name: {
          ar: 'إدارة تعليم الرياض',
          en: 'Riyadh Education Administration'
        },
        coordinator_id: 201,
        coordinator_name: 'نواف الشمري',
        total_applications: 200,
        percentage: 40.0
      },
      {
        education_administration_id: 2,
        education_administration_name: {
          ar: 'إدارة تعليم جدة',
          en: 'Jeddah Education Administration'
        },
        coordinator_id: 202,
        coordinator_name: 'بدر السبيعي',
        total_applications: 150,
        percentage: 60.0
      },
      {
        education_administration_id: 3,
        education_administration_name: {
          ar: 'إدارة تعليم الدمام',
          en: 'Dammam Education Administration'
        },
        coordinator_id: 203,
        coordinator_name: 'فهد البقمي',
        total_applications: 100,
        percentage: 80.0
      },
      {
        education_administration_id: 4,
        education_administration_name: {
          ar: 'إدارة تعليم مكة',
          en: 'Mecca Education Administration'
        },
        coordinator_id: 204,
        coordinator_name: 'سالم الشهراني',
        total_applications: 75,
        percentage: 75.0
      },
      {
        education_administration_id: 5,
        education_administration_name: {
          ar: 'إدارة تعليم المدينة',
          en: 'Medina Education Administration'
        },
        coordinator_id: 205,
        coordinator_name: 'منصور العسيري',
        total_applications: 50,
        percentage: 50.0
      }
    ]
  },
  regionalStagesApplications: [
    {
      region_id: 3,
      region_name: {
        ar: 'المنطقة الغربية',
        en: 'Western Region'
      },
      stages: [
        {
          education_stage: 'university',
          total_applications: 50,
          percentage: 41.7
        },
        {
          education_stage: 'general',
          total_applications: 70,
          percentage: 58.3
        }
      ]
    },
    {
      region_id: 4,
      region_name: {
        ar: 'المنطقة الشمالية',
        en: 'Northern Region'
      },
      stages: [
        {
          education_stage: 'university',
          total_applications: 40,
          percentage: 40.0
        },
        {
          education_stage: 'general',
          total_applications: 60,
          percentage: 60.0
        }
      ]
    },
    {
      region_id: 5,
      region_name: {
        ar: 'المنطقة الجنوبية',
        en: 'Southern Region'
      },
      stages: [
        {
          education_stage: 'university',
          total_applications: 70,
          percentage: 53.8
        },
        {
          education_stage: 'general',
          total_applications: 60,
          percentage: 46.2
        }
      ]
    }
  ],
  stagesApplications: [
    {
      stage: 'university',

      total_applications: 120,
      percentage: 45.2
    },
    {
      stage: 'general',
      total_applications: 145,
      percentage: 54.8
    }
  ],
  administrativesApplications: [
    {
      education_administration_id: 1,
      education_administration_name: {
        ar: 'إدارة تعليم الرياض',
        en: 'Riyadh Education Administration'
      },
      total_applications: 230,
      percentage: 53.5
    },
    {
      education_administration_id: 2,
      education_administration_name: {
        ar: 'إدارة تعليم جدة',
        en: 'Jeddah Education Administration'
      },
      total_applications: 190,
      percentage: 89.4
    },
    {
      education_administration_id: 3,
      education_administration_name: {
        ar: 'إدارة تعليم الدمام',
        en: 'Dammam Education Administration'
      },
      total_applications: 215,
      percentage: 61.9
    },
    {
      education_administration_id: 4,
      education_administration_name: {
        ar: 'إدارة تعليم المدينة',
        en: 'Medina Education Administration'
      },
      total_applications: 205,
      percentage: 50.9
    },
    {
      education_administration_id: 5,
      education_administration_name: {
        ar: 'إدارة تعليم مكة',
        en: 'Mecca Education Administration'
      },
      total_applications: 165,
      percentage: 46.8
    }
  ],
  topEducationAdministrations: [
    {
      education_administration_id: 1,
      education_administration_name: {
        ar: 'إدارة تعليم الرياض',
        en: 'Riyadh Education Administration'
      },
      total_applications: 120,
      percentage: 82.5
    },
    {
      education_administration_id: 2,
      education_administration_name: {
        ar: 'إدارة تعليم جدة',
        en: 'Jeddah Education Administration'
      },
      total_applications: 135,
      percentage: 43.8
    },
    {
      education_administration_id: 3,
      education_administration_name: {
        ar: 'إدارة تعليم الدمام',
        en: 'Dammam Education Administration'
      },
      total_applications: 145,
      percentage: 64.9
    },
    {
      education_administration_id: 4,
      education_administration_name: {
        ar: 'إدارة تعليم المدينة',
        en: 'Medina Education Administration'
      },
      total_applications: 160,
      percentage: 76.4
    },
    {
      education_administration_id: 5,
      education_administration_name: {
        ar: 'إدارة تعليم مكة',
        en: 'Mecca Education Administration'
      },
      total_applications: 110,
      percentage: 41.3
    }
  ],
  topTeachers: {
    byRegion: [
      {
        region_id: 1,
        region_name: {
          ar: 'المنطقة الوسطى',
          en: 'Central Region'
        },
        teacher_id: 101,
        teacher_name: 'محمد علي',
        total_applications: 50,
        percentage: 50.1
      },
      {
        region_id: 2,
        region_name: {
          ar: 'المنطقة الشرقية',
          en: 'Eastern Region'
        },
        teacher_id: 102,
        teacher_name: 'فاطمة الزهراء',
        total_applications: 65,
        percentage: 56.5
      },
      {
        region_id: 3,
        region_name: {
          ar: 'المنطقة الغربية',
          en: 'Western Region'
        },
        teacher_id: 103,
        teacher_name: 'علي حسين',
        total_applications: 70,
        percentage: 57.1
      },
      {
        region_id: 4,
        region_name: {
          ar: 'المنطقة الشمالية',
          en: 'Northern Region'
        },
        teacher_id: 104,
        teacher_name: 'سارة محمد',
        total_applications: 85,
        percentage: 88.2
      },
      {
        region_id: 5,
        region_name: {
          ar: 'المنطقة الجنوبية',
          en: 'Southern Region'
        },
        teacher_id: 105,
        teacher_name: 'خالد سعيد',
        total_applications: 90,
        percentage: 49.3
      }
    ],
    byAdministration: [
      {
        education_administration_id: 1,
        education_administration_name: {
          ar: 'إدارة تعليم الرياض',
          en: 'Riyadh Education Administration'
        },
        teacher_id: 201,
        teacher_name: 'علياء عبد الله',
        total_applications: 30,
        percentage: 33.0
      },
      {
        education_administration_id: 2,
        education_administration_name: {
          ar: 'إدارة تعليم جدة',
          en: 'Jeddah Education Administration'
        },
        teacher_id: 202,
        teacher_name: 'سمية أحمد',
        total_applications: 40,
        percentage: 54.1
      },
      {
        education_administration_id: 3,
        education_administration_name: {
          ar: 'إدارة تعليم الدمام',
          en: 'Dammam Education Administration'
        },
        teacher_id: 203,
        teacher_name: 'حسن علي',
        total_applications: 45,
        percentage: 46.5
      },
      {
        education_administration_id: 4,
        education_administration_name: {
          ar: 'إدارة تعليم المدينة',
          en: 'Medina Education Administration'
        },
        teacher_id: 204,
        teacher_name: 'نور الهدى',
        total_applications: 50,
        percentage: 75.2
      },
      {
        education_administration_id: 5,
        education_administration_name: {
          ar: 'إدارة تعليم مكة',
          en: 'Mecca Education Administration'
        },
        teacher_id: 205,
        teacher_name: 'راشد القحطاني',
        total_applications: 55,
        percentage: 75.7
      }
    ]
  },
  femaleApplications: 250,
  maleApplications: 300,
  draftApplications: 100,
  submittedApplications: 450,
  countryApplicationsCount: 500,
  countryApplicationsPercentage: 95.0,
  otherCountriesApplicationsCount: 25,
  otherCountriesApplicationsPercentage: 5.0,
  topCountries: [
    {
      country_id: 1,
      country_name: {
        ar: 'المملكة العربية السعودية',
        en: 'Saudi Arabia'
      },
      total_applications: 400,
      percentage: 80.0
    },
    {
      country_id: 2,
      country_name: {
        ar: 'الإمارات العربية المتحدة',
        en: 'United Arab Emirates'
      },
      total_applications: 50,
      percentage: 90.0
    },
    {
      country_id: 3,
      country_name: {
        ar: 'مصر',
        en: 'Egypt'
      },
      total_applications: 30,
      percentage: 86.0
    },
    {
      country_id: 4,
      country_name: {
        ar: 'الكويت',
        en: 'Kuwait'
      },
      total_applications: 15,
      percentage: 88.0
    },
    {
      country_id: 5,
      country_name: {
        ar: 'البحرين',
        en: 'Bahrain'
      },
      total_applications: 5,
      percentage: 71.0
    }
  ],
  countryRegionalApplications: [
    {
      region_id: 3,
      region_name: {
        ar: 'المنطقة الغربية',
        en: 'Western Region'
      },
      total_applications: 120,
      percentage: 24.0
    },
    {
      region_id: 1,
      region_name: {
        ar: 'المنطقة الوسطى',
        en: 'Central Region'
      },
      total_applications: 150,
      percentage: 43.0
    },
    {
      region_id: 2,
      region_name: {
        ar: 'المنطقة الشرقية',
        en: 'Eastern Region'
      },
      total_applications: 130,
      percentage: 86.0
    },
    {
      region_id: 3,
      region_name: {
        ar: 'المنطقة الغربية',
        en: 'Western Region'
      },
      total_applications: 120,
      percentage: 24.0
    },
    {
      region_id: 4,
      region_name: {
        ar: 'المنطقة الشمالية',
        en: 'Northern Region'
      },
      total_applications: 100,
      percentage: 80.0
    },
    {
      region_id: 4,
      region_name: {
        ar: 'المنطقة الشمالية',
        en: 'Northern Region'
      },
      total_applications: 400,
      percentage: 90.0
    }
  ],
  universitiesApplications: [
    {
      id: 1,
      value: 90,
      label: {
        ar: 'جامعة الملك سعود',
        en: 'King Saud University'
      }
    },
    {
      id: 2,
      value: 75,
      label: {
        ar: 'جامعة الملك عبد العزيز',
        en: 'King Abdulaziz University'
      }
    },
    {
      id: 3,
      value: 65,
      label: {
        ar: 'جامعة الملك فهد',
        en: 'King Fahd University of Petroleum and Minerals'
      }
    },
    {
      id: 4,
      value: 50,
      label: {
        ar: 'جامعة الأميرة نورة',
        en: 'Princess Nourah University'
      }
    }
  ],
  reachPercentage: [
    {
      itemName: 'أحمد السعدي',
      numberOfApplications: 53.5,
      percentage: 53.5
    },
    {
      itemName: 'محمد الكعبي',
      numberOfApplications: 89.4,
      percentage: 89.4
    },
    {
      itemName: 'سامي الدوسري',
      numberOfApplications: 61.9,
      percentage: 61.9
    },
    {
      itemName: 'عبدالله القحطاني',
      numberOfApplications: 50.9,
      percentage: 50.9
    },
    {
      itemName: 'علي الحربي',
      numberOfApplications: 46.8,
      percentage: 46.8
    }
  ]
}
export const detailsReport = {
  universitiesApplications: [
    {
      id: 1,
      universityName: { ar: 'جامعة الملك سعود', en: 'King Saud University' },
      region_name: { ar: 'الرياض', en: 'Riyadh' },
      total_applications: 120
    },
    {
      id: 2,
      universityName: { ar: 'جامعة الملك عبد العزيز', en: 'King Abdulaziz University' },
      region_name: { ar: 'جدة', en: 'Jeddah' },
      total_applications: 95
    },
    {
      id: 3,
      universityName: { ar: 'جامعة الأمير سلطان', en: 'Prince Sultan University' },
      region_name: { ar: 'الرياض', en: 'Riyadh' },
      total_applications: 80
    },
    {
      id: 4,
      universityName: {
        ar: 'جامعة الأميرة نورة',
        en: 'Princess Nourah bint Abdulrahman University'
      },
      region_name: { ar: 'الرياض', en: 'Riyadh' },
      total_applications: 75
    },
    {
      id: 5,
      universityName: {
        ar: 'جامعة الملك فهد للبترول والمعادن',
        en: 'King Fahd University of Petroleum and Minerals'
      },
      region_name: { ar: 'الظهران', en: 'Dhahran' },
      total_applications: 110
    }
  ],

  regionalStagesApplications: [
    {
      id: 0,
      region_name: { ar: 'المنطقة 1', en: 'Region 1' },
      educationAdministrationsCount: 12,
      total_applications: 150,
      generalEducationCount: 100,
      universityEducationCount: 50
    },
    {
      id: 1,
      region_name: { ar: 'المنطقة 2', en: 'Region 2' },
      educationAdministrationsCount: 10,
      total_applications: 120,
      generalEducationCount: 80,
      universityEducationCount: 40
    },
    {
      id: 2,
      region_name: { ar: 'المنطقة 3', en: 'Region 3' },
      educationAdministrationsCount: 15,
      total_applications: 200,
      generalEducationCount: 150,
      universityEducationCount: 50
    },
    {
      id: 3,
      region_name: { ar: 'المنطقة 4', en: 'Region 4' },
      educationAdministrationsCount: 8,
      total_applications: 90,
      generalEducationCount: 60,
      universityEducationCount: 30
    },
    {
      id: 4,
      region_name: { ar: 'المنطقة 5', en: 'Region 5' },
      educationAdministrationsCount: 14,
      total_applications: 170,
      generalEducationCount: 130,
      universityEducationCount: 40
    }
  ],
  administrativesApplications: [
    {
      id: 0,
      educationAdministration: { ar: 'الإدارة 1', en: 'Admin 1' },
      total_applications: 300,
      maleCount: 180,
      femaleCount: 120
    },
    {
      id: 1,
      educationAdministration: { ar: 'الإدارة 2', en: 'Admin 2' },
      total_applications: 250,
      maleCount: 150,
      femaleCount: 100
    },
    {
      id: 2,
      educationAdministration: { ar: 'الإدارة 3', en: 'Admin 3' },
      total_applications: 220,
      maleCount: 140,
      femaleCount: 80
    },
    {
      id: 3,
      educationAdministration: { ar: 'الإدارة 4', en: 'Admin 4' },
      total_applications: 270,
      maleCount: 160,
      femaleCount: 110
    },
    {
      id: 4,
      educationAdministration: { ar: 'الإدارة 5', en: 'Admin 5' },
      total_applications: 190,
      maleCount: 120,
      femaleCount: 70
    }
  ],
  topCoordinators: [
    {
      id: 0,
      region_name: { ar: 'المنطقة 1', en: 'Region 1' },
      coordinator_name: 'منسق 1',
      total_applications: 300,
      percentage: '85',
      points: 150
    },
    {
      id: 1,
      region_name: { ar: 'المنطقة 2', en: 'Region 2' },
      coordinator_name: 'منسق 2',
      total_applications: 280,
      percentage: '90',
      points: 140
    },
    {
      id: 2,
      region_name: { ar: 'المنطقة 3', en: 'Region 3' },
      coordinator_name: 'منسق 3',
      total_applications: 320,
      percentage: '80',
      points: 160
    },
    {
      id: 3,
      region_name: { ar: 'المنطقة 4', en: 'Region 4' },
      coordinator_name: 'منسق 4',
      total_applications: 310,
      percentage: '75',
      points: 145
    },
    {
      id: 4,
      region_name: { ar: 'المنطقة 5', en: 'Region 5' },
      coordinator_name: 'منسق 5',
      total_applications: 290,
      percentage: '88',
      points: 155
    }
  ],
  topTeachers: [
    {
      id: 0,
      region_name: { ar: 'المنطقة 1', en: 'Region 1' },
      coordinator_name: { ar: 'المحاضر 1', en: 'Teacher 1' },
      total_applications: 120,
      points: 80
    },
    {
      id: 1,
      region_name: { ar: 'المنطقة 2', en: 'Region 2' },
      coordinator_name: { ar: 'المحاضر 2', en: 'Teacher 2' },
      total_applications: 110,
      points: 75
    },
    {
      id: 2,
      region_name: { ar: 'المنطقة 3', en: 'Region 3' },
      coordinator_name: { ar: 'المحاضر 3', en: 'Teacher 3' },
      total_applications: 130,
      points: 85
    },
    {
      id: 3,
      region_name: { ar: 'المنطقة 4', en: 'Region 4' },
      coordinator_name: { ar: 'المحاضر 4', en: 'Teacher 4' },
      total_applications: 140,
      points: 90
    },
    {
      id: 4,
      region_name: { ar: 'المنطقة 5', en: 'Region 5' },
      coordinator_name: { ar: 'المحاضر 5', en: 'Teacher 5' },
      total_applications: 150,
      points: 95
    }
  ],
  topUniversities: [
    {
      id: 0,
      universityName: { ar: 'جامعة 1', en: 'University 1' },
      region_name: { ar: 'المنطقة 1', en: 'Region 1' },
      total_applications: 500
    },
    {
      id: 1,
      universityName: { ar: 'جامعة 2', en: 'University 2' },
      region_name: { ar: 'المنطقة 2', en: 'Region 2' },
      total_applications: 450
    },
    {
      id: 2,
      universityName: { ar: 'جامعة 3', en: 'University 3' },
      region_name: { ar: 'المنطقة 3', en: 'Region 3' },
      total_applications: 480
    },
    {
      id: 3,
      universityName: { ar: 'جامعة 4', en: 'University 4' },
      region_name: { ar: 'المنطقة 4', en: 'Region 4' },
      total_applications: 520
    },
    {
      id: 4,
      universityName: { ar: 'جامعة 5', en: 'University 5' },
      region_name: { ar: 'المنطقة 5', en: 'Region 5' },
      total_applications: 510
    }
  ],
  reachPercentage: [
    {
      id: 0,
      coordinator_name: { ar: 'منسق 1', en: 'Coordinator 1' },
      percentage: '85%',
      total_applications: 250,
      region_name: { ar: 'المنطقة 1', en: 'Region 1' },
      educationAdministration: { ar: 'الإدارة 1', en: 'Admin 1' }
    },
    {
      id: 1,
      coordinator_name: { ar: 'منسق 2', en: 'Coordinator 2' },
      percentage: '90%',
      total_applications: 270,
      region_name: { ar: 'المنطقة 2', en: 'Region 2' },
      educationAdministration: { ar: 'الإدارة 2', en: 'Admin 2' }
    },
    {
      id: 2,
      coordinator_name: { ar: 'منسق 3', en: 'Coordinator 3' },
      percentage: '80%',
      total_applications: 240,
      region_name: { ar: 'المنطقة 3', en: 'Region 3' },
      educationAdministration: { ar: 'الإدارة 3', en: 'Admin 3' }
    },
    {
      id: 3,
      coordinator_name: { ar: 'منسق 4', en: 'Coordinator 4' },
      percentage: '75%',
      total_applications: 260,
      region_name: { ar: 'المنطقة 4', en: 'Region 4' },
      educationAdministration: { ar: 'الإدارة 4', en: 'Admin 4' }
    },
    {
      id: 4,
      coordinator_name: { ar: 'منسق 5', en: 'Coordinator 5' },
      percentage: '88%',
      total_applications: 290,
      region_name: { ar: 'المنطقة 5', en: 'Region 5' },
      educationAdministration: { ar: 'الإدارة 5', en: 'Admin 5' }
    }
  ],
  countryRegionalApplications: [
    {
      country_name: { en: 'Saudi Arabia', ar: 'المملكة العربية السعودية' },
      draft: 120,
      under_review: 80,
      total_applications_count: 200
    },
    {
      country_name: { en: 'United Arab Emirates', ar: 'الإمارات العربية المتحدة' },
      draft: 90,
      under_review: 60,
      total_applications_count: 150
    },
    {
      country_name: { en: 'Egypt', ar: 'مصر' },
      draft: 150,
      under_review: 100,
      total_applications_count: 250
    },
    {
      country_name: { en: 'Jordan', ar: 'الأردن' },
      draft: 45,
      under_review: 30,
      total_applications_count: 75
    },
    {
      country_name: { en: 'Kuwait', ar: 'الكويت' },
      draft: 70,
      under_review: 40,
      total_applications_count: 110
    }
  ],
  countryApplicationsCount: [
    {
      country_name: { en: 'Saudi Arabia', ar: 'المملكة العربية السعودية' },
      draft: 120,
      under_review: 80,
      total_applications_count: 200,
      male_participants: 110,
      female_participants: 90
    },
    {
      country_name: { en: 'United Arab Emirates', ar: 'الإمارات العربية المتحدة' },
      draft: 90,
      under_review: 60,
      total_applications_count: 150,
      male_participants: 70,
      female_participants: 80
    },
    {
      country_name: { en: 'Egypt', ar: 'مصر' },
      draft: 150,
      under_review: 100,
      total_applications_count: 250,
      male_participants: 130,
      female_participants: 120
    },
    {
      country_name: { en: 'Jordan', ar: 'الأردن' },
      draft: 45,
      under_review: 30,
      total_applications_count: 75,
      male_participants: 40,
      female_participants: 35
    },
    {
      country_name: { en: 'Kuwait', ar: 'الكويت' },
      draft: 70,
      under_review: 40,
      total_applications_count: 110,
      male_participants: 50,
      female_participants: 60
    }
  ],
  genderAdministrativesApplications: [
    {
      country_name: { en: 'Saudi Arabia', ar: 'المملكة العربية السعودية' },
      draft: 120,
      under_review: 80,
      total_applications_count: 200,
      male_participants: 110,
      female_participants: 90
    },
    {
      country_name: { en: 'United Arab Emirates', ar: 'الإمارات العربية المتحدة' },
      draft: 90,
      under_review: 60,
      total_applications_count: 150,
      male_participants: 70,
      female_participants: 80
    },
    {
      country_name: { en: 'Egypt', ar: 'مصر' },
      draft: 150,
      under_review: 100,
      total_applications_count: 250,
      male_participants: 130,
      female_participants: 120
    },
    {
      country_name: { en: 'Jordan', ar: 'الأردن' },
      draft: 45,
      under_review: 30,
      total_applications_count: 75,
      male_participants: 40,
      female_participants: 35
    },
    {
      country_name: { en: 'Kuwait', ar: 'الكويت' },
      draft: 70,
      under_review: 40,
      total_applications_count: 110,
      male_participants: 50,
      female_participants: 60
    }
  ],
  stagesApplications: [
    {
      region_name: { en: 'Riyadh', ar: 'الرياض' },
      universityEducationCount: 150,
      generalEducationCount: 320
    },
    {
      region_name: { en: 'Jeddah', ar: 'جدة' },
      universityEducationCount: 200,
      generalEducationCount: 450
    },
    {
      region_name: { en: 'Dammam', ar: 'الدمام' },
      universityEducationCount: 180,
      generalEducationCount: 400
    },
    {
      region_name: { en: 'Mecca', ar: 'مكة' },
      universityEducationCount: 220,
      generalEducationCount: 520
    },
    {
      region_name: { en: 'Medina', ar: 'المدينة المنورة' },
      universityEducationCount: 170,
      generalEducationCount: 390
    }
  ],
  otherCountriesApplicationsPercentage: [
    {
      id: 0,
      region_name: { ar: 'المنطقة 1', en: 'Region 1' },
      educationAdministrationsCount: 12,
      total_applications: 150,
      generalEducationCount: 100,
      universityEducationCount: 50
    },
    {
      id: 1,
      region_name: { ar: 'المنطقة 2', en: 'Region 2' },
      educationAdministrationsCount: 10,
      total_applications: 120,
      generalEducationCount: 80,
      universityEducationCount: 40
    },
    {
      id: 2,
      region_name: { ar: 'المنطقة 3', en: 'Region 3' },
      educationAdministrationsCount: 15,
      total_applications: 200,
      generalEducationCount: 150,
      universityEducationCount: 50
    },
    {
      id: 3,
      region_name: { ar: 'المنطقة 4', en: 'Region 4' },
      educationAdministrationsCount: 8,
      total_applications: 90,
      generalEducationCount: 60,
      universityEducationCount: 30
    },
    {
      id: 4,
      region_name: { ar: 'المنطقة 5', en: 'Region 5' },
      educationAdministrationsCount: 14,
      total_applications: 170,
      generalEducationCount: 130,
      universityEducationCount: 40
    }
  ]
}
export const ithraaReports = {
  countryRegionalApplications: [
    {
      region_id: 1,
      percentage: 46.8,

      country_name: {
        ar: 'السعودية',
        en: 'Saudi Arabia'
      },
      total_applications: 120
    },
    {
      region_id: 2,
      percentage: 46.8,

      country_name: {
        ar: 'مصر',
        en: 'Egypt'
      },
      total_applications: 150
    },
    {
      region_id: 3,
      percentage: 46.8,

      country_name: {
        ar: 'الإمارات',
        en: 'United Arab Emirates'
      },
      total_applications: 130
    },
    {
      region_id: 4,
      percentage: 46.8,

      country_name: {
        ar: 'الكويت',
        en: 'Kuwait'
      },
      total_applications: 120
    },
    {
      region_id: 5,
      percentage: 46.8,

      country_name: {
        ar: 'الأردن',
        en: 'Jordan'
      },
      total_applications: 100
    },
    {
      region_id: 6,
      percentage: 46.8,

      country_name: {
        ar: 'قطر',
        en: 'Qatar'
      },
      total_applications: 400
    }
  ],
  countryApplicationsCount: [
    {
      name: {
        ar: 'السعودية',
        en: 'Saudi Arabia'
      },
      total_applications: '3',
      percentage: 33
    },
    {
      name: {
        ar: 'باقي الدول',
        en: 'Other Countries'
      },
      total_applications: '0',
      percentage: 67
    }
  ],
  topCoordinatorsIthra: [
    {
      points: 95,
      coordinator_name: 'أحمد الفهد',
      region_id: 1,
      region_name: {
        ar: 'الرياض',
        en: 'Riyadh'
      },
      coordinator_id: 101,
      total_applications: 500,
      percentage: 50
    },
    {
      points: 85,
      coordinator_name: 'سارة القحطاني',
      region_id: 2,
      region_name: {
        ar: 'مكة المكرمة',
        en: 'Makkah'
      },
      coordinator_id: 102,
      total_applications: 300,
      percentage: 30
    },
    {
      points: 90,
      coordinator_name: 'فهد الدوسري',
      region_id: 3,
      region_name: {
        ar: 'المدينة المنورة',
        en: 'Madinah'
      },
      coordinator_id: 103,
      total_applications: 200,
      percentage: 20
    },
    {
      points: 75,
      coordinator_name: 'نورة الحربي',
      region_id: 4,
      region_name: {
        ar: 'المنطقة الشرقية',
        en: 'Eastern Province'
      },
      coordinator_id: 104,
      total_applications: 400,
      percentage: 40
    },
    {
      points: 80,
      coordinator_name: 'خالد السعود',
      region_id: 5,
      region_name: {
        ar: 'عسير',
        en: 'Asir'
      },
      coordinator_id: 105,
      total_applications: 250,
      percentage: 25
    }
  ],
  genderAdministrativesApplications: [
    {
      name: {
        ar: 'الأردن',
        en: 'Saudi Arabia'
      },
      progressValue: 80,
      valueText: '80%'
    },
    {
      name: {
        ar: 'الإمارات',
        en: 'United Arab Emirates'
      },
      progressValue: 50,
      valueText: '50%'
    },
    {
      name: {
        ar: 'الكويت',
        en: 'Kuwait'
      },
      progressValue: 70,
      valueText: '70%'
    },
    {
      name: {
        ar: 'قطر',
        en: 'Qatar'
      },
      progressValue: 60,
      valueText: '60%'
    },
    {
      name: {
        ar: 'البحرين',
        en: 'Bahrain'
      },
      progressValue: 90,
      valueText: '90%'
    }
  ],
  educationStageByContry: [
    {
      name: {
        ar: 'السعودية',
        en: 'Saudi Arabia',
        eng: 'Saudi Arabia'
      },
      percentage: 80, // Overall percentage for the country
      total_applications: '8000', // Example total applications for the country
      progressItems: [
        {
          percentage: 80,
          name: 'King Saud University',
          bgColor: 'bg-information_default_fill_normal',
          textColor: 'text-information_default_fill_normal'
        },
        {
          percentage: 50,
          name: 'General',
          bgColor: 'bg-secondary_fill_normal_pressed',
          textColor: 'text-secondary_fill_normal_pressed'
        }
      ]
    },
    {
      name: {
        ar: 'الإمارات',
        en: 'United Arab Emirates',
        eng: 'UAE'
      },
      percentage: 75, // Overall percentage for the country
      total_applications: '7500', // Example total applications for the country
      progressItems: [
        {
          percentage: 75,
          name: 'United Arab Emirates University',
          bgColor: 'bg-information_default_fill_normal',
          textColor: 'text-information_default_fill_normal'
        },
        {
          percentage: 40,
          name: 'General',
          bgColor: 'bg-secondary_fill_normal_pressed',
          textColor: 'text-secondary_fill_normal_pressed'
        }
      ]
    },
    
  ]
}
export const dataTabelTypeMockData = [
    {
        student_personal_data: {
            name: "طالب أ", // Student A in Arabic
            email: "studentA@example.com",
            phone: "+966500000001"
        },
        country: { en: "Saudi Arabia", ar: "المملكة العربية السعودية" }, // Country in English and Arabic
        region: { en: "Riyadh", ar: "الرياض" },
        educational_institution_info: {
            stage: "university",
            administration_name: { en: "King Saud University", ar: "جامعة الملك سعود" }
        },
        school_university: { en: "King Saud University", ar: "جامعة الملك سعود" },
        submission_date: "2024-10-01"
    },
    {
        student_personal_data: {
            name: "طالب ب", // Student B in Arabic
            email: "studentB@example.com",
            phone: "+966500000002"
        },
        country: { en: "Saudi Arabia", ar: "المملكة العربية السعودية" }, // Country in English and Arabic
        region: { en: "Jeddah", ar: "جدة" },
        educational_institution_info: {
            stage: "general",
            administration_name: { en: "Al Faisaliah High School", ar: "مدرسة الفيصلية" }
        },
        school_university: { en: "Al Faisaliah High School", ar: "مدرسة الفيصلية" },
        submission_date: "2024-09-28"
    },
    {
        student_personal_data: {
            name: "طالب ج", // Student C in Arabic
            email: "studentC@example.com",
            phone: "+966500000003"
        },
        country: { en: "Saudi Arabia", ar: "المملكة العربية السعودية" }, // Country in English and Arabic
        region: { en: "Dammam", ar: "الدمام" },
        educational_institution_info: {
            stage: "university",
            administration_name: { en: "Imam Abdulrahman University", ar: "جامعة الإمام عبدالرحمن" }
        },
        school_university: { en: "Imam Abdulrahman University", ar: "جامعة الإمام عبدالرحمن" },
        submission_date: "2024-09-25"
    },
    {
        student_personal_data: {
            name: "طالب د", // Student D in Arabic
            email: "studentD@example.com",
            phone: "+966500000004"
        },
        country: { en: "Saudi Arabia", ar: "المملكة العربية السعودية" }, // Country in English and Arabic
        region: { en: "Mecca", ar: "مكة" },
        educational_institution_info: {
            stage: "general",
            administration_name: { en: "Mecca Secondary School", ar: "مدرسة مكة الثانوية" }
        },
        school_university: { en: "Mecca Secondary School", ar: "مدرسة مكة الثانوية" },
        submission_date: "2024-09-30"
    },
    {
        student_personal_data: {
            name: "طالب هـ", // Student E in Arabic
            email: "studentE@example.com",
            phone: "+966500000005"
        },
        country: { en: "Saudi Arabia", ar: "المملكة العربية السعودية" }, // Country in English and Arabic
        region: { en: "Medina", ar: "المدينة" },
        educational_institution_info: {
            stage: "university",
            administration_name: { en: "Taibah University", ar: "جامعة طيبة" }
        },
        school_university: { en: "Taibah University", ar: "جامعة طيبة" },
        submission_date: "2024-10-03"
    },
    {
        student_personal_data: {
            name: "طالب و", // Student F in Arabic
            email: "studentF@example.com",
            phone: "+966500000006"
        },
        country: { en: "Saudi Arabia", ar: "المملكة العربية السعودية" }, // Country in English and Arabic
        region: { en: "Tabuk", ar: "تبوك" },
        educational_institution_info: {
            stage: "general",
            administration_name: { en: "Tabuk High School", ar: "مدرسة تبوك الثانوية" }
        },
        school_university: { en: "Tabuk High School", ar: "مدرسة تبوك الثانوية" },
        submission_date: "2024-10-02"
    },
    {
        student_personal_data: {
            name: "طالب ز", // Student G in Arabic
            email: "studentG@example.com",
            phone: "+966500000007"
        },
        country: { en: "Saudi Arabia", ar: "المملكة العربية السعودية" }, // Country in English and Arabic
        region: { en: "Al Khobar", ar: "الخبر" },
        educational_institution_info: {
            stage: "university",
            administration_name: { en: "Prince Mohammad Bin Fahd University", ar: "جامعة الأمير محمد بن فهد" }
        },
        school_university: { en: "Prince Mohammad Bin Fahd University", ar: "جامعة الأمير محمد بن فهد" },
        submission_date: "2024-09-22"
    },
    {
        student_personal_data: {
            name: "طالب ح", // Student H in Arabic
            email: "studentH@example.com",
            phone: "+966500000008"
        },
        country: { en: "Saudi Arabia", ar: "المملكة العربية السعودية" }, // Country in English and Arabic
        region: { en: "Abha", ar: "أبها" },
        educational_institution_info: {
            stage: "general",
            administration_name: { en: "Abha Secondary School", ar: "مدرسة أبها الثانوية" }
        },
        school_university: { en: "Abha Secondary School", ar: "مدرسة أبها الثانوية" },
        submission_date: "2024-09-20"
    },
    {
        student_personal_data: {
            name: "طالب ط", // Student I in Arabic
            email: "studentI@example.com",
            phone: "+966500000009"
        },
        country: { en: "Saudi Arabia", ar: "المملكة العربية السعودية" }, // Country in English and Arabic
        region: { en: "Jizan", ar: "جيزان" },
        educational_institution_info: {
            stage: "university",
            administration_name: { en: "Jazan University", ar: "جامعة جازان" }
        },
        school_university: { en: "Jazan University", ar: "جامعة جازان" },
        submission_date: "2024-09-18"
    },
    {
        student_personal_data: {
            name: "طالب ي", // Student J in Arabic
            email: "studentJ@example.com",
            phone: "+966500000010"
        },
        country: { en: "Saudi Arabia", ar: "المملكة العربية السعودية" }, // Country in English and Arabic
        region: { en: "Hail", ar: "حائل" },
        educational_institution_info: {
            stage: "general",
            administration_name: { en: "Hail High School", ar: "مدرسة حائل الثانوية" }
        },
        school_university: { en: "Hail High School", ar: "مدرسة حائل الثانوية" },
        submission_date: "2024-09-15"
    }
];



  
