import { useState } from 'react'
import useLanguage from 'hooks/useLanguage'
import { useEducationLevels } from './get-eduction-levels'
import { getLocalizedName } from '../utils'

interface UseEducationAdministrationOptionsProps {
  countryId?: string
  countryCode?: string
}

export const useEducationLevelsOptions = ({
  countryId,
  countryCode
}: UseEducationAdministrationOptionsProps = {}) => {
  const language = useLanguage() as 'ar' | 'en'
  const { educationLevels, isLoading } = useEducationLevels({ countryId, countryCode })
  const [selectedEducationStage, setSelectedEducationStage] = useState<string | null>(null)

  // Create options for the select field with key and label, handling empty case
  const options = educationLevels.map((level: any) => ({
    key: String(level.id),
    label: getLocalizedName(level.name, language)
  }))

  // Function to handle selection change
  const handleEducationLevelChange = (selectedLevelId: string) => {
    const selectedLevel = educationLevels.find((level: any) => String(level.id) === selectedLevelId)
    if (selectedLevel) {
      setSelectedEducationStage(selectedLevel.education_stage)
    }
  }

  // Return both options and the selected education_stage
  return { options, selectedEducationStage, handleEducationLevelChange, isLoading }
}
