import React, { useEffect, useState } from 'react'
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'
import { useForm, useFieldArray, FormProvider } from 'react-hook-form'
import FilterCriteria from './FilterCriteria'
import { t } from '@lingui/macro'
import { zodResolver } from '@hookform/resolvers/zod'
import { formatDateNow, formatDateTime, formatTime } from 'utils/time'
import ButtonVariant from 'components/button-variant/button'
import { useCountryOptions } from 'pages/SubmitApplication/hooks/useCountryOptions'
import { useRegionOptions } from 'pages/SubmitApplication/hooks/useRegionOptions'
import {
  useEducationStageOptions,
  useGenderOptions
} from 'pages/AccountProfile/personal-info/utils'
import { useEducationAdministrationOptions } from 'pages/SubmitApplication/hooks/useEducationAdministrationOptions'
import { useUniversityOptions } from 'pages/SubmitApplication/hooks/useUniversityOptions'
import { useSchoolOptions } from 'pages/SubmitApplication/hooks/useSchoolOptions'
import { criteriaSchema } from './schema'
import { useDispatch, useSelector } from 'react-redux'
import { CompetitionYearDetails, Condition, Criteria, WorkflowDetail } from 'api/types'
import {
  setCriteriaUpdated,
  updateWorkflowCriteria
} from 'store/slices/updateWorkFlowCriteriaSlice'
import { AppDispatch, RootState } from 'store'
import Alert from 'components/alert/alert'
type Props = { competition: CompetitionYearDetails | null; workflow: WorkflowDetail | null }

export default function WorkFlowFiltration({ competition, workflow }: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const { _, i18n } = useLingui()
  const [showAlert, setshowAlert] = useState(false)
  const dataFields = [
    {
      key: 'gender',
      label: _(msg`الجنس`)
    },
    {
      key: 'nationality_id',
      label: _(msg`الجنسية`)
    },
    {
      key: 'age',
      label: _(msg`العمر`)
    },
    {
      key: 'country_id',
      label: _(msg`الدولة`)
    },
    {
      key: 'region_id',
      label: _(msg`المنطقة`)
    },
    {
      key: 'education_level_id',
      label: _(msg`المرحلة التعليمية`)
    },
    {
      key: 'education_administration_id',
      label: _(msg`الإدارة التعليمية`)
    },
    {
      key: 'school_id',
      label: _(msg`المدرسة`)
    },
    {
      key: 'university_id',
      label: _(msg`الجامعة`)
    },
    {
      key: 'educationLevel.order',
      label: _(msg`المستوى التعليمي`)
    }
  ]

  const operatorOptions = [
    { key: '=', label: _(msg`يساوي`) },
    { key: '!=', label: _(msg`لا يساوي`) },
    { key: '>', label: _(msg`أكبر من`) },
    { key: '<', label: _(msg`أصغر من`) }
  ]
  const operationOptionsMulti = [
    { key: 'in', label: _(msg`ضمن`) },
    { key: 'not in', label: _(msg`ليس ضمن`) }
  ]

  const formatCriteriaForDefaultValues = (criteria: Criteria) => {
    const { logical, conditions } = criteria

    return conditions.map((condition: any, index) => ({
      linkOperator: index == 0 ? '' : logical,
      data: condition.field,
      operator: condition.operator,
      value: condition.value
    }))
  }
  const methods = useForm({
    defaultValues: {
      criteria: workflow?.criteria
        ? formatCriteriaForDefaultValues(workflow?.criteria)
        : [{ linkOperator: '', data: '', operator: '', value: '' }]
    },
    resolver: zodResolver(criteriaSchema)
  })
  const { criteriaUpdated, isLoading } = useSelector(
    (state: RootState) => state.updateWorkFlowCriteria
  )
  const { control, handleSubmit, watch } = methods
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'criteria'
  })
  const selectedData = watch('criteria')

  const onSubmit = (formData: any) => {
    const { criteria } = formData

    let result = {
      logical: 'AND',
      conditions: []
    }

    if (criteria[0]) {
      const { data, operator, value } = criteria[0]
      result.conditions.push({
        field: data,
        operator: operator,
        value: value
      } as never)
    }

    if (criteria[1]) {
      const { linkOperator, data, operator, value } = criteria[1]
      result.logical = linkOperator

      result.conditions.push({
        field: data,
        operator: operator,
        value: value
      } as never)
    }
    dispatch(
      updateWorkflowCriteria({
        competitionId: competition?.id,
        workflowId: workflow?.id,
        criteriaData: result
      } as any)
    )
  }

  const { options: countries } = useCountryOptions()
  const { options: regions } = useRegionOptions(
    selectedData[selectedData.length - 2] &&
      selectedData[selectedData.length - 2].data == 'country_id'
      ? selectedData[selectedData.length - 2].value
      : undefined
  )
  const educationLevelOptions = Array.from({ length: 4 }, (v, i) => ({
    key: (i + 1).toString(),
    label: (i + 1).toString()
  }))

  console.log(educationLevelOptions)

  const educationStages = useEducationStageOptions()
  const { options: administrations } = useEducationAdministrationOptions({
    countryId:
      selectedData[selectedData.length - 2] &&
      selectedData[selectedData.length - 2]?.data == 'country_id'
        ? selectedData[selectedData.length - 2]?.value
        : undefined,
    regionId:
      selectedData[selectedData.length - 2] &&
      selectedData[selectedData.length - 2]?.data == 'region_id'
        ? selectedData[selectedData.length - 2]?.value
        : undefined
  })
  const { options: universities } = useUniversityOptions({
    countryId:
      selectedData[selectedData.length - 2] &&
      selectedData[selectedData.length - 2].data == 'country_id'
        ? selectedData[selectedData.length - 2]?.value
        : undefined,
    regionId:
      selectedData[selectedData.length - 2] &&
      selectedData[selectedData.length - 2]?.data == 'region_id'
        ? selectedData[selectedData.length - 2]?.value
        : undefined
  })
  const { options: schools } = useSchoolOptions({
    countryId:
      selectedData[selectedData.length - 2] &&
      selectedData[selectedData.length - 2]?.data == 'country_id'
        ? selectedData[selectedData.length - 2].value
        : undefined,
    regionId:
      selectedData[selectedData.length - 2]?.data == 'region_id'
        ? selectedData[selectedData.length - 2]?.value
        : undefined
  })
  const getOptionsByKey = (key: string) => {
    switch (key) {
      case 'gender':
        return genders_options
      case 'nationality_id':
        return countries
      case 'country_id':
        return countries
      case 'region_id':
        return regions
      case 'education_level_id':
        return educationStages
      case 'education_administration_id':
        return administrations
      case 'school_id':
        return schools
      case 'university_id':
        return universities
      case 'educationLevel.order':
        return educationLevelOptions
      default:
        return []
    }
  }

  const genders_options = useGenderOptions()
  const valueOptionsForCurrentField = selectedData.map((item, index) => {
    const selectedKey = item.data
    return getOptionsByKey(selectedKey)
  })
  const linkOperatorOptions = [
    { key: 'AND', label: t`و` },
    { key: 'OR', label: t`أو` }
  ]
  const getAvailableDataFields = (index: number) => {
    const previousSelections = selectedData
      .slice(0, index)
      .map((row) => row.data)
      .filter(Boolean)
    if (index === 0) {
      return dataFields.filter(
        (field) =>
          ![
            'region_id',
            'education_level_id',
            'education_administration_id',
            'school_id',
            'university_id',
            'educationLevel.order'
          ].includes(field.key)
      )
    }
    const previousRow = selectedData[index - 1]
    const countrySelected = previousRow?.data === 'country_id'
    const isSaudi = previousRow?.value === '917'
    return dataFields.filter(
      (field) =>
        !previousSelections.includes(field.key) &&
        ((field.key === 'region_id' && countrySelected) ||
          (field.key === 'education_level_id' && countrySelected) ||
          (field.key !== 'educationLevel.order' && countrySelected) ||
          (field.key === 'education_administrative_id' && isSaudi) ||
          ((field.key === 'school_id' || field.key === 'university_id') && countrySelected) ||
          (field.key !== 'region_id' &&
            field.key !== 'education_level_id' &&
            field.key !== 'education_administrative_id' &&
            field.key !== 'school_id' &&
            field.key !== 'university_id'))
    )
  }
  useEffect(() => {
    setshowAlert(criteriaUpdated)
  }, [criteriaUpdated])
  const onCloseAlert = () => {
    dispatch(setCriteriaUpdated(false))
  }
  return (
    <div>
      <div className="flex flex-col gap-2 pb-5">
        <h4 className="font-sub-heading-h4">
          {_(msg`معايير التصفية لسير المشاركة الخاصة بالطلاب الجامعيين`)}
        </h4>
        <span className="text-primary_lv2_text_normal text-sm">
          {_(msg`أخر تحديث:`)}{' '}
          {formatDateTime((workflow?.updated_at ?? '')?.toString(), i18n.locale as any)}
        </span>
      </div>
      {showAlert && (
        <Alert
          closable
          mainText={_(msg`تم حفظ التحديثات بنجاح`)}
          sizeMainText="md"
          weightMainText="normal"
          onClose={onCloseAlert}
          type={'success'}
        />
      )}
      <FormProvider {...methods}>
        <form
          className="bg-natural_fill_normal_hover_pressed_active border p-6 rounded-lg"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-6">
            {fields.map((item, index) => (
              <div key={item.id}>
                <FilterCriteria
                  isMulti={selectedData?.[index]?.data === 'educationLevel.order'}
                  hasAge={selectedData?.[index]?.data === 'age'}
                  linkOperatorOptions={linkOperatorOptions}
                  operatorOptions={
                    selectedData?.[index]?.data === 'educationLevel.order'
                      ? operationOptionsMulti
                      : operatorOptions
                  }
                  valueFields={valueOptionsForCurrentField[index]}
                  dataFields={getAvailableDataFields(index)}
                  remove={remove}
                  index={index}
                />
              </div>
            ))}
          </div>
          <div className="flex flex-row justify-end gap-2 pt-10">
            <ButtonVariant
              isDisabled={fields.length >= 2}
              variant="secondary-outline"
              type="button"
              onClick={() => append({ linkOperator: '', data: '', operator: '', value: '' })}
            >
              {_(msg`إضافة معار جديد`)} +
            </ButtonVariant>

            <ButtonVariant isLoading={isLoading} variant="secondary" type="submit">
              {_(msg`حفظ التغييرات`)}
            </ButtonVariant>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}
