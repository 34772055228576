import { Accordion, AccordionItem } from 'components/accordion/Accordion'
import React from 'react'
import FormLayout from 'components/application-viewer/components/FormLayout'
import { steps } from 'components/application-viewer/components/Steps'
import { useLingui } from '@lingui/react'
import { Application } from 'store/slices/applicationsSlice'

export interface props {
    user_role: string
    applicationData: Application
}

const ApplicationViewer: React.FC<props> = ({ applicationData, user_role }) => {
    const { _ } = useLingui()

    const filteredSteps = steps(applicationData).filter(step =>
        step.roles.includes(user_role)
    )

    return (
        <Accordion showDivider={false} variant="new">
            {filteredSteps.map((step, index) => (
                <AccordionItem key={index} aria-label={`Accordion ${index + 1}`} title={_(step.title)}>
                    <FormLayout>{step.component(applicationData)}</FormLayout>
                </AccordionItem>
            ))}
        </Accordion>
    )
}

export default ApplicationViewer
