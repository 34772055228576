import React from 'react'
import ButtonVariant from 'components/button-variant/button'
import { Popover, PopoverContent, PopoverTrigger } from 'components/popover/popover'
import { useState } from 'react'
import { Trans, msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import SettingElement from './settingElement'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import TableSetting from 'assets/icons/TableSetting'
interface Column {
  label: string
  key: string
}
export default function TableSettingPopover({
  columns,
  handleFilterColumns,
  visibleColumns,
  handleApply,
  handleReset
}: {
  columns: Column[]
  handleFilterColumns: (col: Column) => void
  visibleColumns: Column[]
  handleApply: () => void
  handleReset: () => void
}) {
  const { _ } = useLingui()
  const [popoverVisible, setPopoverVisible] = useState(false)
  const direction = useSelector((state: RootState) => state.layout.direction)
  const colChecked = (col: Column) => {
    return visibleColumns.find((co) => co.key == col.key) != undefined
  }
  return (
    <Popover
      size="lg"
      triggerType="menu"
      isOpen={popoverVisible}
      onOpenChange={setPopoverVisible}
      placement={direction === 'rtl' ? 'bottom-end' : 'bottom-start'}
    >
      <PopoverTrigger>
        <div>
          <ButtonVariant
            isIconOnly
            radius="full"
            variant="secondary"
            onClick={() => setPopoverVisible(!popoverVisible)}
          >
            <TableSetting></TableSetting>
          </ButtonVariant>
        </div>
      </PopoverTrigger>

      <PopoverContent className="w-full">
        <div className="p-2">
          <div className="flex flex-col gap-1">
            <span className="text-primary">
              <Trans>التخصيص </Trans>
            </span>
            <span className="pb-3 text-secondary_lv2_text_normal">
              <Trans>تم اختيار </Trans>
              {' ' + visibleColumns.length + ' '}
              <Trans>من أصل</Trans> {columns.length >= 6 ? 6 : columns.length}
            </span>
          </div>

          {columns?.map((col, i) => (
            <SettingElement
              key={i}
              onchange={() => handleFilterColumns(col)}
              checked={colChecked(col)}
              hideDivider={i == columns.length - 1}
              setting_name={col.label}
            ></SettingElement>
          ))}
        </div>
        <div className="py-4 px-2 w-full flex gap-2 justify-end bg-[#F9FAFB] border-t border-[#E0E0E0]">
          <ButtonVariant size="sm" variant="secondary" onClick={handleApply}>
            {_(msg`تأكيد`)}
          </ButtonVariant>

          <ButtonVariant
            variant="clear"
            size="sm"
            className="border border-natural_stroke_normal_disabled text-natural_icon_normal bg-transparent rounded-full font-bold"
            onClick={handleReset}
          >
            {_(msg`الغاء`)}
          </ButtonVariant>
        </div>
      </PopoverContent>
    </Popover>
  )
}
