import React from 'react'
import SubmitApplication from './SubmitApplication'
import Loader from 'components/loader/Loader'
import { useStudentActiveApplication } from 'hooks/useStudentActiveApplication'
import dayjs from 'dayjs'
import Page404 from 'pages/Page404'

const SubmissionStatusCheck: React.FC = () => {
  const { currentCompetYear, apps, loading } = useStudentActiveApplication()

  const renderContent = () => {
    if (loading) {
      return <Loader />
    }

    const isValidDate = currentCompetYear && dayjs().isBefore(dayjs(currentCompetYear.close_at))

    if (currentCompetYear && currentCompetYear.status === 'started' && isValidDate) {
      if (apps && apps.length > 0) {
        const { status } = apps[0]

        switch (status.id) {
          case 1:
            return <SubmitApplication />
          default:
            return <Page404 />
        }
      } else {
        return <SubmitApplication />
      }
    } else {
      return <Page404 />
    }
  }

  return <div className="h-full w-full">{renderContent()}</div>
}

export default SubmissionStatusCheck
