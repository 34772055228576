import { useMemo } from 'react'
import { Report } from 'api/types'
import { getNameByLanguage } from 'utils/getNameByLanguage'
import { getEducationStageLabel } from 'constants/educationStageOptions'

export const useReportData = (reports: Report, locale: 'ar' | 'en') => {
  return useMemo(() => {
    const dataRegions = (reports?.countryRegionalApplications ?? []).map((_: any) => ({
      itemName: getNameByLanguage(_?.region_name, locale),
      numberOfApplications: _.total_applications
    }))

    const educationStagesData = (reports?.regionalStagesApplications ?? []).map((_: any) => ({
      ..._,
      name: getNameByLanguage(_?.region_name, locale),
      progressItems: [
        {
          name: getEducationStageLabel('general', locale),
          bgColor: 'bg-secondary_fill_normal_pressed',
          textColor: 'text-secondary_fill_normal_pressed',
          percentage: _?.general_percentage
        },
        {
          name: getEducationStageLabel('university', locale),
          bgColor: 'bg-information_default_fill_hover_active',
          textColor: 'text-information_default_fill_hover_active',
          percentage: _?.university_percentage
        }
      ]
    }))

    const dataEducationStagesProgress = (reports?.stagesApplications ?? []).map((_: any) => ({
      ..._,
      name: getEducationStageLabel(_?.stage, locale),
      percentage: _.percentage,
      total_applications: '' + _.total_applications
    }))

    const dataTopCoordinators = {
      byRegion: (reports?.topCoordinators?.byRegion ?? []).map((_: any) => ({
        ..._,
        userName: _.coordinator_name,
        numberOfApplications: _.points,
        region: getNameByLanguage(_.region_name, locale)
      })),
      byAdministration: (reports?.topCoordinators?.byAdministration ?? []).map((_: any) => ({
        ..._,
        userName: _.coordinator_name,
        numberOfApplications: _.points,
        educationAdministration: getNameByLanguage(_.education_administration_name, locale)
      }))
    }

    const dataTopTeachers = {
      byRegion: (reports?.topTeachers?.byRegion ?? []).map((_: any) => ({
        ..._,
        userName: _.teacher_name,
        numberOfApplications: _.total_applications,
        region: getNameByLanguage(_.region_name, locale)
      })),
      byAdministration: (reports?.topTeachers?.byAdministration ?? []).map((_: any) => ({
        ..._,
        userName: _.teacher_name,
        numberOfApplications: _.total_applications,
        educationAdministration: getNameByLanguage(_.education_administration_name, locale)
      }))
    }

    const EducationAdministativeData = {
      top: (reports?.topEducationAdministrations ?? []).map((_: any) => ({
        ..._,
        itemName: getNameByLanguage(_?.education_administration_name, locale),
        percentage: _.percentage,
        numberOfApplications: _.total_applications
      })),
      all: (reports?.administrativesApplications ?? []).map((_: any) => ({
        ..._,
        itemName: getNameByLanguage(_?.education_administration_name, locale),
        numberOfApplications: _.total_applications,
        percentage: _.percentage
      })),
      byGender: (reports?.genderAdministrativesApplications ?? []).map((_: any) => ({
        ..._,
        itemName: getNameByLanguage(_?.education_administration_name, locale),
        numberOfApplications: undefined
      }))
    }
    const universitiesApplications = (reports?.universitiesApplications ?? []).map((_) => ({
      ..._,
      label: _.university_name
    }))
    const reachPercentage = (reports?.reachPercentage ?? []).map((_) => ({
      ..._,
      itemName: _.coordinator_name
    }))
    return {
      dataRegions,
      educationStagesData,
      dataEducationStagesProgress,
      dataTopCoordinators,
      dataTopTeachers,
      EducationAdministativeData,
      universitiesApplications,
      reachPercentage
    }
  }, [reports, locale])
}
