import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'

export interface CompetitionData {
  id: number
  name: string
  start_date: string
  end_date: string
  close_at: string
  status: string
  image: string | null
}

interface CompetitionYearResponse {
  current: CompetitionData
  upcoming: CompetitionData
}

interface CompetitionState {
  current: CompetitionData | null
  upcoming: CompetitionData | null
  isLoading: boolean
  error: string | null
}

const initialState: CompetitionState = {
  current: null,
  upcoming: null,
  isLoading: false,
  error: null,
}

export const fetchCompetitionYear = createAsyncThunk<CompetitionYearResponse>(
  'competition/fetchCompetitionYear',
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance.get('/competition-year')
      return response.data.data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

const competitionSlice = createSlice({
  name: 'competition',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompetitionYear.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchCompetitionYear.fulfilled, (state, action) => {
        state.isLoading = false
        state.current = action.payload.current
        state.upcoming = action.payload.upcoming
      })
      .addCase(fetchCompetitionYear.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload as string
      })
  },
})

export default competitionSlice
