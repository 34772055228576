const FilterIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.11536 18.875C8.71536 18.875 8.3237 18.775 7.95703 18.575C7.2237 18.1667 6.78203 17.425 6.78203 16.5917V12.175C6.78203 11.7583 6.50703 11.1333 6.2487 10.8167L3.13203 7.51667C2.60703 6.99167 2.20703 6.09167 2.20703 5.41667V3.5C2.20703 2.16667 3.21536 1.125 4.4987 1.125H15.4987C16.7654 1.125 17.7904 2.15 17.7904 3.41667V5.25C17.7904 6.125 17.2654 7.11667 16.7737 7.60833L13.1654 10.8C12.8154 11.0917 12.5404 11.7333 12.5404 12.25V15.8333C12.5404 16.575 12.0737 17.4333 11.4904 17.7833L10.3404 18.525C9.96536 18.7583 9.54036 18.875 9.11536 18.875ZM4.4987 2.375C3.91536 2.375 3.45703 2.86667 3.45703 3.5V5.41667C3.45703 5.725 3.70703 6.325 4.0237 6.64167L7.1987 9.98333C7.6237 10.5083 8.04036 11.3833 8.04036 12.1667V16.5833C8.04036 17.125 8.41536 17.3917 8.5737 17.475C8.9237 17.6667 9.3487 17.6667 9.6737 17.4667L10.832 16.725C11.0654 16.5833 11.2987 16.1333 11.2987 15.8333V12.25C11.2987 11.3583 11.732 10.375 12.357 9.85L15.9237 6.69167C16.207 6.40833 16.5487 5.73333 16.5487 5.24167V3.41667C16.5487 2.84167 16.082 2.375 15.507 2.375H4.4987Z"
        fill="#3F3F46"
      />
      <path
        d="M4.99908 8.96132C4.88241 8.96132 4.77408 8.92799 4.66575 8.86965C4.37408 8.68632 4.28241 8.29465 4.46575 8.00299L8.57408 1.41965C8.75741 1.12799 9.14075 1.03632 9.43241 1.21965C9.72408 1.40299 9.81575 1.78632 9.63241 2.07799L5.52408 8.66132C5.40741 8.85299 5.20741 8.96132 4.99908 8.96132Z"
        fill="#3F3F46"
      />
    </svg>
  )
}

export default FilterIcon
