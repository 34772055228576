import { FC } from 'react'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import useLanguage from 'hooks/useLanguage'
import SearchAndFilter from 'components/search-and-filter/SearchAndFilter' 
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import {
  getRequestStatusFilterOptions,
  getRequestStatusFilterLabel
} from 'constants/requestStatusFilterOptions'

interface ApplicationsFiltersProps {
  dateRange: { from: string; to: string }
  setDateRange: (range: { from: string; to: string }) => void
  selectedYear: string
  setSelectedYear: (year: string) => void
  status: number | undefined
  setStatus: (status: number | undefined) => void
  searchValue: string
  setSearchValue: (value: string) => void
  onFilter: () => void
  onCancel: () => void
}

const ApplicationsFilters: FC<ApplicationsFiltersProps> = ({
  dateRange,
  setDateRange,
  selectedYear,
  setSelectedYear,
  status,
  setStatus,
  searchValue,
  setSearchValue,
  onFilter,
  onCancel
}) => {
  const { _ } = useLingui()
  const language = useLanguage()

  const statusOptions = getRequestStatusFilterOptions(language).map(option =>
    getRequestStatusFilterLabel(option.value, language)
  )

  const yearOptions = useSelector((state: RootState) => state.competitionYearNavbar.competitionYears)
  const competitionYearOptions = yearOptions ? yearOptions.map(year => ({
    key: year.id,
    value: year.name
  })) : []

  const statusLabelToIdMap: Record<string, number> = {
    draft: 1,
    pending: 2,
    approved: 3,
    rejected: 4,
  }

  const handleDateRangeChange = (range: { from: string; to: string }) => {
    setDateRange(range)
  }

  const handleYearChange = (yearName: string) => {
    const selectedOption = competitionYearOptions.find(option => option.value === yearName)
    if (selectedOption) {
      setSelectedYear(selectedOption.key.toString())
    }
  }

  const handleStatusChange = (statusLabel: string) => {
    const selectedOption = Object.keys(statusLabelToIdMap).find(key =>
      getRequestStatusFilterLabel(key, language) === statusLabel
    )
    if (selectedOption) {
      setStatus(statusLabelToIdMap[selectedOption])
    }
  }

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
  }

  const filters = [
    {
      type: 'dateRange',
      label: _(msg`تاريخ تقديم الطلب`),
      value: dateRange,
      onChange: handleDateRangeChange
    },
    {
      type: 'dropdown',
      label: _(msg`سنة المسابقة`),
      value: competitionYearOptions.find(option => option.key === +selectedYear)?.value || '',
      options: competitionYearOptions.map(option => option.value),
      onChange: handleYearChange
    },
    {
      type: 'dropdown',
      label: _(msg`حالة الطلب`),
      value: status ? getRequestStatusFilterLabel(status.toString(), language) : '',
      options: statusOptions,
      onChange: handleStatusChange
    }
  ]

  return (
    <SearchAndFilter
      searchValue={searchValue}
      onSearchChange={handleSearchChange}
      filters={filters}
      onFilter={onFilter}
      onCancel={onCancel}
    />
  )
}

export default ApplicationsFilters
