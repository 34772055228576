import { extendVariants, Progress as nextUiProgress, ProgressProps } from '@nextui-org/react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export const ProgressV = extendVariants(nextUiProgress, {
  variants: {
    color: {
      default: {
        indicator: 'bg-natural_stroke_normal_disabled'
      },
      primary: {
        indicator: 'bg-tertiary_normal'
      },
      secondary: {
        indicator: 'bg-secondary'
      },
      success: {
        indicator: 'bg-secondary_fill_active'
      },
      warning: {
        indicator: 'bg-warning'
      },
      danger: {
        indicator: 'bg-danger_default_stroke_normal'
      }
    }
  }
})


const Progress: React.FC<ProgressProps> = (props: ProgressProps) => {
  const direction = useSelector((state: RootState) => state.layout.direction);

  const baseClass = direction === 'rtl' ? 'rotate-180' : '';

  return (
    <ProgressV
      {...props}
      className={baseClass}
    />
  )
}

export default Progress;
