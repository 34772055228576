import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { ActivateRequestResponse } from 'api/types'

interface ActivateRequestState {
  isLoading: boolean
  error: string | null
}

interface ActivateRequestPayload {
  id: string
  formData: Record<string, any>
}

const initialState: ActivateRequestState = {
  isLoading: false,
  error: null
}

export const activateRequest = createAsyncThunk(
  'activateRequest',
  async ({ id, formData }: ActivateRequestPayload, thunkAPI) => {
    try {
      const response = await axiosInstance.post<ActivateRequestResponse>(
        `/management/coordinators/${id}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const activateRequestSlice = createSlice({
  name: 'activateRequest',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(activateRequest.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(activateRequest.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(activateRequest.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default activateRequestSlice
