import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { Award } from 'api/types'

interface Target {
  id: number
  education_stage: string
  number: number
  percentages: {
    id: number
    percentage_number: number
    operator: string
    remaining_applications: number
    awards: Award[]
  }[]
}

interface CoordinatorStatsResponse {
  status: string
  code: number
  message: string
  data: {
    target: Target
    applications_count: number
    points_count: number
    progress_percentage: number
    awards: Award[]
    awards_count: number
    total_students: number
  }
  error: object | null
}
interface CoordinatorStatsState {
  applicationsCount: number
  pointsCount: number
  progressPercentage: number
  awards: CoordinatorStatsResponse['data']['awards']
  awardsCount: number
  totalStudents: number
  target: CoordinatorStatsResponse['data']['target'] | null
  isLoading: boolean
  error: string | null
}

const initialState: CoordinatorStatsState = {
  applicationsCount: 0,
  pointsCount: 0,
  progressPercentage: 0,
  awards: [],
  awardsCount: 0,
  totalStudents: 0,
  target: null,
  isLoading: false,
  error: null
}

export const fetchCoordinatorStats = createAsyncThunk(
    'coordinatorStats/fetch',
    async ({ competition_year_id }: { competition_year_id: string }) => {
      const response = await axiosInstance.get<CoordinatorStatsResponse>(`/coordinators/points?competition_year_id=${competition_year_id}`)
      return response.data
    }
  )

  const coordinatorStatsSlice = createSlice({
    name: 'coordinatorStats',
    initialState,
    reducers: {
      setCoordinatorStats: (state, action: PayloadAction<CoordinatorStatsResponse['data']>) => {
        const { applications_count, points_count, progress_percentage, awards, awards_count, total_students, target } = action.payload
        state.applicationsCount = applications_count
        state.pointsCount = points_count
        state.progressPercentage = progress_percentage
        state.awards = awards
        state.awardsCount = awards_count
        state.totalStudents = total_students
        state.target = target
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchCoordinatorStats.pending, (state) => {
          state.isLoading = true
          state.error = null
        })
        .addCase(fetchCoordinatorStats.fulfilled, (state, action) => {
          state.isLoading = false
          const { applications_count, points_count, progress_percentage, awards, awards_count, total_students, target } = action.payload.data
          state.applicationsCount = applications_count
          state.pointsCount = points_count
          state.progressPercentage = progress_percentage
          state.awards = awards
          state.awardsCount = awards_count
          state.totalStudents = total_students
          state.target = target
        })
        .addCase(fetchCoordinatorStats.rejected, (state, action) => {
          state.isLoading = false
          state.error = action.error.message ?? 'An error occurred'
        })
    }
  })

export const { setCoordinatorStats } = coordinatorStatsSlice.actions

export default coordinatorStatsSlice
