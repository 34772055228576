import SelectInput from 'components/hook-form/select-input'
import React from 'react'
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'
import ButtonVariant from 'components/button-variant/button'
import DateInput from 'components/hook-form/date-picker-input'
import TextInput from 'components/hook-form/text-input'
import { useCountryOptions } from 'pages/SubmitApplication/hooks/useCountryOptions'
import { useFormContext } from 'react-hook-form'
import { useRegionOptions } from 'pages/SubmitApplication/hooks/useRegionOptions'
import { useEducationAdministrationOptions } from 'pages/SubmitApplication/hooks/useEducationAdministrationOptions'
import {
  useEducationStageOptions,
  useGenderOptions
} from 'pages/AccountProfile/personal-info/utils'
import { useUniversityOptions } from 'pages/SubmitApplication/hooks/useUniversityOptions'
import { useSchoolOptions } from 'pages/SubmitApplication/hooks/useSchoolOptions'
import { useWorkflowOptions } from 'pages/SubmitApplication/hooks/useWokFlowsOptions'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import useTeacherOptions from 'pages/SubmitApplication/hooks/useTeachersOptions'
import useStudentOptions from 'pages/SubmitApplication/hooks/useStudentsOptions'
export default function ReportFiltersForm() {
  const { _ } = useLingui()
  const { watch, setValue } = useFormContext()
  const country = watch('country')
  const region = watch('region')
  const { competitionYear } = useSelector((state: RootState) => state.competitionYearNavbar)
  const { options: countries } = useCountryOptions()
  const { options: regions } = useRegionOptions(country)
  const educationStages = useEducationStageOptions()
  const { options: administrations } = useEducationAdministrationOptions({
    countryId: country,
    regionId: region
  })
  const { options: universities } = useUniversityOptions({
    countryId: country,
    regionId: region
  })
  const { options: schools } = useSchoolOptions({
    countryId: country,
    regionId: region
  })
  const { options: workflows_options } = useWorkflowOptions(
    competitionYear?.id ? competitionYear?.id : 1
  )
  const genders_options = useGenderOptions()
  const applications_status = [
    {
      key: 'draft',
      label: _(msg` محفوظة كمسودة`).toString()
    },
    {
      key: 'pending',
      label: _(msg`تم تسليم المشاركة`).toString()
    }
  ]
  const is_linked_to_teacher_options = [
    {
      key: '1',
      label: _(msg`نعم`).toString()
    },
    {
      key: '0',
      label: _(msg`لا`).toString()
    }
  ]
  const { options: teacher_options } = useTeacherOptions()
  const { options: students_options } = useStudentOptions()

  return (
    <div>
      <div className="space-y-4  px-4 pb-24 ">
        <div className="flex w-full gap-4">
          <div className="w-1/2 flex-1">
            <SelectInput
              isChips
              labelClassName="!text-primary !font-normal"
              selectionMode="single"
              name="country"
              label={_(msg`الدولة`)}
              placeholder={_(msg`الدولة`)}
              classNames={{
                trigger: 'border '
              }}
              options={countries}
            />
          </div>
          <div className="w-1/2 flex-1">
            <SelectInput
              labelClassName="!text-primary !font-normal"
              selectionMode="single"
              name="region"
              label={_(msg`المنطقة`)}
              placeholder={_(msg`المنطقة`)}
              classNames={{
                trigger: 'border '
              }}
              options={regions}
            />
          </div>
        </div>
        <div className="flex w-full gap-4">
          <div className="w-1/2 flex-1">
            <SelectInput
              labelClassName="!text-primary !font-normal"
              selectionMode="single"
              name="education_stage"
              label={_(msg`المرحلة التعليمية`)}
              placeholder={_(msg`المرحلة التعليمية`)}
              classNames={{
                trigger: 'border '
              }}
              options={educationStages}
            />
          </div>
          <div className="w-1/2 flex-1">
            <SelectInput
              labelClassName="!text-primary !font-normal"
              selectionMode="single"
              name="education_administration"
              label={_(msg`الأدارة التعليمية`)}
              placeholder={_(msg`الأدارة التعليمية`)}
              classNames={{
                trigger: 'border '
              }}
              options={administrations}
            />
          </div>
        </div>
        <div className="flex w-full gap-4">
          <div className="w-1/2 flex-1">
            <SelectInput
              clearable
              labelClassName="!text-primary !font-normal"
              selectionMode="single"
              name="university"
              label={_(msg`الجامعة`)}
              placeholder={_(msg`الجامعة`)}
              classNames={{
                trigger: 'border '
              }}
              options={universities}
            />
          </div>
          <div className="w-1/2 flex-1">
            <SelectInput
              labelClassName="!text-primary !font-normal"
              selectionMode="single"
              name="school"
              label={_(msg`المدرسة`)}
              placeholder={_(msg`المدرسة`)}
              classNames={{
                trigger: 'border '
              }}
              options={schools}
            />
          </div>
        </div>
        <div className="flex w-full gap-4">
          <div className="w-1/2 flex-1">
            <DateInput
              labelClassName="!text-primary !font-normal"
              selectionMode="single"
              name="participation_start_date"
              label={_(msg`تاريخ التقديم (من)`)}
              placeholder={_(msg`تاريخ التقديم (من)`)}
              classNames={{
                trigger: 'border '
              }}
              options={workflows_options}
            />
          </div>
          <div className="w-1/2 flex-1">
            <DateInput
              labelClassName="!text-primary !font-normal"
              selectionMode="single"
              name="participation_end_date"
              label={_(msg`تاريخ التقديم (إلي)`)}
              placeholder={_(msg`تاريخ التقديم (إلي)`)}
              classNames={{
                trigger: 'border '
              }}
              options={[]}
            />
          </div>
        </div>
        <div className="flex w-full gap-4">
          <div className="w-1/2 flex-1">
            <SelectInput
              labelClassName="!text-primary !font-normal"
              selectionMode="single"
              name="gender"
              label={_(msg`الجنس`)}
              placeholder={_(msg`الجنس`)}
              classNames={{
                trigger: 'border '
              }}
              options={genders_options}
            />
          </div>
          <div className="w-1/2 flex-1">
            <SelectInput
              labelClassName="!text-primary !font-normal"
              selectionMode="single"
              name="is_linked_to_teacher"
              label={_(msg`مرتبط بالمعلم`)}
              placeholder={_(msg`مرتبط بالمعلم`)}
              classNames={{
                trigger: 'border '
              }}
              options={is_linked_to_teacher_options}
            />
          </div>
        </div>
        <div className="flex w-full gap-4">
          <div className="w-1/2 flex-1">
            <SelectInput
              labelClassName="!text-primary !font-normal"
              selectionMode="single"
              name="teacher_name"
              label={_(msg`اسم المعلم المرتبط`)}
              placeholder={_(msg`اسم المعلم المرتبط`)}
              classNames={{
                trigger: 'border '
              }}
              options={teacher_options}
            />
          </div>
          <div className="w-1/2 flex-1">
            <SelectInput
              labelClassName="!text-primary !font-normal"
              selectionMode="single"
              name="application_status"
              label={_(msg`حالة المشاركة`)}
              placeholder={_(msg`حالة المشاركة`)}
              classNames={{
                trigger: 'border '
              }}
              options={applications_status as any}
            />
          </div>
        </div>
        <div className="flex w-full gap-4">
          <div className="w-1/2 flex-1">
            <SelectInput
              labelClassName="!text-primary !font-normal"
              selectionMode="single"
              name="participation_group"
              label={_(msg`مجموعة المشاركة`)}
              placeholder={_(msg`مجموعة المشاركة`)}
              classNames={{
                trigger: 'border '
              }}
              options={workflows_options ?? []}
            />
          </div>
          <div className="w-1/2 flex-1">
            <SelectInput
              labelClassName="!text-primary !font-normal"
              selectionMode="single"
              name="student_name"
              label={_(msg`الطالب`)}
              placeholder={_(msg`الطالب`)}
              classNames={{
                trigger: 'border '
              }}
              options={students_options}
            />
          </div>
        </div>
        <div className="flex w-full gap-4">
          <div className="w-1/2 flex-1">
            <TextInput
              labelClassName="!text-primary !font-normal"
              name="studentEmail"
              label={_(msg`البريد الألكتروني للطالب`)}
            />
          </div>
          <div className="w-1/2 flex-1">
            <TextInput
              labelClassName="!text-primary !font-normal"
              name="participationNumber"
              label={_(msg`رقم المشاركة`)}
            />
          </div>
        </div>
      </div>

      <div className="fixed bottom-0 left-0 right-0  bg-white pb-8 pt-4 flex justify-end gap-2 px-4">
        <ButtonVariant type="submit" size="sm" variant="secondary">
          {_(msg`تأكيد`)}
        </ButtonVariant>
        <ButtonVariant
          size="sm"
          className="border border-natural_stroke_normal_disabled text-natural_icon_normal bg-transparent hover:bg-gray-100 focus:bg-transparent rounded-full font-bold"
          type="reset"
        >
          {_(msg`الغاء`)}
        </ButtonVariant>
      </div>
    </div>
  )
}
