const AppsIcon = ({ fill = '#3F3F46' }: { size?: string; fill?: string }) => {
  return (
    <svg fill={fill} width="21" height="20" viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6667 1.66667H17.1667C17.3877 1.66667 17.5996 1.75446 17.7559 1.91074C17.9122 2.06702 18 2.27899 18 2.5V17.5C18 17.721 17.9122 17.933 17.7559 18.0893C17.5996 18.2455 17.3877 18.3333 17.1667 18.3333H3.83333C3.61232 18.3333 3.40036 18.2455 3.24408 18.0893C3.0878 17.933 3 17.721 3 17.5V2.5C3 2.27899 3.0878 2.06702 3.24408 1.91074C3.40036 1.75446 3.61232 1.66667 3.83333 1.66667H6.33333V0H8V1.66667H13V0H14.6667V1.66667ZM14.6667 3.33333V5H13V3.33333H8V5H6.33333V3.33333H4.66667V16.6667H16.3333V3.33333H14.6667ZM6.33333 6.66667H14.6667V8.33333H6.33333V6.66667ZM6.33333 10H14.6667V11.6667H6.33333V10Z"
        fill="#3F3F46"
      />
    </svg>
  )
}

export default AppsIcon
