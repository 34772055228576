import React from 'react'
import CheckMarkIcon from 'assets/icons/CheckMarkIcon'
import MarkIcon from 'assets/icons/MarkIcon'
import CloseCircleIcon from 'assets/icons/CloseCircleIcon'
import { Activity } from 'store/slices/studentApplicationHistorySlice'
import { timeAgo } from 'utils/time'
import UserAvatar from 'pages/auth/components/UserAvatar'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

interface VerticalStepperProps {
  steps: Activity[]
}

const VerticalStepper: React.FC<VerticalStepperProps> = ({ steps }) => {
  const { _ } = useLingui()

  const getStepStyles = (description: string) => {
    switch (description) {
      case 'application.submitted':
        return {
          icon: <CheckMarkIcon fill="currentColor" />,
          style:
            'border-secondary_fill_normal_pressed bg-secondary_fill_hover text-natural_icon_normal',
          description: _(msg`تقديم المشاركة`)
        }
      case 'application.reminder.sent':
        return {
          icon: <CheckMarkIcon fill="currentColor" />,
          style:
            'border-secondary_fill_normal_pressed bg-secondary_fill_hover text-natural_icon_normal',
          description: _(msg`تم إرسال التذكير بالنموذج`)
        }
      case 'application.created':
        return {
          icon: <CheckMarkIcon fill="currentColor" />,
          style:
            'border-secondary_fill_normal_pressed bg-secondary_fill_hover text-natural_icon_normal',
          description: _(msg`تم إنشاء المشاركة`)
        }
      case 'application.rejected':
        return {
          icon: <CloseCircleIcon fill="currentColor" />,
          style:
            'bg-danger_inverted_fill_normal border border-danger_inverted_stroke_normal text-natural_icon_normal',
          description: _(msg`تم رفض النموذج`)
        }
      case 'application.restored':
        return {
          icon: <MarkIcon fill="currentColor" />,
          style:
            'bg-information_inverted_fill_normal border border-information_inverted_stroke_normal_active text-natural_icon_normal',
          description: _(msg`استعادة المشاركة`)
        }
      case 'application.workflow.updated':
        return {
          icon: <MarkIcon fill="currentColor" />,
          style:
            'bg-information_inverted_fill_normal border border-information_inverted_stroke_normal_active text-natural_icon_normal',
          description: _(msg`تم تحديث سير العمل`)
        }
      default:
        return {
          icon: <MarkIcon fill="currentColor" />,
          style:
            'bg-information_inverted_fill_normal border border-information_inverted_stroke_normal_active text-natural_icon_normal',
          description: _(msg`تم التحديث`)
        }
    }
  }

  return (
    <div className="relative flex flex-col items-center w-full gap-8 md:gap-16">
      {steps.map((step, index) => {
        const { icon, style, description } = getStepStyles(step.description)

        return (
          <div key={index} className="flex gap-2.5 w-full">
            {index < steps.length - 1 && (
              <div
                className={`absolute top-0 right-[25px] h-[90%] w-[2px] bg-gray-300 min-h-14 mt-2 mb-2`}
              />
            )}
            <div
              className={`bg-white h-full z-10 ${index === steps.length - 1 ? 'pt-2 pb-10' : 'py-2 h-fit'}`}
            >
              <div
                className={`flex items-center justify-center rounded-full border-2 w-[50px] h-[50px] ${style}`}
              >
                {icon}
              </div>
            </div>

            <div className="flex flex-col gap-1 rounded-[10px] border border-natural_stroke_normal_disabled bg-natural_fill_normal_hover_pressed_active px-6 py-4 w-full">
              <p className="font-sub-heading-h6-ar text-ithra_purple">{description}</p>
              <p className="font-body-h6-ar text-natural_icon_normal">{timeAgo(step.created_at)}</p>

              <div className="flex items-center gap-2">
                <div>
                  <UserAvatar
                    gender={step.causer.gender}
                    account_type={step.causer.account_type}
                    isAvatar
                  />
                </div>
                <p className="font-body-h6-ar text-natural_icon_normal">{step.causer.name}</p>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default VerticalStepper
