import { msg } from '@lingui/macro'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { cleanObject } from 'utils/cleanObject'

export interface Account {
  id: number
  is_account_completed: boolean | null
  name: string
  first_name: string
  middle_name: string | null
  last_name: string | null
  email: string
  phone: string | null
  active: boolean
  verified: boolean
  country: Country | null
  account_type: string
  created_by: CreatedBy | null
  created_at: string
}

interface Country {
  id: number
  active: boolean
  name: {
    ar: string
    en: string
  }
  region_label: string | null
  code: string
  order: number
  reserved: number
  created_at: string
  updated_at: string
}

interface CreatedBy {
  id: number
  is_account_completed: boolean | null
  name: string
  first_name: string
  middle_name: string | null
  last_name: string | null
  email: string
  phone: string | null
  active: boolean
  verified: boolean
  country: Country | null
  account_type: string
  created_by: CreatedBy | null
  created_at: string
}

export interface AccountState {
  accounts: Account[] | []
  isLoading: boolean
  error: string | null
  search?: string
  added_by_admin?: number
  created_at_from?: string
  created_at_to?: string
  active?: number
  completed_after_migration?: number
  page: number
  per_page: number
  total_pages: number
  total_count: number
}

const initialState: AccountState = {
  accounts: [],
  isLoading: false,
  error: null,
  search: '',
  added_by_admin: undefined,
  created_at_from: undefined,
  created_at_to: undefined,
  active: undefined,
  completed_after_migration: undefined,
  page: 1,
  per_page: 10,
  total_pages: 0,
  total_count: 0,
}

export interface ISearchUser {
  search?: string
  added_by_admin?: number
  created_at_from?: string
  created_at_to?: string
  active?: number
  completed_after_migration?: number
  migrated_from_old_system?: boolean
  page?: number
  per_page?: number
  q?: string
  'countries[]'?: string[]
  'types[]'?: string[]
}

interface RejectedError {
  error?: string
}

export const fetchUsersAccounts = createAsyncThunk('usersAccounts/fetch', async (params: ISearchUser) => {
  const cleanedParams = cleanObject({
    ...params,
  })

  const response = await axiosInstance.get('/management/users', {
    params: { ...cleanedParams }
  })

  return response.data
})

// updating acc status
export const updateUserAccountStatus = createAsyncThunk(
  'usersAccounts/updateStatus',
  async ({ userId, active }: { userId: number; active: boolean }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/management/users/${userId}/update-status`, {
        active,
      })
      return { userId, data: response.data, status: response.status }
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const sendVerificationEmail = createAsyncThunk(
  'usersAccounts/sendVerificationEmail',
  async (userId: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/management/users/${userId}/send-verification-email`)
      return { userId, data: response.data, status: response.status }
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createUserAccount = createAsyncThunk(
  'usersAccounts/create',
  async ({ name, email, account_type }: { name: string; email: string; account_type: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/management/users', {
        name,
        email,
        account_type,
      })
      return { data: response.data, status: response.status }
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)

const usersAccountsSlice = createSlice({
  name: 'usersAccounts',
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
    setAddedByAdmin: (state, action: PayloadAction<number | undefined>) => {
      state.added_by_admin = action.payload
    },
    setCreatedAtFrom: (state, action: PayloadAction<string | undefined>) => {
      state.created_at_from = action.payload
    },
    setCreatedAtTo: (state, action: PayloadAction<string | undefined>) => {
      state.created_at_to = action.payload
    },
    setActive: (state, action: PayloadAction<number | undefined>) => {
      state.active = action.payload
    },
    setCompletedAfterMigration: (state, action: PayloadAction<number | undefined>) => {
      state.completed_after_migration = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setPerPage: (state, action: PayloadAction<number>) => {
      state.per_page = action.payload
    },
    resetFilters: (state) => {
      state.search = ''
      state.added_by_admin = undefined
      state.created_at_from = undefined
      state.created_at_to = undefined
      state.active = undefined
      state.completed_after_migration = undefined
      state.page = 1
      state.per_page = 10
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersAccounts.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchUsersAccounts.fulfilled, (state, action) => {
        state.isLoading = false
        state.accounts = action.payload.data
        state.total_pages = action.payload.meta.pagination.total_pages
        state.total_count = action.payload.meta.pagination.total
        state.page = action.payload.meta.pagination.current_page
        state.per_page = action.payload.meta.pagination.per_page
      })
      .addCase(fetchUsersAccounts.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      }) 
      .addCase(updateUserAccountStatus.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(updateUserAccountStatus.fulfilled, (state, action) => {
        state.isLoading = false
        const updatedUser = action.payload.data.data
        const userId = action.payload.userId

        state.accounts = state.accounts.map((account) =>
          account.id === userId ? { ...account, ...updatedUser } : account
        )
      })
      .addCase(updateUserAccountStatus.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
      .addCase(sendVerificationEmail.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(sendVerificationEmail.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(sendVerificationEmail.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
      .addCase(createUserAccount.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(createUserAccount.fulfilled, (state, action) => {
        state.isLoading = false
        state.accounts = [...state.accounts, action.payload.data.data]
      })
      .addCase(createUserAccount.rejected, (state, action) => {
        state.isLoading = false
        const errorPayload = action.payload as RejectedError
        state.error = errorPayload?.error ?? (msg`حدث خطأ أثناء إنشاء حساب المستخدم`.id)
      })
  },
})

export const {
  setSearch,
  setAddedByAdmin,
  setCreatedAtFrom,
  setCreatedAtTo,
  setActive,
  setCompletedAfterMigration,
  setPage,
  setPerPage,
  resetFilters
} = usersAccountsSlice.actions

export default usersAccountsSlice
