import { Trans } from '@lingui/macro'
import React from 'react'

export default function EmptyChart() {
  return (
    <div className="flex flex-row justify-center">
      <span className="text-center">
        <Trans>لا يوجد بيانات</Trans>
      </span>
    </div>
  )
}
