import { msg } from '@lingui/macro'
import { Trans, useLingui } from '@lingui/react'
import classNames from 'classnames'
import dayjs from 'dayjs'
import React from 'react'
import { DatePicker } from 'rsuite'
import 'rsuite/DatePicker/styles/index.css'
import './date-picker.css'
import getDatePickerLocale from './datePickerLocale'

interface DateRangePickerProps {
  label?: string
  placeholder?: string
  className?: string
  disabled?: boolean
  error?: string | null
  value: string | null
  onChange: (value: Date | null) => void
  labelClassName?: string
}

const DatePickerLocal: React.FC<DateRangePickerProps> = ({
  label,
  className,
  disabled,
  placeholder,
  onChange,
  error,
  value,
  labelClassName,
  ...other
}) => {
  const { _ } = useLingui()
  return (
    <div className="flex flex-col justify-start gap-2 date-picker-wrapper">
      {label && (
        <p
          className={`font-sub-heading-h6-ar text-natural_icon_normal capitalize ${labelClassName}`}
        >
          <Trans id={label} />
        </p>
      )}
      <DatePicker
        oneTap
        placement="topStart"
        format="yyyy-MM-dd"
        value={value ? dayjs(value).toDate() : null}
        appearance={'subtle'}
        onChange={onChange}
        placeholder={placeholder ?? _(msg`اختر التاريخ`)}
        locale={getDatePickerLocale()}
        disabled={disabled}
        className={classNames({
          'border rounded-lg w-full placeholder:font-normal text-natural_icon_normal': true,
          'bg-natural_fill_disabled border-natural_stroke_normal_disabled': Boolean(disabled),
          [`${className}`]: Boolean(className)
        })}
        {...other}
      />
      {error && (
        <p className="mx-2 text-danger_default_lv1_text_normal_active_hover_pressed font-label-sm-ar">
          <Trans id={error} />
        </p>
      )}
    </div>
  )
}

export default DatePickerLocal
