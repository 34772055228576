import React, { forwardRef, useState, useEffect, useImperativeHandle, useRef } from 'react'
import { TagPicker, SelectPicker, PickerHandle } from 'rsuite'
import 'rsuite/SelectPicker/styles/index.css'
import 'rsuite/TagPicker/styles/index.css'
import { Trans } from '@lingui/react'
import { i18n } from '@lingui/core'
import getSelectLocale from './selectLocale'
import './select-input.css'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import classNames from 'classnames'
import { msg } from '@lingui/macro'

interface SelectFieldProps {
  label?: string
  placeholder?: string
  options: { label: string; value: string }[]
  mode?: 'normal' | 'search'
  loading?: boolean
  disabled?: boolean
  defaultValue?: string | number | string[] | number[]
  value?: string | number | string[] | number[]
  helperText?: string
  error?: string | null
  onChange?: (value: string | number | string[] | number[] | null) => void
  selectionMode?: 'single' | 'multiple'
  invisible?: boolean
  allowNewOption?: boolean
  defaultOption?: { label: string; value: string }
  showDefaultOptionWhenNoResults?: boolean
  className?: string
}

const SelectField: React.FC<SelectFieldProps> = forwardRef<PickerHandle, SelectFieldProps>(
  (
    {
      label,
      placeholder = i18n._('اختر الخيار المناسب'),
      options,
      loading = false,
      disabled = false,
      defaultValue = null,
      helperText,
      error,
      value,
      onChange,
      selectionMode = 'single',
      mode = 'normal',
      invisible = false,
      allowNewOption = false,
      defaultOption = { label: i18n._(msg`الجامعة غير مدرجة في القائمة`), value: 'other' },
      showDefaultOptionWhenNoResults = false,
      className,
      ...other
    },
    ref
  ) => {
    const direction = useSelector((state: RootState) => state.layout.direction)
    const pickerRef = useRef<PickerHandle | null>(null)
    const [localOptions, setLocalOptions] = useState(options)
    const [open, setOpen] = useState(false)
    const [selectedValue, setSelectedValue] = useState<
      string | number | string[] | number[] | null
    >(defaultValue || null)
    const controlledValue = value ?? selectedValue
    const isRTL = direction === 'rtl'

    useImperativeHandle(ref, () => ({
      close: () => pickerRef.current?.close?.(),
      open: () => pickerRef.current?.open?.(),
      root: pickerRef.current?.root || null
    }))

    useEffect(() => {
      setLocalOptions(options)
    }, [options])

    useEffect(() => {
      const defaultOptionExists = localOptions.some(
        (option) => option.value === defaultOption.value
      )

      if (!defaultOptionExists && showDefaultOptionWhenNoResults && selectionMode === 'single') {
        setLocalOptions((prevOptions) => [...prevOptions, defaultOption])
      }
    }, [localOptions, showDefaultOptionWhenNoResults, selectionMode])

    const handleOpen = () => {
      if (pickerRef.current && pickerRef.current.root) {
        const comboboxElement = pickerRef.current.root.querySelector('[role="combobox"]')
        if (comboboxElement) {
          comboboxElement.removeAttribute('tabindex')
        }
      }
      setOpen(true)
    }

    const handleClose = () => {
      if (pickerRef.current && pickerRef.current.root) {
        const comboboxElement = pickerRef.current.root.querySelector('[role="combobox"]')
        if (comboboxElement) {
          comboboxElement.setAttribute('tabindex', '0')
        }
      }
      setOpen(false)
    }

    const handleSelect = (selected: any) => {
      setSelectedValue(selected)
      onChange?.(selected)
      if (selectionMode === 'single') {
        setOpen(false)
      }
    }

    const handleClear = () => {
      setSelectedValue(null)
      onChange?.(null)
    }

    const handleTagRemove = (valueToRemove: string) => {
      const newValue = (controlledValue as string[]).filter((value) => value !== valueToRemove)
      setSelectedValue(newValue)
      onChange?.(newValue)
    }

    const handleAddNewOption = (newOptionLabel: string, newOptionValue?: string) => {
      const newOption = { label: newOptionLabel, value: newOptionValue || newOptionLabel }
      if (!localOptions.some((option) => option.value === newOption.value)) {
        setLocalOptions((prevOptions) => [...prevOptions, newOption])
        handleSelect(newOption.value)
      }
    }

    const renderMenu = (menu: React.ReactNode) => {
      const hasNoResults =
        React.isValidElement(menu) &&
        menu.props &&
        (!menu.props.data || menu.props.data.length === 0)

      //const filteredOptions = localOptions.filter((option) => option.value !== defaultOption.value)

      return (
        <>
          <div style={{ maxHeight: '150px', overflowY: 'auto' }}>{!hasNoResults && menu}</div>

          {selectionMode === 'single' && showDefaultOptionWhenNoResults && (
            <div style={{ padding: '12px', borderTop: '1px solid #e5e5e5' }}>
              <button onClick={() => handleSelect(defaultOption.value)}>
                {defaultOption.label}
              </button>
            </div>
          )}

          {allowNewOption && (
            <div style={{ padding: '12px', borderTop: '1px solid #e5e5e5' }}>
              <button onClick={() => handleAddNewOption('New Option')}>Add new option</button>
            </div>
          )}

          {!showDefaultOptionWhenNoResults && !allowNewOption && hasNoResults && (
            <div style={{ padding: '12px' }} className="text-secondary_lv2_text_normal">
              {i18n._('لايوجد نتائج مماثلة')}
            </div>
          )}
        </>
      )
    }

    if (invisible) return null

    const sharedPickerProps = {
      data: localOptions,
      value: controlledValue,
      loading,
      disabled,
      defaultValue,
      virtualized: true,
      locale: getSelectLocale(),
      menuMaxHeight: mode === 'search' ? 120 : 70,
      onClean: handleClear,
      placeholder,
      menuStyle: {
        textAlign: isRTL ? ('right' as 'left' | 'right') : ('left' as 'left' | 'right')
      },
      className: classNames({
        'w-full text-natural_icon_normal': true,
        ' border-natural_stroke_normal_disabled': Boolean(disabled),
        [`${className}`]: Boolean(className)
      }),
      onOpen: handleOpen,
      onClose: handleClose,
      onSelect: handleSelect
    }

    return (
      <div className="flex flex-col gap-1">
        {label && (
          <p className="font-sub-heading-h6-ar text-natural_icon_normal capitalize">
            <Trans id={label} />
          </p>
        )}
        {selectionMode === 'multiple' ? (
          <TagPicker
            {...sharedPickerProps}
            open={open}
            ref={pickerRef}
            searchable={mode !== 'normal'}
            onSelect={handleSelect}
            onTagRemove={handleTagRemove}
            {...other}
          />
        ) : (
          <SelectPicker
            {...sharedPickerProps}
            open={open}
            ref={pickerRef}
            searchable={mode !== 'normal'}
            onSelect={handleSelect}
            renderMenu={renderMenu}
            {...other}
          />
        )}

        {error && (
          <p className="text-danger_default_lv1_text_normal_active_hover_pressed font-label-sm-ar">
            <Trans id={error} />
          </p>
        )}
        {helperText && !error && (
          <p className="text-primary_lv2_text_normal font-sub-heading-h6-ar">
            <Trans id={helperText} />
          </p>
        )}
      </div>
    )
  }
)

export default SelectField
