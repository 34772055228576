import React from 'react'
import { getColorByPercentage } from 'utils/charts'
import { getLocalizedName } from 'utils/getLocalizedName'
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'

type VerticalProgressProps = {
  data: { id: number; label: { ar: string; en: string }; total_applications: number; percentage: number }[]
  maxHeight?: string
  prefix?: string
}

export default function VerticalProgress({ data, maxHeight = 'h-60' }: VerticalProgressProps) {
  const { i18n, _ } = useLingui()

  return (
    <div className="flex items-end overflow-x-auto relative">
      {data.map((progress) => (
        <div key={progress.id} className="relative flex flex-col items-center mx-4"> {/* Added horizontal space using mx-4 */}
          <div className={`relative ${maxHeight} w-12 flex items-end`}>
            <div
              className={`w-full rounded-t-lg ${getColorByPercentage(progress.percentage)}`}
              style={{ height: `${progress.percentage}%` }}
            />
            <span
              className="absolute bottom-full mb-1 text-sm text-center text-wrap text-natural_lv1_text_normal"
              style={{ bottom: `calc(${progress.percentage}% + 8px)` }} 
            >
              {getLocalizedName(progress.label, i18n.locale as any)}
            </span>
          </div>
          <span className="mt-2 text-sm text-nowrap">
            {progress.total_applications} {_(msg`مشاركة`)}{' '}
          </span>
        </div>
      ))}
    </div>
  )
}
