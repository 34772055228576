import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { ArrowLeft } from 'assets/icons/ArrowLeft'
import ButtonVariant from 'components/button-variant/button'
import ModalHeader from 'components/modal/modal-header'
import ModalLayout from 'components/modal/modal-layout'
import Typography from 'components/typography/typography'

type ConfirmModuleProps = {
  onClose: () => void
  isSubmitting: boolean
  onConfirm: () => void
}

function ConfirmModule({ onClose, isSubmitting, onConfirm }: ConfirmModuleProps) {
  const { _ } = useLingui()
  return (
    <ModalLayout
      appStyle
      backdrop="opaque"
      size="sm"
      showBackButton={true}
      onClose={onClose}
      header={
        <ModalHeader
          subheaderText={
            <>
              <div className="flex flex-col gap-2">
                <Typography weight="bold" size="xl" align="center">
                  {_(msg`أنت على وشك أن تقوم بتأكيد مراجعتك ، هل أنت متأكد ؟`)}
                </Typography>
              </div>
            </>
          }
        />
      }
      footer={
        <div className="flex justify-center gap-4 flex-row items-center w-full">
          <ButtonVariant type="button" variant="secondary-outline" onClick={onClose}>
            {_(msg`رجوع`)}
          </ButtonVariant>
          <ButtonVariant
            type="button"
            variant="secondary"
            endContent={<ArrowLeft />}
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            onClick={onConfirm}
          >
            {_(msg`نعم متأكد`)}
          </ButtonVariant>
        </div>
      }
    />
  )
}

export default ConfirmModule
