import ChevronIcon from 'assets/icons/ChevronIcon'
import SectionTitle from 'components/base/section-title'
import { useHorizontalScroll } from 'hooks/useHorizontalScroll'
import { Question } from 'pages/landing/models'
import React, { useState } from 'react'
import faqData from './faq-data'
import FaqItem from './faq-item'
import { msg } from '@lingui/macro'
import { Trans } from '@lingui/react'
import Button from 'components/base/button'

const FaqSection: React.FC = () => {
  const { containerRef, scrollLeft, scrollRight, canScrollLeft, canScrollRight } =
    useHorizontalScroll()

  const [activeTab, setActiveTab] = useState<string>(faqData[0].category.id)
  const [activeQuestion, setActiveQuestion] = useState<number | null>(null)

  const toggleQuestion = (index: number) => {
    if (activeQuestion === index) {
      setActiveQuestion(null)
    } else {
      setActiveQuestion(index)
    }
  }

  return (
    <div className="w-full md:w-10/12 mx-auto py-10 px-3.5 md:px-0">
      <div className="">
        <SectionTitle
          title={msg`معلومات إضافية`}
          sectionDescription={msg`الشروط والأسئلة الشائعة`}
        />
      </div>
      <div className="flex flex-col p-7">
        <div className="relative border-b mb-4 ">
          <div
            ref={containerRef}
            className="bg-white flex flex-row *:mx-2.5 overflow-x-auto scrollbar-hide ml-20 md:ml-0 lg:w-full"
          >
            {faqData.map((tab) => (
              <button
                key={tab.category.id}
                className={`py-2 font-medium text-naturalLv1 text-base md:text-2xl w-full md:w-fit text-nowrap md:text-wrap ${
                  activeTab === tab.category.id ? 'border-b-4 border-customGreen' : ''
                }`}
                onClick={() => setActiveTab(tab.category.id)}
              >
                <Trans id={tab.category.id} />
              </button>
            ))}
            <div className="md:hidden absolute left-0 bottom-0 bg-white h-full px-4 flex items-center">
              <div className="mb-1">
                <Button
                  trailingIcon={true}
                  btnStyle="primary"
                  onClick={scrollLeft}
                  className={`w-11`}
                  isDisabled={!canScrollLeft}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col space-y-4">
          {faqData
            .find((tab) => tab.category.id === activeTab)
            ?.questions.map((faq: Question, index: number) => (
              <FaqItem
                key={index}
                faq={faq}
                isActive={activeQuestion === index}
                onToggle={() => toggleQuestion(index)}
              />
            ))}
        </div>
      </div>
    </div>
  )
}

export default FaqSection
