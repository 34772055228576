import React, { useEffect } from 'react'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import SelectInput from 'components/hook-form/select-field'
import TextInput from 'components/hook-form/text-input'
import { useEducationLevelsOptions } from '../hooks/useEducationLevelsOptions'
import { useEducationAdministrationOptions } from '../hooks/useEducationAdministrationOptions'
import { useSchoolOptions } from '../hooks/useSchoolOptions'
import { useUniversityOptions } from '../hooks/useUniversityOptions'
import { useFormContext } from 'react-hook-form'

const EducationInfoForm = () => {
  const { _ } = useLingui()

  const { watch, setValue } = useFormContext()

  const countryId = watch('location_info.country')
  const countryCode = watch('location_info.countryCode')
  const regionId = watch('location_info.region')
  const educationLevel = watch('education_info.education_level')
  const educationStage = watch('education_info.education_stage')
  const school = watch('education_info.school')
  const university = watch('education_info.university')

  const showEducationAdministration = educationStage === 'general' && (countryId === '425' || countryCode === 'SA')
  const showSchool = educationStage === 'general'
  const showUniversity = educationStage === 'university'
  const showSchoolUniversityName = school === 'other' || university === 'other'

  const { options: EducationLevelOptions, isLoading: EducationLevelOptionsLoading } = useEducationLevelsOptions({ countryId })
  const { options: EducationAdministrationOptions, isLoading: EducationAdministrationOptionsLoading } = useEducationAdministrationOptions({ countryId, regionId })
  const { options: schoolOptions, isLoading: SchoolOptionLoading } = useSchoolOptions({ countryId: countryId!, regionId: regionId! })
  const { options: universitiesOptions, isLoading: UniversityOptionsLoading } = useUniversityOptions({ countryId: countryId!, regionId: regionId! })

  const getLocalizedEducationStage = (stage: string) => {
    switch (stage) {
      case 'general':
        return _(msg`عام`)
      case 'university':
        return _(msg`جامعي`)
      default:
        return ''
    }
  }

  useEffect(() => {
    setValue('education_info.country', countryId ? countryId : null)
    setValue('education_info.countryCode', countryCode ? countryCode : '')

    if (educationLevel && EducationLevelOptions.length) {
      const selectedOption = EducationLevelOptions.find((option) => option.key === educationLevel)

      if (selectedOption && selectedOption.educationStage) {
        setValue('education_info.education_stage', selectedOption.educationStage)
      }
    }
  }, [educationLevel, EducationLevelOptions, setValue, countryId, countryCode])

  useEffect(() => {
    if (!showEducationAdministration) {
      setValue('education_info.education_administration', null)
    }
    if (!showSchool) {
      setValue('education_info.school', null)
    }
    if (!showUniversity) {
      setValue('education_info.university', null)
    }
    if (!showSchoolUniversityName) {
      setValue('education_info.school_university_name', '')
    }
  }, [showEducationAdministration, showSchool, showUniversity, showSchoolUniversityName, setValue])

  return (
    <>
      <TextInput
        name="education_info.education_stage"
        label={_(msg`مرحلة التعليم`)}
        placeholder={_(msg`اختر المستوى التعليمي`)}
        value={getLocalizedEducationStage(educationStage)}
        isDisabled
      />
      <SelectInput
        name="education_info.education_level"
        label={_(msg`المستوى التعليمي`)}
        selectionMode="single"
        isLoading={EducationLevelOptionsLoading}
        options={EducationLevelOptions}
        placeholder={_(msg`اختر المستوى التعليمي`)}
      />
      {showEducationAdministration && (
        <SelectInput
          name="education_info.education_administration"
          label={_(msg`الإدارة التعليمية`)}
          selectionMode="single"
          isLoading={EducationAdministrationOptionsLoading}
          options={EducationAdministrationOptions}
          placeholder={_(msg`اختر إدارة تعليمية`)}
          mode="search"
        />
      )}
      {showSchool && (
        <SelectInput
          name="education_info.school"
          label={_(msg`المدرسة`)}
          isLoading={SchoolOptionLoading}
          selectionMode="single"
          options={schoolOptions}
          placeholder={_(msg`اختر مدرسة`)}
          mode="search"
          showDefaultOptionWhenNoResults={true}
        />
      )}
      {showUniversity && (
        <SelectInput
          name="education_info.university"
          label={_(msg`الجامعة`)}
          isLoading={UniversityOptionsLoading}
          selectionMode="single"
          options={universitiesOptions}
          placeholder={_(msg`اختر جامعة`)}
          mode="search"
          showDefaultOptionWhenNoResults={true}
        />
      )}
      {showSchoolUniversityName && (
        <TextInput
          name="education_info.school_university_name"
          label={showSchool ? _(msg`اسم المدرسة`) : _(msg`اسم الجامعة`)}
          width="100%"
          placeholder={showSchool ? _(msg`اسم المدرسة`) : _(msg`اسم الجامعة`)}
        />
      )}
    </>
  )
}

export default EducationInfoForm
