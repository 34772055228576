import ChevronIcon from 'assets/icons/ChevronIcon'
import React from 'react'
import { MessageDescriptor } from '@lingui/core'
import { Trans } from '@lingui/react'

interface FaqItemProps {
  faq: {
    question: MessageDescriptor
    answer: MessageDescriptor[]
  }
  isActive: boolean
  onToggle: () => void
}

const FaqItem: React.FC<FaqItemProps> = ({ faq, isActive, onToggle }) => {
  return (
    <div
      className="bg-[#F3F8F9] rounded-md px-4 py-4 flex flex-col space-y-4 cursor-pointer transition-all ease-in-out duration-300"
      onClick={onToggle}
    >
      <div className="flex justify-between items-center">
        <h3 className="text-base md:text-xl font-bold text-naturalLv1">
          <Trans id={faq.question.id} />
        </h3>
        <div className={`transition-all ease-in-out duration-500 ${isActive ? '' : 'rotate-180'}`}>
          <ChevronIcon fill="rgba(82, 82, 91, 1)" width="24" height="24" />
        </div>
      </div>
      {isActive && (
        <>
          {faq.answer.length > 1 ? (
            <ul className="list-inside list-disc pl-5 text-naturalLv2 font-helveticaNeueArabic text-xs md:text-lg">
              {faq.answer.map((answer, index) => (
                <li key={index}>
                  <Trans id={answer?.id} />
                </li>
              ))}
            </ul>
          ) : (
            <p className="list-inside list-disc pl-5 text-naturalLv2 font-helveticaNeueArabic text-md md:text-lg">
              <Trans id={faq.answer[0]?.id} />
            </p>
          )}
        </>
      )}
    </div>
  )
}

export default FaqItem
