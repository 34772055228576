import React from 'react'
import { Tabs, Tab } from '@nextui-org/react'

interface TabItem {
  id: string
  label: string
}

interface TabSwitcherProps {
  tabs: TabItem[]
  onClick: (id: string) => void
  activeTab: string
}

const TabSwitcher: React.FC<TabSwitcherProps> = ({ tabs, onClick, activeTab }) => {
  return (
    <Tabs
      color={'primary'}
      radius="full"
      variant="bordered"
      onSelectionChange={(key) => onClick(key as string)}
      aria-label="Tab Switcher"
    >
      {tabs.map((tab) => (
        <Tab key={tab.id} title={tab.label}></Tab>
      ))}
    </Tabs>
  )
}

export default TabSwitcher
