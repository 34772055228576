import backgroundImage from 'assets/images/hero-bg.png'
import backgroundVideo from 'assets/videos/iread-vedio.mp4'
import readLogo from 'assets/icons/read.svg'
import Button from 'components/base/button'
import { msg } from '@lingui/macro'
import { useNavigate } from 'react-router-dom'

const Hero: React.FC = () => {
  const navigate = useNavigate()

  const handleSignUpClick = () => {
    navigate(`/register`)
  }

  return (
    <div className="relative w-full h-96 lg:h-screen mt-16 md:mt-0">
      <video
        className="absolute inset-0 w-full h-full object-cover"
        autoPlay
        loop
        muted
        playsInline
        poster={backgroundImage}
      >
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <div className="absolute inset-0 w-20 lg:w-[245px] mx-auto flex flex-col items-center justify-center text-white">
        <img src={readLogo} alt="Logo" className="mb-4 mx-auto " />
        <Button
          placeholder={msg`التسجيل متاح الآن`}
          btnStyle="primary"
          btnSize="larg"
          hoverEffect
          onClick={() => handleSignUpClick()}
        />
      </div>
    </div>
  )
}

export default Hero
