import React from 'react'
import Button from 'components/base/button'
import SectionTitle from 'components/base/section-title'
import PodcastCard from './podcast-card'
import { msg } from '@lingui/macro'
import podcastList from './podcast-list'
import { useNavigate } from 'react-router-dom'

const PodcastSection: React.FC = () => {
  const navigate = useNavigate()

  const handleBlogLinkClick = () => {
    navigate('/blog-podcast')
    window.scrollTo(0, 0)
  }
  return (
    <div className="border-b border-gray-300">
      <div className="py-12 flex flex-col space-y-7 w-full md:w-10/12 mx-auto px-3.5 md:px-0">
        <div className="flex justify-between">
          <SectionTitle title={msg`بودكاست`} sectionDescription={msg`حلقات بودكاست البرنامج`} />
          <div>
            <Button
              placeholder={msg`المزيد`}
              btnStyle="secondary"
              btnSize="Default"
              trailingIcon={true}
              onClick={handleBlogLinkClick}
            />
          </div>
        </div>
        <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row md:justify-between">
          {podcastList.slice(0, 3).map((podcast) => (
            <PodcastCard
              key={podcast.id}
              name={podcast.name}
              description={podcast.description}
              videoId={podcast.id}
              fallbackImg={podcast.bgImage}
              link={podcast.link}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default PodcastSection
