import { msg } from '@lingui/macro'
import AccountBoxIcon from 'assets/icons/AccountBoxIcon'
import BallPenIcon from 'assets/icons/BallPenIcon'
import FileListIcon from 'assets/icons/FileListIcon'
import Home2Icon from 'assets/icons/Home2Icon'
import PhoneIcon from 'assets/icons/PhoneIcon'
import ApplicationBodyForm from 'components/application-viewer/forms/ApplicationBodyForm'
import ContactInfoForm from 'components/application-viewer/forms/ContactInfoForm'
import EducationInfoForm from 'components/application-viewer/forms/EducationInfoForm'
import LocationInfoForm from 'components/application-viewer/forms/LocationInfoForm'
import PersonalInfoForm from 'components/application-viewer/forms/PersonalInfoForm'
import { Application } from 'store/slices/applicationsSlice'

export interface Step {
  roles: string[]
  title: string
  icon: JSX.Element
  component: (applicationData: Application) => JSX.Element
  fields: string[]
}

export const steps = (applicationData: Application): Step[] => [
  {
    roles: ['ithraa', 'admin', 'student', 'teacher', 'coordinator'],
    title: msg`المعلومات الشخصية`.id,
    icon: <AccountBoxIcon />,
    component: () => <PersonalInfoForm applicationData={applicationData} />,
    fields: [
      'personal_info.first_name',
      'personal_info.second_name',
      'personal_info.last_name',
      'personal_info.date_of_birth',
      'personal_info.age',
      'personal_info.gender',
      'personal_info.nationality'
    ]
  },
  {
    roles: ['ithraa', 'admin', 'student', 'teacher', 'coordinator'],
    title: msg`معلومات التواصل`.id,
    icon: <PhoneIcon />,
    component: () => <ContactInfoForm applicationData={applicationData} />,
    fields: ['contact_info.email', 'contact_info.phone']
  },
  {
    roles: ['ithraa', 'admin', 'student'],
    title: msg`معلومات العنوان`.id,
    icon: <Home2Icon />,
    component: () => <LocationInfoForm applicationData={applicationData} />,
    fields: ['location_info.country', 'location_info.region']
  },
  {
    roles: ['ithraa', 'admin', 'student', 'teacher', 'coordinator'],
    title: msg`معلومات التعليم`.id,
    icon: <BallPenIcon />,
    component: () => <EducationInfoForm applicationData={applicationData} />,
    fields: [
      'education_info.education_stage',
      'education_info.education_level',
      'education_info.education_administration',
      'education_info.school',
      'education_info.university'
    ]
  },
  {
    roles: ['ithraa', 'admin', 'student', 'teacher', 'coordinator'],
    title: msg`معلومات المشاركة`.id,
    icon: <FileListIcon />,
    component: () => <ApplicationBodyForm applicationData={applicationData} />,
    fields: [
      'application_body.reading_story',
      'application_body.book_title',
      'application_body.author_name',
      'application_body.reason_for_choice',
      'application_body.book_review',
      'application_body.teacher_code',
      'application_body.terms_agreed'
    ]
  }
]
