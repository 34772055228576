import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'

interface RestoreApplicationState {
  isLoading: boolean
  success: boolean | null
  error: string | null
}

const initialState: RestoreApplicationState = {
  isLoading: false,
  success: null,
  error: null
}

export const restoreApplication = createAsyncThunk(
  'application/restoreApplication',
  async (applicationId: number, thunkAPI) => {
    try {
      const response = await axiosInstance.put(`/students/applications/${applicationId}/restore`)
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const restoreApplicationSlice = createSlice({
  name: 'moveApplication',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(restoreApplication.pending, (state) => {
        state.isLoading = true
        state.success = null
        state.error = null
      })
      .addCase(restoreApplication.fulfilled, (state) => {
        state.isLoading = false
        state.success = true
      })
      .addCase(restoreApplication.rejected, (state, action) => {
        state.isLoading = false
        state.success = false
        state.error = action.payload as string
      })
  }
})

export default restoreApplicationSlice
