import { FC } from 'react'
import { Checkbox as NextUICheckbox } from '@nextui-org/react'
import { Trans, useLingui } from '@lingui/react'
import Link from 'components/base/link'

interface CheckboxFieldProps {
  label?: string
  labelWithLink?: {
    text: string
    linkText: string
    url?: string
    onClick?: () => void
  }
  error?: string | null
  checked: boolean
  isDisabled?: boolean
  onChange: () => void
  className?: string
  size?: 'sm' | 'md' | 'lg'
  color?: 'primary' | 'secondary' | 'success' | 'warning' | 'default' | 'danger' | undefined
}

const CheckboxField: FC<CheckboxFieldProps> = ({
  label,
  labelWithLink,
  checked,
  onChange,
  className,
  isDisabled = false,
  size = 'md',
  color = 'primary',
  error
}) => {
  const { i18n } = useLingui()
  return (
    <div className={`flex flex-col justify-start gap-2 ${className}`}>
      <NextUICheckbox
        size={size}
        color={color}
        isSelected={checked}
        onChange={onChange}
        isDisabled={isDisabled}
      >
        {labelWithLink ? (
          <div className={`inline-flex items-center gap-2`}>
            <span className="font-sub-heading-h6-ar text-natural_icon_normal">
              <Trans id={labelWithLink.text} />
            </span>
            <Link
              className="font-sub-heading-h6-ar text-natural_icon_normal mt-3"
              fontWeight="bold"
              paddingX={'px-0'}
              placeholder={i18n._(labelWithLink.linkText)}
              onClick={(e) => {
                e.preventDefault()
                if (labelWithLink.onClick) {
                  labelWithLink.onClick()
                }
              }}
            />
          </div>
        ) : (
          label
        )}
      </NextUICheckbox>
    </div>
  )
}

export default CheckboxField
