import React from 'react'

interface BallPenIconProps {
  fill?: string
}

const BallPenIcon: React.FC<BallPenIconProps> = ({ fill = '#A1A1AA' }) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3993 7.87202L11.928 7.40069L5.328 14.0007H2.5V11.172L10.042 3.62935L13.8133 7.40069C13.9383 7.5257 14.0085 7.69524 14.0085 7.87202C14.0085 8.0488 13.9383 8.21833 13.8133 8.34335L9.1 13.0574L8.15667 12.1147L12.3993 7.87202ZM10.9853 6.45802L10.042 5.51535L3.83333 11.724V12.6674H4.776L10.9853 6.45802ZM12.8707 1.74402L14.7567 3.62935C14.8816 3.75437 14.9519 3.92391 14.9519 4.10069C14.9519 4.27746 14.8816 4.447 14.7567 4.57202L13.8133 5.51535L10.9853 2.68669L11.928 1.74402C12.053 1.61904 12.2226 1.54883 12.3993 1.54883C12.5761 1.54883 12.7456 1.61904 12.8707 1.74402Z"
        fill={fill}
      />
    </svg>
  )
}

export default BallPenIcon