import React, { useState, useRef } from 'react'
import Stepper from 'components/stepper/Stepper'
import { steps } from './components/Steps'
import FormNavigationBtns from './components/FormNavigationBtns'
import FormLayout from './components/FormLayout'
import { formSchema } from './forms/schema'
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import useDefaultValues from 'pages/SubmitApplication/hooks/useDefaultValues'
import { AppDispatch } from 'store'
import { useDispatch } from 'react-redux'
import DraftConfirmModal from './modals/DraftConfirmModal'
import SubmitConfirmModal from './modals/SubmitConfirmModal'
import { submitApplicationAction } from './utils/submitApplicationAction'
import Alert from 'components/alert/alert'
import { useNavigate, useLocation } from 'react-router-dom'

const SubmitApplication: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const defaultValues = useDefaultValues()
  const navigate = useNavigate()
  const location = useLocation()

  const methods = useForm({ resolver: zodResolver(formSchema), defaultValues: defaultValues })

  const [currentStep, setCurrentStep] = useState(0)
  const [stepCompletion, setStepCompletion] = useState<{ isCompleted: boolean; errors: number }[]>(
    Array(steps.length).fill({ isCompleted: false, errors: 0 })
  )

  const isLastStep = currentStep === steps.length - 1
  const isFirstStep = currentStep === 0

  const [showDraftModal, setShowDraftModal] = useState(false)
  const [showSubmitModal, setShowSubmitModal] = useState(false)

  const stepCompletionRef = useRef(stepCompletion)

  const [showAlert, setShowAlert] = useState<{ type: 'success' | 'error'; message: string } | null>(
    null
  )

  const validateStep = async () => {
    const stepFields = steps[currentStep].fields

    if (!stepFields || stepFields.length === 0) {
      return false
    }

    const errors = methods.formState.errors

    // console.log("errors", errors)

    const isValid = await methods.trigger(stepFields as any)
    const updatedCompletion = [...stepCompletionRef.current]

    let errorsCount = 0

    stepFields.forEach((field) => {
      const fieldParts = field.split('.')
      let currentErrorLevel: any = methods.formState.errors

      for (const part of fieldParts) {
        if (currentErrorLevel && currentErrorLevel[part] && part !== 'school') {
          currentErrorLevel = currentErrorLevel[part]
        } else {
          currentErrorLevel = null
          break
        }
      }

      if (currentErrorLevel && 'message' in currentErrorLevel) {
        errorsCount += 1
      }
    })

    if (
      updatedCompletion[currentStep].isCompleted !== isValid ||
      updatedCompletion[currentStep].errors !== errorsCount
    ) {
      updatedCompletion[currentStep] = {
        isCompleted: isValid,
        errors: errorsCount
      }
      stepCompletionRef.current = updatedCompletion
    }
    return isValid
  }

  const handleNext = async () => {
    setShowAlert(null)
    await validateStep()
    if (!isLastStep) {
      setStepCompletion([...stepCompletionRef.current])
      setCurrentStep((prev) => prev + 1)
    }
    if (location.state?.message) {
      navigate(location.pathname, { replace: true, state: {} })
    }
  }

  const handlePrev = async () => {
    setShowAlert(null)
    await validateStep()
    if (!isFirstStep) {
      setStepCompletion([...stepCompletionRef.current])
      setCurrentStep((prev) => prev - 1)
    }
  }

  const handleSaveDraft = async (data: typeof defaultValues) => {
    // console.log(methods.getValues())
    // console.log(methods.formState.errors)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    const fieldsToValidate: Array<keyof typeof defaultValues | string> = [
      'personal_info.first_name',
      'personal_info.second_name',
      'personal_info.last_name',
      'personal_info.date_of_birth',
      'personal_info.age',
      'personal_info.gender',
      'personal_info.nationality',

      'contact_info.phone',
      'contact_info.email',

      'location_info.country',
      'location_info.region',

      'education_info.education_stage',
      'education_info.education_level',
      'education_info.education_administration',
      'education_info.school',
      'education_info.university',
      'education_info.school_university_name',
    ]
    const isValid = await methods.trigger(fieldsToValidate as any)
    // const isValid = await methods.trigger()
    if (!isValid) {
      return
    }
    setShowDraftModal(true)
  }

  const onSubmit = async (data: typeof defaultValues) => {
    // console.log(methods.getValues())
    // console.log(methods.formState.errors)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    const fieldsToValidate: Array<keyof typeof defaultValues | string> = [
      'personal_info.first_name',
      'personal_info.second_name',
      'personal_info.last_name',
      'personal_info.date_of_birth',
      'personal_info.age',
      'personal_info.gender',
      'personal_info.nationality',

      'contact_info.phone',
      'contact_info.email',

      'location_info.country',
      'location_info.region',

      'education_info.education_stage',
      'education_info.education_level',
      'education_info.education_administration',
      'education_info.school',
      'education_info.university',
      'education_info.school_university_name',

      'application_body.reading_story',
      'application_body.book_title',
      'application_body.author_name',
      'application_body.reason_for_choice',
      'application_body.book_review',
      'application_body.teacher_code',
      'application_body.terms_agreed'
    ]
    const isValid = await methods.trigger(fieldsToValidate as any)
    // const isValid = await methods.trigger()
    if (!isValid) {
      return
    }
    setShowSubmitModal(true)
  }

  const handleDraftConfirm = async () => {
    // @ts-ignore
    await submitApplicationAction(dispatch, methods.getValues(), 'save', navigate, setShowAlert)
    setShowDraftModal(false)
  }

  const handleDraftCancel = () => {
    setShowDraftModal(false)
  }

  const handleSubmitConfirm = async () => {
    // @ts-ignore
    await submitApplicationAction(dispatch, methods.getValues(), 'submit', navigate, setShowAlert)
    setShowSubmitModal(false)
  }

  const handleSubmitCancel = () => {
    setShowSubmitModal(false)
  }

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col h-full">
        <Stepper
          steps={steps}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          stepCompletion={stepCompletion}
        />

        {(showAlert || location.state?.message) && (
          <div className="pt-4">
            <Alert
              mainText={showAlert?.message || location.state?.message}
              sizeMainText="md"
              weightMainText="normal"
              onClose={() => setShowAlert(null)}
              type={showAlert?.type || 'success'}
            />
          </div>
        )}

        <div className="flex-1">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormLayout>{steps[currentStep].component}</FormLayout>
          </form>
        </div>

        <FormNavigationBtns
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
          onPrev={handlePrev}
          onNext={isLastStep ? () => onSubmit(methods.getValues()) : handleNext}
          onSaveDraft={() => handleSaveDraft(methods.getValues())}
        />

        {showDraftModal && (
          <DraftConfirmModal onConfirm={handleDraftConfirm} onCancel={handleDraftCancel} />
        )}

        {showSubmitModal && (
          <SubmitConfirmModal onConfirm={handleSubmitConfirm} onCancel={handleSubmitCancel} />
        )}
      </div>
    </FormProvider>
  )
}

export default SubmitApplication
