import React from 'react'
import PodcastCard from './podcast-card'
import podcastList from './podcast-list'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'

interface PodcastSectionProps {
  searchValue: string
}

const PodcastSection: React.FC<PodcastSectionProps> = ({ searchValue }) => {
  const filteredPodcasts = podcastList.filter((podcast) =>
    i18n._(podcast.name).toLowerCase().includes(searchValue.toLowerCase())
  )

  return (
    <div className="border-b border-gray-300">
      <div className="py-12 w-full md:w-10/12 mx-auto px-3.5 md:px-0">
        {filteredPodcasts.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredPodcasts.map((podcast) => (
              <PodcastCard
                key={podcast.id}
                name={podcast.name}
                description={podcast.description}
                videoId={podcast.id}
                fallbackImg={podcast.bgImage}
                link={podcast.link}
              />
            ))}
          </div>
        ) : (
          <div className="text-center py-8">
            <p className="text-gray-500 text-lg">
              <Trans>لا توجد نتائج مطابقة للبحث</Trans>
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default PodcastSection
