import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setStudentsNumberFrom } from 'store/slices/activateRequestsSlice'
import './NumberOfStudentsFromFilter.css'
import { Input } from '@nextui-org/react'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
const NumberOfStudentsFromFilter: React.FC = () => {
  const { _ } = useLingui()
  const dispatch = useDispatch()
  const { studentsNumberFrom } = useSelector((state: RootState) => state.activateRequests)
  const [numberFrom, setNumberFrom] = useState<number | null>(studentsNumberFrom)

  const handleNumberFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? parseInt(e.target.value, 10) : null
    setNumberFrom(value)
    // dispatch(setStudentsNumberFrom(value))
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(setStudentsNumberFrom(numberFrom))
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [dispatch, numberFrom])

  return (
    <div>
      <p className="text-primary pb-2">{_(msg`عدد طلاب الجهة التعليمية (من)`)}</p>
      <Input
        variant="bordered"
        color="primary"
        type="number"
        placeholder={_(msg`عدد طلاب الجهة التعليمية (من)`)}
        // value={studentsNumberFrom || ''}
        value={(numberFrom ?? '') as string}
        onChange={handleNumberFromChange}
      />
    </div>
  )
}

export default NumberOfStudentsFromFilter
