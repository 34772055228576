import backgroundImage from 'assets/images/Blog-Background.png'
import Breadcrumbs from '../bread-crumbs'
import { msg, Trans } from '@lingui/macro'

const PodcastHero: React.FC = () => {
  const breadcrumbs = [
    { name: msg`الصفحة الرئيسية`, href: '/' },
    { name: msg`مدونة أقرأ`, href: '/blog' },
    { name: msg`بودكاست`, href: '' }
  ]

  return (
    <div className="relative w-full h-96 lg:h-screen">
      <video
        className="absolute inset-0 w-full h-full object-cover"
        autoPlay
        loop
        muted
        playsInline
        poster={backgroundImage}
      ></video>
      <div className="absolute top-1/2 start-16 lg:w-[100%] text-white">
        <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-nowrap mb-5">
          <Trans>بودكاست</Trans>
        </h1>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </div>
    </div>
  )
}

export default PodcastHero
