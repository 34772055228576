import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import EarthIcon from 'assets/icons/EarthIcon'
import { RootState } from 'store'
import { useLanguage } from 'hooks/LanguageProvider'

interface LanguageButtonProps {
  fillColor: string
  textClass: string
}

const LanguageButton: React.FC<LanguageButtonProps> = ({ fillColor, textClass }) => {
  const { language, toggleLanguage } = useLanguage()

  return (
    <div
      className="flex items-center *:mx-0.5 px-3 py-2.5 cursor-pointer hover:bg-gray-100 hover:bg-opacity-50 rounded-full"
      onClick={toggleLanguage}
    >
      <EarthIcon fill={fillColor} />
      <p className={`${textClass} ml-2`}>
        <span className="block sm:hidden">{language === 'ar' ? 'E' : 'ع'}</span>
        <span className="hidden sm:block">{language === 'ar' ? 'English' : 'العربية'}</span>
      </p>
    </div>
  )
}

export default LanguageButton
