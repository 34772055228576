import React from 'react';
import ButtonVariant from 'components/button-variant/button';
import ArrowIcon from 'assets/icons/ArrowIcon';
import { Trans } from '@lingui/macro';

interface TourControlsProps {
  stepIndex: number;
  stepsLength: number;
  handleNext: () => void;
  handlePrev: () => void;
  handleSkip: () => void;
}

const TourControls: React.FC<TourControlsProps> = ({
  stepIndex,
  stepsLength,
  handleNext,
  handlePrev,
  handleSkip
}) => {
  return (
    <div className="absolute top-0 left-0 w-full z-50 tour-controls">
      <div className="bg-white rounded-3xl flex justify-between items-center h-[57px] md:h-[122px] mx-2 md:mx-[30px] mt-6 md:mt-10 p-3 md:p-6">
        <ButtonVariant
          variant="bordered"
          onClick={handlePrev}
          disabled={stepIndex === 0}
          iconStart={<ArrowIcon fill="currentColor" width="24" height="24" />}
          iconRotate
        >
          <p>
            <Trans>الخلف</Trans>
          </p>
        </ButtonVariant>

        <div className="flex items-center gap-2">
          <ButtonVariant variant="bordered" onClick={handleSkip}>
            <p>
              <Trans>تجاهل</Trans>
            </p>
          </ButtonVariant>

          <ButtonVariant
            variant="secondary"
            onClick={handleNext}
            iconEnd={<ArrowIcon fill="currentColor" width="24" height="24" />}
            iconRotate
          >
            {stepIndex === stepsLength - 1 ? (
              <p>
                <Trans>الانتهاء</Trans>
              </p>
            ) : (
              <p>
                <Trans>التالي</Trans>
              </p>
            )}
          </ButtonVariant>
        </div>
      </div>
    </div>
  );
};

export default TourControls;
