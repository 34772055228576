import { i18n } from '@lingui/core'

const getSelectLocale = () => ({
  noResultsText: i18n._('لايوجد نتائج مماثلة'),
  placeholder: i18n._('اختر الخيار'),
  searchPlaceholder: i18n._('ابحث من هنا'),
  loading: i18n._('تحميل'),
  emptyMessage: i18n._('رسالة فارغة'),
  checkAll: i18n._('اختيار الكل')
})

export default getSelectLocale
