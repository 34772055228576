import { FC } from 'react'
import { Divider } from '@nextui-org/react'
import CheckMarkIcon from 'assets/icons/CheckMarkIcon'
import EditIcon from 'assets/icons/EditIcon'
import Typography from 'components/typography/typography'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

type HistoryActionProps = {
  event: 'created' | 'updated'
  description: string
  date: string
  time: string
  causerName: string
  showDivider: boolean
}

export const HistoryAction: FC<HistoryActionProps> = ({
  event,
  description,
  date,
  time,
  causerName,
  showDivider
}) => {
  const direction = useSelector((state: RootState) => state.layout.direction)
  const textAlign = direction === 'rtl' ? 'right' : 'left'

  return (
    <div className="relative flex gap-4 items-start">
      {/* Divider Line */}
      {showDivider && (
        <div
          className={`absolute ${direction === 'rtl' ? 'right-[29px]' : 'left-[29px]'} top-0 bottom-0 h-full`}
        >
          <Divider orientation="vertical" className="h-full" />
        </div>
      )}

      {/* Status Icon */}
      <div className="relative z-10 w-12 h-12 rounded-full bg-white flex items-center justify-center">
        {event === 'created' ? (
          <div className="w-12 h-12 text-natural_icon_normal border bg-secondary_fill_normal_pressed bg-opacity-30 border-secondary_fill_normal_pressed rounded-full flex items-center justify-center">
            <CheckMarkIcon size="22" />
          </div>
        ) : (
          <div className="w-12 h-12 font-bold border border-danger text-natural_icon_normal bg-danger_inverted_stroke_normal rounded-full flex items-center justify-center">
            <EditIcon size="22" />
          </div>
        )}
      </div>

      {/* Activity Content */}
      <div className="ml-12 !p-4 rounded-lg flex flex-col gap-2 bg-gray-50 border border-gray-200 w-full">
        <Typography color="secondary" size="md" weight="semibold" align={textAlign}>
          {description}
        </Typography>
        <Typography size="xs" weight="bold" align={textAlign}>
          {date} - {time}
        </Typography>
        <div className={`flex flex-row gap-2 items-center mt-2 `}>
          <div className="w-5 h-5 rounded-full bg-primary_fill_soft_overlay" />
          <Typography color="primary" weight="bold" size="xs">
            {causerName}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default HistoryAction
