import React, { ReactNode } from 'react'

interface MetricCardprops {
  children: ReactNode
  color?: string
  className?: string
}

const MetricCard: React.FC<MetricCardprops> = ({ children, color = 'bg-natural_lv1_text_normal', className }) => {

  return (
    <div className={`relative p-7 h-[185px] w-[185px] md:w-fit flex flex-col justify-center rounded-xl bg-natural_fill_normal_hover_pressed_active items-center ${className}`}>
      <div className='absolute top-0 left-0 rounded-xl w-full h-full overflow-hidden'><div className={`h-[5.5px] ${color}`}/></div>
      {children}
    </div>
  )
}

export default MetricCard
