import React from 'react'

interface props {
    label: string
  }

const FilterLabel: React.FC<props>  = ({ label }) => {
  return (
    <p className="text-primary pb-2">{label}</p>
  )
}

export default FilterLabel