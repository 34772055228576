interface Coordinator {
  access_all_regions: boolean
  access_all_education_administrations: boolean
  access_all_education_stages: boolean
}
export const getCoordinatorLevel = (coordinator: Coordinator): number =>
  coordinator.access_all_regions
    ? 1
    : coordinator.access_all_education_administrations
      ? 3
      : coordinator.access_all_education_stages
        ? 2
        : 0
