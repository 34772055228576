import { useEffect, useState } from 'react'
import { verifyEmailHash } from 'store/slices/verifyEmailHashSlice'
import { setToken, setUserType, setEmail, setVerified } from 'store/slices/authSlice'
import { useNavigate, useLocation } from 'react-router-dom'
import { AppDispatch } from 'store'
import { useDispatch } from 'react-redux'
import { msg } from '@lingui/macro'
import { i18n } from '@lingui/core'
import useDocumentTitle from 'hooks/useDocumentTitle'
import { toast } from 'react-toastify'
import { fetchMe } from 'store/slices/meSlice'

const VerifyEmail = () => {
  useDocumentTitle(msg`التحقق من البريد الإلكتروني`)
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const location = useLocation()

  const [name, setName] = useState<string | null>('')

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const hash = queryParams.get('hash')
    const id = queryParams.get('id')
    setName(queryParams.get('name'))

    const handleSuccess = (result: any) => {
      dispatch(setToken(result.data.accessToken))
      dispatch(setVerified(result.data.user.verified))
      dispatch(setUserType(result.data.user.account_type))
      dispatch(setEmail(result.data.user.email))

      navigate('/app/home')
      if (name) {
        const message = i18n._(msg`مرحبا`) + ' ' + name + i18n._(msg`، تم تفعيل حسابك بنجاح`)
        toast.success(message)
      }
    }

    if (hash && id) {
      const verify = async () => {
        try {
          const result = await dispatch(
            verifyEmailHash({
              id,
              hash
            })
          ).unwrap()

          if (result.status === 'success') {
            await dispatch(fetchMe())
            handleSuccess(result)
          }
        } catch (error: any) {
          if (error.status === 'error') {
            const errorMessage =
              i18n._(error.message) || i18n._(msg`فشل التحقق من البريد الإلكتروني!`)
            toast.error(errorMessage)
          }
        }
      }

      verify()
    }
  }, [location.search, dispatch, navigate, name])

  return null
}

export default VerifyEmail
