import { z } from 'zod'
import { t } from '@lingui/macro'

export const createExportFormSchema = (competitionStartDate: Date, competitionEndDate: Date) => {
  return z
    .object({
      reportType: z.string().nonempty(t`يرجى تحديد نوع التقرير`),
      durationType: z.string().nonempty(t`يرجى تحديد الفترة الزمنية`),
      beginDate: z
        .string()
        .nullable()
        .optional()
        .refine((value) => !value || !isNaN(Date.parse(value)), {
          message: t`يرجى إدخال تاريخ بداية صحيح`
        })
        .transform((value) => (value ? new Date(value) : null))
        .refine(
          (date) => {
            if (!date) return true
            return date >= competitionStartDate && date <= competitionEndDate
          },
          {
            message: t`تاريخ البداية يجب أن يكون بين ${competitionStartDate.toLocaleDateString()} و ${competitionEndDate.toLocaleDateString()}`
          }
        ),
      endDate: z
        .string()
        .nullable()
        .optional()
        .refine((value) => !value || !isNaN(Date.parse(value)), {
          message: t`يرجى إدخال تاريخ نهاية صحيح`
        })
        .transform((value) => (value ? new Date(value) : null))
        .refine(
          (date) => {
            if (!date) return true
            return date >= competitionStartDate && date <= competitionEndDate
          },
          {
            message: t`تاريخ النهاية يجب أن يكون بين ${competitionStartDate.toLocaleDateString()} و ${competitionEndDate.toLocaleDateString()}`
          }
        ),
      pdf: z.boolean().optional(),
      csv: z.boolean().optional()
    })
    .superRefine((data, ctx) => {
      const { beginDate, endDate, durationType, csv, pdf } = data

      if (durationType === 'costum' && !beginDate) {
        ctx.addIssue({
          path: ['beginDate'],
          code: z.ZodIssueCode.custom,
          message: t`يرجى تحديد تاريخ بداية صحيح`
        })
      }

      if (beginDate && endDate && beginDate > endDate) {
        ctx.addIssue({
          path: ['beginDate'],
          code: z.ZodIssueCode.custom,
          message: t`تاريخ البداية يجب أن يكون قبل تاريخ النهاية`
        })
      }

      if (durationType === 'costum' && !endDate) {
        ctx.addIssue({
          path: ['endDate'],
          code: z.ZodIssueCode.custom,
          message: t`يرجى تحديد تاريخ نهاية صحيح`
        })
      }

      if (endDate && beginDate && endDate < beginDate) {
        ctx.addIssue({
          path: ['endDate'],
          code: z.ZodIssueCode.custom,
          message: t`تاريخ النهاية يجب أن يكون بعد تاريخ البداية`
        })
      }

      if (!pdf && !csv) {
        ctx.addIssue({
          path: ['pdf'],
          code: z.ZodIssueCode.custom,
          message: t`يرجى تحديد نوع الملف (PDF أو CSV)`
        })
      }
    })
}
