import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface BreadcrumbState {
  customLastLabel: string | null
}

const initialState: BreadcrumbState = {
  customLastLabel: null, 
}

const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    setBreadcrumbLabel: (state, action: PayloadAction<string>) => {
      state.customLastLabel = action.payload;
    },
    clearBreadcrumbLabel: (state) => {
      state.customLastLabel = null
    },
  },
})

export const { setBreadcrumbLabel, clearBreadcrumbLabel } = breadcrumbSlice.actions

export default breadcrumbSlice;
