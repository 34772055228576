export const chartsColor = {
  success: 'bg-success_default_fill_normal_hover_pressed_active',
  warning: 'bg-[#245257]',
  info: 'bg-information_default_fill_normal',
  danger: 'bg-danger_default_fill_normal_active'
}

export const getColorByPercentage = (p: number) => {
  let percentage = Number(p)
  switch (true) {
    case percentage >= 70:
      return chartsColor.success
    case percentage >= 40 && percentage < 70:
      return chartsColor.info
    case percentage >= 20 && percentage < 40:
      return chartsColor.warning
    case percentage < 20:
      return chartsColor.danger
    default:
      return chartsColor.danger
  }
}
export const contentTypes = [
  'graph',
  'progress',
  'doughnut',
  'regions',
  'administration',
  'top-teachers',
  'top-coordinators',
  'doughnut_progress',
  'progress-list',
  'stagesApplications',
  'administrativesApplications',
  'topEducationAdministrations',
  'topTeachers',
  'femaleApplications',
  'maleApplications',
  'draftApplications',
  'submittedApplications',
  'countryApplicationsCount',
  'countryApplicationsPercentage',
  'otherCountriesApplicationsCount',
  'otherCountriesApplicationsPercentage',
  'topCountries',
  'countryRegionalApplications',
  'genderAdministrativesApplications',
  'regionalStagesApplications',
  'topCoordinators',
  'reachPercentage',
  'topUniversities',
  'universitiesApplications',
  'genderCountriesApplications',
  'countriesApplications',
  'countriesApplicationsStatus',
  'countryStagesApplications',
  'countryTopCoordinators',
  'countryTopTeachers'
] as const

export const cardTitleMap: Record<string, string> = {
  graph: 'الرسم البياني',
  progress: 'التقدم',
  doughnut: 'الدونات',
  regions: 'المناطق',
  administration: 'الإدارات',
  'top-teachers': 'أعلى المعلمين',
  'top-coordinators': 'أعلى المنسقين',
  doughnut_progress: 'دونات التقدم',
  'progress-list': 'قائمة التقدم',
  stagesApplications: 'طلبات المراحل التعليمية',
  administrativesApplications: 'طلبات الإدارات',
  topEducationAdministrations: 'أعلى الإدارات التعليمية',
  topTeachers: 'أعلى المعلمين',
  femaleApplications: 'طلبات الإناث',
  maleApplications: 'طلبات الذكور',
  draftApplications: 'طلبات المسودة',
  submittedApplications: 'طلبات المقدمة',
  countryApplicationsCount: 'عدد الطلبات حسب الدولة',
  countryApplicationsPercentage: 'نسبة الطلبات حسب الدولة',
  otherCountriesApplicationsCount: 'عدد الطلبات من الدول الأخرى',
  otherCountriesApplicationsPercentage: 'نسبة الطلبات من الدول الأخرى',
  topCountries: 'أعلى الدول',
  countryRegionalApplications: 'طلبات الدولة حسب المناطق',
  genderAdministrativesApplications: 'طلبات الإدارات حسب الجنس',
  regionalStagesApplications: 'طلبات المراحل التعليمية حسب المناطق',
  topCoordinators: 'أعلى المنسقين',
  reachPercentage: 'نسبة الوصول',
  topUniversities: 'أعلى الجامعات'
}
