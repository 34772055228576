import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Spinner } from '@nextui-org/react'
import { CompetitionStatus } from 'api/types'
import TabsNavigator from 'components/tabs-navigator/tabs-navigator'
import CompetitionWorkFlows from 'pages/CompetitionWorkFlows'
import { FC, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import { fetchCompetitionYearDetails } from 'store/slices/competitionYearDetails'
import HistoryChanges from './HistoryChanges'
import CompetitionSettings from './Settings'
import YearInformation from './YearInformation'

export const CompetitionDetails: FC = () => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch<AppDispatch>()
  const { _ } = useLingui()

  const { competitionYearDetails, isLoading } = useSelector(
    (state: RootState) => state.competitionYearDetails
  )
  const statusRef = useRef<CompetitionStatus>()

  useEffect(() => {
    if (id) {
      dispatch(fetchCompetitionYearDetails({ id }))
    }
  }, [dispatch, id])

  useEffect(() => {
    if (!statusRef.current && competitionYearDetails)
      statusRef.current = competitionYearDetails.status
  }, [competitionYearDetails])

  const tabItems = [
    {
      key: 'workflows',
      title: _(msg`سير المشاركات`),
      isLoading,
      content: (
        <div className="mt-5">
          <CompetitionWorkFlows yearId={competitionYearDetails?.id} />
        </div>
      )
    },
    {
      key: 'year-information',
      title: _(msg`معلومات السنة`),
      isLoading,
      content: (
        <YearInformation
          id={id}
          status={statusRef as any}
          competitionYear={competitionYearDetails!}
        />
      )
    },
    {
      key: 'settings',
      title: _(msg`الاعدادات`),
      isLoading,
      content: (
        <CompetitionSettings status={statusRef as any} competitionYear={competitionYearDetails!} />
      )
    },
    {
      key: 'history',
      title: _(msg`سجل التغييرات`),
      isLoading,

      content: <HistoryChanges activities={competitionYearDetails?.activities!} />
    }
  ]

  return (
    <div className="flex w-full flex-col">
      <TabsNavigator variant="underlined" color="primary" items={tabItems} />
    </div>
  )
}

export default CompetitionDetails
