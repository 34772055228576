import { useEffect, useState } from 'react'
import { AppDispatch, RootState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { fetchActivateRequestDetail } from 'store/slices/activateRequestDetailSlice'
import {
  AccessRequestSchemaType,
  ActivitySchemaType,
  CoordinatorInfoSchemaType,
  ReviewRequestSchemaType
} from './ReviewRequestSchemaType2'
import ReviewRequestTabs from './review-requests-tabs'
import { Spinner } from '@nextui-org/react'
import { Trans } from '@lingui/macro'

export default function ReviewRequest() {
  const dispatch = useDispatch<AppDispatch>()
  const { activateRequestDetail, error, isLoading } = useSelector(
    (state: RootState) => state.activateRequestDetail
  )
  const { id } = useParams()

  const [reviewRequestValues, setReviewRequestValues] = useState<
    ReviewRequestSchemaType | undefined
  >(undefined)
  const [reviewAccessValues, setAccessValues] = useState<AccessRequestSchemaType | undefined>(
    undefined
  )
  const [activitiesList, setActivityList] = useState<ActivitySchemaType[] | undefined>([])

  const [coordinatorInfo, setCoordinatorInfo] = useState<CoordinatorInfoSchemaType | undefined>(
    undefined
  )

  useEffect(() => {
    if (id) {
      dispatch(fetchActivateRequestDetail({ id }))
        .unwrap()
        .then((response) => {
          if (response.status === 'success') {
            const data = response?.data

            if (data) {
              setAccessValues({
                all_regions: data?.access?.all_regions || false,
                regions:
                  data?.access?.regions && data?.access?.regions.length > 0
                    ? data?.access?.regions.map((region) => String(region.id))
                    : [],
                education_stage:
                  data?.access?.education_stages && data?.access?.education_stages.length !== 2
                    ? data?.access?.education_stages[0]
                    : data.education_stages && data.education_stages.length !== 2
                      ? data.education_stages[0]
                      : undefined,
                education_administrations:
                  data?.access?.education_administrations &&
                  data?.access?.education_administrations.length > 0
                    ? data?.access?.education_administrations.map((region) => String(region.id))
                    : [],
                all_education_stages: data?.access?.all_education_stages || false,
                all_education_administrations: data?.access?.all_education_administrations || false,
                universities: data?.access?.universities || false,
                university:
                  data?.access?.universities.length > 0
                    ? String(data?.access?.universities[0].id)
                    : undefined,
                gender_of_education: data?.access?.gender_of_education ?? undefined,
                number_of_students:
                  data?.access?.number_of_students ?? (data.number_of_students || undefined)
              })
              setActivityList(data.activites || [])
              setCoordinatorInfo({
                name: data.name,
                country: {
                  id: data.country?.id,
                  name: { ar: data.country?.name.ar, en: data.country?.name.en }
                },
                phone: data.phone,
                email: data.email,
                nationality: {
                  id: data.nationality?.id,
                  name: { ar: data.nationality?.name?.ar, en: data.nationality?.name?.en }
                },
                gender: data.gender
              })
              setReviewRequestValues({
                country_id: data?.country_id,
                country_code: data?.country_code,
                region_label: data?.country?.region_label || undefined,
                regions:
                  data?.regions && data?.regions.length > 0
                    ? data.regions.map((region) => String(region.id))
                    : [],
                education_stages:
                  data.education_stages && data.education_stages.length !== 2
                    ? data.education_stages[0]
                    : undefined,
                education_stage:
                  data.education_stages && data.education_stages.length !== 2
                    ? data.education_stages[0]
                    : undefined,
                education_administrations:
                  data?.education_administrations && data?.education_administrations.length > 0
                    ? data.education_administrations.map((region) => String(region.id))
                    : [],
                all_regions: data?.all_regions || false,
                all_education_stages: data?.all_education_stages || false,
                all_education_administrations: data?.all_education_administrations || false,
                university: data.university_id
                  ? String(data.university_id)
                  : data.school_university_name
                    ? 'other'
                    : undefined,
                gender_of_education: data.gender_of_education ?? undefined,
                number_of_students: data.number_of_students ?? undefined,
                comment: data.comment ?? undefined,
                school_university_name: data.school_university_name ?? undefined,
                proof_document: data.proof_document ?? undefined,
                result:
                  data.status === 'rejected'
                    ? 'reject'
                    : data.status === 'approved'
                      ? 'approve'
                      : undefined,
                rejection_reason: data.rejection_reason ?? undefined,
                status: data.status
              })
            }
          } else {
            setReviewRequestValues(undefined)
            toast.error('Failed to fetch the data')
          }
        })
        .catch((error) => {
          const message = error.message ?? 'Error fetching request details'
          toast.error(message)
        })
    }
  }, [dispatch, id])

  return (
    <>
      {isLoading && (
        <div>
          <>
            <Spinner /> <Trans>يرجى الإنتظار</Trans> ...
          </>
        </div>
      )}
      {error && (
        <div>
          {' '}
          <Trans>حدثت مشكلة أثناء تحميل الصفحة </Trans> : {error}
        </div>
      )}
      {!isLoading && reviewRequestValues && (
        <ReviewRequestTabs
          reviewRequestValues={reviewRequestValues}
          reviewAccessValues={reviewAccessValues}
          coordinatorInfo={coordinatorInfo}
          activitiesList={activitiesList}
        />
      )}
    </>
  )
}
