import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setResponseDate } from 'store/slices/activateRequestsSlice'
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'
import DatePicker from 'components/date-picker-localized/date-picker'

const ResponseDateFilter: React.FC = () => {
  const { _ } = useLingui()
  const dispatch = useDispatch()
  const { fromResponseDate, toResponseDate } = useSelector(
    (state: RootState) => state.activateRequests
  )

  const handleResponseDateChangeFrom = (date: Date | null) => {
    const fromDate = date
    dispatch(setResponseDate({ from: fromDate, to: toResponseDate } as any))
  }

  const handleResponseDateChangeTo = (date: Date | null) => {
    const toDate = date
    dispatch(setResponseDate({ to: toDate, from: fromResponseDate } as any))
  }
 

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-0.5">
        <p className="text-primary pb-2">{_(msg`تاريخ مراجعة الطلب (من)`)}</p>
        <DatePicker
          value={(fromResponseDate ? fromResponseDate : null) as any}
          onChange={handleResponseDateChangeFrom as any}
        />
      </div>

      <div className="flex flex-col gap-0.5">
        <p className="text-primary pb-2">{_(msg`تاريخ مراجعة الطلب (إلى)`)}</p>
        <DatePicker
       // onClean={handleResponseDateChangeTo}
          value={(toResponseDate ? toResponseDate : null) as any}
          onChange={handleResponseDateChangeTo as any}
        />
      </div>
    </div>
  )
}

export default ResponseDateFilter
