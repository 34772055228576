import React from 'react'
import AwardsSection from 'components/card/AwardsSection'
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import CardsSection from 'pages/CoordinatorPages/components/CardsSection'
import AwardsProgress from 'pages/CoordinatorPages/components/AwardsProgress';


const Points = () => {
  const { awards } = useSelector((state: RootState) => state.coordinatorStats)

  return (
    <div className="w-full flex flex-col gap-6">

      <CardsSection />
      <AwardsSection awardsList={awards} />
      <AwardsProgress />
      
    </div>
  )
}

export default Points
