import { msg, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Modal, ModalBody, ModalContent, ModalHeader } from '@nextui-org/react'
import { CompetitionYear } from 'api/types'
import ButtonVariant from 'components/button-variant/button'
import Form from 'components/FormStructure/Form'
import DateInput from 'components/hook-form/date-picker-input'
import TextInput from 'components/hook-form/text-input'
import TextareaInput from 'components/hook-form/textarea-input'
import dayjs from 'dayjs'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { AppDispatch, RootState } from 'store'
import { createWorkflow } from 'store/slices/createWorkflowSlice'
import { fetchWorkflows, workflowErrors } from 'store/slices/workFlowSlice'
import { z } from 'zod'

type CreateWorkflowType = {
  competition: CompetitionYear
  isOpen: boolean
  onOpenChange: () => void
}

export const schema = z.object({
  name: z.string().nonempty({ message: t`الاسم مطلوب` }),
  description: z.string().nonempty({ message: t`وصف سير المشاركة مطلوب` }),
  end_date: z
    .string()
    .nonempty({ message: t`تاريخ الانتهاء مطلوب` })
    .refine((date) => dayjs(date).isValid(), { message: t`تاريخ الانتهاء غير صالح` })
})

type CreateWorkflowSchemaType = z.infer<typeof schema>

export const CreateWorkflow: FC<CreateWorkflowType> = (props) => {
  const { isOpen, onOpenChange, competition } = props
  const isLoading = useSelector((state: RootState) => state.createWorkflow.isLoading)
  const dispatch = useDispatch<AppDispatch>()
  const { _ } = useLingui()

  const onSubmit = async (data: CreateWorkflowSchemaType) => {
    try {
      await dispatch(
        createWorkflow({
          competitionId: competition.id,
          name: data.name,
          status: 'active',
          description: data.description,
          end_date: data.end_date
        })
      ).unwrap()
      toast.success(_(msg`تم اضافة سير المشاركة`))
      await dispatch(fetchWorkflows({ competitionId: competition.id })).unwrap()
      onOpenChange()
    } catch (error: any) {
      if (error.status === 'error') {
        const message = error.message ?? ''
        toast.dismiss()
        toast.error(_(workflowErrors[message]))
      }
    }
  }

  if (!props.competition) return null
  return (
    <Modal
      size="lg"
      placement="center"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      isDismissable={false}
      isKeyboardDismissDisabled={true}
    >
      <ModalContent className="min-h-[60vh] pt-6">
        <ModalHeader className="flex flex-col items-center gap-1 mb-2">
          <p className="font-bold text-2xl">{_(msg`اضافة سير المشاركة`)}</p>
          <p className="font-normal text-sm">
            {_(msg`يرجى إدخال المعلومات المطلوبة للمشاركة لاضافتها`)}
          </p>
        </ModalHeader>
        <ModalBody>
          <Form
            className="!gap-3"
            schema={schema}
            onSubmit={onSubmit}
            defaultValues={{ year: competition.name, name: '', description: '', end_date: '' }}
          >
            <TextInput
              isDisabled
              className="w-full rounded-lg"
              name="year"
              size="lg"
              label={_(msg`سنة المسابقة`)}
              placeholder={_(msg`سنة المسابقة`)}
            />

            <TextInput
              className="w-full rounded-lg"
              name="name"
              size="lg"
              label={_(msg`الاسم`)}
              placeholder={_(msg`الاسم`)}
            />
            <DateInput
              labelClassName=" text-natural_icon_normal font-sub-heading-h6-ar"
              className="!h-auto rounded-lg bg-primary_fill_soft_overlay"
              size="lg"
              name="end_date"
              label={_(msg`تاريخ الانتهاء`)}
            />
            <TextareaInput
              size="lg"
              minRows={5}
              name="description"
              label={_(msg`الوصف`)}
              className="w-full rounded-lg"
              placeholder={_(msg`الوصف`)}
            />

            <div className="flex w-full mt-4 justify-center">
              <ButtonVariant
                type="submit"
                size="lg"
                variant="secondary"
                disabled={isLoading}
                isLoading={isLoading}
              >
                {_(msg`اضافة`)}
              </ButtonVariant>
            </div>
          </Form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
