import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import StarIcon from 'assets/icons/StarIcon'
import useLanguage from 'hooks/useLanguage'
import React from 'react'

interface Props {
  points?: number
}

const Ribbon: React.FC<Props> = ({ points = 0 }) => {
  const { _ } = useLingui()
  const lang = useLanguage()

  const getPointLabel = () => {
    if (lang === 'en') return points < 2 ? _(msg`نقطة`) : _(msg`نقاط`)
    return points < 3 ? _(msg`نقطة`) : points > 10 ? _(msg`نقطة`) : _(msg`نقاط`)
  }

  return (
    <div className="flex items-center gap-2 py-1.5 px-3 rounded-s-lg bg-natural_lv1_text_normal">
      <StarIcon />
      <p className="font-body-h6 text-natural_fill_normal_hover_pressed_active">
        {`${points} `}
        <span className="hidden md:inline">{getPointLabel()}</span>
      </p>
    </div>
  )
}

export default Ribbon
