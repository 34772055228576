import React from 'react'
import CompetitionYear from 'components/CompetitionYear'
import NotificationDropdown from 'components/Notification'
import UserDropDown from 'components/UserDropDown'
import SidebarRoute from '../sidebar/SidebarRoute'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import routesConfig from '../routesConfig'
import { iconMap } from './IconMap'

const MobileMenu: React.FC = () => {
  const data = useSelector((state: RootState) => state.me?.me?.data)
  const direction = useSelector((state: RootState) => state.layout.direction)
  const userRole = data?.account_type

  const sidebarRoutes = routesConfig.filter(
    (route) => route.showInSidebar && route.roles?.includes(userRole ?? '')
  )

  return (
    <div
      className={`fixed top-[73px] shadow-xl rounded-2xl bg-white z-50 p-6 overflow-y-auto ${direction === 'rtl' ? 'left-0' : 'right-0'}`}
    >
      <div className="flex flex-col gap-4">
        {data && <UserDropDown name={data?.name} account_type={data?.account_type} />}
        <CompetitionYear />
        {/* <NotificationDropdown /> */}

        <div className="mt-6">
          {sidebarRoutes.map((route) => (
            <SidebarRoute
              key={route.path}
              route={route}
              basePath="/app"
              isCollapsed={false}
              iconMap={iconMap}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default MobileMenu
