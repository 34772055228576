import { useMemo } from 'react'
import { Report } from 'api/types'
import { getNameByLanguage } from 'utils/getNameByLanguage'
import { getEducationStageLabel } from 'constants/educationStageOptions'

export const useReportDataIthraa = (
  reports: any,
  locale: 'ar' | 'en',
  countryName?: { ar: string; en: string }
) => {
  return useMemo(() => {
    const dataRegions = (reports?.countryRegionalApplications ?? []).map((_: any) => ({
      itemName: getNameByLanguage(_?.region_name, locale),
      numberOfApplications: _.total_applications
    }))

    const educationStagesData = (reports?.regionalStagesApplications ?? []).map((_: any) => ({
      ..._,
      name: getNameByLanguage(_?.region_name, locale),
      progressItems: (reports?.stagesApplications ?? []).map((stage: any) => ({
        ...stage,
        name: getEducationStageLabel(stage.stage, locale),
        bgColor:
          stage.stage == 'general'
            ? 'bg-secondary_fill_normal_pressed'
            : 'bg-information_default_fill_hover_active',
        textColor:
          stage.stage == 'general'
            ? 'text-secondary_fill_normal_pressed'
            : 'text-information_default_fill_hover_active'
      }))
    }))

    const dataEducationStagesProgress = (reports?.stagesApplications ?? []).map((_: any) => ({
      ..._,
      name: getEducationStageLabel(_?.stage, locale),
      percentage: _.percentage,
      total_applications: '' + _.total_applications
    }))

    const dataTopCoordinators = {
      byRegion: (reports?.topCoordinators?.byRegion ?? []).map((_: any) => ({
        ..._,
        userName: _.coordinator_name,
        numberOfApplications: _.points,
        region: getNameByLanguage(_.region_name, locale)
      })),
      byAdministration: (reports?.topCoordinators?.byAdministration ?? []).map((_: any) => ({
        ..._,
        userName: _.coordinator_name,
        numberOfApplications: _.points,
        educationAdministration: getNameByLanguage(_.education_administration_name, locale)
      }))
    }
    const administrativesApplications = (reports?.administrativesApplications ?? []).map(
      (_: any) => ({
        ..._,
        userName: _.coordinator_name,
        numberOfApplications: _.points,
        region: getNameByLanguage(_.education_administration_name, locale)
      })
    )
    const topEducationAdministrations = (reports?.topEducationAdministrations ?? []).map(
      (_: any) => ({
        ..._,
        userName: _.coordinator_name,
        numberOfApplications: _.points,
        region: getNameByLanguage(_.education_administration_name, locale)
      })
    )

    const countryTopTeachers = (reports?.countryTopTeachers ?? []).map((_: any) => ({
      ..._,
      userName: _.teacher_name,
      numberOfApplications: _.points,
      region: getNameByLanguage(_.region_name, locale)
    }))

    const otherCountriesTopTeachers = (reports?.otherCountriesTopTeachers ?? []).map((_: any) => ({
      ..._,
      userName: _.teacher_name,
      numberOfApplications: _.points,
      region: getNameByLanguage(_.country_name, locale)
    }))
    const countryTopCoordinators = (reports?.countryTopCoordinators ?? []).map((_: any) => ({
      ..._,
      userName: _.coordinator_name,
      numberOfApplications: _.points,
      region: getNameByLanguage(_.region_name, locale)
    }))

    const otherCountriesTopCoordinators = (reports?.otherCountriesTopCoordinators ?? []).map(
      (_: any) => ({
        ..._,
        userName: _.coordinator_name,
        numberOfApplications: _.points,
        region: getNameByLanguage(_.country_name, locale)
      })
    )
    const EducationAdministativeData = {
      top: (reports?.topEducationAdministrations ?? []).map((_: any) => ({
        ..._,
        itemName: getNameByLanguage(_?.education_administration_name, locale),
        percentage: _.percentage,
        numberOfApplications: _.total_applications
      })),
      all: (reports?.administrativesApplications ?? []).map((_: any) => ({
        ..._,
        itemName: getNameByLanguage(_?.education_administration_name, locale),
        numberOfApplications: _.total_applications,
        percentage: undefined
      })),
      byGender: (reports?.genderAdministrativesApplications ?? []).map((_: any) => ({
        ..._,
        itemName: getNameByLanguage(_?.education_administration_name, locale),
        numberOfApplications: undefined
      }))
    }
    const universitiesApplications = (reports?.universitiesApplications ?? []).map((_: any) => ({
      ..._,
      label: _.university_name
    }))
    const reachPercentage = (reports?.reachPercentage ?? []).map((_: any) => ({
      ..._,
      itemName: _.coordinator_name
    }))

    const countryApplicationsCount = [
      {
        name: getNameByLanguage(
           {
            ar: 'السعودية',
            en: 'Saudi Arabia'
          },
          locale
        ),

        total_applications: reports?.countryApplicationsCount,
        percentage: (Number(reports?.countryApplicationsPercentage) ?? 0).toFixed(2)
      },
      {
        name: getNameByLanguage(
          {
            ar: 'باقي الدول',
            en: 'Other Countries'
          },
          locale
        ),
        total_applications: reports?.otherCountriesApplicationsCount,
        percentage: reports?.otherCountriesApplicationsPercentage
      }
    ]

    const dataCountriesDrafts = (reports?.countriesApplicationsStatus?.draftApplications ?? []).map(
      (_: any) => ({
        ..._,
        itemName: getNameByLanguage(_?.country_name, locale),
        numberOfApplications: _.total_applications
      })
    )

    const countriesApplications = (reports?.countriesApplications ?? []).map((_: any) => ({
      ..._,
      itemName: getNameByLanguage(_?.country_name, locale),
      numberOfApplications: _.total_applications
    }))
    const topCountries = (reports?.topCountries ?? []).map((_: any) => ({
      ..._,
      itemName: getNameByLanguage(_?.country_name, locale),
      numberOfApplications: _.total_applications
    }))
    const dataCountriesDInprogress = (
      reports?.countriesApplicationsStatus?.pendingApplications ?? []
    ).map((_: any) => ({
      ..._,
      itemName: getNameByLanguage(_?.country_name, locale),
      numberOfApplications: _.total_applications
    }))

    const genderCountriesApplications = (reports?.genderCountriesApplications ?? []).map(
      (_: any) => ({
        ..._,
        name: getNameByLanguage(_.country_name, locale),
        progressValue: _.male_percentage,
        valueText: _.male_percentage + '%',
        progressValue2: _.female_percentage,
        valueText2: _.female_percentage + '%'
      })
    )
    const educationStageByContry = (reports?.countryStagesApplications?.data ?? []).map(
      (_: any) => ({
        ..._,
        name: getNameByLanguage(_.region_name, locale),
        percentage: 80,
        total_applications: '8000',
        progressItems: [
          {
            percentage: _?.general_percentage,
            name: 'general',
            bgColor: 'bg-information_default_fill_normal',
            textColor: 'text-information_default_fill_normal'
          },
          {
            percentage: _?.university_percentage,
            name: 'university',
            bgColor: 'bg-secondary_fill_normal_pressed',
            textColor: 'text-secondary_fill_normal_pressed'
          }
        ]
      })
    )
    const educationStageByOthersContries = (
      reports?.otherCountriesStagesApplications?.data ?? []
    ).map((_: any) => ({
      ..._,
      name: getNameByLanguage(_.region_name, locale),
      percentage: 80,
      total_applications: '8000',
      progressItems: [
        {
          percentage: _?.general_percentage,
          name: 'general',
          bgColor: 'bg-information_default_fill_normal',
          textColor: 'text-information_default_fill_normal'
        },
        {
          percentage: _?.university_percentage,
          name: 'university',
          bgColor: 'bg-secondary_fill_normal_pressed',
          textColor: 'text-secondary_fill_normal_pressed'
        }
      ]
    }))
    const educationStageByContryChart = [
      {
        name: getEducationStageLabel('university', locale),
        percentage: reports?.countryStagesApplications?.university_applications,
        total_applications: reports?.countryStagesApplications?.university_applications
      },
      {
        name: getEducationStageLabel('general', locale),
        percentage: reports?.countryStagesApplications?.general_applications,
        total_applications: reports?.countryStagesApplications?.general_applications
      }
    ]
    const educationStageByOthersCountriesChart = [
      {
        name: getEducationStageLabel('university', locale),
        percentage: reports?.otherCountriesStagesApplications?.university_applications,
        total_applications: reports?.otherCountriesStagesApplications?.university_applications
      },
      {
        name: getEducationStageLabel('general', locale),
        percentage: reports?.otherCountriesStagesApplications?.general_applications,
        total_applications: reports?.otherCountriesStagesApplications?.general_applications
      }
    ]
    const countryTotalSagesApplication = {
      country: reports?.countryStagesApplications?.total_applications,
      all: reports?.submittedApplications,
      othersCountries: reports?.otherCountriesStagesApplications?.total_applications
    }
    return {
      dataRegions,
      educationStagesData,
      dataEducationStagesProgress,
      dataTopCoordinators,
      EducationAdministativeData,
      universitiesApplications,
      reachPercentage,
      countryApplicationsCount,
      dataCountriesDrafts,
      dataCountriesDInprogress,
      countryTopTeachers,
      otherCountriesTopTeachers,
      genderCountriesApplications,
      countriesApplications,
      educationStageByContry,
      educationStageByContryChart,
      topCountries,
      educationStageByOthersContries,
      countryTotalSagesApplication,
      educationStageByOthersCountriesChart,
      administrativesApplications,
      topEducationAdministrations,
      countryTopCoordinators,
      otherCountriesTopCoordinators
    }
  }, [reports, locale])
}
