import React from 'react'
import PersonalInfoForm from '../forms/PersonalInfoForm'
import ContactInfoForm from '../forms/ContactInfoForm'
import LocationInfoForm from '../forms/LocationInfoForm'
import EducationInfoForm from '../forms/EducationInfoForm'
import ApplicationBodyForm from '../forms/ApplicationBodyForm'
import AccountBoxIcon from 'assets/icons/AccountBoxIcon'
import PhoneIcon from 'assets/icons/PhoneIcon'
import Home2Icon from 'assets/icons/Home2Icon'
import BallPenIcon from 'assets/icons/BallPenIcon'
import FileListIcon from 'assets/icons/FileListIcon'
import { msg } from '@lingui/macro'
import { Step } from 'components/stepper/Step'

export const steps: Step[] = [
  {
    title: msg`المعلومات الشخصية`.id,
    icon: <AccountBoxIcon fill='currentColor' />,
    component: <PersonalInfoForm />,
    fields: [
      "personal_info.first_name",
      "personal_info.second_name",
      "personal_info.last_name",
      "personal_info.date_of_birth",
      "personal_info.age",
      "personal_info.gender",
      "personal_info.nationality"
    ],
  },
  {
    title: msg`معلومات التواصل`.id,
    icon: <PhoneIcon fill='currentColor' />,
    component: <ContactInfoForm />,
    fields: [
      "contact_info.email",
      "contact_info.phone"
    ],
  },
  {
    title: msg`معلومات العنوان`.id,
    icon: <Home2Icon fill='currentColor' />,
    component: <LocationInfoForm />,
    fields: [
      "location_info.country",
      "location_info.region"
    ],
  },
  {
    title: msg`معلومات التعليم`.id,
    icon: <BallPenIcon fill='currentColor' />,
    component: <EducationInfoForm />,
    fields: [
      "education_info.education_stage",
      "education_info.education_level",
      "education_info.education_administration",
      "education_info.school",
      "education_info.university",
      "education_info.school_university_name",
    ],
  },
  {
    title: msg`معلومات المشاركة`.id,
    icon: <FileListIcon fill='currentColor' />,
    component: <ApplicationBodyForm />,
    fields: [
      "application_body.reading_story",
      "application_body.book_title",
      "application_body.author_name",
      "application_body.reason_for_choice",
      "application_body.book_review",
      "application_body.teacher_code",
      "application_body.terms_agreed"
    ],
  }
]
