import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useLanguage from 'hooks/useLanguage'
import { AppDispatch, RootState } from 'store'
import { IthraUser } from 'api/types'
import { fetchStudentUsers } from 'store/slices/studentsUsersSlice'

const useStudentOptions = () => {
  const dispatch = useDispatch<AppDispatch>()
  const language = useLanguage() as 'ar' | 'en'
  const { studentUsers, isLoading, error } = useSelector((state: RootState) => state.studentUsers)
  useEffect(() => {
    if (studentUsers.length === 0) {
      dispatch(fetchStudentUsers())
    }
  }, [dispatch, studentUsers.length])

  const options = useMemo(() => {
    if (studentUsers.length === 0) {
      return [
        {
          key: '',
          label: language === 'ar' ? 'لا يوجد بيانات للاختيار' : 'No options available'
        }
      ]
    } else {
      return studentUsers.map((user: IthraUser) => ({
        key: String(user.id),
        label: user.name
      }))
    }
  }, [studentUsers, language])

  return { options, isLoading, error }
}

export default useStudentOptions
