import React from 'react'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import IthraaReports from './IthraaReports'
import Reports from './Reports'
export default function ReportsProvider() {
  const userType = useSelector((state: RootState) =>
    typeof state.auth.userType === 'string' ? state.auth.userType : null
  )
   return userType=="ithraa"?<IthraaReports></IthraaReports> :<Reports></Reports>
 // return true ? <IthraaReports></IthraaReports> : <Reports></Reports>
}
