import React from 'react'
import { Progress } from '@nextui-org/react'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { getColorByPercentage } from 'utils/charts'

interface DataItem {
  itemName: string
  numberOfApplications: number
  percentage?: number
}

interface ListViewProps {
  data: DataItem[]
  hideProgress?: boolean
  translationKey?: string
  showPercentageValue?: boolean
}

const ListView: React.FC<ListViewProps> = ({
  data,
  hideProgress,
  translationKey = msg`مشاركة`.id,
  showPercentageValue
}) => {
  const { _ ,i18n} = useLingui()

  return (
    <div className="max-h-60 overflow-y-auto scrollbar-thin scrollbar-thumb-primary scrollbar-track-transparent">
      <ul className="flex flex-col px-4">
        {data.map((item, index) => (
          <li key={index} className="flex flex-col justify-between py-2">
            <div className="flex justify-between w-full">
              <span className="text-left">{item.itemName}</span>
              {!showPercentageValue && (
                <span className="text-right text-natural_lv1_text_normal">
                  {item.numberOfApplications + ' ' + _(translationKey)} 
                </span>
              )}
              {showPercentageValue && (
                <span className="text-right text-natural_lv1_text_normal">
                  {item.percentage + '%  ' + _(translationKey)} 
                </span>
              )}
            </div>
            {item.percentage !== undefined && !hideProgress && (
              <Progress
                classNames={{
                  indicator: `${getColorByPercentage(item.percentage)}`
                }}
                className={`${i18n.locale=="ar"?'transform scale-x-[-1]':''} mt-1`}
                value={item.percentage}
                size="sm"
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ListView
