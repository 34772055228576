import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useScrollContext } from 'pages/landing/context/scroll-context'
import NavbarMenu from './NavbarMenu'
import NavbarBtns from './NavbarBtns'
import IthraLogo from 'assets/icons/IthraLogo'
import IthraLogoEn from 'assets/icons/ithra_en.png'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

interface NavbarProps {
  isScrolled?: boolean
}

const Navbar: React.FC<NavbarProps> = ({ isScrolled }) => {
  const [scrolled, setScrolled] = useState<boolean>()
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  const direction = useSelector((state: RootState) => state.layout.direction)
  const navigate = useNavigate()
  const location = useLocation()
  const scrollToSection = useScrollContext()

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  useEffect(() => {
    if (isScrolled) {
      setScrolled(true)
      return
    }

    const handleScroll = () => {
      if (window.scrollY > 20) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isScrolled])

  const handleNavigation = (section: string) => {
    if (location.pathname === '/') {
      if (scrollToSection) {
        scrollToSection(section)
        if (menuOpen) toggleMenu()
      }
    } else {
      navigate(`/?section=${section}`)
      setTimeout(() => {
        if (scrollToSection) {
          scrollToSection(section)
          if (menuOpen) toggleMenu()
        }
      }, 0)
    }
  }

  const handleBlogLinkClick = () => {
    navigate('/blog')
    window.scrollTo(0, 0)
    if (menuOpen) toggleMenu()
  }
  const handleSupplementaryLinkClick = () => {
    navigate('/supplementary-files')
    window.scrollTo(0, 0)
    if (menuOpen) toggleMenu()
  }

  const handleLogoClick = () => {
    navigate('/')
  }

  const borderClass = scrolled ? 'border-naturalLv2' : 'border-customWhite'
  const textClass = `${scrolled ? 'text-naturalLv2' : 'text-naturalLv2 md:text-customWhite'}`
  const fillColor = scrolled ? 'rgba(63, 63, 70, 1)' : 'rgba(249, 250, 251, 1)'

  return (
    <div
      className={`h-24 md:h-32 fixed top-0 right-0 left-0 z-[100] ${
        scrolled ? 'bg-customWhite' : 'bg-white md:bg-customWhite10'
      }`}
    >
      <div className="px-8 py-1.5 md:px-14 h-full flex items-center justify-between">
        <div
          className={`h-full overflow-hidden ${
            scrolled ? 'text-customTeal' : 'text-customTeal md:text-customWhite'
          }`}
          onClick={handleLogoClick}
          style={{ cursor: 'pointer' }}
        >
          {direction == 'ltr' ? (
            <div className="overflow-scroll md:overflow-hidden h-full">
              <img
                src={IthraLogoEn}
                alt={'ithra'}
                className={`h-2 object-cover  ${scrolled ? 'filter brightness-100' : 'filter brightness-0 invert'}`}
                style={{ minWidth: '100%', minHeight: '100%', objectFit: 'cover' }}
              />
            </div>
          ) : (
            <IthraLogo width="81" height="100%" />
          )}
        </div>

        <div className="md:flex md:items-center md:*:mx-2.5">
          <NavbarMenu
            menuOpen={menuOpen}
            fillColor={fillColor}
            textClass={textClass}
            handleNavigation={handleNavigation}
            handleBlogLinkClick={handleBlogLinkClick}
            handleSupplementaryLinkClick={handleSupplementaryLinkClick}
            toggleMenu={toggleMenu}
          />

          <NavbarBtns
            borderClass={borderClass}
            fillColor={fillColor}
            textClass={textClass}
            toggleMenu={toggleMenu}
            menuOpen={menuOpen}
          />
        </div>
      </div>
    </div>
  )
}

export default Navbar
