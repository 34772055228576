import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { DateValue, useDisclosure } from '@nextui-org/react'
import { CompetitionStatus } from 'api/types'
import CheckboxVariant from 'components/checkbox/checkbox'
import DateField from 'components/date-picker-localized/date-picker'
import FilterLayout from 'components/Filter/FilterLayout'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import {
  fetchCompetitionYears,
  setEndDate,
  setSearch,
  setStartDate,
  setStatusFilter
} from 'store/slices/competitionYearSlice'
import Competitions from './Competitions'
import CreateCompetition from './CreateCompetition'

export const CompetitionYear: FC = () => {
  const { _ } = useLingui()
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const dispatch = useDispatch<AppDispatch>()
  const { search, start_date, end_date, status, auto_start, page, per_page } = useSelector(
    (state: RootState) => state.competitionYear
  )

  const handleFilterApply = () => {
    dispatch(
      fetchCompetitionYears({
        search,
        start_date,
        end_date,
        auto_start,
        page,
        per_page,
        status
      })
    )
  }

  const handleFilterReset = async () => {
    dispatch(setSearch(''))
    dispatch(setStartDate(undefined))
    dispatch(setEndDate(undefined))
    dispatch(setStatusFilter(null))
    dispatch(
      fetchCompetitionYears({
        search: '',
        start_date: undefined,
        end_date: undefined,
        auto_start: undefined,
        page: 1,
        per_page: 8,
        status: null
      })
    )
  }

  const filterFields = [
    {
      name: 'search',
      hidden: true,
      component: <></>,
      action: (value: string) => {
        dispatch(setSearch(value))
        dispatch(
          fetchCompetitionYears({
            search,
            start_date,
            end_date,
            auto_start,
            page,
            per_page,
            status
          })
        )
      },
      selector: (state: RootState) => state.competitionYear.search
    },
    {
      name: 'not_started',
      label: _(msg`حالة النسخة`),
      component: (
        <CheckboxVariant
          label={_(msg`لم يبدأ`)}
          checked={status === 'not_started'}
          onChange={() =>
            dispatch(setStatusFilter(status === 'not_started' ? null : 'not_started'))
          }
        />
      ),
      action: (value: CompetitionStatus) => dispatch(setStatusFilter(value)),
      selector: (state: RootState) => state.competitionYear.status
    },
    {
      name: 'started',
      component: (
        <CheckboxVariant
          label={_(msg`بدأ`)}
          checked={status === 'started'}
          onChange={() => dispatch(setStatusFilter(status === 'started' ? null : 'started'))}
        />
      ),
      action: (value: CompetitionStatus) => dispatch(setStatusFilter(value)),
      selector: (state: RootState) => state.competitionYear.status
    },
    {
      name: 'ended',
      component: (
        <CheckboxVariant
          label={_(msg`انتهى`)}
          checked={status === 'ended'}
          onChange={() => dispatch(setStatusFilter(status === 'ended' ? null : 'ended'))}
        />
      ),
      action: (value: CompetitionStatus) => dispatch(setStatusFilter(value)),
      selector: (state: RootState) => state.competitionYear.status
    },
    {
      name: 'terminated',
      component: (
        <CheckboxVariant
          label={_(msg`تم انهاء الخدمة`)}
          checked={status === 'terminated'}
          onChange={() => dispatch(setStatusFilter(status === 'terminated' ? null : 'terminated'))}
        />
      ),
      action: (value: CompetitionStatus) => dispatch(setStatusFilter(value)),
      selector: (state: RootState) => state.competitionYear.status
    },
    {
      name: 'archived',
      component: (
        <CheckboxVariant
          label={_(msg`مؤرشفة`)}
          checked={status === 'archived'}
          onChange={() => dispatch(setStatusFilter(status === 'archived' ? null : 'archived'))}
        />
      ),
      action: (value: CompetitionStatus) => dispatch(setStatusFilter(value)),
      selector: (state: RootState) => state.competitionYear.status
    },
    {
      name: 'start_date',
      component: (
        <DateField
          labelClassName="text-primary !font-bold mt-2 -mr-3"
          label={_(msg`تاريخ البدء`)}
          value={start_date || null}
          onChange={(date) => dispatch(setStartDate(date?.toString()))}
        />
      ),

      action: (value: DateValue | null) =>
        dispatch(setStartDate(value ? value.toString() : undefined)),
      selector: (state: RootState) => state.competitionYear.start_date
    },
    {
      name: 'end_date',
      component: (
        <DateField
          labelClassName="text-primary !font-bold mt-1 -mr-3"
          label={_(msg`تاريخ الانتهاء`)}
          value={end_date || null}
          onChange={(date) => dispatch(setEndDate(date?.toString()))}
        />
      ),
      action: (value: DateValue | null) =>
        dispatch(setEndDate(value ? value.toString() : undefined)),
      selector: (state: RootState) => state.competitionYear.end_date
    }
  ]

  return (
    <div className="py-6">
      <FilterLayout
        filterFields={filterFields}
        onFilterApply={handleFilterApply}
        onFilterReset={handleFilterReset}
        buttonText={_(msg`اضافة نسخة جديدة`)}
        onButtonPress={onOpen}
        showButton={true}
      />
      <Competitions />
      <CreateCompetition isOpen={isOpen} onOpenChange={onOpenChange} />
    </div>
  )
}
