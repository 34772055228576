import { useState } from 'react'
import { FC } from 'react'
import { Tabs, Tab, TabsProps } from '@nextui-org/react'
import Loader from 'components/loader/Loader'

interface TabsNavigatorProps extends Omit<TabsProps, 'children'> {
  items: {
    key: string
    title: string
    content: React.ReactNode
    isLoading?: boolean
  }[]
}

const TabsNavigator: FC<TabsNavigatorProps> = ({ items, ...other }) => {
  const [selectedTab, setSelectedTab] = useState<string>(items[0]?.key || '')

  return (
    <Tabs
      {...other}
      selectedKey={selectedTab}
      onSelectionChange={(key) => setSelectedTab(key.toString())}
      classNames={{
        tabList: 'gap-6 w-full relative rounded-none p-0 border-b border-divider',
        cursor: 'w-full',
        tab: 'max-w-fit px-0 h-10',
        tabContent:
          'group-data-[selected=true]:text-danger_inverted_lv1_text_normal font-label-h6-ar',
        panel: 'h-full',
        ...(other.classNames || {})
      }}
    >
      {items.map(({ key, title, content, isLoading }, i) => (
        <Tab key={`${key}-${i}`} title={title}>
          {isLoading ? <Loader /> : content}
        </Tab>
      ))}
    </Tabs>
  )
}

export default TabsNavigator
