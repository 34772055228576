import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { Region, RegionsResponse } from 'api/types'
import { cleanObject } from 'utils/cleanObject'

interface RegisterState {
  regions: Region[]
  isLoading: boolean
  error: string | null
}

const initialState: RegisterState = {
  regions: [],
  isLoading: false,
  error: null
}

export const fetchRegions = createAsyncThunk(
  'regions',
  async (params: { countryId?: string | string[]; countryCode?: string }) => {
    const { countryId, countryCode } = params

    const paramsObj = { 'countries[]': countryId, country_code: countryCode }

    const response = await axiosInstance.get<RegionsResponse>('/geo/regions', {
      params: cleanObject(paramsObj),
      paramsSerializer: (params) => {
        return Object.entries(params)
          .map(([key, value]) =>
            Array.isArray(value)
              ? value.map((v) => `${key}=${encodeURIComponent(v)}`).join('&')
              : `${key}=${encodeURIComponent(value)}`
          )
          .join('&')
      }
    })
    return response.data
  }
)

const regionsSlice = createSlice({
  name: 'regions',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRegions.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchRegions.fulfilled, (state, action) => {
        state.isLoading = false
        const regions = action.payload.data ?? []
        state.regions = regions
      })
      .addCase(fetchRegions.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default regionsSlice
