interface CheckMarkIconProps {
  size?: string
  fill?: string
}

const CheckMarkIcon: React.FC<CheckMarkIconProps>= ({ size = '24', fill = '#000'  }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <path fill={fill} d="M21 7L9 19l-5.5-5.5l1.41-1.41L9 16.17L19.59 5.59z" />

    </svg>
  )
}

export default CheckMarkIcon