import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useLanguage from 'hooks/useLanguage'
import { AppDispatch, RootState } from 'store'
import { fetchSchools } from 'store/slices/schoolsSlice'
import { getLocalizedName } from 'utils/getLocalizedName'
import { School, UseSchoolsProps, UseSchoolOptionsProps } from './types'


const useSchools = ({ countryId, regionId }: UseSchoolsProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { schools, isLoading, error } = useSelector((state: RootState) => state.schools)

  const memoizedCountryId = useMemo(() => countryId, [countryId])
  const memoizedRegionId = useMemo(() => regionId, [regionId])

  useEffect(() => {
    if (memoizedCountryId || memoizedRegionId) {
      dispatch(
        fetchSchools({
          countryId: memoizedCountryId,
          regionsIds: memoizedRegionId ? [memoizedRegionId] : []
        })
      )
    }
  }, [dispatch, memoizedCountryId, memoizedRegionId])

  return { schools, isLoading, error }
}

export const useSchoolOptions = ({ countryId, regionId }: UseSchoolOptionsProps) => {
  const language = useLanguage() as 'ar' | 'en'
  const { schools, isLoading, error } = useSchools({ countryId, regionId })

  const options = useMemo(() => {
    if (schools.length === 0) {
      return [
        // {
        //   key: '',
        //   label: language === 'ar' ? 'لا يوجد بيانات للاختيار' : 'No options available'
        // }
      ]
    } else {
      return schools.map((school: School) => ({
        key: String(school.id),
        label: getLocalizedName(school.name, language)
      }))
    }
  }, [schools, language])

  return { options, isLoading, error }
}
