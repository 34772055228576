import { z } from 'zod'
import { msg } from '@lingui/macro'

// Updated validation schema
const validationSchema = z.object({
  email: z
    .string()
    .trim()
    .min(1, { message: msg`يرجى ملء جميع الحقول المطلوبة (*) للمتابعة`.id })
    .email({
      message: msg`الرجاء إدخال عنوان بريد إلكتروني صحيح. مثال: mailto:example@email.com`.id
    }),
  password: z
    .string()
    .min(1, msg`كلمة المرور مطلوبة`.id)
    .min(8, msg`يجب أن تتكون كلمة المرور من 8 أحرف على الأقل`.id)
})

export default validationSchema
