import React from 'react'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import InputField from 'components/application-viewer/components/DisabledInput'
import { PhoneInput } from 'react-international-phone'
import { Application } from 'store/slices/applicationsSlice'
import './phone-input.css'
import 'react-international-phone/style.css'
import Label from 'components/label/Label'

const ContactInfoForm = ({ applicationData }: { applicationData: Application }) => {
  const { _ } = useLingui()

  return (
    <>
      <div className="flex flex-col justify-start gap-2">
        <Label label={_(msg`رقم الجوال`)} />
        <PhoneInput
          disabled
          value={applicationData.contact_info.phone || ''}
          className={
            'bg-natural_fill_disabled bg-opacity-65 border-natural_stroke_normal_disabled border-2 w-full font-sub-heading-h6-ar text-natural_icon_normal placeholder:text-primary_lv2_text_normal h-10'
          }
          style={{
            direction: 'ltr',
            width: '100%'
          }}
        />
      </div>
      <InputField
        name="contact_info.email"
        label={_(msg`البريد الإلكتروني`)}
        value={applicationData.contact_info.email || ''}
        isDisabled
      />
    </>
  )
}

export default ContactInfoForm
