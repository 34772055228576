import React from 'react'
import YouTubeVideo from 'components/base/youtube-video'
import { Trans } from '@lingui/react'
import { MessageDescriptor } from '@lingui/core'

interface PodcastCardProps {
  name: MessageDescriptor
  description: MessageDescriptor
  videoId: number
  fallbackImg: string
  link: string
}

const PodcastCard: React.FC<PodcastCardProps> = ({
  name,
  description,
  videoId,
  fallbackImg,
  link
}) => {
  return (
    <div className="flex flex-col items-start p-3 w-full rounded-xl hover:bg-customTeal10">
      <div className="relative w-full">
        <YouTubeVideo videoId={link} fallbackImg={fallbackImg} altText={name.id} />
      </div>
      <div className="mt-4 mx-4 md:mx-0">
        <h3 className="text-teal-600 text-lg font-semibold">
          <Trans id={name.id} />
        </h3>
        <p className="text-naturalLv2 mt-2">
          <Trans id={description.id} />
        </p>
      </div>
    </div>
  )
}

export default PodcastCard
