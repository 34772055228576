import { msg, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import Button from 'components/base/button'
import { ArrowLeft } from 'assets/icons/ArrowLeft'
import Alert from 'components/alert/alert'
import AppTabs from 'components/apps/Tabs'
import MetricCard from 'components/card/MetricCardLayout'
import OnBoardingMsgs from 'components/onBoardingMsgs/OnBoardingmsgs'
import useLanguage from 'hooks/useLanguage'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import { fetchApps } from 'store/slices/applicationsSlice'
import unseenApps from 'utils/unseenApps'
import CodeCard from '../components/CodeCard'
import PointsCard from './components/PointsCard'

const TeacherAccount = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { _ } = useLingui()
  const language = useLanguage()
  const isAr = language === 'ar'

  const user = useSelector((s: RootState) => s.me.me?.data! || {})
  const { pointsCount, code } = useSelector((state: RootState) => state.teacherStats)
  const { competitionYear } = useSelector((state: RootState) => state.competitionYearNavbar)
  const { apps, isLoading, page, per_page, total_pages } = useSelector(
    (state: RootState) => state.appsStates
  )
  const [showAlert, setShowAlert] = useState<{ type: 'success' | 'error'; message: string } | null>(
    null
  )

  const { account_type } = user
  const unseenAppsNum = unseenApps(user, apps)

  useEffect(() => {
    dispatch(
      fetchApps({
        user: account_type || '',
        competition_year_id: competitionYear?.id.toString()
      })
    )
  }, [dispatch, competitionYear, account_type])

  return (
    <>
      {!!unseenAppsNum && (
        <Alert
          type="success"
          sizeMainText="md"
          mainText={`${_(msg`قام`)} ${unseenAppsNum > 1 ? unseenAppsNum : ''} ${unseenAppsNum === 1 ? _(msg`طالب جديد`) : _(msg`طلاب جدد`)} ${_(msg`بالتسجيل من خلالك، يمكنك الاطلاع على مشاركاتهم.`)}`}
          onButtonClick={() => navigate('/app/applications')}
          buttonText={`${_(msg`الاطلاع على المشاركات`)}`}
          btnProps={{
            iconEnd: (
              <div className={!isAr ? 'rotate-180' : ''}>
                <ArrowLeft />
              </div>
            )
          }}
        />
      )}
      {showAlert && (
        <Alert
          closable
          mainText={showAlert.message}
          sizeMainText="md"
          weightMainText="normal"
          onClose={() => setShowAlert(null)}
          type={showAlert.type}
        />
      )}
      <OnBoardingMsgs />

      <div className="flex flex-col w-full">
        <div className="w-full py-9">
          <div className="mx-auto w-fit flex flex-col md:flex-row justify-center items-center gap-6 first-step">
            <MetricCard color="bg-natural_lv1_text_normal">
              <PointsCard points={pointsCount} />
            </MetricCard>
            <MetricCard color="bg-natural_lv1_text_normal">
              <CodeCard
                code={code}
                onCopy={() =>
                  setShowAlert({ type: 'success', message: _(msg`تم نسخ الكود بنجاح`) })
                }
              />
            </MetricCard>
          </div>
        </div>

        <div className="w-full md:flex flex-col gap-y-3 py-9">
          <div className="flex w-full justify-between items-center my-2.5">
            <h2 className="font-heading-h4-ar text-natural_lv1_text_normal">
              <Trans>مشاركات الطلاب</Trans>
            </h2>

            <div>
              <Button
                placeholder={msg`مشاهدة الكل`}
                btnStyle="secondary"
                btnSize="Default"
                trailingIcon={true}
                onClick={() => navigate('/app/applications')}
              />
            </div>
          </div>

          <AppTabs
            apps={apps}
            page={page}
            showPagination={false}
            per_page={per_page}
            isLoading={isLoading}
            isCoordinator={false}
            total_pages={total_pages}
          />
        </div>
      </div>
    </>
  )
}

export default TeacherAccount
