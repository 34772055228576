import { FC, useEffect, useState } from 'react'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import SearchAndFilter, { FilterOption } from 'components/search-and-filter/SearchAndFilter'
import { useCountryOptions } from 'pages/SubmitApplication/hooks/useCountryOptions'
import TabSwitcher from 'components/tabSwitcher/TabSwitcher'
import { setPage } from 'store/slices/usersAccountsSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { Filters } from '../AdminUsersAccounts'

interface props {
  activeTab: string
  setActiveTab: (activeTab: string) => void
  setIsAddedByAdmin: (isAddedByAdmin: number) => void
  filters: Filters
  setFilters: (filters: Filters) => void
  searchValue: string
  setSearchValue: (value: string) => void
  onFilter: () => void
  onCancel: () => void
  setShowAlert: (showAlert: { type: 'success' | 'error'; message: string } | null) => void
}

const AccountsFilters: FC<props> = ({
  activeTab,
  setActiveTab,
  setIsAddedByAdmin,
  filters,
  setFilters,
  searchValue,
  setSearchValue,
  onFilter,
  onCancel,
  setShowAlert
}) => {
  const { _ } = useLingui()

  const dispatch = useDispatch<AppDispatch>()

  const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue)

  const { options: countryOptions, isLoading: CountryOptionsLoading } = useCountryOptions()
  const userTypes = [
    { label: _(msg`مستخدم إثراء`), key: 'ithraa' },
    { label: _(msg`طالب`), key: 'student' },
    { label: _(msg`معلم`), key: 'teacher' },
    { label: _(msg`منسق`), key: 'coordinator' },
    { label: _(msg`حكم`), key: 'judge' }
  ]
  const yesNo = [
    { label: _(msg`نعم`), key: '1' },
    { label: _(msg`لا`), key: '0' }
  ]
  const accStatus = [
    { label: _(msg`فعال`), key: '1' },
    { label: _(msg`غير فعال`), key: '0' }
  ]

  const tabs = [
    {
      id: '0',
      label: _(msg`الحسابات المُسجّلة ذاتيًا`)
    },
    {
      id: '1',
      label: _(msg`الحسابات المُضافة من الإدارة`)
    }
  ]

  const handleDateChange = (key: 'created_at_from' | 'created_at_to', date: string) => {
    setFilters({ ...filters, [key]: date })
  }

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
  }

  const onTabChange = (tab: string) => {
    if (tab === activeTab) {
      return
    }
    setSearchValue('')
    setFilters({
      'countries[]': [],
      'types[]': [],
      completed_after_migration: '',
      migrated_from_old_system: '',
      active: '',
      created_at_from: '',
      created_at_to: ''
    })
    setIsAddedByAdmin(+tab)
    setActiveTab(tab)
    dispatch(setPage(1))
    setShowAlert(null)
  }

  const handleDropdownChange = (key: string, value: string | string[]) => {
    setFilters({ ...filters, [key]: value })
  }

  const filtersList: FilterOption[] = [
    {
      type: 'dropdown',
      selectionMode: 'multiple',
      label: _(msg`الدولة`),
      value: filters['countries[]'] || '',
      options: countryOptions.map((option) => ({ label: option.label, key: option.key })),
      onChange: (value: string) => handleDropdownChange('countries[]', value)
    },
    {
      type: 'dropdown',
      selectionMode: 'multiple',
      label: _(msg`نوع المستخدم`),
      value: filters['types[]'] || '',
      options: userTypes.map((user) => ({ label: user.label, key: user.key })),
      onChange: (value: string) => handleDropdownChange('types[]', value)
    },
    {
      type: 'date',
      label: _(msg`تاريخ الإضافة (من)`),
      value: filters.created_at_from || '',
      onChange: (value: string) => handleDateChange('created_at_from', value)
    },
    {
      type: 'date',
      label: _(msg`تاريخ الإضافة (إلي)`),
      value: filters.created_at_to || '',
      onChange: (value: string) => handleDateChange('created_at_to', value)
    },
    {
      type: 'dropdown',
      label: _(msg`المستخدم أكمل تسجيل الدخول في النظام الجديد بعد الانتقال؟`),
      value: filters.completed_after_migration || '',
      options: yesNo.map((option) => ({ label: option.label, key: option.key })),
      onChange: (value: string) => handleDropdownChange('completed_after_migration', value)
    },
    {
      type: 'dropdown',
      label: _(msg`المستخدم قد انتقل من النظام القديم؟`),
      value: filters.migrated_from_old_system || '',
      options: yesNo.map((option) => ({ label: option.label, key: option.key })),
      onChange: (value: string) => handleDropdownChange('migrated_from_old_system', value)
    },
    {
      type: 'dropdown',
      label: _(msg`حالة الحساب`),
      value: filters.active || '',
      options: accStatus.map((status) => ({ label: status.label, key: status.key })),
      onChange: (value: string) => handleDropdownChange('active', value)
    }
  ]

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue)
    }, 1000)

    return () => {
      clearTimeout(handler)
    }
  }, [searchValue])

  useEffect(() => {
    onFilter()
  }, [debouncedSearchValue])

  return (
    <div className="flex flex-col-reverse md:flex-row items-center justify-center md:justify-between pb-4 gap-8 md:gap-0">
      <SearchAndFilter
        // isSlider
        searchValue={searchValue}
        onSearchChange={handleSearchChange}
        filters={filtersList}
        onFilter={onFilter}
        onCancel={onCancel}
      />
      <TabSwitcher activeTab={activeTab} onClick={onTabChange} tabs={tabs} />
    </div>
  )
}

export default AccountsFilters
