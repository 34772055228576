import React from 'react'
import { Trans } from '@lingui/macro'
import Stepper from 'components/stepper/Stepper'
import CardLayout from 'components/card/card-layout'
import { useSteps } from '../hooks/useSteps'

const AwardsProgress = () => {
    const { steps, currentStep } = useSteps()
  return (
    <CardLayout isBordered={false} isFullWidth paddig="p-6">
    <div className="flex flex-col gap-6 pb-10 pt-4">
    <h4 className="font-heading-h6-ar text-natural_icon_normal capitalize">
      <Trans>مدى تقدمك في الحصول على الجوائز</Trans>
    </h4>
    <Stepper steps={steps} currentStep={currentStep} theme="awards" />
  </div>
  </CardLayout>
  )
}

export default AwardsProgress