import React from 'react'
import ModalHeader from 'components/modal/modal-header'
import ModalLayout from 'components/modal/modal-layout'
import { Trans } from '@lingui/macro'
import { msg } from '@lingui/macro'
import ButtonVariant from 'components/button-variant/button'

interface RestoreAppModalProps {
    onConfirm: () => void
    onCancel: () => void
  }


const RestoreAppModal: React.FC<RestoreAppModalProps> = ({ onConfirm, onCancel }) => {

  return (
    <ModalLayout
      onBack={onCancel}
      accountPageStyle
      header={
          <ModalHeader
            headerText={msg`التراجع عن تقديم المشاركة`}
            subheaderText={
              <p>
                <Trans>هل أنت متأكد من استعادة مشاركتك ؟</Trans>
              </p>
            }
          />
      }
      footer={
        <>
          <div className='flex items-center gap-2'>
            <ButtonVariant onClick={onCancel} variant="bordered">
              <Trans>إلغاء</Trans>
            </ButtonVariant>
            <ButtonVariant onClick={onConfirm} variant="secondary">
              <Trans>موافقة</Trans>
            </ButtonVariant>
          </div>
        </>
      }
    />
  )
}

export default RestoreAppModal
