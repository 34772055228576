import { XIcon } from 'assets/icons/XIcon'
import ButtonVariant from 'components/button-variant/button'
import React from 'react'

export default function ModalCloseButton({ onClose }: { onClose?: () => void }) {
  return (
    <div className="px-4 pt-4">
      <ButtonVariant onClick={onClose} variant="clear" isIconOnly radius="full">
        <XIcon></XIcon>
      </ButtonVariant>
    </div>
  )
}
