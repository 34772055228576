import TrophyIcon from 'assets/icons/TrophyIcon'
import React from 'react'
import { Tooltip } from 'components/toolTip/Tooltip'
import { Award } from 'api/types'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

interface props {
  award: Award
  index: number
}

const bg = ['bg-secondary_fill_normal_pressed', 'bg-tertiary_normal', 'bg-ithra_purple']

const AwardCard: React.FC<props> = ({ award, index }) => {
  const direction = useSelector((state: RootState) => state.layout.direction)

  const name = direction === 'ltr' ? award.name.en : award.name.ar
  const description = direction === 'ltr' ? award.description.en : award.description.ar
  const bgColor = bg[index % bg.length]

  return (
    <Tooltip showArrow={true} content={description}> 
    <div className="w-full max-w-72 flex items-center gap-2 px-4 py-4 rounded-[20px] bg-white border border-natural_stroke_normal_disabled">
      <div className={`w-12 h-12 flex justify-center items-center rounded-full ${bgColor}`}> 
        <TrophyIcon fill="white" />
      </div>
      <h4 className="font-heading-h6 text-natural_icon_normal capitalize">{name}</h4> 
    </div>
    </Tooltip>
  )
}

export default AwardCard
