import { z } from 'zod'
import { t } from '@lingui/macro'

const ReportFiltersFormSchema = z
  .object({
    country: z.string().optional(),
    region: z.string().optional(),
    education_stage: z.string().optional(),
    education_administration: z.string().optional(),
    university: z.string().optional(),
    school: z.string().optional(),
    participation_start_date: z.date().nullable(),
    participation_end_date: z.date().nullable(),
    participation_group: z.string().optional(),
    stage_name: z.string().optional(),
    age: z.number().optional(),
    student_name: z.string().optional(),
    student_email: z.string().optional(),
    participation_number: z.string().optional(),
    gender: z.string().optional()
  })
  .refine(
    (data) => {
      if (data.participation_start_date && data.participation_end_date) {
        return data.participation_start_date < data.participation_end_date
      }
      return true
    },
    {
      message: t`تاريخ البدء يجب أن يكون قبل تاريخ الانتهاء.`,
      path: ['participation_start_date']
    }
  )

export default ReportFiltersFormSchema
