import React from 'react'
import { Trans } from '@lingui/macro'
import AwardCard from './AwardCard'
import BoxIcon from 'assets/icons/BoxIcon'
import { Award } from 'api/types'

interface AwardsSectionProps {
  awardsList: Award[]
}

const AwardsSection: React.FC<AwardsSectionProps> = ({ awardsList }) => {
  return (
    <div className="bg-natural_fill_normal_hover_pressed_active rounded-[20px] px-6 py-8 flex flex-col gap-6">
      {awardsList.length === 0 ? (
        <div className="size-full border-2 border-dashed border-ithra_purple text-ithra_purple flex flex-col items-center justify-center gap-2 pt-7 pb-12">
          <BoxIcon fill="currentColor" />
          <p className={`font-paragraph-overline-h6 text-natural_icon_normal`}>
            <Trans>حتى الآن، لم يتم الفوز بأي جوائز بعد.</Trans>
          </p>
        </div>
      ) : (
        <>
          <h3 className="font-heading-h6-ar text-natural_icon_normal">
            <Trans> الجوائز التي تمكنت من الفوز بها حتى الآن:</Trans>
          </h3>

          <div className="flex flex-wrap gap-4 mx-4 md:mx-12 justify-center items-center">
            {awardsList.map((award, index) => (
              <AwardCard key={index} award={award} index={index} />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default AwardsSection
