import { FC } from 'react'
import PopoverOption, { Popover, PopoverTrigger, PopoverContent } from 'components/popover/popover'
import ButtonVariant from 'components/button-variant/button'
import { VerticalDotsIcon } from 'assets/icons/VerticalDotsIcon'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

interface OptionButtonProps {
  item: any
  index: number
  popoverVisible: number | null
  setPopoverVisible: (visible: number | null) => void
  handleOptionClick: (option: string, item: any) => void
}

const OptionButton: FC<OptionButtonProps> = ({
  item,
  index,
  popoverVisible,
  setPopoverVisible,
  handleOptionClick
}) => {
  const { _ } = useLingui()
  const direction = useSelector((state: RootState) => state.layout.direction)

  const handleClick = (index: number) => {
    setPopoverVisible(popoverVisible === index ? null : index)
  }

  const handleOpenChange = (isOpen: boolean, index: number) => {
    if (isOpen) {
      setPopoverVisible(index)
    } else {
      setPopoverVisible(null)
    }
  }

  return (
    <div key={index}>
      <Popover
        isOpen={popoverVisible === index}
        onOpenChange={(isOpen) => handleOpenChange(isOpen, index)}
        placement={direction === 'rtl' ? 'bottom-end' : 'bottom-start'}
        size="optionsList"
      >
        <PopoverTrigger>
          <div>
            <ButtonVariant variant="clear" isIconOnly onClick={() => handleClick(index)}>
              <VerticalDotsIcon />
            </ButtonVariant>
          </div>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverOption
            optionText={
              item.active ? _(msg`تغيير الحالة الي “غير فعال”`) : _(msg`تغيير الحالة الي “فعال”`)
            }
            onClick={() => handleOptionClick('updateAccountStatus', item)}
          />
          {!item.verified && (
            <PopoverOption
              optionText={_(msg`إرسال رابط تفعيل الحساب`)}
              onClick={() => handleOptionClick('sendActivationEmail', item)}
            />
          )}
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default OptionButton
