import React from 'react'
import { User } from 'pages/AccountPages/types'
import ArrowIcon from 'assets/icons/ArrowIcon'
import DynamicBackground from 'pages/AccountPages/components/DynamicBackground'
import { Trans } from '@lingui/react'

interface UserSectionProps {
  user: User
  selectedUser: User | null
  isActive: boolean
}

const UserSection: React.FC<UserSectionProps> = ({ user, isActive, selectedUser }) => {
  return (
    <div className={`group w-full h-full ${isActive ? '' : 'cursor-pointer'}`}>
      <DynamicBackground
        img={user.background.img}
        overlay={user.background.overlay}
        isActive={isActive}
      >
        {!selectedUser && (
          <div className="w-full h-full z-20 md:h-3/5 p-12 md:p-6 flex flex-row md:flex-col justify-between items-center">
            <div className="flex flex-col space-y-4 items-center text-center">
              <h2 className="text-[#F9FAFB] font-ithra text-3xl group-hover:text-4xl font-400 transition-all ease-in-out duration-1000">
                {user.label}
              </h2>
              <p className="block md:hidden text-[#F9FAFB] font-ithra text-sm font-700 transition-opacity duration-1000">
                <Trans id={user.description.id} />
              </p>
              <p className="hidden md:block text-[#F9FAFB] font-ithra text-sm font-700 transition-opacity duration-1000 opacity-0 md:group-hover:opacity-100">
                <Trans id={user.description.id} />
              </p>
            </div>
            <div className="relative w-fit md:w-full">
              <div className="md:absolute md:group-hover:end-0 md:bottom-0 md:end-[50%] transition-all ease-in-out duration-1000">
                <div className="flex flex-row items-center justify-center bg-[#2A2B2D] bg-opacity-10 w-11 h-11 text-customWhite rounded-full rotate-180">
                  <ArrowIcon fill="currentColor" width="24" height="24" />
                </div>
              </div>
            </div>
          </div>
        )}
      </DynamicBackground>
    </div>
  )
}

export default UserSection
