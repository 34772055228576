import InformationIcon from 'assets/icons/InformationIcon'
import React from 'react'

interface HelperTextProps {
    text: string
}

const HelperText: React.FC<HelperTextProps> = ({ text }) => {
  return (
    <div className='flex gap-1'>
        <InformationIcon />
        <p className='text-natural_icon_normal font-body-h6'>{text}</p>
    </div>
  )
}

export default HelperText