import { NextUIProvider } from '@nextui-org/react'
import Routes from 'routes/routes'

function App() {
  return (
    <NextUIProvider>
      <Routes />
    </NextUIProvider>
  )
}

export default App
