import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { EducationAdministration, EducationAdministrationsResponse } from 'api/types'
import { cleanObject } from 'utils/cleanObject'

interface RegisterState {
  educationAdministrations: EducationAdministration[]
  isLoading: boolean
  error: string | null
  regionsIds: string[]
}

const initialState: RegisterState = {
  educationAdministrations: [],
  isLoading: false,
  error: null,
  regionsIds: []
}

export const fetchEducationAdministrations = createAsyncThunk(
  'educationAdministrations',
  async (params: {
    countryCode?: string
    regionsIds?: string | string[]
    countryId?: string | string[]
  }) => {
    const { regionsIds = [], countryCode, countryId } = params

    const paramsObj = {
      'countries[]': countryId,
      'regions[]': regionsIds,
      country_code: countryCode
    }

    const response = await axiosInstance.get<EducationAdministrationsResponse>(
      '/education-administrations',
      {
        params: cleanObject(paramsObj),
        paramsSerializer: (params) => {
          return Object.entries(params)
            .map(([key, value]) =>
              Array.isArray(value)
                ? value.map((v) => `${key}=${encodeURIComponent(v)}`).join('&')
                : `${key}=${encodeURIComponent(value)}`
            )
            .join('&')
        }
      }
    )
    return response.data
  }
)

const educationAdministrationsSlice = createSlice({
  name: 'educationAdministrations',
  initialState,
  reducers: {
    setRegionsIds(state, action: PayloadAction<string[]>) {
      state.regionsIds = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEducationAdministrations.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchEducationAdministrations.fulfilled, (state, action) => {
        state.isLoading = false
        const educationAdministrations = action.payload.data ?? []
        state.educationAdministrations = educationAdministrations
      })
      .addCase(fetchEducationAdministrations.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export const { setRegionsIds } = educationAdministrationsSlice.actions

export default educationAdministrationsSlice
