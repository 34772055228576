import { Award } from 'pages/landing/models'
import img1 from 'assets/images/awards-bg-1.png'
import img2 from 'assets/images/awards-bg-2.png'
import img3 from 'assets/images/awards-bg-3.png'
import img4 from 'assets/images/awards-bg-4.png'
import img5 from 'assets/images/awards-bg-5.png'
import img6 from 'assets/images/awards-bg-6.png'
import img7 from 'assets/images/awards-bg-7.png'
import { msg } from '@lingui/macro'

const awardsList: Award[] = [
  {
    id: 1,
    title: msg`قارئ العام`,
    bgImage: img1,
    description: msg`الجائزة الكبرى المخصصة للطلاب والطالبات المشاركين في مسار قارئ العام، حيث يُتوّج الفائز بالجائزة بناءً على مجموع النقاط في جميع مراحل المسابقة، والتي تشمل تقييم المراجعة التي تقدم بها المشارك في التصفيات الأولية ثم المقابلات الشخصية، والملتقى الإثرائي، وانتهاءً بتقييم لجنة التحكيم في الحفل الختامي.`
  },
  {
    id: 2,
    title: msg`نص العام`,
    bgImage: img2,
    description: msg`جائزة تمنحها لجنة تحكيم الحفل الختامي لأفضل نص تأهل لمسرح الحفل الختامي ضمن المرشحين للمنافسة على "قارئ العام".`
  },
  {
    id: 3,
    title: msg`قارئ الجمهور`,
    bgImage: img3,
    description: msg`جائزة يمنحها الجمهور باستخدام التصويت لأفضل نص تأهل للحفل ضمن المرشحين للمنافسة على "قارئ العام".`
  },
  {
    id: 4,
    title: msg`القارئ الواعد`,
    bgImage: img4,
    description: msg`جائزة تُمنح لأحد المشاركين في ملتقى أقرأ الإثرائي للمرحلة الابتدائية والمتوسطة وفق مجموع نقاط المشاركين في جميع مراحل المسابقة، والتي تشمل تقييم المراجعة التي تقدم بها المشارك في التصفيات الأولية ثم المقابلات الشخصية، والملتقى الإثرائي.`
  },
  {
    id: 5,
    title: msg`مناظر العام`,
    bgImage: img5,
    description: msg`جائزة تمنحها لجنة التحكيم للمجموعة الفائزة في المناظرة الختامية، حيث يتأهل إلى الحفل الختامي ثمانية مشاركين من ملتقى أقرأ الإثرائي ضمن فريقين للمناظرة حول قضية فكرية.`
  },
  {
    id: 6,
    title: msg`سفراء القراءة`,
    bgImage: img6,
    description: msg`جائزة تُمنح للمعلمين والمعلمات والأساتذة في المدارس والجامعات بحسب عدد النقاط التي تحصلوا عليها عن طريق تسجيل طلابهم وتقدمهم في مراحل المسابقة، وتُمنح تقديرًا لجهودهم في تشجيع القراءة بين الطلاب والطالبات.`
  },
  {
    id: 7,
    title: msg`المدرسة / الجامعة القارئة`,
    bgImage: img7,
    description: msg`جائزة تُمنح لأعلى مدرسة أو جامعة بحسب عدد النقاط التي تحصلوا عليها عن طريق تسجيل طلابهم وتقدمهم في مراحل المسابقة، وتُمنح تقديرًا لجهود المدارس والجامعات في تشجيع القراءة بين الطلاب والطالبات.`
  }
]

export default awardsList
