import useLanguage from 'hooks/useLanguage'
import { useRegions } from './get-regions'
import { getLocalizedName } from '../utils'

export const useRegionOptions = (countryId?: string, countryCode?: string) => {
  const language = useLanguage() as 'ar' | 'en' // Using language from the custom hook
  const { regions, isLoading } = useRegions({ countryId, countryCode }) // Fetch regions based on countryId

  // Create options based on the fetched regions, only if they are available
  const options = regions.map((region: any) => ({
    key: String(region.id), // Region ID for key
    label: getLocalizedName(region.name, language) // Localized name based on language
  }))

  return { options, isLoading }
}
