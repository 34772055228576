import { Trans } from '@lingui/macro'

export const tourSteps = [
  {
    target: '.first-step',
    content: <Trans>يمكنك هنا نسخ الرمز الخاص بك والإطلاع النقاط التي اكتسبتها.</Trans>,
    disableBeacon: true
  },
  {
    target: '.second-step',
    content: <Trans>يمكنك من هنا مشاهدة تفاصيل النقاط والجوائز التي اكتسبتها.</Trans>,
    disableBeacon: true
  },
  {
    target: '.third-step',
    content: <Trans>يمكنك مراجعة آخر المشاركات التي تم نشرها من قبل الطلاب على هذه القسم.</Trans>,
    disableBeacon: true
  }
]
