import { z } from 'zod'
import { msg } from '@lingui/macro'

const validationSchema = z.object({
  name: z
  .string()
  .trim()
  .min(1, { message: msg`هذا الحقل مطلوب`.id }),

  email: z
    .string()
    .trim()
    .min(1, { message: msg`هذا الحقل مطلوب`.id })
    .email({
      message: msg`الرجاء إدخال عنوان بريد إلكتروني صحيح. مثال: mailto:example@email.com`.id
    }),

    account_type: z
    .string()
    .min(1, { message: msg`هذا الحقل مطلوب`.id })

})

export default validationSchema
