import ArrowDawnIcon from 'assets/icons/ArrowDawnIcon'
import ButtonVariant from 'components/button-variant/button'
import useLanguage from 'hooks/useLanguage'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

const AppActions: FC<{ to?: string }> = ({ to }) => {
  const navigate = useNavigate()
  const language = useLanguage()
  const isAr = language === 'ar'
  return (
    <ButtonVariant
      isIconOnly
      onClick={to ? () => navigate(to) : undefined}
      className="bg-transparent text-transparent focus:bg-transparent hover:bg-transparent"
    >
      <div className={isAr ? 'transform rotate-90' : 'transform -rotate-90'}>
        <ArrowDawnIcon />
      </div>
    </ButtonVariant>
  )
}

export default AppActions
