import useDocumentTitle from 'hooks/useDocumentTitle'
import notFound from 'assets/images/404.png'
import { msg, Trans } from '@lingui/macro'
import { useNavigate } from 'react-router-dom'
import ButtonVariant from 'components/button-variant/button'
import { ArrowLeft } from 'assets/icons/ArrowLeft'

interface props {
  errorMsg?: string
}

const PagePublic404: React.FC<props> = ({ errorMsg }) => {
  const navigate = useNavigate()

  const handleClick = async () => {
    navigate('/')
  }
  useDocumentTitle(msg`الصفحة غير موجودة`)

  return (
    <div className="relative w-full h-auto">
      <div className="flex flex-col items-center justify-center min-h-screen py-10 px-4 gap-6 sm:gap-8 lg:gap-10">
        <img
          src={notFound}
          alt="404"
          className="rounded-lg w-full max-w-xs sm:max-w-md lg:max-w-xl"
        />
        <h1 className="font-heading-h3-ar text-natural_icon_normal text-center text-base sm:text-lg lg:text-2xl leading-relaxed sm:leading-loose px-4 sm:px-6">
          <Trans>الصفحة التي تحاول الوصول إليها غير متاحة</Trans>
        </h1>
        <div>
          <ButtonVariant variant="secondary" endContent={<ArrowLeft />} onClick={handleClick}>
            <p className="text-sm sm:text-base lg:text-lg">
              <Trans>الذهاب للصفحة الرئيسية</Trans>
            </p>
          </ButtonVariant>
        </div>
      </div>
    </div>
  )
}

export default PagePublic404
