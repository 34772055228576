import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { CreateWorkflowResponse } from 'api/types'

interface CreateWorkflowState {
  isLoading: boolean
  error: string | null
}

const initialState: CreateWorkflowState = {
  isLoading: false,
  error: null
}

export const createWorkflow = createAsyncThunk(
  'createWorkflow',
  async (
    {
      competitionId,
      name,
      description,
      status,
      end_date
    }: {
      competitionId: number
      name: string
      description: string
      status?: 'active' | 'archived'
      end_date: string
    },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.post<CreateWorkflowResponse>(
        `/management/competition-years/${competitionId}/workflows`,
        {
          name,
          description,
          status,
          end_date
        }
      )
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const createWorkflowSlice = createSlice({
  name: 'createWorkflow',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createWorkflow.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(createWorkflow.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(createWorkflow.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default createWorkflowSlice
