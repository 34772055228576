import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button, Popover, PopoverContent, PopoverTrigger } from '@nextui-org/react'
import FilterIcon from 'assets/icons/FilterIcon'
import ButtonVariant from 'components/button-variant/button'
import SearchInput from 'components/search-field/search-field'
import { ChangeEvent, FC, ReactNode, useState } from 'react'

interface Params {
  name: string
  label?: string
  hidden?: boolean
  component: ReactNode
  action?: (value: any) => void
  className?: string
}

export interface Props {
  buttonContent?: ReactNode
  filterFields: Params[]
  onFilterApply: () => void
  onFilterReset: () => void
  filterTitle?: string
  customFilterContent?: ReactNode
  className?: string
}

export const Filter: FC<Props> = ({
  buttonContent,
  filterFields,
  onFilterApply,
  onFilterReset,
  customFilterContent,
  className
}) => {
  const { _ } = useLingui()
  const [isPopoverOpen, setPopoverOpen] = useState(false)
  const [query, setQuery] = useState('')

  const searchField = filterFields.find((field) => field.name === 'search')

  const onChangeQuery = (e: ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value
    setQuery(searchValue)

    if (searchValue === '') onFilterReset()
    else if (searchField?.action) searchField.action(searchValue)
  }

  const handleApply = () => {
    onFilterApply()
    setPopoverOpen(false)
  }

  const handleReset = () => {
    setQuery('')
    onFilterReset()
    setPopoverOpen(false)
  }

  return (
    <div className={`flex flex-row gap-x-2 md:gap-x-4 justify-between" ${className}`}>
      {searchField && (
        <SearchInput
          className="max-sm:!min-w-[75%] max-sm:!w-[75%]"
          placeholder={_(msg`بحث`)}
          value={query}
          onChange={onChangeQuery}
        />
      )}
      <Popover
        placement="bottom-end"
        showArrow={true}
        isOpen={isPopoverOpen}
        onOpenChange={setPopoverOpen}
        shouldCloseOnInteractOutside={() => false}
      >
        <PopoverTrigger>
          <Button
            className="bg-white border border-natural_stroke_normal_disabled text-black hover:border-[#2AACAD]"
            size="md"
            isIconOnly={!buttonContent || typeof buttonContent !== 'string'}
          >
            {buttonContent || <FilterIcon />}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0">
          <div className="min-w-[300px]">
            <div className="p-[14px] flex flex-col gap-2 w-full">
              {!customFilterContent &&
                filterFields.map((field, index) => {
                  if (field.hidden) return null
                  return (
                    <div key={index.toString()}>
                      {field.label && (
                        <div className="font-sub-heading-h6-ar mt-2 text-primary capitalize">
                          {field.label}
                        </div>
                      )}
                      <div className="mx-3">{field.component}</div>
                    </div>
                  )
                })}
              {customFilterContent != null && customFilterContent}
            </div>
            <div className="py-4 px-2 w-full flex gap-2 justify-end bg-[#F9FAFB] border-t border-[#E0E0E0]">
              <ButtonVariant size="sm" variant="secondary" onClick={handleApply}>
                {_(msg`تأكيد`)}
              </ButtonVariant>

              <ButtonVariant
                size="sm"
                className="border border-natural_stroke_normal_disabled text-natural_icon_normal bg-transparent hover:bg-gray-100 focus:bg-transparent rounded-full font-bold"
                onClick={handleReset}
              >
                {_(msg`الغاء`)}
              </ButtonVariant>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}
