import React from 'react'

const RecentAppCard = () => {
  return (
    <svg
      width="224"
      height="228"
      viewBox="0 0 224 228"
      xmlns="http://www.w3.org/2000/svg"
      style={{ opacity: 0.55 }}
    >
      <g clipPath="url(#clip0_6137_208996">
        <path
          d="M38.1484 -6V-1.50997C34.6532 -1.50997 33.3301 -0.540645 33.0472 1.9228H33.1414C36.448 1.9228 39 4.47672 39 7.55797C39 10.6392 36.2594 13.281 33.0472 13.281C29.835 13.281 27 10.9029 27 5.35818C27 0.428716 29.4577 -6 38.1484 -6ZM38.9972 30.4527C38.9972 33.7097 36.3509 36 32.9501 36C29.5492 36 27.0916 33.7097 27.0916 30.4527C27.0916 27.1957 29.5492 24.9055 32.9501 24.9055C36.3509 24.9055 38.9972 27.2836 38.9972 30.4527Z"
          fill="none"
          stroke="#4CE2DA"
          strokeWidth="2"
        />
        <path
          d="M45.7408 88.6346H35.5691V87.1288C35.5691 84.5179 32.2134 82.6113 28.753 80.1036C22.4603 75.7865 19 72.0736 19 64.8481C19 56.9182 25.8161 52 38.189 52C43.7459 52 46.9969 52.8016 46.9969 52.8016V63.2391C46.9969 63.2391 43.3272 62.5377 39.0264 62.5377C32.9431 62.5377 30.4278 64.3441 30.4278 67.2556C30.4278 70.167 34.3069 73.2788 38.186 75.6863C42.379 78.3974 45.7347 81.5063 45.7347 86.4274V88.6346H45.7408ZM40.2886 93.3524C44.063 93.3524 47 95.9632 47 99.6762C47 103.389 44.063 106 40.2886 106C36.5142 106 33.7866 103.289 33.7866 99.6762C33.7866 96.0634 36.5142 93.3524 40.2886 93.3524Z"
          fill="none"
          stroke="#7ECD33"
          strokeWidth="2"
        />
        <path
          d="M38.5239 122H54L27.4761 165H12L38.5239 122Z"
          fill="none"
          stroke="#44146D"
          strokeWidth="2"
        />
        <path
          d="M33.1505 181C38.5778 181 42.5 184.451 42.5 189.358C42.5 194.265 38.5778 197.715 33.1505 197.715C27.7232 197.715 23.5 194.132 23.5 189.358C23.5 184.583 27.7232 181 33.1505 181ZM23.5 224.642C23.5 219.735 27.7232 216.285 33.1505 216.285C38.5778 216.285 42.5 219.735 42.5 224.642C42.5 229.549 38.5778 233 33.1505 233C27.7232 233 23.5 229.417 23.5 224.642Z"
          fill="none"
          stroke="#7ECD33"
          strokeWidth="2"
        />
        <path
          d="M112.143 21.9657C117.284 21.9657 121 24.4499 121 27.9829C121 31.5158 117.284 34 112.143 34C107.001 34 103 31.4204 103 27.9829C103 24.5453 107.001 21.9657 112.143 21.9657ZM103.86 -17H120.287L116.861 17.4767H107.433L103.864 -17H103.86Z"
          fill="none"
          stroke="#44146D"
          strokeWidth="2"
        />
        <path
          d="M124.741 86.6346H114.569V85.1288C114.569 82.5179 111.213 80.6113 107.753 78.1036C101.46 73.7865 98 70.0736 98 62.8481C98 54.9182 104.816 50 117.189 50C122.746 50 125.997 50.8016 125.997 50.8016V61.2391C125.997 61.2391 122.327 60.5377 118.026 60.5377C111.943 60.5377 109.428 62.3441 109.428 65.2556C109.428 68.167 113.307 71.2788 117.186 73.6863C121.379 76.3974 124.735 79.5063 124.735 84.4274V86.6346H124.741ZM119.289 91.3524C123.063 91.3524 126 93.9632 126 97.6762C126 101.389 123.063 104 119.289 104C115.514 104 112.787 101.289 112.787 97.6762C112.787 94.0634 115.514 91.3524 119.289 91.3524Z"
          fill="none"
          stroke="#7ECD33"
          strokeWidth="2"
        />
        <path
          d="M112.143 158.966C117.284 158.966 121 161.45 121 164.983C121 168.516 117.284 171 112.143 171C107.001 171 103 168.42 103 164.983C103 161.545 107.001 158.966 112.143 158.966ZM103.86 120H120.287L116.861 154.477H107.433L103.864 120H103.86Z"
          fill="none"
          stroke="#44146D"
          strokeWidth="2"
        />
        <path
          d="M117.148 187V191.49C113.653 191.49 112.33 192.459 112.047 194.923H112.141C115.448 194.923 118 197.477 118 200.558C118 203.639 115.259 206.281 112.047 206.281C108.835 206.281 106 203.903 106 198.358C106 193.429 108.458 187 117.148 187ZM117.997 223.453C117.997 226.71 115.351 229 111.95 229C108.549 229 106.092 226.71 106.092 223.453C106.092 220.196 108.549 217.905 111.95 217.905C115.351 217.905 117.997 220.284 117.997 223.453Z"
          fill="none"
          stroke="#4CE2DA"
          strokeWidth="2"
        />
        <path
          d="M196.143 42.9657C201.284 42.9657 205 45.4499 205 48.9829C205 52.5158 201.284 55 196.143 55C191.001 55 187 52.4204 187 48.9829C187 45.5453 191.001 42.9657 196.143 42.9657ZM187.86 4H204.287L200.861 38.4767H191.433L187.864 4H187.86Z"
          fill="none"
          stroke="#44146D"
          strokeWidth="2"
        />
        <path
          d="M201.148 71V75.49C197.653 75.49 196.33 76.4594 196.047 78.9228H196.141C199.448 78.9228 202 81.4767 202 84.558C202 87.6392 199.259 90.281 196.047 90.281C192.835 90.281 190 87.9029 190 82.3582C190 77.4287 192.458 71 201.148 71ZM201.997 107.453C201.997 110.71 199.351 113 195.95 113C192.549 113 190.092 110.71 190.092 107.453C190.092 104.196 192.549 101.905 195.95 101.905C199.351 101.905 201.997 104.284 201.997 107.453Z"
          fill="none"
          stroke="#4CE2DA"
          strokeWidth="2"
        />
        <path
          d="M208.741 165.635H198.569V164.129C198.569 161.518 195.213 159.611 191.753 157.104C185.46 152.787 182 149.074 182 141.848C182 133.918 188.816 129 201.189 129C206.746 129 209.997 129.802 209.997 129.802V140.239C209.997 140.239 206.327 139.538 202.026 139.538C195.943 139.538 193.428 141.344 193.428 144.256C193.428 147.167 197.307 150.279 201.186 152.686C205.379 155.397 208.735 158.506 208.735 163.427V165.635H208.741ZM203.289 170.352C207.063 170.352 210 172.963 210 176.676C210 180.389 207.063 183 203.289 183C199.514 183 196.787 180.289 196.787 176.676C196.787 173.063 199.514 170.352 203.289 170.352Z"
          fill="none"
          stroke="#7ECD33"
          strokeWidth="2"
        />
        <path
          d="M201.524 199H217L190.476 242H175L201.524 199Z"
          fill="none"
          stroke="#44146D"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_6137_208996">
          <rect width="224" height="670" fill="white" transform="translate(0 -221)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default RecentAppCard
