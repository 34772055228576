import useLanguage from 'hooks/useLanguage'
import { useSchools } from './get-schools'
import { getLocalizedName } from '../utils'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

interface UseSchoolOptionsProps {
  countryId?: string
  regionId?: string
  countryCode?: string
}

export const useSchoolOptions = ({ countryId, regionId, countryCode }: UseSchoolOptionsProps) => {
  const language = useLanguage() as 'ar' | 'en'
  const { i18n } = useLingui()
  const { schools, isLoading } = useSchools({ countryId, regionId, countryCode })

  const options = schools.map((school: any) => ({
    key: String(school.id),
    label: getLocalizedName(school.name, language)
  }))
  const otherOption = {
    key: 'other',
    label: i18n._(msg`المدرسة غير مدرجة في القائمة`)
  }

  return {
    options: [...options],
    isLoading
  }
}
