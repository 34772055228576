import React from 'react'
import { CoordinatorInfoSchemaType } from '../ReviewRequestSchemaType2'
import Typography from 'components/typography/typography'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import Form from 'components/FormStructure/Form'
import TextInput from 'components/hook-form/text-input'

interface CoordinatorProps {
  coordinatorInfo?: CoordinatorInfoSchemaType
}

const CoordinatorDetail: React.FC<CoordinatorProps> = ({ coordinatorInfo }) => {
  const { _, i18n } = useLingui()
  console.log(coordinatorInfo?.name)
  return (
    <div className="flex flex-col gap-5 w-full">
      <div className="flex flex-col gap-2 items-start">
        <Typography size="xl" weight="bold">
          {_(msg`البيانات الشخصية للمنسق`)}
        </Typography>
      </div>
      <Form schema={{} as any} defaultValues={undefined} onSubmit={undefined as any}>
        <div className="flex flex-col md:flex-row gap-4 w-full items-center">
          <div className="w-full md:w-1/2">
            <TextInput
              name="name"
              size="md"
              label={_(msg`الإسم`)}
              isDisabled={true}
              value={coordinatorInfo?.name}
            />
          </div>
          <div className="w-full md:w-1/2">
            <TextInput
              name="email"
              size="md"
              label={_(msg`البريد الإلكتروني`)}
              isDisabled={true}
              value={coordinatorInfo?.email}
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-4 w-full items-center">
          <div className="w-full md:w-1/2">
            <TextInput
              name="phone_number"
              width="100%"
              value={coordinatorInfo?.phone}
              label={_(msg`رقم الجوال`)}
              isDisabled={true}
              type="phone-number"
            />
          </div>
          <div className="w-full md:w-1/2">
            <TextInput
              name="gender"
              size="md"
              label={_(msg`النوع`)}
              isDisabled={true}
              value={coordinatorInfo?.gender == 'female' ? _(msg`أنثى`) : _(msg`ذكر`)}
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-4 w-full items-center">
          <div className="w-full md:w-1/2">
            <TextInput
              name="country"
              value={
                i18n.locale == 'ar'
                  ? coordinatorInfo?.country.name.ar
                  : coordinatorInfo?.country.name.en
              }
              label={_(msg`الدولة`)}
              isDisabled={true}
            />
          </div>
          <div className="w-full md:w-1/2">
            <TextInput
              name="nationlity"
              size="md"
              label={_(msg`الجنسية`)}
              isDisabled={true}
              value={
                i18n.locale == 'ar'
                  ? coordinatorInfo?.nationality.name.ar
                  : coordinatorInfo?.nationality.name.en
              }
            />
          </div>
        </div>
      </Form>
    </div>
  )
}

export default CoordinatorDetail
