import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setStudentsNumberTo } from 'store/slices/activateRequestsSlice'
import './NumberOfStudentsToFilter.css'
import { Input } from '@nextui-org/react'
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'
const NumberOfStudentsToFilter: React.FC = () => {
  const { _ } = useLingui()
  const dispatch = useDispatch()
  const { studentsNumberTo } = useSelector((state: RootState) => state.activateRequests)
  const [numberTo, setNumberTo] = useState<number | null>(studentsNumberTo)
  const handleNumberToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? parseInt(e.target.value, 10) : null
    setNumberTo(value)
    // dispatch(setStudentsNumberTo(value))
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(setStudentsNumberTo(numberTo))
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [dispatch, numberTo])

  return (
    <div>
      <p className="text-primary pb-2">{_(msg`عدد طلاب الجهة التعليمية (إلى)`)}</p>
      <Input
        variant="bordered"
        color="primary"
        type="number"
        placeholder={_(msg`عدد طلاب الجهة التعليمية (إلى)`)}
        // value={studentsNumberFrom || ''}
        value={(numberTo ?? '') as string}
        onChange={handleNumberToChange}
      />
    </div>
  )
}

export default NumberOfStudentsToFilter
