import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { ApplicationResponse } from 'api/types'
import { cleanObject } from 'utils/cleanObject'
import { yyyy_mm_dd } from 'utils/time'
import { Application } from './applicationsSlice'

interface IInit {
  workflowApps: Application[]
  isLoading: boolean
  error: string | null
  competition_year_id: string | undefined
  page: number
  per_page: number
  total_pages: number
}

const initialState: IInit = {
  workflowApps: [],
  isLoading: false,
  error: null,
  competition_year_id: undefined,
  page: 1,
  per_page: 10,
  total_pages: 0
}

export interface ISearchWorkflowApp {
  workflow_id: number
  competition_year_id?: number
  q?: string
  submission_at_from?: string
  submission_at_to?: string
  workflow_assigned_at_from?: string
  workflow_assigned_at_to?: string
  workflow_assigned_by?: string
  workflow_assign_type?: 'admin' | 'system'
  'statuses[]'?: string[]
  'countries[]'?: string[]
  'regions[]'?: string[]
  'education_levels[]'?: string[]
  'education_administrations[]'?: string[]
  page?: number
  per_page?: number
}

export const fetchWorkflowApps = createAsyncThunk(
  'workflow-apps/fetch',
  async (params: ISearchWorkflowApp) => {
    const { submission_at_from, submission_at_to } = params
    const { workflow_assigned_at_from, workflow_assigned_at_to } = params
    const cleanedParams = cleanObject({
      ...params,
      per_page: 10,
      workflow_assigned_at_to: workflow_assigned_at_to ? yyyy_mm_dd(workflow_assigned_at_to) : '',
      submission_at_from: submission_at_from ? yyyy_mm_dd(submission_at_from) : '',
      submission_at_to: submission_at_to ? yyyy_mm_dd(submission_at_to) : '',
      workflow_assigned_at_from: workflow_assigned_at_from
        ? yyyy_mm_dd(workflow_assigned_at_from)
        : ''
    })

    const response = await axiosInstance.get<ApplicationResponse>(
      `/management/applications/workflows`,
      {
        params: cleanedParams,
        paramsSerializer: (params) => {
          return Object.entries(params)
            .map(([key, value]) =>
              Array.isArray(value)
                ? value.map((v) => `${key}=${encodeURIComponent(v)}`).join('&')
                : `${key}=${encodeURIComponent(value)}`
            )
            .join('&')
        }
      }
    )

    return response.data
  }
)

const workflowAppsSlice = createSlice({
  name: 'workflow-apps',
  initialState,
  reducers: {
    setWorkflowApps: (state, action: PayloadAction<Application[]>) => {
      state.workflowApps = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkflowApps.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchWorkflowApps.fulfilled, (state, action) => {
        if (!action.payload) return
        state.isLoading = false
        state.workflowApps = action.payload.data
        state.total_pages = action.payload.meta?.pagination?.total_pages
        state.page = action.payload.meta?.pagination?.current_page
        state.per_page = action.payload.meta?.pagination?.per_page
      })
      .addCase(fetchWorkflowApps.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export const { setWorkflowApps } = workflowAppsSlice.actions

export default workflowAppsSlice
