import React from 'react'
import ChartProgressBar from './ProgressBar'

interface UserProgress {
  userName: string
  region?: string
  percentage: number
  educationAdministration?: string
  total_applications: number
}

interface ChartCompProps {
  data: UserProgress[]
}

const getNumberOfLines = () => {
  if (window.innerWidth >= 1024) {
    // lg
    return 12
  } else if (window.innerWidth >= 768) {
    // md
    return 6
  } else {
    // sm
    return 4
  }
}
const ChartComp: React.FC<ChartCompProps> = ({ data }) => {
  const [numLines, setNumLines] = React.useState(getNumberOfLines())

  React.useEffect(() => {
    const handleResize = () => {
      setNumLines(getNumberOfLines())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className="flex flex-row justify-center ">
      <div className="flex flex-col space-y-6 px-4">
        {data.map((user, index) => (
          <span key={index} className="text-nowrap  text-right">
            {user.region ?? user.educationAdministration}
          </span>
        ))}
      </div>
      <div className="relative flex flex-col w-9/12	 h-64 ">
        <div className="relative flex flex-row justify-between items-center w-full h-full ">
          {[...Array(numLines)].map((_, index) => (
            <div key={index} className="h-full border-r border-gray-200" style={{ width: '1px' }} />
          ))}
        </div>

        <div className="absolute flex flex-col w-full space-y-6 p-4 ">
          {data.map((user) => (
            <ChartProgressBar user={user}></ChartProgressBar>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ChartComp
