import { formatDateTime } from 'utils/time'
import Badge from 'components/badge/Badge'
import { useLingui } from '@lingui/react'
import OptionButton from './OptionButton';

interface props {
  item: any
  columnKey: string | number
  popoverVisible: number | null
  setPopoverVisible: (visible: number | null) => void
  handleOptionClick: (option: string, item: any) => void
}

const RenderCell: React.FC<props> = ({ item, columnKey, popoverVisible, setPopoverVisible, handleOptionClick }) => {
  
  const { i18n } = useLingui()

  const renderStatusChip = (status: string, variant: any) => {
    return <Badge variant={variant} placeholder={status} shape="rectangular" />
  }

  if (columnKey === 'student_personal_data') {
    return (
      <div className="flex flex-col gap-1">
        <span>{item.name}</span>
        <span className="text-primary_lv2_text_normal">{item.email}</span>
        <span
          className={`text-primary_lv2_text_normal ${i18n.locale === 'en' ? 'text-left' : 'text-right'}`}
        >
          {item.phone}
        </span>
      </div>
    )

  } else if (columnKey === 'registration_date') {
    return <span>{formatDateTime(item.created_at, i18n.locale as any)}</span>

  } else if (columnKey === 'account_status') {
    const activeLabel = i18n.locale === 'en' ? 'Active' : 'فعال'
    const inactiveLabel = i18n.locale === 'en' ? 'Not Active' : 'غير فعال'

    return item.active
      ? renderStatusChip(activeLabel, 'success')
      : renderStatusChip(inactiveLabel, 'danger')

  } else if (columnKey === 'email_verified') {
    const yesLabel = i18n.locale === 'en' ? 'Yes' : 'نعم'
    const noLabel = i18n.locale === 'en' ? 'No' : 'لا'

    return <span>{item.verified ? yesLabel : noLabel}</span>

  } else if (columnKey === 'country') {
    return item.country?.name[i18n.locale as 'en' | 'ar']

  } else if (columnKey === 'added_by') {
    return item.created_by?.name

} else if (columnKey === 'options') {
    return (
      <OptionButton
        item={item}
        index={item.id}
        popoverVisible={popoverVisible}
        setPopoverVisible={setPopoverVisible}
        handleOptionClick={handleOptionClick}
      />
    );
  }
};

export default RenderCell
