import React from 'react'
import ReportFiltersFormSchema from './schema'
import ReportFiltersForm from './IthraaFilterForm'
import Form from 'components/FormStructure/Form'
import { fetchIthraaApplicationStatistics } from 'store/slices/ithraaReports'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'

export default function FilterFormIthraa() {
  const { competitionYear } = useSelector((state: RootState) => state.competitionYearNavbar)
  const dispatch = useDispatch<AppDispatch>()

  const defaultValues = {
    country: '',
    region: '',
    education_stage: '',
    education_administration: '',
    university: '',
    school: '',
    participation_start_date: null,
    participation_end_date: null,
    participation_group: '',
    stage_name: '',
    age: 0,
    student_name: '',
    student_email: '',
    participation_number: '',
    gender: ''
  }
  const onSubmit = async (data: any) => {
    const params = {
      submission_at_from: data.participation_start_date,
      submission_at_to: data.participation_end_date,
      workflow_name: data.participation_group,
      competition_year_id: competitionYear?.id,
      page: 1,
      ...data
    }

    dispatch(fetchIthraaApplicationStatistics(params))
  }
  return (
    <div className="relative min-h-screen overflow-y-auto scrollbar-thin scrollbar-thumb-primary scrollbar-track-transparent">
      <Form
        defaultValues={defaultValues as any}
        schema={ReportFiltersFormSchema}
        onSubmit={onSubmit}
      >
        <ReportFiltersForm></ReportFiltersForm>
      </Form>
    </div>
  )
}
