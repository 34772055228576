import useLanguage from 'hooks/useLanguage'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchRegions } from 'store/slices/regionsSlice'
import { getLocalizedName } from 'utils/getLocalizedName'
import { Region } from './types'

interface Props {
  countryId?: string | string[]
}
const useRegions = ({ countryId }: Props = {}) => {
  const dispatch = useDispatch<AppDispatch>()
  const { regions, isLoading, error } = useSelector((state: RootState) => state.regions)

  useEffect(() => {
    if (countryId) {
      dispatch(fetchRegions({ countryId }))
    }
  }, [dispatch, countryId])

  return { regions, isLoading, error }
}

export const useRegionOptions = (
  countryId?: string | string[]
): { options: { key: string; label: string }[]; isLoading: boolean; error: string | null } => {
  const language = useLanguage() as 'ar' | 'en'
  const { regions, isLoading, error } = useRegions({ countryId })

  const options = useMemo(() => {
    if (regions.length) {
      return regions.map((region: Region) => {
        const localizedName = getLocalizedName(region.name, language)
        const fallbackName = language === 'ar' ? region.name.en : region.name.ar

        return {
          key: String(region.id),
          label: localizedName || fallbackName || 'Unknown Region'
        }
      })
    } else {
      return [
        // {
        //   key: '',
        //   label: language === 'ar' ? 'لا يوجد بيانات للاختيار' : 'No options available'
        // }
      ]
    }
  }, [regions, language])

  return { options, isLoading, error }
}
