import React from 'react'

interface StarIconProps {
  fill?: string
}

const StarIcon: React.FC<StarIconProps> = ({ fill = "#F9FAFB" }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5107_226568)">
        <path
          d="M12.4989 18.26L5.44594 22.208L7.02094 14.28L1.08594 8.792L9.11294 7.84L12.4989 0.5L15.8849 7.84L23.9119 8.792L17.9769 14.28L19.5519 22.208L12.4989 18.26ZM12.4989 15.968L16.7459 18.345L15.7969 13.572L19.3699 10.267L14.5369 9.694L12.4989 5.275L10.4609 9.695L5.62794 10.267L9.20094 13.572L8.25194 18.345L12.4989 15.968Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_5107_226568">
          <rect width="24" height="24" fill={fill} transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default StarIcon
