import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { Workflow, WorkflowResponse } from 'api/types'
import { cleanObject } from 'utils/cleanObject'
import { yyyy_mm_dd } from 'utils/time'

export interface ISearchWorkFlow {
  search?: string
  status?: string
  end_date?: string
  competitionId: number
  page?: number
  per_page?: number
}

interface WorkflowState {
  workflows: Workflow[] | null
  isLoading: boolean
  error: string | null
  page: number
  per_page: number
  total_pages: number
  total_items: number
}

const initialState: WorkflowState = {
  workflows: null,
  isLoading: false,
  error: null,
  page: 1,
  per_page: 10,
  total_pages: 1,
  total_items: 0
}

export const workflowErrors: { [key: string]: MessageDescriptor } = {
  FIELD_UNIQUE: msg`يجب أن يكون اسم سير المشاركة فريدًا`,
  WORKFLOW_END_DATE_MUST_BE_SAME: msg`يجب ان تكون كل سير المشاركة بنفس تاريخ الانتهاء`,
  WORKFLOW_END_DATE_LESS_THAN_COMPETITION_YEAR_START_DATE: msg`يجب أن يكون تاريخ الانتهاء ضمن فترة سنة المسابقة`,
  WORKFLOW_END_DATE_OUTSIDE_COMPETITION_YEAR_PERIOD: msg`يجب أن يكون تاريخ الانتهاء ضمن فترة سنة المسابقة`
}

export const fetchWorkflows = createAsyncThunk(
  'workflow/fetch',
  async (params: ISearchWorkFlow) => {
    const { competitionId, end_date } = params
    const cleanedParams = cleanObject({ ...params, end_date: end_date ? yyyy_mm_dd(end_date) : '' })

    const response = await axiosInstance.get<WorkflowResponse>(
      `/management/competition-years/${competitionId}/workflows`,
      { params: { ...cleanedParams } }
    )
    return response.data
  }
)

const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    setWorkflows: (state, action: PayloadAction<Workflow[]>) => {
      state.workflows = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setPerPage: (state, action: PayloadAction<number>) => {
      state.per_page = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkflows.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchWorkflows.fulfilled, (state, action) => {
        state.isLoading = false
        state.workflows = action.payload.data
        state.page = action.payload.meta.pagination.current_page
        state.per_page = action.payload.meta.pagination.per_page
        state.total_pages = action.payload.meta.pagination.total_pages
        state.total_items = action.payload.meta.pagination.total
      })
      .addCase(fetchWorkflows.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export const { setWorkflows, setPage, setPerPage } = workflowSlice.actions

export default workflowSlice
