import useLanguage from 'hooks/useLanguage'
import { useEducationAdministrations } from './get-education-adminstrations'
import { getLocalizedName } from '../utils'

interface UseEducationAdministrationOptionsProps {
  countryId?: string
  regionId?: string // Array of region IDs
  countryCode?: string
}

export const useEducationAdministrationOptions = ({
  countryId,
  regionId,
  countryCode
}: UseEducationAdministrationOptionsProps = {}) => {
  const language = useLanguage() as 'ar' | 'en' // Using language from the custom hook
  const { educationAdministrations, isLoading } = useEducationAdministrations({
    countryId,
    regionId,
    countryCode
  }) // Fetch based on countryId and regions

  // Create options for the select field
  const options = educationAdministrations.map((school: any) => ({
    key: String(school.id),
    label: getLocalizedName(school.name, language)
  }))

  return { options, isLoading }
}
