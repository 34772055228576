import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import AppTabs from 'components/apps/Tabs'
import CheckboxVariant from 'components/checkbox/checkbox'
import DateField from 'components/date-picker-localized/date-picker'
import FilterApps from 'components/Filter'
import { getRequestStatusLabel } from 'constants/requestStatus'
import useLanguage from 'hooks/useLanguage'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchApps, ISearchApp } from 'store/slices/applicationsSlice'

const DEFAULT_PAGE = 1
const PER_PAGE = 10

const Applications = () => {
  const dispatch = useDispatch<AppDispatch>()
  const language = useLanguage()
  const { _ } = useLingui()
  const queryRef = useRef<Partial<ISearchApp>>({})
  const [status, setStatus] = useState<number | undefined>()
  const [submission, setSubmission] = useState<{ from?: string; to?: string }>({
    from: undefined,
    to: undefined
  })

  const { account_type } = useSelector((s: RootState) => s.me.me?.data! || {})
  const isCoordinator = account_type === 'coordinator'
  const { competitionYear } = useSelector((state: RootState) => state.competitionYearNavbar)
  const { apps, isLoading, page, per_page, total_pages } = useSelector(
    (state: RootState) => state.appsStates
  )

  const onSearch = (query?: Partial<ISearchApp>) => {
    queryRef.current = {
      ...queryRef.current,
      ...query,
      submission_at_from: submission.from,
      submission_at_to: submission.to,
      status
    }

    dispatch(
      fetchApps({
        user: account_type || '',
        competition_year_id: competitionYear?.id.toString(),
        page,
        per_page,
        ...queryRef.current
      })
    )
  }

  const filterReset = async () => {
    queryRef.current = {}
    setStatus(undefined)
    setSubmission({ from: undefined, to: undefined })
    dispatch(
      fetchApps({
        search: '',
        status: undefined,
        submission_at_from: undefined,
        submission_at_to: undefined,
        user: account_type || '',
        competition_year_id: competitionYear?.id.toString(),
        page: DEFAULT_PAGE,
        per_page: PER_PAGE
      })
    )
  }

  const fields = [
    {
      name: 'search',
      hidden: true,
      component: <></>,
      action: (value: string) => onSearch({ search: value })
    },
    // {
    //   name: 'draft',
    //   label: _(msg`الحالة`),
    //   component: (
    //     <CheckboxVariant
    //       label={getRequestStatusLabel('draft', language)}
    //       checked={status === 1}
    //       onChange={() => setStatus(status === 1 ? undefined : 1)}
    //     />
    //   )
    // },
    {
      name: 'pending',
      component: (
        <CheckboxVariant
          label={getRequestStatusLabel('pending', language)}
          checked={status === 2}
          onChange={() => setStatus(status === 2 ? undefined : 2)}
        />
      )
    },
    {
      name: 'approved',
      component: (
        <CheckboxVariant
          label={getRequestStatusLabel('approved', language)}
          checked={status === 3}
          onChange={() => setStatus(status === 3 ? undefined : 3)}
        />
      )
    },
    {
      name: 'rejected',
      component: (
        <CheckboxVariant
          label={getRequestStatusLabel('rejected', language)}
          checked={status === 4}
          onChange={() => setStatus(status === 4 ? undefined : 4)}
        />
      )
    },
    {
      name: 'start_date',
      label: _(msg`تاريخ تقديم الطلب`),
      component: (
        <DateField
          placeholder={_(msg`من`)}
          className="mt-3"
          value={submission.from || null}
          onChange={(date) => setSubmission((s) => ({ ...s, from: date?.toString() }))}
        />
      )
    },
    {
      name: 'end_date',
      component: (
        <DateField
          placeholder={_(msg`إلى`)}
          className="mt-1"
          value={submission.to || null}
          onChange={(date) => setSubmission((s) => ({ ...s, to: date?.toString() }))}
        />
      )
    }
  ]

  useEffect(() => {
    dispatch(
      fetchApps({
        user: account_type || '',
        competition_year_id: competitionYear?.id.toString(),
        page: DEFAULT_PAGE,
        per_page: PER_PAGE,
        ...queryRef.current
      })
    )
  }, [dispatch, account_type, competitionYear])

  return (
    <div className="flex flex-col w-full">
      <div className="sm:max-w-sm">
        <FilterApps
          className="justify-start"
          filterFields={fields}
          onFilterApply={onSearch}
          onFilterReset={filterReset}
        />
      </div>

      <div className="w-full md:flex flex-col gap-y-3 py-9">
        <AppTabs
          apps={apps}
          page={page}
          per_page={per_page}
          isLoading={isLoading}
          total_pages={total_pages}
          isCoordinator={isCoordinator}
          onPageChange={(page) => onSearch({ page })}
        />
      </div>
    </div>
  )
}

export default Applications
