import React from 'react'
import StepComponent, { Step } from './Step'

interface StepperProps {
  steps: Step[]
  currentStep?: number | null
  setCurrentStep?: (step: number) => void
  onSubmit?: () => void
  // stepCompletion?: boolean[]
  stepCompletion?: { isCompleted: boolean, errors: number }[]
  theme?: 'form' | 'awards'
}

const Stepper: React.FC<StepperProps> = ({
  steps,
  currentStep = 0,
  setCurrentStep,
  stepCompletion = [],
  theme,
}) => {

const bg = theme === 'form' ? 'bg-background_creamy' : 'bg-white'

  return (
    <div className="w-full">
      <div className={`flex overflow-x-auto justify-start md:justify-center items-start py-5 gap-1 rounded-[20px] shadow-lg w-full px-8 md:px-16 ${bg}`}>
        {steps.map((step, index) => (
          <StepComponent
            key={index}
            index={index}
            currentStep={currentStep}
            step={step}
            numberOfSteps={steps.length}
            isErrorFree={stepCompletion[index]}
            theme={theme}
          />
        ))}
      </div>
    </div>
  )
}

export default Stepper