import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'api';

interface OnboardingState {
  isLoading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: OnboardingState = {
  isLoading: false,
  error: null,
  success: false,
};

export const updateOnboardingStatus = createAsyncThunk(
  'onboarding/updateStatus',
  async (onboarding: boolean, thunkAPI) => {
    try {
      const response = await axiosInstance.put('/auth/profile/onboarding', {
        onboarding: onboarding
      });
      return response.data;
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateOnboardingStatus.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(updateOnboardingStatus.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(updateOnboardingStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? '';
        state.success = false;
      });
  }
});

export default onboardingSlice
