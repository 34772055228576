import * as React from 'react'

interface SvgComponentProps extends React.SVGProps<SVGSVGElement> {}

const ActivateRequestsIcon: React.FC<SvgComponentProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#3F3F46"
      d="M11.665 11.875v1.742A5.002 5.002 0 0 0 5 18.332H3.332a6.667 6.667 0 0 1 8.333-6.458v.001ZM10 10.832c-2.763 0-5-2.237-5-5 0-2.762 2.237-5 5-5 2.762 0 5 2.238 5 5 0 2.763-2.238 5-5 5Zm0-1.667a3.332 3.332 0 0 0 3.333-3.333 3.332 3.332 0 1 0-3.333 3.333Zm6.666 5h2.5v1.667h-2.5v2.917l-4.166-3.75 4.166-3.75v2.916Z"
    />
  </svg>
)

export default ActivateRequestsIcon
