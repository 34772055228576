import React from 'react'
import { useNavigate } from 'react-router-dom'
import Stepper from 'components/stepper/Stepper'
import { steps } from './components/Steps'
import CardLayout from 'components/card/card-layout'
import { msg, Trans } from '@lingui/macro'
import RecentApplicationCard from './components/RecentApplicationCard'
import Loader from 'components/loader/Loader'
import Alert from 'components/alert/alert'
import { useStudentActiveApplication } from 'hooks/useStudentActiveApplication'
import dayjs from 'dayjs'
import { useLingui } from '@lingui/react'

const StudentAccount = () => {
  const { _ } = useLingui()
  const { currentCompetYear, upcomingCompetYear, apps, error, loading } =
    useStudentActiveApplication()

  const navigate = useNavigate()
  const handeNewApplClick = () => {
    navigate('/app/applications/submit-application')
  }

  const renderContent = () => {
    if (loading) {
      return <Loader />
    }

    if (error) {
      return <Alert mainText={error} type="error" />
    }

    const isValidDate = currentCompetYear && dayjs().isBefore(dayjs(currentCompetYear.close_at))

    if (currentCompetYear && currentCompetYear.status === 'started' && isValidDate) {
      if (apps && apps.length > 0) {
        return (
          <>
            <h2 className="font-heading-h4-ar text-natural_lv1_text_normal capitalize">
              <Trans>نماذج المشاركة</Trans>
            </h2>
            <RecentApplicationCard competitionYear={currentCompetYear} application={apps[0]} />
          </>
        )
      } else {
        const msgText = _(msg`بدأ التسجيل واستقبال المشاركات لمسابقة هذا العام.`)
        const mainText = msgText + ' ' + currentCompetYear.name
        return (
          <Alert
            mainText={mainText}
            type="success"
            buttonText="سجل مشاركتك"
            onButtonClick={handeNewApplClick}
          />
        )
      }
    }

    if (
      currentCompetYear &&
      // (currentCompetYear.status !== 'started' || !isValidDate) &&
      !isValidDate && upcomingCompetYear
    ) {
      const msgText = _(msg`تم إغلاق استقبال المشاركات لهذا العام ، نراكم في النسخة القادمة والتي تبدأ في تاريخ`)
      const mainText = msgText + ' ' + upcomingCompetYear.start_date
      return <Alert mainText={mainText} type="info" />
    }
    return null
  }

  return (
    <div className="size-full flex flex-col">
      {renderContent()}
      <CardLayout isBordered={false} isFullWidth paddig="p-6">
        <div className="flex flex-col gap-6 pb-10 pt-4">
          <h4 className="font-heading-h6-ar text-natural_icon_normal capitalize">
            <Trans>مراحل تقديم المشاركة </Trans>
          </h4>
          <Stepper steps={steps} theme="awards" />
        </div>
      </CardLayout>
    </div>
  )
}

export default StudentAccount
