import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import DataTable from 'components/data-table/data-table'
import { FC, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import ApplicationsFilters from './components/ApplicationsFilters'
import AppStatus from 'components/apps/Status'
import { fetchApps, setPage, ISearchApp } from 'store/slices/applicationsSlice'
import { ApplicationStatus } from 'store/slices/applicationsSlice'
import { formatDateTime } from 'utils/time'
import AppActions from 'components/apps/Actions'

const DEFAULT_PAGE = 1
const PER_PAGE = 10

const ApplicationList: FC = () => {
  const { _ } = useLingui()
  const direction = useSelector((state: RootState) => state.layout.direction)

  const dispatch = useDispatch<AppDispatch>()
  // const navigate = useNavigate()

  const { apps, isLoading, page, per_page, total_pages } = useSelector(
    (state: RootState) => state.appsStates
  )

  const [dateRange, setDateRange] = useState<{ from: string; to: string }>({ from: '', to: '' })
  const [selectedYear, setSelectedYear] = useState<string>('')
  const [status, setStatus] = useState<number | undefined>()
  const [searchValue, setSearchValue] = useState<string>('')
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue)

  const [key, setKey] = useState<number>(0)

  const queryRef = useRef<Partial<ISearchApp>>({
    user: 'student',
    page: DEFAULT_PAGE,
    per_page: PER_PAGE
  })

  useEffect(() => {
    dispatch(
      fetchApps({
        user: 'student',
        page: DEFAULT_PAGE,
        per_page: PER_PAGE
      })
    )
  }, [dispatch, page, per_page])

  useEffect(() => {
    setKey((prevKey) => prevKey + 1)
  }, [direction])
  
  const columns = [
    { key: 'number', label: _(msg`رقم المشاركة`) },
    { key: 'created_at', label: _(msg`التاريخ`) },
    { key: 'status', label: _(msg`الحالة`) },
    { key: 'competition_year_id', label: _(msg`سنة المسابقة`) },
    { key: 'view', label: _(msg`عرض التفاصيل`) }
  ]

  const renderStatusChip = (status: any) => {
    return <AppStatus status={status.name.en.toLocaleLowerCase() as ApplicationStatus} />
  }

  const handlePageChange = (newPage: number) => {
    dispatch(setPage(newPage))
    dispatch(
      fetchApps({
        ...queryRef.current,
        user: 'student',
        page: newPage
      })
    )
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue)
    }, 1000)
  
    return () => {
      clearTimeout(handler)
    }
  }, [searchValue])

  useEffect(() => {
    queryRef.current = {
      ...queryRef.current,
      search: searchValue || undefined,
      page: 1
    }

    dispatch(
      fetchApps({
        ...queryRef.current,
        user: 'student'
      })
    )
  }, [debouncedSearchValue])

  const onFilter = () => {
    queryRef.current = {
      ...queryRef.current,
      user: 'student',
      status: status || undefined,
      submission_at_from: dateRange.from || undefined,
      submission_at_to: dateRange.to || undefined,
      competition_year_id: selectedYear || undefined,
      search: searchValue || undefined,
      page: 1
    }

    dispatch(
      fetchApps({
        ...queryRef.current,
        user: 'student'
      })
    )
  }
  
  const onCancel = () => {
    setStatus(undefined)
    setDateRange({ from: '', to: '' })
    setSelectedYear('')
    setSearchValue('')
  
    queryRef.current = {
      user: 'student', 
      page: 1,
      per_page,
    }
  
    dispatch(fetchApps({
      ...queryRef.current, 
      user: 'student' 
    }))
  }

  return (
    <div className="flex flex-col w-full gap-4">

      <ApplicationsFilters
        dateRange={dateRange}
        setDateRange={setDateRange}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        status={status}
        setStatus={setStatus}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        onFilter={onFilter}
        onCancel={onCancel}
      />
     
      <DataTable
        key={key}
        columns={columns}
        data={apps  || []}
        isLoading={isLoading}
        page={page}
        totalPages={total_pages}
        onPageChange={handlePageChange}
        renderCell={(item, columnKey) => {
          if (columnKey === 'created_at') {
            return formatDateTime(item.created_at, direction === 'rtl' ? 'ar' : 'en')
          } else if (columnKey === 'competition_year_id') {
            return item.competition_year?.name
          } else if (columnKey === 'status') {
            return renderStatusChip(item.status)
          } else if (columnKey === 'view') {
            return <AppActions to={`/app/applications/application-details/${item.id}`}/>
          } else {
            return item[columnKey]
          }
        }}
      />
    </div>
  )
}

export default ApplicationList
