import React from 'react'
import FilterLabel from './FilterLabel'
import DateField from 'components/date-picker-localized/date-picker'
import dayjs from 'dayjs'

interface Props {
  label: string
  selectedDate?: string | null
  onDateChange: (date: string) => void
}

const DateFilter: React.FC<Props> = ({ label, selectedDate, onDateChange }) => {
  const handleDateChange = (date: Date | null) => {
    const dateISO = date ? date.toISOString() : ''
    onDateChange(dateISO)
  }

  return (
    <div className="flex flex-col gap-0.5 w-full">
      <FilterLabel label={label} />
      <DateField
        value={selectedDate ? dayjs(selectedDate.split('T')[0]).toISOString() : null}
        onChange={handleDateChange}
      />
    </div>
  )
}

export default DateFilter
