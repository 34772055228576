import { yyyy_mm_dd } from 'utils/time'

export const filterEmptyFields = (data: any) => {
  const filteredData = { ...data }

  // Application 
  if (!filteredData.application_body?.reading_story) delete filteredData.application_body.reading_story
  if (!filteredData.application_body?.book_title) delete filteredData.application_body.book_title
  if (!filteredData.application_body?.author_name) delete filteredData.application_body.author_name
  if (!filteredData.application_body?.reason_for_choice) delete filteredData.application_body.reason_for_choice
  if (!filteredData.application_body?.book_review) delete filteredData.application_body.book_review
  if (!filteredData.application_body?.teacher_code) delete filteredData.application_body.teacher_code
  if (!filteredData.application_body?.terms_agreed) delete filteredData.application_body.terms_agreed

  // Location
  if (!filteredData.location_info?.region) {
    delete filteredData.location_info.region
  } else {
    filteredData.location_info.region = Number(filteredData.location_info.region)
  }

  if (!filteredData.location_info?.country) {
    delete filteredData.location_info.country
  } else {
    filteredData.location_info.country = Number(filteredData.location_info.country)
  }

  // Personal
  if (!filteredData.personal_info?.first_name) delete filteredData.personal_info.first_name
  if (!filteredData.personal_info?.second_name) delete filteredData.personal_info.second_name
  if (!filteredData.personal_info?.last_name) delete filteredData.personal_info.last_name
  // if (!filteredData.personal_info?.date_of_birth) delete filteredData.personal_info.date_of_birth
  if (!filteredData.personal_info?.date_of_birth) {
    delete filteredData.personal_info.date_of_birth;
  } else {
    const dob = filteredData.personal_info.date_of_birth;
    const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(dob);
    if (!isValidDate) {
      filteredData.personal_info.date_of_birth = yyyy_mm_dd(dob)
    }
  }
  if (!filteredData.personal_info?.age) {
    delete filteredData.personal_info.age
  } else {
    filteredData.personal_info.age = Number(filteredData.personal_info.age)
  }

  if (!filteredData.personal_info?.nationality) {
    delete filteredData.personal_info.nationality
  } else {
    filteredData.personal_info.nationality = Number(filteredData.personal_info.nationality)
  }

  if (!filteredData.personal_info?.gender) delete filteredData.personal_info.gender

  // Contact 
  if (!filteredData.contact_info?.phone) delete filteredData.contact_info.phone
  if (!filteredData.contact_info?.email) delete filteredData.contact_info.email

  // Education
  if (!filteredData.education_info?.education_stage) delete filteredData.education_info.education_stage

  if (!filteredData.education_info?.education_level) {
    delete filteredData.education_info.education_level
  } else {
    filteredData.education_info.education_level = Number(filteredData.education_info.education_level)
  }

  if (!filteredData.education_info?.education_administration) {
    delete filteredData.education_info.education_administration
  } else {
    filteredData.education_info.education_administration = Number(filteredData.education_info.education_administration)
  }

  if (!filteredData.education_info?.school || filteredData.education_info?.school === 'other') {
    delete filteredData.education_info.school
  } else {
    filteredData.education_info.school = Number(filteredData.education_info.school)
  }

  if (!filteredData.education_info?.university || filteredData.education_info?.university === 'other') {
    delete filteredData.education_info.university
  } else {
    filteredData.education_info.university = Number(filteredData.education_info.university)
  }

  if (!filteredData.education_info?.school_university_name) delete filteredData.education_info.school_university_name

  return filteredData
}
