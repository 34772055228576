import {
  extendVariants,
  Popover as nextUiPopover,
  PopoverContent as nextUiPopoverContent,
  PopoverTrigger as nextUiPopoverTrigger
} from '@nextui-org/react'
export const Popover = extendVariants(nextUiPopover, {
  slots: {
    wrapper: [],
    base: [
      'z-0',
      'relative',
      'bg-transparent',
      'data-placement:before:-top-[calc(theme(spacing.5)/4_-_1.5px)]',
      'data-placement:before:right-3',
      'px-2 py-6'
    ],
    content: [],
    trigger: ['z-10'],
    arrow: ['hidden'],
    backdrop: []
  },
  variants: {
    size: {
      custom: {content: "relative text-medium px-5 pt-5 pb-[88px] grid grid-rows-4 grid-flow-col auto-cols-fr auto-rows-fr gap-3 overflow-hidden"},
      optionsList: {content: "pt-4 px-0 pb-0 rounded-lg font-sub-heading-h6 text-natural_icon_normal flex flex-col gap-4 shadow-lg cursor-pointer overflow-hidden"},
    },
  },

  defaultVariants: {
    color: 'default',
    radius: 'lg',
    size: 'custom',
    shadow: 'md',
    backdrop: 'transparent'
  }
})

export const PopoverContent = extendVariants(nextUiPopoverContent, {})
export const PopoverTrigger = extendVariants(nextUiPopoverTrigger, {})

interface props {
  optionText: string
  onClick: () => void
}

const PopoverOption: React.FC<props> = ({ optionText, onClick }) => {
  return (
    <div className="hover:bg-primary_fill_hover px-4 py-4" onClick={onClick}>
      <p>{optionText}</p>
    </div>
  )
}

export default PopoverOption
