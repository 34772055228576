import Badge from 'components/badge/Badge'
import React from 'react'
import { BsThreeDots } from 'react-icons/bs'
import Ribbon from './Ribbon'

interface Props {
  name: string
  date: string
  points?: number
  status: string
  onClick?: () => void
}

const AppCard: React.FC<Props> = ({ name, date, points, status, onClick }) => {
  return (
    <div className="relative m-1.5 w-full h-56 flex flex-col gap-2 justify-center rounded-xl border border-natural_stroke_normal_disabled bg-natural_fill_normal_hover_pressed_active p-5">
      <button onClick={onClick} className="absolute top-4 left-4">
        <BsThreeDots className="text-natural_lv2_text_normal text-xl" />
      </button>
      {points !== undefined && (
        <div className="absolute md:top-[28%] end-0">
          <Ribbon points={points} />
        </div>
      )}

      <Badge placeholder={status} />

      <h4 className="font-sub-heading-h4 text-natural_icon_normal">{name}</h4>
      <p className=" text-primary_lv2_text_normal">{date}</p>
    </div>
  )
}

export default AppCard
