import { AppDispatch } from 'store'
import { submitApplication } from 'store/slices/submitApplicationSlice'
import { filterEmptyFields } from './filterUtils'
import { SetShowAlertFunction, FormData } from 'pages/SubmitApplication/types'
import { msg } from '@lingui/macro'
import { i18n } from '@lingui/core'

export const submitApplicationAction = async (
  dispatch: AppDispatch,
  data: FormData,
  action: 'save' | 'submit',
  navigate: (path: string, options?: { state: any }) => void,
  setShowAlert?: SetShowAlertFunction
) => {
  const dataCopy = JSON.parse(JSON.stringify(data))
  const filteredData = filterEmptyFields(dataCopy)

  const appId = filteredData.appId

  const applicationPayload = {
    action,
    first_name: filteredData.personal_info.first_name,
    second_name: filteredData.personal_info.second_name,
    last_name: filteredData.personal_info.last_name,
    birth_date: filteredData.personal_info.date_of_birth,
    age: filteredData.personal_info.age,
    gender: filteredData.personal_info.gender,
    nationality_id: filteredData.personal_info.nationality,
    phone: filteredData.contact_info.phone,
    email: filteredData.contact_info.email,
    region_id: filteredData.location_info.region,
    country_id: filteredData.location_info.country,
    education_stage: filteredData.education_info.education_stage,
    education_level_id: filteredData.education_info.education_level,
    education_administration_id: filteredData.education_info.education_administration,
    school_university_name: filteredData.education_info.school_university_name,
    school_id: filteredData.education_info.school,
    university_id: filteredData.education_info.university,
    story: filteredData.application_body.reading_story,
    book_title: filteredData.application_body.book_title,
    book_author: filteredData.application_body.author_name,
    book_reason: filteredData.application_body.reason_for_choice,
    book_review: filteredData.application_body.book_review,
    teacher_code: filteredData.application_body.teacher_code,
    terms_agreed: filteredData.application_body.terms_agreed
  }

  const successMsg =
    action === 'save'
      ? msg`تم حفظ مشاركتك بنجاح`.id
      : msg`تم استلام مشاركتك بنجاح ، سيتم إبلاغك بأي تحديثات من خلال بريدك الإلكتروني`.id

  try {
    const result = await dispatch(
      submitApplication({
        applicationId: appId,
        applicationData: applicationPayload,
        method: appId ? 'PUT' : 'POST'
      })
    ).unwrap()

    if (result.status === 'success') {
      const applicationId = result.data.id
      navigate(`/app/applications/application-details/${applicationId}`, {
        state: { message: successMsg }
      })
    }

    return result
  } catch (error: any) {
    const errorMessage = i18n._(error.message) || i18n._("حدث خطأ ما")
    setShowAlert?.({ type: 'error', message: errorMessage })
  }
}
