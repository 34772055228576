import Button from 'components/base/button'
import { Speaker } from 'pages/landing/models'
import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import { msg } from '@lingui/macro'

interface SpeakerCardProps {
  speaker: Speaker
}

const SpeakerCard: React.FC<SpeakerCardProps> = ({ speaker }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const backgroundStyle = {
    backgroundImage: `url(${speaker.avatar})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '244px',
    width: '244px',
    transition: 'transform 2s ease-in-out',
    transform: isHovered ? 'scale(1.2)' : 'scale(1)'
  }
  return (
    <div className="w-[66vw] md:w-fit flex flex-col items-center px-2.5">
      <div
        className="group flex flex-col items-center space-y-4 w-[66vw] md:w-fit"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="relative h-[244px] w-[244px] rounded-full border-2 border-customTeal shadow-lg overflow-hidden">
          <div className="absolute top-0 left-0 -z-10" style={backgroundStyle}></div>
        </div>
        <div className="flex flex-col space-y-4 text-center items-center justify-end">
          <p className="text-2xl text-customTeal font-bold">
            <Trans id={speaker.name.id} />
          </p>
          <p className="text-xl text-naturalLv2 font-medium">
            <Trans id={speaker.country.id} />
          </p>
          <div className="min-h-11">
            {/* <div className="block md:hidden group-hover:block transition-all ease-in-out duration-800">
              <Button placeholder={msg`التفاصيل`} btnStyle="primary" trailingIcon={true} />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpeakerCard
