import React, { ReactNode, forwardRef, useImperativeHandle } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { ZodSchema } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

interface FormProps {
  schema: ZodSchema
  onSubmit: (data: any) => Promise<void>
  defaultValues: any
  children: ReactNode
  className?: string
}

const Form = forwardRef<any, FormProps>(
  ({ schema, onSubmit, defaultValues, children, className }, ref) => {
    const methods = useForm({
      resolver: zodResolver(schema),
      defaultValues
    })

    useImperativeHandle(ref, () => ({
      ...methods
    }))

    return (
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className={`flex flex-col gap-6 ${className}`}
        >
          {children}
        </form>
      </FormProvider>
    )
  }
)

export default Form
