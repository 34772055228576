import React, { useState } from 'react'
import { FaPlayCircle } from 'react-icons/fa'

interface YouTubeVideoProps {
  videoId?: string
  fallbackImg: string
  altText: string
}

const YouTubeVideo: React.FC<YouTubeVideoProps> = ({ videoId, fallbackImg, altText }) => {
  const embed_url = videoId ? `https://www.youtube.com/embed/${videoId}` : ''
  const [isVideoVisible, setIsVideoVisible] = useState(false)

  const handlePlayVideo = () => {
    setIsVideoVisible(true)
  }
  return (
    <div className="">
      {isVideoVisible ? (
        <div className="relative transform transition-transform duration-300 hover:scale-105 w-full">
          <iframe
            className="rounded-lg w-full h-64 object-cover"
            src={embed_url}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      ) : (
        <div
          className="relative transform transition-transform duration-300 hover:scale-105 w-full cursor-pointer"
          onClick={handlePlayVideo}
        >
          <img src={fallbackImg} alt={altText} className="rounded-lg w-full h-64 object-cover" />
          <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
            <FaPlayCircle className="text-teal-400 text-6xl" />
          </div>
        </div>
      )}
    </div>
  )
}

export default YouTubeVideo
