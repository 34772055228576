import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setSubmissionDate } from 'store/slices/activateRequestsSlice'
import { DateRangePicker, DateValue, RangeValue } from '@nextui-org/react'
import { parseDate } from '@internationalized/date'
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'
import DatePicker from 'components/date-picker-localized/date-picker'

const SubmissionDateFilter: React.FC = () => {
  const { _ } = useLingui()
  const dispatch = useDispatch()
  const { fromSubmissionDate, toSubmissionDate } = useSelector(
    (state: RootState) => state.activateRequests
  )
  const handleSubmissionDateChangeFrom = (date: Date | null) => {
    const fromDate = date
    dispatch(setSubmissionDate({ from: fromDate, to: toSubmissionDate } as any))
  }

  const handleSubmissionDateChangeTo = (date: Date | null) => {
    const toDate = date
    dispatch(setSubmissionDate({ to: toDate, from: fromSubmissionDate } as any))
  }
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-0.5">
        <p className="text-primary pb-2">{_(msg`تاريخ تقديم الطلب (من)`)}</p>
        <DatePicker
          value={(fromSubmissionDate ? fromSubmissionDate : null) as any}
          onChange={handleSubmissionDateChangeFrom as any}
        />
      </div>

      <div>
        <p className="text-primary pb-2">{_(msg`تاريخ تقديم الطلب (إلى)`)}</p>

        <DatePicker
          value={(toSubmissionDate ? toSubmissionDate : null) as any}
          onChange={handleSubmissionDateChangeTo as any}
        />
      </div>
    </div>
  )
}

export default SubmissionDateFilter
