import { Trans as Translation } from '@lingui/macro'

interface WordCharCounterProps {
  currentWordCount: number
  currentLength: number
  maxWords?: number
  maxLength?: number
}

const WordCharCounter: React.FC<WordCharCounterProps> = ({
  currentWordCount,
  currentLength,
  maxWords,
  maxLength
}) => {
  const isWordLimitExceeded = maxWords && currentWordCount >= maxWords
  const isCharLimitExceeded = maxLength && currentLength >= maxLength

  return (
    <p
      className={`font-label-sm-ar ${(maxLength && !maxWords && isCharLimitExceeded) || (maxWords && isWordLimitExceeded)
          ? 'text-danger_default_lv1_text_normal_active_hover_pressed'
          : 'text-natural_icon_normal'
      }`}
    >
      {maxWords && (
        <>
          {`${currentWordCount} / ${maxWords}`} <Translation>كلمة</Translation>
        </>
      )}
      {maxLength && !maxWords && (
        <>
          {`${currentLength} / ${maxLength}`} <Translation>حرف</Translation>
        </>
      )}
    </p>
  )
}

export default WordCharCounter