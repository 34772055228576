import React, { useState } from 'react'
import { userTypeEnum } from 'store/slices/authSlice'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import UserAvatar from '../components/UserAvatar'
import SidebarRoute from './SidebarRoute'
import ButtonVariant from 'components/button-variant/button'
import DoubleChevron from 'assets/icons/DoubleChevron'
import { getCoordinatorLevel } from 'utils/headCoordinatorLevel'
import { iconMap } from '../components/IconMap'
import { RouteConfig } from '../routesConfig'

interface SidebarProps {
  routes: RouteConfig[]
  userRole: userTypeEnum
}

const Sidebar: React.FC<SidebarProps> = ({ routes, userRole }) => {
  const data = useSelector((state: RootState) => state.me?.me?.data)
  const [isCollapsed, setIsCollapsed] = useState(false)

  const basePath = '/app'
  const filterRoutes = (routes: RouteConfig[]): RouteConfig[] => {
    return routes
      .filter((route) => route.showInSidebar && route.roles?.includes(userRole ?? ''))
      .map((route) => ({
        ...route,
        children: route.children ? filterRoutes(route.children) : []
      }))
  }

  let sidebarRoutes = filterRoutes(routes)

  if (userRole === 'coordinator' && getCoordinatorLevel(data?.coordinator ?? ({} as any)) === 0) {
    sidebarRoutes = sidebarRoutes.filter((_) => !_.name.includes('REPORT'))
  }
  if (userRole === 'coordinator' && data?.coordinator?.status != 'approved') {
    sidebarRoutes = sidebarRoutes.filter(
      (_) => !_.name.includes('POINTS') && !_.name.includes('APPLICATIONS')
    )
  }
  const toggleCollapse = () => setIsCollapsed(!isCollapsed)

  return (
    <aside
      className={`hidden md:flex md:flex-col gap-3.5 h-full relative bg-white rounded-2xl shadow-xl px-6 pt-[72px] pb-4 transition-all duration-300 ${
        isCollapsed ? 'w-[88px]' : 'w-[336px]'
      }`}
    >
      <UserAvatar
        isColumnLayout
        isCollapsed={isCollapsed}
        name={data?.name}
        account_type={data?.account_type}
        gender={data?.gender}
      />

      <div className="flex-1 flex flex-col gap-1">
        {sidebarRoutes.map((route) => (
          <SidebarRoute
            key={route.path}
            route={route}
            basePath={basePath}
            isCollapsed={isCollapsed}
            iconMap={iconMap}
          />
        ))}
      </div>

      <div
        className={`${
          isCollapsed ? 'rotate-180' : 'rotate-0'
        } w-fit origin-center transition-all ease-in-out duration-400`}
      >
        <ButtonVariant
          iconRotate
          onClick={toggleCollapse}
          iconEnd={<DoubleChevron />}
          variant="primary"
          isIconOnly
        />
      </div>
    </aside>
  )
}

export default Sidebar
