import React from 'react'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import InputField from 'components/application-viewer/components/DisabledInput'
import { Application } from 'store/slices/applicationsSlice'
import { yyyy_mm_dd } from 'utils/time'
import { useNationalityOptions } from 'pages/SubmitApplication/hooks/useNationalityOptions'
import { useGenderOptions } from 'pages/SubmitApplication/hooks/useGenderOptions'

const PersonalInfoForm = ({ applicationData }: { applicationData: Application }) => {
  const { _ } = useLingui()

  const { options: nationalityOptions } = useNationalityOptions()
  const genderOptions = useGenderOptions()
  // @ts-ignore
  const nationalityName =
    nationalityOptions.find(
      (option) => option.key === String(applicationData.personal_info.nationality_id)
    )?.label || ''
  const genderName =
    genderOptions.find((option) => option.key === applicationData.personal_info.gender)?.label || ''

  const date_of_birth = applicationData.personal_info.birth_date
    ? yyyy_mm_dd(applicationData.personal_info.birth_date)
    : ''
  const age = applicationData.personal_info.age ? String(applicationData.personal_info.age) : ''

  return (
    <>
      <InputField
        name="personal_info.first_name"
        label={_(msg`الاسم الأول`)}
        value={applicationData.personal_info.first_name || ''}
      />
      <InputField
        name="personal_info.second_name"
        label={_(msg`الاسم الأوسط`)}
        value={applicationData.personal_info.second_name || ''}
      />
      <InputField
        name="personal_info.last_name"
        label={_(msg`الاسم الأخير`)}
        value={applicationData.personal_info.last_name || ''}
      />
      <InputField
        name="personal_info.date_of_birth"
        label={_(msg`تاريخ الميلاد`)}
        value={date_of_birth}
      />
      <InputField name="personal_info.age" label={_(msg`العمر`)} value={age} />
      <InputField name="personal_info.gender" label={_(msg`الجنس`)} value={genderName || ''} />
      <InputField
        name="personal_info.nationality"
        label={_(msg`الجنسية`)}
        value={nationalityName || ''}
      />
    </>
  )
}

export default PersonalInfoForm
