import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { z } from 'zod'

export const useReviewRequestSchema = (isLocatedInSA: boolean) => {
  const { _ } = useLingui()
  return z
    .object({
      result: z.enum(['approve', 'reject'], {
        errorMap: () => ({ message: _(msg`الحقل مطلوب`) })
      }),
      regions: z.preprocess((val) => (val === null ? [] : val), z.array(z.string()).optional()),
      education_stage: z.string().optional(),
      education_administrations: z.preprocess(
        (val) => (val === null ? [] : val),
        z.array(z.string()).optional()
      ),
      all_regions: z.boolean().optional(),
      all_education_stages: z.boolean().optional(),
      all_education_administrations: z.boolean().optional(),
      add_to_data_base: z.boolean().optional(),
      university: z.string().nullable().optional(),
      gender_of_education: z.string().nullable().optional(),
      school_university_name: z.string().optional(),
      school_university_name_en: z.string().optional(),
      rejection_reason: z.string().optional(),
      number_of_students: z
        .preprocess(
          (val) => (typeof val === 'string' ? Number(val) : val),
          z
            .number()
            .min(1, {
              message: _(msg`عدد الطلاب ينبغي أن يكون 1 على الأقل`)
            })
            .refine((val) => !isNaN(val), {
              message: _(msg`يرجى إدخال رقم صحيح`)
            })
        )
        .optional(),
      proof_document: z.union([z.instanceof(File), z.string(), z.null()]).optional(),
      comment: z.string().optional()
    })
    .superRefine((data, ctx) => {
      if (
        data.education_stage == 'university' &&
        data.university === 'other' &&
        data.result === 'approve' &&
        data.add_to_data_base
      ) {
        if (!data.school_university_name_en) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['school_university_name_en'],
            message: _(msg`الحقل مطلوب`)
          })
        }
      }
      if (
        data.education_stage == 'university' &&
        data.university === 'other' &&
        data.result === 'approve'
      ) {
        if (!data.school_university_name) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['school_university_name'],
            message: _(msg`الحقل مطلوب`)
          })
        }
      }

      // Validate rejection_reason if the result is 'rejected'
      if (data.result === 'reject') {
        if (!data.rejection_reason) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['rejection_reason'],
            message: _(msg`الحقل مطلوب`)
          })
        }
      }

      // Ensure regions are present if not all_regions
      if (!data.all_regions && (!data.regions || data.regions.length === 0)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['regions'],
          message: _(msg`الحقل مطلوب`)
        })
      }

      // Ensure education_stage is present if not all_education_stages
      if (!data.all_education_stages && !data.education_stage) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['education_stage'],
          message: _(msg`الحقل مطلوب`)
        })
      }

      // Ensure education_administrations are present if required and in Saudi Arabia
      if (
        !data.all_education_administrations &&
        data.education_stage === 'general' &&
        isLocatedInSA &&
        (!data.education_administrations || data.education_administrations.length === 0)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['education_administrations'],
          message: _(msg`الحقل مطلوب`)
        })
      }

      // Ensure gender_of_education is present
      if (!data.gender_of_education) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['gender_of_education'],
          message: _(msg`الحقل مطلوب`)
        })
      }
      if (data.education_stage == 'university' && (data.university == '' || !data.university)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['university'],
          message: _(msg`الحقل مطلوب`)
        })
      }

      if (
        data.education_stage == 'university' &&
        !data.add_to_data_base &&
        data.university === 'other' &&
        data.result === 'approve'
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['add_to_data_base'],
          message: _(
            msg`عند قبول الطلب ينبغي أن يتم تفعيل هذا الخيار أو اختيار جامعة مضافة في القائمة`
          )
        })
      }

      if (
        data.education_stage === 'university' &&
        data.university === 'other' &&
        data.result === 'approve'
      ) {
        const arabicRegex = /^[\u0600-\u06FF0-9\s]+$/
        if (!arabicRegex.test(data.school_university_name || '')) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['school_university_name'],
            message: _(msg`الحقل لايقبل إلا حروف عربية وأرقام فقط`)
          })
        }

        const englishRegex = /^[a-zA-Z0-9\s]*$/
        if (!englishRegex.test(data.school_university_name_en || '')) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['school_university_name_en'],
            message: _(msg`الحقل لايقبل إلا حروف إنجليزية وأرقام فقط`)
          })
        }
      }
    })
}
