import React from 'react'
import { useNavigate } from 'react-router-dom'
import Badge from 'components/badge/Badge'
import AppStatus from 'components/apps/Status'
import { Application, ApplicationStatus } from 'store/slices/applicationsSlice'
import { CompetitionData } from 'store/slices/competitionSlice'
import RecentAppCard from 'assets/icons/RecentAppCard'
import ButtonVariant from 'components/button-variant/button'
import ArrowIcon from 'assets/icons/ArrowIcon'
import { Trans } from '@lingui/macro'
import { extractYear, formatDateTime } from 'utils/time'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

interface props {
  competitionYear: CompetitionData | null
  application: Application
}

const RecentApplicationCard: React.FC<props> = ({ competitionYear, application }) => {
  const navigate = useNavigate()
  const direction = useSelector((state: RootState) => state.layout.direction)

  const handleAppCardClick = (id: number) => () => {
    navigate(`/app/applications/application-details/${id}`)
  }

  return (
    <div
      className="flex justify-between w-full gap-2.5 rounded-[20px] bg-primary_fill_active p-5 my-5 md:my-10"
      onClick={handleAppCardClick(application.id)}
    >
      <div className="flex-1 h-full flex flex-col justify-between">
        <div className="flex gap-2 w-full justify-end">
          {competitionYear?.end_date && (
            <Badge placeholder={extractYear(competitionYear.end_date)} variant="success" />
          )}
          <AppStatus status={application.status.name.en.toLocaleLowerCase() as ApplicationStatus} />
        </div>

        <div>
          {application?.created_at && (
            <h4 className="font-sub-heading-h5 text-natural_lv2_text_normal mb-5">
              {formatDateTime(application.created_at, direction === 'rtl' ? 'ar' : 'en')}
            </h4>
          )}
          {competitionYear?.name && (
            <div className="mb-2">
              <h3 className="font-sub-heading-h3 text-white">
                <Trans>مشاركتك لنسخة المسابقة</Trans>
              </h3>
              <h3 className="font-sub-heading-h4 text-natural_icon_normal">
                {competitionYear.name}
              </h3>
            </div>
          )}

          {application.teacher && (
            <>
              <h4 className="font-sub-heading-h6 text-success_default_lv1_text_normal_hover_active">
                <span
                  className={`${direction === 'ltr' ? 'pr-3' : 'pl-3'} text-success_default_lv2_text_normal_active`}
                >
                  <Trans>المعلم : </Trans>
                </span>
                {application.teacher.name}
              </h4>
            </>
          )}
        </div>

        <div className="flex w-full justify-end">
          <ButtonVariant
            size="sm"
            variant="clear"
            iconEnd={<ArrowIcon fill="white" width="24" height="24" />}
            iconRotate
            onClick={handleAppCardClick(application.id)}
          >
            <p className="text-white">
              <Trans>مشاهدة التفاصيل</Trans>
            </p>
          </ButtonVariant>
        </div>
      </div>

      <div className="hidden md:block">
        <RecentAppCard />
      </div>
    </div>
  )
}

export default RecentApplicationCard
