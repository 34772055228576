import React from 'react'

interface AccountBoxIconProps {
  fill?: string
}

const AccountBoxIcon: React.FC<AccountBoxIconProps> = ({ fill = '#A1A1AA' }) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4776_176545)">
        <path
          d="M2.5 3.33C2.5 2.59533 3.09533 2 3.83 2H13.17C13.9047 2 14.5 2.59533 14.5 3.33V12.67C14.5 13.0227 14.3599 13.361 14.1105 13.6105C13.861 13.8599 13.5227 14 13.17 14H3.83C3.47726 14 3.13897 13.8599 2.88955 13.6105C2.64012 13.361 2.5 13.0227 2.5 12.67V3.33ZM3.83333 3.33333V12.6667H13.1667V3.33333H3.83333ZM5.81467 12.12C5.40466 11.9424 5.01357 11.724 4.64733 11.468C5.07018 10.8127 5.65064 10.2739 6.3356 9.90096C7.02055 9.52803 7.7881 9.33287 8.568 9.33333C10.168 9.33333 11.5793 10.138 12.42 11.3647C12.0608 11.6304 11.6757 11.8593 11.2707 12.048C10.9619 11.62 10.5559 11.2715 10.0859 11.0314C9.61602 10.7912 9.09574 10.6662 8.568 10.6667C7.42467 10.6667 6.41467 11.2427 5.81467 12.12ZM8.5 8.66667C8.19358 8.66667 7.89017 8.60631 7.60707 8.48905C7.32398 8.37179 7.06675 8.19992 6.85008 7.98325C6.63341 7.76658 6.46154 7.50935 6.34428 7.22626C6.22702 6.94317 6.16667 6.63975 6.16667 6.33333C6.16667 6.02692 6.22702 5.7235 6.34428 5.44041C6.46154 5.15731 6.63341 4.90009 6.85008 4.68342C7.06675 4.46675 7.32398 4.29488 7.60707 4.17761C7.89017 4.06035 8.19358 4 8.5 4C9.11884 4 9.71233 4.24583 10.1499 4.68342C10.5875 5.121 10.8333 5.71449 10.8333 6.33333C10.8333 6.95217 10.5875 7.54566 10.1499 7.98325C9.71233 8.42083 9.11884 8.66667 8.5 8.66667ZM8.5 7.33333C8.76522 7.33333 9.01957 7.22798 9.20711 7.04044C9.39464 6.8529 9.5 6.59855 9.5 6.33333C9.5 6.06812 9.39464 5.81376 9.20711 5.62623C9.01957 5.43869 8.76522 5.33333 8.5 5.33333C8.23478 5.33333 7.98043 5.43869 7.79289 5.62623C7.60536 5.81376 7.5 6.06812 7.5 6.33333C7.5 6.59855 7.60536 6.8529 7.79289 7.04044C7.98043 7.22798 8.23478 7.33333 8.5 7.33333Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4776_176545">
          <rect width="16" height="16" fill={fill} transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AccountBoxIcon