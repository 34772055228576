import Typography from 'components/typography/typography'
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'
import ButtonVariant from 'components/button-variant/button'
import SelectInput from 'components/hook-form/select-field'
import DateInput from 'components/hook-form/date-picker-input'
import { useFormContext } from 'react-hook-form'
import CheckboxInput from 'components/hook-form/checkbox-input'
import { useEffect } from 'react'

export default function ExportForm({ fixedReportType }: { fixedReportType?: string }) {
  const { _ } = useLingui()

  const optionsReports = [
    {
      key: 'all',

      label: _(msg`جميع التقارير`)
    },
    {
      key: 'topCoordinators',
      label: _(msg`تقرير أعلى المنسقين`)
    },
    {
      key: 'administrativesApplications',
      label: _(msg`تقرير الإدارات التعليمية`)
    },
    {
      key: 'countryRegionalApplications',
      label: _(msg`تقرير المناطق`)
    },
    {
      key: 'universitiesApplications',
      label: _(msg`تقرير المشاركات بالجامعات`)
    },
    {
      key: 'reachPercentage',
      label: _(msg`تقرير مدي تحقيق المنسقين للرقم المستهدف`)
    },
    {
      key: 'topTeachers',
      label: _(msg`تقرير أعلى المعلمين `)
    },
    {
      key: 'countryTopTeachers',
      label: _(msg`تقرير أعلى المعلمين `)
    },
    {
      key: 'otherCountriesTopTeachers',
      label: _(msg`تقرير أعلى المعلمين `)
    },
    {
      key: 'otherCountriesTopCoordinators',
      label: _(msg`تقرير أعلى المنسقين`)
    },
    {
      key: 'countryTopCoordinators',
      label: _(msg`تقرير أعلى المنسقين`)
    },
    {
      key: 'countryStagesApplications',
      label: _(msg`تقرير المراحل التعليمية`)
    },
    {
      key: 'otherCountriesStagesApplications',
      label: _(msg`تقرير المراحل التعليمية`)
    },
    {
      key: 'countriesApplications',
      label: _(msg`تقرير المشاركات بالدول`)
    },
    {
      key: 'genderCountriesApplications',
      label: _(msg`تقرير مشاركات الذكور والإناث`)
    },
    {
      key: 'countryApplicationsCount',
      label: _(msg`تقرير مشاركات الدول`)
    }
  ]
  const optionsDuration = [
    {
      key: 'all',
      label: _(msg`جميع الفترات الزمنية لهذه السنة `)
    },
    {
      key: 'costum',
      label: _(msg`فترة زمنية محددة`)
    }
  ]
  const { watch, setValue } = useFormContext()
  const durationType = watch('durationType')
  const pdf = watch('pdf')
  const csv = watch('csv')
  useEffect(() => {
    if (pdf) {
      return setValue('csv', false)
    }
  }, [pdf])
  useEffect(() => {
    if (csv) {
      return setValue('pdf', false)
    }
  }, [csv])

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col justify-center items-center gap-2 ">
        <Typography size="xl" weight="bold">
          {_(msg`تصدير التقارير`)}
        </Typography>
        <Typography>{_(msg`يرجي تحديد التواريخ المراد إستخراج التقارير خلالها`)}</Typography>
      </div>
      <SelectInput
        selectionMode="single"
        name="reportType"
        disabled={fixedReportType != undefined}
        className="h-"
        label={_(msg`نوع التقارير`)}
        options={optionsReports}
      ></SelectInput>

      <SelectInput
        selectionMode="single"
        name="durationType"
        label={_(msg`الفترة الزمنية للتقارير`)}
        options={optionsDuration}
      ></SelectInput>
      {durationType == 'costum' && (
        <>
          <DateInput name="beginDate" label={_(msg`تاريخ بداية الفترة`)}></DateInput>
          <DateInput name="endDate" label={_(msg`تاريخ نهاية الفترة`)}></DateInput>
        </>
      )}
      <div className="flex flex-col gap-2">
        <span>{_(msg`نوع الملف`)}</span>
        <div className="flex flex-row gap-4 ">
          <CheckboxInput name="pdf" label="pdf"></CheckboxInput>
          <CheckboxInput name="csv" label="csv"></CheckboxInput>
        </div>
      </div>
      
      <div className="flex flex-col justify-center items-center">
        <ButtonVariant
          type="submit"
          variant="clear"
          className=" text-white bg-natural_lv1_text_normal"
        >
          {_(msg`تصدير `)}
        </ButtonVariant>
      </div>
    </div>
  )
}
