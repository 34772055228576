import React, { useMemo } from 'react'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import InputField from 'components/application-viewer/components/DisabledInput'
import { Application } from 'store/slices/applicationsSlice'
import { useEducationLevelsOptions } from 'pages/SubmitApplication/hooks/useEducationLevelsOptions'
import { useEducationAdministrationOptions } from 'pages/SubmitApplication/hooks/useEducationAdministrationOptions'
import { useSchoolOptions } from 'pages/SubmitApplication/hooks/useSchoolOptions'
import { useUniversityOptions } from 'pages/SubmitApplication/hooks/useUniversityOptions'

const EducationInfoForm = ({ applicationData }: { applicationData: Application }) => {
  const { _ } = useLingui()

  const countryId = String(applicationData.location.country_id)
  const countryCode = String(applicationData.location.country_code)
  const regionId = String(applicationData.location.region_id)
  const educationStage = applicationData.education.education_stage
  const educationLevelId = applicationData.education.education_level_id

  const showEducationAdministration = educationStage === 'general' && (countryId === '425' || countryCode === 'SA')
  const showSchool = educationStage === 'general'
  const showUniversity = educationStage === 'university'

  const { options: EducationLevelOptions } = useEducationLevelsOptions({ countryId })
  const { options: EducationAdministrationOptions } = useEducationAdministrationOptions({ countryId, regionId })
  const { options: SchoolOptions } = useSchoolOptions({ countryId, regionId })
  const { options: UniversityOptions } = useUniversityOptions({ countryId, regionId })

  const educationLevelName = EducationLevelOptions.find((option) => +option.key === educationLevelId)?.label || ''
  const educationAdministrationName = EducationAdministrationOptions.find( (option) => +option.key === applicationData.education.education_administration_id )?.label || ''
  const schoolName = SchoolOptions.find((option) => +option.key === applicationData.education.school_id)?.label || ''
  const universityName = UniversityOptions.find((option) => +option.key === applicationData.education.university_id)?.label || ''
  const schoolUniversityName = applicationData.education.school_university_name

  const getLocalizedEducationStage = (stage: string) => {
    switch (stage) {
      case 'general':
        return _(msg`عام`)
      case 'university':
        return _(msg`جامعي`)
      default:
        return ''
    }
  }

  return (
    <>
      <InputField
        name="education_info.education_stage"
        label={_(msg`مرحلة التعليم`)}
        value={getLocalizedEducationStage(educationStage) || ''}
        isDisabled
      />
      <InputField
        name="education_info.education_level"
        label={_(msg`المستوى التعليمي`)}
        value={educationLevelName || ''}
        isDisabled
      />
      {showEducationAdministration && (
        <InputField
          name="education_info.education_administration"
          label={_(msg`الإدارة التعليمية`)}
          value={educationAdministrationName || ''}
          isDisabled
        />
      )}
      {showSchool && (
        <InputField
          name="education_info.school"
          label={_(msg`المدرسة`)}
          value={schoolName || schoolUniversityName || ''}
          isDisabled
        />
      )}
      {showUniversity && (
        <InputField
          name="education_info.university"
          label={_(msg`الجامعة`)}
          value={universityName || schoolUniversityName || ''}
          isDisabled
        />
      )}
    </>
  )
}

export default EducationInfoForm
