import React from 'react'

interface TrophyIconProps {
  fill?: string
}

const TrophyIcon: React.FC<TrophyIconProps> = ({ fill = 'white' }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5219_15822)">
        <path
          d="M13 17.438V19.5H18V21.5H6V19.5H11V17.438C9.0667 17.1942 7.28882 16.2533 6 14.7917C4.71119 13.3302 4.00003 11.4486 4 9.5V3.5H20V9.5C20 11.4486 19.2888 13.3302 18 14.7917C16.7112 16.2533 14.9333 17.1942 13 17.438ZM6 5.5V9.5C6 11.0913 6.63214 12.6174 7.75736 13.7426C8.88258 14.8679 10.4087 15.5 12 15.5C13.5913 15.5 15.1174 14.8679 16.2426 13.7426C17.3679 12.6174 18 11.0913 18 9.5V5.5H6ZM1 5.5H3V9.5H1V5.5ZM21 5.5H23V9.5H21V5.5Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_5219_15822">
          <rect width="24" height="24" fill={fill} transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TrophyIcon
