import React from 'react'

interface ClipboardIconProps {
  fill?: string
}

const ClipboardIcon: React.FC<ClipboardIconProps> = ({ fill = 'white' }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5178_27739)">
        <path
          d="M6.33333 4.9974V2.4974C6.33333 2.27638 6.42113 2.06442 6.57741 1.90814C6.73369 1.75186 6.94565 1.66406 7.16667 1.66406H17.1667C17.3877 1.66406 17.5996 1.75186 17.7559 1.90814C17.9122 2.06442 18 2.27638 18 2.4974V14.1641C18 14.3851 17.9122 14.597 17.7559 14.7533C17.5996 14.9096 17.3877 14.9974 17.1667 14.9974H14.6667V17.4974C14.6667 17.9574 14.2917 18.3307 13.8275 18.3307H3.83917C3.72927 18.3314 3.62033 18.3103 3.5186 18.2688C3.41687 18.2272 3.32436 18.1659 3.24638 18.0885C3.1684 18.011 3.10649 17.919 3.06421 17.8175C3.02193 17.7161 3.00011 17.6073 3 17.4974L3.0025 5.83073C3.0025 5.37073 3.3775 4.9974 3.84167 4.9974H6.33333ZM8 4.9974H14.6667V13.3307H16.3333V3.33073H8V4.9974Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_5178_27739">
          <rect width="20" height="20" fill={fill} transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ClipboardIcon
