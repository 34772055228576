import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { VerticalDotsIcon } from 'assets/icons/VerticalDotsIcon'
import ButtonVariant from 'components/button-variant/button'
import DataTable from 'components/data-table/data-table'
import { FC, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { useLocation, useNavigate } from 'react-router-dom'
import ApplicationsFilters from './components/ApplicationsFilters'
import AppStatus from 'components/apps/Status'
import { fetchApps, setPage, ISearchApp } from 'store/slices/applicationsSlice'
import { ApplicationStatus } from 'store/slices/applicationsSlice'
import { formatDateTime } from 'utils/time'
import Alert from 'components/alert/alert'

const DEFAULT_PAGE = 1
const PER_PAGE = 10

const IdleApplicationsList: FC = () => {
  const { _, i18n } = useLingui()
  const direction = useSelector((state: RootState) => state.layout.direction)

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const location = useLocation()

  const { apps, isLoading, page, per_page, total_pages } = useSelector(
    (state: RootState) => state.appsStates
  )

  const [dateRange, setDateRange] = useState<{ from: string; to: string }>({ from: '', to: '' })
  const [selectedYear, setSelectedYear] = useState<string>('')
  const [status, setStatus] = useState<number | undefined>()
  const [searchValue, setSearchValue] = useState<string>('')
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue)
  const [showAlert, setShowAlert] = useState<{ type: 'success' | 'error'; message: string } | null>(
    null
  )
  const [key, setKey] = useState<number>(0)

  const queryRef = useRef<Partial<ISearchApp>>({
    user: 'ithraa',
    page: DEFAULT_PAGE,
    per_page: PER_PAGE,
    idle: true
  })

  useEffect(() => {
    dispatch(
      fetchApps({
        user: 'ithraa',
        page: page || DEFAULT_PAGE,
        per_page: per_page || PER_PAGE,
        idle: true,
        'statuses[]': status ? status : undefined
      })
    )
  }, [dispatch, page, per_page, status])

  useEffect(() => {
    setKey((prevKey) => prevKey + 1)
  }, [direction])

  const columns = [
    { key: 'number', label: _(msg`رقم المشاركة`) },
    { key: 'name', label: _(msg`اسم الطالب`) },
    { key: 'country', label: _(msg`الدولة`) },
    { key: 'created_at', label: _(msg`التاريخ`) },
    { key: 'status', label: _(msg`الحالة`) },
    { key: 'competition_year_id', label: _(msg`سنة المسابقة`) },
    { key: 'view', label: _(msg`عرض التفاصيل`) }
  ]

  const renderStatusChip = (status: any) => {
    return <AppStatus status={status.name.en.toLocaleLowerCase() as ApplicationStatus} />
  }

  const renderViewButton = (application: any) => (
    <ButtonVariant
      variant="clear"
      isIconOnly
      iconEnd={<VerticalDotsIcon />}
      onClick={() =>
        navigate(
          `/app/management-applications/idle-applications/application-details/${application.id}`
        )
      }
    />
  )

  const handlePageChange = (newPage: number) => {
    dispatch(setPage(newPage))
    dispatch(
      fetchApps({
        ...queryRef.current,
        user: 'ihtraa',
        page: newPage,
        idle: true
      })
    )
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue)
    }, 1000)
  
    return () => {
      clearTimeout(handler)
    }
  }, [searchValue])

  useEffect(() => {
    queryRef.current = {
      ...queryRef.current,
      search: searchValue || undefined,
      page: 1
    }

    dispatch(
      fetchApps({
        ...queryRef.current,
        user: 'ithraa',
        idle: true
      })
    )
  }, [debouncedSearchValue])

  const onFilter = () => {
    queryRef.current = {
      ...queryRef.current,
      user: 'ithraa',
      status: status || undefined,
      submission_at_from: dateRange.from || undefined,
      submission_at_to: dateRange.to || undefined,
      competition_year_id: selectedYear || undefined,
      search: searchValue || undefined,
      page: 1
    }

    dispatch(
      fetchApps({
        ...queryRef.current,
        user: 'ithraa',
        idle: true
      })
    )
  }

  const onCancel = () => {
    setStatus(undefined)
    setDateRange({ from: '', to: '' })
    setSelectedYear('')
    setSearchValue('')

    queryRef.current = {
      user: 'ithraa',
      idle: true,
      page: 1,
      per_page
    }

    dispatch(
      fetchApps({
        ...queryRef.current,
        user: 'ithraa',
        idle: true
      })
    )
  }

  return (
    <div className="flex flex-col w-full gap-4">
      {(showAlert || location.state?.message) && (
        <div className="pt-4">
          <Alert
            mainText={showAlert?.message || location.state?.message}
            sizeMainText="md"
            weightMainText="normal"
            onClose={() => setShowAlert(null)}
            type={showAlert?.type || 'success'}
          />
        </div>
      )}

      <ApplicationsFilters
        dateRange={dateRange}
        setDateRange={setDateRange}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        status={status}
        setStatus={setStatus}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        onFilter={onFilter}
        onCancel={onCancel}
      />

      <DataTable
        key={key}
        columns={columns}
        data={apps || []}
        isLoading={isLoading}
        page={page}
        totalPages={total_pages}
        onPageChange={handlePageChange}
        renderCell={(item, columnKey) => {
          if (columnKey === 'name') {
            return (
              item.student?.first_name +
              ' ' +
              (item.student?.middle_name ?? '') +
              (item.student?.last_name ?? '')
            )
          } else if (columnKey === 'country') {
            return item.location?.country?.name[i18n.locale as 'en' | 'ar']
          } else if (columnKey === 'created_at') {
            return formatDateTime(item.created_at, direction === 'rtl' ? 'ar' : 'en')
            } else if (columnKey === 'competition_year_id') {
              return item.competition_year?.name
          } else if (columnKey === 'status') {
            return renderStatusChip(item.status)
          } else if (columnKey === 'view') {
            return renderViewButton(item)
          } else {
            return item[columnKey]
          }
        }}
      />
    </div>
  )
}

export default IdleApplicationsList
