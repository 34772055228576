import ModalHeader from 'components/modal/modal-header'
import ModalLayout from 'components/modal/modal-layout'
import React from 'react'
import { Trans } from '@lingui/macro'
import { msg } from '@lingui/macro'
import ChangeEmail from '../forms/change-email'

interface changeEmailModalProps {
  handleEmailModalDisplay: (shouldShow: boolean) => void
  handleOTPModalDisplay: (shouldShow: boolean) => void
}

const ChangeEmailModal: React.FC<changeEmailModalProps> = ({
  handleEmailModalDisplay,
  handleOTPModalDisplay
}) => {
  const handleBack = () => {
    handleEmailModalDisplay(false)
    handleOTPModalDisplay(true)
  }

  return (
    <ModalLayout
      onBack={handleBack}
      accountPageStyle
      header={
        <ModalHeader
          headerText={msg`تغير البريد الإلكتروني`}
          subheaderText={<Trans>يرجى إدخال البريد الإلكتروني الجديد لإضافته.</Trans>}
        />
      }
      body={
        <ChangeEmail
          handleEmailModalDisplay={handleEmailModalDisplay}
          handleOTPModalDisplay={handleOTPModalDisplay}
        />
      }
    />
  )
}

export default ChangeEmailModal
