import { useMemo } from 'react'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'
import { GenderOption } from './types'


export const useGenderOptions = (): GenderOption[] => {
  const { i18n } = useLingui()

  const genderOptions = useMemo(
    () => [
      { key: 'male', label: i18n._(t`ذكر`) },
      { key: 'female', label: i18n._(t`انثى`) }
    ],
    [i18n]
  )

  return genderOptions
}
