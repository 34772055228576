import Dash from 'assets/icons/Dash'
import MarkIcon from 'assets/icons/MarkIcon'
import DividerHeader from 'components/DividerHeader'
import ButtonVariant from 'components/button-variant/button'
import CheckboxField from 'components/checkbox-field/checkbox-field'
import React from 'react'
import { Divider } from '@nextui-org/react'

export default function SettingElement({
  setting_name,
  hideDivider,
  checked,
  onchange
}: {
  setting_name: string
  hideDivider?: boolean
  checked: boolean
  onchange: () => void
}) {
  return (
    <div className="w-full">
      <div className="flex flex-row justify-between items-center w-full">
        <div className="w-5 h-5 bg-primary rounded-md flex justify-center items-center">
          <Dash />
        </div>
        <span className="font-700 px-2"> {setting_name} </span>
        <CheckboxField checked={checked} onChange={onchange}></CheckboxField>
      </div>
      {!hideDivider && <Divider className="my-4"></Divider>}
    </div>
  )
}
