import React from 'react'
import { Trans } from '@lingui/react'
import MailBoxIcon from 'assets/icons/MailBoxIcon'

interface NumericCardProps {
  title: string
  numericValue: number
  color?: string
  unit?: string
  emptyMsg?: string
  isPercentage?: boolean
}

const NumericCard: React.FC<NumericCardProps> = ({
  title,
  numericValue,
  unit,
  emptyMsg,
  color = 'text-natural_lv1_text_normal',
  isPercentage
}) => {
  return (
    <div className="w-full min-w-[132px] h-full flex flex-col items-center justify-center">
      {(numericValue === 0 || numericValue == undefined) && emptyMsg ? (
        <>
          <div className={`${color}`}>
            <MailBoxIcon fill="currentColor" />
          </div>
          <p className="font-paragraph-overline-h6 text-natural_icon_normal text-center">
            <Trans id={emptyMsg} />
          </p>
        </>
      ) : (
        <>
          <h4 className="font-heading-h5-ar text-natural_icon_normal text-center">
            <Trans id={title} />
          </h4>
          <p className={`font-sub-heading-h2 ${color}`}>
            {numericValue}
            {isPercentage && <span>%</span>}
          </p>
          {/* {unit && (
            <h4 className="font-heading-h5-ar text-natural_icon_normal">
              <Trans id={unit} />
            </h4>
          )} */}
        </>
      )}
    </div>
  )
}

export default NumericCard
