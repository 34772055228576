import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import DataCards from 'components/data-cards/data-cards'
import DataTable from 'components/data-table/data-table'
import TabsNavigator from 'components/tabs-navigator/tabs-navigator'
import useLanguage from 'hooks/useLanguage'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Application, ApplicationStatus } from 'store/slices/applicationsSlice'
import { dd_mm_yyyy, yyyy_mm_dd } from 'utils/time'
import AppActions from './Actions'
import AppCard from './Card'
import AppStatus from './Status'

interface Props {
  apps: any[]
  isLoading: boolean
  page: number
  per_page: number
  total_pages: number
  onPageChange?: (page: number) => void
  isCoordinator: boolean
  showPagination?: boolean
}

const AppTabs: FC<Props> = ({
  apps,
  isLoading,
  page,
  per_page,
  total_pages,
  onPageChange = () => {},
  isCoordinator,
  showPagination = true
}) => {
  const { _ } = useLingui()
  const language = useLanguage()
  const navigate = useNavigate()

  const columns = [
    { key: 'id', label: _(msg`رقم المشاركة`) },
    { key: 'name', label: _(msg`البيانات الشخصية`) },
    { key: 'submit', label: _(msg`تاريخ تقديم الطلب`) },
    { key: 'status', label: _(msg`الحالة`) },
    { key: 'points', label: _(msg`النقاط المكتسبة`) },
    { key: 'settings', label: _(msg`الاعدادات`) }
  ]

  function renderCell(app: Application, columnKey: React.Key) {
    return (
      <div className="min-w-28">
        {columnKey === 'id' && <p>{app.number}</p>}
        {columnKey === 'name' && (
          <div className=" flex flex-col gap-0.5">
            <span>{app.student.name}</span>
            <span className="text-sm">{app.student.email}</span>
            <span className="text-sm">{app.student.phone}</span>
          </div>
        )}
        {columnKey === 'submit' && (
          <span className={language === 'ar' ? 'text-right' : 'text-left'}>
            {app.submission_at ? yyyy_mm_dd(app.submission_at) : '/'}
          </span>
        )}
        {columnKey === 'status' && (
          <AppStatus status={app.status.name.en.toLocaleLowerCase() as ApplicationStatus} />
        )}
        {!isCoordinator && columnKey === 'points' && <span>{app.total_points || 0}</span>}
        {columnKey === 'settings' && <AppActions to={`/app/applications/application-details/${app.id}`} />}
      </div>
    )
  }

  const tabs = [
    {
      key: 'cards',
      title: _(msg`بطاقات`),
      isLoading,
      content: (
        <DataCards
          page={page}
          data={apps || []}
          isLoading={isLoading}
          showPagination={showPagination}
          per_page={per_page}
          total_pages={total_pages}
          onPageChange={onPageChange}
          renderCard={(item) => {
            return (
              <AppCard
                key={item.id}
                name={item.student.name}
                date={item.submission_at ? dd_mm_yyyy(item.submission_at) : ''}
                points={isCoordinator ? undefined : item.total_points || 0}
                status={item.status.name[language as 'ar' | 'en']}
                onClick={() => navigate(`/app/applications/application-details/${item.id}`)}
              />
            )
          }}
        />
      )
    },
    {
      key: 'table',
      title: _(msg`جدول`),
      isLoading,
      content: (
        <DataTable
          page={page}
          columns={isCoordinator ? columns.filter((e) => e.key !== 'points') : columns}
          data={apps || []}
          showPagination={showPagination}
          isLoading={isLoading}
          totalPages={total_pages}
          onPageChange={onPageChange}
          renderCell={renderCell}
        />
      )
    }
  ]

  return (
    <TabsNavigator
      classNames={{
        tab: '',
        tabList:
          'w-56 mb-2 bg-natural_fill_normal_hover_pressed_active border border-natural_stroke_normal_disabled',
        tabContent: ''
      }}
      variant="solid"
      radius="full"
      color="primary"
      items={tabs}
    />
  )
}

export default AppTabs
