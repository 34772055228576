import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import Container from 'pages/auth/container'
import TeacherPoints from 'pages/TeacherPages/TeacherPoints'
import CoordinatorPoints from 'pages/CoordinatorPages/CoordinatorPoints'

const Points: React.FC = () => {
  const { userType } = useSelector((state: RootState) => state.auth)

  const renderContent = () => {

    switch (userType) {
      case 'teacher':
        return <TeacherPoints />
      case 'coordinator':
        return <CoordinatorPoints />
      default:
        return (
          <Container title="Welcome" subtitles="This is the general points page.">
            <p>Welcome to the points Page!</p>
          </Container>
        )
    }
  }

  return <div className="h-full w-full">{renderContent()}</div>
}

export default Points
