import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'

interface ApplicationPayload {
  action: string
  story: string
  book_title: string
  book_author: string
  book_reason: string
  book_review: string
  teacher_code: string
  terms_agreed: boolean
  region_id: number
  first_name: string
  second_name: string
  last_name: string
  phone: string
  gender: string
  nationality_id: number
  country_id: number
  education_stage: string
  school_id: number
  university_id: number
  email: string
  birth_date: string
  age: number
  school_university_name: string
  education_level_id: number
  // competition_year_id: number
}

interface ApplicationData {
  id: number
  number: string
  status: string
  student_id: number
  student: {
    id: number
    name: string
    email: string
    phone: string
  }
  personal_info: {
    first_name: string
    second_name: string
    last_name: string
    birth_date: string
    age: number
    gender: string
    nationality_id: number
  }
  contact_info: {
    email: string
    phone: string
  }
  location: {
    region_id: number
    country_id: number
  }
  education: {
    education_stage: string
    school_id: number
    university_id: number
    education_level_id: number
    school_university_name: string
    education_administration: number
  }
  application_body: {
    book_title: string
    book_author: string
    book_reason: string
    book_review: string
    story: string
    teacher_code: string
    terms_agreed: boolean
  }
}

interface ApplicationState {
  isLoading: boolean
  error: string | null
  data: ApplicationData | null 
}

const initialState: ApplicationState = {
  isLoading: false,
  error: null,
  data: null
}

export const submitApplication = createAsyncThunk(
  'application/submitOrUpdate',
  async (
    { applicationId, applicationData, method }: 
    { applicationId?: number; applicationData: ApplicationPayload; method: 'POST' | 'PUT' }, 
    thunkAPI
  ) => {
    try {
      const url = method === 'POST' 
        ? '/students/applications' 
        : `/students/applications/${applicationId}`;
        
      const response = method === 'POST' 
        ? await axiosInstance.post(url, applicationData) 
        : await axiosInstance.put(url, applicationData);
        
      return response.data;
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);



const submitApplicationSlice = createSlice({
  name: 'submitApplication',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitApplication.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(submitApplication.fulfilled, (state, action) => {
        state.isLoading = false
        state.data = action.payload
      })
      .addCase(submitApplication.rejected, (state, action) => {
        state.isLoading = false
        state.error = typeof action.payload === 'string' ? action.payload : 'Failed to submit application'
      })
  }
})

export default submitApplicationSlice
