import React from 'react'
import TwitterIcon from 'assets/icons/twitter-icon.svg'
import YouTubeIcon from 'assets/icons/youtube-icon.svg'
import FacebookIcon from 'assets/icons/facebook-icon.svg'
import InstegramIcon from 'assets/icons/instegram.svg'
import sheildIcon from 'assets/icons/sheild-icon.svg'
import Link from 'components/base/link'
import { msg, Trans } from '@lingui/macro'
import { useLocation, useNavigate } from 'react-router-dom'
import { useScrollContext } from '../context/scroll-context'

const Footer: React.FC = () => {
  const navigate = useNavigate()
  const scrollToSection = useScrollContext()
  const location = useLocation()

  const handleSupplementaryLinkClick = () => {
    navigate('/supplementary-files')
    console.log('supplementary-files')
    window.scrollTo(0, 0)
  }
  const handleBlogLinkClick = () => {
    navigate('/blog')
    window.scrollTo(0, 0)
  }
  const handleNavigation = (section: string) => {
    return (event: React.MouseEvent) => {
      event.preventDefault()
      if (location.pathname === '/') {
        if (scrollToSection) {
          scrollToSection(section)
        }
      } else {
        navigate(`/?section=${section}`)
        setTimeout(() => {
          if (scrollToSection) {
            scrollToSection(section)
          }
        }, 0)
      }
    }
  }

  const currentYear = new Date().getFullYear() // Get the current year
  const linkClass: string = 'text-naturalLv1 font-medium'
  const headerClass: string = 'text-naturalLv1 font-bold text-xl'
  const sectionClass: string =
    'w-full md:w-1/4 text-start md:text-center md:text-start flex flex-col space-y-4'

  return (
    <footer className="border-t border-customTeal py-8">
      <div className="relative w-10/12 mx-auto flex flex-wrap justify-between space-y-4 lg:space-y-0">
        <div className={sectionClass}>
          <h4 className={headerClass}>
            <Trans>مسابقة أقرأ</Trans>
          </h4>
          <ul className="space-y-1">
            <li>
              <Link
                placeholder={msg`عن أقرأ`}
                className={linkClass}
                onClick={handleNavigation('about')}
              />
            </li>
            <li>
              <Link
                placeholder={msg`مسارات المسابقة`}
                className={linkClass}
                onClick={handleNavigation('paths')}
              />
            </li>
          </ul>
        </div>
        <div className={sectionClass}>
          <h4 className={headerClass}>
            <Trans>روابط هامة</Trans>
          </h4>
          <ul className="space-y-1">
            {/* <li>
              <Link
                placeholder={msg`الملفات المساعدة`}
                className={linkClass}
                onClick={handleSupplementaryLinkClick}
              />
            </li> */}
            <li>
              <Link
                placeholder={msg`الشروط والأحكام العامة`}
                className={linkClass}
                onClick={handleNavigation('faq')}
              />
            </li>

            {/* <li>
              <Link placeholder={msg`الشروط والأحكام العامة`} className={linkClass} />
            </li> */}
          </ul>
        </div>
        <div className={sectionClass}>
          <h4 className={headerClass}>
            <Trans>تواصل معنا</Trans>
          </h4>
          <ul className="space-y-1">
            <li>
              <Link
                href="mailto:info@ireadaward.com"
                placeholder={msg`info@ireadaward.com`}
                className={linkClass}
              />
            </li>
          </ul>
        </div>
        <div className={sectionClass}>
          <h4 className={headerClass}>
            <Trans>التواصل الاجتماعي</Trans>
          </h4>
          <div className="flex *:mx-2">
            <a href="https://x.com/iReadAward">
              <img src={TwitterIcon} alt="X" />
            </a>
            <a href="https://www.youtube.com/playlist?list=PLlomFhm7uiZEUWndEcoYFeIdFW4cWHJS2">
              <img src={YouTubeIcon} alt="YouTube" />
            </a>
            <a href="https://www.facebook.com/IReadaward1">
              <img src={FacebookIcon} alt="Facebook" />
            </a>
            <a href="https://www.instagram.com/ireadaward">
              <img src={InstegramIcon} alt="LinkedIn" />
            </a>
          </div>
        </div>
      </div>
      <div className="relative mt-8 border-t border-customTeal pt-4 text-center text-sm">
        <div className="absolute -top-5 right-4 md:right-10 lg:right-20 p-4">
          <img src={sheildIcon} alt="Shield" />
        </div>
        <div className="w-10/12 mx-auto flex flex-col space-y-3 md:space-y-0 md:flex-row md:items-center justify-between">
          <div className="md:mt-2 md:*:mx-1 flex flex-col md:flex-row md:items-center">
            <div className="flex *:mx-1 items-center h-8">
              <span className="md:hidden mb-8 text-5xl text-customTeal leading-none flex items-center">
                ،
              </span>
              <Link
                placeholder={msg`سياسة الخصوصية`}
                href="https://www.ithra.com/ar/privacy-policy"
                className="text-naturalLv1 font-bold text-sm"
              />
            </div>
            <div className="flex *:mx-1 items-center h-8">
              <span className="mb-8 text-5xl text-customTeal leading-none flex items-center">
                ،
              </span>
              <Link
                placeholder={msg`شروط الاستخدام`}
                href="https://www.ithra.com/ar/terms-conditions"
                className="text-naturalLv1 font-bold text-sm"
              />
            </div>
            {/* <div className="flex *:mx-1 items-center h-8">
              <span className="mb-8 text-5xl text-customTeal leading-none flex items-center">
                ،
              </span>
              <Link
                placeholder={msg`سياسة ملفات التعريف`}
                href="https://www.ithra.com/ar/privacy-policy"
                className="text-naturalLv1 font-bold text-sm"
              />
            </div> */}
          </div>
          <p className="text-naturalLv2">
            © {currentYear}{' '}
            <Trans>مركز الملك عبدالعزيز الثقافي العالمي (إثراء). جميع الحقوق محفوظة</Trans>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
