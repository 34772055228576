import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'

interface sendSubmitReminderApplicationState {
  isLoading: boolean
  success: boolean | null
  error: string | null
}

const initialState: sendSubmitReminderApplicationState = {
  isLoading: false,
  success: null,
  error: null
}

export const sendSubmitReminderApplication = createAsyncThunk(
  'application/sendSubmitReminderApplication',
  async (
    { applicationId, emailContent }: { applicationId: number; emailContent: string },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.put(`/management/applications/idle/send-reminder`, {
        applications: [applicationId],
        email_content: emailContent
      })
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const sendSubmitReminderApplicationSlice = createSlice({
  name: 'sendSubmitReminderApplication',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendSubmitReminderApplication.pending, (state) => {
        state.isLoading = true
        state.success = null
        state.error = null
      })
      .addCase(sendSubmitReminderApplication.fulfilled, (state) => {
        state.isLoading = false
        state.success = true
      })
      .addCase(sendSubmitReminderApplication.rejected, (state, action) => {
        state.isLoading = false
        state.success = false
        state.error = action.payload as string
      })
  }
})

export default sendSubmitReminderApplicationSlice
