import React, { useEffect, useState } from 'react'
import { msg } from '@lingui/macro'
import ReportHeader from './ReportHeader/ReportHeader'
import { useLingui } from '@lingui/react'
import { AppDispatch, RootState } from 'store'
import { useDispatch } from 'react-redux'
import {
  fetchApplicationStatistics,
  setExportSuccess
} from 'store/slices/reportsHeadCoordinatorSlice'
import ModalLayout from 'components/modal/modal-layout'
import { Spinner } from '@nextui-org/react'
import { useSelector } from 'react-redux'
import ReportWrapper from './ReportContent'
import { useReportData } from './useReports'
import { Report } from 'api/types'
import { getCoordinatorLevel } from 'utils/headCoordinatorLevel'
import ExportConfirmation from './ModalExport/ExportConfirmation'
import Export from './ModalExport/Export'
export default function Reports() {
  const dispatch = useDispatch<AppDispatch>()
  const [reportModal, setreportModal] = useState(false)
  const [coordinatorLevel, setcoordinatorLevel] = useState<number | undefined>(undefined)
  const { _ } = useLingui()
  const { i18n } = useLingui()
  const { me } = useSelector((state: RootState) => state.me)
  const {
    data: reports,
    isLoading,
    exportSuccess
  } = useSelector((state: RootState) => state.reportsHeadCoordinator)
  const { competitionYear } = useSelector((state: RootState) => state.competitionYearNavbar)
  const durationType = useSelector(
    (state: RootState) => state.reportsHeadCoordinatorFilter.durationType
  )
  const beginDate = useSelector((state: RootState) => state.reportsHeadCoordinatorFilter.beginDate)
  const endDate = useSelector((state: RootState) => state.reportsHeadCoordinatorFilter.endDate)
  useEffect(() => {
    if (me && me?.data?.coordinator) {
      // setcoordinatorLevel(getCoordinatorLevel(me.data?.coordinator))
      setcoordinatorLevel(1)
    }
  }, [me])

  const tabsTypeA = [
    { id: 'byRegion', label: _(msg`المناطق`.id) },
    { id: 'byEducationStage', label: _(msg`المرحلة التعليمية`.id) }
  ]
  const tabsTypeB = [
    { id: 'byRegion', label: _(msg`المناطق`) },
    { id: 'byAdministration', label: _(msg`الإدارة التعليمية`) }
  ]
  const handleExportModal = () => {
    setreportModal(!reportModal)
  }

  const reportData2 = [
    { itemName: 'جدة', numberOfApplications: 10, progress: 22 },
    { itemName: 'مكة المكرمة', numberOfApplications: 5, progress: 35 },
    { itemName: 'الرياض', numberOfApplications: 8, progress: 50 },
    { itemName: 'المدينة المنورة', numberOfApplications: 6, progress: 15 },
    { itemName: 'الدمام', numberOfApplications: 4, progress: 40 }
  ]
  const optionAdministration = [
    {
      key: '1',
      label: _(msg`أعلى 5 إدارات تعليمية`)
    },
    {
      key: '2',
      label: _(msg`جميع الإدارات التعليمية`)
    }
  ]
  const optionAdministrationB = [
    {
      key: '1',
      label: _(msg`أعلى 5 إدارات تعليمية`)
    },
    {
      key: '3',
      label: _(msg`مشاركات الذكور والإناث`)
    }
  ]

  const fetchReports = () => {
    dispatch(
      fetchApplicationStatistics({
        competition_year_id: competitionYear?.id
      })
    )
  }
  useEffect(() => {
    fetchReports()
  }, [competitionYear])
  const {
    dataRegions,
    educationStagesData,
    dataEducationStagesProgress,
    dataTopCoordinators,
    dataTopTeachers,
    EducationAdministativeData,
    universitiesApplications,
    reachPercentage
  } = useReportData(reports as Report, i18n.locale as 'ar' | 'en')

  const onApplyFilter = () => {
    let prams =
      durationType + '' == 'custom'
        ? {
            competition_year_id: competitionYear?.id,
            submission_at_from: new Date(beginDate as string),
            submission_at_to: new Date(endDate as string)
          }
        : {
            competition_year_id: competitionYear?.id
          }
    dispatch(fetchApplicationStatistics(prams))
  }
  const onResetFilter = () => {
    dispatch(
      fetchApplicationStatistics({
        competition_year_id: competitionYear?.id
      })
    )
  }
  const onBack = () => {
    dispatch(setExportSuccess(false))
  }
  useEffect(() => {
    if (exportSuccess) {
      setreportModal(false)
    }
  }, [exportSuccess])
  return (
    <div>
      {!isLoading && (
        <div>
          <ReportHeader
            onResetFilter={onResetFilter}
            onApplyFilter={onApplyFilter}
            onrefresh={fetchReports}
            handleExportModal={handleExportModal}
          />
          <div className="flex flex-col lg:flex-row gap-4 justify-between">
            <div className="flex-1">
              <ReportWrapper
                chartProgress={dataEducationStagesProgress}
                totalApplications={reports?.totalApplications}
                cardTitle={_(msg`المناطق`)}
                includeTabs={true}
                tabs={tabsTypeA}
                contentType="regionalStagesApplications"
                data={{ byRegion: dataRegions, byEducationStage: educationStagesData }}
              />

              {/* <ReportWrapper
              selectOptions={optionAdministrationB}
              includeSelect
              totalApplications={reports?.totalApplications}
              cardTitle={_(msg`الأدارات التعليمية`)}
              includeTabs={false}
              contentType="administrativesApplications"
              data={EducationAdministativeData}
            /> */}
            </div>

            <div className="flex-1">
              {/*           {coordinatorLevel == 1 ? (
               */}
              <ReportWrapper
                selectOptions={optionAdministration}
                includeSelect
                totalApplications={reports?.totalApplications}
                cardTitle={_(msg`الإدارات التعليمية`)}
                tabs={tabsTypeB}
                contentType="administrativesApplications"
                data={EducationAdministativeData}
              />

              {/*   <ReportWrapper
              totalApplications={reports?.totalApplications}
              cardTitle={_(msg`المراحل التعليمية`)}
              includeTabs={true}
              tabs={tabsTypeB}
              contentType="administrativesApplications"
              data={EducationAdministativeData}
            /> */}
            </div>
          </div>
          <ReportWrapper
            totalApplications={reports?.totalApplications}
            cardTitle={_(msg`أعلى المنسقين`)}
            includeTabs={true}
            tabs={tabsTypeB}
            chartData={dataTopCoordinators}
            contentType="topCoordinators"
            data={reportData2}
          />
          <ReportWrapper
            totalApplications={reports?.totalApplications}
            cardTitle={_(msg`أعلى المعلمين `)}
            includeTabs={true}
            tabs={tabsTypeB}
            chartData={dataTopTeachers}
            contentType="topTeachers"
            data={reportData2}
          />
          <div className="flex flex-col lg:flex-row gap-4 justify-between">
            <div className="flex-1">
              <ReportWrapper
                cardTitle={_(msg`المشاركات بالجامعات`)}
                includeTabs={false}
                tabs={tabsTypeA}
                chartData={dataTopCoordinators}
                contentType="universitiesApplications"
                data={universitiesApplications ?? []}
              />
            </div>
            <div className="flex-1">
              <ReportWrapper
                translationKey={_(msg`من الرقم المستهدف`)}
                cardTitle={_(msg`مدى تحقيق المنسقين للرقم المتسهدف`)}
                includeTabs={false}
                tabs={tabsTypeA}
                chartData={dataTopCoordinators}
                contentType="reachPercentage"
                data={reachPercentage ?? []}
              />
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="flex flex-1 w-full justify-center">
          <Spinner size="lg" color="primary"></Spinner>
        </div>
      )}
      {reportModal && (
        <ModalLayout forOthersPage backdrop="opaque" onBack={handleExportModal}>
          <Export></Export>
        </ModalLayout>
      )}
      {exportSuccess && (
        <ExportConfirmation
          onBack={onBack}
          title={_(msg`تم تصدير تقريرك بنجاح`)}
          subtitle={_(msg`عند إعداد التقرير سيتم إرساله إلى البريد الإلكتروني الخاص بكم`)}
        ></ExportConfirmation>
      )}{' '}
    </div>
  )
}
