import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { Data } from 'api/ithraaApisTypes'

interface ApplicationStatisticsState {
  isLoading: boolean
  error: string | null
  data: Data | null
  exportSuccess: boolean
}

const initialState: ApplicationStatisticsState = {
  isLoading: false,
  error: null,
  data: null,
  exportSuccess: false
}

export const fetchIthraaApplicationStatisticsCharts = createAsyncThunk(
  'fetchApplicationStatistics',
  async (
    params: {
      export?: boolean
      export_as?: 'csv' | 'pdf'
      top_n?: number
      competition_year_id?: number
      keys?: string[]
      submission_at_from?: Date
      submission_at_to?: Date
    },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.get('/management/applications-statistics', {
        params: {
          export: params.export,
          export_as: params.export_as,
          top_n: params.top_n,
          competition_year_id: params.competition_year_id,
          keys: params.keys,
          submission_at_from: params.submission_at_from,
          submission_at_to: params.submission_at_to
        }
      })
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const chartsIthraaReportsSlice = createSlice({
  name: 'applicationStatistics',
  initialState,
  reducers: {
    setExportSuccess: (state, action) => {
      state.exportSuccess = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchIthraaApplicationStatisticsCharts.pending, (state, action) => {
        if (!action.meta.arg.export) state.isLoading = true
        state.error = null
      })
      .addCase(fetchIthraaApplicationStatisticsCharts.fulfilled, (state, action) => {
        if (action.meta.arg.export) {
          state.exportSuccess = true
        } else {
          state.data = action.payload?.data
        }
        state.isLoading = false
      })
      .addCase(fetchIthraaApplicationStatisticsCharts.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})
export const { setExportSuccess } = chartsIthraaReportsSlice.actions

export default chartsIthraaReportsSlice
