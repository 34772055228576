import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { IthraUsersResponse, IthraUser } from 'api/types'

interface TeacherState {
  teacherUsers: IthraUser[]
  isLoading: boolean
  error: string | null
}

const initialState: TeacherState = {
  teacherUsers: [],
  isLoading: false,
  error: null
}

export const fetchTeacherUsers = createAsyncThunk('teacherUsers/fetchTeacherUsers', async () => {
  const response = await axiosInstance.get<IthraUsersResponse>('/management/users', {
    params: {
      type: 'teacher'
    }
  })
  return response.data
})

const teacherUsersSlice = createSlice({
  name: 'teacherUsers',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTeacherUsers.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchTeacherUsers.fulfilled, (state, action) => {
        state.isLoading = false
        const teacherUsers = action.payload.data ?? []
        state.teacherUsers = teacherUsers
      })
      .addCase(fetchTeacherUsers.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default teacherUsersSlice
