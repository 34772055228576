import React, { useEffect, useState, ReactNode } from 'react'
import { ScrollProvider } from 'pages/landing/context/scroll-context'
import Layout from './layout'
import { DeviceProvider } from './context/device-context'
import Loader from 'components/base/loader'
import { useLocation } from 'react-router-dom'

interface WrapperProps {
  children: ReactNode
  navbar?: boolean
  footer?: boolean
  isScrolled?: boolean
  showLoader?: boolean // This prop can control the visibility of the loader
}

const Wrapper: React.FC<WrapperProps> = ({
  children,
  navbar = true,
  footer = true,
  isScrolled,
  showLoader = true
}) => {
  const [loading, setLoading] = useState(true)
  const location = useLocation()

  // const location = useLocation()
  // useEffect(() => {
  //   setLoading(true)
  //   const timer = setTimeout(() => {
  //     setLoading(false)
  //   }, 2000)
  //   return () => {
  //     clearTimeout(timer)
  //     setLoading(false)
  //   }
  // }, [location])
  useEffect(() => {
    const hiddenLoaderPaths = ['/login', '/register', '/reset-password', '/forgot-password']
    if (hiddenLoaderPaths.includes(location.pathname)) {
      setLoading(false)
      return
    }
    if (showLoader) {
      const timer = setTimeout(() => {
        setLoading(false)
      }, 5800)

      return () => clearTimeout(timer)
    } else {
      setLoading(false)
    }
  }, [showLoader, location])

  return (
    <DeviceProvider>
      <ScrollProvider>
        <Loader isVisible={loading} />
        <div
          className={`transition-opacity duration-500 ease-in-out ${loading ? 'opacity-0' : 'opacity-100'}`}
        >
          <Layout navbar={navbar} footer={footer} isScrolled={isScrolled}>
            {children}
          </Layout>
        </div>
      </ScrollProvider>
    </DeviceProvider>
  )
}

export default Wrapper
