import { msg, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import ArrowIcon from 'assets/icons/ArrowIcon'
import { ArrowLeft } from 'assets/icons/ArrowLeft'
import Alert from 'components/alert/alert'
import AppTabs from 'components/apps/Tabs'
import OnBoardingMsgs from 'components/onBoardingMsgs/OnBoardingmsgs'
import useLanguage from 'hooks/useLanguage'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import { fetchApps } from 'store/slices/applicationsSlice'
import unseenApps from 'utils/unseenApps'
import CardsSection from '../components/CardsSection'
import AwardsProgress from '../components/AwardsProgress'

const CoordinatorAccount = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { _ } = useLingui()
  const language = useLanguage()
  const isAr = language === 'ar'

  const user = useSelector((s: RootState) => s.me.me?.data! || {})
  const { competitionYear } = useSelector((state: RootState) => state.competitionYearNavbar)
  const { apps, isLoading, page, per_page, total_pages } = useSelector(
    (state: RootState) => state.appsStates
  )

  const { account_type } = user
  const unseenAppsNum = unseenApps(user, apps)

  useEffect(() => {
    dispatch(
      fetchApps({
        user: account_type || '',
        competition_year_id: competitionYear?.id.toString()
      })
    )
  }, [dispatch, competitionYear, account_type])

  return (
    <>
      {!!unseenAppsNum && (
        <Alert
          type="success"
          sizeMainText="md"
          mainText={`${_(msg`قام`)} ${unseenAppsNum > 1 ? unseenAppsNum : ''} ${unseenAppsNum === 1 ? _(msg`طالب جديد`) : _(msg`طلاب جدد`)} ${_(msg`بالتسجيل من خلالك، يمكنك الاطلاع على مشاركاتهم.`)}`}
          onButtonClick={() => navigate('/app/applications')}
          buttonText={`${_(msg`الاطلاع على المشاركات`)}`}
          btnProps={{
            iconEnd: (
              <div className={!isAr ? 'rotate-180' : ''}>
                <ArrowLeft />
              </div>
            )
          }}
        />
      )}
      <OnBoardingMsgs />

      <div className="flex flex-col w-full">
        <div className="w-full flex flex-col gap-9 py-9">
          <CardsSection />
          <div className="first-step second-step third-step">
            <AwardsProgress />
          </div>
        </div>

        <div className="w-full md:flex flex-col gap-y-3 py-9 fourth-step">
          <div className="flex w-full justify-between items-center my-2.5">
            <h2 className="font-heading-h4-ar text-natural_lv1_text_normal">
              <Trans>مشاركات الطلاب</Trans>
            </h2>

            <button
              onClick={() => navigate('/app/applications')}
              className="flex gap-x-1 items-center underline text-natural_lv1_text_normal"
            >
              <Trans>مشاهدة الكل</Trans>
              <div className="rotate-180">
                <ArrowIcon width={16} height={16} fill="#2AACAD" />
              </div>
            </button>
          </div>

          <AppTabs
            apps={apps}
            page={page}
            isCoordinator
            showPagination={false}
            per_page={per_page}
            isLoading={isLoading}
            total_pages={total_pages}
          />
        </div>
      </div>
    </>
  )
}

export default CoordinatorAccount
