import React from 'react'
import ButtonVariant from 'components/button-variant/button'
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'

interface ButtonGroupProps {
  onConfirm: () => void
  onCancel: () => void
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({ onConfirm, onCancel }) => {
  const { _ } = useLingui()

  return (
    <div className="absolute bottom-0 inset-x-0 py-4 px-5 w-full flex gap-2 justify-end border-t border-[#E0E0E0] bg-background_creamy">
      <div><ButtonVariant size='sm' variant="secondary" onClick={onConfirm} >{_(msg`تأكيد`)}</ButtonVariant></div>
      <div><ButtonVariant size='sm' variant="bordered" onClick={onCancel} >{_(msg`الغاء`)}</ButtonVariant></div>
    </div>
  )
}

export default ButtonGroup
