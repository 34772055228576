import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { z } from 'zod'

export const useRestAccountPasswordSchema = () => {
  const { _ } = useLingui()

  return z
    .object({
      currentPassword: z.string({
        required_error: _(msg`هذا الحقل مطلوب`)
      }),
      newPassword: z
        .string({
          required_error: _(msg`هذا الحقل مطلوب`)
        })
        .min(8, {
          message: _(msg`يحتوي على 8 أحرف على الأقل`)
        })
        .regex(/[A-Z]/, {
          message: _(msg`يحتوي على حرف كبير واحد على الأقل`)
        })
        .regex(/[a-z]/, {
          message: _(msg`يحتوي على حرف صغير واحد على الأقل`)
        })
        .regex(/\d/, {
          message: _(msg`يحتوي على رقم واحد على الأقل`)
        })
        .regex(/[!@#$%^&*()]/, {
          message: _(msg`يحتوي على رمز مميز واحدة على الأقل`)
        }),
      confirmNewPassword: z.string({
        required_error: _(msg`هذا الحقل مطلوب`)
      })
    })
    .superRefine(({ newPassword, confirmNewPassword }, ctx) => {
      if (newPassword !== confirmNewPassword) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: _(msg`كلمة المرور وتأكيد كلمة المرور غير متطابقين`),
          path: ['confirmNewPassword']
        })
      }
    })
}
