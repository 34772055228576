import dayjs from 'dayjs'
import 'dayjs/locale/ar'
import 'dayjs/locale/en'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advanced from 'dayjs/plugin/advancedFormat'

dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(advanced)
dayjs.extend(relativeTime)

// Set locale based on layout direction
dayjs.locale(localStorage.getItem('layoutDirection') === 'rtl' ? 'ar' : 'en')

export const extractYear = (date: string) => dayjs(date).format('YYYY');

function getTimeZone() {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return timeZone
}

export const dd_mm_yyyy = (time: string, type: '-' | '/' = '/') =>
  dayjs(time).format(`DD${type}MM${type}YYYY`)

export const yyyy_mm_dd = (time: string) => dayjs(time).format('YYYY-MM-DD')

export function formatTime(date: Date | string, language: 'en' | 'ar'): string {
  dayjs.locale(language)
  let formattedTime = dayjs(new Date(date)).tz(getTimeZone()).format('h:mm A')

  if (language === 'ar') {
    formattedTime = formattedTime.replace('AM', 'ص').replace('PM', 'م')
  }

  return formattedTime
}

export function formatDateTime(date: Date | string, language: 'en' | 'ar'): string {
  dayjs.locale(language)
  let formattedDateTime = dayjs(new Date(date)).tz(getTimeZone()).format('DD/MM/YYYY h:mm A')

  if (language === 'ar') {
    formattedDateTime = formattedDateTime.replace('AM', 'ص').replace('PM', 'م')
  }

  return formattedDateTime
}

export const timeAgo = (time: string) => dayjs(time).fromNow()

export const amPm = (date: string) => {
  let formattedTime = dayjs(date).tz(getTimeZone()).format('h:mm A')

  // Adjust AM/PM for Arabic
  if (dayjs.locale() === 'ar') {
    formattedTime = formattedTime.replace('AM', 'ص').replace('PM', 'م')
  }

  return formattedTime
}

export const formatDateNow = () => {
  return dayjs().format('DD/MM/YYYY - hh:mm A')
}
