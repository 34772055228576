import { Button, ButtonProps } from '@nextui-org/react'
import { FC, ReactNode } from 'react'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

export interface ButtonVariantProps extends Omit<ButtonProps, 'children' | 'variant'> {
  fontBold?: boolean
  children?: ReactNode
  variant?:
    | 'primary'
    | 'secondary'
    | 'outline'
    | 'clear'
    | 'danger-default'
    | 'danger-outline'
    | 'danger-alert'
    | 'success-default'
    | 'success-outline'
    | 'success-alert'
    | 'grey-default'
    | 'grey-outline'
    | 'secondary-default'
    | 'secondary-outline'
    | 'info-alert'
    | 'bordered'
    | 'ghost'
    | 'light'
  size?: 'sm' | 'md' | 'lg'
  active?: boolean
  disabled?: boolean
  iconStart?: ReactNode
  iconEnd?: ReactNode
  iconRotate?: boolean
}

const sizeStyles: Record<string, string> = {
  sm: 'px-4 py-2.5 font-heading-h6-ar',
  md: 'px-4 py-2.5 font-heading-h6-ar',
  lg: 'px-6 py-3.5 font-heading-h6-ar'
}

const variantStyles = (active: boolean, disabled: boolean): Record<string, string> => ({
  primary: `rounded-full bg-secondary_fill_normal_pressed text-primary_lv1_text_normal hover:bg-secondary_fill_hover focus:bg-secondary_fill_normal_pressed ${active ? 'bg-secondary_fill_active shadow-focus-effect' : ''} ${disabled ? 'bg-natural_fill_disabled text-natural_lv2_text_disabled cursor-not-allowed' : ''}`,

  secondary: `rounded-full bg-primary_fill_normal_pressed text-primary_lv1_text_normal hover:bg-sprimary_fill_hover focus:bg-primary_fill_normal_pressed ${active ? 'bg-secondary_fill_active shadow-focus-effect' : ''} ${disabled ? 'bg-natural_fill_disabled text-natural_lv2_text_disabled cursor-not-allowed' : ''}`,

  outline: `rounded-xl
  bg-transparent border-2 border-natural_stroke_normal_disabled text-primary_stroke_normal
  hover:bg-primary_fill_normal_pressed hover:text-white
  focus:bg-primary_fill_hover focus:border-primary_fill_hover focus:text-white
  ${active ? 'bg-primary_fill_active text-white shadow-focus-effect' : ''}
  ${disabled ? 'border-natural_lv2_text_disabled text-natural_lv2_text_disabled cursor-not-allowed' : ''}
`,

  bordered: `rounded-full
  bg-transparent border-2 border-primary_fill_normal_pressed text-primary_stroke_normal
  hover:bg-primary_fill_normal_pressed hover:text-white
  focus:bg-primary_fill_hover focus:border-primary_fill_hover focus:text-white
  ${active ? 'bg-primary_fill_active text-white shadow-focus-effect' : ''}
  ${disabled ? 'border-natural_lv2_text_disabled text-natural_lv2_text_disabled cursor-not-allowed' : ''}
`,

  clear: `bg-transparent rounded-full`,

  light: `bg-transparent rounded-full border-none `,

  'danger-default': `rounded-full
    bg-danger_default_fill_normal_active text-danger_default_lv1_text_normal_active_hover_pressed hover:bg-danger_default_fill_hover focus:bg-danger_default_fill_pressed ${
      active ? 'bg-danger_default_fill_pressed shadow-focus-effect' : ''
    } ${disabled ? 'bg-danger_default_stroke_active text-danger_default_lv2_text_normal_active cursor-not-allowed' : ''}`,

  'grey-default': `rounded-full
    bg-natural_stroke_normal_disabled text-natural_lv2_text_normal hover:bg-natural_stroke_hover_active focus:bg-natural_stroke_pressed ${
      active ? 'bg-natural_stroke_pressed shadow-focus-effect' : ''
    } ${disabled ? 'bg-natural_fill_disabled text-natural_lv2_text_disabled cursor-not-allowed' : ''}`,

  'grey-outline': `rounded-full
    bg-transparent border-2 border-natural_stroke_normal_disabled text-natural_lv2_text_normal
    hover:bg-natural_stroke_hover_active hover:text-white
    focus:bg-natural_stroke_pressed focus:text-white
    ${active ? 'bg-natural_stroke_pressed shadow-focus-effect' : ''}
    ${disabled ? 'border-natural_stroke_normal_disabled text-natural_lv2_text_disabled cursor-not-allowed' : ''}
  `,

  'danger-outline': `rounded-full
    bg-transparent border-2 border-danger_default_stroke_normal text-danger_default_lv1_text_normal_active_hover_pressed
    hover:bg-danger_default_fill_hover hover:text-white
    focus:bg-danger_default_fill_pressed focus:text-white
    ${active ? 'bg-danger_default_fill_pressed shadow-focus-effect' : ''}
    ${disabled ? 'border-danger_default_stroke_active text-danger_default_lv2_text_normal_active cursor-not-allowed' : ''}
  `,

  'secondary-default': `rounded-full
    bg-secondary_fill_normal_pressed text-white hover:bg-secondary_fill_hover focus:bg-secondary_fill_normal_pressed ${
      active ? 'bg-secondary_fill_active shadow-focus-effect' : ''
    } ${disabled ? 'bg-natural_fill_disabled text-natural_lv2_text_disabled cursor-not-allowed' : ''}
  `,

  'secondary-outline': `rounded-full
    bg-transparent border-2 border-secondary_fill_normal_pressed text-secondary_fill_normal_pressed
    hover:bg-secondary_fill_normal_pressed hover:text-white
    focus:bg-secondary_fill_normal_pressed focus:text-white
    ${active ? 'bg-secondary_fill_active shadow-focus-effect' : ''}
    ${disabled ? 'border-natural_lv2_text_disabled text-natural_lv2_text_disabled cursor-not-allowed' : ''}
  `,

  'danger-alert': `rounded-full
    bg-transparent border-2 border-danger_default_fill_normal_active text-danger_default_fill_normal_active
    hover:bg-danger_default_fill_hover hover:text-white
    focus:bg-danger_inverted_stroke_normal focus:text-white
    ${active ? 'bg-danger_inverted_stroke_normal shadow-focus-effect' : ''}
    ${disabled ? 'border-danger_default_stroke_active text-danger_default_lv2_text_normal_active cursor-not-allowed' : ''}
  `,

  'success-default': `rounded-full
    bg-success_default_fill_normal_hover_pressed_active text-success_default_lv1_text_normal_hover_active hover:bg-success_default_stroke_hover focus:bg-success_default_stroke_pressed ${
      active ? 'bg-success_default_stroke_pressed shadow-focus-effect' : ''
    } ${disabled ? 'bg-success_default_icon_normal text-success_default_lv2_text_normal_active cursor-not-allowed' : ''}`,

  'success-outline': `rounded-full
    bg-transparent border-2 border-success_default_stroke_normal text-success_default_lv1_text_normal_hover_active
    hover:bg-success_default_stroke_hover hover:text-white
    focus:bg-success_default_stroke_pressed focus:text-white
    ${active ? 'bg-success_default_stroke_pressed shadow-focus-effect' : ''}
    ${disabled ? 'border-success_default_stroke_active text-success_default_lv2_text_normal_active cursor-not-allowed' : ''}
  `,

  'success-alert': `rounded-full
    bg-transparent border-2 border-success_default_stroke_pressed text-success_default_stroke_pressed
    hover:bg-success_default_stroke_hover hover:text-white
    focus:bg-success_default_stroke_pressed focus:text-white
    ${active ? 'bg-success_default_stroke_pressed shadow-focus-effect' : ''}
    ${disabled ? 'border-success_default_stroke_active text-success_default_lv2_text_normal_active cursor-not-allowed' : ''}
  `,

  'info-alert': `
  bg-transparent border-2 border-information_default_stroke_pressed text-information_default_stroke_pressed
  hover:bg-success_default_stroke_hover hover:text-white
  focus:bg-success_default_stroke_pressed focus:text-white
  ${active ? 'bg-success_default_stroke_pressed shadow-focus-effect' : ''}
  ${disabled ? 'border-success_default_stroke_active text-success_default_lv2_text_normal_active cursor-not-allowed' : ''}
`
})

const ButtonVariant: FC<ButtonVariantProps> = ({
  fontBold,
  children,
  variant = 'primary',
  size = 'md',
  active = false,
  disabled = false,
  isLoading = false,
  iconStart,
  iconEnd,
  iconRotate,
  hidden = false,
  className,
  ...other
}) => {
  const styles = variantStyles(active, disabled)
  const direction = useSelector((state: RootState) => state.layout.direction)

  return (
    <Button
      className={clsx(styles[variant], sizeStyles[size], { 'font-bold': fontBold }, className)}
      style={{ display: hidden ? 'none' : undefined }}
      disabled={disabled}
      isLoading={isLoading}
      {...other}
    >
      <div
        className={`flex items-center gap-1 ${direction === 'rtl' ? 'flex-row-reverse' : 'flex-row'}`}
      >
        {direction === 'rtl' ? (
          <>
            {iconEnd && <span className={`${iconRotate ? 'rotate-180' : ''}`}>{iconEnd}</span>}
            {children && children}
            {iconStart && <span className={`${iconRotate ? '' : 'rotate-180'}`}>{iconStart}</span>}
          </>
        ) : (
          <>
            {iconStart && <span className={`${iconRotate ? 'rotate-180' : ''}`}>{iconStart}</span>}
            {children && children}
            {iconEnd && <span className={`${iconRotate ? '' : 'rotate-180'}`}>{iconEnd}</span>}
          </>
        )}
      </div>
    </Button>
  )
}

export default ButtonVariant
