import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import TabsNavigator from 'components/tabs-navigator/tabs-navigator'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import ApplicationHistory from './tabs/ApplicationHistory'
import { FormDetails } from './tabs/FormDetails'
import useDocumentTitle from 'hooks/useDocumentTitle'

const ApplicationDetails = ({ idle }: { idle?: boolean }) => {
  useDocumentTitle(msg`تفاصيل المشاركة`)
  const { _ } = useLingui()
  const { account_type } = useSelector((s: RootState) => s.me.me?.data! || {})
  const isStudent = account_type ? account_type === 'student' : true

  const tabItems = [
    {
      key: 'application-details',
      title: _(msg`نموذج  المشاركة`),
      content: <FormDetails idle={idle} />
    }
  ]

  if (!idle) {
    tabItems.push({
      key: 'application-history',
      title: _(msg`سجل المشاركة`),
      content: <ApplicationHistory />
    })
  }
  return (
    <>
      <div className="flex flex-col size-full">
        <TabsNavigator
          variant="underlined"
          color="success"
          items={isStudent ? tabItems : [tabItems[0]]}
        />
      </div>
    </>
  )
}

export default ApplicationDetails
