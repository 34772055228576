import { useLingui } from '@lingui/react'
import { MutableRefObject, useState } from 'react'
import { ISearchWorkflowApp } from 'store/slices/workflowAppsSlice'
import RangeLabel from './RangeLabel'
import DateField from 'components/date-picker-localized/date-picker'
import { msg } from '@lingui/macro'

type dateValue = { from?: string; to?: string }

interface DPProps {
  name: { from: keyof ISearchWorkflowApp; to: keyof ISearchWorkflowApp }
  queryRef: MutableRefObject<Partial<ISearchWorkflowApp>>
  label?: { from: string; to: string }
}

const RangeDatePicker = ({ name, queryRef, label }: DPProps) => {
  const { _ } = useLingui()
  const [value, setValue] = useState<dateValue>({
    from: (queryRef.current[name.from] as string) || undefined,
    to: (queryRef.current[name.to] as string) || undefined
  })

  const onChange = (type: 'from' | 'to') => {
    return (date: Date | null) => {
      queryRef.current[name[type]] = date?.toString() as any
      setValue((v) => ({ ...v, [type]: date?.toString() }))
    }
  }

  return (
    <div className="flex gap-x-4">
      <div>
        <RangeLabel value={label?.from} />
        <DateField
          className="mt-2"
          placeholder={_(msg`من`)}
          value={value.from || null}
          onChange={onChange('from')}
        />
      </div>

      <div>
        <RangeLabel value={label?.to} />
        <DateField
          className="mt-2"
          placeholder={_(msg`الى`)}
          value={value.to || null}
          onChange={onChange('to')}
        />
      </div>
    </div>
  )
}

export default RangeDatePicker
