import React from 'react'
import ModalHeader from 'components/modal/modal-header'
import ModalLayout from 'components/modal/modal-layout'
import { Trans } from '@lingui/macro'
import { msg } from '@lingui/macro'
import AddUserForm from '../forms/AddUserForm'

interface props {
    onCancel: () => void
    setShowAlert: (showAlert: { type: 'success' | 'error'; message: string } | null) => void
    setShowAddUserModal: (showAddUserModal: boolean) => void
  }
  
  const AddUserModal: React.FC<props> = ({ onCancel, setShowAlert, setShowAddUserModal }) => {

  return (
    <ModalLayout
    onBack={onCancel}
      accountPageStyle
      header={
        <ModalHeader
          headerText={msg`إضافة مستخدم جديد`}
          subheaderText={<Trans>يرجي إدخال البيانات التالية من اجل إضافة مستخدم جديد</Trans>}
        />
      }
      body={<AddUserForm setShowAlert={setShowAlert} setShowAddUserModal={setShowAddUserModal} />}
    />
  )
}

export default AddUserModal
