import { Trans } from '@lingui/macro'
import { ArrowLeft } from 'assets/icons/ArrowLeft'
import PromptMsg from 'pages/TeacherPages/components/PromptMsg'
import React from 'react'
import { Link } from 'react-router-dom'

interface PointsCardprops {
  points: number
}

const PointsCard: React.FC<PointsCardprops> = ({ points }) => {
  return (
    <div className="flex flex-col justify-between items-center md:w-[224px]">
      {points === 0 && (
        <>
          <div className="absolute inset-0 backdrop-blur-[1.9px] flex items-center justify-center z-10" />
          <div className="absolute -top-[65%] inset-x-auto z-20">
            <PromptMsg />
          </div>
        </>
      )}

      <p className="font-heading-h6-ar text-natural_icon_normal capitalize">
        <Trans>النقاط المكتسبة</Trans>
      </p>
      <h2 className="font-sub-heading-h2 text-natural_lv1_text_normal">{points}</h2>

      <Link
        to={'/app/points'}
        className={`md:p-3 gap-3 rounded-md flex items-center transition-all duration-300 second-step`}
      >
        <p className="font-heading-h6 text-secondary_fill_normal_pressed">
          <Trans>النقاط و الجوائز</Trans>
        </p>
        <ArrowLeft fill="#7ECD33" />
      </Link>
    </div>
  )
}

export default PointsCard
