import React from 'react'
import TextInput from 'components/hook-form/text-input'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import HelperText from '../components/HelperText'

const ContactInfoForm = () => {
  const { _ } = useLingui()

  return (
    <>
      <TextInput
        name="contact_info.phone"
        width="100%"
        label={_(msg`رقم الجوال`)}
        type="phone-number"
      />
      <div className="flex flex-col gap-2">
        <TextInput
          name="contact_info.email"
          label={_(msg`البريد الإلكتروني`)}
          type="email"
          disabled={true}
        />
        <HelperText text={_(msg`يمكنك تغيير عنوان بريدك الإلكتروني من خلال صفحة الملف الشخصي.`)} />
      </div>
    </>
  )
}

export default ContactInfoForm
