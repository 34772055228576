import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { CompetitionStatus } from 'api/types'
import Badge from 'components/badge/Badge'
import CardLayout from 'components/card/card-layout'
import DataCards from 'components/data-cards/data-cards'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import { fetchCompetitionYears, setPage } from 'store/slices/competitionYearSlice'

const formatDate = (dateString: string): string => {
  const date = new Date(dateString)
  return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' })
}

export const getStatusColor = (status: CompetitionStatus) => {
  switch (status) {
    case 'not_started':
      return 'warning'
    case 'started':
      return 'success'
    case 'ended':
      return 'black'
    case 'terminated':
      return 'danger'
    case 'archived':
      return 'disabled'
    default:
      return ''
  }
}

export const getStatus = (status: CompetitionStatus) => {
  switch (status) {
    case 'not_started':
      return msg`لم يبدأ`
    case 'started':
      return msg`بدأ`
    case 'ended':
      return msg`انتهى`
    case 'terminated':
      return msg`تم انهاء الخدمة`
    case 'archived':
      return msg`مؤرشفة`
  }
}

export const Competitions: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { _ } = useLingui()

  const { competitionYears, isLoading, page, per_page, total_pages } = useSelector(
    (state: RootState) => state.competitionYear
  )

  useEffect(() => {
    dispatch(fetchCompetitionYears({ page, per_page }))
  }, [dispatch, page, per_page])

  const handlePageChange = (newPage: number) => {
    dispatch(setPage(newPage))
  }

  const renderCard = (year: any) => {
    return (
      <CardLayout
        isFullWidth
        key={year.id}
        onClick={() => navigate(`competition-details/${year.id}`)}
      >
        <Badge
          className="!min-w-14"
          placeholderClassName="text-center"
          variant={getStatusColor(year.status) as any}
          placeholder={_(getStatus(year.status)?.id!)}
        />
        <p className="text-xl font-bold text-natural_icon_normal">{year.name}</p>
        <p className="font-medium text-natural_icon_normal">
          {formatDate(year.start_date)} - {formatDate(year.end_date)}
        </p>
      </CardLayout>
    )
  }

  return (
    <DataCards
      data={competitionYears || []}
      isLoading={isLoading}
      page={page}
      per_page={per_page}
      total_pages={total_pages}
      onPageChange={handlePageChange}
      renderCard={renderCard}
    />
  )
}

export default Competitions
