import { i18n } from '@lingui/core'

const getDatePickerLocale = () => ({
  sunday: i18n._('الأحد'),
  monday: i18n._('الأثنين'),
  tuesday: i18n._('الثلاثاء'),
  wednesday: i18n._('الأربعاء'),
  thursday: i18n._('الخميس'),
  friday: i18n._('الجمعة'),
  saturday: i18n._('السبت'),
  january: i18n._('يناير'),
  february: i18n._('فبراير'),
  march: i18n._('مارس'),
  april: i18n._('أبريل'),
  may: i18n._('مايو'),
  june: i18n._('يونيو'),
  july: i18n._('يوليو'),
  august: i18n._('أغسطس'),
  september: i18n._('سبتمبر'),
  September: i18n._('سبتمبر'),
  october: i18n._('أكتوبر'),
  november: i18n._('نوفمبر'),
  december: i18n._('ديسمبر'),
  today: 'اليوم',
  yesterday: 'أمس',
  hours: 'ساعات',
  minutes: 'دقائق',
  seconds: 'ثواني',
  formattedMonthPattern: 'MMM | yyyy',
  formattedDayPattern: 'MMM dd, yyyy',
  weekdaysShort: [
    i18n._('ح'),
    i18n._('ن'),
    i18n._('ث'),
    i18n._('ر'),
    i18n._('خ'),
    i18n._('ج'),
    i18n._('س')
  ],
  months: [
    i18n._('يناير'),
    i18n._('فبراير'),
    i18n._('مارس'),
    i18n._('أبريل'),
    i18n._('مايو'),
    i18n._('يونيو'),
    i18n._('يوليو'),
    i18n._('أغسطس'),
    i18n._('سبتمبر'),
    i18n._('أكتوبر'),
    i18n._('نوفمبر'),
    i18n._('ديسمبر')
  ],
  firstOfWeek: 0
})

export default getDatePickerLocale
