import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { CompetitionYear, CompetitionYearNavbarResponse } from 'api/types'

interface CompetitionYearState {
  competitionYears: CompetitionYear[] | null
  competitionYear: CompetitionYear | null
  isLoading: boolean
  error: string | null
}

const initialState: CompetitionYearState = {
  competitionYears: null,
  competitionYear: null,
  isLoading: false,
  error: null
}

export const fetchCompetitionYearsNavbar = createAsyncThunk(
  'competitionYearNavbar/fetch',
  async () => {
    const response = await axiosInstance.get<CompetitionYearNavbarResponse>('/competition-years')
    return response.data
  }
)
const persistedCompetitionYear: CompetitionYear = localStorage.getItem('competitionYear')
  ? JSON.parse(localStorage.getItem('competitionYear') as string)
  : null
const competitionYearNavbarSlice = createSlice({
  name: 'competitionYearNavbar',
  initialState,
  reducers: {
    setCompetitionYears: (state, action: PayloadAction<CompetitionYear[]>) => {
      state.competitionYears = action.payload
    },
    setCompetitionYear: (state, action: PayloadAction<CompetitionYear>) => {
      state.competitionYear = action.payload
      localStorage.setItem('competitionYear', JSON.stringify(action.payload))
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompetitionYearsNavbar.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchCompetitionYearsNavbar.fulfilled, (state, action) => {
        state.isLoading = false
        state.competitionYears = action.payload.data
        if (
          persistedCompetitionYear &&
          action.payload.data.find((cp) => cp.id == persistedCompetitionYear.id) != undefined
        ) {
          state.competitionYear = persistedCompetitionYear
        } else {
          state.competitionYear = action.payload.data?.[action.payload.data.length - 1] || null
        }
      })
      .addCase(fetchCompetitionYearsNavbar.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export const { setCompetitionYears, setCompetitionYear } = competitionYearNavbarSlice.actions

export default competitionYearNavbarSlice
