import useLanguage from 'hooks/useLanguage'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchEducationLevels } from 'store/slices/educationLevelsSlice'
import { getLocalizedName } from 'utils/getLocalizedName'
import { EducationLevel, EducationLevelOption } from './types'

interface Props {
  countryId?: string | string[]
}

export const useEducationLevels = ({ countryId }: Props = {}) => {
  const dispatch = useDispatch<AppDispatch>()
  const { educationLevels, isLoading, error } = useSelector(
    (state: RootState) => state.educationLevels
  )

  useEffect(() => {
    if (countryId) {
      dispatch(fetchEducationLevels({ countriesIds: countryId }))
    }
  }, [dispatch, countryId])

  return { educationLevels, isLoading, error }
}

export const useEducationLevelsOptions = ({ countryId }: Props = {}) => {
  const language = useLanguage() as 'ar' | 'en'
  const { educationLevels, isLoading, error } = useEducationLevels({ countryId })
  const [selectedEducationStage, setSelectedEducationStage] = useState<string | null>(null)

  const options: EducationLevelOption[] = useMemo(() => {
    if (educationLevels.length) {
      return educationLevels.map((level: EducationLevel) => ({
        key: String(level.id),
        label: getLocalizedName(level.name, language) || 'Unknown',
        educationStage: level.education_stage
      }))
    } else {
      return []
    }
  }, [educationLevels, language])

  const handleEducationLevelChange = (selectedLevelId: string) => {
    const selectedLevel = educationLevels.find(
      (level: EducationLevel) => String(level.id) === selectedLevelId
    )
    if (selectedLevel) {
      setSelectedEducationStage(selectedLevel.education_stage)
    } else {
      setSelectedEducationStage(null)
    }
  }

  return { options, selectedEducationStage, handleEducationLevelChange, isLoading, error }
}
