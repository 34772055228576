import { Avatar } from '@nextui-org/react'
import { getRequestActivityStatus } from 'constants/requestActivityStatus'
import dayjs from 'dayjs'
import React from 'react'
import { msg } from '@lingui/macro'
import './requestActivity.css'
import Stepper from './Stepper'
import CheckMarkIcon from 'assets/icons/CheckMarkIcon'
import MarkIcon from 'assets/icons/MarkIcon'
import AppStatus from 'components/apps/Status'
import { ActivitySchemaType } from '../ReviewRequestSchemaType2'
import { useLingui } from '@lingui/react'

interface RequestActivitesProps {
  activaty?: ActivitySchemaType[]
}

const RequestActivites: React.FC<RequestActivitesProps> = ({ activaty }) => {
  const data = activaty
  const { _, i18n } = useLingui()

  const renderActivityComponent = (status: string, activity: any) => {
    const renderActivityCard = (title: string) => (
      <div className="flex items-center mb-8 gap-2   bg-[#F9FAFB] p-[16px] rounded-md border ">
        <div className="ml-4 flex-grow">
          <h3 className="text-lg font-semibold text-secondary">{title}</h3>
          <p className="text-sm text-gray-500">
            {activity?.created_at
              ? dayjs(new Date(activity?.created_at)).format('DD MMM YYYY hh:mm A')
              : '-'}
          </p>
        </div>
      </div>
    )

    switch (status) {
      case 'coordinator.submit':
        return renderActivityCard(i18n._(msg`تم تقديم طلب تنشيط الحساب`))

      case 'coordinator.approve':
        return (
          <div className="flex items-center mb-8 gap-2 border rounded-md w-full ">
            <div className="ml-4 flex-grow bg-white  rounded-md  p-[16px] ">
              <AppStatus status={getRequestActivityStatus(activity?.description) as any} />

              <h3 className="text-lg font-semibold text-secondary">
                {i18n._(msg`تم تحديث حالة الطلب`)}
              </h3>
              <p className="text-sm text-gray-500">
                {activity?.created_at
                  ? dayjs(new Date(activity?.created_at)).format('DD MMM YYYY hh:mm A')
                  : '-'}
              </p>
              <p className="flex flex-row justify-start items-center text-xs text-gray-400 mt-1 gap-1">
                <Avatar src="" style={{ width: 20, height: 20 }} />
                <span>{i18n._(msg`من قبل`)}</span>
                {activity?.causer?.name}
              </p>
            </div>
          </div>
        )

      case 'coordinator.reject':
        return (
          <div className="flex items-center mb-8 gap-2 w-full">
            <div className="flex-grow bg-[#F9FAFB] p-[16px] rounded-md border">
              <AppStatus status={getRequestActivityStatus(activity?.description) as any} />
              <h3 className="text-lg font-semibold text-black-600">
                {i18n._(msg`تم تحديث حالة الطلب`)}
              </h3>
              <p className="text-sm text-gray-500">
                {activity?.created_at
                  ? dayjs(new Date(activity?.created_at)).format('DD MMM YYYY hh:mm A')
                  : '-'}
              </p>
              <p className="flex flex-row justify-start items-center text-xs text-gray-400 mt-1 gap-1">
                <Avatar src="" style={{ width: 20, height: 20 }} />
                <span>{i18n._(msg`من قبل`)}</span>
                {activity?.causer?.name}
              </p>
              {activity?.description === 'coordinator.reject' && (
                <p className="text-xs text-gray-400 mt-1 flex flex-row justify-start items-start gap-0.5">
                  <span className="text-xs text-gray-400 mt-1">{i18n._(msg`سبب الرفض`)}</span>
                  <span className="text-xs text-gray-400 mt-1">{':'}</span>
                  <span className="text-xs text-gray-400 mt-1">
                    {activity?.properties?.rejection_reason ?? ''}
                  </span>
                </p>
              )}
            </div>
          </div>
        )

      default:
        return <div></div>
    }
  }

  const activityComponents = data?.map((activity) =>
    renderActivityComponent(activity?.description ?? '', activity)
  )

  const getBg_border_Color = (
    status: string
  ): { borderColor: string; bgColor: string; icon: React.ReactNode } => {
    switch (status) {
      case 'coordinator.submit':
        return {
          borderColor: 'border-success',
          bgColor: 'bg-success bg-opacity-30',
          icon: <CheckMarkIcon />
        }
      case 'coordinator.approve':
      case 'coordinator.reject':
        return {
          borderColor: 'border-danger_default_fill_hover',
          bgColor: 'bg-danger_default_fill_hover bg-opacity-30',
          icon: <MarkIcon />
        }
      default:
        return {
          borderColor: 'border-gray-300',
          bgColor: 'bg-gray-300 bg-opacity-30',
          icon: <CheckMarkIcon />
        }
    }
  }

  const steps = data?.map((ac) => ({
    status: ac.description,
    ...getBg_border_Color(ac.description)
  }))
  return (
    <div className=" flex flex-row justify-start  items-start w-full">
      <div className="pt-6">
        <Stepper steps={steps}></Stepper>
      </div>
      <div className="flex flex-col w-full pr-4">{activityComponents}</div>
    </div>
  )
}

export default RequestActivites
