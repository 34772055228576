import { msg } from '@lingui/macro'
import MetricCard from 'components/card/MetricCardLayout'
import NumericCard from 'components/card/NumericCard'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

const CardsSection = () => {

  const { applicationsCount, progressPercentage, totalStudents } = useSelector((state: RootState) => state.coordinatorStats)

  return (
    <div className="mx-auto w-fit flex flex-col md:flex-row justify-center items-center gap-6">
      <MetricCard color="bg-natural_lv1_text_normal">
        <NumericCard
          title={msg`إجمالي عدد الطلاب`.id}
          numericValue={totalStudents}
          color="text-natural_lv1_text_normal"
          emptyMsg={msg`حتى الآن، لم يتم تسجيل اي طالب بعد.`.id}
        />
      </MetricCard>

      <MetricCard color="bg-ithra_purple">
        <NumericCard
          title={msg`عدد الطلاب المشاركين`.id}
          numericValue={applicationsCount}
          color="text-ithra_purple"
          emptyMsg={msg`حتى الآن، لم يتم تسجيل اي طالب بعد.`.id}
        />
      </MetricCard>

      <MetricCard color="bg-natural_lv1_text_normal">
        <NumericCard
          title={msg`عدد المشاركين مقارنة بالعدد الاجمالي`.id}
          numericValue={progressPercentage}
          isPercentage
          color="text-natural_lv1_text_normal"
          emptyMsg={msg`حتى الآن، لم يتم تسجيل اي طالب بعد.`.id}
        />
      </MetricCard>
    </div>
  )
}

export default CardsSection
