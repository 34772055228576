import React from 'react'
import { DateRangePicker, DateValue, RangeValue } from '@nextui-org/react'

import { parseDate } from '@internationalized/date'
import FilterLabel from './FilterLabel'

interface DateRangeFilterProps {
  label: string
  fromDate?: string | null
  toDate?: string | null
  onDateChange: (range: { from: string; to: string }) => void
}

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  label,
  fromDate,
  toDate,
  onDateChange
}) => {
  const handleDateChange = (range: RangeValue<DateValue>) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const from = range.start ? range.start.toDate(timeZone) : null
    const to = range.end ? range.end.toDate(timeZone) : null
    const fromISO = from ? from.toISOString() : ''
    const toISO = to ? to.toISOString() : ''
    onDateChange({ from: fromISO, to: toISO })
  }

  return (
    <div className="flex flex-col gap-0.5 w-full">
      <FilterLabel label={label} />
      <DateRangePicker
        aria-label="date picker"
        value={
          {
            start: fromDate ? parseDate(fromDate.split('T')[0]) : null,
            end: toDate ? parseDate(toDate.split('T')[0]) : null
          } as any
        }
        onChange={handleDateChange}
        color="primary"
        variant="bordered"
        radius="sm"
      />
    </div>
  )
}

export default DateRangeFilter
