import React from 'react'
import { Spinner } from '@nextui-org/react'

const Loader = () => {
  return (
    <div className="size-full flex items-center justify-center">
      <Spinner color="default" labelColor="foreground" size='lg' />
    </div>
  )
}

export default Loader
