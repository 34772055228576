import { extendVariants, Tooltip as nextUiToolTip } from "@nextui-org/react"

export const Tooltip = extendVariants(nextUiToolTip, {
    variants: {
        color: {
          primary: {
            base: '#84cc16 max-w-52 text-center',
            color: '#000',
          },
        },
      },
      defaultVariants: {
        color: 'primary',
      },
    })