import { Trans } from '@lingui/react'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { RouteConfig } from '../routesConfig'
import ArrowDawnIcon from 'assets/icons/ArrowDawnIcon'
import ArrowUpIcon from 'assets/icons/ArrowUpIcon'

interface SidebarRouteProps {
  route: RouteConfig
  basePath: string
  isCollapsed: boolean
  iconMap: { [key: string]: React.FC<{ fill?: string }> }
}

const SidebarRoute: React.FC<SidebarRouteProps> = ({ route, basePath, isCollapsed, iconMap }) => {
  const location = useLocation()
  const isAnyChildActive = route.children?.some((child) => location.pathname.includes(`${basePath}/${route.path}/${child.path}`))
  const [isOpen, setIsOpen] = useState(isAnyChildActive)
  const fullPath = `${basePath}/${route.path}`
  const isActive = location.pathname.includes(fullPath)

  const IconComponent = route.icon ? iconMap[route.icon] : null
  const hasChildren = route.children && route.children.length > 0

  const toggleOpen = () => setIsOpen(!isOpen)

  return (
    <div>
      <Link
        to={hasChildren ? '#' : fullPath}
        onClick={hasChildren ? toggleOpen : () => {}}
        className={`p-3 gap-3 rounded-md flex items-center transition-all duration-300 
        ${isActive && !isOpen ? 'bg-primary_fill_light_overlay' : 'bg-white'} 
        ${isCollapsed ? 'justify-center' : ''} 
        hover:bg-primary_fill_medium_overlay hover:text-white`}
        style={{ cursor: 'pointer' }}
      >
        {IconComponent && (
          <div className="w-5 h-5 flex items-center justify-center">
            <IconComponent fill={'#3F3F46'} />
          </div>
        )}
        {!isCollapsed && (
          <span
            className={`text-natural_icon_normal font-label-h5-radix transition-opacity duration-300 ${
              hasChildren ? 'flex-1' : ''
            }`}
          >
            <Trans id={route.label} />
          </span>
        )}
        {hasChildren && !isCollapsed && (
          <button onClick={toggleOpen} className="text-gray-500 focus:outline-none">
            {isOpen ? <ArrowUpIcon /> : <ArrowDawnIcon />}
          </button>
        )}
      </Link>
      {hasChildren &&
        isOpen &&
        route.children?.map((childRoute: RouteConfig) => (
          <SidebarRoute
            key={childRoute.path}
            route={childRoute}
            basePath={fullPath}
            isCollapsed={isCollapsed}
            iconMap={iconMap}
          />
        ))}
    </div>
  )
}

export default SidebarRoute
