import { FC, ReactNode } from 'react'
import { Card, CardHeader, CardBody, CardFooter, Divider } from '@nextui-org/react'
import clsx from 'clsx'

interface CardLayoutProps {
  children?: ReactNode
  onClick?: () => void
  header?: ReactNode
  content?: ReactNode
  footer?: ReactNode
  type?: 'default' | 'report'
  isBordered?: boolean
  isFullWidth?: boolean
  paddig?: string
}

const CardLayout: FC<CardLayoutProps> = ({
  children,
  onClick,
  isBordered = true,
  isFullWidth = false,
  paddig = 'py-8 px-2',
  header,
  content,
  footer,
  type = 'default'
}) => {
  if ((type == 'report')) {
    return (
      <Card onClick={onClick} className="cursor-pointer hover:bg-bgCardHover">
        <CardHeader>{header}</CardHeader>
        <CardBody>{content}</CardBody>
        <Divider></Divider>
        <CardFooter>{footer}</CardFooter>
      </Card>
    )
  } else
    return (
      <div
        className={clsx(
          `flex flex-col w-full gap-2.5 rounded-lg bg-natural_fill_normal_hover_pressed_active ${paddig}`,
          {
            'border border-natural_stroke_normal_disabled': isBordered,
            'max-w-[294px] ': !isFullWidth,
            'cursor-pointer': onClick
          }
        )}
        onClick={onClick}
      >
        {children}
      </div>
    )
}

export default CardLayout
