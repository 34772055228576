import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchStudentActivities } from 'store/slices/studentApplicationHistorySlice'
import { AppDispatch, RootState } from 'store'
import { useParams } from 'react-router-dom'
import Alert from 'components/alert/alert'
import Loader from 'components/loader/Loader'
import VerticalStepper from 'components/vertical-stepper/VerticalStepper'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

const ApplicationHistory: React.FC = () => {
  const { _ } = useLingui()

  const { id } = useParams<{ id: string }>()
  const applicationId = Number(id)

  const dispatch = useDispatch<AppDispatch>()

  const { activities, isLoading, error } = useSelector(
    (state: RootState) => state.applicationHistory
  )

  useEffect(() => {
    if (applicationId) {
      dispatch(fetchStudentActivities(applicationId))
    }
  }, [dispatch, applicationId])

  if (isLoading) {
    return <Loader />
  }

  if (error) {
    return <Alert mainText={error} type="error" />
  }

  if (!activities || activities.length === 0) {
    return (
      <Alert
        mainText={_(msg`لا يوجد سجل لهذه المشاركة`)}
        sizeMainText="md"
        weightMainText="normal"
        type="info"
      />
    )
  }

  return (
    <div>
      <VerticalStepper steps={activities} />
    </div>
  )
}

export default ApplicationHistory
