import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { useState, useEffect } from 'react'
import TextInput from 'components/hook-form/text-input'
import ButtonVariant from 'components/button-variant/button'
import Alert from 'components/alert/alert'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { updatePassword } from 'store/slices/updatePasswordSlice'
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'
import { useRestAccountPasswordSchema } from './schema'

// Utility function to check individual password validation rules
const validatePassword = (password: string) => {
  return {
    hasMinLength: password.length >= 8,
    hasUppercase: /[A-Z]/.test(password),
    hasLowercase: /[a-z]/.test(password),
    hasNumber: /\d/.test(password),
    hasSpecialChar: /[!@#$%^&*()]/.test(password)
  }
}

function RestAccountPassword() {
  const { _, i18n } = useLingui()
  const [showAlert, setShowAlert] = useState<{ type: 'success' | 'error'; message: string } | null>(
    null
  )
  const dispatch = useDispatch<AppDispatch>()
  const restAccountPassword = useRestAccountPasswordSchema()

  const [passwordValidations, setPasswordValidations] = useState({
    hasMinLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecialChar: false
  })

  // Initialize the form
  const methods = useForm({
    resolver: zodResolver(restAccountPassword),
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    }
  })

  const {
    handleSubmit,
    watch, // to watch field changes
    setValue,
    formState: { isSubmitting, errors }
  } = methods

  // Watch new password changes
  const newPassword = watch('newPassword', '')

  // Update password validations as the user types using useEffect
  useEffect(() => {
    const validations = validatePassword(newPassword)
    setPasswordValidations(validations)
  }, [newPassword]) // Only run when newPassword changes

  const onSubmit = async (data: any) => {
    try {
      const result = await dispatch(
        updatePassword({
          current_password: data.currentPassword,
          new_password: data.newPassword
        })
      ).unwrap()

      if (result.status === 'success') {
        setShowAlert({ type: 'success', message: _(msg`تم حفظ التحديثات بنجاح`) })
        // Reset form values
        setValue('currentPassword', '')
        setValue('newPassword', '')
        setValue('confirmNewPassword', '')
      }
    } catch (error: any) {
      const errorMessage = i18n._(error.message) || i18n._(msg`هنالك خطأ`)
      setShowAlert({ type: 'error', message: errorMessage })
    }
  }

  return (
    <FormProvider {...methods}>
      {showAlert && (
        <Alert
          closable
          mainText={showAlert.message}
          sizeMainText="md"
          weightMainText="normal"
          onClose={() => setShowAlert(null)}
          type={showAlert.type}
        />
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-12 w-full"
      >
        <TextInput label={_(msg`كلمة المرور (الحالية)`)} type="password" name="currentPassword" />

        <div className="flex flex-col gap-2">
          <TextInput label={_(msg`كلمة المرور (الجديدة)`)} type="password" name="newPassword" />
          <div className="flex flex-col gap-1 items-start w-full text-primary_lv2_text_normal ">
            {/* Show validation progress */}
            <p
              className={` font-body-h6 ${passwordValidations.hasMinLength ? 'text-success_inverted_lv1_text_normal' : 'text-danger_default_stroke_normal'}`}
            >
              {_(msg`يحتوي على 8 أحرف على الأقل`)}
            </p>
            <p
              className={`font-body-h6 ${passwordValidations.hasUppercase ? 'text-success_inverted_lv1_text_normal' : 'text-danger_default_stroke_normal'}`}
            >
              {_(msg`يحتوي على حرف كبير واحد على الأقل`)}
            </p>
            <p
              className={`font-body-h6 ${passwordValidations.hasLowercase ? 'text-success_inverted_lv1_text_normal' : 'text-danger_default_stroke_normal'}`}
            >
              {_(msg`يحتوي على حرف صغير واحد على الأقل`)}
            </p>
            <p
              className={` font-body-h6 ${passwordValidations.hasNumber ? 'text-success_inverted_lv1_text_normal' : 'text-danger_default_stroke_normal'}`}
            >
              {_(msg`يحتوي على رقم واحد على الأقل`)}
            </p>
            <p
              className={` font-body-h6 ${passwordValidations.hasSpecialChar ? 'text-success_inverted_lv1_text_normal' : 'text-danger_default_stroke_normal'}`}
            >
              {_(msg`يحتوي على رمز مميز واحدة على الأقل`)}
            </p>
          </div>
        </div>

        <TextInput label={_(msg`تأكيد كلمة المرور`)} type="password" name="confirmNewPassword" />

        <div className="col-span-full flex justify-end">
          <ButtonVariant
            type="submit"
            variant="secondary"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            {_(msg`تحديث`)}
          </ButtonVariant>
        </div>
      </form>
    </FormProvider>
  )
}

export default RestAccountPassword
