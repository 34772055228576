import React, { useState, useEffect } from 'react'
import Joyride from 'react-joyride'
import { tourSteps as teacherTourSteps } from 'pages/TeacherPages/TeacherAccount/tourSteps'
import { tourSteps as coordinatorTourSteps } from 'pages/CoordinatorPages/components/tourSteps'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, AppDispatch } from 'store'
import { updateOnboardingStatus } from 'store/slices/updateOnboardingStatus'
import { fetchMe } from 'store/slices/meSlice'
import TourControls from './TourControls'

interface TourStep {
  target: string
  content: JSX.Element
  disableBeacon: boolean
}

const OnBoardingMsgs = () => {
  const dispatch = useDispatch<AppDispatch>()

  const [runTour, setRunTour] = useState(false)
  const [stepIndex, setStepIndex] = useState(0)

  const onboarding = useSelector((state: RootState) => state.me?.me?.data?.onboarding)
  const { userType } = useSelector((state: RootState) => state.auth)

  let tourSteps: TourStep[] = []

  switch (userType) {
    case 'teacher':
      tourSteps = teacherTourSteps
      break
    case 'coordinator':
      tourSteps = coordinatorTourSteps
      break
    default:
      tourSteps = []
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setRunTour(true)
    }, 5800)

    return () => clearTimeout(timer) 
  }, [])

  if (!onboarding) {
    return null
  }

  const handleNext = async () => {
    if (stepIndex < tourSteps.length - 1) {
      setStepIndex((prevIndex) => Math.min(prevIndex + 1, tourSteps.length - 1))
    } else {
      setRunTour(false)
      await dispatch(updateOnboardingStatus(false))
      await dispatch(fetchMe())
    }
  }

  const handlePrev = () => {
    setStepIndex((prevIndex) => Math.max(prevIndex - 1, 0))
  }

  const handleSkip = async () => {
    setRunTour(false)
    setStepIndex(0)
    await dispatch(updateOnboardingStatus(false))
    await dispatch(fetchMe())
  }

  return (
    <>
      <Joyride
        steps={tourSteps}
        run={runTour}
        continuous={true}
        stepIndex={stepIndex}
        showProgress={true}
        showSkipButton={false}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        hideBackButton={true}
        hideCloseButton={true}
        disableScrolling={true}
        styles={{
          options: {
            arrowColor: '#2AACAD',
            backgroundColor: '#2AACAD',
            overlayColor: '#6d9090',
            primaryColor: '#2AACAD',
            textColor: '#FFFFFF',
            beaconSize: 36,
            width: 235,
            zIndex: 10
          },
          buttonNext: {
            backgroundColor: '#FFFFFF33',
            color: '#FFFFFF',
            borderRadius: '50px',
            padding: '8px',
            cursor: 'default',
            margin: 0,
            outline: 'none',
            boxShadow: 'none',
            pointerEvents: 'none'
          },
          tooltip: {
            borderRadius: 12,
            fontSize: 16,
            padding: 12,
            margin: 0,
            display: 'flex',
            flexDirection: 'column-reverse',
            alignItems: 'flex-start',
            pointerEvents: 'none'
          },
          tooltipContent: {
            padding: '2px'
          },
          tooltipFooter: {
            padding: '2px',
            margin: 0
          }
        }}
        locale={{
          next: ''
        }}
      />

      {runTour && (
        <TourControls
          stepIndex={stepIndex}
          stepsLength={tourSteps.length}
          handleNext={handleNext}
          handlePrev={handlePrev}
          handleSkip={handleSkip}
        />
      )}
    </>
  )
}

export default OnBoardingMsgs
