import React, { ReactNode } from 'react'

interface FormLayoutProps {
  children: ReactNode
}

const FormLayout: React.FC<FormLayoutProps> = ({ children }) => {
  return <div className="grid grid-cols-1 md:grid-cols-3 gap-3">{children}</div>
}

export default FormLayout
