import CodeCard from 'pages/TeacherPages/components/CodeCard'
import HowToCalc from 'pages/TeacherPages/TeacherPoints/components/HowToCalc'
import React, { useState } from 'react'
import Alert from 'components/alert/alert'
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

const CalculationMethod = () => {
  const [showAlert, setShowAlert] = useState<{ type: 'success' | 'error'; message: string } | null>(
    null
  )
  const { code } = useSelector((state: RootState) => state.teacherStats)
  const { _ } = useLingui()
  return (
    <>
      {showAlert && (
        <Alert
          closable
          mainText={showAlert.message}
          sizeMainText="md"
          weightMainText="normal"
          onClose={() => setShowAlert(null)}
          type={showAlert.type}
        />
      )}
      <div className="w-full flex flex-col gap-6">
        <CodeCard
                code={code}
                onCopy={() =>
                  setShowAlert({ type: 'success', message: _(msg`تم نسخ الكود بنجاح`) })
                }
                layout="row"
              />
        <HowToCalc />
      </div>
    </>
  )
}

export default CalculationMethod
