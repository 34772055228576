import { msg, Trans } from '@lingui/macro'
import MedalIcon from 'assets/icons/MedalIcon'
import React from 'react'
import { awardsList } from './awardsList'
import { useLingui } from '@lingui/react'

interface props {
  totalStudents: number
}

const HowToCalc: React.FC<props> = ({ totalStudents }) => {
  const { _ } = useLingui()

  const listClass = `flex items-center gap-4`
  const subHeaderStyle = `font-sub-heading-h4-ar text-ithra_purple`

  const howToText = _(msg`عندما تشجع طلابك في إداراتك التعليمية (التي يبلغ عددهم الإجمالي`) + ' ' + String(totalStudents) + ' ' + _(msg`طالب) على التسجيل في المسابقة، فإذا سجل الطالب من إدارتك في المسابقة، سيزيد عدد المشاركين من خلالك ويتم حساب الجوائز وفقًا لنسبة مشاركة الطلاب كما يلي:`)

  return (
    <div className="flex flex-col gap-10">
      <div>
        <h2 className="font-heading-h4-ar text-natural_lv1_text_normal">
          <Trans>كيفية حساب النقاط:</Trans>
        </h2>
        <p className="font-heading-h5-ar text-natural_icon_normal">{howToText}</p>
      </div>
      <div>
        <h2 className="font-heading-h4-ar text-natural_icon_normal ">
          <Trans>طرق جمع النقاط واستبدالها بالجوائز:</Trans>
        </h2>

        <ul className="font-sub-heading-h5 text-primary_lv2_text_normal space-y-4 py-3">
          {awardsList.map((awardItem) => (
            <li key={awardItem.id} className={listClass}>
              <div>
                <MedalIcon fill="#44146D" size="24" />
              </div>
              <div>
                <h3 className={subHeaderStyle}>{_(awardItem.title)}</h3>
                <ul className="list-disc ms-10">
                  {awardItem.awards.map((award, index) => (
                    <li key={index}>{_(award)}</li>
                  ))}
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default HowToCalc
