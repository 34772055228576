import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { resetAuth } from './authSlice'

interface LogoutState {
  isLoading: boolean
  error: string | null
}

const initialState: LogoutState = {
  isLoading: false,
  error: null
}

export const logout = createAsyncThunk('logout', async (_, thunkAPI) => {
  try {
    const response = await axiosInstance.post('auth/logout')

    // Dispatch resetAuth after logout
    thunkAPI.dispatch(resetAuth())
    window.location.reload()

    return response.data
  } catch (error: any) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data)
    } else if (error.request) {
      return thunkAPI.rejectWithValue(error.request)
    } else {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
})

const logoutSlice = createSlice({
  name: 'logout',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(logout.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default logoutSlice
