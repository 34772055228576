import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearBreadcrumbLabel } from 'store/slices/breadcrumbSlice';
import { RootState } from 'store';

const BreadcrumbWatcher = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const customLastLabel = useSelector((state: RootState) => state.breadcrumb.customLastLabel)

  useEffect(() => {
    if (customLastLabel) {
    dispatch(clearBreadcrumbLabel())
    }
  }, [location.pathname, dispatch, customLastLabel])

  return null
}

export default BreadcrumbWatcher
