export enum Sections {
  REGIONS = 'regionalStagesApplications',
  EDUCATION_ADMINISTRATION = 'education_administarion',
  TOP_COORDINATOR = 'top_coordinator',
  TOP_TEACHER = 'top_teacher',
  APPLICATIONS_BY_EDUCATION_STAGES = 'applications_by_education_stages',
  COORDINATOR_POINTS = 'coorindator_points',
  UNIVERSITIES_APPLICATIONS = 'universitiesApplications',
  COUNTRY_REGIONAL_APPLICATIONS = 'countryRegionalApplications',
  ADMINISTRATIVES_APPLICATIONS = 'administrativesApplications',
  TOP_COORDINATORS = 'topCoordinators',
  TOP_TEACHERS = 'topTeachers',
  TOP_UNIVERSITIES = 'topUniversities',
  REACH_PERCENTAGE = 'reachPercentage'
}

export const getTooltipContentBySection = (section: string) => {
  switch (section) {
    case Sections.REGIONS:
      return {
        ar: 'عدد المشاركات في كل منطقة على مستوى الدولة، أو عدد المشاركات في كل مرحلة تعليمية على مستوى الدولة.',
        en: 'The number of submissions for each region across the country, or the number of submissions for each educational stage nationwide.'
      }
    case Sections.EDUCATION_ADMINISTRATION:
      return {
        ar: 'عدد المشاركات في كل إدارة تعليمية على مستوى الدولة، بالإضافة إلى معدل مشاركات الذكور والإناث في كل إدارة.',
        en: 'The number of submissions in each educational administration nationwide, along with the participation rate of males and females in each administration.'
      }

    case Sections.TOP_COORDINATOR:
      return {
        ar: 'أفضل المنسقين من حيث عدد المشاركات على مستوى جميع المناطق والإدارات التعليمية في الدولة.',
        en: 'Top coordinators in terms of the number of submissions across all regions and educational administrations nationwide.'
      }

    case Sections.TOP_TEACHER:
      return {
        ar: 'أفضل المحاضرين من حيث عدد المشاركات وعدد النقاط على مستوى جميع المناطق والإدارات التعليمية في الدولة.',
        en: 'Top teachers in terms of the number of submissions and points across all regions and educational administrations nationwide.'
      }

    case Sections.APPLICATIONS_BY_EDUCATION_STAGES:
      return {
        ar: 'عدد المشاركات في كل جامعة على مستوى الدولة.',
        en: 'The number of submissions at each university across the entire country.'
      }

    case Sections.COORDINATOR_POINTS:
      return {
        ar: 'مدى تحقيق جميع المنسقين في الدولة للرقم المستهدف الخاص بهم.',
        en: 'The extent to which all coordinators nationwide have achieved their target numbers.'
      }

    case Sections.UNIVERSITIES_APPLICATIONS:
      return {
        ar: 'عدد المشاركات في كل جامعة على مستوى الدولة.',
        en: 'The number of submissions for each university nationwide.'
      }

    case Sections.COUNTRY_REGIONAL_APPLICATIONS:
      return {
        ar: 'عدد الإدارات التعليمية وعدد المشاركين في كل منطقة على مستوى الدولة.',
        en: 'The number of educational administrations and participants in each region nationwide.'
      }

    case Sections.ADMINISTRATIVES_APPLICATIONS:
      return {
        ar: 'عدد المشاركات في الإدارات التعليمية، مع توزيع المشاركين حسب الجنس.',
        en: 'The number of submissions in educational administrations, with participants divided by gender.'
      }

    case Sections.TOP_COORDINATORS:
      return {
        ar: 'قائمة بأفضل المنسقين من حيث عدد المشاركات والنقاط على مستوى الدولة.',
        en: 'A list of the top coordinators by the number of submissions and points nationwide.'
      }

    case Sections.TOP_TEACHERS:
      return {
        ar: 'قائمة بأفضل المحاضرين من حيث عدد المشاركات والنقاط.',
        en: 'A list of the top teachers by the number of submissions and points.'
      }

    case Sections.TOP_UNIVERSITIES:
      return {
        ar: 'قائمة بأفضل الجامعات من حيث عدد المشاركات.',
        en: 'A list of the top universities by the number of submissions.'
      }

    case Sections.REACH_PERCENTAGE:
      return {
        ar: 'نسبة تحقيق الأهداف المستهدفة للمنسقين على مستوى الدولة.',
        en: 'The percentage of target achievement by coordinators nationwide.'
      }

    default:
      return {
        ar: 'لا توجد معلومات متاحة لهذا القسم.',
        en: 'No tooltip available for this section.'
      }
  }
}
