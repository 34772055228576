import { Term } from 'pages/AccountPages/types'
import { msg } from '@lingui/macro'

const termsAndConditions: Term[] = [
  {
    id: 1,
    content: msg`الشروط الواردة أدناه تتضمن شروط وأحكام مسارات مسابقة أقرأ، والتي سيطلق عليها لفظ "المسابقة"، حيث أن الاشتراك في المسابقة يعني الالتزام التام بهذه الشروط والأحكام وما يترتّب عليها.`
  },
  {
    id: 2,
    content: msg`هذه المسابقة من تنظيم مركز الملك عبدالعزيز الثقافي العالمي (إثراء).`
  },
  {
    id: 3,
    content: msg`يحتفظ المنظمون بحق إلغاء أو تمديد أو تعديل مجريات المسابقة أو شروطها أو أحكامها من دون إشعار مسبق.`
  },
  {
    id: 4,
    content: msg`تخضع كل المشاركات المقدمة في المسابقة للمراجعة وذلك للتأكد من مدى توافقها مع الشروط والأحكام.`
  },
  {
    id: 5,
    content: msg`على جميع المشاركين الالتزام بمواعيد بدء وانتهاء المدة المحددة لتقديم المشاركات في جميع المراحل.`
  },
  {
    id: 6,
    content: msg`يجب على المشترك الإقرار بأنه قد اطلع ووافق على شروط وأحكام المسابقة قبل الاشتراك فيها.`
  },
  {
    id: 7,
    content: msg`سيتم إلغاء أي مشاركة إذا ثبت عدم صحة المعلومات الواردة فيها، وإن ثبت ذلك بعد الترشيح لسبب من الأسباب، فسيُتخذ إجراء استبعاد المشارك المعني وترشيح مشارك بديل.`
  },
  {
    id: 8,
    content: msg`يحق للمنظمين عدم منح الجائزة لمتسابق فائز واختيار فائز آخر في حال أخلّ بأي من شروط وأحكام المسابقة.`
  },
  {
    id: 9,
    content: msg`يجب على المشترك التحلّي بالمصداقية التامة في جميع التفاصيل الخاصة بالمشاركة التي يضعها في نموذج المشاركة.`
  },
  {
    id: 10,
    content: msg`يجب على المشارك ضمان أن تكون جميع المعلومات المقدمة من قبله في هذه المسابقة صحيحة وحديثة وكاملة حتى يتجنب استبعاد اسمه.`
  },
  {
    id: 11,
    content: msg`للمنظمين الحق في نشر ما يرونه مناسبًا من المعلومات المدخلة في نموذج التسليم عن المشاركين في حال الترشح أو الفوز بإحدى الجوائز.`
  },
  {
    id: 12,
    content: msg`يوافق أصحاب المشاركات أن يقوم فريق العمل أو أي طرف ثالث يتم تفويضه بنشر هذه المشاركات على الموقع أو في أي وسيلة إعلامية أخرى سواءً المعروفة حاليًا أم ناشئة مستقبلًا؛ كالمواقع الإلكترونية، أو القنوات الفضائية، أو عن طريق الهاتف الجوال، كما يمكن استخدامها في إطار التصويت/ الأنشطة الترويجية/ الإعلانات/ الدعاية الإعلانية.`
  },
  {
    id: 13,
    content: msg`يوافق المشارك على منح فريق العمل أو أي طرف ثالث يتم تفويضه، ترخيصًا غير حصري ولا رجوع فيه بعرض أو نشر أو نقل المشاركة المقدمة إلكترونيًا دون تغيير أو تعديل المشاركة أو تخزينها أو إعادة إخراجها بشكل أو بآخر أو ترخيصها للغير في سبيل نشره إعلاميًا بناءً على الترخيص الأول لهذه الغايات.`
  },
  {
    id: 14,
    content: msg`يجب أن تكون المشاركة بجميع تفاصيلها من عمل المتقدم شخصيًا، بحيث يقرّ بأن كل المعلومات التي تقدم بها للمسابقة لا تنتهك حقوق الملكية الفكرية أو الحقوق الخاصة والخصوصية أو غيرها من الحقوق لأي طرف ثالث، وأنها لا تتضمن إساءة أو تعدًّ أو قدح أو ذم أو تهديد أو ازدراء أو غير ذلك من المحظورات، ويحتفظ فريق العمل بحق رفض نشر أي مشاركة أو إقصائها دون الرجوع للمشترك في حال تعارضها مع أحد شروط المسابقة.`
  },
  {
    id: 15,
    content: msg`يقرّ المتسابق بموافقته على قيام فريق العمل بتصوير حملات دعائية، واستخدام المشاركات الفائزة وأسماء الفائزين في جميع الوسائل الإعلانية المتاحة.`
  },
  {
    id: 16,
    content: msg`الجهة المنظمة غير مسؤولة عن كافة الإجراءات القانونية اتجاه حقوق الملكية الفكرية على كافة الأصعدة أمام الجهات الرسمية.`
  },
  {
    id: 17,
    content: msg`لا يتحمل المنظمون مسؤولية أي مشاكل أو أعطال تقنية أو أي أضرار معنوية أو حسية أو مادية للمشترك تأتي نتيجة لتنفيذ عمل بهدف الاشتراك في المسابقة.`
  },
  {
    id: 18,
    content: msg`يحق للمسابقة استخدام اسم المشارك وصوره الضوئية وأي تصريحات شفوية أو مقاطع فيديو تخص المشارك للإعلان في الوسائط الإعلامية المختلفة، وفي وسائل التواصل الاجتماعي بأنواعها دون أي إخطار مسبق.`
  }
]

export default termsAndConditions
