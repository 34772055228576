import React from 'react';

const Refresh = ({ fill = "#7ECD33", width = 20, height = 20 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
    <path
      d="M4.5499 3.69401C6.06203 2.38373 7.99655 1.66372 9.9974 1.66651C14.5999 1.66651 18.3307 5.39735 18.3307 9.99985C18.3307 11.7798 17.7724 13.4298 16.8224 14.7832L14.1641 9.99985H16.6641C16.6642 8.69287 16.2801 7.41468 15.5596 6.32423C14.8391 5.23377 13.814 4.37914 12.6117 3.86662C11.4094 3.35409 10.083 3.20626 8.79736 3.44153C7.51173 3.67679 6.32361 4.28476 5.38073 5.18985L4.5499 3.69401ZM15.4449 16.3057C13.9328 17.616 11.9982 18.336 9.9974 18.3332C5.3949 18.3332 1.66406 14.6023 1.66406 9.99985C1.66406 8.21985 2.2224 6.56985 3.1724 5.21651L5.83073 9.99985H3.33073C3.33062 11.3068 3.71469 12.585 4.43518 13.6755C5.15566 14.7659 6.18079 15.6205 7.38308 16.1331C8.58537 16.6456 9.9118 16.7934 11.1974 16.5582C12.4831 16.3229 13.6712 15.7149 14.6141 14.8098L15.4449 16.3057Z"
      fill={fill}
    />
  </svg>
);

export default Refresh;
