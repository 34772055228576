import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Chip, useDisclosure } from '@nextui-org/react'
import { Workflow } from 'api/types'
import AppActions from 'components/apps/Actions'
import CheckboxVariant from 'components/checkbox/checkbox'
import DataTable from 'components/data-table/data-table'
import DateField from 'components/date-picker-localized/date-picker'
import FilterLayout from 'components/Filter/FilterLayout'
import useLanguage from 'hooks/useLanguage'
import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import { fetchWorkflows, ISearchWorkFlow } from 'store/slices/workFlowSlice'
import { yyyy_mm_dd } from 'utils/time'
import CreateWorkflow from './creatWorkflow'

const DEFAULT_PAGE = 1
const PER_PAGE = 10

export const CompetitionWorkFlows: FC<{ yearId?: number }> = ({ yearId }) => {
  const dispatch = useDispatch<AppDispatch>()
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const competitionYear = useSelector((state: RootState) =>
    yearId
      ? state.competitionYearDetails.competitionYearDetails
      : state.competitionYearNavbar.competitionYear
  )
  const competitionId = yearId || competitionYear?.id
  const { workflows, isLoading, page, per_page, total_pages } = useSelector(
    (state: RootState) => state.workflows
  )

  const { _ } = useLingui()
  const language = useLanguage()
  const queryRef = useRef<Partial<ISearchWorkFlow>>({})
  const [status, setStatus] = useState<string | undefined>()
  const [end_date, setEndDate] = useState<string | undefined>()
  const navigate = useNavigate()

  const renderStatusChip = (status: string) => {
    const isActive = status === 'active'
    const translatedStatus = isActive ? _(msg`مفعلة`) : _(msg`مؤرشفة`)
    const color = isActive ? 'success' : 'danger'
    return (
      <Chip color={color} variant="flat">
        {translatedStatus}
      </Chip>
    )
  }

  const onSearch = (query?: Partial<ISearchWorkFlow>) => {
    queryRef.current = { ...queryRef.current, ...query, end_date, status }

    dispatch(
      fetchWorkflows({
        competitionId: competitionYear!.id,
        page,
        per_page,
        ...queryRef.current
      })
    )
  }

  const filterReset = async () => {
    queryRef.current = {}
    setStatus(undefined)
    setEndDate(undefined)
    dispatch(
      fetchWorkflows({
        search: '',
        status: undefined,
        end_date: undefined,
        competitionId: competitionYear!.id,
        page: DEFAULT_PAGE,
        per_page: PER_PAGE
      })
    )
  }

  const columns = [
    { key: 'name', label: _(msg`الإسم`) },
    { key: 'description', label: _(msg`وصف سير المشاركة`) },
    { key: 'end_date', label: _(msg`تاريخ انتهاء سير المشاركة`) },
    { key: 'status', label: _(msg`الحالة`) },
    { key: 'settings', label: _(msg`الاعدادات`) }
  ]

  const fields = [
    {
      name: 'search',
      hidden: true,
      component: <></>,
      action: (value: string) => onSearch({ search: value })
    },
    {
      name: 'active',
      label: _(msg`الحالة`),
      component: (
        <CheckboxVariant
          label={_(msg`مفعلة`)}
          checked={status === 'active'}
          onChange={() => setStatus(status === 'active' ? undefined : 'active')}
        />
      )
    },
    {
      name: 'archived',
      component: (
        <CheckboxVariant
          label={_(msg`مؤرشفة`)}
          checked={status === 'archived'}
          onChange={() => setStatus(status === 'archived' ? undefined : 'archived')}
        />
      )
    },
    {
      name: 'end_date',
      label: _(msg`تاريخ الانتهاء`),
      component: (
        <DateField
          placeholder={_(msg`تاريخ الانتهاء`)}
          className="mt-3"
          value={end_date || null}
          onChange={(date) => setEndDate((s) => date?.toString())}
        />
      )
    }
  ]

  function renderCell(workflow: Workflow, columnKey: React.Key) {
    return (
      <div className="min-w-28">
        {columnKey === 'name' && <p>{workflow.name}</p>}
        {columnKey === 'description' && <span>{workflow.description}</span>}
        {columnKey === 'end_date' && (
          <span className={language === 'ar' ? 'text-right' : 'text-left'}>
            {yyyy_mm_dd(workflow.end_date)}
          </span>
        )}
        {columnKey === 'status' && renderStatusChip(workflow.status)}
        {columnKey === 'settings' && (
          <AppActions to={`/app/workflows/${competitionId}/${workflow.id}`} />
        )}
      </div>
    )
  }

  useEffect(() => {
    if (competitionId) {
      dispatch(
        fetchWorkflows({
          competitionId,
          page: DEFAULT_PAGE,
          per_page: PER_PAGE,
          ...queryRef.current
        })
      )
    }
  }, [dispatch, competitionId, page, per_page])

  return (
    <div className="flex flex-col w-full gap-4">
      <FilterLayout
        showButton
        filterFields={fields}
        onFilterApply={onSearch}
        onFilterReset={filterReset}
        buttonText={_(msg`اضافة سير المشاركة`)}
        onButtonPress={onOpen}
      />

      <DataTable
        page={page}
        columns={columns}
        onRowClick={(w) => navigate(`/app/workflows/${competitionId}/${w.id}`)}
        data={workflows || []}
        isLoading={isLoading}
        totalPages={total_pages}
        renderCell={renderCell}
        onPageChange={(page) => onSearch({ page })}
      />

      <CreateWorkflow isOpen={isOpen} onOpenChange={onOpenChange} competition={competitionYear!} />
    </div>
  )
}

export default CompetitionWorkFlows
