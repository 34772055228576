import React from 'react';

const BlackedIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="81" height="80" viewBox="0 0 81 80" fill="none">
      <path
        d="M24.149 18.9336L61.5676 56.3523C65.08 51.8353 67.168 46.1636 67.168 40C67.168 25.2724 55.229 13.3333 40.5013 13.3333C34.3376 13.3333 28.6659 15.4214 24.149 18.9336ZM56.8536 61.0663L19.4349 23.6476C15.9228 28.1646 13.8346 33.8363 13.8346 40C13.8346 54.7276 25.7737 66.6666 40.5013 66.6666C46.665 66.6666 52.3366 64.5786 56.8536 61.0663ZM16.9311 16.4297C22.9596 10.4012 31.2975 6.66663 40.5013 6.66663C58.9106 6.66663 73.8346 21.5905 73.8346 40C73.8346 49.2036 70.1 57.5416 64.0716 63.5703C58.043 69.5986 49.705 73.3333 40.5013 73.3333C22.0918 73.3333 7.16797 58.4093 7.16797 40C7.16797 30.7961 10.9026 22.4582 16.9311 16.4297Z"
        fill="#2AACAD"
      />
    </svg>
  );
};

export default BlackedIcon;
