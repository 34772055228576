const ArrowUpIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4993 10.8273L7.54928 15.7773L6.13528 14.3633L12.4993 7.99934L18.8633 14.3633L17.4493 15.7773L12.4993 10.8273Z"
        fill="#3F3F46"
      />
    </svg>
  )
}

export default ArrowUpIcon
