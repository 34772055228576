import img1 from 'assets/images/stage-bg-1.png'
import img2 from 'assets/images/stage-bg-2.png'
import img3 from 'assets/images/stage-bg-3.png'
import img4 from 'assets/images/stage-bg-4.png'
import img5 from 'assets/images/stage-bg-5.png'
import { Stage } from 'pages/landing/models'

import { msg } from '@lingui/macro'

const stagesList: Stage[] = [
  {
    id: 1,
    title: msg`التسجيل في موقع المسابقة`,
    date: msg`12 أكتوبر 2024 – 15 فبراير 2025`,
    bgImage: img1,
    description: msg`التسجيل في منصة المسابقة وتعبئة كافة البيانات المطلوبة بشكل صحيح، ثم تقديم مراجعة وافية باللغة العربية لكتاب من اختيار المشارك.`
  },
  {
    id: 2,
    title: msg`التصفيات الأولية`,
    date: msg`16 فبراير – 5 مارس  2025`,
    bgImage: img2,
    description: msg`يقوم فريق المسابقة بقراءة وتقييم جميع المشاركات لترشيح أفضل 500 مشارك إلى مرحلة المقابلات الشخصية.`
  },
  {
    id: 3,
    title: msg`المقابلات الشخصية`,
    date: msg`27 أبريل – 10 مايو  2025`,
    bgImage: img3,
    description: msg`تقوم لجان تحكيم مختصة بإجراء مقابلات مع المشاركين المرشحين لتقييم تجربتهم القرائية واستيعابهم للمراجعة المقدمة، وتختار اللجنة أفضل 50 مشارك ومشاركة للتأهل إلى ملتقى أقرأ الإثرائي.`
  },
  {
    id: 4,
    title: msg`ملتقى أقرأ الإثرائي`,
    date: msg`19 يوليو – 10 أغسطس  2025`,
    bgImage: img4,
    description: msg`ملتقى مصمم بعناية فائقة، يُقام الملتقى في مركز إثراء بالظهران ويجتمع فيه أفضل القراء المشاركين من جميع أنحاء العالم العربي لخوض تجربة ملهمة وصانعة للتحول، تضم مجموعة من المحاضرات والورش والحوارات المتنوعة، ويقدمها نخبة من الكتّاب والمفكرين والأدباء. ويقُسّم القراء المتأهلون إلى ملتقيين: ملتقى للمرحلة الابتدائية والمتوسطة يضم عشرين مشاركًا، وآخر للمرحلة الثانوية والجامعية يضم ثلاثين مشاركًا.
ويتأهل من الملتقى عشرة مشاركين للحفل الختامي للمنافسة على لقب "قارئ العام" وثمانية مشاركين للمنافسة على لقب "مناظر العام"`
  },
  {
    id: 5,
    title: msg`حفل أقرأ الختامي`,
    date: msg`أكتوبر 2025`,
    bgImage: img5,
    description: msg`فعالية للاحتفاء بالقراءة والقراء على مدى يومين بمشاركة واسعة من القراء والكتّاب والمفكرين في العالم العربي ضمن برنامج ثقافي متنوع يضم ندوات نقاشية وأمسيات شعرية ومنصات توقيع كتب.
وتُعقد فيه المنافسة الختامية للمشاركين في مسابقة أقرأ، حيث يقدم أفضل عشرة مشاركين نصوصهم الختامية
 على مسرح إثراء بالإضافة للمناظرة الختامية، وتُعلن في نهاية برنامج الحفل أسماء الفائزين في مسابقة أقرأ بجميع مساراتها.`
  }
]

export default stagesList
