import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { EducationLevel, EducationLevelsResponse } from 'api/types'
import { cleanObject } from 'utils/cleanObject'

interface RegisterState {
  educationLevels: EducationLevel[]
  isLoading: boolean
  error: string | null
}

const initialState: RegisterState = {
  educationLevels: [],
  isLoading: false,
  error: null
}

export const fetchEducationLevels = createAsyncThunk(
  'educationLevels',
  async (params: { countriesIds?: string | string[]; countryCode?: string }) => {
    const { countriesIds = [], countryCode = '' } = params

    const paramsObj = { 'countires[]': countriesIds, country_code: countryCode }

    const response = await axiosInstance.get<EducationLevelsResponse>('/education-levels', {
      params: cleanObject(paramsObj),
      paramsSerializer: (params) => {
        return Object.entries(params)
          .map(([key, value]) =>
            Array.isArray(value)
              ? value.map((v) => `${key}=${encodeURIComponent(v)}`).join('&')
              : `${key}=${encodeURIComponent(value)}`
          )
          .join('&')
      }
    })
    return response.data
  }
)

const educationLevelsSlice = createSlice({
  name: 'educationLevels',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchEducationLevels.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchEducationLevels.fulfilled, (state, action) => {
        state.isLoading = false
        const educationLevels = action.payload.data ?? []
        state.educationLevels = educationLevels
      })
      .addCase(fetchEducationLevels.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default educationLevelsSlice
