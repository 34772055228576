import React from 'react'
import { createExportFormSchema } from './ExportSchema'
import Form from 'components/FormStructure/Form'
import ExportForm from './ExportForm'
import { useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchMoreReports } from 'store/slices/reportsHeadCoordinatorMoreSlice'
import { useDispatch } from 'react-redux'
import { fetchApplicationStatistics } from 'store/slices/reportsHeadCoordinatorSlice'
import { fetchIthraaApplicationStatistics } from 'store/slices/ithraaReports'
export default function Export({
  fixedReportType,
  onSuccessExport,
  ithraaTable
}: {
  fixedReportType?: string
  onSuccessExport?: () => void
  ithraaTable?: boolean
}) {
  const dispatch = useDispatch<AppDispatch>()
  const exportFormDefaultValues = {
    reportType: fixedReportType ?? '',
    durationType: 'all',
    beginDate: null,
    endDate: null,
    pdf: false,
    csv: false
  }
  const { competitionYear } = useSelector((state: RootState) => state.competitionYearNavbar)

  const onSubmit = async (data: any) => {
    if (fixedReportType) {
      dispatch(
        fetchMoreReports({
          export: true,
          export_as: data.pdf ? 'pdf' : 'csv',
          competition_year_id: competitionYear?.id,
          keys: [fixedReportType],
          submission_at_from: data.beginDate,
          submission_at_to: data.endDate
        })
      )
    } else if (ithraaTable) {
      dispatch(
        fetchIthraaApplicationStatistics({
          export: true,
          export_as: data.pdf ? 'pdf' : 'csv',
          competition_year_id: competitionYear?.id,
          keys: data.reportType != 'all' ? [data.reportType] : [],
          submission_at_from: data.beginDate,
          submission_at_to: data.endDate
        })
      )
    } else {
      dispatch(
        fetchApplicationStatistics({
          export: true,
          export_as: data.pdf ? 'pdf' : 'csv',
          competition_year_id: competitionYear?.id,
          keys: data.reportType != 'all' ? [data.reportType] : [],
          submission_at_from: data.beginDate,
          submission_at_to: data.endDate
        })
      )
    }
  }
  return (
    <Form
      schema={createExportFormSchema(
        new Date(competitionYear?.start_date as string),
        new Date(competitionYear?.end_date as string)
      )}
      onSubmit={onSubmit}
      defaultValues={exportFormDefaultValues}
    >
      <ExportForm fixedReportType={fixedReportType}></ExportForm>
    </Form>
  )
}
