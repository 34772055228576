import React from 'react'

interface Home2IconProps {
  fill?: string
}

const Home2Icon: React.FC<Home2IconProps> = ({ fill = '#A1A1AA' }) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4776_176539)">
        <path
          d="M13.1641 14.0003H3.83073C3.65392 14.0003 3.48435 13.93 3.35932 13.805C3.2343 13.68 3.16406 13.5104 3.16406 13.3336V7.33361H1.16406L8.04873 1.07494C8.17147 0.963257 8.33145 0.901367 8.4974 0.901367C8.66334 0.901367 8.82332 0.963257 8.94606 1.07494L15.8307 7.33361H13.8307V13.3336C13.8307 13.5104 13.7605 13.68 13.6355 13.805C13.5104 13.93 13.3409 14.0003 13.1641 14.0003ZM4.4974 12.6669H12.4974V6.10494L8.4974 2.46894L4.4974 6.10494V12.6669Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4776_176539">
          <rect width="16" height="16" fill={fill} transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Home2Icon