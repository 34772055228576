import useLanguage from 'hooks/useLanguage'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchEducationAdministrations } from 'store/slices/educationAdministrationsSlice'
import { getLocalizedName } from 'utils/getLocalizedName'
import { EducationAdministration } from './types'

interface Props {
  countryId?: string | string[]
  regionId?: string | string[]
}

const useEducationAdministrations = ({ countryId, regionId }: Props = {}) => {
  const dispatch = useDispatch<AppDispatch>()
  const { educationAdministrations, isLoading, error } = useSelector(
    (state: RootState) => state.educationAdministrations
  )

  useEffect(() => {
    dispatch(fetchEducationAdministrations({ countryId, regionsIds: regionId }))
  }, [dispatch, countryId, regionId])

  return { educationAdministrations, isLoading, error }
}

export const useEducationAdministrationOptions = ({ countryId, regionId }: Props = {}) => {
  const language = useLanguage() as 'ar' | 'en'
  const { educationAdministrations, isLoading, error } = useEducationAdministrations({
    countryId,
    regionId
  })

  const options = useMemo(() => {
    if (educationAdministrations.length === 0) {
      return [
        // {
        //   key: '',
        //   label: language === 'ar' ? 'لا يوجد بيانات للاختيار' : 'No options available'
        // }
      ]
    } else {
      return educationAdministrations.map((administration: EducationAdministration) => ({
        key: String(administration.id),
        label: getLocalizedName(administration.name, language)
      }))
    }
  }, [educationAdministrations, language])

  return { options, isLoading, error }
}
