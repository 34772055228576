import React from 'react';

const ReportIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
    <path 
      d="M9.66667 5.83317H11.3333V14.1665H9.66667V5.83317ZM13 9.1665H14.6667V14.1665H13V9.1665ZM6.33333 10.8332H8V14.1665H6.33333V10.8332ZM13 3.33317H4.66667V16.6665H16.3333V6.6665H13V3.33317ZM3 2.49317C3 2.0365 3.3725 1.6665 3.8325 1.6665H13.8333L18 5.83317V17.494C18.0008 17.6034 17.98 17.712 17.9388 17.8134C17.8976 17.9148 17.8369 18.007 17.7601 18.085C17.6832 18.1629 17.5918 18.2249 17.491 18.2675C17.3902 18.3101 17.2819 18.3324 17.1725 18.3332H3.8275C3.60865 18.3316 3.39918 18.2441 3.24435 18.0894C3.08951 17.9347 3.00175 17.7254 3 17.5065V2.49317Z" 
      fill="#3F3F46"
    />
  </svg>
);

export default ReportIcon;
