import SelectField from 'components/select-field/select-field'
import { MutableRefObject, useState } from 'react'
import { ISearchWorkflowApp } from 'store/slices/workflowAppsSlice'
import RangeLabel from './RangeLabel'

interface SPProps {
  name: { from: keyof ISearchWorkflowApp; to: keyof ISearchWorkflowApp }
  queryRef: MutableRefObject<Partial<ISearchWorkflowApp>>
  label?: { from: string; to: string }
  options: { from: { key: string; label: string }[]; to: { key: string; label: string }[] }
  multiple?: boolean
  getValue?: (e: { from: string | string[]; to: string | string[] }) => void
}

const MSelectPicker = ({ name, queryRef, label, multiple, getValue, options }: SPProps) => {
  const defaultValue = queryRef.current
  const [value, setValue] = useState({
    from: multiple ? (defaultValue[name.from] as string[]) : (defaultValue[name.from] as string),
    to: multiple ? (defaultValue[name.to] as string[]) : (defaultValue[name.to] as string)
  })

  const onChange = (type: 'from' | 'to') => {
    return (v: string | string[]) => {
      if (getValue) getValue({ ...value, [type]: v })
      queryRef.current[name[type]] = v as any
      setValue((value) => ({ ...value, [type]: v }))
    }
  }

  return (
    <div className="flex gap-x-4 w-full">
      <div className="!w-1/2">
        <RangeLabel value={label?.from} />

        <SelectField
          isChips={multiple}
          selectionMode={multiple ? 'multiple' : 'single'}
          options={options.from}
          value={value.from}
          onChange={onChange('from')}
        />
      </div>
      <div className="!w-1/2">
        <RangeLabel value={label?.to} />
        <SelectField
          isChips={multiple}
          selectionMode={multiple ? 'multiple' : 'single'}
          options={options.to}
          value={value.to}
          onChange={onChange('to')}
        />
      </div>
    </div>
  )
}

export default MSelectPicker
