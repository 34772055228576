import React, { useState } from 'react'
import Form from 'components/FormStructure/Form'
import validationSchema from './validation-schema'
import TextInput from 'components/hook-form/text-input'
import ButtonVariant from 'components/button-variant/button'
import ArrowIcon from 'assets/icons/ArrowIcon'
import { useDispatch } from 'react-redux'
import { resetPassword } from 'store/slices/resetPasswordSlice'
import { toast } from 'react-toastify'
import { useSearchParams } from 'react-router-dom'
import { AppDispatch } from 'store'
import { useNavigate } from 'react-router-dom'
import { msg } from '@lingui/macro'
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro'
import ErrorMessage from 'pages/AccountPages/components/ErrorMessage'

const ResetPassword: React.FC = () => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const email = searchParams.get('email')

  const [errorMsg, setErrorMsg] = useState<string>('')

  const defaultValues = {
    password: '',
    confirm_password: ''
  }

  const handleSubmitSuccess = (result: any) => {
    if (result.status === 'success') {
      toast.success(i18n._(result.message) || i18n._(msg`تم إعادة تعيين كلمة المرور بنجاح!`))
      navigate('/login')
    } else {
      throw new Error(result.message || i18n._(msg`فشلت عملية إعادة تعيين كلمة المرور.`))
    }
  }

  const onSubmit = async (data: typeof defaultValues) => {
    setErrorMsg('')

    setIsLoading(true)
    try {
      const result = await dispatch(
        resetPassword({
          email: email ?? '',
          token: token ?? '',
          password: data.password,
          password_confirmation: data.confirm_password
        })
      ).unwrap()

      handleSubmitSuccess(result)
    } catch (error: any) {
      setErrorMsg(error.message)
      toast.error(i18n._(error.message) || i18n._(msg`حدث خطأ أثناء إعادة تعيين كلمة المرور.`))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Form schema={validationSchema} onSubmit={onSubmit} defaultValues={defaultValues}>
      {errorMsg && <ErrorMessage msgstr={errorMsg} />}
      <TextInput
        name="password"
        label={msg`كلمة المرور الجديدة`.id}
        type="password"
        placeholder={msg`أدخل كلمة مرور`.id}
        helperText=""
      />
      <TextInput
        name="confirm_password"
        label={msg`تأكيد كلمة المرور الجديدة`.id}
        type="password"
        placeholder={msg`أدخل كلمة مرور`.id}
        helperText=""
      />
      <div className="flex w-full justify-center">
        <ButtonVariant
          variant="secondary"
          type="submit"
          size="lg"
          iconEnd={<ArrowIcon fill="currentColor" width="24" height="24" />}
          iconRotate
          isLoading={isLoading}
          disabled={isLoading}
        >
          <p><Trans>تأكيد كلمة المرور</Trans></p>
        </ButtonVariant>
      </div>
    </Form>
  )
}

export default ResetPassword
