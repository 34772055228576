import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { SubmitApplicationIcon } from 'assets/icons/SubmitApplicationIcon'
import ModalHeader from 'components/modal/modal-header'
import ModalLayout from 'components/modal/modal-layout'
import Typography from 'components/typography/typography'

function SubmitApplicationModule() {
  const { _ } = useLingui()
  return (
    <ModalLayout
      showBackButton={false}
      appStyle
      backdrop="opaque"
      coordinitorPageStyle={true}
      header={
        <ModalHeader
          subheaderText={
            <>
              <div className="flex flex-col gap-2">
                <div className="mb-4 flex w-full justify-center">
                  <SubmitApplicationIcon />
                </div>
                <Typography weight="bold" size="xl" align="center">
                  {_(msg`تم إرسال طلبك بنجاح`)}
                </Typography>
                <Typography align="center">
                  {_(
                    msg`سيراجع فريق المسابقة طلبك، وسوف تصلك رسالة البريد الإلكتروني بعد الموافقة.`
                  )}
                </Typography>
              </div>
            </>
          }
        />
      }
    />
  )
}

export default SubmitApplicationModule
