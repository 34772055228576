import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import { MessageDescriptor } from '@lingui/core'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

interface ButtonProps {
  onClick?: () => void
  placeholder?: MessageDescriptor
  btnStyle?: 'primary' | 'secondary' | 'default'
  btnSize?: 'Default' | 'larg'
  hoverEffect?: boolean
  isActive?: boolean
  isDisabled?: boolean
  className?: string
  trailingIcon?: boolean
  customIcon?: React.ReactNode
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  placeholder,
  btnStyle = 'primary',
  btnSize = 'Default',
  hoverEffect,
  isActive = false,
  isDisabled = false,
  className,
  trailingIcon,
  customIcon
}) => {
  const [isHovered, setIsHovered] = useState(false)

  // Get the direction from Redux
  const direction = useSelector((state: RootState) => state.layout.direction)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const sharedStyle = `flex flex-row items-center justify-center rounded-full border border-customGreen transition-all ease-in-out duration-200 cursor cursor-pointer`
  const primaryStyle = `bg-customGreen text-customWhite ${className}`
  const secondaryStyle = `text-customGreen ${className}`
  const defaultStyle = `border-primary_fill_active bg-primary_fill_normal_pressed text-white ${className}`
  const hoverStyle = `bg-customGreen opacity-50 text-customWhite`
  const disabledStyle = `bg-[#E4E4E7] border-none text-[#A1A1AA] cursor-not-allowed`

  return (
    <div
      className={`relative overflow-hidden rounded-full group bg-customWhite cursor cursor-pointer`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button
        onClick={isDisabled ? undefined : onClick}
        className={`
          cursor cursor-pointer
          ${sharedStyle}   
          ${btnSize === 'larg' ? 'w-44 h-11 lg:w-80 lg:h-16 text-xs lg:text-xl' : 'px-5 h-11'} 
          ${btnStyle === 'primary' && !isDisabled ? primaryStyle : secondaryStyle} 
          ${btnStyle === 'default' && !isDisabled ? defaultStyle : ''} 
          ${isHovered && !hoverEffect && !isDisabled ? hoverStyle : ''} 
          ${isDisabled ? disabledStyle : ``}
        `}
        disabled={isDisabled}
      >
        {placeholder && <Trans id={placeholder.id} />}

        {customIcon ? (
          <span className="px-1.5">{customIcon}</span>
        ) : (
          trailingIcon && (
            <span
              className={`px-1.5
            ${isDisabled ? `text-[#D4D4D8]` : ``}
            ${direction === 'rtl' ? 'rotate-0' : 'rotate-180'}
            `}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.828 7.00066H16V9.00066H3.828L9.192 14.3647L7.778 15.7787L0 8.00066L7.778 0.222656L9.192 1.63666L3.828 7.00066Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          )
        )}
      </button>
      {hoverEffect && !isDisabled && (
        <div className="absolute top-[-50%] right-[-20%]">
          <div className="w-12 h-40 bg-customWhite24 rotate-[26deg] transition-all ease-in-out duration-800 group-hover:animate-slide-in-out"></div>
        </div>
      )}
    </div>
  )
}

export default Button
