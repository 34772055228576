import { msg, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import Alert from 'components/alert/alert'
import ApplicationViewer from 'components/application-viewer/ApplicationViewer'
import ButtonVariant from 'components/button-variant/button'
import Loader from 'components/loader/Loader'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AppDispatch, RootState } from 'store'
import { Application, fetchApplicationById } from 'store/slices/applicationsSlice'
import { moveApplication } from 'store/slices/moveApplicationSlice'
import { restoreApplication } from 'store/slices/restoreApplicationSlice'
import { sendSubmitReminderApplication } from 'store/slices/sendSubmitReminderApplicationSlice'
import MoveAppModal from '../modals/MoveAppModal'
import RestoreAppModal from '../modals/RestoreAppModal'
import SendSubmitReminderAppModal from '../modals/SendSubmitReminderAppModal'
import { useStudentActiveApplication } from 'hooks/useStudentActiveApplication'
import dayjs from 'dayjs'

export const FormDetails = ({ idle }: { idle?: boolean }) => {
  const { _ } = useLingui()
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch<AppDispatch>()
  const [applicationData, setApplicationData] = useState<Application | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [showSendReminderModal, setShowSendReminderModal] = useState(false)

  const { account_type } = useSelector((s: RootState) => s.me.me?.data! || {})
  const isLoading = useSelector((state: RootState) => state.appsStates.isLoading)
  const error = useSelector((state: RootState) => state.appsStates.error)
  const isStudent = account_type === 'student'
  const isIdle = idle || ['ithraa', 'admin'].includes(account_type || '')

  const { currentCompetYear } = useStudentActiveApplication()
  const isValidDate = currentCompetYear && dayjs().isBefore(dayjs(currentCompetYear.close_at))

  const handleRestoreConfirm = async () => {
    if (!id) return
    try {
      const result = await dispatch(restoreApplication(parseInt(id))).unwrap()
      if (result.status === 'success') {
        const successMsg = _(msg`تم استعادة مشاركتك بنجاح`)
        navigate('/app/applications/submit-application', { state: { message: successMsg } })
      }
    } catch (error) {
      console.error('Failed to restore the application:', error)
    }
  }

  const onMoveApplication = async (workflow_id: string) => {
    try {
      const result = await dispatch(
        moveApplication({ workflow_id, applications: [`${id}`] })
      ).unwrap()
      if (result.status === 'success') {
        toast.success(_(msg`تم نقل المشاركة بنجاح`.id))
        navigate(-1)
      }
    } catch (error) {
      console.error('Failed to move the application:', error)
    }
  }

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const handleSendReminder = async (emailContent: string) => {
    if (!id) return
    try {
      await dispatch(sendSubmitReminderApplication({ applicationId: parseInt(id), emailContent }))
      const successMsg = _(msg`تم إرسال التذكير بنجاح`)
      navigate('/app/management-applications/idle-applications', { state: { message: successMsg } })
    } catch (error) {
      console.error('Failed to send the reminder:', error)
    }
  }

  const handleSendReminderCancel = () => {
    setShowSendReminderModal(false)
  }

  useEffect(() => {
    if (id && account_type) {
      const getApplicationData = async () => {
        try {
          const result = await dispatch(
            fetchApplicationById({
              id: parseInt(id),
              userType: account_type,
              idle: isIdle
            })
          ).unwrap()
          setApplicationData(result.data || null)
        } catch (err) {
          console.error('Error fetching application data:', err)
        }
      }
      getApplicationData()
    }
  }, [id, account_type, dispatch, isIdle])

  if (isLoading) {
    return <Loader />
  }

  if (error || !applicationData) {
    return (
      <Alert
        mainText={_(msg`لم يتم العثور على المشاركة`)}
        sizeMainText="md"
        weightMainText="normal"
        type="error"
      />
    )
  }

  const actionButtons = () => {
    switch (applicationData.status?.id) {
      case 1:
        return (
          <ButtonVariant
            variant="secondary"
            onClick={() => navigate('/app/applications/submit-application')}
          >
            <Trans>تعديل المشاركة</Trans>
          </ButtonVariant>
        )
      case 2:
        return (
          <ButtonVariant variant="secondary" onClick={openModal}>
            <Trans>تراجع عن تقديم المشاركة</Trans>
          </ButtonVariant>
        )
      default:
        return null
    }
  }

  const idleActionButtons = () => {
    if (applicationData.status?.id === 1)
      return (
        <ButtonVariant variant="secondary" onClick={() => setShowSendReminderModal(true)}>
          <Trans>إرسال تذكير</Trans>
        </ButtonVariant>
      )

    if (applicationData.status?.id === 2 || isIdle)
      return (
        <ButtonVariant variant="secondary" onClick={openModal}>
          <Trans>نقل سير المشاركة</Trans>
        </ButtonVariant>
      )

    return null
  }

  return (
    <div className="flex flex-col justify-between h-full pt-5">
      <div>
        {location.state?.message && (
          <Alert
            mainText={_(location.state.message)}
            sizeMainText="md"
            weightMainText="normal"
            type="success"
          />
        )}
        <h2 className="pb-5 font-heading-h4-ar text-natural_lv1_text_normal capitalize">
          <Trans>رقم المشاركة</Trans> {applicationData.number}
        </h2>

        <ApplicationViewer applicationData={applicationData} user_role={`${account_type}`} />
      </div>

      {!!isStudent &&
        currentCompetYear &&
        currentCompetYear.status === 'started' &&
        applicationData.competition_year_id === currentCompetYear.id &&
        isValidDate && <div className="flex justify-end w-full pt-9">{actionButtons()}</div>}

      {isIdle && <div className="flex justify-end w-full pt-9">{idleActionButtons()}</div>}

      {showModal && !!isStudent && (
        <RestoreAppModal onConfirm={handleRestoreConfirm} onCancel={closeModal} />
      )}

      {showModal && isIdle && (
        <MoveAppModal
          year={applicationData.competition_year_id}
          onMove={onMoveApplication}
          onCancel={closeModal}
        />
      )}

      {showSendReminderModal && (
        <SendSubmitReminderAppModal
          onConfirm={handleSendReminder}
          onCancel={handleSendReminderCancel}
        />
      )}
    </div>
  )
}
