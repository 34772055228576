import React from 'react'
import ModalHeader from 'components/modal/modal-header'
import ModalLayout from 'components/modal/modal-layout'
import { Trans } from '@lingui/macro'
import { msg } from '@lingui/macro'
import ButtonVariant from 'components/button-variant/button'

interface DraftConfirmModalProps {
  onConfirm: () => void
  onCancel: () => void
}

const DraftConfirmModal: React.FC<DraftConfirmModalProps> = ({ onConfirm, onCancel }) => {
  return (
    <ModalLayout
      onBack={onCancel}
      accountPageStyle
      header={
        <ModalHeader
          headerText={msg`حفظ النموذج كمسودة`}
          subheaderText={
            <p>
              <Trans>
                سيتم حفظ مشاركتك فقط بدون إرسالها ، للمشاركة ينبغي أن يتم إرسال من خلال زر تقديم
                المشاركة{' '}
              </Trans>
            </p>
          }
        />
      }
      footer={
        <>
          <div className="flex items-center gap-2">
            <ButtonVariant onClick={onCancel} variant="bordered">
              <Trans>إلغاء</Trans>
            </ButtonVariant>
            <ButtonVariant onClick={onConfirm} variant="secondary">
              <Trans>موافقة</Trans>
            </ButtonVariant>
          </div>
        </>
      }
    />
  )
}

export default DraftConfirmModal
