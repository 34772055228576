import React, { useEffect, useRef } from 'react'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import SelectInput from 'components/hook-form/select-field'
import { useCountryOptions } from '../hooks/useCountryOptions'
import { useRegionOptions } from '../hooks/useRegionOptions'
import { useFormContext } from 'react-hook-form'

const LocationInfoForm = () => {
  const { _ } = useLingui()
  const language = useLingui().i18n.locale as 'ar' | 'en'

  const { watch, setValue } = useFormContext()
  const country = watch('location_info.country')
  const regionId = watch('location_info.region')

  const { options: countryOptions, isLoading: CountryOptionsLoading } = useCountryOptions()
  const { options: regionOptions, isLoading: RegionOptionsLoading } = useRegionOptions(
    country as string
  )

  const selectedCountry = countryOptions.find((option) => option.key === country)
  const regionLabel = selectedCountry?.regionLabel || (language === 'ar' ? 'المنطقة' : 'Region')

  const isRegionDisabled = regionOptions.length === 0

  const prevCountryRef = useRef<string | undefined | null>(country)
  const prevRegionIdRef = useRef<string | undefined | null>(regionId)

  useEffect(() => {
    setValue('location_info.countryCode', selectedCountry? selectedCountry?.countryCode : '')
  }, [selectedCountry, setValue])

  useEffect(() => {
    if (prevCountryRef.current !== country && (country || country === null)) {
      setValue('location_info.region', null)
      setValue('location_info.countryCode', '')
    }
    prevCountryRef.current = country
  }, [country, setValue])

  useEffect(() => {
    if (prevRegionIdRef.current !== regionId && (regionId || regionId === null)) {
      setValue('education_info.education_administration', null)
      setValue('education_info.education_level', null)
      setValue('education_info.school', null)
      setValue('education_info.university', null)
    }
    prevRegionIdRef.current = regionId
  }, [regionId, setValue])

  return (
    <>
      <SelectInput
        name="location_info.country"
        isLoading={CountryOptionsLoading}
        label={_(msg`الدولة`)}
        options={countryOptions}
        selectionMode="single"
        placeholder={_(msg`اختر الدولة`)}
        mode="search"
        value={watch('location_info.country')}
      />
      <SelectInput
        name="location_info.region"
        label={regionLabel}
        options={regionOptions}
        isLoading={RegionOptionsLoading}
        placeholder={_(msg`اختر المنطقة`)}
        isDisabled={isRegionDisabled}
        mode="search"
      />
    </>
  )
}

export default LocationInfoForm
