import React, { forwardRef, useState, useEffect } from 'react'
import { Select, SelectItem, Autocomplete, AutocompleteItem, Chip } from '@nextui-org/react'
import { Trans } from '@lingui/react'
import { i18n } from '@lingui/core'

interface SelectFieldProps {
  label?: string
  placeholder?: string
  options: { key: string; label: string }[]
  helperText?: string
  error?: string | null
  value?: string | string[]
  onChange?: (value: string | string[]) => void
  selectionMode?: 'single' | 'multiple'
  mode?: 'normal' | 'search'
  invisible?: boolean
  isChips?: boolean
  [key: string]: any
  className?: string
  containerClassName?: string
  labelClassName?: string
  classNames?: any
}

const SelectField: React.FC<SelectFieldProps> = forwardRef<HTMLDivElement, SelectFieldProps>(
  (
    {
      label,
      placeholder = i18n._('اختر الخيار المناسب'),
      options,
      helperText,
      error,
      value,
      onChange,
      selectionMode = 'single',
      mode = 'normal',
      invisible = false,
      isChips = false,
      className,
      containerClassName,
      labelClassName,
      classNames,
      ...other
    },
    ref
  ) => {
    const controlledValue = value ?? (selectionMode === 'multiple' ? [] : '')

    const [filteredOptions, setFilteredOptions] = useState(options)
    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
      setFilteredOptions(
        options.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()))
      )
    }, [inputValue, options])

    const selectedKeys =
      selectionMode === 'multiple'
        ? new Set(controlledValue as string[])
        : controlledValue
          ? new Set([controlledValue])
          : undefined

    const handleSelectionChange = (key: any) => {
      const selectedValue = Array.isArray(key) || key instanceof Set ? Array.from(key) : [key]
      const newValue = selectionMode === 'multiple' ? selectedValue : selectedValue[0] || ''

      if (selectionMode === 'single' && !newValue) {
        return
      }

      onChange?.(newValue)
    }

    if (invisible) return null

    return (
      <div className={`flex flex-col gap-1 ${containerClassName}`} ref={ref}>
        {label && (
          <p
            className={`font-sub-heading-h6-ar text-natural_icon_normal capitalize ${labelClassName}`}
          >
            <Trans id={label} />
          </p>
        )}
        {selectionMode === 'multiple' && isChips ? (
          <div className="flex flex-wrap gap-2">
            <Select
            
              placeholder={i18n._(placeholder || '')}
              className={`w-full h-11 font-sub-heading-h6-ar text-natural_icon_normal placeholder:text-primary_lv2_text_normal`}
              aria-label="select option component"
              variant="bordered"
              fullWidth
              radius="sm"
              color="primary"
              selectionMode="multiple"
              classNames={{ trigger: 'py-2' }}
              selectedKeys={selectedKeys as any}
              renderValue={(items) => {
                if (selectionMode === 'multiple') {
                  return (
                    <div className="flex gap-2 overflow-x-auto whitespace-nowrap no-scrollbar">
                      {items.map((item) => (
                        <Chip className="whitespace-nowrap" color="primary" key={item.key}>
                          {options.find((o) => o.key === item.key)?.label}
                        </Chip>
                      ))}
                    </div>
                  )
                }
                return null
              }}
              onSelectionChange={handleSelectionChange}
              {...other}
              hidden
            >
              {filteredOptions.map((option) => (
                <SelectItem key={option.key} value={option.key}>
                  {option.label}
                </SelectItem>
              ))}
            </Select>
          </div>
        ) : mode === 'normal' ? (
          <Select
            placeholder={i18n._(placeholder || '')}
            classNames={classNames}
            className={`w-full h-11 font-sub-heading-h6-ar text-natural_icon_normal placeholder:text-primary_lv2_text_normal  ${className}`}
            aria-label="select option component"
            variant="bordered"
            fullWidth
            radius="sm"
            color="primary"
            selectionMode={selectionMode}
            selectedKeys={selectedKeys as any}
            onSelectionChange={handleSelectionChange}
            {...other}
          >
            {filteredOptions.map((option) => (
              <SelectItem key={option.key} value={option.key}>
                {option.label}
              </SelectItem>
            ))}
          </Select>
        ) : (
          <Autocomplete
            inputProps={{ name: 'unique-field-name' }}
            className={`w-full h-11 font-sub-heading-h6-ar text-natural_icon_normal placeholder:text-primary_lv2_text_normal`}
            aria-label="autocomplete option component"
            variant="bordered"
            fullWidth
            radius="sm"
            color="primary"
            onSelectionChange={(key: any) => {
              handleSelectionChange(key)
            }}
            defaultItems={filteredOptions}
            onInput={(event) => setInputValue((event.target as HTMLInputElement).value)}
            placeholder={i18n._(placeholder || 'Search...')}
            {...other}
          >
            {filteredOptions.map((option) => (
              <AutocompleteItem key={option.key} value={option.key}>
                {option.label}
              </AutocompleteItem>
            ))}
          </Autocomplete>
        )}
        {error && (
          <p className="text-danger_default_lv1_text_normal_active_hover_pressed font-label-sm-ar">
            <Trans id={error} />
          </p>
        )}
        {helperText && !error && (
          <p className="text-primary_lv2_text_normal font-sub-heading-h6-ar">
            <Trans id={helperText} />
          </p>
        )}
      </div>
    )
  }
)

export default SelectField
