import React from 'react'
import { Select, SelectItem, SelectedItems } from '@nextui-org/react'
import FilterLabel from './FilterLabel'

interface DropdownFilterProps {
  label: string
  selectedValue: string
  onValueChange: (value: string) => void
  options: string[] | { label: string; key: string }[]
  selectionMode?: 'single' | 'multiple'
}

const DropdownFilter: React.FC<DropdownFilterProps> = ({
  label,
  selectedValue,
  onValueChange,
  options,
  selectionMode = 'single'
}) => {
  const handleValueChange = (keys: Selection) => {
    let selectedKeys: string[] = []
    if (keys instanceof Set) {
      selectedKeys = Array.from(keys) as string[]
    } else if (typeof keys === 'string') {
      selectedKeys = [keys]
    }

    if (selectionMode === 'multiple') {
      // @ts-ignore
      onValueChange(selectedKeys)
    } else {
      onValueChange(selectedKeys[0] || '')
    }
  }

  const renderOptions = () => {
    if (typeof options[0] === 'string') {
      return (options as string[]).map((option) => (
        <SelectItem key={option} value={option}>
          {option}
        </SelectItem>
      ))
    } else {
      return (options as { label: string; key: string }[]).map((option) => (
        <SelectItem key={option.key} value={option.key}>
          {option.label}
        </SelectItem>
      ))
    }
  }

  const renderSelectedItems = (items: SelectedItems<any>) => {
    return items.map((item, index) => (
      <span key={index}  className="bg-natural_lv1_text_normal rounded-full px-2 py-1 text-white text-xs">
        {item.textValue}
      </span>
    ))
  }

  return (
    <div className="flex flex-col gap-0.5 w-full">
      <FilterLabel label={label} />
      <Select
        aria-label="input"
        selectedKeys={Array.isArray(selectedValue) ? selectedValue : [selectedValue]}
        variant="bordered"
        color="primary"
        onSelectionChange={handleValueChange as any}
        placeholder={label}
        selectionMode={selectionMode}
        renderValue={
          selectionMode === 'multiple'
            ? (items: SelectedItems<any>) => (
                <div className="selected-item-container flex items-center gap-2 selected-item">
                  {renderSelectedItems(items)}
                </div>
              )
            : undefined
        }
      >
        {renderOptions()}
      </Select>
    </div>
  )
}

export default DropdownFilter
