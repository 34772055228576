import {
  Pagination,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow
} from '@nextui-org/react'
import { useEffect, useState } from 'react'
import { RenderCell } from './RenderCell'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchActivateRequests, setPage } from 'store/slices/activateRequestsSlice'
import useLanguage from 'hooks/useLanguage'
import { useNavigate } from 'react-router-dom'

import './RequestTable.css'
import { useLingui } from '@lingui/react'
import { getLocalizedName } from 'utils/getLocalizedName'
import { ActivateRequest } from 'api/types'

const RequestsTable = ({ SearchText }: { SearchText: string }) => {
  const dispatch: AppDispatch = useDispatch()
  const language = useLanguage()
  const {
    requests,
    isLoading,
    // search,
    status,
    // sortDescriptor,
    page,
    rowsPerPage,
    fromSubmissionDate,
    toSubmissionDate,
    fromResponseDate,
    toResponseDate,
    ithraUsersIds,
    studentsNumberFrom,
    studentsNumberTo,
    pagination
  } = useSelector((state: RootState) => state.activateRequests)
  const navigate = useNavigate()
  const { i18n } = useLingui()

  const goToReview = (requestId: any) => {
    navigate(`/app/activate-requests/review-request/${requestId}`)
  }
  useEffect(() => {
    dispatch(
      fetchActivateRequests({
         search:SearchText,
        status,
        // sortDescriptor,
        page,
        rowsPerPage,
        fromSubmissionDate,
        toSubmissionDate,
        fromResponseDate,
        toResponseDate,
        ithraUsersIds,
        studentsNumberFrom,
        studentsNumberTo
      })
    )
  }, [page,SearchText])

  const onChagePage = (page: number) => {
    dispatch(setPage(page))
  }
  const items = requests ?? []
  const columns = [
    { name: i18n._('معلومات المنسق'), uid: 'coordinator_information', sortable: false },
    { name: i18n._('الدولة'), uid: 'coordinator_country', sortable: false },
    { name: i18n._('المنطقة'), uid: 'region', sortable: false },
    { name: i18n._('الإدارة التعليمية'), uid: 'education_administration', sortable: false },
    { name: i18n._('تاريخ التقديم'), uid: 'submission_date', sortable: false },
    { name: i18n._('حالة الطلب'), uid: 'request_status', sortable: false },
    { name: i18n._('الإجراءات'), uid: 'actions' }
  ]



  return (
    <div className="w-full flex flex-col gap-1 p-0">
      <Table
        classNames={{
          th: 'm-0 border-b'
        }}
        className="p-0"
        aria-label="Example table with custom cells"
      >
        <TableHeader className="" columns={columns}>
          {(column) => (
            <TableColumn
              key={column.uid}
              hideHeader={column.uid === 'actions'}
              align={column.uid === 'actions' ? 'center' : 'start'}
              dir={language === 'ar' ? 'rtl' : 'ltr'}
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          items={requests}
          emptyContent={i18n._('لايوجد طلبات تفعيل')}
          isLoading={isLoading}
          loadingContent={<Spinner label="Loading..." />}
        >
          {requests.map((item, i) => (
            <TableRow
              onClick={() => goToReview(item.id)}
              key={i}
              className={`${i < items.length - 1 ? 'border-b border-gray-100' : ''} cursor-pointer`}
            >
              {(columnKey) => (
                <TableCell className="py-4">
                  {/* Pass the index along with the item */}
                  {RenderCell({ requestItem: item, columnKey, language })}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={`w-full flex ${language === 'ar' ? 'justify-end' : 'justify-start'}`}>
        {pagination && (
          <Pagination
            variant="light"
            classNames={{
              wrapper: 'gap-8',
              prev: `${language === 'ar' ? 'rotate-180' : ''}`, // Rotate arrow for RTL
              next: `${language === 'ar' ? 'rotate-180' : ''}` // Rotate arrow for RTL
            }}
            radius="full"
            className={`gap-5 p-6 ${language === 'ar' ? 'text-right' : 'text-left'}`}
            showControls
            total={pagination?.total_pages}
            page={pagination?.current_page}
            onChange={onChagePage}
          />
        )}
      </div>

      <div className="mt-4" />
    </div>
  )
}

export default RequestsTable
