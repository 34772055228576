import React, { useEffect, useRef } from 'react'
import Button from 'components/base/button'
import { useHorizontalScroll } from 'hooks/useHorizontalScroll'

interface YearSelectorProps {
  yearsList: number[]
  activeYear: number
  setActiveYear: (year: number) => void
}

const YearSelector: React.FC<YearSelectorProps> = ({ yearsList, activeYear, setActiveYear }) => {
  const containerWidth: boolean = true

  const { containerRef, scrollLeft, scrollRight, canScrollLeft, canScrollRight } =
    useHorizontalScroll({ containerWidth })

  const activeYearRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (activeYearRef.current) {
      activeYearRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      })
    }
  }, [activeYear])

  const sortedYearsList = [...yearsList].sort((a, b) => b - a)

  return (
    <div className="flex flex-row *:mx-1.5 items-center max-w-[360px] md:max-w-[480px]">
      <div>
        <Button
          trailingIcon={true}
          btnStyle="primary"
          onClick={scrollRight}
          className={` w-11 rotate-180`}
          isDisabled={!canScrollRight}
        />
      </div>
      <div ref={containerRef} className="flex flex-row overflow-x-auto scrollbar-hide ">
        {sortedYearsList.map((year: number, index: number) => (
          <div
            key={index}
            ref={year === activeYear ? activeYearRef : null}
            className={`mx-1 min-h-28 min-w-28 border-4 border-customTeal rounded-[30px] text-customTeal font-medium text-4xl text-center content-center hover:bg-customTeal hover:text-customWhite hover:border-customWhite transition-all ease-in-out duration-500 cursor-pointer ${
              year === activeYear ? 'bg-customGreen text-customWhite border-customWhite' : ''
            }`}
            onClick={() => setActiveYear(year)}
          >
            <h5>{year}</h5>
          </div>
        ))}
      </div>
      <div>
        <Button
          trailingIcon={true}
          btnStyle="primary"
          onClick={scrollLeft}
          className={` w-11`}
          isDisabled={!canScrollLeft}
        />
      </div>
    </div>
  )
}

export default YearSelector
