import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { Application } from 'api/ithraaApisTypes'
import { Pagination, Report, ReportApi } from 'api/types'

interface ApplicationStatisticsState {
  isLoading: boolean
  error: string | null
  data: Application[] | null
  exportSuccess: boolean
  pagination: Pagination | null
}

const initialState: ApplicationStatisticsState = {
  isLoading: false,
  error: null,
  data: null,
  exportSuccess: false,
  pagination: null
}

export const fetchIthraaApplicationStatistics = createAsyncThunk(
  'fetchIthraaApplicationStatistics',
  async (
    params: {
      export?: boolean;
      export_as?: 'csv' | 'pdf';
      top_n?: number;
      competition_year_id?: number;
      keys?: string[];
      submission_at_from?: Date;
      submission_at_to?: Date;
      country_id?: number;
      region_id?: number;
      education_level_id?: number;
      education_administration_id?: number;
      university_id?: number;
      school_id?: number;
      teacher_id?: number;
      teacher_name?: string;
      status?: string;
      workflow_name?: string;
      education_stage?: string;
      age_from?: number;
      age_to?: number;
      student_name?: string;
      student_email?: string;
      gender?: string;
      participation_number?: string;
      page?: number;
    },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.get('/management/applications-report', {
        params: {
          export: params.export,
          export_as: params.export_as,
          top_n: params.top_n,
          competition_year_id: params.competition_year_id,
          keys: params.keys,
          submission_at_from: params.submission_at_from,
          submission_at_to: params.submission_at_to,
          country_id: params.country_id,
          region_id: params.region_id,
          education_level_id: params.education_level_id,
          education_administration_id: params.education_administration_id,
          university_id: params.university_id,
          school_id: params.school_id,
          teacher_id: params.teacher_id,
          teacher_name: params.teacher_name,
          status: params.status,
          workflow_name: params.workflow_name,
          education_stage: params.education_stage,
          age_from: params.age_from,
          age_to: params.age_to,
          student_name: params.student_name,
          student_email: params.student_email,
          gender: params.gender,
          participation_number: params.participation_number,
          page: params.page,
        },
      })
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const reportsIthraaSlice = createSlice({
  name: 'ithraaApplicationStatistics',
  initialState,
  reducers: {
    setExportIthraaSuccess: (state, action) => {
      state.exportSuccess = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchIthraaApplicationStatistics.pending, (state, action) => {
        if (!action.meta.arg.export) state.isLoading = true
        state.error = null
      })
      .addCase(fetchIthraaApplicationStatistics.fulfilled, (state, action) => {
        if (action.meta.arg.export) {
          state.exportSuccess = true
        } else {
          state.data = action.payload?.data
        }
        state.isLoading = false
        state.pagination = action.payload?.meta?.pagination
      })
      .addCase(fetchIthraaApplicationStatistics.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export const { setExportIthraaSuccess } = reportsIthraaSlice.actions

export default reportsIthraaSlice
