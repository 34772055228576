import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import useLanguage from 'hooks/useLanguage'
import { fetchCountries } from 'store/slices/countriesSlice'
import { getLocalizedName } from 'utils/getLocalizedName'


const useCountries = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { countries, isLoading, error } = useSelector((state: RootState) => state.countries)

  useEffect(() => {
    if (countries.length === 0 && !isLoading && !error) {
      dispatch(fetchCountries()).then((action: any) => {
        if (action.payload.length === 0 && !error) {
          return
        }
      })
    }
  }, [dispatch, countries, isLoading, error])

  return { countries, isLoading, error }
}

export const useCountryOptions = () => {
  const language = useLanguage() as 'ar' | 'en'
  const { countries, isLoading, error } = useCountries()

  const options = useMemo(() => {
    if (countries.length === 0) return []

    return countries.map((country) => {
      const localizedName = getLocalizedName(country.name, language)
      const fallbackName = language === 'ar' ? country.name.en : country.name.ar

      const localizedRegionLabel = country.region_label ? country.region_label[language] : language === 'ar' ? 'منطقة' : 'Region'

      return {
        key: String(country.id),
        label: localizedName || fallbackName || 'Unknown Country',
        regionLabel: localizedRegionLabel,
        countryCode: String(country.code)
      }
    })
  }, [countries, language])

  return { options, isLoading, error }
}
