import React, { useState } from 'react'
import useDocumentTitle from 'hooks/useDocumentTitle'
import { msg } from '@lingui/macro'
import PodcastHero from './components/hero/blog-podcast'
import PodcastNavbar from './components/navbars/podcast-navbar'
import PodcastSection from './components/podcast/podcast-blog-section'

const BlogPodcasts: React.FC = () => {
  useDocumentTitle(msg`مدونة أقرأ - بودكاست`)

  const [searchValue, setSearchValue] = useState('')

  return (
    <div
      className=""
      style={{
        background:
          'radial-gradient(55.13% 22.7% at 75.63% 22.72%, rgba(43, 173, 173, 0.1) 0%, rgba(43, 173, 173, 0.03) 100%), radial-gradient(44.53% 18.34% at 13.43% 20.37%, rgba(249, 250, 251, 0.2) , rgba(249, 250, 251, 0) )'
      }}
    >
      <section id="">
        <PodcastHero />
      </section>
      <section id="" className="">
        <PodcastNavbar searchValue={searchValue} setSearchValue={setSearchValue} />
      </section>
      <section id="" className="">
        <PodcastSection searchValue={searchValue} />
      </section>
    </div>
  )
}

export default BlogPodcasts
