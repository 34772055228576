import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store' 
import { fetchCompetitionYear, CompetitionData } from 'store/slices/competitionSlice'
import { fetchApps, ISearchApp, Application } from 'store/slices/applicationsSlice'

export const useStudentActiveApplication = () => {
  const dispatch: AppDispatch = useDispatch() 

  const { account_type } = useSelector((s: RootState) => s.me.me?.data! || {})

  const [currentCompetYear, setCurrentCompetYear] = useState<CompetitionData | null>(null)
  const [upcomingCompetYear, setUpcomingCompetYear] = useState<CompetitionData | null>(null)
  const [apps, setApps] = useState<Application[]>([]) 
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (account_type !== 'student') return
    const handleUserLogin = async () => {
      try {
        const competitionYearData = await dispatch(fetchCompetitionYear()).unwrap()
        const competitionYear = competitionYearData.current
        const upcomingYear = competitionYearData.upcoming

        setCurrentCompetYear(competitionYear || null)
        setUpcomingCompetYear(upcomingYear || null)

        if (competitionYear) {
          const appsData = await dispatch(
            fetchApps({
              user: 'student',
              competition_year_id: String(competitionYear.id)
            } as ISearchApp)
          ).unwrap()

          if(appsData) {setApps(appsData.data)}
        }
      } catch (err) {
        // console.error('Failed to fetch competition year or applications', err)
        setError('Failed to fetch competition year or applications')
      } finally {
        setLoading(false)
      }
    }

    handleUserLogin()
  }, [dispatch, account_type])

  return { currentCompetYear, upcomingCompetYear, apps, error, loading }
}
