import { Textarea, TextAreaProps } from '@nextui-org/react'
import classNames from 'classnames'
import { ChangeEvent, useState, forwardRef, ClipboardEvent as ReactClipboardEvent } from 'react'
import { Trans } from '@lingui/react'
import Label from 'components/label/Label'
import WordCharCounter from './WordCharCounter'

type TextareaFieldProps = TextAreaProps & {
  label?: string
  helperText?: React.ReactNode
  error?: string | null
  maxLength?: number
  maxWords?: number
  maxRows?: number
  containerClassName?: string
  className?: string
  isDisabled?: boolean
  tooltipInfoText?: string
}

const TextareaField = forwardRef<HTMLTextAreaElement, TextareaFieldProps>(
  (
    {
      label,
      helperText,
      error,
      maxLength = 200, // Set a default maxLength
      maxRows = 5, // Default to 5 rows
      maxWords,
      containerClassName,
      className,
      isDisabled,
      tooltipInfoText,
      ...other
    },
    ref
  ) => {
    const countWords = (value: string) => value.trim().split(/\s+/).filter(Boolean).length
    const [currentWordCount, setCurrentWordCount] = useState(countWords(other.value || ''))

    const [currentLength, setCurrentLength] = useState(other.value?.length || 0)

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value
      const wordCount = countWords(value)
      const charCount = value.length

      setCurrentWordCount(wordCount)
      setCurrentLength(charCount)

      if (other.onChange) {
        other.onChange(e as any)
      }
    }

    const handlePaste = (e: ReactClipboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (!e.clipboardData) return
      const clipboardData = e.clipboardData
      const pastedData = clipboardData.getData('Text')
      const wordCount = countWords(pastedData)
      const charCount = pastedData.length

      setCurrentWordCount(currentWordCount + wordCount)
      setCurrentLength(currentLength + charCount)
    }

    return (
      <div className={`flex flex-col gap-2 ${containerClassName}`}>
        <div className="flex justify-between items-center">
          {label && <Label label={label} tooltipInfoText={tooltipInfoText} />}
          <WordCharCounter
            currentWordCount={currentWordCount}
            currentLength={currentLength}
            maxWords={maxWords}
            maxLength={maxLength}
          />
        </div>

        <Textarea
          aria-label="textarea component"
          fullWidth
          variant="bordered"
          className={` ${className} ${error ? 'border-red-500' : ''} ${isDisabled ? 'rounded-xl bg-input_disabled text-black-300' : ''}`}
          helperText={error || helperText}
          // maxLength={maxLength}
          // maxRows={maxRows}
          {...other}
          isDisabled={isDisabled}
          onChange={handleChange}
          onPaste={handlePaste}
        />
        {error && (
          <div
            className={classNames({
              'flex items-center gap-4 mt-1': true,
              'justify-between': error,
              'justify-end': !error
            })}
          >
            <p className="text-danger_default_lv1_text_normal_active_hover_pressed font-label-sm-ar">
              <Trans id={error} />
            </p>
          </div>
        )}
      </div>
    )
  }
)

export default TextareaField
