import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from 'api'
interface Causer {
  id: number
  name: string
  email: string
  unverified_email: string
  email_verified_at: string
  created_at: string
  updated_at: string
  phone: string
  gender: string
  account_type: string
  terms_agreed: number
  country_id: number
  nationality_id: number
  date_of_birth: string
  middle_name: string | null
  last_name: string | null
  region_id: number | null
  education_stage: string
  education_administration_id: number | null
  school_id: number | null
  university_id: number
  school_university_name: string | null
  gender_of_education: string | null
  education_level_id: number
  onboarding: boolean
  is_profile_completed: boolean
  created_by: number | null
  migrated_from_old_system: number
  active: number
}

export interface Activity {
  id: number
  log_name: string
  description: string
  subject_type: string
  event: string | null
  subject_id: number
  causer_type: string
  causer_id: number
  properties: object[]
  batch_uuid: string | null
  created_at: string
  updated_at: string
  causer: Causer
}

interface StudentActivitiesResponse {
  status: string
  code: number
  message: string
  data:  Activity[] 
  error: object | null
}

interface StudentActivitiesState {
  activities: Activity[] 
  isLoading: boolean
  error: string | null
}

const initialState: StudentActivitiesState = {
  activities: [],
  isLoading: false,
  error: null,
}

export const fetchStudentActivities = createAsyncThunk(
  'applicationHistory/fetch',
  async (application_id: number) => {
    const response = await axiosInstance.get<StudentActivitiesResponse>(
      `/students/applications/${application_id}/activities`
    )
    return response.data
  }
)

const applicationHistorySlice = createSlice({
  name: 'applicationHistory',
  initialState,
  reducers: {
    setStudentActivities: (state, action: PayloadAction<StudentActivitiesResponse['data']>) => {
      state.activities = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStudentActivities.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchStudentActivities.fulfilled, (state, action) => {
        state.isLoading = false
        state.activities = action.payload.data 
      })
      .addCase(fetchStudentActivities.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? 'An error occurred'
      })
  },
})

export const { setStudentActivities } = applicationHistorySlice.actions

export default applicationHistorySlice
