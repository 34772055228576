import { Trans, useLingui } from '@lingui/react'
import CheckMarkIcon from 'assets/icons/CheckMarkIcon'
import React from 'react'
import Progress from 'components/progress/Progress'
import { Tooltip } from 'components/toolTip/Tooltip'
import InformationIconOutline from 'assets/icons/InformationIconOutline'
import { msg } from '@lingui/macro'

export interface Step {
  title: string
  icon: JSX.Element
  component?: JSX.Element
  fields?: string[]
  description?: JSX.Element | string
  progressText?: string
  progressDisplay?: boolean
  progressBarValue?: number
}

interface props {
  step: Step
  index: number
  currentStep?: number | null
  numberOfSteps: number
  // isComplete?: boolean
  isErrorFree?: { isCompleted: boolean; errors: number }
  theme?: 'form' | 'awards'
}

const StepComponent: React.FC<props> = ({
  step,
  index,
  currentStep,
  numberOfSteps,
  isErrorFree,
  theme = 'form'
}) => {
  // console.log('index', index)
  // console.log('currentStep', currentStep)
  // console.log('isErrorFree', isErrorFree)

  const { _ } = useLingui()

  const completed = isErrorFree ? isErrorFree.isCompleted : false

  let progressBarColor:
    | 'default'
    | 'primary'
    | 'success'
    | 'danger'
    | 'secondary'
    | 'warning'
    | undefined = 'default'

  if (theme === 'awards') {
    progressBarColor = step.progressDisplay
      ? 'primary'
      : completed
        ? 'success'
        : currentStep != null && index <= currentStep
          ? 'primary'
          : 'default'
  } else if (theme === 'form' && currentStep != null) {
    progressBarColor =
      index === currentStep
        ? 'primary'
        : index < currentStep
          ? completed
            ? 'success'
            : 'danger'
          : 'default'
  }

  const progressBarValue = step.progressDisplay ? step.progressBarValue : 100

  const awardsTheme = `${
    index === numberOfSteps - 1
      ? `border-ithra_purple bg-ithra_purple bg-opacity-60 text-white`
      : `border-tertiary_normal text-primary_fill_heavy_overlay bg-primary_fill_medium_overlay`
  }`

  let conditionedIconStyle = ''

  if (theme === 'awards') {
    conditionedIconStyle = completed
      ? 'bg-secondary_fill_active text-white border-secondary_stroke_normal'
      : currentStep != null && index <= currentStep
        ? 'bg-tertiary_normal border-tertiary_normal text-white'
        : awardsTheme
  } else if (theme === 'form' && currentStep != null) {
    conditionedIconStyle =
      index === currentStep
        ? 'bg-tertiary_normal border-tertiary_normal text-white'
        : index < currentStep
          ? completed
            ? 'bg-secondary_fill_active text-white border-secondary_stroke_normal'
            : 'bg-danger_default_stroke_normal text-white border-danger_default_stroke_normal'
          : 'text-natural_lv2_text_normal'
  }

  const activeHighlight = 'border-3 border-white border-opacity-40 shadow-small'

  const stepTooltip = step.description ? true : false
  const barTooltip = step.progressText ? true : false

  const validationText = isErrorFree ? isErrorFree.errors + ' ' + _(msg`حقل/حقول بحاجة إلى مراجعة`) : ''
  // const validationText = _(msg`يوجد هنا`) + isErrorFree?.errors + _(msg`خانة غير مكتملة`)

  const renderIcon = () => {
    return (
      <div
        className={`transition duration-300 border-1 flex items-center justify-center size-10 rounded-full ${conditionedIconStyle} ${
          index === currentStep ? activeHighlight : ''
        }`}
      >
        {theme === 'form' && currentStep != null ? (
          index === currentStep ? (
            <div>{step.icon}</div>
          ) : index < currentStep ? (
            completed ? (
              <CheckMarkIcon fill="white" />
            ) : (
              <InformationIconOutline fill="white" />
            )
          ) : (
            <div>{step.icon}</div>
          )
        ) : (
          <div>{step.icon}</div>
        )}
      </div>
    )
  }

  const renderProgressBar = () => {
    if (index !== 0) {
      return (
        <div className="flex-1 pt-5">
          <Progress
            aria-label="progress bar"
            size="sm"
            color={progressBarColor}
            value={progressBarValue}
          />
        </div>
      )
    }
    return null
  }

  const renderErrorText = () => {
    if (
      theme === 'form' &&
      currentStep != null &&
      index < currentStep &&
      isErrorFree &&
      isErrorFree.errors > 0
    ) {
      return (
        <div className="absolute -top-6 flex flex-col items-center">
          <p className="text-center font-body-sm text-danger_default_lv1_text_normal_active_hover_pressed">
            <span className="hidden md:block md:text-nowrap">{validationText}</span>
            <span className="md:hidden font-body-h3-ar">*</span>
          </p>
        </div>
      )
    }

    return null
  }

  return (
    <div
      className={`max-w-40 flex items-start gap-2 ${index !== 0 ? 'w-full min-w-16 md:min-w-24' : ''} `}
    >
      {barTooltip ? (
        <Tooltip showArrow={true} closeDelay={1000} content={step.progressText}>
          {renderProgressBar()}
        </Tooltip>
      ) : (
        renderProgressBar()
      )}

      <div className="relative w-10">
        <div className="`absolute flex flex-col items-center gap-3">
          {renderErrorText()}

          {stepTooltip ? (
            <Tooltip showArrow={true} closeDelay={1000} content={step.description}>
              {renderIcon()}
            </Tooltip>
          ) : (
            renderIcon()
          )}

          <p className="text-center font-body-h6 text-natural_lv2_text_normal">
            <span className="md:text-nowrap">
              <Trans id={step.title} />
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default StepComponent
