import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Activity } from 'api/types'
import HistoryAction from 'components/history-actions/history-actions'
import dayjs from 'dayjs'
import { FC } from 'react'
import { amPm, timeAgo } from 'utils/time'

type HistoryChangesProps = {
  activities: Activity[]
}

const getStatus = (status: string) => {
  switch (status) {
    case 'not_started':
      return msg`لم تبدأ بعد`
    case 'started':
      return msg`بدأت`
    case 'ended':
      return msg`انتهت`
    case 'terminated':
      return msg`تم إقفال السنة`
    case 'archived':
      return msg`مؤرشفة`
    case 'active':
      return msg`مفعلة`
  }
}

export const HistoryChanges: FC<HistoryChangesProps> = ({ activities }) => {
  const { _ } = useLingui()

  function formatActivity(activity: Activity) {
    const { description, properties } = activity

    if (Array.isArray(properties)) return []

    if (description === 'updated') {
      const changes = Object.keys(properties.attributes).map((attr) => {
        const oldValue = properties.old[attr]
        const newValue = properties.attributes[attr]

        if (oldValue === newValue) return ''

        if (typeof newValue === 'boolean') {
          if (attr === 'auto_start')
            return newValue
              ? `${_(msg`تم تفعيل`)} ${_(msg`البدء التلقائي`)}`
              : `${_(msg`تم إلغاء التفعيل`)} ${_(msg`البدء التلقائي`)}`

          if (attr === 'notification_enable')
            return newValue
              ? `${_(msg`تم تفعيل`)} ${_(msg`إشعارات بداية العام`)}`
              : `${_(msg`تم إلغاء تفعيل`)} ${_(msg`إشعارات بداية العام`)}`
        }

        if (attr === 'status') {
          return `${_(msg`تم تعديل`)} ${_(msg`الحالة`)} ${_(msg`من`)} "${_(getStatus(oldValue)?.id!)}" ${_(msg`إلى`)} "${_(getStatus(newValue)?.id!)}"`
        }

        if (attr === 'name') {
          return `${_(msg`تم تعديل`)} ${_(msg`الإسم`)} ${_(msg`من`)} "${oldValue}" ${_(msg`إلى`)} "${newValue}"`
        }

        if (attr === 'close_at') {
          return formatDateChangeMessage(_(msg`تاريخ الإغلاق`), oldValue, newValue)
        }

        if (attr === 'end_date') {
          return formatDateChangeMessage(_(msg`تاريخ الإنتهاء`), oldValue, newValue)
        }

        if (attr === 'start_date') {
          return formatDateChangeMessage(_(msg`تاريخ البدء`), oldValue, newValue)
        }

        if (attr === 'description') {
          return `${_(msg`تم تعديل`)} ${_(msg`الوصف`)} ${_(msg`من`)} "${oldValue}" ${_(msg`إلى`)} "${newValue}"`
        }

        return ''
      })

      return changes.filter(Boolean).map((e) => ({ ...activity, message: e }))
    } else if (description === 'created')
      return [
        {
          ...activity,
          message: `${_(msg`تم تحديد`)} ${_(msg`الإسم`)} "${properties.attributes.name}"`
        }
      ]
  }

  function formatDateChangeMessage(eventName: string, oldValue: string, newValue: string): string {
    const oldDate = new Date(oldValue)
    const newDate = new Date(newValue)
    return `${_(msg`تم تحديد`)} ${eventName} ${_(msg`في`)} ${dayjs(oldDate).format('DD/MM/YYYY')} ${_(msg`بدلاً`)} ${_(msg`من`)} ${dayjs(newDate).format('DD/MM/YYYY')}.`
  }

  const histories = activities.map(formatActivity).flat()

  return (
    <div className="relative flex flex-col mt-8 gap-6">
      {histories.map((activity, index, arr) => (
        <HistoryAction
          key={index}
          event={activity!.event as 'created' | 'updated'}
          description={activity?.message!}
          date={timeAgo(activity!.created_at)}
          time={amPm(activity!.created_at)}
          causerName={activity!.causer!.name}
          showDivider={index < arr.length - 1}
        />
      ))}
    </div>
  )
}

export default HistoryChanges
