import { User } from 'api/types'
import dayjs from 'dayjs'
import { Application } from 'store/slices/applicationsSlice'

const unseenApps = (user?: User, apps?: Application[]) => {
  if (!apps || !user) return 0
  return apps.filter((app) =>
    dayjs(app.submission_at).isAfter(dayjs(user.last_login.last_login_at))
  ).length
}

export default unseenApps
