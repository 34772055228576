import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useLanguage from 'hooks/useLanguage'
import { AppDispatch, RootState } from 'store'
import { fetchUniversities } from 'store/slices/universitiesSlice'
import { getLocalizedName } from 'utils/getLocalizedName'
import { University, UseUniversitiesProps, UseUniversityOptionsProps } from './types'


const useUniversities = ({ countryId, regionId }: UseUniversitiesProps = {}) => {
  const dispatch = useDispatch<AppDispatch>()
  const { universities, isLoading, error } = useSelector((state: RootState) => state.universities)

  const memoizedCountryId = useMemo(() => countryId, [countryId])
  const memoizedRegionId = useMemo(() => regionId, [regionId])

  useEffect(() => {
    if (memoizedCountryId || memoizedRegionId) {
      dispatch(
        fetchUniversities({
          countryId: memoizedCountryId,
          regionsIds: memoizedRegionId ? [memoizedRegionId] : [] 
        })
      )
    }
  }, [dispatch, memoizedCountryId, memoizedRegionId])

  return { universities, isLoading, error }
}

export const useUniversityOptions = ({ countryId, regionId }: UseUniversityOptionsProps = {}) => {
  const language = useLanguage() as 'ar' | 'en'
  const { universities, isLoading, error } = useUniversities({ countryId, regionId })

  const options = useMemo(() => {
    const mappedOptions = universities.map((university: University) => ({
      key: String(university.id),
      label: getLocalizedName(university.name, language)
    }))

    // const otherOption = {
    //   key: 'other',
    //   label: language === 'ar' ? 'اسم الجامعة غير متوفر' : 'Other'
    // }

    // return [...mappedOptions, otherOption]
    return [...mappedOptions]
  }, [universities, language])

  return { options, isLoading, error }
}
