import { msg, Trans } from '@lingui/macro'
import ClipboardIcon from 'assets/icons/ClipboardIcon'
import ButtonVariant from 'components/button-variant/button'
import React from 'react'
import { copyToClipboard } from 'utils/copyToClipboard'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Link } from 'react-router-dom'
import { ArrowLeft } from 'assets/icons/ArrowLeft'
import { useLingui } from '@lingui/react'

interface CodeCardprops {
  code: string | null
  onCopy?: () => void
  layout?: string
}

const CodeCard: React.FC<CodeCardprops> = ({ code, onCopy, layout = 'col' }) => {
  const is_profile_completed = useSelector(
    (state: RootState) => state.me?.me?.data?.is_profile_completed ?? false
  )

  const handleCopyClick = () => {
    if (code) {
      let message = `قم بالمشاركة في مسابقة أقرأ من خلال الرابط (https://ireadaward.com) وأدخل الرمز الخاص بي من خلال استمارة المشاركة في خانة رمز المعلم: `
      copyToClipboard(message + code)
      onCopy && onCopy()
    }
  }

  return (
    <div
      className={`flex ${layout === 'col' ? 'flex-col size-full justify-end md:w-[224px]' : 'flex-row'} items-center gap-2.5`}
    >
      {is_profile_completed ? (
        <>
          <p className="font-heading-h6-ar text-natural_icon_normal">
            <Trans>الرمز الخاص بك</Trans>
          </p>
          <div className="w-full md:max-w-[178px] p-2 flex items-center justify-center border-2 border-natural_lv1_text_normal bg-primary_fill_soft_overlay rounded-lg">
            {code ? (
              <p className="text-natural_icon_normal font-heading-h6">{code}</p>
            ) : (
              <p className="text-natural_icon_disabled font-heading-h6">
                <Trans>لا يوجد كود متاح</Trans>
              </p>
            )}
          </div>

          <ButtonVariant
            variant="secondary"
            iconStart={<ClipboardIcon />}
            isIconOnly
            onClick={handleCopyClick}
            disabled={!code}
          />
        </>
      ) : (
        <>
          <p className="font-heading-h6-ar text-natural_icon_normal">
            <Trans>أكمل ملفك الشخصي لإظهار الرمز الخاص بك</Trans>
          </p>
          <Link
            to={'/app/account-profile'}
            className={`md:p-3 gap-3 rounded-md flex items-center transition-all duration-300`}
          >
            <p className="font-heading-h6 text-secondary_fill_normal_pressed cursor-pointer">
              <Trans>الملف الشخصي</Trans>
            </p>
            <ArrowLeft fill="#7ECD33" />
          </Link>
        </>
      )}
    </div>
  )
}

export default CodeCard
