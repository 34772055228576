import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import DatePicker from 'components/date-picker-localized/date-picker'
import dayjs from 'dayjs'

interface DateInputProps {
  name: string
  label: string
  value?: Date
  placeholder?: string
  returnAsDate?: boolean
  disabled?: boolean
  [key: string]: any
}

const DateInput: FC<DateInputProps> = ({
  name,
  label,
  value,
  disabled,
  placeholder,
  returnAsDate = false,
  ...other
}) => {
  const { control, clearErrors } = useFormContext()
  
  const handleDateChange = (e: Date | null) => {
    const formattedValue = returnAsDate ? e : e ? dayjs(e).format('YYYY-MM-DD') : null
    return formattedValue
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          {...other}
          label={label}
          value={field.value}
          placeholder={placeholder}
          disabled={disabled}
          error={error ? error.message : null}
          onChange={(e) => {
            field.onChange(handleDateChange(e))
            clearErrors(name) 
          }}
        />
      )}
    />
  )
}

export default DateInput
