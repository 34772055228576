import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type DurationType = 'all' | 'custom'
type DateType = string

interface CompetitionYearState {
  durationType: DurationType
  beginDate: DateType | null
  endDate: DateType | null
}

const initialState: CompetitionYearState = {
  durationType: 'all',
  beginDate: null,
  endDate: null
}

const coordinatorFilterSlice = createSlice({
  name: 'headCoordinatorFilter',
  initialState,
  reducers: {
    setDurationType: (state, action: PayloadAction<DurationType>) => {
      state.durationType = action.payload
    },
    setBeginDate: (state, action: PayloadAction<DateType | null>) => {
      state.beginDate = action.payload
    },
    setEndDate: (state, action: PayloadAction<DateType | null>) => {
      state.endDate = action.payload
    }
  }
})

export const { setDurationType, setBeginDate, setEndDate } = coordinatorFilterSlice.actions

export default coordinatorFilterSlice
