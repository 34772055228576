import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Form from 'components/FormStructure/Form'
import validationSchema from './validation-schema'
import TextInput from 'components/hook-form/text-input'
import ButtonVariant from 'components/button-variant/button'
import ArrowIcon from 'assets/icons/ArrowIcon'
import { AppDispatch, RootState } from 'store'
import { useNavigate } from 'react-router-dom'
import { signIn } from 'store/slices/signInSlice'
import { setToken, setEmail, setVerified, setUserType } from 'store/slices/authSlice'
import { msg } from '@lingui/macro'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import ErrorMessage from 'pages/AccountPages/components/ErrorMessage'
import SelectInput from 'components/select-input/select-input'
import UserBlocked from './user-blocked'

const UserLogin: React.FC<{
  handleOTPModalDisplay: (shouldShow: boolean) => void
  handleInactiveModalDisplay: (shouldShow: boolean) => void
  handleResetProcessModalDisplay: (shouldShow: boolean) => void

}> = ({ handleOTPModalDisplay, handleInactiveModalDisplay,handleResetProcessModalDisplay }) => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const [errorMsg, setErrorMsg] = useState<string>('')

  const isLoading = useSelector((state: RootState) => state.signIn.isLoading)

  const defaultValues = {
    email: '',
    password: ''
  }
  const data = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
    (item) => ({ label: item, value: item })
  )

  const handleTokenAndVerification = (result: any) => {
    console.log({ result })
    if (result?.data?.accessToken) {
      dispatch(setToken(result.data.accessToken))
      dispatch(setEmail(result.data.user?.email))
      dispatch(setVerified(result.data.user?.verified))
      dispatch(setUserType(result.data.user?.account_type))

      if (!result.data.user?.email_verified_at) {
        handleOTPModalDisplay(true)
      } else {
        toast.success(i18n._(msg`تم تسجيل الدخول بنجاح`))
        navigate('/app/home')
      }
    }
  }

  const onSubmit = async (data: typeof defaultValues) => {
    setErrorMsg('')
    try {
      const result = await dispatch(
        signIn({
          email: data.email,
          password: data.password
        })
      ).unwrap()

      if (result.status === 'success') {
        if (result.message == 'RESET_PROCESS_TRIGGERED') {
          handleResetProcessModalDisplay(true)
        } else handleTokenAndVerification(result)
      }
    } catch (error: any) {
      if (error.message == 'ACCOUNT_INACTIVE') {
        handleInactiveModalDisplay(true)
      } else {
        setErrorMsg(error.message)
        toast.error(i18n._(error.message))
      }
    }
  }

  return (
    <Form schema={validationSchema} onSubmit={onSubmit} defaultValues={defaultValues}>
      {errorMsg && <ErrorMessage msgstr={errorMsg} />}

      <TextInput
        name="email"
        label={msg`البريد الإلكتروني`.id}
        type="email"
        placeholder={msg`Exmple@gmail.com`.id}
        helperText="Enter your email address"
      />
      <TextInput
        name="password"
        label={msg`كلمة المرور`.id}
        type="password"
        placeholder={msg`أدخل كلمة المرور`.id}
        helperText="Enter your password"
      />
      <div className="flex w-full justify-center">
        <ButtonVariant variant="clear" onClick={() => navigate('/forgot-password')} size="lg">
          <p>
            <Trans>هل نسيت كلمة المرور؟</Trans>
          </p>
        </ButtonVariant>
      </div>

      <div className="flex w-full justify-center">
        <ButtonVariant
          variant="secondary"
          type="submit"
          size="lg"
          isLoading={isLoading}
          iconEnd={<ArrowIcon fill="currentColor" width="24" height="24" />}
          iconRotate
        >
          <p>
            <Trans>تسجيل الدخول</Trans>
          </p>
        </ButtonVariant>
      </div>
    </Form>
  )
}

export default UserLogin
