import React from 'react'
import { msg, Trans } from '@lingui/macro'
import StarIcon from 'assets/icons/StarIcon'
import TrophyIcon from 'assets/icons/TrophyIcon'
import { Step } from 'components/stepper/Step'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useLingui } from '@lingui/react'

export const useSteps = (): { steps: Step[]; currentStep: number | null } => {
  const { _ } = useLingui()

  const { totalStudents, applicationsCount, progressPercentage, target } = useSelector((state: RootState) => state.coordinatorStats)
  const direction = useSelector((state: RootState) => state.layout.direction)

  // const { totalStudents, applicationsCount, progressPercentage, target } = {
  //   applicationsCount: 0,
  //   progressPercentage: 0,
  //   totalStudents: 100,
  //   target: {
  //     id: 1,
  //     education_stage: 'general',
  //     number: 20,
  //     percentages: [
  //       {
  //         id: 1,
  //         percentage_number: 10,
  //         operator: 'Less than',
  //         remaining_applications: 10,
  //         awards: [
  //           {
  //             id: 1,
  //             name: {
  //               en: 'Award 1',
  //               ar: 'جائزه 1'
  //             },
  //             description: {
  //               en: 'This award is given to the best teacher of the year2',
  //               ar: 'تُمنح هذه الجائزة لأفضل معلم في العام'
  //             },
  //             status: true,
  //             user_type: 'coordinator',
  //             created_at: '2024-09-30 13:54:07',
  //             updated_at: '2024-09-30 13:54:07'
  //           }
  //         ]
  //       },
  //       {
  //         id: 2,
  //         percentage_number: 20,
  //         operator: 'Less than',
  //         remaining_applications: 20,
  //         awards: [
  //           {
  //             id: 2,
  //             name: {
  //               en: 'Award 2',
  //               ar: 'جائزه 2'
  //             },
  //             description: {
  //               en: 'This award is given to the best teacher of the year2',
  //               ar: 'تُمنح هذه الجائزة لأفضل معلم في العام'
  //             },
  //             status: true,
  //             user_type: 'coordinator',
  //             created_at: '2024-09-30 13:54:17',
  //             updated_at: '2024-09-30 13:54:17'
  //           }
  //         ]
  //       },
  //       {
  //         id: 7,
  //         percentage_number: 30,
  //         operator: 'Less than',
  //         remaining_applications: 30,
  //         awards: [
  //           {
  //             id: 5,
  //             name: {
  //               en: 'Award 5',
  //               ar: 'جائزه 5'
  //             },
  //             description: {
  //               en: 'This award is given to the best teacher of the year2',
  //               ar: 'تُمنح هذه الجائزة لأفضل معلم في العام'
  //             },
  //             status: true,
  //             user_type: 'coordinator',
  //             created_at: '2024-09-30 13:54:44',
  //             updated_at: '2024-09-30 13:54:44'
  //           }
  //         ]
  //       },
  //       {
  //         id: 8,
  //         percentage_number: 40,
  //         operator: 'Less than',
  //         remaining_applications: 40,
  //         awards: [
  //           {
  //             id: 5,
  //             name: {
  //               en: 'Award 5',
  //               ar: 'جائزه 5'
  //             },
  //             description: {
  //               en: 'This award is given to the best teacher of the year2',
  //               ar: 'تُمنح هذه الجائزة لأفضل معلم في العام'
  //             },
  //             status: true,
  //             user_type: 'coordinator',
  //             created_at: '2024-09-30 13:54:44',
  //             updated_at: '2024-09-30 13:54:44'
  //           }
  //         ]
  //       }
  //     ]
  //   }
  // }

  if (!target || !target.percentages) return { steps: [], currentStep: -1 }

  const percentNumbers = target.percentages.map((percentage) => percentage.percentage_number)

  let currentStepIndex = null

  const postText = _(msg`طالب متبقي للفوز`)
  const postPercentageText = _(msg`من الطلاب`)

  const steps = target.percentages.map((percentage, index) => {
    const titlePart1 = _(msg`مشاركة نسبة قدرها`)
    const titlePart2 = _(msg`من إجمالي عدد الطلاب`)

    const description = (
      <div className="text-start">
        <h3 className="font-bold">
          {titlePart1} {percentage.percentage_number}% {titlePart2}
        </h3>
        <ul className="list-disc ms-3">
          {percentage.awards.map((award) => (
            <li key={award.id}>
              {direction === 'ltr' ? award.description.en : award.description.ar}
            </li>
          ))}
        </ul>
      </div>
    )

    let progressBarValue = 0
    let progressText = ''

    const remainStd = percentage.remaining_applications

    if (remainStd > 0 && applicationsCount !== 0) {
      const stepStd =
        (percentNumbers[index] / 100) * totalStudents -
        (percentNumbers[index - 1] / 100) * totalStudents

      progressBarValue = 100 - (remainStd / stepStd) * 100
      progressBarValue = Math.max(0, Math.min(100, progressBarValue))

      progressText = `${remainStd} ${postText}`
    } else if (remainStd === 0 && applicationsCount !== 0) {
      progressBarValue = 100
    }

    if (progressBarValue > 0) {
      if (progressBarValue === 100) {
        currentStepIndex = index
      } else {
        currentStepIndex = index - 1
      }
    }

    const icon = index === 0 ? <StarIcon fill="currentColor" /> : <TrophyIcon fill="currentColor" />

    return {
      title: `${percentage.percentage_number}% ${postPercentageText}`,
      icon: icon,
      description: description,
      progressDisplay: true,
      progressBarValue: progressBarValue,
      ...(progressText && { progressText })
    }
  })

  const unitText = _(msg`طالب`)

  const finaleStepStd =
    totalStudents - (percentNumbers[percentNumbers.length - 1] / 100) * totalStudents
  const finaleRemainStd = totalStudents - applicationsCount

  let finaleProgressBarValue = 100 - (finaleRemainStd / finaleStepStd) * 100

  finaleProgressBarValue = Math.max(0, Math.min(100, finaleProgressBarValue))

  let finaleProgressText = ''
  if (finaleRemainStd > 0) {
    finaleProgressText = `${finaleRemainStd} ${postText}`
  } 
  
  const finaleStep = {
    title: `${totalStudents} ${unitText}`,
    icon: <TrophyIcon fill="currentColor" />,
    description: (
      <span>
        <Trans>إجمالي عدد الطلاب المسجلين والتابعين لك.</Trans>
      </span>
    ),
    progressDisplay: true,
    progressBarValue: finaleProgressBarValue,
    progressText: finaleProgressText
  }
  
  steps.push(finaleStep)

  if (finaleProgressBarValue > 0) {
    if (finaleProgressBarValue === 100) {
      currentStepIndex = steps.length - 1
    } else {
      currentStepIndex = steps.length - 2
    }
  }
  if (currentStepIndex === -1) {
    currentStepIndex = 0
  }

  return { steps, currentStep: currentStepIndex }
}
