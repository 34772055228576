import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { CreateWorkflowResponse } from 'api/types'

interface UpdateWorkflowCriteriaState {
  isLoading: boolean
  error: string | null
  criteriaUpdated: boolean
}

const initialState: UpdateWorkflowCriteriaState = {
  isLoading: false,
  error: null,
  criteriaUpdated: false
}

export const updateWorkflowCriteria = createAsyncThunk(
  'updateWorkflowCriteria',
  async (
    {
      competitionId,
      workflowId,
      criteriaData
    }: {
      competitionId: number
      workflowId: number
      criteriaData: {
        field: string
        operator: string
        value: string | number
      }[]
    },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.put<CreateWorkflowResponse>(
        `/management/competition-years/${competitionId}/workflows/${workflowId}/criteria`,
        { ...criteriaData }
      )
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const updateWorkflowCriteriaSlice = createSlice({
  name: 'updateWorkflowCriteria',
  initialState,
  reducers: {
    setCriteriaUpdated: (state, action) => {
      state.criteriaUpdated = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(updateWorkflowCriteria.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(updateWorkflowCriteria.fulfilled, (state) => {
        state.isLoading = false
        state.criteriaUpdated = true
      })
      .addCase(updateWorkflowCriteria.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})
export const { setCriteriaUpdated } = updateWorkflowCriteriaSlice.actions

export default updateWorkflowCriteriaSlice
