import { z } from 'zod'
import { t, msg } from '@lingui/macro'

export const criteriaSchema = z.object({
  criteria: z
    .array(
      z.object({
        linkOperator: z.string(),
        data: z.string().min(1, { message: t`هذا الحقل مطلوب` }),
        operator: z.string().min(1, { message: t`هذا الحقل مطلوب` }),
        value: z.union([
          z.string().min(1, { message: t`هذا الحقل مطلوب` }),
          z.array(z.string().min(1)).nonempty({ message: t`هذا الحقل مطلوب` })
        ])
      })
    )
    .superRefine((data, ctx) => {
      data.forEach((item, index) => {
        if (index > 0 && !item.linkOperator.trim()) {
          ctx.addIssue({
            path: [index, 'linkOperator'],
            message: t`هذا الحقل مطلوب`,
            code: z.ZodIssueCode.custom
          })
        }
      })
    })
})
