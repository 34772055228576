import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { SubmitApplicationIcon } from 'assets/icons/SubmitApplicationIcon'
import ModalHeader from 'components/modal/modal-header'
import ModalLayout from 'components/modal/modal-layout'

function ExportConfirmation({
  title,
  subtitle,
  onBack
}: {
  title?: string
  subtitle?: string
  onBack: () => void
}) {
  const { _ } = useLingui()
  return (
    <ModalLayout
      forOthersPage
      onBack={onBack}
      showBackButton={true}
      backdrop="opaque"
      header={
        <ModalHeader
          subheaderText={
            <>
              <div className="flex flex-col gap-2">
                <div className="mb-4 flex w-full justify-center">
                  <SubmitApplicationIcon />
                </div>
                <span className="font-bold text-lg text-center">{title}</span>
                <span className=" text-center">{subtitle}</span>
              </div>
            </>
          }
        />
      }
    />
  )
}

export default ExportConfirmation
