import React, { useEffect } from 'react'
import TextInput from 'components/hook-form/text-input'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import DateInput from 'components/hook-form/date-picker-input'
import SelectInput from 'components/hook-form/select-field'
import { useGenderOptions } from '../hooks/useGenderOptions'
import { useNationalityOptions } from '../hooks/useNationalityOptions'
import { useFormContext } from 'react-hook-form'

const PersonalInfoForm = () => {
  const { _ } = useLingui()

  const genderOptions = useGenderOptions()
  const { options: nationalityOptions, isLoading: NationalityOptionsLoading } =
    useNationalityOptions()

  const { watch, setValue } = useFormContext()
  const dateOfBirth = watch('personal_info.date_of_birth')

  useEffect(() => {
    if (dateOfBirth) {
      const birthDate = new Date(dateOfBirth)
      const today = new Date()

      if (birthDate > today) {
        setValue('personal_info.age', 0)
        return
      }

      let age = today.getFullYear() - birthDate.getFullYear()
      const monthDiff = today.getMonth() - birthDate.getMonth()
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }

      setValue('personal_info.age', age >= 0 ? age : null)
    }
  }, [dateOfBirth, setValue])

  return (
    <>
      <TextInput
        name="personal_info.first_name"
        label={_(msg`الاسم الأول`)}
        width="100%"
        placeholder={_(msg`الاسم الأول`)}
      />
      <TextInput
        name="personal_info.second_name"
        label={_(msg`الاسم الأوسط`)}
        width="100%"
        placeholder={_(msg`الاسم الأوسط`)}
      />
      <TextInput
        name="personal_info.last_name"
        label={_(msg`الاسم الأخير`)}
        width="100%"
        placeholder={_(msg`الاسم الأخير`)}
      />
      <DateInput name="personal_info.date_of_birth" label={_(msg`تاريخ الميلاد`)} />
      <TextInput
        name="personal_info.age"
        label={_(msg`العمر`)}
        width="100%"
        placeholder={_(msg`العمر`)}
        disabled={true}
      />
      <SelectInput
        name="personal_info.gender"
        label={_(msg`الجنس`)}
        options={genderOptions}
        selectionMode="single"
        placeholder={_(msg`اختر الجنس`)}
      />
      <SelectInput
        name="personal_info.nationality"
        label={_(msg`الجنسية`)}
        isLoading={NationalityOptionsLoading}
        options={nationalityOptions}
        selectionMode="single"
        placeholder={_(msg`اختر الجنسية`)}
        mode="search"
      />
    </>
  )
}

export default PersonalInfoForm
