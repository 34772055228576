import React from 'react'
import ModalHeader from 'components/modal/modal-header'
import ModalLayout from 'components/modal/modal-layout'
import { Trans } from '@lingui/macro'
import { msg } from '@lingui/macro'
import ButtonVariant from 'components/button-variant/button'

interface props {
  onConfirm: () => void
  onCancel: () => void
  isActive: boolean
}

const AccActivationModal: React.FC<props> = ({ onConfirm, onCancel, isActive }) => {
  return (
    <ModalLayout
      onBack={onCancel}
      accountPageStyle
      header={
        <ModalHeader
          headerText={
            isActive ? msg`هل تود تأكيد إلغاء تفعيل الحساب` : msg`هل تود تأكيد تفعيل الحساب`
          }
          subheaderText={
            <p>
              {isActive ? (
                <Trans>
                  في حالة تأكيد إلغائك تفعيلك لحساب المستخدم سيتم إرسال بريد إلكتروني للمستخدم
                </Trans>
              ) : (
                <Trans>في حالة تأكيد تفعيلك لحساب المستخدم سيتم إرسال بريد إلكتروني للمستخدم</Trans>
              )}
            </p>
          }
        />
      }
      footer={
        <>
          <div className="flex items-center gap-2">
            <ButtonVariant onClick={onCancel} variant="bordered">
              <Trans>إلغاء</Trans>
            </ButtonVariant>
            <ButtonVariant onClick={onConfirm} variant="secondary">
              <Trans>تأكيد</Trans>
            </ButtonVariant>
          </div>
        </>
      }
    />
  )
}

export default AccActivationModal
