import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { IthraUsersResponse, IthraUser } from 'api/types'

interface StudentState {
  studentUsers: IthraUser[]
  isLoading: boolean
  error: string | null
}

const initialState: StudentState = {
  studentUsers: [],
  isLoading: false,
  error: null
}

export const fetchStudentUsers = createAsyncThunk('studentUsers/fetchStudentUsers', async () => {
  const response = await axiosInstance.get<IthraUsersResponse>('/management/users', {
    params: {
      type: 'student'
    }
  })
  return response.data
})

const studentUsersSlice = createSlice({
  name: 'studentUsers',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchStudentUsers.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchStudentUsers.fulfilled, (state, action) => {
        state.isLoading = false
        const studentUsers = action.payload.data ?? [] 
        state.studentUsers = studentUsers
      })
      .addCase(fetchStudentUsers.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default studentUsersSlice
