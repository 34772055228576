import React from 'react'

interface Props {
  fill?: string
}

const WorkFlowIcon: React.FC<Props> = ({ fill = '#3F3F46' }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6667 0.666016C15.1267 0.666016 15.5 1.03935 15.5 1.49935V4.63018L13.8333 6.29685V2.33268H2.16667V15.666H13.8333V13.3677L15.5 11.701V16.4993C15.5 16.9594 15.1267 17.3327 14.6667 17.3327H1.33333C0.873333 17.3327 0.5 16.9594 0.5 16.4993V1.49935C0.5 1.03935 0.873333 0.666016 1.33333 0.666016H14.6667ZM16.1483 6.33935L17.3267 7.51768L10.845 13.9993L9.665 13.9977L9.66667 12.821L16.1483 6.33935ZM8.83333 8.99935V10.666H4.66667V8.99935H8.83333ZM11.3333 5.66602V7.33268H4.66667V5.66602H11.3333Z"
        fill={fill}
      />
    </svg>
  )
}

export default WorkFlowIcon
