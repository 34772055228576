import React from 'react'
interface props {
  fill?: string
}

const CloseCircleIcon: React.FC<props> = ({ fill = '#3F3F46' }) => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.375 22.9038C6.852 22.9038 2.375 18.4268 2.375 12.9038C2.375 7.38081 6.852 2.90381 12.375 2.90381C17.898 2.90381 22.375 7.38081 22.375 12.9038C22.375 18.4268 17.898 22.9038 12.375 22.9038ZM12.375 20.9038C14.4967 20.9038 16.5316 20.061 18.0319 18.5607C19.5321 17.0604 20.375 15.0255 20.375 12.9038C20.375 10.7821 19.5321 8.74725 18.0319 7.24695C16.5316 5.74666 14.4967 4.90381 12.375 4.90381C10.2533 4.90381 8.21844 5.74666 6.71815 7.24695C5.21785 8.74725 4.375 10.7821 4.375 12.9038C4.375 15.0255 5.21785 17.0604 6.71815 18.5607C8.21844 20.061 10.2533 20.9038 12.375 20.9038ZM12.375 11.4898L15.203 8.66081L16.618 10.0758L13.789 12.9038L16.618 15.7318L15.203 17.1468L12.375 14.3178L9.547 17.1468L8.132 15.7318L10.961 12.9038L8.132 10.0758L9.547 8.66081L12.375 11.4898Z"
      fill={fill}
    />
  </svg>
)

export default CloseCircleIcon
