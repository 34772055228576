import React from 'react'

interface MedalIconProps {
  fill?: string
  size?: string
}

const MedalIcon: React.FC<MedalIconProps> = ({ fill = '#13100D', size = '20' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5178_29101)">
        <path
          d="M9.9987 5.83156C11.7668 5.83156 13.4625 6.53394 14.7127 7.78418C15.963 9.03443 16.6654 10.7301 16.6654 12.4982C16.6654 14.2663 15.963 15.962 14.7127 17.2123C13.4625 18.4625 11.7668 19.1649 9.9987 19.1649C8.23059 19.1649 6.5349 18.4625 5.28465 17.2123C4.03441 15.962 3.33203 14.2663 3.33203 12.4982C3.33203 10.7301 4.03441 9.03443 5.28465 7.78418C6.5349 6.53394 8.23059 5.83156 9.9987 5.83156V5.83156ZM9.9987 7.49823C8.67262 7.49823 7.40085 8.02501 6.46316 8.9627C5.52548 9.90038 4.9987 11.1721 4.9987 12.4982C4.9987 13.8243 5.52548 15.0961 6.46316 16.0338C7.40085 16.9714 8.67262 17.4982 9.9987 17.4982C11.3248 17.4982 12.5966 16.9714 13.5342 16.0338C14.4719 15.0961 14.9987 13.8243 14.9987 12.4982C14.9987 11.1721 14.4719 9.90038 13.5342 8.9627C12.5966 8.02501 11.3248 7.49823 9.9987 7.49823ZM9.9987 8.74823L11.1012 10.9816L13.5654 11.3399L11.782 13.0774L12.2029 15.5324L9.9987 14.3732L7.79453 15.5316L8.21536 13.0774L6.43203 11.3391L8.8962 10.9807L9.9987 8.74823ZM14.9987 1.6649V4.1649L13.8629 5.11323C12.9203 4.61871 11.8913 4.31059 10.832 4.20573V1.6649H14.9987ZM9.16536 1.66406V4.20573C8.10649 4.31043 7.07772 4.61827 6.13536 5.1124L4.9987 4.1649V1.6649L9.16536 1.66406Z"
          fill="#3F3F46"
        />
      </g>
      <defs>
        <clipPath id="clip0_5178_29101">
          <rect width="20" height="20" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MedalIcon
