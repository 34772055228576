import {
  Modal,
  ModalContent,
  ModalProps,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ScrollShadow
} from '@nextui-org/react'
import ArrowIcon from 'assets/icons/ArrowIcon'
import ButtonVariant from 'components/button-variant/button'
import { FC, ReactNode } from 'react'
import ModalCloseButton from './modal-close-button'

interface ModalLayoutProps extends Omit<ModalProps, 'children' | 'open'> {
  showCloseButton?: boolean
  showBackButton?: boolean
  defaultOpen?: boolean
  isKeyboardDismissDisabled?: boolean
  isDismissable?: boolean
  onBack?: () => void
  header?: ReactNode
  body?: ReactNode
  footer?: ReactNode
  children?: ReactNode
  className?: string
  backdrop?: 'transparent' | 'opaque' | 'blur' | undefined
  accountPageStyle?: boolean
  coordinitorPageStyle?: boolean
  onClose?: () => void
  appStyle?: boolean
  isDrawer?: boolean
  forOthersPage?: boolean
}

const ModalLayout: FC<ModalLayoutProps> = ({
  showCloseButton = false,
  showBackButton = true,
  defaultOpen = true,
  isKeyboardDismissDisabled = true,
  isDismissable = false,
  onBack,
  header,
  body,
  footer,
  children,
  className,
  backdrop = 'transparent',
  accountPageStyle,
  coordinitorPageStyle = false,
  appStyle,
  onClose,
  isDrawer,
  forOthersPage,
  ...other
}) => {
  const combinedClassNames = {
    wrapper: `${accountPageStyle ? `justify-center pt-24 md:pt-32 bg-gradient-to-t from-primary_fill_hover to-primary_fill_hover-900/50` : forOthersPage ? '' : 'z-40'}`,
    backdrop: `${coordinitorPageStyle ? `bg-gradient-to-t from-primary_fill_hover to-primary_fill_hover-900/10 backdrop-opacity-20` : ``} z-40`,
    base: `${accountPageStyle ? `pointer-events-auto` : ''} ${appStyle ? `` : ''}`
  }

  return (
    <Modal
      hideCloseButton={!showCloseButton}
      closeButton={<ModalCloseButton onClose={onClose} />}
      backdrop={backdrop}
      size={isDrawer ? 'full' : undefined}
      placement="center"
      isDismissable={isDismissable}
      isKeyboardDismissDisabled={isKeyboardDismissDisabled}
      defaultOpen={defaultOpen}
      className={`${className}`}
      classNames={combinedClassNames}
      motionProps={
        isDrawer
          ? {
              variants: {
                enter: {
                  x: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                    ease: 'easeOut'
                  }
                },
                exit: {
                  x: -300,
                  opacity: 0,
                  transition: {
                    duration: 0.2,
                    ease: 'easeIn'
                  }
                }
              }
            }
          : undefined
      }
      {...other}
    >
      <ModalContent
        className={`flex flex-col ${isDrawer ? 'px-0 py-0' : ' px-10 py-6'} gap-6 ${isDrawer ? 'w-1/3  h-screen fixed top-0 left-0 overflow-y-auto ' : ''}`}
      >
        {(onClose) => (
          <>
            {showBackButton && (
              <div className="w-full flex justify-end text-natural_icon_normal">
                <ButtonVariant
                  isIconOnly
                  size="sm"
                  variant="clear"
                  iconEnd={<ArrowIcon fill="currentColor" width="24" height="24" />}
                  iconRotate
                  onClick={onBack || onClose}
                />
              </div>
            )}
            <ModalHeader className="w-full p-0">{header}</ModalHeader>
            <ScrollShadow className="w-full p-0 scrollbar-thin scrollbar-thumb-gray-400">
              <ModalBody className="w-full p-0" style={{ overflowY: 'auto' }}>
                {body}
                {children}
              </ModalBody>
            </ScrollShadow>
            <ModalFooter className="w-full p-0 flex justify-center items-center">
              {footer}
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default ModalLayout
