import { zodResolver } from '@hookform/resolvers/zod'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import Typography from 'components/typography/typography'
import { FormProvider, useForm } from 'react-hook-form'
import { useCoordinatorActiveAccountSchema } from './schema'
import { z } from 'zod'

import { useRegionOptions } from 'pages/AccountProfile/personal-info/hook/regions-options'
import {
  useEducationStageOptions,
  useGenderOfEducationOptions
} from 'pages/AccountProfile/personal-info/utils'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchMe } from 'store/slices/meSlice'
import { useEducationAdministrationOptions } from 'pages/AccountProfile/personal-info/hook/eduction-adminstrations-options'
import CheckboxInput from 'components/hook-form/checkbox-input'
import { useUniversityOptions } from 'pages/AccountProfile/personal-info/hook/university-options'
import TextInput from 'components/hook-form/text-input'
import UploadInput from 'components/hook-form/upload-input'
import TextareaInput from 'components/hook-form/textarea-input'
import ButtonVariant from 'components/button-variant/button'
import { ArrowLeft } from 'assets/icons/ArrowLeft'
import PendingApplicationModule from './pending-application-module/pendding-applicatoin-module'
import {
  fetchFileFromURL,
  getCoordinatorStatusApplication,
  getRegionLabelFromCountry
} from './utils'
import RejectApplicationModule from './reject-application-module/reject-application-module'
import SubmitApplicationModule from './submit-application-module/submit-application-module'
import { completeCoordinator } from 'store/slices/completeCoordinatorSlice'
import useLanguage from 'hooks/useLanguage'
import Alert from 'components/alert/alert'
import SelectInput from 'components/hook-form/select-field'

function CoordinatorActiveAccount() {
  const { _, i18n } = useLingui()
  const language = useLanguage()
  const { me } = useSelector((state: RootState) => state.me)
  const dispatch = useDispatch<AppDispatch>()
  const [countryCode, setCountryCode] = useState<string | null>(null)
  const [countryId, setCountryId] = useState<string | null>(null)
  const [applicationStatus, setApplicationStatus] = useState<
    'draft' | 'pending' | 'approved' | 'rejected' | undefined
  >(undefined)
  const [showRejectModule, setShowRejectModule] = useState<boolean>(true)
  const [showSubmitModule, setShowSubmitModule] = useState<boolean>(false)
  const [regionLabelCountry, setRegionLabelCountry] = useState<string | null>(null)

  const [showAlert, setShowAlert] = useState<{ type: 'success' | 'error'; message: string } | null>(
    null
  )
  const [rejectAlert, setRejectAlert] = useState<string | null>(null)
  const coordinatorActiveAccountSchema = useCoordinatorActiveAccountSchema(countryCode === 'SA')
  //   const regionOptions = useRegionOptions()
  const educationStageOptions = useEducationStageOptions()
  const {
    options: educationAdministrationOptions,
    isLoading: EducationAdministrationOptionLoading
  } = useEducationAdministrationOptions({
    countryId: countryId!
  })
  const { options: regionOptions, isLoading: RegionOptionsLoading } = useRegionOptions(countryId!)
  const { options: universityOptions, isLoading: UniversityOptionsLoading } = useUniversityOptions({
    countryId: countryId!
  })
  const genderOfEducationOptions = useGenderOfEducationOptions()

  // Infer the type from the schema
  type CoordinatorActiveAccountType = z.infer<typeof coordinatorActiveAccountSchema>

  // Initialize the form
  const methods = useForm<CoordinatorActiveAccountType>({
    resolver: zodResolver(coordinatorActiveAccountSchema),
    defaultValues: {
      regions: [],
      education_stage: undefined,
      education_administrations: [],
      all_regions: false,
      all_education_stages: false,
      all_education_administrations: false,
      university: undefined,
      gender_of_education: undefined,
      number_of_students: undefined,
      proof_document: undefined,
      comment: undefined,
      school_university_name: undefined,
      action: 'submit'
    }
  })

  const {
    handleSubmit,
    clearErrors,
    setValue,
    reset,
    watch,
    formState: { isSubmitting }
  } = methods
  const education_stage = watch('education_stage')
  const all_regions = watch('all_regions')
  const all_education_stage = watch('all_education_stages')
  const all_education_administrations = watch('all_education_administrations')
  const university = watch('university')
  const action = watch('action')

  const onSubmit = async (data: CoordinatorActiveAccountType) => {
    setRejectAlert(null)
    setShowAlert(null)
    try {
      let payload: Record<string, any> = {
        regions: data.regions && data.regions.length > 0 ? data.regions : undefined,
        education_stages: data.education_stage ? [data.education_stage] : undefined,
        education_administrations:
          data.education_administrations && data.education_administrations.length > 0
            ? data.education_administrations
            : undefined,
        all_regions: data.all_regions ? 1 : 0,
        all_education_stages: data.all_education_stages ? 1 : 0,
        all_education_administrations: data.all_education_administrations ? 1 : 0,
        university: data.university === 'other' ? undefined : data.university,
        gender_of_education: data.gender_of_education || undefined,
        number_of_students: data.number_of_students || undefined,
        school_university_name:
          data.university === 'other' ? data.school_university_name : undefined,
        comment: data.comment || undefined,
        action: data.action
      }

      // Handle proof_document separately
      if (typeof data.proof_document === 'string') {
        delete payload.proof_document // Exclude proof_document if it's a link (string)
      } else if (data.proof_document === null) {
        payload.delete_proof_document = true // Send proof_document as null if it's removed
      } else if (data.proof_document instanceof File) {
        payload.proof_document = data.proof_document // Include the file if it's uploaded
      }

      const result = await dispatch(completeCoordinator(payload)).unwrap()
      if (result.status === 'success') {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        setShowAlert({
          type: 'success',
          message:
            payload.action == 'save' ? _(msg`تم حفظ الطلب بنجاح`) : _(msg`تم إرسال الطلب بنجاح`)
        })
        if (payload.action == 'submit') setShowSubmitModule(true)
      }
    } catch (error: any) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      const errorMessage = i18n._(error.message) || i18n._(msg`هنالك خطأ`)
      setShowAlert({ type: 'error', message: errorMessage })
    }
  }
  const handleSaveDraft = async () => {
    setValue('action', 'save')
    clearErrors()
    await handleSubmit(onSubmit)()
  }
  const handleSubmitClick = async () => {
    setValue('action', 'submit')
    await handleSubmit(onSubmit)()
  }

  useEffect(() => {
    dispatch(fetchMe())
  }, [dispatch, reset])

  useEffect(() => {
    if (me?.data) {
      setCountryCode(me.data.country.code)
      setCountryId(String(me.data.country.id))
      if (me.data.country.region_label)
        setRegionLabelCountry(getRegionLabelFromCountry(me.data.country.region_label, language))
      setApplicationStatus(getCoordinatorStatusApplication(me.data))
    }
  }, [language, me])

  useEffect(() => {
    const updateFormDefaults = async () => {
      if (
        me?.data?.coordinator &&
        (applicationStatus === 'pending' ||
          applicationStatus === 'rejected' ||
          applicationStatus === 'draft')
      ) {
        const coordinator = me.data.coordinator

        // Map regions to their IDs
        const formattedRegions = coordinator.regions.map((region) => String(region.id))

        // Map education_administration to their IDs
        const formattedEducationAdministration = coordinator.education_administrations
          ? coordinator.education_administrations?.map((administration) =>
              String(administration.id)
            )
          : []

        // Set the form values
        reset({
          regions: formattedRegions,
          education_stage:
            coordinator.education_stages && coordinator.education_stages.length != 2
              ? coordinator.education_stages[0]
              : undefined,
          education_administrations: formattedEducationAdministration,
          all_regions: Boolean(coordinator.all_regions) || false,
          all_education_stages: Boolean(coordinator.all_education_stages) || false,
          all_education_administrations:
            Boolean(coordinator.all_education_administrations) || false,
          university: coordinator.university
            ? String(coordinator.university.id)
            : coordinator.school_university_name
              ? 'other'
              : undefined,
          gender_of_education: coordinator.gender_of_education
            ? coordinator.gender_of_education
            : undefined,
          number_of_students: coordinator.number_of_students
            ? coordinator.number_of_students
            : undefined,
          comment: coordinator.comment ? coordinator.comment : undefined,
          school_university_name: coordinator.school_university_name
            ? coordinator.school_university_name
            : undefined,
          proof_document: coordinator.proof_document ? coordinator.proof_document : undefined,
          action: 'submit'
        })
      }
    }

    updateFormDefaults()
  }, [applicationStatus, me, reset])

  const softReset = () => {
    reset({
      regions: [],
      education_stage: undefined,
      education_administrations: [],
      all_regions: false,
      all_education_stages: false,
      all_education_administrations: false,
      university: undefined,
      gender_of_education: undefined,
      number_of_students: undefined,
      proof_document: undefined,
      comment: undefined,
      school_university_name: undefined,
      action: 'submit'
    })
  }
  const handleRejectAndAlert = () => {
    setRejectAlert(me?.data?.coordinator?.rejection_reason || _(msg`غير محدد`))
    softReset()
  }

  useEffect(() => {
    if (all_regions) setValue('regions', [])
    if (all_education_stage) setValue('education_stage', undefined)
    if (all_education_administrations) setValue('education_administrations', [])
  }, [all_education_administrations, all_education_stage, all_regions, setValue])

  return (
    <div className="flex flex-col gap-5 w-full">
      <div className="flex flex-col gap-2 items-start">
        <Typography size="xl" weight="bold">
          {_(msg`البيانات المطلوبة لتنشيط الحساب`)}
        </Typography>
        <Typography size="md">
          {_(msg`يرجى ملء الإستمارة التالية وتقديمها ليتم تفعيل حسابك بعد مراجعته`)}
        </Typography>
      </div>
      {showAlert && (
        <Alert
          mainText={showAlert.message}
          sizeMainText="md"
          weightMainText="normal"
          onClose={() => setShowAlert(null)}
          type={showAlert.type}
        />
      )}
      {rejectAlert && (
        <Alert
          mainText={`${_(msg`تم رفض طلبك للسبب التالي`)}: ${rejectAlert}`}
          sizeMainText="md"
          weightMainText="normal"
          type="error"
          onClose={() => setRejectAlert(null)}
        />
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4 w-full">
          <div className="flex flex-col md:flex-row gap-4 w-full items-center">
            <div className="w-full md:w-1/4">
              <SelectInput
                name="regions"
                size="md"
                selectionMode="multiple"
                mode="search"
                isDisabled={all_regions}
                isLoading={RegionOptionsLoading}
                options={regionOptions}
                label={regionLabelCountry ? regionLabelCountry : _(msg`المنطقة`)}
                placeholder={_(msg`اختر المنطقة`)}
              />
            </div>
            <div className="w-full md:w-auto mt-4 md:mt-0">
              <div className="flex items-center gap-x-2">
                <CheckboxInput
                  rounded
                  className="mt-8"
                  removeBorder
                  reverse
                  name="all_regions"
                  label={_(msg`جميع المناطق`)}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-4 w-full">
            <div className="w-full md:w-1/4">
              <SelectInput
                name="education_stage"
                size="md"
                isDisabled={all_education_stage}
                options={educationStageOptions}
                label={_(msg`مرحلة التعليم`)}
                placeholder={_(msg`اختر المرحلة التعليمية`)}
              />
            </div>
            <div className="w-full md:w-auto mt-4 md:mt-0">
              <div className="flex items-center gap-x-2">
                <CheckboxInput
                  rounded
                  removeBorder
                  reverse
                  className="mt-8"
                  name="all_education_stages"
                  label={_(msg`جميع المراحل التعليمية `)}
                />
              </div>
            </div>
          </div>
          {countryCode === 'SA' && education_stage === 'general' && (
            <div className="flex flex-col md:flex-row items-center gap-4 w-full">
              <div className="w-full md:w-1/4">
                <SelectInput
                  name="education_administrations"
                  size="md"
                  mode="search"
                  selectionMode="multiple"
                  isDisabled={all_education_administrations}
                  isLoading={EducationAdministrationOptionLoading}
                  options={educationAdministrationOptions}
                  label={_(msg`الإدارة التعليمية`)}
                  placeholder={_(msg`اختر الادارات التعليمية`)}
                />
              </div>
              <div className="w-full md:w-auto mt-4 md:mt-0">
                <div className="flex items-center gap-x-2">
                  <CheckboxInput
                    rounded
                    removeBorder
                    className="mt-8"
                    reverse
                    name="all_education_administrations"
                    label={_(msg`جميع الإدارات التعليمية `)}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="w-full md:w-1/4">
            <SelectInput
              name="gender_of_education"
              multiple
              size="md"
              options={genderOfEducationOptions}
              label={_(msg`جنس التعليم`)}
              placeholder={_(msg`اختر جنس التعليم`)}
            />
          </div>
          {education_stage === 'university' && (
            <div className="flex flex-col md:flex-row items-center gap-4 w-full">
              <div className="w-full md:w-1/4">
                <SelectInput
                  name="university"
                  multiple
                  size="md"
                  mode="search"
                  isLoading={UniversityOptionsLoading}
                  options={universityOptions}
                  showDefaultOptionWhenNoResults={true}
                  label={_(msg`الجامعة`)}
                  placeholder={_(msg`اختر الجامعة`)}
                />
              </div>
              {university === 'other' && (
                <div className="w-full md:w-1/4 mt-4 md:mt-0">
                  <TextInput
                    name="school_university_name"
                    size="md"
                    label={_(msg`اسم الجامعة`)}
                    placeholder={_(msg`قم بكتابة اسم الجامعة`)}
                  />
                </div>
              )}
            </div>
          )}
          <TextInput
            className="w-full md:w-1/4"
            name="number_of_students"
            type="number"
            size="md"
            label={_(msg`عدد الطلاب`)}
            placeholder={_(msg`عدد الطلاب`)}
          />
          <UploadInput
            name="proof_document"
            label={_(msg`مستند داعم`)}
            placeholder={_(msg`حجم الملف الاقصى 3 ميجا`)}
            onRemove={() => setValue('proof_document', null)}
          />
          <TextareaInput
            name="comment"
            maxLength={200}
            minRows={5}
            label={_(msg`ملاحظات`)}
            placeholder={_(msg`بإمكانك إضافة ملاحظاتك هنا`)}
          />
          <div className="flex justify-end gap-4 flex-row items-center w-full">
            <ButtonVariant
              type="button"
              isLoading={isSubmitting && action !== 'submit'}
              isDisabled={(isSubmitting && action !== 'submit') || applicationStatus === 'pending'}
              variant="secondary-outline"
              onClick={handleSaveDraft}
            >
              {_(msg`حفظ كمسودة`)}
            </ButtonVariant>
            <ButtonVariant
              type="button"
              variant="secondary"
              isLoading={isSubmitting && action !== 'save'}
              isDisabled={(isSubmitting && action !== 'save') || applicationStatus === 'pending'}
              endContent={<ArrowLeft />}
              onClick={handleSubmitClick}
            >
              {_(msg`طلب تفعيل الحساب `)}
            </ButtonVariant>
          </div>
        </form>
      </FormProvider>
      {applicationStatus === 'pending' && <PendingApplicationModule />}
      {showRejectModule && applicationStatus === 'rejected' && (
        <RejectApplicationModule
          onClose={() => setShowRejectModule(false)}
          rejectReason={me?.data?.coordinator?.rejection_reason || _(msg`غير محدد`)}
          resetForm={handleRejectAndAlert}
        />
      )}
      {showSubmitModule && <SubmitApplicationModule />}
    </div>
  )
}

export default CoordinatorActiveAccount
