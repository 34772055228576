import React from 'react'

interface ToolTipProps {
  fill?: string
}

const ToolTip: React.FC<ToolTipProps> = ({ fill = 'white' }) => {
  return (
    <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9696 11.4079C10.1777 12.2658 8.82232 12.2658 8.03039 11.4079L0.598359 3.35656C-0.584229 2.07542 0.324456 5.61237e-08 2.06797 2.08546e-07L16.932 1.508e-06C18.6755 1.66043e-06 19.5842 2.07542 18.4016 3.35656L10.9696 11.4079Z"
        fill={fill}
      />
    </svg>
  )
}

export default ToolTip
