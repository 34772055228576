import TabsNavigator from 'components/tabs-navigator/tabs-navigator'
import PersonalInfoExtra from './personal-info/personal-info'
import RestAccountPassword from './password/rest-account-passwrod'
import RestEmail from './rest-email/rest-email'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

export default function AccountProfile() {
  const { _ } = useLingui()
  const tabItems = [
    {
      key: 'workflows',
      title: _(msg`البيانات الشخصية`),
      content: <PersonalInfoExtra />
    },
    {
      key: 'year-information',
      title: _(msg`البريد الإلكتروني`),
      content: <RestEmail />
    },
    {
      key: 'settings',
      title: _(msg`كلمة المرور`),
      content: <RestAccountPassword />
    }
  ]

  return (
    <div className="flex flex-col w-full">
      <TabsNavigator variant="underlined" color="success" items={tabItems} />
    </div>
  )
}
