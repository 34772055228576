// import React, { useState, useEffect } from 'react'
import TextInput from 'components/hook-form/text-input'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import CheckboxInput from 'components/hook-form/checkbox-input'
import TextareaInput from 'components/hook-form/textarea-input'
import { useState } from 'react'
import TermsModal from 'pages/SubmitApplication/modals/TermsModal'

const ApplicationBodyForm = () => {
  const { _ } = useLingui()
  
  const [showTermsModal, setShowTermsModal] = useState(false)

  const handelTermsLinkClick = () => {
    setShowTermsModal(true)
  }

  return (
    <>
      <div className="col-span-3">
        <TextareaInput
          name="application_body.reading_story"
          label={_(msg`قصتك مع القراءة`)}
          placeholder={_(msg`قصتك مع القراءة`)}
          maxWords={500}
          tooltipInfoText={_(
            msg`اكتب لنا عن بداياتك مع القراءة. كيف أثرت بك؟ وكيف تسعى لتطوير علاقتك معها ومع الكتب؟`
          )}
        />
      </div>
      <TextInput
        name="application_body.book_title"
        width="100%"
        label={_(msg`عنوان الكتاب`)}
        placeholder={_(msg`عنوان الكتاب`)}
        type="text"
      />
      <TextInput
        name="application_body.author_name"
        label={_(msg`اسم المؤلف`)}
        type="text"
        placeholder={_(msg`اسم مؤلف`)}
      />
      <div className="col-span-3">
        <TextareaInput
          name="application_body.reason_for_choice"
          label={_(msg`سبب اختيارك للكتاب ؟`)}
          placeholder={_(msg`سبب اختيارك للكتاب`)}
          maxWords={500}
          tooltipInfoText={_(
            msg`أخبرنا لماذا اخترت هذا الكتاب من بين جميع الكتب التي قمت بقراءتها ؟`
          )}
        />
      </div>
      <div className="col-span-3">
        <TextareaInput
          name="application_body.book_review"
          label={_(msg`مراجعة الكتاب`)}
          placeholder={_(msg`مراجعة الكتاب`)}
          maxWords={1000}
          tooltipInfoText={_(
            msg` مراجعة الكتاب هي ملخص عن الكتاب يحتوي على أهم النقاط الرئيسة في الكتاب ورأي المشارك فيه.`
          )}
        />
      </div>
      <TextInput
        name="application_body.teacher_code"
        label={_(msg`رمز المعلم`)}
        type="text"
        placeholder={_(msg`رمز المعلم`)}
        tooltipInfoText={_(
          msg`هو رمز خاص بكل معلم، يحصل عليه بعد تسجيله في مسار سفراء القراءة الخاص بالمعلمين والمعلمات`
        )}
      />
      <div className="col-span-3">
        <CheckboxInput
          name="application_body.terms_agreed"
          label="terms-and-conditions"
          labelWithLink={{
            text: _(msg`أقبل الموافقة على`),
            linkText: _(msg`شروط المسابقة `),
            onClick: handelTermsLinkClick
          }}
          layout="second"
        />
      </div>

      {showTermsModal && (
        <TermsModal key="compition modal" handleTermsModalDisplay={setShowTermsModal} />
      )}
    </>
  )
}

export default ApplicationBodyForm
