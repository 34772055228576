import { Controller, useFormContext } from 'react-hook-form'
import { FC } from 'react'
import UploadBox from 'components/upload/upload-box'

interface UploadInputProps {
  name: string
  label?: string
  placeholder?: React.ReactNode
  disabled?: boolean
  multiple?: boolean
  allowRemove?: boolean
  noFileLabel?: boolean
  onDrop?: (value: any) => void
  onRemove?: () => void
}

const UploadInput: FC<UploadInputProps> = ({
  name,
  label,
  placeholder,
  disabled,
  multiple = false,
  allowRemove = true,
  noFileLabel = false,
  onDrop,
  onRemove,
  ...other
}) => {
  const { control, setValue } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) =>
        multiple ? (
          <UploadBox
            multiple
            onDrop={(acceptedFiles) => {
              const newFiles = [...(field.value || []), ...acceptedFiles]
              field.onChange(newFiles)
              onDrop && onDrop(newFiles)
            }}
            files={field.value || []}
            placeholder={placeholder}
            onRemove={(fileToRemove) => {
              const updatedFiles = field.value.filter(
                (file: File | string) => file !== fileToRemove
              )
              field.onChange(updatedFiles)
              if (updatedFiles.length === 0) {
                setValue(name, null)
              }
              if (onRemove) onRemove()
            }}
            onRemoveAll={() => {
              field.onChange([])
              setValue(name, null)
              if (onRemove) onRemove()
            }}
            error={error}
            disabled={disabled}
            label={label}
            allowRemove={allowRemove}
            noFileLabel={noFileLabel}
            {...other}
          />
        ) : (
          <UploadBox
            className="inp-upload"
            onDrop={(acceptedFiles) => {
              field.onChange(acceptedFiles[0])
              onDrop && onDrop(acceptedFiles)
            }}
            file={field.value}
            placeholder={placeholder}
            onRemove={() => {
              field.onChange(null)
              setValue(name, null)
              if (onRemove) onRemove()
            }}
            error={error}
            disabled={disabled}
            noFileLabel={noFileLabel}
            allowRemove={allowRemove}
            label={label}
            {...other}
          />
        )
      }
    />
  )
}

export default UploadInput
