import React, { useCallback } from 'react'
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'
import CheckboxVariant from 'components/checkbox/checkbox'
import DatePickerLocal from 'components/date-picker-localized/date-picker'
import { useDispatch, useSelector } from 'react-redux'
import { setBeginDate, setDurationType } from 'store/slices/headerCoordinatorFilterSlice'
import { setEndDate } from 'store/slices/competitionYearSlice'
import { AppDispatch, RootState } from 'store'

export default function FilterLayout() {
  const { _ } = useLingui()
  const dispatch = useDispatch<AppDispatch>()

  const durationType = useSelector(
    (state: RootState) => state.reportsHeadCoordinatorFilter.durationType
  )
  const beginDate = useSelector((state: RootState) => state.reportsHeadCoordinatorFilter.beginDate)
  const endDate = useSelector((state: RootState) => state.reportsHeadCoordinatorFilter.endDate)

  const handleDurationAllChange = useCallback(() => {
    dispatch(setDurationType('all'))
  }, [dispatch])

  const handleDurationCustomChange = useCallback(() => {
    dispatch(setDurationType('custom'))
  }, [dispatch])

  const handleBeginDateChange = useCallback(
    (date: Date) => {
      dispatch(setBeginDate(date.toISOString()))
    },
    [dispatch]
  )

  const handleEndDateChange = useCallback(
    (date: Date) => {
      dispatch(setEndDate(date.toISOString()))
    },
    [dispatch]
  )

  return (
    <div className="flex flex-col w-full gap-4">
      <CheckboxVariant
        label={_(msg`كل الفترات`)}
        checked={durationType === 'all'}
        onChange={handleDurationAllChange}
      />
      <CheckboxVariant
        label={_(msg`فترة زمنية محددة`)}
        checked={durationType === 'custom'}
        onChange={handleDurationCustomChange}
      />
      {durationType === 'custom' && (
        <>
          <DatePickerLocal
            labelClassName="text-primary"
            label={_(msg`تاريخ بداية الفترة`)}
            value={(beginDate ? new Date(beginDate) : new Date()) as any}
            onChange={handleBeginDateChange as any}
          />
          <DatePickerLocal
            labelClassName="text-primary"
            label={_(msg`تاريخ نهاية الفترة`)}
            value={endDate ? new Date(endDate) : (new Date() as any)}
            onChange={handleEndDateChange as any}
          />
        </>
      )}
    </div>
  )
}
