import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { ArrowLeft } from 'assets/icons/ArrowLeft'
import { RejectApplicationIcon } from 'assets/icons/RejectAppliactoinIcon'
import ButtonVariant from 'components/button-variant/button'
import ModalHeader from 'components/modal/modal-header'
import ModalLayout from 'components/modal/modal-layout'
import Typography from 'components/typography/typography'

type RejectApplicationModuleProps = {
  onClose: () => void
  rejectReason: string
  resetForm?: () => void
}

function RejectApplicationModule({
  onClose,
  rejectReason,
  resetForm
}: RejectApplicationModuleProps) {
  const { _ } = useLingui()
  const handleResetAndClose = () => {
    resetForm && resetForm()
    onClose()
  }

  return (
    <ModalLayout
      showBackButton={false}
      appStyle
      backdrop="opaque"
      coordinitorPageStyle={true}
      header={
        <ModalHeader
          subheaderText={
            <>
              <div className="flex flex-col gap-2">
                <div className="mb-4 flex w-full justify-center">
                  <RejectApplicationIcon />
                </div>
                <Typography weight="bold" size="xl" align="center">
                  {_(msg`تم رفض الطلب `)}
                </Typography>
                <Typography align="center" className="pb-5">
                  {_(msg`سبب الرفض :`)}{' '}
                  <span className="text-danger_default_stroke_hover">{rejectReason}</span>
                </Typography>
                <ButtonVariant
                  type="button"
                  variant="secondary"
                  onClick={handleResetAndClose}
                  endContent={<ArrowLeft />}
                >
                  {_(msg`رفع الطلب من جديد`)}
                </ButtonVariant>
              </div>
            </>
          }
        />
      }
    />
  )
}

export default RejectApplicationModule
