import { FC, ReactNode } from 'react'
import ButtonVariant, { ButtonVariantProps } from 'components/button-variant/button'
import useLanguage from 'hooks/useLanguage'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

type AlertProps = {
  mainText: string
  weightMainText?: 'light' | 'normal' | 'medium' | 'semibold' | 'bold'
  sizeMainText?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  subText?: string
  buttonText?: string | ReactNode
  onButtonClick?: () => void
  onClose?: () => void
  closable?: boolean
  type?: 'success' | 'error' | 'info' // Added 'info' here
  btnProps?: ButtonVariantProps
}

export const Alert: FC<AlertProps> = ({
  mainText,
  weightMainText = 'semibold',
  sizeMainText = 'lg',
  subText,
  buttonText,
  onButtonClick,
  onClose,
  closable = false,
  type = 'success',
  btnProps = {}
}) => {
  const language = useLanguage()
  const { _ } = useLingui()

  const styles = {
    success: {
      background: 'bg-success_alert',
      border: 'border-success_default_stroke_pressed',
      text: 'text-success_default_stroke_pressed',
      btnBackground:
        'bg-success_default_stroke_pressed hover:bg-success_default_stroke_hover focus:bg-success_default_stroke_hover'
    },
    error: {
      background: 'bg-danger_default_lv2_text_normal_active',
      border: 'border-danger_inverted_stroke_normal',
      text: 'text-danger_default_fill_normal_active',
      btnBackground:
        'bg-danger_default_fill_normal_active hover:bg-danger_default_stroke_hover focus:bg-danger_default_stroke_hover'
    },
    info: {
      background: 'bg-primary_fill_soft_overlay',
      border: 'border-information_default_stroke_pressed',
      text: 'text-information_default_stroke_pressed',
      btnBackground:
        'bg-information_default_stroke_pressed hover:bg-information_default_stroke_hover focus:bg-information_default_stroke_hover'
    }
  }

  const currentStyle = styles[type] || styles.success

  return (
    <div
      dir={language === 'ar' ? 'rtl' : 'ltr'}
      className={`relative py-4 px-4 flex flex-col gap-y-3 sm:flex-row items-center w-full justify-between mb-4 rounded-xl ${currentStyle.background} ${currentStyle.border} border`}
    >
      <div className="flex flex-col gap-1">
        <p className={`text-${sizeMainText} font-${weightMainText} ${currentStyle.text}`}>
          {mainText}
        </p>
        {subText && <p className={`text-md ${currentStyle.text}`}>{subText}</p>}
      </div>

      {buttonText && (
        <ButtonVariant
          radius="full"
          onPress={onButtonClick}
          className={`self-end  ${currentStyle.btnBackground}`}
          style={{ backgroundColor: currentStyle.text }}
          {...btnProps}
        >
          {buttonText}
        </ButtonVariant>
      )}

      {closable && onClose && (
        <ButtonVariant
          variant={
            type === 'success' ? `success-alert` : type === 'error' ? 'danger-alert' : 'info-alert'
          }
          onClick={onClose}
        >
          {_(msg`إغلاق`)}
        </ButtonVariant>
      )}
    </div>
  )
}

export default Alert
