import InputField from 'components/input-field/input-field'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type TextInputProps = {
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  helperText?: React.ReactNode
} & Omit<React.ComponentProps<typeof InputField>, 'name' | 'label' | 'helperText'>

const TextInput: FC<TextInputProps> = ({
  name,
  label,
  placeholder,
  disabled,
  helperText,
  ...other
}) => {
  const { control, clearErrors } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <InputField
          {...field}
          label={label}
          isDisabled={disabled}
          placeholder={placeholder}
          helperText={helperText}
          error={error ? error.message : null}
          {...other}
          onChange={(e) => {
            field.onChange(e)
            clearErrors(name)
          }}
        />
      )}
    />
  )
}

export default TextInput
