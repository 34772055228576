import { Trans } from '@lingui/react'
import React from 'react'

interface BadgeProps {
  variant?:
    | 'success'
    | 'danger'
    | 'primary'
    | 'natural'
    | 'secondary'
    | 'information'
    | 'disabled'
    | 'warning'
    | 'black'
    | 'default'
  placeholder: string
  size?: 'sm' | 'lg'
  shape?: 'curved' | 'rectangular'
  className?: string
  placeholderClassName?: string
}

const variantStyles = () => ({
  primary: `border border-tertiary_normal text-natural_lv2_text_normal bg-tertiary_normal bg-opacity-30`, // from Figma
  natural: `border border-secondary_stroke_normal text-natural_lv2_text_normal bg-secondary_fill_normal_pressed bg-opacity-30`, // from Figma
  secondary: `border border-natural_lv1_text_normal text-natural_lv1_text_normal bg-primary_fill_soft_overlay`, // from Figma
  information: `text-information_inverted_lv2_text_normal bg-information_inverted_fill_normal border border-information_inverted_stroke_normal_active`, // from Figma
  success: `border border-success_inverted_stroke_normal text-success_inverted_lv2_text_normal bg-success_inverted_fill_normal`, // from Figma
  warning: `border border-warning_inverted_stroke_hover_pressed text-warning_default_fill_pressed bg-warning_inverted_fill_normal_active`, // from Figma
  black: `border border-natural_stroke_pressed text-warning_default_lv1_text_normal bg-background_creamy`, // from Figma
  danger: `text-red-700 bg-danger_inverted_fill_normal border border-danger_inverted_stroke_normal`, // from Figma
  disabled: `border text-gray-700 bg-gray-200`,
  default: `text-gray-700 bg-transparent`
})

const sizeStyles = {
  sm: 'py-1 px-2',
  lg: 'py-1.5 px-2.5'
}

const shapeStyles = {
  curved: 'rounded-full',
  rectangular: 'rounded-md'
}

const Badge: React.FC<BadgeProps> = ({
  variant = 'success',
  placeholder,
  size = 'sm',
  shape = 'rectangular',
  className,
  placeholderClassName
}) => {
  const styles = variantStyles()
  const sizeClass = sizeStyles[size]
  const shapeClass = shapeStyles[shape]

  return (
    <div className={`w-fit ${styles[variant]} ${sizeClass} ${shapeClass} ${className}`}>
      <p className={`"font-body-h6 capitalize ${placeholderClassName}`}>
        <Trans id={placeholder} />
      </p>
    </div>
  )
}

export default Badge
