import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import ButtonVariant, { ButtonVariantProps } from 'components/button-variant/button'
import Filter, { Props } from 'components/Filter'
import { FC, ReactNode } from 'react'

interface FProps extends Props {
  buttonContent?: ReactNode
  buttonText?: string
  onButtonPress?: () => void
  showButton?: boolean
  btnProps?: ButtonVariantProps
}

export const FilterLayout: FC<FProps> = ({
  filterFields,
  onFilterApply,
  onFilterReset,
  buttonContent,
  buttonText,
  onButtonPress,
  showButton = true,
  btnProps
}) => {
  const { _ } = useLingui()

  return (
    <div className="flex flex-col gap-4 relative items-center sm:flex-row justify-between w-[100%] mb-12">
      <Filter
        filterFields={filterFields}
        onFilterApply={onFilterApply}
        onFilterReset={onFilterReset}
      />
      {showButton && (
        <div className="block-inline">
          <ButtonVariant onPress={onButtonPress} size="lg" variant="secondary" {...btnProps}>
            {buttonContent || buttonText || _(msg`Add something`)}
          </ButtonVariant>
        </div>
      )}
    </div>
  )
}

export default FilterLayout
