import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Trans } from '@lingui/react'
import { RouteConfig } from 'pages/auth/routesConfig'
import SolidHomeIcon from 'assets/icons/SolidHomeIcon'
import ChevronIcon from 'assets/icons/ChevronIcon'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

interface BreadcrumbProps {
  breadcrumbs: RouteConfig[]
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumbs }) => {
  const direction = useSelector((state: RootState) => state.layout.direction)
  const chevronClass = `${direction === 'rtl' ? '-rotate-90' : 'rotate-90'}`

  const location = useLocation()
  const pathnameParts = location.pathname.split('/').filter(Boolean)

  const customLastLabel = useSelector((state: RootState) => state.breadcrumb.customLastLabel)

  const pathToRegex = (path: string) => new RegExp(path.replace(/:[^\s/]+/g, '([\\w-]+)'))

  const getBreadcrumbTrail = (routes: RouteConfig[], pathParts: string[]) => {
    let trail: RouteConfig[] = []
    let currentPath = ''

    for (let i = 0; i < pathParts.length; i++) {
      let currentPathScoped = `${currentPath}/${pathParts[i]}`

      const match = routes.find((route) => {
        const regex = pathToRegex(route.path)
        return regex.test(currentPathScoped)
      })

      if (match && match.showInBreadcrumb !== false) {
        trail.push(match)

        const nestedRoutes = routes.filter(
          (route) => route.name.startsWith(`${match.name}.`) && route.path.includes(pathParts[i])
        )
        if (nestedRoutes.length > 0) {
          routes = nestedRoutes
        }
      }

      currentPath = currentPathScoped
    }

    return trail
  }

  const breadcrumbTrail = getBreadcrumbTrail(breadcrumbs, pathnameParts)

  return (
    <nav className="flex items-center gap-2 py-2.5">
      <SolidHomeIcon />
      <span className={chevronClass}>
        <ChevronIcon fill="#3F3F46" width="24" height="24" />
      </span>
      {breadcrumbTrail.length > 0 &&
        breadcrumbTrail.map((crumb, index) => {
          const isLast = index === breadcrumbTrail.length - 1
          const label = isLast && customLastLabel ? customLastLabel : crumb.label

          return (
            <span key={crumb.path} className="flex items-center">
              {index > 0 && (
                <span className={chevronClass}>
                  <ChevronIcon fill="#3F3F46" width="24" height="24" />
                </span>
              )}
              {isLast ? (
                <span className="text-natural_lv1_text_normal font-body-h6">
                  <Trans id={label} />
                </span>
              ) : (
                <Link to={`${crumb.path}`} className="text-natural_icon_normal font-body-h6">
                  <Trans id={label} />
                </Link>
              )}
            </span>
          )
        })}
    </nav>
  )
}

export default Breadcrumb
