import { MessageDescriptor } from '@lingui/core'
import { msg, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Modal, ModalBody, ModalContent, ModalHeader } from '@nextui-org/react'
import { CompetitionStatus } from 'api/types'
import ButtonVariant from 'components/button-variant/button'
import Form from 'components/FormStructure/Form'
import DateInput from 'components/hook-form/date-picker-input'
import SelectInput from 'components/hook-form/select-input'
import TextInput from 'components/hook-form/text-input'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { AppDispatch, RootState } from 'store'
import { fetchCompetitionYears } from 'store/slices/competitionYearSlice'
import { createCompetition } from 'store/slices/createCompoetitionSlice'
import { z } from 'zod'

import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

export const CompetitionErrors: { [key: string]: MessageDescriptor } = {
  FIELD_UNIQUE: msg`يجب أن يكون اسم السنة فريدًا`,
  ANOTHER_COMPETITION_YEAR_ALREADY_STARTED: msg`يجب أن يكون هناك سنة واحدة فقط حالتها بدأت`,
  CONFLICTING_COMPETITION_YEAR_PERIOD: msg`يجب أن تكون فترات سنوات المسابقة غير متداخلة مع السنوات الأخرى.`,
  CANNOT_TERMINATE_NOT_STARTED_COMPETITION_YEAR: msg`لا يمكن إنهاء سنة المسابقة قبل أن تبدأ`,
  CANNOT_START_COMPETITION_YEAR_MUST_BE_ENDED_OR_TERMINATED: msg`لبدء المنافسة، يجب أن تكون في حالة منتهية.`,
  CANNOT_ARCHIVE_STARTED_COMPETITION_YEAR: msg`لا يمكن ارشفت سنة المسابقة بعد أن تبدأ`,
  CANNOT_START_AUTO_START_COMPETITION_YEAR: msg`لا يمكن بدأ مسابقة تبدأ تلقائيا`,
  END_DATE_MUST_BE_AFTER_OR_EQUAL_TODAY: msg`يجب أن يكون تاريخ النهاية بعد أو يساوي تاريخ اليوم`,
  CANNOT_UPDATE_COMPETITION_YEAR_WITH_WORKFLOWS: msg`لا يمكن تحديث سنة مسابقة لديها سير المشاركات`,
  CLOSE_AT_MUST_BE_AFTER_START_DATE: msg`يجب أن يكون تاريخ الإغلاق بين تاريخ البدء وتاريخ النهاية`,
  CLOSE_AT_MUST_BE_BEFORE_OR_EQUAL_END_DATE: msg`يجب أن يكون تاريخ الإغلاق بين تاريخ البدء وتاريخ النهاية`,
  END_DATE_MUST_BE_AFTER_START_DATE: msg`يجب أن يكون تاريخ النهاية بعد تاريخ البدء`,
  CANNOT_DELETE_STARTED_COMPETITION_YEAR: msg`لا يمكن حذف سنة المسابقة بعد أن تبدأ`
}

export const CompetitionSchema = z
  .object({
    name: z.string().nonempty({ message: t`الاسم مطلوب` }),
    status: z.string().nonempty({ message: t`الحالة مطلوبة` }),
    start_date: z
      .string()
      .nonempty({ message: t`تاريخ البدء مطلوب` })
      .refine((date) => dayjs(date).isValid(), { message: t`تاريخ البدء غير صالح` }),
    end_date: z
      .string()
      .nonempty({ message: t`تاريخ النهاية مطلوب` })
      .refine((date) => dayjs(date).isValid(), { message: t`تاريخ النهاية غير صالح` })
      .refine(
        (date) =>
          dayjs(date).isAfter(dayjs().startOf('day')) || dayjs(date).isSame(dayjs().startOf('day')),
        {
          message: t`يجب أن يكون تاريخ النهاية بعد أو يساوي تاريخ اليوم`
        }
      ),
    closed_at: z
      .string()
      .nonempty({ message: t`تاريخ الإغلاق مطلوب` })
      .refine((date) => dayjs(date).isValid(), { message: t`تاريخ الإغلاق غير صالح` })
  })
  .superRefine((data, ctx) => {
    const startDate = dayjs(data.start_date)
    const endDate = dayjs(data.end_date)
    const closedAt = dayjs(data.closed_at)

    if (!startDate.isBefore(endDate)) {
      ctx.addIssue({
        code: 'custom',
        path: ['end_date'],
        message: t`يجب أن يكون تاريخ النهاية بعد تاريخ البدء`
      })
    }

    if (!closedAt.isBetween(startDate, endDate, 'day', '(]')) {
      ctx.addIssue({
        code: 'custom',
        path: ['closed_at'],
        message: t`يجب أن يكون تاريخ الإغلاق بين تاريخ البدء وتاريخ النهاية`
      })
    }
  })

type CreateCompetitionType = {
  isOpen: boolean
  onOpenChange: () => void
}

export const useStatusOptions = () => {
  return [
    { key: 'not_started', label: t`لم يبدأ` },
    { key: 'started', label: t`بدأ` },
    { key: 'terminated', label: t`تم انهاء الخدمة` },
    { key: 'archived', label: t`مؤرشفة` }
  ]
}

export const CreateCompetition: FC<CreateCompetitionType> = (props) => {
  const { isOpen, onOpenChange } = props
  const isLoading = useSelector((state: RootState) => state.createCompetition.isLoading)
  const dispatch = useDispatch<AppDispatch>()
  const statusOptions = useStatusOptions()
  const { _ } = useLingui()

  type CreateCompetitionSchemaType = z.infer<typeof CompetitionSchema>

  const onSubmit = async (data: CreateCompetitionSchemaType) => {
    try {
      await dispatch(
        createCompetition({
          name: data.name,
          startDate: data.start_date,
          endDate: data.end_date,
          closedAt: data.closed_at,
          status: data.status as CompetitionStatus
        })
      ).unwrap()
      await dispatch(fetchCompetitionYears({})).unwrap()
      onOpenChange()
    } catch (error: any) {
      if (error.status === 'error') {
        const message = error.message ?? ''
        toast.dismiss()
        if (message === 'CONFLICTING_COMPETITION_YEAR_PERIOD')
          toast.error(
            `${_(CompetitionErrors[message])} ${_(msg`تداخل مع`)} ${error?.error?.conflict_year?.name}.`
          )
        else toast.error(_(CompetitionErrors[message]))
      }
    }
  }

  return (
    <Modal
      size="lg"
      placement="center"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      isDismissable={false}
      isKeyboardDismissDisabled={true}
    >
      <ModalContent className="min-h-[50vh] pt-8">
        <ModalHeader className="flex flex-col items-center gap-1 mb-6">
          <p className="font-bold text-2xl">{_(msg`اضافة نسخة جديدة`)}</p>
          <p className="font-normal text-sm">
            {_(msg`يرجى إدخال المعلومات المطلوبة للنسخة الجديدة لإضافتها.`)}
          </p>
        </ModalHeader>
        <ModalBody>
          <Form
            className="!gap-3"
            schema={CompetitionSchema}
            onSubmit={onSubmit}
            defaultValues={{
              name: '',
              status: 'not_started',
              start_date: '',
              end_date: '',
              closed_at: ''
            }}
          >
            <TextInput
              className="w-full rounded-lg"
              name="name"
              size="lg"
              label={_(msg`الاسم`)}
              placeholder={_(msg`الاسم`)}
            />
            <div className="hidden">
              <SelectInput
                labelClassName="text-natural_icon_normal font-sub-heading-h6-ar"
                className="!h-auto rounded-lg"
                size="lg"
                name="status"
                options={statusOptions}
                label={_(msg`حالة النسخة`)}
                placeholder={_(msg`حالة النسخة`)}
              />
            </div>
            <DateInput
              className="!h-auto rounded-lg bg-primary_fill_soft_overlay"
              labelClassName=" text-natural_icon_normal font-sub-heading-h6-ar"
              size="lg"
              name="start_date"
              label={_(msg`تاريخ البدء`)}
            />
            <DateInput
              labelClassName=" text-natural_icon_normal font-sub-heading-h6-ar"
              className="!h-auto rounded-lg bg-primary_fill_soft_overlay"
              size="lg"
              name="end_date"
              label={_(msg`تاريخ الانتهاء`)}
            />
            <DateInput
              labelClassName=" text-natural_icon_normal font-sub-heading-h6-ar"
              className="!h-auto rounded-lg bg-primary_fill_soft_overlay"
              size="lg"
              name="closed_at"
              label={_(msg`تاريخ الإغلاق`)}
            />

            <div className="flex w-full mt-4 justify-center">
              <ButtonVariant
                type="submit"
                size="lg"
                variant="secondary"
                disabled={isLoading}
                isLoading={isLoading}
              >
                {_(msg`اضافة`)}
              </ButtonVariant>
            </div>
          </Form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CreateCompetition
