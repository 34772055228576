import { FC, ReactNode, ElementType } from 'react'
import clsx from 'clsx'

type TypographyProps = {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  weight?: 'light' | 'normal' | 'medium' | 'semibold' | 'bold'
  color?: 'primary' | 'secondary' | 'danger' | 'success' | 'warning' | 'gray' | 'black' | 'white'
  align?: 'left' | 'center' | 'right'
  as?: ElementType
  children: ReactNode
  className?: string
}

const Typography: FC<TypographyProps> = ({
  size = 'md',
  weight = 'normal',
  color = 'black',
  align = 'left',
  as: Component = 'p',
  children,
  className,
  ...other
}) => {
  const sizeClass = {
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl'
  }[size]

  const weightClass = {
    light: 'font-light',
    normal: 'font-normal',
    medium: 'font-medium',
    semibold: 'font-semibold',
    bold: 'font-bold'
  }[weight]

  const colorClass = {
    primary: 'text-primary',
    secondary: 'text-secondary',
    danger: 'text-red-600',
    success: 'text-green-600',
    warning: 'text-yellow-600',
    gray: 'text-gray-500',
    black: 'text-black',
    white: 'text-white'
  }[color]

  const alignClass = {
    left: 'text-left',
    center: 'text-center',
    right: 'text-right'
  }[align]

  return (
    <Component
      className={clsx(sizeClass, weightClass, colorClass, alignClass, className)}
      {...other}
    >
      {children}
    </Component>
  )
}

export default Typography
