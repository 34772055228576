import React, { useMemo } from 'react'
import { msg } from '@lingui/macro'
import InputField from 'components/application-viewer/components/DisabledInput'
import { Application } from 'store/slices/applicationsSlice'
import { useCountryOptions } from 'pages/SubmitApplication/hooks/useCountryOptions'
import { useRegionOptions } from 'pages/SubmitApplication/hooks/useRegionOptions'
import useLanguage from 'hooks/useLanguage'
import { useLingui } from '@lingui/react'

const LocationInfoForm = ({ applicationData }: { applicationData: Application }) => {
  const { _ } = useLingui()
  const language = useLanguage()

  const { options: countryOptions, isLoading: CountryOptionsLoading } = useCountryOptions()

  const countryId = applicationData.location?.country_id
  const regionId = applicationData.location?.region_id

  const { options: regionOptions, isLoading: RegionOptionsLoading } = useRegionOptions(
    countryId ? countryId.toString() : ''
  )

  const selectedCountry =
    !countryOptions || CountryOptionsLoading
      ? null
      : countryOptions.find((option) => +option.key === countryId)

  const countryName = selectedCountry ? selectedCountry.label : ''

  const regionName =
    !regionOptions || RegionOptionsLoading
      ? ''
      : regionOptions.find((option) => +option.key === regionId)?.label || ''

  const regionLabel = selectedCountry?.regionLabel || (language === 'ar' ? 'المنطقة' : 'Region')

  return (
    <>
      <InputField
        name="location_info.country"
        label={_(msg`الدولة`)}
        width="100%"
        value={CountryOptionsLoading ? 'Loading...' : countryName}
        isDisabled
      />
      <InputField
        name="location_info.region"
        label={regionLabel}
        width="100%"
        value={RegionOptionsLoading ? 'Loading...' : regionName}
        isDisabled
      />
    </>
  )
}

export default LocationInfoForm
