import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchSchools } from 'store/slices/schoolsSlice'

interface UseSchoolsProps {
  countryId?: string
  regionId?: string // Array of region IDs
  countryCode?: string
}

export const useSchools = ({ countryId, regionId, countryCode }: UseSchoolsProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { schools, isLoading } = useSelector((state: RootState) => state.schools)

  // Memoize the countryId and regions to prevent unnecessary re-renders
  const memoizedCountryId = useMemo(() => countryId, [countryId])
  const memoizedCountryCode = useMemo(() => countryCode, [countryCode])
  const memoizedRegionId = useMemo(() => regionId, [regionId])

  useEffect(() => {
    // Prevent unnecessary API calls if countryId is not set
    if (memoizedCountryId || memoizedRegionId || memoizedCountryCode) {
      dispatch(
        fetchSchools({
          countryId: memoizedCountryId,
          countryCode: memoizedCountryCode,
          regionsIds: memoizedRegionId ? [memoizedRegionId] : []
        })
      )
    }
  }, [dispatch, memoizedCountryId, memoizedRegionId, memoizedCountryCode])

  return { schools, isLoading }
}
