import SelectField from 'components/select-input/select-input'
import { FC, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface SelectInputProps {
  name: string
  options: { key: string; label: string }[] // Accepting { key, label }
  label?: string
  placeholder?: string
  helperText?: string
  selectionMode?: 'single' | 'multiple'
  mode?: 'normal' | 'search'
  isChips?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  [key: string]: any
  className?: string
}

const SelectInput: FC<SelectInputProps> = ({
  name,
  options,
  label,
  placeholder,
  helperText,
  selectionMode = 'single',
  mode = 'normal',
  isChips,
  isLoading,
  isDisabled,
  className,
  ...other
}) => {
  const { control, clearErrors } = useFormContext()
  const mappedOptions = useMemo(
    () => options.map(({ key, label }) => ({ value: key, label })),
    [options]
  )

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={selectionMode === 'multiple' ? [] : ''}
      render={({ field, fieldState: { error } }) => (
        <SelectField
          label={label}
          placeholder={placeholder}
          options={mappedOptions}
          loading={isLoading}
          disabled={isDisabled}
          value={field.value}
          selectionMode={selectionMode}
          mode={mode}
          className={className}
          error={error ? error.message : undefined}
          helperText={helperText}
          onChange={(value) => {
            field.onChange(value) 
            clearErrors(name) 
          }}
          {...other}
        />
      )}
    />
  )
}

export default SelectInput
