import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { ArrowLeft } from 'assets/icons/ArrowLeft'
import BlackedIcon from 'assets/icons/BlockedIcon'
import ButtonVariant from 'components/button-variant/button'
import ModalLayout from 'components/modal/modal-layout'
import React from 'react'

export default function UserBlocked() {
  const { _ } = useLingui()

  const handleEmailClick = () => {
    window.location.href = 'mailto:info@ireadaward.com';
  }

  return (
    <ModalLayout
      showBackButton={false}
      body={
        <div className="flex flex-col gap-4 items-center w-full">
          <BlackedIcon />
          <h4 className="font-heading-h4">{_(msg`حسابك متوقف في الوقت الحالي`)}</h4>
          <span className="text-center">
            {_(msg`
               لا يُسمح لك حاليًا بالدخول إلى النظام. يرجى التواصل مع الدعم الفني عبر البريد الإلكتروني 
              `)}
            <a href="mailto:info@ireadaward.com" className="text-primary">
              {' '}
              info@ireadaward.com{' '}
            </a>
            {_(msg` للحصول على المساعدة.`)}
          </span>
          <ButtonVariant variant="secondary" onClick={handleEmailClick}>
            <div className="text-white">
              <ArrowLeft fill="currentColor" />
            </div>
            {_(msg`تواصل مع الادارة`)}
          </ButtonVariant>
        </div>
      }
    />
  )
}
