import { Speaker } from 'pages/landing/models'

import orhan from 'assets/images/speakers/orhan.png'
import olgha from 'assets/images/speakers/olgha.png'
import gornah from 'assets/images/speakers/gornah.png'
import adonec from 'assets/images/speakers/adonec.png'
import koleto from 'assets/images/speakers/koleto.png'
import jokha from 'assets/images/speakers/jokha.png'
import alghathami from 'assets/images/speakers/alghathami.png'
import halaq from 'assets/images/speakers/halaq.png'
import haddad from 'assets/images/speakers/haddad.png'
import ahlam from 'assets/images/speakers/ahlam.png'
import alberto from 'assets/images/speakers/alberto.png'

import { msg } from '@lingui/macro'

const speakersList: Speaker[] = [
  {
    name: msg`أورهان باموق`,
    country: msg`الفائز بجائزة نوبل للآداب 2006`,
    avatar: orhan
  },
  {
    name: msg`أولغا توكارتشوك`,
    country: msg`الفائزة بجائزة نوبل للآداب 2018`,
    avatar: olgha
  },
  {
    name: msg`عبدالرزاق قرنح`,
    country: msg`الفائز بجائزة نوبل للآداب 2021`,
    avatar: gornah
  },
  {
    name: msg`أدونيس`,
    country: msg`الفائز بجائزة جوته الألمانية 2011`,
    avatar: adonec
  },
  {
    name: msg`عبدالفتاح كيليطو`,
    country: msg`الفائز بجائزة الملك فيصل العالمية 2023`,
    avatar: koleto
  },
  {
    name: msg`ألبرتو مانغويل`,
    country: msg`كاتب ومترجم أرجنتيني-كندي`,
    avatar: alberto
  },
  {
    name: msg`جوخة الحارثي`,
    country: msg`الفائزة بجائزة مان بوكر العالمية 2019`,
    avatar: jokha
  },
  {
    name: msg`عبدالله الغذامي`,
    country: msg`الفائز بجائزة الشيخ زايد للكتاب لشخصية العام الثقافية`,
    avatar: alghathami
  },
  {
    name: msg`وائل حلاق`,
    country: msg`الفائز بجائزة الملك فيصل العالمية 2024`,
    avatar: halaq
  },
  {
    name: msg`قاسم حداد`,
    country: msg`شاعر وباحث بحريني`,
    avatar: haddad
  },
  {
    name: msg`أحلام مستغانمي`,
    country: msg`كاتبة وروائية جزائرية`,
    avatar: ahlam
  }
]

export default speakersList
