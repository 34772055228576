import React from 'react';
interface props {
  fill?: string
}

const MarkIcon: React.FC<props> = ({ fill= "#3F3F46"}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <path d="M5.23438 19.2501H6.64838L15.9624 9.93608L14.5484 8.52208L5.23438 17.8361V19.2501ZM21.2344 21.2501H3.23438V17.0071L16.6694 3.57208C16.8569 3.38461 17.1112 3.2793 17.3764 3.2793C17.6415 3.2793 17.8958 3.38461 18.0834 3.57208L20.9124 6.40108C21.0998 6.58861 21.2052 6.84292 21.2052 7.10808C21.2052 7.37325 21.0998 7.62756 20.9124 7.81508L9.47738 19.2501H21.2344V21.2501ZM15.9624 7.10808L17.3764 8.52208L18.7904 7.10808L17.3764 5.69408L15.9624 7.10808Z" fill={fill}/>
  </svg>
);

export default MarkIcon;
