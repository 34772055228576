import TabsNavigator from 'components/tabs-navigator/tabs-navigator'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import {
  AccessRequestSchemaType,
  ActivitySchemaType,
  CoordinatorInfoSchemaType,
  ReviewRequestSchemaType
} from './ReviewRequestSchemaType2'
import CoordinatorDetail from './CoordinatorDetail/coordinator-detail'
import RequestActivites from './RequestActivites/request-activites'
import ReviewRequestForm from './review-fequest-form'

interface ReviewRequestTabsProps {
  reviewRequestValues?: ReviewRequestSchemaType
  reviewAccessValues?: AccessRequestSchemaType
  coordinatorInfo?: CoordinatorInfoSchemaType
  activitiesList?: ActivitySchemaType[]
}

const ReviewRequestTabs: React.FC<ReviewRequestTabsProps> = ({
  reviewRequestValues,
  reviewAccessValues,
  coordinatorInfo,
  activitiesList
}) => {
  const { _ } = useLingui()
  const tabItems = [
    {
      key: 'reviewRequest',
      title: _(msg`بيانات تنشيط حساب المنسق`),
      content: (
        <ReviewRequestForm
          reviewRequestValues={reviewRequestValues}
          reviewAccessValues={reviewAccessValues}
        />
      )
    },
    {
      key: 'coordinitorInfo',
      title: _(msg`بيانات المنسق`),
      content: <CoordinatorDetail coordinatorInfo={coordinatorInfo} />
    },
    {
      key: 'recordHistory',
      title: _(msg`سجل التغييرات`),
      content: <RequestActivites activaty={activitiesList} />
    }
  ]

  return (
    <div className="flex flex-col w-full">
      <TabsNavigator variant="underlined" color="success" items={tabItems} />
    </div>
  )
}
export default ReviewRequestTabs
