import { Path } from 'pages/landing/models'
import img1 from 'assets/images/path-bg-1.png'
import img2 from 'assets/images/path-bg-2.png'
import img3 from 'assets/images/path-bg-3.png'
import { msg } from '@lingui/macro'

const pathList: Path[] = [
  {
    id: 1,
    title: msg`قارئ العام`,
    description: msg`مسابقة تستهدف طلاب وطالبات المرحلة الابتدائية العليا والمتوسطة والثانوية والجامعية أو ما يعادلها في الدول العربية. يقدم الطالب للمشاركة في المسابقة مراجعة لكتاب من اختياره ويحكي تجربته الملهمة معه، ويتدرج المشاركون عبر عدة تصفيات للتأهل إلى ملتقى أقرأ الإثرائي الذي يُقام في مركز إثراء ويجتمع فيه أفضل القراء المشاركين من العالم العربي لخوض تجربة ملهمة وصانعة للتحول، تضم مجموعة من المحاضرات والورش والحوارات، ويتأهل أفضل المشاركين في الملتقى الإثرائي إلى الحفل الختامي للتتويج بلقب قارئ العام`,
    bgImage: img1,
    showBtn: true,
    type: 'student'
  },
  {
    id: 2,
    title: msg`المدرسة / الجامعة القارئة`,
    description: msg`مسابقة لتكريم أعلى المدارس أو الجامعات في عدد المشاركين في المسابقة من طلابها وتقدمهم في مراحل المسابقة، تهدف إلى إبراز دور المدارس والجامعات في نشر ثقافة القراءة بين الطلاب والطالبات. لا يتطلب الدخول في المنافسة تسجيل المدرسة أو الجامعة، حيث يتم إحصاء نقاط المدارس والجامعات تلقائيًا وفق بيانات الطلاب المشاركين في المسابقة ضمن مسار قارئ العام.`,
    bgImage: img2,
    showBtn: false
  },
  {
    id: 3,
    title: msg`سفراء القراءة`,
    description: msg`مسابقة مخصصة للمعلمين والمعلمات والأساتذة في المدارس والجامعات. تهدف إلى تعزيز دورهم في نشر ثقافة القراءة بين الطلاب والطالبات عن طريق احتساب نقاط لكل معلم بحسب عدد المشاركين المسجلين عن طريقهم وتقدمهم في مراحل المسابقة. يتطلب الدخول في المنافسة تسجيل المعلم في موقع المسابقة للحصول على الرمز الخاص به ومشاركته مع الطلاب.`,
    bgImage: img3,
    showBtn: true,
    type: 'teacher'
  }
]

export default pathList
