import { Trans } from '@lingui/macro'

export const tourSteps = [
  {
    target: '.first-step',
    content: <Trans>يمكنك هنا معرفة مدى التقدم الذي وصلت إليه في الفوز بالجوائز..</Trans>,
    disableBeacon: true
  },
  {
    target: '.second-step',
    content: <Trans>عند النقر على الجائزة، يمكنك رؤية تفاصيلها.</Trans>,
    disableBeacon: true
  },
  {
    target: '.third-step',
    content: <Trans>يمكنك معرفة عدد الطلاب الباقيين والإجمالي عند النقر على الخط الزمني.</Trans>,
    disableBeacon: true
  },
  {
    target: '.fourth-step',
    content: (
      <Trans>يمكنك مراجعة آخر المشاركات التي تم نشرها من قبل الطلاب من خلال هذا القسم.</Trans>
    ),
    disableBeacon: true
  }
]
