import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'

interface State {
  isLoading: boolean
  success: boolean | null
  error: string | null
}

const initialState: State = {
  isLoading: false,
  success: null,
  error: null
}

export const moveApplication = createAsyncThunk(
  'application/move',
  async (body: { workflow_id: string; applications: string[] }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(`management/applications/idle/workflow`, body)
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const moveApplicationSlice = createSlice({
  name: 'moveApplication',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(moveApplication.pending, (state) => {
        state.isLoading = true
        state.success = null
        state.error = null
      })
      .addCase(moveApplication.fulfilled, (state) => {
        state.isLoading = false
        state.success = true
      })
      .addCase(moveApplication.rejected, (state, action) => {
        state.isLoading = false
        state.success = false
        state.error = action.payload as string
      })
  }
})

export default moveApplicationSlice
