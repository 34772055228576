import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import CheckboxVariant from 'components/checkbox/checkbox'
import CheckboxField from 'components/checkbox-field/checkbox-field'
import { Trans } from '@lingui/react'

interface CheckboxInputProps {
  name: string
  label: string
  className?: string
  layout?: 'first' | 'second'
  labelWithLink?: {
    text: string
    linkText: string
    url?: string
    onClick?: () => void
  }
  size?: 'sm' | 'md' | 'lg'
  color?: 'primary' | 'secondary' | 'success' | 'warning' | 'default' | 'danger' | undefined
  removeBorder?: boolean
  reverse?: boolean
  rounded?: boolean
  isDisabled?: boolean
}

interface ErrorObject {
  message?: string
  [key: string]: any
}

function getErrorMessage(errors: ErrorObject, name: string): string {

  if (name.includes('.')) {
      const pathParts = name.split('.');
      let error = errors;

      for (let part of pathParts) {
          if (error && error[part]) {
              error = error[part]
          } else {
              return ''
          }
      }

      return error?.message || ''
  } else {
      return errors[name]?.message || ''
  }
}

const CheckboxInput: FC<CheckboxInputProps> = ({
  name,
  label,
  className,
  layout = 'first',
  labelWithLink,
  size = 'md',
  color = 'primary',
  removeBorder,
  isDisabled,
  reverse,
  rounded,
  ...other
}) => {
  const {
    control,
    formState: { errors },
    clearErrors
  } = useFormContext()

  return (
    <div className="flex flex-col gap-2 items-start">
      <Controller
        name={name}
        control={control}
        rules={{ required: 'You must agree to the terms and conditions' }}
        render={({ field, fieldState: { error } }) =>
          layout === 'first' ? (
            <CheckboxVariant
              rounded={rounded}
              reverse
              removeBorder
              label={label}
              isDisabled={isDisabled}
              checked={field.value}
              onChange={() => {field.onChange(!field.value) 
                clearErrors(name)}}
              className={className}
            />
          ) : (
            <CheckboxField
              label={label}
              labelWithLink={labelWithLink}
              checked={field.value}
              onChange={() => {field.onChange(!field.value)
                clearErrors(name)}}
              className={className}
              isDisabled={isDisabled}
              size={size}
              color={color}
              error={error ? error.message : null}
            />
          )
        }
      />
      {errors && (
        <p
          className="text-danger_default_lv1_text_normal_active_hover_pressed font-label-sm-ar"
          style={{
            maxWidth: '250px',
            wordWrap: 'break-word'
          }}
        >
          <Trans id={getErrorMessage(errors, name)} />
        </p>
      )}
    </div>
  )
}

export default CheckboxInput
